import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import * as PrescriptionOrderAction from '../../actions/prescriptionOrderAction';
import { Tab, Tabs } from "react-bootstrap";
import TodayReminder from '../../components/PharmacyReminder/TodayReminder';
import OverallReminder from '../../components/PharmacyReminder/OverallReminder';
import { getDateFormat } from '../../utils/helpers';
import moment from 'moment';

class PharmacyReminderList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeKey: 'today',
        }
    }

    componentDidMount() {
        this._initialService({
            filter: {
                start_date: moment().format('YYYY-MM-DD'),
                end_date: moment().format('YYYY-MM-DD'),
            }
        })
    }

    columns = [{
        dataField: 'id',
        text: 'ID',
        hidden: true
    }, {
        dataField: 'patient.full_name',
        text: "Patient Name"
    }, {
        dataField: 'patient.contact_number',
        text: "Contact Number"
    }, {
        dataField: 'reminder_date',
        text: "Reminder Date",
        formatter: (cell, _row) => {
            return getDateFormat(cell)
        }
    },];

    handleSelectTab = (activeKey) => {
        this.setState({ activeKey });
        let filter = null;
        if (activeKey === "today") {
            filter = {
                start_date: moment().format('YYYY-MM-DD'),
                end_date: moment().format('YYYY-MM-DD'),
            }
        }
        this._initialService({ filter: filter })
    }

    _initialService = ({ page = 1, sizePerPage = 25, filter }) => {
        this.props.paginate({
            page, sizePerPage,
            sortBy: { sortField: "reminder_date", sortOrder: "desc" }, filter: filter
        });
    }

    render() {
        let { data, meta } = this.props.list;

        return (<React.Fragment>
            <Helmet>
                <title>Reminder List</title>
            </Helmet>
            <section className="container-fluid">
                <div className="row">
                    <div className="col-md-24 requester-tabs">
                        <h4 class="page-title">Reminder List</h4>
                    </div>
                    <div className="col-md-24 mt-3 request-pending">
                        <Tabs
                            defaultActiveKey="today"
                            onSelect={this.handleSelectTab}
                        >
                            <Tab eventKey="today" title="Today's Reminder">
                                {this.state.activeKey === "today" && <TodayReminder
                                    data={data}
                                    meta={meta}
                                    columns={this.columns}
                                    paginate={this._initialService}
                                />}
                            </Tab>
                            <Tab eventKey="overall" title="Overall Reminder">
                                {this.state.activeKey === "overall" && <OverallReminder data={data}
                                    meta={meta}
                                    columns={this.columns}
                                    paginate={this._initialService}
                                />}
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </section>
        </React.Fragment>);
    }
}

const mapStateToProps = (state) => ({
    list: state.prescription_order.paginate
});

const mapActionsToProps = ({
    paginate: PrescriptionOrderAction.getOrderReminders
})

export default connect(mapStateToProps, mapActionsToProps)(PharmacyReminderList);
