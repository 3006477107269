import React from 'react';
import { connect } from 'react-redux';

class Alert extends React.Component {
    render() {
        const { alert } = this.props;

        return (<div className={"no-print " + this.props.className}>
            {alert && alert.message &&
                <div className={"alert alert-" + alert.type} role="alert">
                    <strong>{alert.message}</strong>
                    {alert.errors && Object.keys(alert.errors).map((key) => (
                        <span><br />{alert.errors[key].join()}</span>
                    ))}
                </div>
            }
        </div>
        );
    }
}

const mapStateToProps = (state) => ({
    alert: state.alert
});

export default connect(mapStateToProps, null)(Alert);