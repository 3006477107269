import React from 'react';
import _ from 'lodash';
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import * as EventConfig from '../config/events';
import { hasProvider } from '../utils/auth';

class ActivityLogFilter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            filter: {
                start_date: "",
                end_date: "",
                events: ''
            },
            show_start_date: new Date(),
            show_end_date: new Date(),
            tags: {
                date_filter: "",
                events: ""
            },
        }
    }

    handleEvent = (_event, picker) => {
        this.setState({
            filter: {
                start_date: (picker.startDate._d) ? moment(picker.startDate._d).format('YYYY-MM-DD') : '',
                end_date: (picker.endDate._d) ? moment(picker.endDate._d).format('YYYY-MM-DD') : '',
                events: this.state.filter.events
            },
            show_start_date: picker.startDate._d,
            show_end_date: picker.endDate._d,
            tags: {
                date_filter: (picker.startDate._d && picker.endDate._d) ? moment(picker.startDate._d).format('DD-MM-YYYY') + ' to ' + moment(picker.endDate._d).format('DD-MM-YYYY') : '',
                events: this.state.tags.events
            },
        });
    }

    handleChange = (e) => {
        const filter = this.state.filter;
        filter[e.target.name] = e.target.value;
        const events = this.getActions()
        this.setState({
            filter: filter,
            tags: {
                date_filter: this.state.tags.date_filter,
                [e.target.name]: events[e.target.value]
            }
        });
    }

    resetSearch = () => {
        const filter = this.state.filter;
        filter.start_date = ""
        filter.end_date = ""
        filter.events = ''
        this.setState({
            filter: filter,
            show_start_date: new Date(),
            show_end_date: new Date(),
            tags: {
                date_filter: "",
                events: ""
            }
        });
        this.props.handleFilter(this.state.filter);
        window['searchToggle']();
    }

    removeFilter = (e) => {
        const filter = this.state.filter;
        const tags = this.state.tags;
        if (e.target.dataset.tag === 'date_filter') {
            filter["start_date"] = '';
            filter["end_date"] = '';
        } else {
            filter['events'] = '';
        }
        tags[e.target.dataset.tag] = '';
        this.props.handleFilter(this.state.filter);
        this.setState({
            filter: filter, tags: tags,
            show_start_date: new Date(),
            show_end_date: new Date(),
        });
    }

    handleSubmit = () => {
        this.props.handleFilter(this.state.filter);
        window['searchToggle']();
    }
    getActions = () => {
        return (hasProvider(this.props.selected_user_profile.service_provider, ['covid-center'])) ? EventConfig.covidCenterEvents : (hasProvider(this.props.selected_user_profile.service_provider, ['airline'])) ? EventConfig.airlineEvents : EventConfig.events
    }

    renderActions = () => {
        const events = this.getActions()

        return Object.keys(events).map(function (key, index) {
            return <option value={key} key={index}>
                {events[key]}
            </option>;
        })
    }

    render() {
        return (
            <div className="search-wrapper">
                <div className="search-box clearfix">
                    {(Object.keys(this.state.tags)).map((tag, index) => {
                        if (this.state.tags[tag] !== '' && !(_.isEmpty(this.state.tags[tag]))) {
                            return <span className="search-list" key={index}>{this.state.tags[tag]} <span className='removesearch' data-tag={tag} onClick={this.removeFilter.bind(this)}> x </span> </span>
                        }
                        return '';
                    })}
                    <span className="search-box-icon"></span>
                    <div className="search-container">
                        <ul>
                            <li>
                                <span>Select Date</span>
                                <DateRangePicker
                                    startDate={this.state.show_start_date}
                                    onApply={this.handleEvent}
                                    endDate={this.state.show_end_date}
                                >
                                    <input
                                        type="text"
                                        className="search-input"
                                        name="start_date"
                                        autoComplete="off"
                                        defaultValue={this.state.filter.start_date ? moment(this.state.filter.start_date).format('DD-MM-YYYY') : ''}
                                    ></input>
                                    <input
                                        type="text"
                                        className="search-input"
                                        name="end_date"
                                        autoComplete="off"
                                        defaultValue={this.state.filter.end_date ? moment(this.state.filter.end_date).format('DD-MM-YYYY') : ''}
                                    ></input>
                                </DateRangePicker>
                            </li>
                            <li>
                                <span>Select Action</span>
                                <select className="form-control" name="events" onChange={this.handleChange} value={this.state.filter.events}>
                                    <option value=""> Select Action </option>
                                    {this.renderActions()}
                                </select>

                            </li>
                            <li>
                                <button type="button" className="btn btn-secondary" onClick={this.resetSearch}>Reset</button>
                                <button type="submit" className="btn btn-primary" onClick={this.handleSubmit}>Search</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}
export default ActivityLogFilter;