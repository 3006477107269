import React from "react";
import SimpleReactValidator from "simple-react-validator";
import * as HELPER from "../utils/helpers";
import PatientService from '../services/patientService';
import Notification from "../utils/notification";
import CardSwipe from 'react-card-swipe';
import { withTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import QrReader from 'react-qr-reader';

class SearchPatient extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            card: '',
            patient_code: '',
            access_code: '',
            show: false,
            qr_code: 'No result'
        };

        CardSwipe.init({
            success: this.handleSwipeSuccess,
            debug: false
        });

        this.searchByCardBtn = React.createRef();
        this.validatorOne = new SimpleReactValidator();
        this.validatorTwo = new SimpleReactValidator();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmitOne = this.handleSubmitOne.bind(this);
        this.handleSubmitTwo = this.handleSubmitTwo.bind(this);
    }

    handleSwipeSuccess = (swipeData) => {
        this.setState({
            card: swipeData.line1
        });

        this.searchByCardBtn.current.click();
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmitOne = (e) => {
        e.preventDefault();
        if (!this.validatorOne.allValid()) {
            this.validatorOne.showMessages();
            this.forceUpdate();
            return false;
        }

        let cardObj = HELPER.getCardObj(this.state.card);

        if (cardObj) {
            this.setState({
                card: cardObj.user_code
            });

            let postData = {
                user_code: cardObj.user_code,
                card_number: cardObj.card_number,
                hash: cardObj.hash,
            };

            PatientService.searchPatientCard(postData)
                .then(resp => {
                    if (resp.data && resp.data.patient_id <= 0) {
                        Notification.show("error", { message: "Patient record not found." });
                    }
                    this.props.handlePatientSearch(resp.data.patient_id);
                })
                .catch(error => {
                    if (error.response && error.response.status) {
                        Notification.show("error", error.response.data);
                    }
                });
        } else {
            this.setState({
                card: ""
            });
            Notification.show("error", { message: "Invalid card." });
        }

    }

    handleSubmitTwo = (e) => {

        e.preventDefault();

        if (!this.validatorTwo.allValid()) {
            this.validatorTwo.showMessages();
            this.forceUpdate();
            return false;
        }

        let searchData = {
            patient_code: this.state.patient_code,
            access_code: this.state.access_code,
        };

        PatientService.searchPatientConsent(searchData)
            .then(resp => {
                if (resp.data && resp.data.patient_id <= 0) {
                    Notification.show("error", { message: "Patient record not found." });
                }
                this.props.handlePatientSearch(resp.data.patient_id);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    Notification.show("error", error.response.data);
                }
            });
    }

    dontHaveCardClick = (e) => {
        e.preventDefault();

        this.setState({
            card: '',
            patient_code: '',
            access_code: ''
        });

        this.searchCodeForm.reset();
        this.searchCardForm.reset();
    }

    haveCardClick = (e) => {
        e.preventDefault();

        this.setState({
            card: '',
            patient_code: '',
            access_code: ''
        });

        this.searchCodeForm.reset();
        this.searchCardForm.reset();
    }

    handleHideBlock = () => {
        this.setState({
            show: false
        })
    }
    componentDidUpdate(prevProps){
        if(prevProps.show === true && prevProps.show != this.props.show){
            this.setState({ show : this.props.show })
        }
    }
    showQrCodeBlock = (e) => {
        this.setState({
            show: true
        });
    }

    handleScan = data => {
        if (data) {
            this.setState({
                qr_code: data
            }, () => {

                let searchData = {
                    code: this.state.qr_code
                };

                PatientService.searchPatientQRCode(searchData)
                    .then(resp => {
                        if (resp.data && resp.data.patient_id <= 0) {
                            Notification.show("error", { message: "The QR code is invalid or has been used already, Please regenerate QR Code." });
                        }
                        this.props.handlePatientSearch(resp.data.patient_id);
                    })
                    .catch(error => {
                        if (error.response && error.response.status) {
                            Notification.show("error", error.response.data);
                        }
                    });

            })
        }
    }

    handleError = err => {
        console.error(err)
    }
    render() {
        return (
            <React.Fragment>
                <div className="col-md-17 search-by-id show">
                    <form onSubmit={this.handleSubmitOne} id="number_search_form" ref={form => this.searchCardForm = form}>
                        <div className="row">
                            <div className="col-md-12">
                                <fieldset>
                                    {HELPER.config('CARD_DEBUG') === 'false' ?
                                        <input type="text" className="form-control" id="card" name="card" value={this.state.card} onChange={this.handleChange} placeholder="Search by Card" readOnly /> :
                                        <input type="text" className="form-control" id="card" name="card" value={this.state.card} onChange={this.handleChange} placeholder="Search by Card" />}
                                    {this.validatorOne.message('card', this.state.card, 'required')}
                                </fieldset>
                            </div>
                            <div className="col-md-12">
                                <button type="submit" className="btn btn-primary card_number_search_btn show" form="number_search_form" ref={this.searchByCardBtn} data-index="14" data-tooltip="Please swipe daisy health patient card here">Search</button>
                                <button type="button" className="btn btn-secondary advance-patient-search-btn show" onClick={this.dontHaveCardClick} data-index="15" data-tooltip="If in case patient do not have card then please click here">Don’t have a card</button>
                                <button type="button" className="btn btn-secondary" data-index="16" onClick={this.showQrCodeBlock}>Search by QR Code</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="col-md-16 advance-search-form hide">
                    <form onSubmit={this.handleSubmitTwo} ref={form => this.searchCodeForm = form}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        <fieldset>
                                            <input type="text" className="form-control" id="patient_code" name="patient_code" value={this.state.patient_code}
                                                onChange={this.handleChange}
                                                autoComplete="off" placeholder="Patient Code" />
                                            {this.validatorTwo.message('patient_code', this.state.patient_code, 'required')}
                                        </fieldset>
                                    </div>
                                    <div className="col-md-12">
                                        <fieldset>
                                            <input type="text" className="form-control" id="access_code" name="access_code" value={this.state.access_code}
                                                onChange={this.handleChange}
                                                autoComplete="off" placeholder="Access Code" />
                                            {this.validatorTwo.message('access_code', this.state.access_code, 'required')}
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <button type="submit" className="btn btn-primary">Search</button>
                                <button type="button" className="btn btn-secondary search-by-id-search-btn hide" data-index="16" data-tooltip="If a patient does not have a card then ask the patient to contact the call centre and obtain the Patient code and Access code to view and fulfil the prescription." onClick={this.haveCardClick}>Have Card</button>
                                <button type="button" className="btn btn-secondary" data-index="16" onClick={this.showQrCodeBlock}>Search by QR Code</button>
                            </div>
                        </div>
                    </form>
                </div>

                <div>
                    <Modal size='md' centered aria-labelledby="contained-modal-title-vcenter" show={this.state.show} onHide={this.handleHideBlock} className="qr-code-scanner">
                        <Modal.Header closeButton>
                            <h6>QR Code Scan</h6>
                        </Modal.Header>
                        <Modal.Body>
                            <QrReader
                                delay={5000}
                                onError={this.handleError}
                                onScan={this.handleScan}
                                style={{ width: '100%' }}
                            />
                        </Modal.Body>
                    </Modal>
                </div>
            </React.Fragment>
        );
    }
}


export default withTranslation()(SearchPatient);
