import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import Footer from '../components/Footer';
import * as Sentry from '@sentry/react';
import ErrorBoundary from './../components/ErrorBoundary';

class PublicLayout extends React.Component {
    constructor(props) {
        super(props);

        document.body.classList.add("login-forgot-background");
        document.body.classList.remove("page-404");
    }
    render() {
        const { component: Component, ...rest } = this.props;

        return (
            <Route {...rest} render={props => (
                <React.Fragment>
                    <Sentry.ErrorBoundary fallback={({ error }) => (
                        <ErrorBoundary error={error} />
                    )}>
                        <NotificationContainer />
                        <Component {...props} />
                    </Sentry.ErrorBoundary>
                    <Footer {...props} />
                </React.Fragment>
            )} />
        );
    }
}

export default connect(null, null)(PublicLayout);