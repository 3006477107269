import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import overlayFactory from 'react-bootstrap-table2-overlay';

const MyExportCSV = (props) => {
    const handleClick = () => props.onExport();
    return <button className="btn btn-primary" onClick={handleClick}>Export CSV</button>;
};

class DataGrid extends React.Component {
    static defaultProps = {
        loading: false,
        pagination: true,
        noDataIndication: " ",
        data: [],
        meta: {
            current_page: 0,
            per_page: 0,
            total: 0
        },
    }

    render() {
        return (<React.Fragment>
            <PaginationProvider
                pagination={paginationFactory({
                    custom: true,
                    page: parseInt(this.props.meta.current_page),
                    sizePerPage: parseInt(this.props.meta.per_page),
                    totalSize: parseInt(this.props.meta.total),
                })}
            >{
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <ToolkitProvider
                            keyField={this.props.keyField ? this.props.keyField : "id"}
                            data={this.props.data}
                            columns={this.props.columns}
                            exportCSV
                        >
                            {
                                props => (
                                    <React.Fragment>
                                        {this.props.export_csv && <div><MyExportCSV {...props.csvProps} /></div>}
                                        <div className="data-grid-table">
                                            <BootstrapTable
                                                bootstrap4
                                                classes={"table-responsive common-table " + (this.props.classes ? this.props.classes : '')}
                                                {...(this.props.selectRow ? { selectRow: this.props.selectRow } : {})}
                                                bordered={false}
                                                remote
                                                {...props.baseProps}
                                                onTableChange={this.props.handleTableChange}
                                                {...paginationTableProps}
                                                noDataIndication={this.props.loading === false ? this.props.noDataIndication : " Loading ..."}
                                                rowClasses={this.props.rowClasses}
                                                loading={this.props.loading}
                                                overlay={overlayFactory({ spinner: true, styles: { overlay: (base) => ({ ...base, background: 'rgba(158, 158, 158, 0.5)' }) } })}
                                                exportCSV
                                            />
                                            {(this.props.pagination && this.props.meta.total > 0) &&
                                                <div>
                                                    <span className="float-left">
                                                        Show <SizePerPageDropdownStandalone {...paginationProps} /> entries
                                                    </span>
                                                    <span className="float-right">
                                                        <PaginationListStandalone {...paginationProps} />
                                                    </span>
                                                </div>
                                            }
                                        </div>
                                    </React.Fragment>
                                )}
                        </ToolkitProvider>
                    )
                }
            </PaginationProvider>
        </React.Fragment>);
    }
}

export default DataGrid;
