import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { fullNameStr, getDateFormat, getStatus } from '../../utils/helpers';
import DataGrid from "../../components/DataGrid";
import * as PathoRadioAction from '../../actions/PathoRadioAction';
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import PathologyRadiologyDashboard from '../../components/Dashboard/Pathology&RadiologyDashboard';
import _ from 'lodash'

class ListPathologyOrderPage extends React.Component {
    columns = () => {
        return [{
            dataField: 'order_id',
            text: 'Order Id',
        }, {
            dataField: 'patient_id',
            text: 'Patient Id',
            hidden: true
        }, {
            text: 'Patient Name',
            dataField: 'patient_info.full_name',
            sort: true
        }, {
            formatter: this.dateTimeFormat,
            text: 'Order Date'
        }, {
            text: 'Status',
            formatter: this.statusFormatter,
        }, {
            dataField: 'actions',
            text: 'Actions',
            isDummyField: true,
            formatter: this.actionFormatter
        }
        ];
    };

    actionFormatter = (cell, row) => {
        return (
            <Link to={"/pathology/order/" + row.order_id} >
                <span className="icon file"></span>
            </Link>
        );
    };

    constructor(props) {
        super(props);
        this.props.paginate({ sortBy: { sortField: "updated_at", sortOrder: "desc" }, });
        this.state = {
            filter: {
                order_id: "",
            },
            tags: {
                order_id: "",
            }
        }
        this.validator = new SimpleReactValidator();
    }

    statusFormatter = (cell, row) => {
        return getStatus(row.status, this.props.selected_user_profile.role_slug)
    }

    nameFormatter = (cell, row) => {
        return fullNameStr(row.patient_info);

    };

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        if (type === 'search') {
            page = 1;
        }
        this.props.paginate({ page, sizePerPage, sortBy: { sortField: "updated_at", sortOrder: "desc" } });
    }

    dateTimeFormat = (cell, row) => {
        return getDateFormat(row.order_date)
    }

    handleChange = e => {
        const filter = this.state.filter;
        filter[e.target.name] = e.target.value ? e.target.value : undefined;
        this.setState({ filter });
    };

    resetSearch = () => {
        this.props.paginate({
            sortBy: { sortField: "updated_at", sortOrder: "desc" },
            filter: {
                order_id: "",
            }
        })
        this.setState({
            filter: {
                order_id: "",
            },
            tags: { order_id: "", }
        })
        window["closeToggle"]();
    };

    handleSubmit = e => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        window["closeToggle"]();
        this.setState({
            tags: { order_id: this.state.filter.order_id }
        })
        this.props.paginate({
            sortBy: { sortField: "updated_at", sortOrder: "desc" },
            filter: this.state.filter
        });
    }

    removeFilter = (e) => {
        const filter = this.state.filter;
        const tags = this.state.tags;
        filter[e.target.dataset.tag] = '';
        tags[e.target.dataset.tag] = '';
        this.setState({ filter: filter, tags: tags });
        this.props.paginate({ sortBy: { sortField: "updated_at", sortOrder: "desc" }, filter: filter });
    }

    renderTags(tags) {
        let options = [];
        return (Object.keys(tags)).map((tag, i) => {
            if (tags[tag] !== '' && !(_.isEmpty(tags[tag]))) {
                options.push(<span className="search-list">{tags[tag]} <span className='removesearch' data-tag={tag} onClick={this.removeFilter.bind(this)}> x </span> </span>)
            } else {
                return false
            }
            return options;
        })
    }

    render() {
        let { data, meta, is_requested } = this.props.prescription_patho_radio_test;
        return <div>
            <Helmet>
                <title>Order List</title>
            </Helmet>
            <div className="col-md-12">
                <h4 className="page-title">Order List</h4>
            </div>
            <div className="row">
                <PathologyRadiologyDashboard selected_user_profile={this.props.selected_user_profile} />
            </div>
            <div className="search-wrapper mt-3">
                <label>Search</label>
                <div className="search-box clearfix">
                    {this.state.tags && this.renderTags(this.state.tags)}
                    <span className="search-box-icon"></span>
                    <div className="search-container">
                        <form onSubmit={this.handleSubmit}>
                            <ul>
                                <li>
                                    <span>Order Id</span>
                                    <input
                                        type="text"
                                        value={this.state.filter.order_id}
                                        onChange={this.handleChange}
                                        name="order_id"
                                    ></input>
                                    {this.validator.message(
                                        "order_id",
                                        this.state.filter.order_id,
                                        "alpha_num"
                                    )}
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        onClick={this.resetSearch}
                                        className="btn btn-secondary"
                                    >
                                        Reset
                                </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                    >
                                        Search
                                </button>
                                </li>
                            </ul>
                        </form>
                    </div>
                </div>
            </div>
            {data && <div className="content-wrapper">
                <DataGrid
                    columns={this.columns()}
                    data={data}
                    noDataIndication={is_requested === true ? "No Record Found" : ""}
                    handleTableChange={this.handleTableChange}
                    meta={meta}
                    pagination={true}
                    
                />
            </div>}
        </div>
    }
}

const mapStateToProps = (state) => ({
    prescription_patho_radio_test: state.prescription_patho_radio_test,
    user: state.session.user,
    selected_user_profile: state.selected_user_profile,
});

const mapActionsToProps = {
    paginate: PathoRadioAction.listPathologyOrder,
};

export default connect(mapStateToProps, mapActionsToProps)(ListPathologyOrderPage);