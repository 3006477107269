import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import { capitalize, isEmpty } from 'lodash';
import { getAgeString, fullAddressStr, getDateFormat } from '../../utils/helpers';
import { hasRole } from '../../utils/auth';
import SimpleReactValidator from 'simple-react-validator';
import { connect } from 'react-redux';
import MedicineBlock from "../../components/Prescription/MedicineBlock";
import PrescriptionService from '../../services/prescriptionService';
import PatientService from '../../services/patientService';
import * as LoaderAction from '../../actions/loaderAction';
import Notification from '../../utils/notification';
import PinModal from '../../components/Signature/PinModal';
class AddPrescriptionPage extends React.Component {
    constructor(props) {
        super(props);
        const params = this.props.match.params;
        this.state = {
            patient: {},
            key: 1,
            patientId: params.patientId,
            prescriptionId: params.prescriptionId,
            btn_assign: false,
            assign_to: "",
            assign_by: this.props.selected_user_profile.role_slug,
            showCancelModal: false,
            medicines: [],
            showPinModal: false,
        }

        this.validator = new SimpleReactValidator({
            validators: {
                medicines: {
                    message: 'Please add at least one medicine',
                    rule: (val) => {
                        return val.length > 0;
                    },
                    required: true
                }
            }
        });
    }


    componentDidMount() {
        this.props.showLoader();
        PatientService.getPhamacyPatient(this.state.patientId).then(response => {
            let { data } = response;
            this.setState({
                patient: data
            });
            this.props.hideLoader();
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data)
        });
    }

    handlePrevious = () => {
        this.setState((prevState, _props) => {
            return { key: prevState.key - 1 }
        })
    }


    handleSaveAndNext = () => {
        this.setState((prevState, _props) => {
            return { key: prevState.key + 1 }
        })
    }
    handleRedirect = () => {
        this.props.history.push('/patients/search');
    }
    handleAddMedicine = () => {

        this.setState({
            medicines: this.state.medicines.concat([{
                drug_id: '',
                drug_name: '',
                drug_generic_id: '',
                drug_generic_name: '',
                dosage: '',
                meal: '',
                duration: '',
                duration_type: '',
                substitution_not_allowed: false,
                quantity: '',
                drug_type: '',
                is_other: false,
                over_dosage: false,
                substitution_not_allowed_disable: false
            }])
        });
    };
    handleMedicineChange = (idx, data) => {
        const newMedicines = this.state.medicines.map((medicine, sidx) => {
            if (idx !== sidx) return medicine;

            return { ...medicine, ...data };
        });
        this.setState({ medicines: newMedicines });
    };
    handleRemoveMedicine = (event) => {
        const idx = Number(event.target.dataset['id']);
        const medicines = this.state.medicines.filter((s, sidx) => idx !== sidx);
        this.setState({ medicines: medicines });
    };
    handleSubmit = (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        this.props.showLoader();
        const data = {
            patient_id: this.state.patientId ? this.state.patientId : null,
            prescription_drugs: this.state.medicines ? this.state.medicines : null,
        }
        PrescriptionService.createPharmacyPrescription(data).then(_resp => {
            this.props.hideLoader();
            let { data } = _resp;
            if (!isEmpty(data)) {
                this.setState({
                    prescriptionId: data.prescription_id
                });
                Notification.show('success', { message: 'Prescription has been saved successfully' });
                this.showPinModal();
            }
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data)
        })
    }
    showPinModal = () => {
        this.setState({ showPinModal: true });
    }
    handleClose = () => {
        this.setState({ showPinModal: false });
    }
    onSuccessRedirect = () => {
        this.setState({ showPinModal: false });
        this.props.history.push("/pharmacy/prescription/" + this.state.prescriptionId + '/view');
    }
    render() {
        this.validator.purgeFields();
        return (<React.Fragment >
            <Helmet>
                <title>Add Prescription</title>
            </Helmet>
            <section className="container-fluid">
                <div className="row">
                    <div className="col-md-12 requester-tabs">
                        <h4 className="page-title">Add Prescription</h4>
                    </div>


                    <div className="content-wrapper padding-bottom-10">
                        <h6>Personal Info</h6>
                        <ul className="prescription-patient-details">
                            <li>Name: <span>{this.state.patient.full_name}</span></li>
                            <li>Date of Birth: <span>{getDateFormat(this.state.patient.date_of_birth)} ({getAgeString(this.state.patient.date_of_birth)})</span></li>
                            <li>Gender: <span>{capitalize(this.state.patient.gender)}</span></li>
                            <li>Contact: <span>{capitalize(this.state.patient.contact_number)}</span></li>
                        </ul>
                        <ul className="prescription-patient-details">
                            <li>Address: <span>{fullAddressStr(this.state.patient.address)}</span></li>
                        </ul>
                        <ul className="prescription-patient-details">
                            <li>Allergy: <span>{this.state.patient.allergy ? this.state.patient.allergy.join(", ") : '--'}</span></li>
                        </ul>
                    </div>
                    <div className="content-wrapper padding-bottom-10">
                        <h6>Medication</h6>
                        <ul className="prescription-patient-details medicine-details">

                            {this.state.medicines.map((medicine, idx) => (
                                <MedicineBlock
                                    key={idx}
                                    medicine={medicine}
                                    idx={idx}
                                    handleChangeMedicine={this.handleMedicineChange}
                                    handleRemoveMedicine={this.handleRemoveMedicine}
                                    validator={this.validator}
                                    selected_user_profile={this.props.selected_user_profile}
                                    disabled={false}
                                    not_required={false}
                                    show_cancel
                                />
                            ))}
                            {this.validator.message('medicines', this.state.medicines, 'medicines')}
                        </ul>
                        <button onClick={this.handleAddMedicine} className="add-new btn btn-primary">Add</button>
                    </div>
                    <div className="col-md-24">
                        <div className="bottom-btns">
                            {hasRole(this.props.selected_user_profile, ['pharmacist', "pharmacy-manager", 'doctor', "associate-doctor"]) && <button className="btn btn-primary" onClick={this.handleSubmit}>submit</button>}
                            <Link to="/pharmacy/prescriptions/search" className="btn btn-default btn-outline-primary">Cancel</Link>
                        </div>
                    </div>
                    {this.state.showPinModal &&
                        <PinModal show={this.state.showPinModal} handleClose={this.handleClose} id={this.state.prescriptionId} type="prescription" onSuccess={this.onSuccessRedirect} />
                    }
                </div>
            </section>

        </React.Fragment >)
    }
}
const mapStateToProps = (state) => ({
    selected_user_profile: state.selected_user_profile,
});
const mapActionsToProps = {
    hideLoader: LoaderAction.loaderHide,
    showLoader: LoaderAction.loaderShow,
};
export default connect(mapStateToProps, mapActionsToProps)(AddPrescriptionPage)
