import React from "react";
import { connect } from "react-redux";
import * as DoctorAction from "../../actions/doctorAction";
import { Helmet } from "react-helmet";
import DataGrid from "../../components/DataGrid";
import DoctorAddQueueButton from "../../components/DoctorAddQueueButton";
import * as Helper from "../../utils/helpers";
import { ButtonToolbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import _ from 'lodash';
import SimpleReactValidator from 'simple-react-validator';
import { hasRole, hasProvider } from "../../utils/auth";
import * as LoaderAction from '../../actions/loaderAction';
import * as PatientAction from '../../actions/patientAction';

class ListPatientPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sortBy: { sortField: "visited_at", sortOrder: "desc" },
            filter: {
                patient_name: "",
                patient_by_code: ""
            },
            tags: {
                patient_name: "",
                patient_by_code: ""
            }
        };

        this.validator = new SimpleReactValidator();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.props.paginate({ sortBy: this.state.sortBy });
    }

    componentWillUnmount() {
        this.props.resetPaginate();
    }

    columns = () => {
        return [
            {
                dataField: "patient_code",
                text: "Patient Code",
                sort: true
            },
            {
                dataField: 'full_name',
                text: 'Full Name',
            },
            {
                dataField: "contact_number",
                text: "Contact Number"
            },
            {
                dataField: "gender",
                text: "Gender",
                formatter: this.genderFormatter
            },
            {
                dataField: "date_of_birth",
                text: "Age",
                sort: true,
                formatter: this.getAge
            },
            {
                dataField: "last_visit_at",
                text: "Visited At",
                formatter: this.dateFormatter
            },
            {
                dataField: 'actions',
                text: 'Actions',
                formatter: this.actionFormatter
            }
        ];
    };

    dateFormatter = (cell) => {
        if (cell) {
            return Helper.getDateFormat(cell);
        }
        return '--'
    }

    handleChange(e) {
        const filter = this.state.filter;
        filter[e.target.name] = e.target.value ? e.target.value : undefined;
        this.setState({ filter });
    }

    getAge = cell => {
        return Helper.getAgeString(cell);
    };

    genderFormatter = cell => {
        return _.capitalize(cell);
    };

    actionFormatter = (cell, row) => {
        const { service_provider } = this.props.selected_user_profile
        if (hasRole(this.props.selected_user_profile, ["doctor-nurse", "junior-doctor", "doctor-receptionist", "medical-practice-manager"])) {
            return <ButtonToolbar>
                <Link size="sm" to={"/patients/" + row.patient_id} title="View Patient"><span className="icon file"></span></Link>&nbsp;
                <DoctorAddQueueButton patientId={row.patient_id} history={this.props.history} />
            </ButtonToolbar>;
        } else if (hasRole(this.props.selected_user_profile, ["doctor", "associate-doctor"])) {
            return <ButtonToolbar>
                <Link size="sm" to={"/patients/" + row.patient_id} title="View Patient"><span className="icon file"></span></Link>&nbsp;
            </ButtonToolbar>;
        } else if (hasProvider(service_provider, ['covid-center'])) {
            return <ButtonToolbar>
                <Link size="sm" to={"/covid-centers/patients/" + row.patient_id} title="View Patient"><span className="icon file"></span></Link>&nbsp;
            </ButtonToolbar>;
        }
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        if (type === "search") {
            page = 1;
        }
        if (sortField === 'last_visit_at')
            sortField = 'visited_at';
        const filter = this.state.filter;

        this.props.paginate({ page, sizePerPage, sortBy: { sortField, sortOrder }, searchText, filter });
    };

    handleSubmit(e) {
        e.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        window['searchToggle']();

        this.setState({
            tags: {
                patient_name: this.state.filter.patient_name,
                patient_by_code: this.state.filter.patient_by_code,
            }
        });

        this.props.paginate({ sortBy: { sortField: "created_at", sortOrder: "desc" }, filter: this.state.filter });
    }

    resetSearch = () => {

        this.setState({
            filter: {
                patient_by_code: '',
                patient_name: ''
            },
            tags: {
                patient_by_code: '',
                patient_name: ''
            }
        });

        window['searchToggle']();

        this.props.paginate({ sortBy: { sortField: "created_at", sortOrder: "desc" } });
    }

    removeFilter = (e) => {

        const filter = this.state.filter;
        const tags = this.state.tags;

        filter[e.target.dataset.tag] = '';
        tags[e.target.dataset.tag] = '';

        this.setState({ filter: filter, tags: tags });
        this.props.paginate({ sortBy: { sortField: "created_at", sortOrder: "desc" }, filter: this.state.filter });
    }

    render() {
        const { data, meta, isLoading } = this.props.patient_list;
        let tags = this.state.tags;
        return (
            <React.Fragment>
                <Helmet>
                    <title>Patients</title>
                </Helmet>
                <div className="row">
                    <div className="col-md-24">
                        <h4 className="page-title">Patients</h4>
                        <div className="search-wrapper">
                            <label>Search</label>
                            <div className="search-box clearfix">
                                {(Object.keys(tags)).map((tag) => {
                                    if (tag !== 'doctor_id' && tags[tag] !== '' && !(_.isEmpty(tags[tag]))) {
                                        return <span className="search-list">{tags[tag]} <span className='removesearch' data-tag={tag} onClick={this.removeFilter.bind(this)}> x </span> </span>
                                    }
                                    return '';
                                })
                                }
                                <span className="search-box-icon" ref={this.searchBox}></span>

                                <div className="search-container">
                                    <form onSubmit={this.handleSubmit}>
                                        <ul>
                                            <li>
                                                <span>Patient Name</span>
                                                <input type="text" value={this.state.filter.patient_name} onChange={this.handleChange} name="patient_name"></input>
                                                {this.validator.message('patient_name', this.state.filter.patient_name, 'alpha_num_space')}
                                            </li>
                                            <li>
                                                <span>Patient Id</span>
                                                <input type="text" value={this.state.filter.patient_by_code} onChange={this.handleChange} name="patient_by_code"></input>
                                                {this.validator.message('patient_by_code', this.state.filter.patient_by_code, 'alpha_num')}
                                            </li>
                                            <li>
                                                <button type="button" onClick={this.resetSearch} className="btn btn-secondary">Reset</button>
                                                <button type="submit" className="btn btn-primary">Search</button>
                                            </li>
                                        </ul>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-wrapper">
                    <DataGrid
                        loading={isLoading}
                        columns={this.columns()}
                        noDataIndication="No Record Found"
                        data={data}
                        meta={meta}
                        handleTableChange={this.handleTableChange}
                    />
                </div>
                <Link to={{ pathname: "/patients/create" }} className="add-new-patient">Add New Patient</Link>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    patient_list: state.patient_list,
    user: state.session.user,
    selected_user_profile: state.selected_user_profile,
});

const mapActionsToProps = {
    paginate: DoctorAction.patientList,
    resetPaginate: PatientAction.reset,
    hideLoader: LoaderAction.loaderHide,
    showLoader: LoaderAction.loaderShow,
};

export default connect(mapStateToProps, mapActionsToProps)(ListPatientPage);
