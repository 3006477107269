import React from 'react';
import _ from 'lodash';
import { overall_body_chart_label_config, body_chart_sub_part } from '../../../../config/add_prescription';
import Select from 'react-select';
class BodyChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stroke: "black",
            slug: this.props.slug,
            body_chart: this.props.body_chart,
        }

    }
    getBodyChartValueByKey = (key) => {
        return overall_body_chart_label_config[key]
    }
    select = (partNumber, slug, e) => {
        let { body_chart } = this.props;
        let paths = this.props.pathConfig;

        if (!(body_chart.some(body_charts => paths[partNumber].value === body_charts.part_name && slug === body_charts.image_slug))) {

            paths[partNumber].fill = "rgba(29, 81, 138)"
            paths[partNumber].className = paths[partNumber].className + " active"
            let bodyCharts = [{
                part_slug: paths[partNumber].name,
                part_name: paths[partNumber].value,
                description: '',
            }];
            this.props.handleBodyChart(bodyCharts)
        } else {

            paths[partNumber].fill = "rgba(255, 255,255,0)"
            paths[partNumber].className = paths[partNumber].className.replace(" active", "")
            this.props.removePart(paths[partNumber].name)
            //body_chart = body_chart.filter((s) => paths[partNumber].value !== s.part_name);
        }
        this.setState({ slug: slug })
        this.props.setPaths(paths);
    }
    remove = (parts, slug, event) => {

        let paths = this.props.pathConfig;
        const idx = Number(event.target.dataset['id']);

        paths.filter(function (item) {
            return item.name === parts;
        }).map(function (path, i) {
            path.fill = "rgba(255, 255,255,0)";
            path.className = paths[idx].className.replace(" active", "");
            return path;
        });
        this.props.setPaths(paths);
        this.props.removePart(parts)

        this.setState({ slug: slug })
    }
    onHover = (pathId, e) => {
        let paths = this.props.pathConfig;
        paths[pathId].fill = "rgba(29, 81, 138)"
        this.props.setPaths(paths)
    }

    onLeave = (pathId, e) => {
        let paths = this.props.pathConfig;
        paths[pathId].fill = "rgba(255, 255, 255, 0)"
        this.props.setPaths(paths)
    }


    render() {
        let { body_chart } = this.props;
        let body_chart_data = _.groupBy(body_chart, 'image_slug');
        let title = this.getBodyChartValueByKey(this.state.slug);

        return (
            <React.Fragment>
                <div className="col-md-24">

                    <h6 className="anatomy-title">{title}</h6>
                    <div className="cancel_section">
                        <span className="cancel" onClick={(e) => this.props.removeChart(this.state.slug, e)}></span>
                    </div>
                </div>
                <div className="col-md-15">
                    {body_chart_data && Object.keys(body_chart_data).map((charts, index) =>
                        body_chart_data && body_chart_data[charts].map((item, index) => (
                            item.image_slug === this.state.slug && <SelectedPartBlock
                                item={item}
                                index={index}
                                title={title}
                                key={index}
                                remove={this.remove}
                                changeHandler={this.props.changeHandler}
                                changeSubPartHandler={this.props.changeSubPartHandler}
                            />
                        ))
                    )}
                </div>
                <div className="col-md-9">
                    <span id="anatip"></span>
                    <div id="anawrapper">
                        <div id="basea">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="800px" height="1360px" viewBox="0 0 800 1360" xmlSpace="preserve" id="men_back" className="anatomys">

                                {this.props.pathConfig && this.props.pathConfig.map((path, i) => (
                                    <path key={i} onMouseEnter={(e) => this.onHover(i, e)} onMouseLeave={(e) => this.onLeave(i, e)} onClick={(e) => this.select(i, this.state.slug, e)} className={path.className} fill={path.fill} stroke={this.state.stroke} vectorEffect="non-scaling-stroke" d={path.d}
                                    >
                                        <title>{path.value}</title>
                                    </path>
                                ))}

                                <g id="anaspotsa"></g>
                            </svg>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default BodyChart;


export const SelectedPartBlock = ({ item, index, title, remove, changeHandler, changeSubPartHandler }) => {
    let key = item.image_slug.split("_");
    const subParts = body_chart_sub_part[key[0]][key[1]][item.part_slug];
    let options = []
    subParts && Object.keys(subParts).forEach(function (key) {
        options.push({ 'value': key, 'label': subParts[key] })
    })
    return (
        <React.Fragment key={index}>
            <div className="cancel_section">
                <span><label>{title + ' - ' + item.part_name}</label>
                    <span className="cancel mt-n5" data-id={index} onClick={(e) => remove(item.part_slug, item.image_slug, e)}></span>
                    {!_.isEmpty(options) && <Select className="basic-select mb-2" name="sub_part"
                        options={options} onChange={(e) => changeSubPartHandler(item.part_slug, e)} value={options.filter(obj => obj.value == item.sub_part)} >
                    </Select>
                    }
                    <textarea id="description" name="description" className="form-control" onChange={(e) => changeHandler(item.part_slug, e)} value={item.description ? item.description : ''}></textarea>
                </span>


            </div>
        </React.Fragment>
    )
}

