import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Content from '../components/Content';
import Footer from '../components/Footer';
import { NotificationContainer } from 'react-notifications';
import { hasRole, hasProvider } from '../utils/auth';
import SelectProfile from '../components/SelectProfile';
import _ from 'lodash';
import moment from 'moment';
import * as authAction from "../actions/authAction";
import InactivityTimer from '../components/IdleTimer/InactivityTimer';
import ErrorBoundary from '../components/ErrorBoundary';
import * as Sentry from '@sentry/react';

class PrivateLayout extends React.Component {
    constructor(props) {
        super(props);

        this.checkTrailExpiry = this.checkTrailExpiry.bind(this);

        document.body.classList.remove("login-forgot-background");
        document.body.classList.remove("page-404");
    }

    checkTrailExpiry = (trialExpiryDate) => {
        const currentDate = moment();
        const expiryDate = moment(trialExpiryDate);
        const remainingTime = moment(expiryDate).diff(moment(currentDate), 'minutes');

        if (remainingTime <= 0) {
            return true;
        }
    }

    render() {
        const { component: Component, authenticated, allowRoles, allowProviders, selected_user, selected_profile, ...rest } = this.props;
        if (_.isEmpty(selected_user) && this.props.location.pathname !== '/select-profile') {
            return (<Redirect to="/select-profile" />)
        }

        if (!_.isEmpty(selected_user) && selected_user.is_profile_complete === false && this.props.location.pathname !== '/edit-profile') {
            return (<Redirect to="/edit-profile" />)
        }

        if (!_.isEmpty(selected_profile) && !_.isEmpty(selected_profile.service_provider) && selected_profile.service_provider.status === 'trial' && (this.checkTrailExpiry(selected_profile.service_provider.trial_expiry_at)) && this.props.location.pathname !== '/account-expired') {
            return (<Redirect to="/account-expired" />)
        }

        if (!_.isEmpty(allowProviders) && !_.isEmpty(selected_user) && !hasProvider(selected_user.service_provider, allowProviders)) {
            return (<Redirect to="/unauthorized" />)
        }

        if (!_.isEmpty(allowRoles) && !_.isEmpty(selected_user) && !hasRole(selected_user, allowRoles)) {
            return (<Redirect to="/unauthorized" />)
        }

        return (
            <>
                <Route {...rest} render={props => (
                    authenticated ? (
                        <React.Fragment>
                            <Sentry.ErrorBoundary fallback={({ error }) => (
                                <ErrorBoundary error={error} />
                            )}>
                                <InactivityTimer {...props} logout={this.props.logout.bind(this)} />
                                <Header {...props} />
                                <Sidebar {...props} />
                                <Content {...props} >
                                    <NotificationContainer />
                                    <Component {...props} />
                                </Content>
                                <Footer {...props} />
                                {this.props.toggle_select_profile_popup ? <SelectProfile {...props} /> : null}
                            </Sentry.ErrorBoundary>
                        </React.Fragment>
                    ) : (<Redirect to="/login" />)
                )} />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    authenticated: state.session.authenticated,
    selected_user: state.selected_user_profile,
    toggle_select_profile_popup: state.toggle_popup.select_profile,
    selected_profile: state.selected_user_profile,
});

const mapActionsToProps = {
    logout: authAction.logout
};

export default connect(mapStateToProps, mapActionsToProps)(PrivateLayout);