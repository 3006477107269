import React from "react";
import PrescriptionService from '../services/prescriptionService';
import Notification from '../utils/notification';
import MedicineLockModal from "./Medicine/MedicineLockModal";

class PrintButtonGroup extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			prescriptionId: '',
			type: '',
			isloading: false,
			title: '',
			showMedicineLockModal: false,
		}
		
		this.onSuccessRedirect = this.onSuccessRedirect.bind(this);
		this.showMedicineLockModal = this.showMedicineLockModal.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}
	componentDidMount() {
		this.setState({
			prescriptionId: this.props.prescriptionId,
			type: this.props.type,
			title: this.props.title
		});
	}

	getPdfLink = (type, id) => {
		this.setState({
			isloading: true
		});
		PrescriptionService.getPrescriptionsDownloadLink(id, type).then(resp => {
			this.setState({
				isloading: false
			});
			window.open(resp.data.link);
			if (type === 'medicine') {
				this.showMedicineLockModal();
			}
		}).catch(error => {
			this.setState({
				isloading: false
			});
			Notification.show('error', error.response.data);
		});

	}
	onSuccessRedirect() {
		this.setState({ showMedicineLockModal: false });
	}
	showMedicineLockModal() {
		this.setState({ showMedicineLockModal: true });
	}
	handleClose() {
		this.setState({ showMedicineLockModal: false });
	}
	render() {

		return (<React.Fragment>
			{this.state.isloading && this.state.isloading && <div className="loading">Loading</div>}
			<button className="btn btn-primary ml-2" onClick={e => this.getPdfLink(this.state.type, this.state.prescriptionId)}>
				{this.state.title}
			</button>
			<MedicineLockModal
				id={this.state.prescriptionId}
				onSuccess={this.onSuccessRedirect}
				show={this.state.showMedicineLockModal}
				handleClose={this.handleClose}
			/>
		</React.Fragment>)
	}
}

export default PrintButtonGroup;