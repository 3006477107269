import CovidCenterService from '../services/covidCenterService';
import Notification from '../utils/notification';

export function paginate({ page, sizePerPage, sortBy, filter }) {
    return dispatch => {
        Notification.clearAll()
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: 'COVID_REPORT_LIST_REQUEST', request: { page, sizePerPage, sortBy, filter } });

        CovidCenterService.list({ page, sizePerPage, sortBy, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: 'COVID_REPORT_LIST_SUCCESS', ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    dispatch({ type: 'COVID_REPORT_LIST_FAILED' });
                    Notification.show('error', error.response.data)
                }
            });
    }
}
export function paginateUploadedReports({ page, sizePerPage, sortBy, filter }) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: 'COVID_REPORT_LIST_REQUEST', request: { page, sizePerPage, sortBy, filter } });

        CovidCenterService.getUploadExistingReport({ page, sizePerPage, sortBy, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: 'COVID_REPORT_LIST_SUCCESS', ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    dispatch({ type: 'COVID_REPORT_LIST_FAILED' });
                    Notification.show('error', error.response.data)
                }
            });
    }
}
export function paginatePayments({ page, sizePerPage, sortBy, filter }) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: 'COVID_REPORT_LIST_REQUEST', request: { page, sizePerPage, sortBy, filter } });

        CovidCenterService.paginatePayments({ page, sizePerPage, sortBy, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: 'COVID_REPORT_LIST_SUCCESS', ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    dispatch({ type: 'COVID_REPORT_LIST_FAILED' });
                    Notification.show('error', error.response.data)
                }
            });
    }
}
export function paginateCovidTestForm({ page, sizePerPage, sortBy, filter }) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: 'COVID_REPORT_LIST_REQUEST', request: { page, sizePerPage, sortBy, filter } });

        CovidCenterService.paginateCovidTestPatients({ page, sizePerPage, sortBy, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: 'COVID_REPORT_LIST_SUCCESS', ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    dispatch({ type: 'COVID_REPORT_LIST_FAILED' });
                    Notification.show('error', error.response.data)
                }
            });
    }
}