import React from 'react';

export const Psychiatric = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <div className="heading">
            <h6>Psychiatric</h6>
        </div>
        <div className="form-group full">
            <div className="options-details">
                {/* depression */}
                <input type="checkbox" id='depression' name='depression' onChange={e => handleChange(e, 'psychiatric')} checked={checkIfProblemExist('psychiatric', 'depression') ? 'checked' : ''} />
                <label htmlFor='depression'>Depression</label>
                {/* excessive worries */}
                <input type="checkbox" id='excessive-worries' name='excessive-worries' onChange={e => handleChange(e, 'psychiatric')} checked={checkIfProblemExist('psychiatric', 'excessive-worries') ? 'checked' : ''} />
                <label htmlFor='excessive-worries'>Excessive worries</label>
                {/* falling asleep */}
                <input type="checkbox" id='difficulty-falling-asleep' name='difficulty-falling-asleep' onChange={e => handleChange(e, 'psychiatric')} checked={checkIfProblemExist('psychiatric', 'difficulty-falling-asleep') ? 'checked' : ''} />
                <label htmlFor='difficulty-falling-asleep'>Difficulty falling asleep</label>
                {/* staying asleep */}
                <input type="checkbox" id='difficulty-staying-asleep' name='difficulty-staying-asleep' onChange={e => handleChange(e, 'psychiatric')} checked={checkIfProblemExist('psychiatric', 'difficulty-staying-asleep') ? 'checked' : ''} />
                <label htmlFor='difficulty-staying-asleep'>Difficulty staying asleep</label>
                {/* sexual arousal */}
                <input type="checkbox" id='difficulties-with-sexual-arousal' name='difficulties-with-sexual-arousal' onChange={e => handleChange(e, 'psychiatric')} checked={checkIfProblemExist('psychiatric', 'difficulties-with-sexual-arousal') ? 'checked' : ''} />
                <label htmlFor='difficulties-with-sexual-arousal'>Difficulties with sexual arousal</label>
                {/* poor appetite */}
                <input type="checkbox" id='poor-apetite' name='poor-apetite' onChange={e => handleChange(e, 'psychiatric')} checked={checkIfProblemExist('psychiatric', 'poor-apetite') ? 'checked' : ''} />
                <label htmlFor='poor-apetite'>Poor appetite</label>
                {/* food cravings */}
                <input type="checkbox" id='food-cravings' name='food-cravings' onChange={e => handleChange(e, 'psychiatric')} checked={checkIfProblemExist('psychiatric', 'food-cravings') ? 'checked' : ''} />
                <label htmlFor='food-cravings'>Food cravings</label>
                {/* freq crying */}
                <input type="checkbox" id='frequent-crying' name='frequent-crying' onChange={e => handleChange(e, 'psychiatric')} checked={checkIfProblemExist('psychiatric', 'frequent-crying') ? 'checked' : ''} />
                <label htmlFor='frequent-crying'>Frequent crying</label>
                {/* sensitivity */}
                <input type="checkbox" id='sensitivity' name='sensitivity' onChange={e => handleChange(e, 'psychiatric')} checked={checkIfProblemExist('psychiatric', 'sensitivity') ? 'checked' : ''} />
                <label htmlFor='sensitivity'>Sensitivity</label>
                {/* thought of suicide */}
                <input type="checkbox" id='thoughts-of-suicide-attempts' name='thoughts-of-suicide-attempts' onChange={e => handleChange(e, 'psychiatric')} checked={checkIfProblemExist('psychiatric', 'thoughts-of-suicide-attempts') ? 'checked' : ''} />
                <label htmlFor='thoughts-of-suicide-attempts'>Thought of suicide / attempts</label>
                {/* stress */}
                <input type="checkbox" id='stress' name='stress' onChange={e => handleChange(e, 'psychiatric')} checked={checkIfProblemExist('psychiatric', 'stress') ? 'checked' : ''} />
                <label htmlFor='stress'>Stress</label>
                {/* irritability */}
                <input type="checkbox" id='irritability' name='irritability' onChange={e => handleChange(e, 'psychiatric')} checked={checkIfProblemExist('psychiatric', 'irritability') ? 'checked' : ''} />
                <label htmlFor='irritability'>Irritability</label>
                {/* poor concentration */}
                <input type="checkbox" id='poor-concentration' name='poor-concentration' onChange={e => handleChange(e, 'psychiatric')} checked={checkIfProblemExist('psychiatric', 'poor-concentration') ? 'checked' : ''} />
                <label htmlFor='poor-concentration'>Poor Concentration</label>
                {/* racing thoughts */}
                <input type="checkbox" id='racing-thoughts' name='racing-thoughts' onChange={e => handleChange(e, 'psychiatric')} checked={checkIfProblemExist('psychiatric', 'racing-thoughts') ? 'checked' : ''} />
                <label htmlFor='racing-thoughts'>Racing thoughts</label>
                {/* hallucinations */}
                <input type="checkbox" id='hallucinations' name='hallucinations' onChange={e => handleChange(e, 'psychiatric')} checked={checkIfProblemExist('psychiatric', 'hallucinations') ? 'checked' : ''} />
                <label htmlFor='hallucinations'>Hallucination</label>
                {/* radip speech */}
                <input type="checkbox" id='rapid-speech' name='rapid-speech' onChange={e => handleChange(e, 'psychiatric')} checked={checkIfProblemExist('psychiatric', 'rapid-speech') ? 'checked' : ''} />
                <label htmlFor='rapid-speech'>Rapid speech</label>
                {/* guilty thought */}
                <input type="checkbox" id='guilty-thoughts' name='guilty-thoughts' onChange={e => handleChange(e, 'psychiatric')} checked={checkIfProblemExist('psychiatric', 'guilty-thoughts') ? 'checked' : ''} />
                <label htmlFor='guilty-thoughts'>Guilty Thoughts</label>
                {/* paranoia */}
                <input type="checkbox" id='paranoia' name='paranoia' onChange={e => handleChange(e, 'psychiatric')} checked={checkIfProblemExist('psychiatric', 'paranoia') ? 'checked' : ''} />
                <label htmlFor='paranoia'>Paranoia</label>
                {/* mood swings */}
                <input type="checkbox" id='mood-swings' name='mood-swings' onChange={e => handleChange(e, 'psychiatric')} checked={checkIfProblemExist('psychiatric', 'mood-swings') ? 'checked' : ''} />
                <label htmlFor='mood-swings'>Mood swings</label>
                {/* anxiety */}
                <input type="checkbox" id='anxiety' name='anxiety' onChange={e => handleChange(e, 'psychiatric')} checked={checkIfProblemExist('psychiatric', 'anxiety') ? 'checked' : ''} />
                <label htmlFor='anxiety'>Anxiety</label>
                {/* risky behaviour */}
                <input type="checkbox" id='risk-behaviour' name='risk-behaviour' onChange={e => handleChange(e, 'psychiatric')} checked={checkIfProblemExist('psychiatric', 'risk-behaviour') ? 'checked' : ''} />
                <label htmlFor='risk-behaviour'>Risky behavior</label>
            </div>
        </div>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment }) => {
    return <React.Fragment>
        <label className="heading-comment">Comment </label>
        <textarea className="form-control" name="comments" placeholder="Type here..." onChange={e => handleChange(e, 'psychiatric')} value={getCategoryComment('psychiatric') ? getCategoryComment('psychiatric') : ''}></textarea>
    </React.Fragment>
}