import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class RadiologyService {
    static list({ page, sizePerPage, sortBy, searchText }, filter) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute('/v1/radiology/test-search'), requestOptions);
    }

    static getAllRadiologyTests() {
        const requestOptions = {
            headers: getApiHeader(),
            // params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute('/v1/radiology-tests'), requestOptions);
    }
}


export default RadiologyService;