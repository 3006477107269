import React from "react";
import { connect } from "react-redux";
import * as PrescriptionOrderAction from "../../actions/prescriptionOrderAction";
import _ from "lodash";
import queryString from 'query-string';
import ViewOrder from '../../components/Order/ViewOrder';

class OperatePrescriptionOrderPage extends React.Component {
    constructor(props) {
        super(props);

        const {
            match: { params }
        } = this.props;
        const id = params.orderId;

        const queryParams = queryString.parse(this.props.location.search)
        this.state = {
            autoPrint: (_.has(queryParams, 'print') && queryParams.print === 1) ? true : false,
        };

        this.props.fetchDetails(id, this.props.history);
        this.delete_order = this.delete_order.bind(this);
        this.delete_and_order = this.delete_and_order.bind(this);
    }

    delete_order() {
        const orderId = this.props.match.params.orderId;
        this.props.deleteOrder(orderId, this.props.history);
    }

    delete_and_order() {
        const orderId = this.props.match.params.orderId;
        this.props.deleteAndReOrder(orderId, this.props.history);
    }

    render() {
        const prescription_order = this.props.prescription_order;

        return (
            <div>
                <ViewOrder
                    autoPrint={this.state.autoPrint}
                    prescription_order={prescription_order}
                >
                </ViewOrder>

                <div className="clearfix">
                    <button class="btn btn-primary card_number_search_btn" onClick={() => { if (window.confirm('Are You Sure? Do You Want To Return it? ')) { this.delete_order() }; }}>Return Order</button>
                    <button class="btn btn-secondary advance-patient-search-btn(()" onClick={() => { if (window.confirm('Are You Sure? Do You Want To Re-order it?')) { this.delete_and_order() }; }}>Return & Re-Order </button>
                </div>
            </div >
        )
    }
}

function mapStateToProps(state) {
    return {
        prescription_order: state.prescription_order.fetch,
        pharmacy: state.selected_user_profile.service_provider,
    };
}

const mapActionsToProps = {
    fetchDetails: PrescriptionOrderAction.fetch,
    deleteOrder: PrescriptionOrderAction.deleteOrder,
    deleteAndReOrder: PrescriptionOrderAction.deleteAndReOrder,
};

export default connect(
    mapStateToProps,
    mapActionsToProps
)(OperatePrescriptionOrderPage);
