import HospitalService from '../services/hospitalService';
import Notification from '../utils/notification';

export function list({ page, sizePerPage, sortBy, searchText, filter }, status) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: 'CLEAR_HOSPITAL_PATIENT_LIST' });
        dispatch({ type: 'HOSPITAL_PATIENT_LIST_REQUEST', request: { page, sizePerPage, sortBy, searchText, filter }, status });

        HospitalService.paginateRecords({ page, sizePerPage, sortBy, searchText, filter }, status)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: 'HOSPITAL_PATIENT_LIST_SUCCESS', ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    dispatch({ type: 'HOSPITAL_PATIENT_LIST_FAILED' });
                    Notification.show('error', error.response.data)
                }
            });
    }
}

export function get(recordId) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: 'FETCH_AMBULANCE_RECORD_REQUEST'});

        HospitalService.getRecord(recordId)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: 'FETCH_AMBULANCE_RECORD_SUCCESS', ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    dispatch({ type: 'FETCH_AMBULANCE_RECORD_FAILED' });
                    Notification.show('error', error.response.data)
                }
            });
    }
}