import React from "react";
import PathologyService from "../../services/pathologyService";
import AutoSuggest from "../AutoSuggest";

class PathologyTestBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      options: []
    };
  }

  _handleChange = evt => {
    const { pathology } = this.props;

    let fieldName = evt.target.name;
    let fieldValue = evt.target.value;

    pathology[fieldName] = fieldValue;

    this.props.handleChangePathology(this.props.idx, pathology);
  };

  _handleSearch = query => {
    this.setState({ isLoading: true });
    PathologyService.list({ searchText: query }).then(resp => {
      let data = resp.data;
      const items = data.data;
      const options = items.map(i => ({
        test_id: i.test_id,
        test_type: i.test_type,
        test_category: i.test_category,
        test_name: i.test_name,
        test_description: i.test_description,
      }));

      this.setState({
        isLoading: false,
        options: options
      });
    });
  };

  _onChange = (option) => {
    let { pathology } = this.props;

    if (option) {
      if (option.customOption === true) {
        pathology.test_name = option.test_name;
        pathology.test_category = "Others";
        pathology.test_category_name = "OTHERS";
        pathology.is_other = true;
      } else {
        pathology.test_id = option.test_id;
        pathology.test_type = option.test_type;
        pathology.test_category = option.test_category;
        pathology.test_name = option.test_name;
        pathology.test_category_name = option.test_category ? option.test_category : option.test_type;
        pathology.test_description = option.test_description;
        pathology.is_other = false;
      }
    } else {
      pathology.test_id = "";
      pathology.test_type = "";
      pathology.test_category = "";
      pathology.test_name = "";
      pathology.test_category_name = "";
      pathology.test_description = "";
      pathology.is_other = false;
    }

    this.props.handleChangePathology(this.props.idx, pathology);
  };

  render() {
    return (
      <li data-id={"pathology" + this.props.idx}>
        <div className="form-group half">
          <label>Search Test</label>
          <AutoSuggest
            disabled={true}
            allowNew={true}
            isLoading={this.state.isLoading}
            options={this.state.options}
            newSelectionPrefix="New Test: "
            labelKey="test_name"
            placeholder="Search Test"
            defaultValue={this.props.pathology.test_name}
            onSearch={this._handleSearch}
            onChange={this._onChange}
            renderMenuItem={option => {
              return (
                <div key={option.id}>
                  <div>{option.test_name}</div>
                  <small>{option.test_category ? option.test_category : option.test_type}</small>
                </div>
              );
            }}
          />
          {this.props.validator.message(
            "test_name",
            this.props.pathology.test_name,
            "required"
          )}
        </div>
        <div className="form-group half">
          <label>Type</label>
          <input
            type="text"
            name="test_category"
            className="form-control"
            value={this.props.pathology.test_category ? this.props.pathology.test_category : this.props.pathology.test_type}
            onChange={this._handleChange}
            readOnly="readOnly"
          />
        </div>
        <div className="form-group full">
          <label>Test Remark</label>
          <textarea
            className="form-control"
            name="remark"
            value={this.props.pathology.remark ? this.props.pathology.remark : ''}
            onChange={this._handleChange}
            placeholder="Type test remark"
            disabled={this.props.disabled}
          ></textarea>
        </div>
        {this.props.disabled === false && <a
          href
          className="cancel"
          data-id={this.props.pathology.test_category === "COVID-19" ? this.props.pathology.test_id : this.props.idx}
          data-type={this.props.pathology.test_category === "COVID-19" ? "COVID-19" : ""}
          onClick={this.props.handleRemovePathologyTest}
        > </a>}
      </li>
    );
  }
}
export default PathologyTestBlock;
