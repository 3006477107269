import React from 'react';
import * as ConductedSubmittedBlock from '../ConductedSubmittedBlock';
import * as AddPresentingConditionComponents from './components/AddPresentingConditionComponents';
import { review_of_system_label_config } from '../../../config/add_prescription';

class ViewPresentingConditionInfo extends React.Component {
    render() {
        const { symptoms, review_of_system } = this.props;
        const getReviewOfSystemValueByKey = (key) => {
            return review_of_system_label_config[key]
        }
        return (<div className='form-group half'>
            <ConductedSubmittedBlock.ConductedBySubmittedAt
                name={review_of_system.submitted_by}
                date={symptoms.created_at}
            />
            <h6 className='mt-4'>Symptoms</h6>
            <React.Fragment>
                <p className='text-primary'>{symptoms.value}</p>
            </React.Fragment>
            <hr />

            <AddPresentingConditionComponents.ReviewOfSystem
                review_of_system={review_of_system.problems}
                getReviewOfSystemValueByKey={getReviewOfSystemValueByKey}
            />
            <hr />
        </div>)
    }
}
export default ViewPresentingConditionInfo