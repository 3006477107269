import React from 'react';
import RoleService from '../../services/roleService';

class AssignToBlock extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            assign_to: [],
        };
    }
    handleChange = (evt) => {
        this.props.handleChangeAssign(evt.target.value);
    }

    componentDidMount = () => {
        let role = RoleService.getRoleBySlug(this.props.roleSlug);
        this.setState({
            assign_to: role ? role.assign_to : []
        })
    }

    render() {
        return (
            <React.Fragment>
                <select className="form-control" onChange={this.handleChange}>
                    <option value=""></option>
                    {this.state.assign_to.map((slug, idx) => {
                        let role = RoleService.getRoleBySlug(slug);
                        return <option value={role.slug} key={idx}>{role.name}</option>
                    })}
                </select>
            </React.Fragment>
        );
    }
}

export default AssignToBlock;