import React from 'react';
import { Modal, Button, Row } from 'react-bootstrap';
import * as loaderAction from '../actions/loaderAction';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import Notification from '../utils/notification';
import PatientService from '../services/patientService';
import { FormGroup, FormControl } from "react-bootstrap";
import PathoRadioService from '../services/pathoRadioService';
import * as authUtils from '../utils/auth';
import { getDateFormat } from '../utils/helpers';
import { LabTest } from '../config/generate_report';


class GenerateLabReportModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pathology_test_items: "",
            test_item: '',
            report_data: {}
        }
    }
    componentDidMount() {
        this.getPrescribedPathologyTests();
    }

    getPrescribedPathologyTests = (e) => {
        PatientService.getPrescribedPathologyTests(this.props.patient.patient_id).then(resp => {
            this.setState({
                pathology_test_items: resp.data
            })
            this.props.hideLoader();
        }).catch(err => {
            this.props.hideLoader();
            console.log(err)
        });
    }
    handleChange = (e) => {
        this.setState({
            report_data: {
                ...this.state.report_data,
                [e.target.name]: e.target.value
            }
        })
    }

    handleSelectedTest = (test_item) => {
        this.setState({ test_item }, () => {
            let report_data = {}
            !isEmpty(LabTest[25]) && LabTest[25].map(row =>
                report_data[row.parameter] = ""
            )
            this.setState({
                report_data: report_data
            })
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (isEmpty(this.state.test_item)) {
            Notification.show('error', { message: 'Kindly select test.' })
            return false;
        } else if (this.validateData()) {
            Notification.show('error', { message: 'Kindly fill report details.' })
            return false;
        }

        const reportData = {
            test_id: this.state.test_item.test_id,
            item_id: this.state.test_item.id,
            report_data: this.state.report_data,
            patient_id: this.props.patient.patient_id,
            prescription_pathology_test_id: this.state.test_item.prescription_pathology_tests_id
        };
        this.props.showLoader();

        PathoRadioService.generatePathologyReportOrder(reportData).then(resp => {
            Notification.show('success', { message: 'Report generated successfully.' })
            this.props.refreshList();
            this.props.hideLoader();
            this.props.handleModal();
        }).catch(err => {
            this.props.hideLoader();
            Notification.show('error', err.response.data)
        })

    }

    validateData = () => {
        return Object.values(this.state.report_data).every(value => {
            if (!value) {
                return true;
            }
            return false;
        });
    }

    render() {
        let { test_item } = this.state;
        return (
            <div>
                <br />
                <form>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Date</th>
                                <th>Test</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.pathology_test_items && !isEmpty(this.state.pathology_test_items.data) ? this.state.pathology_test_items.data.map(row =>
                                <tr>
                                    <td><input className='position-initial' type="radio" name="radioGroup" id={row.id} onClick={() => this.handleSelectedTest(row)} /></td>
                                    <td>{getDateFormat(row.created_at)}</td>
                                    <td>{row.test_name}</td>
                                </tr>
                            ) : <tr>No Record Found</tr>}
                        </tbody>
                    </table>
                    <br />
                    {test_item && <div className='col-sm-24'>
                        <h5> Generate Report : {test_item.test_name} </h5>

                        {
                            !isEmpty(LabTest[25]) && LabTest[25].map(row =>
                                // !isEmpty(LabTest[test_item.test_id]) && LabTest[test_item.test_id].map(row =>
                                <FormGroup className="col-sm-12 float-left">
                                    <label>{row.text} {row.unit ? "(" + row.unit + ")" : ""}:</label>
                                    <FormControl
                                        name={row.parameter}
                                        type="text"
                                        autoComplete="none"
                                        onChange={this.handleChange}
                                    />
                                </FormGroup>
                            )
                        }
                    </div>}
                </form>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={this.props.handleModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={this.handleSubmit}>
                        Submit
                    </Button>
                </Modal.Footer>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
});

const mapActionsToProps = {
    showLoader: loaderAction.loaderShow,
    hideLoader: loaderAction.loaderHide
};

export default connect(mapStateToProps, mapActionsToProps)(GenerateLabReportModal);
