import * as Types from './actionTypes';
import UserManagementService from '../services/userManagementService';
import Notification from '../utils/notification';

export function getLocations(selected_user_profile) {
    return dispatch => {
        const { service_provider_id } = selected_user_profile
        dispatch({ type: Types.SERVICE_PROVIDER_LOCATION_REQUEST });
        UserManagementService.serviceProviderLocations(service_provider_id).then(resp => {
            dispatch({ type: Types.SERVICE_PROVIDER_LOCATION_SUCCESS, locations: resp.data });
        }).catch(error => {
            if (error.response && error.response.status) {
                Notification.show('error', error.response.data);
                dispatch({ type: Types.SERVICE_PROVIDER_LOCATION_FAILED, ...error.response });
            }
        });
    }
}