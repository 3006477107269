import React from 'react'
import QRCode from 'qrcode.react';
import { getPatientQrCodeUrl } from '../config/qr_code';

export class QrCodeGenerator extends React.PureComponent {
    qrCode;
    setRef = (ref) => (this.qrCode = ref);
  render() {
    return (
      <div ref={this.setRef} className="qrcode-container" style={{ textAlign: 'center' }}>
        <QRCode id="qr-gen" value={`${getPatientQrCodeUrl(process.env.REACT_APP_ENVIRONMENT)}/${this.props.serviceProviderId}/${this.props.userLocationId}`} size={512} includeMargin={true} />
        <h3>{`${getPatientQrCodeUrl(process.env.REACT_APP_ENVIRONMENT)}/${this.props.serviceProviderId}/${this.props.userLocationId}`}</h3>
      </div>
    );
  }
}