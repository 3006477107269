import React from 'react';
import OfflinePrescriptionService from '../../services/offlinePrescriptionService';
import _ from 'lodash';
import * as HELPER from '../../utils/helpers';
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';

class ViewOfflinePrescription extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            id: this.props.data.id,
        }
    }

    componentDidMount() {
        OfflinePrescriptionService.getofflinePresciptionById(this.props.match.params.id).then(resp => {
            this.setState({
                data: resp.data
            })
        }).catch(error => {
            Notification.show('error', error.response)
        })
    }

    render() {
        const data = this.state.data
        return (!_.isEmpty(data) && <React.Fragment>
            <Helmet>
                <title>View Prescription</title>
            </Helmet>
            <div className="row">
                <div className="col-md-24">
                    <h4 className="page-title no-heading">
                        <span className="prescription-details">Temporary Id: <i>{data && data.temporary_id}</i></span>
                        <span className="date">Date <i>{HELPER.getDateFormat(data.prescription_date)}</i></span>
                    </h4>

                    <div className="content-wrapper align-center">
                        <Link to={{
                            pathname: "/offline/prescriptions/" + this.props.match.params.id + "/orders/create",
                            data: data.offline_prescription_id // your data array of objects
                        }} className="btn btn-outline-primary">Create Rx</Link>
                    </div>


                    <div className="content-wrapper padding-bottom-10">
                        <h6>Patient Info</h6>
                        <ul className="prescription-patient-details">
                            <li>Patient ID: <span>{data && data.patient_id}</span></li>
                            <li>Name: <span>{data.patient_info && data.patient_info.patient_name}</span></li>
                            <li>Age: <span>{data.patient_info && data.patient_info.age}</span></li>
                            <li>Gender: <span>{data.patient_info && _.capitalize(data.patient_info.gender)}</span></li>
                            <li>Contact Number: <span>{data.patient_info && (data.patient_info.contact_number)}</span></li>
                        </ul>
                    </div>

                    <div className="content-wrapper padding-bottom-10">
                        <h6>Doctor Info</h6>
                        <ul className="prescription-patient-details">
                            <li>Doctor ID: <span>{data && data.doctor_id}</span></li>
                            <li>Name: <span>{data.doctor_info && data.doctor_info.doctor_name}</span></li>
                            {<li>Registration No.: <span>{data && data.doctor_info.license_no}</span></li>}
                            {<li>Contact Number: <span>{data && data.doctor_info.doctor_contact_number}</span></li>}
                            {<li>Email: <span>{data && data.doctor_info.email}</span></li>}
                        </ul>
                    </div>

                    <h4 className="page-title font-size-16">Prescribed Medicine</h4>
                    <div className="content-wrapper">
                        <table className="table table table-responsive common-table">
                            <thead>
                                <tr>
                                    <th>Brand Name</th>
                                    <th>Generic Name</th>
                                    <th>Frequency</th>
                                    <th>Meal</th>
                                    <th>Duration</th>
                                    <th>Dosage</th>
                                    <th>Substitution Not Allowed</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.prescription_info && data.prescription_info.map((medicine, idx) => (
                                    <React.Fragment>
                                        <tr>
                                            <td>{medicine.drug_name}</td>
                                            <td>{medicine.drug_generic_name ? medicine.drug_generic_name : '--'}</td>
                                            <td>{medicine.dosage ? HELPER.getDosageType(medicine.dosage) : '--'}</td>
                                            <td>{medicine.meal ? _.capitalize(medicine.meal) : '--'}</td>
                                            <td>{medicine.duration ? medicine.duration : ''}{medicine.duration_type ? HELPER.getDurationType(medicine.duration_type) : '--'}</td>
                                            <td>{medicine.quantity ? medicine.quantity : '--'}</td>
                                            <td>{medicine.is_generic_allowed ? "No" : "Yes"}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="7" className="text-left">
                                                <span className="text-primary">Instructions: </span><span>{medicine.remarks ? medicine.remarks : "--"}</span>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
        )

    }
}
const mapStateToProps = (state) => ({
    selected_user_profile: state.selected_user_profile,
    data: state.offline_prescription
});

const mapActionsToProps = ({})
export default connect(mapStateToProps, mapActionsToProps)(ViewOfflinePrescription)