import React from 'react';

export const NervousSystem = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <div className="heading">
            <h6>Nervous System</h6>
        </div>
        <div className="form-group full">
            <div className="options-details">
                {/* Headache */}
                <input type="checkbox" id='headaches' name='headaches' onChange={e => handleChange(e, 'nervous-system')} checked={checkIfProblemExist('nervous-system', 'headaches') ? 'checked' : ''} />
                <label htmlFor='headaches'>Headaches</label>
                {/* Dizziness */}
                <input type="checkbox" id='dizziness' name='dizziness' onChange={e => handleChange(e, 'nervous-system')} checked={checkIfProblemExist('nervous-system', 'dizziness') ? 'checked' : ''} />
                <label htmlFor='dizziness'>Dizziness</label>
                {/* Fainting */}
                <input type="checkbox" id='fainting-or-loss-of-consciousness' name='fainting-or-loss-of-consciousness' onChange={e => handleChange(e, 'nervous-system')} checked={checkIfProblemExist('nervous-system', 'fainting-or-loss-of-consciousness') ? 'checked' : ''} />
                <label htmlFor='fainting-or-loss-of-consciousness'>Fainting or loss of consciousness</label>
                {/* Numbness */}
                <input type="checkbox" id='numbness-or-tingling' name='numbness-or-tingling' onChange={e => handleChange(e, 'nervous-system')} checked={checkIfProblemExist('nervous-system', 'numbness-or-tingling') ? 'checked' : ''} />
                <label htmlFor='numbness-or-tingling'>Numbness or tingling</label>
                {/* Memory loss */}
                <input type="checkbox" id='memory-loss' name='memory-loss' onChange={e => handleChange(e, 'nervous-system')} checked={checkIfProblemExist('nervous-system', 'memory-loss') ? 'checked' : ''} />
                <label htmlFor='memory-loss'>Memory loss</label>
                {/* syncope */}
                <input type="checkbox" id='syncope' name='syncope' onChange={e => handleChange(e, 'nervous-system')} checked={checkIfProblemExist('nervous-system', 'syncope') ? 'checked' : ''} />
                <label htmlFor='syncope'>Syncope</label>
            </div>
        </div>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment }) => {
    return <React.Fragment>
        <label className="heading-comment">Comment </label>
        <textarea className="form-control" name="comments" placeholder="Type here..." onChange={e => handleChange(e, 'nervous-system')} value={getCategoryComment('nervous-system') ? getCategoryComment('nervous-system') : ''}></textarea>
    </React.Fragment>
}