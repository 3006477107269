import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Form, Row, Col } from 'react-bootstrap';
import Notification from '../../utils/notification';
import ProcedureService from '../../services/procedureService';
import SimpleReactValidator from 'simple-react-validator';

class ProcedureModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {            
            name: "",
            buttonText: "Submit",
            isSubmitDisabled: true,
            show: false,
            isloading: false,
            price_jmd: "0",
            price_usd: "0",
            price_cad: "0",
            price_gbp: "0",
            price_eur: "0"
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validator = new SimpleReactValidator();
    }
    componentDidMount () {
        this.setProcedure(this.props);
    }
    componentDidUpdate(prevProps) {
        if (this.props.procedure != prevProps.procedure) {
            this.setProcedure(this.props)
        }
    }
    setProcedure = (props) => {
        let procedure = props.procedure
        this.setState({  
            name: procedure ? procedure.name : "",
            buttonText: props.buttonText ? props.buttonText : "Submit",
            isSubmitDisabled: true,
            show: props.show ? props.show : false,
            price_jmd: procedure ? this.getPrice('JMD') : "0",
            price_usd: procedure ? this.getPrice('USD') : "0",
            price_cad: procedure ? this.getPrice('CAD') : "0",
            price_gbp: procedure ? this.getPrice('GBP') : "0",
            price_eur: procedure ? this.getPrice('EUR') : "0",
        });
    }

    getPrice = (currency) => {
        let row = this.props.procedure
        if(row.id){
            let data = row.prices.filter( (items) => items.currency.name === currency )
            return data[0] ? data[0].price : ''
        }
        return ''
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            isSubmitDisabled: false
        });
    }

    handleSubmit = (e) => {
        console.log('state=',this.state)
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        const data = {
            name: this.state.name ? this.state.name : null,
            price_jmd: this.state.price_jmd ? this.state.price_jmd : 0,
            price_usd: this.state.price_usd ? this.state.price_usd : 0,
            price_cad: this.state.price_cad ? this.state.price_cad : 0,
            price_gbp: this.state.price_gbp ? this.state.price_gbp : 0,
            price_eur: this.state.price_eur ? this.state.price_eur : 0,
        }
        this.setState({
            isloading: true
        });

        if(this.state.buttonText === "Update") {
            let message = 'Procedure Updated';

            ProcedureService.update(this.props.procedure.id, data).then(resp => {
                Notification.show('success', { message: message });
                this.props.onSuccess(this.props);
                this.clearState()
            }).catch(error => {
                this.setState({
                    isloading: false,
                    buttonText: 'Update'
                });
                Notification.show('error', error.response.data)
            })
        } else {
            let message = 'Procedure Added';
            ProcedureService.create(data).then(resp => {
                Notification.show('success', { message: message });
                this.props.onSuccess();
                this.clearState()
            }).catch(error => {
                this.setState({
                    isloading: false,
                    buttonText: 'Submit'
                });
                Notification.show('error', error.response.data)
            })
        }
    }

    handleClose = () => {
        this.props.handleClose()
        this.validator.hideMessages();
    }

    clearState = () => {
        this.setState({
            name: "",
            isloading: false,
            price_jmd: "0",
            price_usd: "0",
            price_cad: "0",
            price_gbp: "0",
            price_eur: "0",
        })
    }
    render() {
        return (
            <React.Fragment>
                {this.state.isloading && this.state.isloading && <div className="loading">Loading</div>}
                <Modal
                    size='md' centered aria-labelledby="contained-modal-title-vcenter"
                    show={this.props.show}
                    onHide={this.handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <h6>{this.state.buttonText === "Update" ? "Update" : "Add"} Procedure</h6>
                    </Modal.Header>
                    <Modal.Body>
                            <Row>
                                <Col md={24}>
                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                        <Form.Label column sm={8}>
                                        Procedure Name: *
                                        </Form.Label>
                                        <Col sm={16}>
                                        <Form.Control type="text" placeholder="Procedure Name" name="name" value={this.state.name} onChange={this.handleChange}/>
                                        {this.validator.message('Procedure Name', this.state.name, 'required|alpha_num_space|max:55')}
                                        </Col>
                                    </Form.Group>
                                </Col>

                                <Col md={24}>
                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                        <Form.Label column sm={8}>
                                        Cost (In JMD): *
                                        </Form.Label>
                                        <Col sm={16}>
                                        <Form.Control type="number" placeholder="Cost (In JMD)" name="price_jmd" value={this.state.price_jmd} onChange={this.handleChange}/>
                                        {this.validator.message('jmd Price', this.state.price_jmd, 'required|numeric|min:0,num|max:10')}
                                        </Col>
                                    </Form.Group>
                                </Col>

                                <Col md={24}>
                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                        <Form.Label column sm={8}>
                                        Cost (In USD): *
                                        </Form.Label>
                                        <Col sm={16}>
                                        <Form.Control type="number" placeholder="Cost (In USD)" name="price_usd" value={this.state.price_usd} onChange={this.handleChange}/>
                                        {this.validator.message('usd Price', this.state.price_usd, 'required|numeric|min:0,num|max:10')}
                                        </Col>
                                    </Form.Group>
                                </Col>

                                <Col md={24}>
                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                        <Form.Label column sm={8}>
                                        Cost (In CAD): *
                                        </Form.Label>
                                        <Col sm={16}>
                                        <Form.Control type="number" placeholder="Cost (In CAD)" name="price_cad" value={this.state.price_cad} onChange={this.handleChange}/>
                                        {this.validator.message('cad Price', this.state.price_cad, 'required|numeric|min:0,num|max:10')}
                                        </Col>
                                    </Form.Group>
                                </Col>

                                <Col md={24}>
                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                        <Form.Label column sm={8}>
                                        Cost (In GBP): *
                                        </Form.Label>
                                        <Col sm={16}>
                                        <Form.Control type="number" placeholder="Cost (In GBP)" name="price_gbp" value={this.state.price_gbp} onChange={this.handleChange}/>
                                        {this.validator.message('gbp Price', this.state.price_gbp, 'required|numeric|min:0,num|max:10')}
                                        </Col>
                                    </Form.Group>
                                </Col>

                                <Col md={24}>
                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                        <Form.Label column sm={8}>
                                        Cost (In EUR): *
                                        </Form.Label>
                                        <Col sm={16}>
                                        <Form.Control type="number" placeholder="Cost (In EUR)" name="price_eur" value={this.state.price_eur} onChange={this.handleChange}/>
                                        {this.validator.message('eur Price', this.state.price_eur, 'required|numeric|min:0,num|max:10')}
                                        </Col>
                                    </Form.Group>
                                </Col>
                            </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Cancel
                        </Button>
                        <Button variant="primary" disabled={this.state.isSubmitDisabled} onClick={this.handleSubmit}>
                            {this.state.buttonText}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        )
    }
}

export default ProcedureModal