import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as PrescriptionOrderAction from "../../actions/prescriptionOrderAction";
import DataGrid from "../../components/DataGrid";
import * as Helper from "../../utils/helpers";
import { hasRole } from '../../utils/auth';

class ReturnOrderList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: {
                status: 'cancelled',
            },
        }
    }
    componentDidMount() {
        this.props.paginate({
            sortBy: { sortField: "updated_at", sortOrder: "desc" },
            filter: this.state.filter
        });
    }
    columns = () => {
        return [
            {
                dataField: "order_id",
                text: "Rx Id",
                sort: true
            },
            {
                dataField: "prescription_id",
                text: "Prescription Id",
                sort: true
            },
            {
                dataField: "patient_full_name",
                text: "Patient Name",
                hidden: hasRole(this.props.selected_user_profile, ['pharmacy']),
                sort: false
            },
            {
                dataField: "order_date",
                text: "Rx Date",
                formatter: (cell) => {
                    return Helper.getDateFormat(cell);
                }
            },
            {
                dataField: "updated_at",
                text: "Returned On",
                formatter: (cell) => {
                    return Helper.getDateTimeFormat(cell);
                }
            },
            {
                dataField: "location_name",
                text: "Location",
                sort: false
            },
            {
                dataField: "status",
                text: "Status",
            },
            {
                dataField: "actions",
                text: "Actions",
                isDummyField: true,
                formatter: this.actionFormatter,
                hidden: hasRole(this.props.selected_user_profile, ['pharmacy', "pharmacy-manager"])
            }
        ];
    };
    actionFormatter = (_cell, row) => {
        return (
            <Link to={"/prescription/order/" + row.order_id}>
                <span className="icon file"></span>
            </Link>
        );
    };

    handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === "search") {
            page = 1;
        }
        this.props.paginate({ page, sizePerPage, sortBy: { sortField: "updated_at", sortOrder: "desc" }, searchText, filter: this.state.filter });
    };
    render() {
        let { data, meta } = this.props.return_orders_list;
        return (
            <React.Fragment>
                <Helmet>
                    <title>Returned Orders</title>
                </Helmet>
                <h4 className="page-title">Returned Orders</h4>
                <div className="content-wrapper">
                    {data && (
                        <DataGrid
                            columns={this.columns()}
                            data={data}
                            meta={meta}
                            handleTableChange={this.handleTableChange}
                            noDataIndication="No Record Found"
                            
                        />
                    )}
                </div>
            </React.Fragment>
        )
    }
}
function mapStateToProps(state) {
    return {
        return_orders_list: state.prescription_order.paginate,
        selected_user_profile: state.selected_user_profile
    };
}

const mapActionsToProps = {
    paginate: PrescriptionOrderAction.list
};

export default connect( mapStateToProps, mapActionsToProps )(ReturnOrderList);