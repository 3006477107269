import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import * as AuthAction from '../actions/authAction';
import * as NotificationAction from "../actions/notificationAction";
import UserNotificationService from '../services/userNotificationService';
import * as SelectedDoctorAction from '../actions/selectedDoctorAction';
import * as SelectedProfileAction from '../actions/selectedProfileAction';
import * as TogglePopupAction from '../actions/togglePopupAction';
import { capitalize, isEmpty, isNull, includes } from 'lodash';
import { handleRedirect, hasRole } from '../utils/auth';
import NotificationPopup from '../components/NotificationPopup';
import OtherProfileNotificationPopup from '../components/OtherProfileNotificationPopup';
import ProfileService from '../services/profileService';
import Notification from '../utils/notification';
import NotificationComponent from './Notification';
import { QR_CODE_ENABLE } from '../config/qr_code';
import { redirectToChat } from '../utils/helpers';

class Header extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showNotificationPopup: false,
            notificationData: null,
            showOtherProfileNotificationPopup: false,
            profileData: null,
            notification_count: ''
        }

        this.onLogout = this.onLogout.bind(this);
    }

    componentDidMount() {
        if (!isEmpty(this.props.selected_profile.service_provider)) {
            this.props.getNotificationCount();
            this.countInterval = setInterval(() => {
                this.props.getNotificationCount();
            }, 60000);
        }
    }

    componentWillUnmount() {
        clearInterval(this.countInterval);
        this.props.clearNotifications();
    }

    onLogout() {
        this.props.logout(this.props.history);
    }

    showSelectProfilePopup = () => {
        let { profileData } = this.state;
        if (isNull(profileData)) {
            ProfileService.listOfProfiles().then(resp => {
                profileData = resp.data.data;
                this.setState({ profileData });
                this.handleProfileData(profileData)
            }).catch(error => {
                Notification.show('error', error.response.data);
            })
        } else {
            this.handleProfileData(profileData)
        }
    }

    handleProfileData = (profileData) => {
        if (profileData.length === 1)
            Notification.show('info', { message: 'Sorry, you only have one profile.' })
        else if (profileData.length >= 2)
            this.props.openPopup('select_profile');
    }

    showUserNotifications = () => {
        this.props.paginate({
            sortBy: { sortField: "created_at", sortOrder: "desc" }
        });
    }

    handleReadAction = (data) => {
        if (data.user_profile_id === this.props.selected_profile.user_profile_id) {
            this.setState({ showNotificationPopup: true, notificationData: data });
        } else {
            let { profileData } = this.state;
            if (isNull(profileData)) {
                ProfileService.listOfProfiles().then(resp => {
                    profileData = resp.data.data;
                    this.setState({ showOtherProfileNotificationPopup: true, notificationData: data, profileData });
                }).catch(error => {
                    Notification.show('error', error.response.data);
                })
            } else {
                this.setState({ showOtherProfileNotificationPopup: true, notificationData: data });
            }
        }
    }

    shouldRedirect = (notificationData) => {
        this.handleCloseModal();
        this.sameProfileNotificationRedirect(notificationData);
    }

    sameProfileNotificationRedirect = (notificationData) => {
        UserNotificationService.readNotification(notificationData.id);

        if (notificationData.type === 'chat') {
            redirectToChat(notificationData.notify_by_user_profile_id);
        }else if (notificationData.type === 'prescription_refill_reminder') {
            this.props.history.replace("/reminders");
        } else if (this.props.selected_profile.role_slug === "pharmacist") {
            this.props.history.replace("/prescription/orders/queue");
        } else if (this.props.selected_profile.role_slug === "pharmacy-technician") {
            this.props.history.replace("/pharmacy/technician/prescription/list");
        } else if (notificationData.type === 'prescription_approval') {
            if (notificationData.payload.findIndex(d => d.label === "Urgent Care Id") > 0) {
                this.props.history.replace("/urgent-care");
            } else {
                if (this.props.selected_profile.role_slug !== "doctor") {
                    this.props.history.replace("/prescriptions");
                } else {
                    this.props.history.replace("/approve-prescription");
                }
            }
        } else if (notificationData.type === 'prescription_queue') {
            this.props.history.replace("/doctor-queues");
        } else if (notificationData.type === 'permission_request') {
            this.props.history.replace("/request-pending");
        } else if (notificationData.type === 'immunization' && this.props.selected_profile.role_slug === "doctor") {
            this.props.history.replace("/immunization-queues");
        } else if (notificationData.type === 'prescription_concern') {
            notificationData.payload.map((d) => {
                if (d.label === 'Prescription Id') {
                    this.props.history.replace("/prescriptions/" + d.value);
                }
                return true;
            });
        } else if (notificationData.type === 'appointment_cancel') {
            this.props.history.replace("/appointments");
        } else if(notificationData.type === "pathology_report_upload" || notificationData.type === "radiology_report_upload") {
            this.props.history.replace('/patients/' + notificationData.payload.patient_id)
        } else if(notificationData.type === 'patient_fees') {
            this.props.history.replace("/prescriptions/fees");
        }
    }


    handleCloseModal = () => {
        this.setState({ showNotificationPopup: false })
    }

    handleOtherProfileCloseModal = () => {
        this.setState({ showOtherProfileNotificationPopup: false })
    }

    shouldSwitchProfile = (_notificationData, user) => {
        this.props.switchProfile(user[0]);
        this.handleOtherProfileCloseModal();
    }

    render() {
        const { service_provider, user_location } = this.props.selected_profile;
        const { notification_count } = this.props.user_notification
        return (
            <header className="clearfix no-print">
                {this.props.selected_profile && <Link to={handleRedirect(this.props.selected_profile)}><img src="/images/daisy-logo.png" alt="img" /></Link>}
                <ul className="clearfix">
                    {service_provider && <li className="group-details">
                        <a href data-index="14" data-tooltip="You can select the work profile here." onClick={this.showSelectProfilePopup} data-modal-id="select-user">
                            {service_provider && service_provider.name ? capitalize(service_provider.name) : '&nbps;'}
                            <span>{user_location && user_location.name ? capitalize(user_location.name) : '&nbps;'}</span>
                        </a>
                    </li>}
                    <li className="help"><a href ><span className="sprite-icon help"></span></a>
                    </li>
                    {this.props.selected_profile.role_slug !== "covid-center-owner" && <li className="notifications">
                        <a href data-index="15" data-tooltip="Here, it will display all the notification from the technician and message from the doctor" onClick={this.showUserNotifications}>
                            <span className="sprite-icon notification-icon">
                                {notification_count > 0 && <span className="badge badge-primary">{notification_count}</span>}
                            </span>
                        </a>
                        <div className="dr-approval-pending">
                            {!isEmpty(this.props.user_notification.request) && isEmpty(this.props.user_notification.data) && <div className="content-wrapper"><span className="no-notification-tag"> No notifications. </span></div>}
                            {!isEmpty(this.props.user_notification.data) &&
                                <React.Fragment>
                                    <div className="content-wrapper">
                                        {this.props.user_notification.data.map((notification) => (
                                            <React.Fragment>
                                                <NotificationComponent notification={notification}
                                                    handleReadAction={this.handleReadAction} />
                                                <NotificationPopup showModal={this.state.showNotificationPopup} handleClose={this.handleCloseModal} shouldRedirect={this.shouldRedirect} notificationData={this.state.notificationData} />

                                                <OtherProfileNotificationPopup showModal={this.state.showOtherProfileNotificationPopup} handleClose={this.handleOtherProfileCloseModal} shouldSwitchProfile={this.shouldSwitchProfile} notificationData={this.state.notificationData} profileData={this.state.profileData} />
                                            </React.Fragment>
                                        ))}
                                    </div>
                                    {this.props.user_notification.meta.total > 10 && <div className="fixed"><Link to={{ pathname: "/notification-list" }} className="notification-more" target="_blank">See All</Link></div>}
                                </React.Fragment>
                            }
                        </div>
                    </li>}
                    <li className="setting"><a href><span className="sprite-icon settings"></span></a>
                        <div className="dropdown-content">
                            <ul>
                                <li><Link to={{
                                    pathname: "/edit-profile",
                                }} className="icon avatar"><span>Profile</span></Link></li>
                                {hasRole(this.props.selected_profile, ['doctor', 'associate-doctor', 'doctor-nurse']) &&
                                <li>
                                    <Link className="icon avatar" onClick={ e => redirectToChat()}><span>Chat</span></Link>
                                </li>}
                                <li>
                                    <Link to={{
                                        pathname: "/change-password",
                                    }} className="icon password"><span>Change Password</span></Link>
                                </li>
                                {
                                    !isEmpty(service_provider) ? QR_CODE_ENABLE === true &&  includes(['doctor', 'covid-center'], service_provider.service_provider_type) &&<li>
                                    <Link to={{
                                        pathname: "/qrcode",
                                    }} className="icon avatar"><span>QR Code</span></Link>
                                </li> : ''
                                }
                                <li><a href onClick={this.onLogout} className="icon logout"><span className="sign-out">Sign Out</span></a></li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </header>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.session.user,
    selected_profile: state.selected_user_profile,
    user_notification: state.user_notification
});

const mapActionsToProps = ({
    logout: AuthAction.logout,
    selectDoctor: SelectedDoctorAction.select,
    openPopup: TogglePopupAction.openPopup,
    paginate: NotificationAction.userNotification,
    clearNotifications: NotificationAction.clearNotifications,
    switchProfile: SelectedProfileAction.switchProfile,
    getNotificationCount: NotificationAction.getNotificationCount,
})

export default connect(mapStateToProps, mapActionsToProps)(Header);
