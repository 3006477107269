import axios from 'axios';
import { apiRoute, getApiHeader } from '../utils/helpers';
import * as encryptUtils from '../utils/encrypt';

class UserSignatureService {
    static verifyPin(pin, id, type) {
        const requestOptions = {
            headers: getApiHeader()
        };

        const body = JSON.stringify({
            id: id, 
            pin: encryptUtils.encryptServer(pin) 
        });

        return axios.post(apiRoute("/v1/"+type+"/add-signature/"), body, requestOptions);
    }
}


export default UserSignatureService;
