import React from 'react';
import { Tab, Tabs } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import { capitalize } from 'lodash';
import { getAgeString, getDateFormat } from '../../utils/helpers';
import VitalComponent from '../../components/Prescription/Vital/AddVital';
import HistoryComponent from '../../components/Prescription/History/AddHistoryAccordion';
import PresentConditionComponent from '../../components/Prescription/PresentingCondition/AddPresentingCondition';
import AssessmentComponent from '../../components/Prescription/Assessment/AddAssessment';
import RecommendationComponent from '../../components/Prescription/Recommendation/AddRecommendation';
import SimpleReactValidator from 'simple-react-validator';
import { connect } from 'react-redux';
import ExaminationComponent from '../../components/Prescription/Examination/AddExamination';
import * as LoaderAction from '../../actions/loaderAction';
import FreeSelectModal from '../../components/FreeSelectModal';
import * as HospitalAction from '../../actions/hospitalAction'

class AddRecordPrescriptionPage extends React.Component {
    constructor(props) {
        super(props);
        const params = this.props.match.params;
        this.state = {
            patient: {},
            currentKey: 1,
            prescriptionId: params.prescriptionId,
            isEdited: false,
            editedSection: '',
            showFreeSelectModal: false,
            selectedKey: null,
            recordId: params.recordId,
        }
        this.validator1 = new SimpleReactValidator();
        this.vitalRef = React.createRef();
        this.historyRef = React.createRef();
        this.presentConditionRef = React.createRef();
        this.assessmentRef = React.createRef();
        this.recommendationRef = React.createRef();
        this.bodyChartRef = React.createRef();
        this.examinationRef = React.createRef();
    }

    componentDidMount() {
        const params = this.props.match.params;
        this.props.getRecord(params.recordId, { log: true });
    }
    componentWillReceiveProps(props) {
        this.setState({
            patientId: props.ambulance.data.patient_id,
            patient: props.ambulance.data.patient_info,
        })
    }
    handlePrevious = () => {
        this.setState((prevState, _props) => {
            return { currentKey: prevState.currentKey - 1 }
        })
    }
    handleSaveAndNext = () => {
        this.setState((prevState, _props) => {
            return { currentKey: (this.state.selectedKey ? parseInt(this.state.selectedKey) : prevState.currentKey + 1), selectedKey: '', isEdited: false }
        })
    }
    handleRedirect = () => {
        this.props.history.push('/patients/search');
    }
    _onSelect = (selectedKey) => {
        this.state.isEdited ? this.setState({ showFreeSelectModal: true, selectedKey }) : this.setState({ currentKey: parseInt(selectedKey), selectedKey: '' })
    };

    handleIsPrescriptionEdited = (editedSection) => this.setState({ editedSection, isEdited: true });

    handleFreeSelect = (evt, type = null) => {
        let { selectedKey } = this.state
        if (type === 'save') {
            this.state.editedSection === 'vital' && this.vitalRef.current.handleVitalSaveAndNext(evt, 'save');
            this.state.editedSection === 'history' && this.historyRef.current.handleHistorySaveAndNext(evt, 'save');
            this.state.editedSection === 'present_condition' && this.presentConditionRef.current.handlePresentConditionSaveAndNext(evt, 'save');
            this.state.editedSection === 'examination' && this.examinationRef.current.handleExaminationSaveAndExit(evt, 'save');
            this.state.editedSection === 'assessment' && this.assessmentRef.current.handleAssessmentSaveAndNext(evt, 'save');
            this.state.editedSection === 'recommendation' && this.recommendationRef.current.handleRecommendationSave(evt, 'save');
        }
        if (type === 'cancel') selectedKey = '';
        this.setState({ showFreeSelectModal: false, selectedKey });
    }

    render() {
        let { data } = this.props.ambulance;
        return (<React.Fragment >
            <Helmet>
                <title>Discharge Summary</title>
            </Helmet>
            <section className="container-fluid">
                <div className="row">
                    <div className="col-md-12 requester-tabs">
                        <h4 className="page-title">Discharge Summary</h4>
                    </div>
                    {data.patient_info && <div className="content-wrapper padding-bottom-10">
                        <h6>Personal Info</h6>
                        <ul className="prescription-patient-details">
                            <li>Name: <span>{data.patient_info.full_name}</span></li>
                            <li>Date of Birth: <span>{data.patient_info.date_of_birth && getDateFormat(data.patient_info.date_of_birth)} ({getAgeString(data.patient_info.date_of_birth)})</span></li>
                            <li>Gender: <span>{capitalize(data.patient_info.gender)}</span></li>
                        </ul>
                    </div>}
                    <div className="col-md-24 mt-3 request-pending">
                        <Tabs activeKey={this.state.currentKey} onSelect={this._onSelect}>
                            <Tab eventKey={1} title="Vitals">
                                {this.state.currentKey === 1 && <VitalComponent
                                    selected_user_profile={this.props.selected_user_profile}
                                    showLoader={this.props.showLoader}
                                    hideLoader={this.props.hideLoader}
                                    handleSaveAndNext={this.handleSaveAndNext}
                                    prescriptionId={this.state.prescriptionId}
                                    patientId={this.state.patientId}
                                    handleRedirect={this.handleRedirect}
                                    handleIsPrescriptionEdited={this.handleIsPrescriptionEdited}
                                    ref={this.vitalRef}
                                />}
                            </Tab>
                            <Tab eventKey={2} title="History">
                                {this.state.currentKey === 2 && <HistoryComponent
                                    patient_gender={this.state.patient.gender}
                                    prescriptionId={this.state.prescriptionId}
                                    selected_user_profile={this.props.selected_user_profile}
                                    showLoader={this.props.showLoader}
                                    hideLoader={this.props.hideLoader}
                                    handleSaveAndNext={this.handleSaveAndNext}
                                    patientId={this.state.patientId}
                                    handlePrevious={this.handlePrevious}
                                    handleIsPrescriptionEdited={this.handleIsPrescriptionEdited}
                                    ref={this.historyRef}
                                />}
                            </Tab>
                            <Tab eventKey={3} title="Presenting Condition">
                                {this.state.currentKey === 3 && <PresentConditionComponent
                                    prescriptionId={this.state.prescriptionId}
                                    validator={this.validator1}
                                    patientId={this.state.patientId}
                                    handlePrevious={this.handlePrevious}
                                    showLoader={this.props.showLoader}
                                    hideLoader={this.props.hideLoader}
                                    handleSaveAndNext={this.handleSaveAndNext}
                                    handleIsPrescriptionEdited={this.handleIsPrescriptionEdited}
                                    ref={this.presentConditionRef}
                                />}
                            </Tab>
                            <Tab eventKey={4} title="Examination">
                                {this.state.currentKey === 4 && <ExaminationComponent
                                    prescriptionId={this.state.prescriptionId}
                                    validator={this.validator1}
                                    patientId={this.state.patientId}
                                    handlePrevious={this.handlePrevious}
                                    showLoader={this.props.showLoader}
                                    hideLoader={this.props.hideLoader}
                                    handleSaveAndNext={this.handleSaveAndNext}
                                    handleRedirect={this.handleRedirect}
                                    selected_user_profile={this.props.selected_user_profile}
                                    handleIsPrescriptionEdited={this.handleIsPrescriptionEdited}
                                    ref={this.examinationRef}
                                />}
                            </Tab>
                            <Tab eventKey={5} title="Assessment">
                                {this.state.currentKey === 5 && <AssessmentComponent
                                    prescriptionId={this.state.prescriptionId}
                                    validator={this.validator1}
                                    patientId={this.state.patientId}
                                    handlePrevious={this.handlePrevious}
                                    showLoader={this.props.showLoader}
                                    hideLoader={this.props.hideLoader}
                                    handleSaveAndNext={this.handleSaveAndNext}
                                    selected_user_profile={this.props.selected_user_profile}
                                    handleIsPrescriptionEdited={this.handleIsPrescriptionEdited}
                                    ref={this.assessmentRef}
                                />}
                            </Tab>
                            <Tab eventKey={6} title="Prescription and Referral">
                                {this.state.currentKey === 6 && <RecommendationComponent
                                    prescriptionId={this.state.prescriptionId}
                                    patientId={this.state.patientId}
                                    handlePrevious={this.handlePrevious}
                                    showLoader={this.props.showLoader}
                                    hideLoader={this.props.hideLoader}
                                    handleSaveAndNext={this.handleSaveAndNext}
                                    selected_user_profile={this.props.selected_user_profile}
                                    history={this.props.history}
                                    handleIsPrescriptionEdited={this.handleIsPrescriptionEdited}
                                    ref={this.recommendationRef}
                                    recordId={this.state.recordId}
                                />}
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </section>
            {this.state.showFreeSelectModal &&
                <FreeSelectModal
                    showModal={this.state.showFreeSelectModal}
                    handleModal={this.handleFreeSelect} />}
        </React.Fragment >)
    }
}
const mapStateToProps = (state) => ({
    selected_user_profile: state.selected_user_profile,
    ambulance: state.ambulance_fetch
});
const mapActionsToProps = {
    hideLoader: LoaderAction.loaderHide,
    showLoader: LoaderAction.loaderShow,
    getRecord: HospitalAction.get,
};
export default connect(mapStateToProps, mapActionsToProps)(AddRecordPrescriptionPage)