import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import * as PatientAction from '../../actions/patientAction';
import { Helmet } from 'react-helmet';
import { hasRole } from '../../utils/auth';
import DoctorAddQueueButton from '../../components/DoctorAddQueueButton';
import Alert from '../../components/Alert';
import * as Helper from "../../utils/helpers";
import * as PrescriptionAction from "../../actions/prescriptionAction";
import * as PathoRadioAction from "../../actions/PathoRadioAction";
import DataGrid from "../../components/DataGrid";
import _, { now } from "lodash";
import { Tab, Tabs } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";
import PathoRadioService from '../../services/pathoRadioService';
import PrescriptionService from '../../services/prescriptionService';
import UrgentCareService from '../../services/urgentCareService';
import Notification from "../../utils/notification";
import * as Config from '../../config';
import ScheduleAppointmentModal from '../../components/Slot/ScheduleAppointmentModal';
import * as loaderAction from '../../actions/loaderAction';
import AppointmentService from '../../services/appointmentService';
import AttachHistoricDataModal from '../../components/AttachHistoricDataModal';
import Profile from '../../components/Patient/Profile'
import PatientPathologyTestTab from '../../components/PatientPathologyTestTab';
import PatientRadiologyTestTab from '../../components/PatientRadiologyTestTab';
import PatientCollectFeeButton from '../../components/PatientCollectFeeButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import DocketNumberModal from '../../components/DocketNumberModal';

const AddUrgentCareButton = ({ patientId, createDraftUrgentCare }) => {
  return <Link className="btn btn-primary ml-2" onClick={() => createDraftUrgentCare(patientId)}>
    Urgent Care
  </Link>
}

const AddCertificateButton = ({ patientId }) => {
  return <Link className="btn btn-outline-primary ml-2" to={'/patients/' + patientId + '/medical-certificate'}>
    Certificate
  </Link>
}

const AddPrescriptionButton = ({ patientId, createDraftPrescription }) => {
  return <Link className="btn btn-primary ml-2" onClick={() => createDraftPrescription(patientId)}>
    Add Encounter
  </Link>
}

const ImmunizationCardButton = ({ patientId }) => {
  return <Link to={"/patients/" + patientId + "/immunizations/view"} className="btn btn-info ml-2">
    Immunization Card
  </Link>
}

const ScheduledAppointmentButton = ({ handleModalShowHide }) => {
  return <Link to="#" className="btn btn-outline-primary ml-2" onClick={handleModalShowHide}>Schedule Appointment </Link>
}

const DateFilter = ({ filter, handleEvent }) => {
  return <li>
    <span>Date</span>
    <DateRangePicker
      startDate={filter.show_start_date}
      onApply={handleEvent}
      endDate={filter.show_end_date}
    >
      <input
        type="text"
        className="search-input"
        defaultValue={filter.start_date ? moment(filter.start_date).format('DD-MM-YYYY') : ''}
        name="start_date"
        autoComplete="off"
      ></input>
      <input
        type="text"
        className="search-input"
        defaultValue={filter.end_date ? moment(filter.end_date).format('DD-MM-YYYY') : ''}
        name="end_date"
        autoComplete="off"
      ></input>
    </DateRangePicker>
  </li>
}

const PrescriptionIdFilter = ({ filter, handleChange }) => {
  return <React.Fragment>
    <span>Prescription Id</span>
    <input
      type="text"
      value={filter.prescription_id}
      onChange={handleChange}
      name="prescription_id"
    ></input>
  </React.Fragment>
}
class ShowPatientPage extends React.Component {
  constructor(props) {
    super(props);

    const params = this.props.match.params;
    this.props.getPatient(params.patientId, { log: true });

    this.state = {
      history_data_modal: false,
      all_checked: false,
      disabled: true,
      requestPermissionId: [],
      patientId: params.patientId,
      tab_status: 'prescription',
      showHide: false,
      filter: {
        prescription_id: "",
        start_date: "",
        end_date: "",
        show_start_date: new Date(),
        show_end_date: new Date(),
        prescription_created: 'own',
        status: 'prescribed,partially-fulfilled,fulfilled',
        prescription_type: 'doctor-prescription',
      },
      tags: {
        prescription_id: "",
      },
      showDocketNumber: false,
      showEditModal: false,
      showAddModal: false,
      showCollectFeeModal: this.props.location.state ? this.props.location.state.showCollectFeeModal : false,
    };
    this.validator = new SimpleReactValidator();
  }
  shouldComponentUpdate(nextProps) {
    const { patient } = this.props;
    if (_.isEmpty(patient) && !_.isEmpty(nextProps.patient) && !nextProps.patient.is_profile_complete) {
      this.props.history.push('/patient/' + this.state.patientId + '/edit');
    }
    return true;
  }
  componentDidMount() {
    if (hasRole(this.props.selected_user_profile, ["doctor", "associate-doctor", "paramedic-doctor"])) {
      this.props.getPrescriptions(
        {
          sortBy: { sortField: "updated_at", sortOrder: "desc" },
          filter: {
            prescription_created: 'own',
            status: 'prescribed,partially-fulfilled,fulfilled'
          }
        },
        this.state.patientId, this.state.tab_status
      );
    }

    if (hasRole(this.props.selected_user_profile, ["doctor-nurse"])) {
      this.setState({ tab_status: 'medical-pathology-test' })
    }
  }
  handleModalShowHide = () => {
    this.setState({ showHide: !this.state.showHide })
  }

  firstColumns = () => {
    if (hasRole(this.props.selected_user_profile, ["paramedic-doctor"])) {
      return [
        {
          dataField: "prescription_id",
          text: "Encounter Id",
          // sort: true
        },
        {
          dataField: "prescription_date",
          text: "Created Date",
          // sort: true,
          formatter: this.dateFormatter
        },
        {
          dataField: "actions",
          text: "Actions",
          isDummyField: true,
          formatter: this.firstActionFormatter
        }
      ];
    } else {
      return [
        {
          dataField: "prescription_id",
          text: "Encounter Id",
          // sort: true
        },
        {
          dataField: "prescription_date",
          text: "Created Date",
          // sort: true,
          formatter: this.dateFormatter
        },
        {
          dataField: "updated_at",
          text: "Updated Date",
          // sort: true,
          formatter: this.dateFormatter
        }, {
          dataField: "prescription_medicine_status",
          text: "Medicine Status",
          sort: false,
          formatter: this.statusFormatter
        },
        {
          dataField: "prescription_pathology_test_status",
          text: "Pathology Test Status",
          sort: false,
          formatter: this.statusFormatter
        }, {
          dataField: "prescription_radiology_test_status",
          text: "Radiology Test Status",
          sort: false,
          formatter: this.statusFormatter
        },
        {
          dataField: "actions",
          text: "Actions",
          isDummyField: true,
          formatter: this.firstActionFormatter
        }
      ];
    }
  };

  secondColumns = () => {
    return [
      {
        dataField: "access",
        formatter: this.checkBoxFormatter
      },
      {
        dataField: "prescription_id",
        text: "Prescription Id",
        // sort: true
      },
      {
        dataField: "user_profile_info.full_name",
        text: "Doctor Name",
        sort: false
      },
      {
        dataField: "user_profile_info.specialization",
        text: "Specialization",
        sort: false,
      },
      {
        dataField: "prescription_date",
        text: "Created Date",
        // sort: true,
        formatter: this.dateFormatter
      },
      {
        dataField: "updated_at",
        text: "Updated Date",
        // sort: true,
        formatter: this.dateFormatter
      },
      {
        dataField: "actions",
        text: "Actions",
        isDummyField: true,
        formatter: this.secondActionFormatter
      }
    ];
  };

  documentColumns = () => {
    return [
      {
        dataField: "document_name",
        text: "File Name",
      },
      {
        dataField: "test_name",
        text: "Test Name",
      },
      {
        dataField: "created_at",
        text: "Date",
        // sort: true,
        formatter: this.dateFormatter
      },
      {
        dataField: "actions",
        text: "Actions",
        isDummyField: true,
        formatter: this.downloadFileActionFormatter
      }
    ];
  }
  emergencyPrescriptionColumns = () => {
    return [
      {
        dataField: "prescription_id",
        text: "Prescription Id",
        // sort: true
      },
      {
        dataField: "user_profile_info.full_name",
        text: "Pharmacist Name",
        sort: false
      },
      {
        dataField: "prescription_date",
        text: "Prescription Date",
        // sort: true,
        formatter: this.dateFormatter
      },
      {
        dataField: "actions",
        text: "Actions",
        isDummyField: true,
        formatter: this.emergencyPrescriptionActionFormatter
      }
    ];
  };
  statusFormatter = (row) => {
    return Helper.getStatus(row);
  }

  downloadFileActionFormatter = (cell, row) => {
    return (
      <a href onClick={this.props.documentDownload.bind(this, row.document_id)} title="View in New Tab">
        <FontAwesomeIcon icon={faExternalLinkAlt} className="c-mb-2"/>
      </a>
    );
  }

  handlePathologyTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
    if (type === "search") {
      page = 1;
    }

    PathoRadioService.listPathologyDocuments({ page, sizePerPage, sortBy: { sortField, sortOrder }, }, this.state.patientId).then(resp => {
      this.setState({
        documents: resp.data
      })
    }).catch(err => {
      Notification.show('error', err.response.data)
    });
  };

  handleRadiologyTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (type === "search") {
      page = 1;
    }

    PathoRadioService.listRadiologyDocuments({ page, sizePerPage, sortBy: { sortField, sortOrder } }, this.state.patientId).then(resp => {
      this.setState({
        documents: resp.data
      })
    }).catch(err => {
      Notification.show('error', err.response.data)
    });
  };

  handleTableChangefirst = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (type === "search") {
      page = 1;
    }

    this.props.getPrescriptions({ page, sizePerPage, sortBy: { sortField, sortOrder }, filter: this.state.filter }, this.state.patientId, this.state.tab_status);
  };

  handleTableChangeSecond = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (type === "search") {
      page = 1;
    }

    this.props.getPrescriptions({ page, sizePerPage, sortBy: { sortField, sortOrder }, filter: this.state.filter }, this.state.patientId, this.state.tab_status);
  };
  handleTableChangeEmergencyPrescription = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (type === "search") {
      page = 1;
    }

    this.props.getPrescriptions({ page, sizePerPage, sortBy: { sortField, sortOrder }, filter: this.state.filter }, this.state.patientId, this.state.tab_status);
  };
  dateFormatter = (cell, row) => {
    return Helper.getDateFormat(cell);
  };

  checkBoxFormatter = (cell, row) => {
    if (cell !== 'allowed') {
      return (
        <React.Fragment>
          <input type="checkbox" id={"checkbox" + row.prescription_id} name="request_prescription_id" value={row.prescription_id} onClick={this.handleCheckboxChange} checked={this.state.requestPermissionId.includes(row.prescription_id) ? true : false} />
          <label htmlFor={"checkbox" + row.prescription_id}></label>
        </React.Fragment>);
    }
  }

  firstActionFormatter = (cell, row) => {
    return (
      <React.Fragment>
        <Link to={"/prescriptions/" + row.prescription_id} title="View">
          <span className="icon file"></span>
        </Link>
        <Link to={"/prescriptions/" + row.prescription_id} target="_blank" title="View in New Tab">
          <FontAwesomeIcon icon={faExternalLinkAlt} className="c-mb-2"/>
        </Link>
      </React.Fragment>
    );
  };

  secondActionFormatter = (cell, row) => {
    if (row.access === 'denied') {
      return <Link className="icon readonly">Locked</Link>;
    } else if (row.access === 'requested') {
      return <Link className="icon request-pending">Requested</Link>;
    } else if (row.access === 'partially-allowed') {
      return <Link to={'/prescriptions/' + row.prescription_id} title="View"><span className="icon file">Partial View</span></Link>;
    } else if (row.access === 'allowed') {
      return <>
        <Link to={'/prescriptions/' + row.prescription_id} title="View"><span className="icon file"></span></Link>
        <Link to={"/prescriptions/" + row.prescription_id} target="_blank" title="View in New Tab">
          <FontAwesomeIcon icon={faExternalLinkAlt} className="c-mb-2"/>
        </Link>
      </>
    }
  };
  emergencyPrescriptionActionFormatter = (cell, row) => {
    return (
      <React.Fragment>
        <Link to={'/doctor/prescriptions/' + row.prescription_id + '/view'} title="View">
          <span className="icon file"></span>
        </Link>
        <Link to={"/doctor/prescriptions/" + row.prescription_id + '/view'} target="_blank" title="View in New Tab">
          <FontAwesomeIcon icon={faExternalLinkAlt} className="c-mb-2"/>
        </Link>
      </React.Fragment>
    );
  };
  handleChange = e => {
    if (e.target.name === 'check_all_prescription') {
      if (e.target.checked) {
        let ids = [];
        this.setState({
          all_checked: true,
          requestPermissionId: []
        });
        this.props.prescriptions.data.forEach((prescription, idx) => {
          if (prescription.access !== 'allowed') {
            ids.push(prescription.prescription_id);
          }
        });
        this.setState({
          requestPermissionId: ids
        });
      } else {
        this.setState({
          all_checked: false,
          requestPermissionId: []
        });
      }
    }
    const filter = this.state.filter;
    filter[e.target.name] = e.target.value ? e.target.value : undefined;
    this.setState({ filter });
  };

  handleCheckboxChange = e => {

    if (e.target.name === 'request_prescription_id') {
      if (e.target.checked) {
        this.setState({
          all_checked: false,
          disabled: false,
          requestPermissionId: this.state.requestPermissionId.concat(e.target.value)
        });
      } else {
        const requestPermissionId = this.state.requestPermissionId.filter(function (item) {
          return item.search(e.target.value) === -1;
        });
        this.setState({ all_checked: false, requestPermissionId: requestPermissionId });
        if (_.isEmpty(requestPermissionId)) {
          this.setState({
            disabled: true
          });
        }
      }
    }
  }

  createDraftPrescription = (patientId) => {
    PrescriptionService.createDraftPrescription({ "patient_id": patientId }).then(resp => {
      this.props.history.push("/patients/" + patientId + "/prescriptions/" + resp.data.prescription_id)
    }).catch(error => {
      Notification.show('error', error.response.data)
    })
  }

  createDraftUrgentCare = (patientId) => {
    UrgentCareService.createDraft({ "patient_id": patientId }).then(resp => {
      this.props.history.push("/patients/" + patientId + "/urgent-care/" + resp.data.prescription_id)
    }).catch(error => {
      Notification.show('error', error.response.data)
    })
  }

  actionBlock = (selected_user_profile, patientId) => {
    if (hasRole(selected_user_profile, ["doctor-receptionist"])) {
      return (
        <React.Fragment>
          <DoctorAddQueueButton patientId={patientId} history={this.props.history} />
          <ScheduledAppointmentButton handleModalShowHide={this.handleModalShowHide} />
          {this.hideCollectFee() &&
            <PatientCollectFeeButton
              patientId={patientId}
              title="Collect Fee"
              selected_user_profile={selected_user_profile}
              showCollectFeeModal={this.state.showCollectFeeModal} />}
        </React.Fragment>
      );
    }

    if (hasRole(selected_user_profile, ["doctor", "associate-doctor"])) {
      return (
        <React.Fragment>
          <AddCertificateButton patientId={patientId} />
          <AddPrescriptionButton patientId={patientId} createDraftPrescription={this.createDraftPrescription} />
          <ImmunizationCardButton patientId={patientId} />
          <ScheduledAppointmentButton handleModalShowHide={this.handleModalShowHide} />
          <AddUrgentCareButton patientId={patientId} createDraftUrgentCare={this.createDraftUrgentCare} />
          {/* fee collection */}
          {hasRole(this.props.selected_user_profile, ['doctor', "associate-doctor"]) && this.hideCollectFee() &&
            <PatientCollectFeeButton
              patientId={patientId}
              title="Collect Fee"
              selected_user_profile={selected_user_profile}
              showCollectFeeModal={this.state.showCollectFeeModal} />}
        </React.Fragment>
      );
    }

    if (hasRole(selected_user_profile, ["junior-doctor", "doctor-nurse"])) {
      return (
        <React.Fragment>
          <DoctorAddQueueButton patientId={patientId} history={this.props.history} />
          <AddPrescriptionButton patientId={patientId} createDraftPrescription={this.createDraftPrescription} />
          <ImmunizationCardButton patientId={patientId} />
          {hasRole(selected_user_profile, ["doctor-nurse"]) && <ScheduledAppointmentButton handleModalShowHide={this.handleModalShowHide} />}
          {hasRole(this.props.selected_user_profile, ["doctor-nurse"]) && this.hideCollectFee() &&
            <PatientCollectFeeButton
              patientId={patientId}
              title="Collect Fee"
              selected_user_profile={selected_user_profile}
              showCollectFeeModal={this.state.showCollectFeeModal} />}
        </React.Fragment>
      );
    }

    if (hasRole(selected_user_profile, ["medical-practice-manager"])) {
      return (
        <React.Fragment>
          <DoctorAddQueueButton patientId={patientId} history={this.props.history} />
          <ScheduledAppointmentButton handleModalShowHide={this.handleModalShowHide}/>
        </React.Fragment>
      );
    }
    return "";
  };

  removeFilter = (e) => {

    const filter = this.state.filter;
    const tags = this.state.tags;

    if (e.target.dataset.tag === 'date_filter') {
      filter["start_date"] = '';
      filter["end_date"] = '';
    } else {
      filter[e.target.dataset.tag] = '';
    }
    tags[e.target.dataset.tag] = '';

    this.setState({ filter: filter, tags: tags });

    this.props.paginate({ filter: this.state.filter });
  }

  fetchPathologyDocuments = () => {
    this.props.showLoader()
    PathoRadioService.listPathologyDocuments({}, this.state.patientId).then(resp => {
      this.setState({
        documents: resp.data,
        tab_status: "medical-pathology-test"
      })
      this.props.hideLoader()
    });
  }

  fetchRadiologyDocuments = () => {
    this.props.showLoader()
    PathoRadioService.listRadiologyDocuments({}, this.state.patientId).then(resp => {
      this.setState({
        documents: resp.data,
        tab_status: "medical-radiology-test",
      })
      this.props.hideLoader()
    })
  }

  listPrescription = key => {
    if (key === "medical-pathology-test") {
      this.fetchPathologyDocuments();
    }
    if (key === "medical-radiology-test") {
      this.fetchRadiologyDocuments();
    }
    if (key === "other-prescription") {
      this.setState({
        tab_status: "other-prescription",
        tags: {
          prescription_id: "",
          date_filter: ""
        },
        filter: {
          prescription_id: "",
          start_date: "",
          end_date: "",
          show_start_date: new Date(),
          show_end_date: new Date(),
          prescription_created: 'other',
          status: 'prescribed,partially-fulfilled,fulfilled',
          prescription_type: 'doctor-prescription',
        }
      });
      this.props.getPrescriptions(
        {
          sortBy: { sortField: "updated_at", sortOrder: "desc" },
          filter: {
            prescription_created: 'other',
            status: 'prescribed,partially-fulfilled,fulfilled',
            prescription_type: 'doctor-prescription',
          }
        },
        this.state.patientId, key
      );
    } else if (key === "prescription") {
      this.setState({
        tab_status: "prescription",
        tags: {
          prescription_id: "",
          date_filter: ""
        },
        filter: {
          prescription_id: "",
          start_date: "",
          end_date: "",
          show_start_date: new Date(),
          show_end_date: new Date(),
          prescription_created: 'own',
          prescription_type: 'doctor-prescription',
          status: 'prescribed,partially-fulfilled,fulfilled',
        }
      });
      this.props.getPrescriptions(
        {
          sortBy: { sortField: "updated_at", sortOrder: "desc" },
          filter: {
            prescription_created: 'own',
            status: 'prescribed,partially-fulfilled,fulfilled',
            prescription_type: 'doctor-prescription',
          }
        },
        this.state.patientId, key
      );
    } else if (key === "emergency-prescription") {
      this.setState({
        tab_status: "emergency-prescription",
        tags: {
          prescription_id: "",
          date_filter: ""
        },
        filter: {
          prescription_id: "",
          start_date: "",
          end_date: "",
          show_start_date: new Date(),
          show_end_date: new Date(),
          prescription_created: 'other',
          status: 'fulfilled',
          prescription_type: 'pharmacy-prescription',
        }
      });
      this.props.getPrescriptions(
        {
          sortBy: { sortField: "updated_at", sortOrder: "desc" },
          filter: {
            prescription_created: 'other',
            prescription_type: 'pharmacy-prescription',
            status: 'fulfilled'
          }
        },
        this.state.patientId, key
      );
    } else if (key === "historical-data") {
      let { patient: { middle_name, first_name, last_name, patient_id } } = this.props;
      const historical_data_filter = {
        search: `${first_name}_${middle_name}_${last_name}`
      }
      this.setState({ historical_data_filter })
      this.props.getHitoricalData(patient_id, { filter: historical_data_filter })
    }
    window["closeToggle"]();
  };

  sendPermissionRequest = e => {
    e.preventDefault();

    if (_.isEmpty(this.state.requestPermissionId)) {
      return false;
    }
    let prescription_ids = {
      prescription_id: this.state.requestPermissionId
    }

    PrescriptionService.requestPrescriptionAccess(prescription_ids)
      .then(resp => {
        setTimeout(() => {
          Notification.show('success', { message: 'Patient detail request sent successfully.' });
          this.setState({
            disabled: true
          });
        }, 1000);
        this.props.getPrescriptions({ sortBy: { sortField: "updated_at", sortOrder: "desc" }, filter: this.state.filter }, this.state.patientId, this.state.tab_status);
      }).catch(error => {
        setTimeout(() => {
          Notification.show('error', { error: 'Something went wrong, try again!' });
        }, 1000);
      });
    this.setState({
      requestPermissionId: []
    });
    var checkboxes = document.getElementsByName("request_prescription_id");
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      checkboxes[i].checked = false;
    }
  }

  handleSubmit = e => {
    e.preventDefault();

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return false;
    }

    window["closeToggle"]();

    this.setState({
      tags: {
        prescription_id: this.state.filter.prescription_id,
        date_filter:
          this.state.filter.start_date && this.state.filter.end_date
            ? this.state.filter.start_date + " to " + this.state.filter.end_date
            : ""
      }
    });

    this.props.getPrescriptions({
      sortBy: { sortField: "updated_at", sortOrder: "desc" },
      filter: this.state.filter
    }, this.state.patientId, this.state.tab_status);
  };

  handleEvent = (event, picker) => {
    this.setState({
      filter: {
        prescription_id: this.state.filter.prescription_id,
        start_date: (picker.startDate._d) ? moment(picker.startDate._d).format('YYYY-MM-DD') : '',
        end_date: (picker.endDate._d) ? moment(picker.endDate._d).format('YYYY-MM-DD') : '',
        show_start_date: picker.startDate._d,
        show_end_date: picker.endDate._d,
        prescription_created: this.state.filter.prescription_created,
        status: 'prescribed,partially-fulfilled,fulfilled'
      },
      tags: {
        prescription_id: this.state.filter.prescription_id,
        date_filter: (picker.startDate._d && picker.endDate._d) ? moment(picker.startDate._d).format('DD-MM-YYYY') + ' to ' + moment(picker.endDate._d).format('DD-MM-YYYY') : ''
      }
    });
    this.props.getPrescriptions({ sortBy: { sortField: "updated_at", sortOrder: "desc" }, filter: this.state.filter }, this.state.patientId, this.state.tab_status);
  }

  removeFilter = (e) => {
    const filter = this.state.filter;
    const tags = this.state.tags;
    filter["status"] = 'prescribed,partially-fulfilled,fulfilled';
    if (e.target.dataset.tag === 'date_filter') {
      filter["start_date"] = '';
      filter["end_date"] = '';
    } else {
      filter[e.target.dataset.tag] = '';
    }
    tags[e.target.dataset.tag] = '';

    this.setState({ filter: filter, tags: tags });

    this.props.getPrescriptions({ sortBy: { sortField: "updated_at", sortOrder: "desc" }, filter: this.state.filter }, this.state.patientId, this.state.tab_status);
  }

  getInsurance = () => {
    const { patient } = this.props;

    let insurance = patient.insurance_type.map(value => {
      return _.has(Config.INSURANCE, value) ? Config.INSURANCE[value] : ''
    })
    return _.filter(insurance).join(', ')
  }
  handleAppointmentSubmit = (data) => {
    this.props.showLoader(this.state);
    let appointmentData = {
      "from": data.from,
      "to": data.to,
      "patient_name": data.patient_name,
      "patient_contact": data.patient_contact,
      "slot_id": data.slot_id,
      "doctor_id": data.doctor_profile_id,
      "patient_id": data.patient_id,
      "type": "card"
    }
    AppointmentService.createAppointment(appointmentData).then(resp => {
      this.props.hideLoader();
      Notification.show('success', { 'message': 'Appointment has been scheduled.' });
      this.setState({ showHide: false });
    }).catch(_error => {
      this.props.hideLoader();
      Notification.show('error', { message: 'Something went wrong.' })
    })
  }

  historicalDataTableColumn = () => [{
    dataField: "attached_at",
    text: "Attached Date",
    formatter: this.dateFormatter
  }, {
    dataField: "file_name",
    text: "File Name",
  }, {
    dataField: "pdf_password",
    text: "Password",
  }, {
    dataField: "actions",
    text: "Actions",
    isDummyField: true,
    formatter: this.historicalDataAction
  }];

  detachPrescription = (e, document_id) => {
    e.preventDefault();
    this.props.showLoader()
    PrescriptionService.detachPrescriptionHistory(this.props.patient.patient_id, document_id)
      .then(resp => {
        this.props.hideLoader()
        Notification.show('success', { message: 'Record has been detched successfully' });
        this.props.getHitoricalData(this.props.patient.patient_id, { filter: this.state.historical_data_filter })
      }).catch(err => {
        this.props.hideLoader()
        Notification.show('error', err.response.data)
      })
  }

  historicalDataAction = (cell, row) => {
    return <>
      <Link to="#" onClick={this.props.getHistoryDownloadUrl.bind(this, row.document_uuid)}>
        <FontAwesomeIcon icon={faExternalLinkAlt} className="c-mb-2"/>
      </Link>
      <Link to="#" onClick={(e) => this.detachPrescription(e, row.document_uuid)}>Detach</Link>
    </>;
  };

  _onHistoricalDataTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (type === "search") {
      page = 1;
    }
    this.props.getHitoricalData(this.props.patient.patient_id, { page, sizePerPage, sortBy: { sortField, sortOrder }, filter: this.state.historical_data_filter })
  };

  handleAttachHistoryDataModal = () => {
    this.setState({
      history_data_modal: !this.state.history_data_modal
    });
    this.props.getHitoricalData(this.props.patient.patient_id, { filter: this.state.historical_data_filter })
  }
  hideCollectFee = () => {
    let { prescription } = this.state;
    let expireAt = moment(now()).add(12, 'hours');
    let currentTimestamp = moment();
    return currentTimestamp.isBefore(expireAt) || currentTimestamp.isSame(expireAt)
  }

  handleDocketNumberCloseModal = () => {
    this.setState({ showAddModal: false, showEditModal: false, showDocketNumber: false});
  }

  handleDocketNumberShowModal = (action) => {
    this.setState({ showDocketNumber: true });
    if(action === "Add"){
        this.setState({ showAddModal: true});
    }else{
        this.setState({ showEditModal: true});
    }
  }

  onSuccessRedirect = (docket_number) => {
    this.props.patient.docket_number = docket_number ? docket_number : ''
  }

  render() {
    const { patient } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>Patient</title>
        </Helmet>
        <section className="container-fluid">
          <div className="row">
            <div className="col-md-24">
              <div className="row">
                <div className="col-md-24">
                  {hasRole(this.props.selected_user_profile, ["paramedic-doctor"]) && <h4 className="page-title no-heading"><Link onClick={() => this.props.history.goBack()} to="#">&#8249; Back</Link><span>Discharged Patient Summary</span></h4>}
                  {!hasRole(this.props.selected_user_profile, ["paramedic-doctor"]) && <h4 className="page-title"><span>Patient Profile</span>
                    {this.actionBlock(this.props.selected_user_profile, this.state.patientId)}
                  </h4>}
                </div>
              </div>
              {hasRole(this.props.selected_user_profile, [
                "doctor-receptionist"
              ]) ? (
                <Alert className="col-md-24" />
              ) : (
                ""
              )}
              <div className="row">
                <Profile patient={patient} selected_user_profile={this.props.selected_user_profile} />
              </div>
            </div>
          </div>
        </section>
        {hasRole(this.props.selected_user_profile, ["doctor", "associate-doctor", "paramedic-doctor", "doctor-nurse"]) && (
          <section className="container-fluid">
            <div className="row">
              <div className="col-md-24 mt-3 requester-tabs">
                <Tabs
                  onSelect={this.listPrescription.bind(this)}
                >
                  {hasRole(this.props.selected_user_profile, ["doctor", "associate-doctor", "paramedic-doctor"]) && <Tab eventKey="prescription" title="Health Record">
                    {this.props.prescriptions.prescription_type === 'prescription' && this.state.tab_status === 'prescription' && (<React.Fragment>
                      {!hasRole(this.props.selected_user_profile, ["paramedic-doctor"]) && <div className="search-wrapper mt-3">
                        <label>Search</label>
                        <div className="search-box clearfix">
                          {(Object.keys(this.state.tags)).map((tag) => {
                            if (tag !== 'doctor_id' && this.state.tags[tag] !== '' && !(_.isEmpty(this.state.tags[tag]))) {
                              return <span className="search-list">{this.state.tags[tag]} <span className='removesearch' data-tag={tag} onClick={this.removeFilter.bind(this)}> x </span> </span>
                            }
                            return '';
                          })
                          }
                          <span className="search-box-icon"></span>
                          <div className="search-container">
                            <form onSubmit={this.handleSubmit}>
                              <ul>
                                <li>
                                  <PrescriptionIdFilter filter={this.state.filter} handleChange={this.handleChange} />
                                  {this.validator.message(
                                    "prescription_id",
                                    this.state.filter.prescription_id,
                                    "alpha_num"
                                  )}
                                </li>
                                <DateFilter filter={this.state.filter} handleEvent={this.handleEvent} />
                                <li>
                                  <button
                                    type="button"
                                    onClick={this.listPrescription.bind(this, "prescription")}
                                    className="btn btn-secondary"
                                  >
                                    Reset
                                  </button>
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    Search
                                  </button>
                                </li>
                              </ul>
                            </form>
                          </div>
                        </div>
                      </div>}
                      <div className="content-wrapper">
                        <DataGrid
                          noDataIndication="No Record Found"
                          columns={this.firstColumns()}
                          data={this.props.prescriptions.data}
                          meta={this.props.prescriptions.meta}
                          handleTableChange={this.handleTableChangefirst}
                        />
                      </div></React.Fragment>
                    )}
                  </Tab>}
                  {hasRole(this.props.selected_user_profile, ["doctor", "associate-doctor"]) && <Tab eventKey="other-prescription" title="External Visits">
                    {this.props.prescriptions.prescription_type === "other-prescription" && this.state.tab_status === 'other-prescription' && (<React.Fragment>
                      <button
                        type="submit"
                        className="btn btn-primary float-left mt-3"
                        onClick={this.sendPermissionRequest}
                        disabled={this.state.disabled}
                      >
                        Request Permission
                      </button>
                      <div className="search-wrapper mt-3">
                        <label>Search</label>
                        <div className="search-box clearfix">
                          {(Object.keys(this.state.tags)).map((tag) => {
                            if (tag !== 'doctor_id' && this.state.tags[tag] !== '' && !(_.isEmpty(this.state.tags[tag]))) {
                              return <span className="search-list">{this.state.tags[tag]} <span className='removesearch' data-tag={tag} onClick={this.removeFilter.bind(this)}> x </span> </span>
                            }
                            return '';
                          })
                          }
                          <span className="search-box-icon"></span>
                          <div className="search-container">
                            <form onSubmit={this.handleSubmit}>
                              <ul>
                                <li>
                                  <PrescriptionIdFilter filter={this.state.filter} handleChange={this.handleChange} />
                                  {this.validator.message(
                                    "prescription_id",
                                    this.state.filter.prescription_id,
                                    "alpha_num"
                                  )}
                                </li>
                                <DateFilter filter={this.state.filter} handleEvent={this.handleEvent} />
                                <li>
                                  <button type="button" onClick={this.listPrescription.bind(this, "other-prescription")} className="btn btn-secondary">Reset</button>
                                  <button type="submit" className="btn btn-primary">Search</button>
                                </li>
                              </ul>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="content-wrapper">
                        <DataGrid
                          noDataIndication="No Record Found"
                          columns={this.secondColumns()}
                          data={this.props.prescriptions.data}
                          meta={this.props.prescriptions.meta}
                          handleTableChange={this.handleTableChangeSecond}
                        />
                      </div> </React.Fragment>
                    )}
                  </Tab>}
                  {hasRole(this.props.selected_user_profile, ["doctor", "associate-doctor", "doctor-nurse"]) && <Tab eventKey="medical-pathology-test" title="Lab Reports" >
                    {this.state.tab_status === "medical-pathology-test" && <PatientPathologyTestTab
                      pathologyColumns={this.documentColumns()}
                      documents={this.state.documents}
                      handlePathologyTableChange={this.handlePathologyTableChange}
                      patient={this.props.patient}
                      refreshPathologyList={this.fetchPathologyDocuments}
                    />}
                  </Tab>}

                  {hasRole(this.props.selected_user_profile, ["doctor", "associate-doctor"]) && <Tab eventKey="medical-radiology-test" title="Radiology Reports">
                    <React.Fragment>
                      {this.state.tab_status === "medical-radiology-test" &&
                        <PatientRadiologyTestTab radiologyColumns={this.documentColumns()}
                          documents={this.state.documents}
                          handleRadiologyTableChange={this.handleRadiologyTableChange}
                          patient={this.props.patient}
                          refreshRadiologyList={this.fetchRadiologyDocuments} />}
                    </React.Fragment>
                  </Tab>}
                  {hasRole(this.props.selected_user_profile, ["doctor", "associate-doctor"]) && <Tab eventKey="emergency-prescription" title="Emergency Prescription">
                    {this.props.prescriptions.prescription_type === 'emergency-prescription' && this.state.tab_status === 'emergency-prescription' && (<React.Fragment>

                      <div className="search-wrapper mt-3">
                        <label>Search</label>
                        <div className="search-box clearfix">
                          {(Object.keys(this.state.tags)).map((tag) => {
                            if (tag !== 'doctor_id' && this.state.tags[tag] !== '' && !(_.isEmpty(this.state.tags[tag]))) {
                              return <span className="search-list">{this.state.tags[tag]} <span className='removesearch' data-tag={tag} onClick={this.removeFilter.bind(this)}> x </span> </span>
                            }
                            return '';
                          })
                          }
                          <span className="search-box-icon"></span>
                          <div className="search-container">
                            <form onSubmit={this.handleSubmit}>
                              <ul>
                                <li>
                                  <PrescriptionIdFilter filter={this.state.filter} handleChange={this.handleChange} />
                                  {this.validator.message(
                                    "prescription_id",
                                    this.state.filter.prescription_id,
                                    "alpha_num"
                                  )}
                                </li>
                                <DateFilter filter={this.state.filter} handleEvent={this.handleEvent} />
                                <li>
                                  <button type="button" onClick={this.listPrescription.bind(this, "emergency-prescription")} className="btn btn-secondary">Reset</button>
                                  <button type="submit" className="btn btn-primary">Search</button>
                                </li>
                              </ul>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="content-wrapper">
                        {this.props.prescriptions.data && (
                          <DataGrid
                            noDataIndication="No Record Found"
                            columns={this.emergencyPrescriptionColumns()}
                            data={this.props.prescriptions.data}
                            meta={this.props.prescriptions.meta}
                            handleTableChange={this.handleTableChangeEmergencyPrescription}
                          />
                        )}
                      </div> </React.Fragment>
                    )}
                  </Tab>}
                  {hasRole(this.props.selected_user_profile, ["doctor", "associate-doctor"]) &&
                    <Tab eventKey="historical-data" title="Historic Data">
                      <React.Fragment>
                        <div className="docket-number-heading mt-3">
                          <label>Docket Number:</label> { patient.docket_number ? patient.docket_number.docket_number : '--' }
                        </div>
                        <div className='docket-number-button'>
                          {patient.docket_number ? <span className="edit" onClick={() => this.handleDocketNumberShowModal('Update')} title="Edit"/> : <span className="add" onClick={() => this.handleDocketNumberShowModal('Add')} title="Add"/>}
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary float-right mt-3"
                          onClick={this.handleAttachHistoryDataModal}
                        >Attach Data</button>
                      </React.Fragment>
                      {this.props.historical_data && <div className="content-wrapper">
                        {_.isEmpty(this.props.historical_data.data) && <span>No records found!</span>}
                        {!_.isEmpty(this.props.historical_data.data) && (
                          <DataGrid
                            columns={this.historicalDataTableColumn()}
                            data={this.props.historical_data.data}
                            meta={this.props.historical_data.meta}
                            handleTableChange={this._onHistoricalDataTableChange}
                          />
                        )}
                      </div>}
                    </Tab>
                  }
                </Tabs>
              </div>
            </div>
          </section>
        )}
        {this.state.history_data_modal && hasRole(this.props.selected_user_profile, ["doctor", "associate-doctor"]) && <AttachHistoricDataModal
          handleShowHideModal={this.handleAttachHistoryDataModal}
          patient={patient}
          actionFormatter={this.props.getHistoryDownloadUrl}
          show={this.state.history_data_modal}
        />}
        {
          this.state.showHide && hasRole(this.props.selected_user_profile, ["doctor", "associate-doctor", "doctor-receptionist", "doctor-nurse", "medical-practice-manager"]) && <ScheduleAppointmentModal
            handleModalShowHide={this.handleModalShowHide}
            showHide={this.state.showHide}
            handleSubmit={this.handleAppointmentSubmit}
            patient_name={patient.full_name}
            patient_id={this.state.patientId}
            patient_code={patient.patient_code}
            patient_contact={patient.contact_number}
            title="Schedule Appointment" />
        }
        {this.state.showEditModal && <DocketNumberModal
            show={this.state.showDocketNumber}
            onSuccess={this.onSuccessRedirect}
            handleClose={this.handleDocketNumberCloseModal}
            buttonText={"Update"}
            patient={patient}
          />}

        {this.state.showAddModal && <DocketNumberModal
            show={this.state.showDocketNumber}
            onSuccess={this.onSuccessRedirect}
            handleClose={this.handleDocketNumberCloseModal}
            buttonText={"Add"}
            patient={patient}
          />}
      </React.Fragment >
    );
  }
}

function mapStateToProps(state) {
  return {
    historical_data: state.patient_historic_data,
    patient: state.patient,
    selected_user_profile: state.selected_user_profile,
    prescriptions: state.prescription.paginate
  }
};

const mapActionsToProps = {
  getPatient: PatientAction.get,
  getHitoricalData: PatientAction.getHitoricalData,
  getHistoryDownloadUrl: PatientAction.getHistoryDownloadUrl,
  getPrescriptions: PrescriptionAction.getPrescriptionsList,
  documentDownload: PathoRadioAction.documentDownload,
  showLoader: loaderAction.loaderShow,
  hideLoader: loaderAction.loaderHide
};

export default connect(mapStateToProps, mapActionsToProps)(ShowPatientPage);
