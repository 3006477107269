import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import * as CommissionAction from "../../actions/commissionAction";
import DataGrid from "../../components/DataGrid";
import * as Helper from "../../utils/helpers";
import ServiceProviderLocations from '../../components/ServiceProviderLocations';
import _ from 'lodash';
import { hasProvider } from '../../utils/auth';

class ListCommissionPage extends React.Component {
    constructor(props) {
        super(props);
        this.props.paginate({ sortBy: { sortField: "prescription_date", sortOrder: "desc" } });
        this.state = {
            filter: {
                giver_user_location_id: ''
            },
            tags: {
                location_filter: ""
            }
        }
    }

    columns = (selected_user_profile) => {
        if (selected_user_profile.service_provider.service_provider_type === 'doctor' || selected_user_profile.service_provider.service_provider_type === 'associate-doctor') {
            return [
                {
                    dataField: "prescription_id",
                    text: "Prescription Id",
                    sort: false
                },
                {
                    dataField: "prescription_date",
                    text: "Prescription Date",
                    sort: true,
                    formatter: this.dateFormatter
                },
                {
                    dataField: "order_id",
                    text: "Order Id",
                    sort: false
                },
                {
                    dataField: "order_date",
                    text: "Order Date",
                    sort: true,
                    formatter: this.dateFormatter
                },
                {
                    dataField: "giver_service_provider.id",
                    text: "Provider Id",
                    sort: false
                },
                {
                    dataField: "giver_service_provider.name",
                    text: "Provider Name",
                    sort: false
                },
                {
                    dataField: "order_amount",
                    text: "Order Value",
                    sort: false
                },
                {
                    dataField: "receiver_commission",
                    text: "Consultancy Fees",
                    sort: false
                },
            ];
        } else {
            return [
                {
                    dataField: "prescription_id",
                    text: "Prescription Id",
                    sort: false
                },
                {
                    dataField: "prescription_date",
                    text: "Prescription Date",
                    sort: true,
                    formatter: this.dateFormatter
                },
                {
                    dataField: "order_id",
                    text: "Order Id",
                    sort: false
                },
                {
                    dataField: "order_date",
                    text: "Order Date",
                    sort: true,
                    formatter: this.dateFormatter
                },
                {
                    dataField: "giver_user_location.name",
                    text: "Location Name",
                    sort: false
                },
                {
                    dataField: "receiver_service_provider.id",
                    text: "Doctor Id",
                    sort: false
                },
                {
                    dataField: "receiver_service_provider.name",
                    text: "Provider Name",
                    sort: false
                },
                {
                    dataField: "order_amount",
                    text: "Order Value",
                    sort: false
                },
                {
                    dataField: "giver_commission",
                    text: "Commission Value",
                    sort: false
                },
                {
                    dataField: "commission_type",
                    text: "Commission Type",
                    sort: false
                }
            ];
        }
    };

    dateFormatter = (cell) => {
        return Helper.getDateFormat(cell);
    };


    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        if (type === "search") {
            page = 1;
        }

        this.props.paginate({ page, sizePerPage, sortBy: { sortField, sortOrder }, searchText, filter: this.state.filter });
    };

    handleLocationFilter = (user_location_id, location_name = null) => {
        const filter = this.state.filter;
        filter.giver_user_location_id = user_location_id ? user_location_id : undefined;
        this.setState({
            filter: filter,
            tags: {
                location_filter: location_name
            }
        });
    }

    removeFilter = (e) => {
        const filter = this.state.filter;
        const tags = this.state.tags;
        filter['giver_user_location_id'] = '';
        tags[e.target.dataset.tag] = '';
        this.setState({ filter: filter, tags: tags });
        this.props.paginate({ sortBy: { sortField: "prescription_date", sortOrder: "desc" }, filter: filter });
    }

    renderTags(tags) {
        let options;
        return (Object.keys(tags)).map((tag) => {
            if (tags[tag] !== '' && !(_.isEmpty(tags[tag]))) {
                options = <span className="search-list">{tags[tag]} <span className='removesearch' data-tag={tag} onClick={this.removeFilter.bind(this)}> x </span> </span>
            } else {
                return false
            }
            return options
        });
    }

    handleSubmit = () => {
        this.props.paginate({ sortBy: { sortField: "prescription_date", sortOrder: "desc" }, filter: this.state.filter });
    }

    resetSearch = () => {
        const filter = this.state.filter;
        filter.giver_user_location_id = "";
        this.setState({
            filter: filter,
            tags: {
                location_filter: ""
            }
        });
        
        this.props.paginate({ sortBy: { sortField: "prescription_date", sortOrder: "desc" }, filter: filter });
    }

    filterComponent = () => {
        return <div className="search-wrapper">
            <label>Filter</label>
            <div className="search-box clearfix">
                {this.state.tags && this.renderTags(this.state.tags)}
                <span className="search-box-icon"></span>
                <div className="search-container">
                    <ul>
                        <li>
                            <span>Select location</span>
                            <ServiceProviderLocations
                                service_provider_id={this.props.selected_user_profile.service_provider_id}
                                handleLocationChange={this.handleLocationFilter}
                                user_location_id={this.state.filter.giver_user_location_id}
                            />
                        </li>
                        <li>
                            <button type="button" className="btn btn-secondary" onClick={this.resetSearch}>Reset</button>
                            <button type="submit" className="btn btn-primary" onClick={this.handleSubmit}>Search</button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    }

    render() {
        let { data, meta, isLoading } = this.props.commission;
        let { selected_user_profile } = this.props
        return (
            <React.Fragment>
                <Helmet>
                    <title>Report</title>
                </Helmet>
                <div className="row">
                    <div className="col-md-24">
                        <h4 className="page-title">Report Details</h4>
                        {data && hasProvider(selected_user_profile.service_provider, ['pathology', 'radiology', 'pharmacy']) && this.filterComponent()}
                    </div>
                </div>
                <div className="content-wrapper">
                    <DataGrid
                        loading={isLoading}
                        noDataIndication="No Record Found"
                        columns={this.columns(selected_user_profile)}
                        data={data}
                        meta={meta}
                        handleTableChange={this.handleTableChange}
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.session.user,
    commission: state.commission,
    selected_user_profile: state.selected_user_profile
});

const mapActionsToProps = {
    paginate: CommissionAction.list
};

export default connect(mapStateToProps, mapActionsToProps)(ListCommissionPage);