import React from 'react';

export const PersonalNote = ({ note, handleChange }) => {
    return <React.Fragment>
    <div className="content-wrapper">
	    <h6>Personal Note:</h6>
	    <p>
	        <textarea className="form-control" name="personal_note" value={note ? note : ''} onChange={handleChange} placeholder="Type here..."></textarea>
	    </p>
    </div>
    </React.Fragment>
}
export default PersonalNote