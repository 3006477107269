import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Notification from '../utils/notification';
import PrescriptionService from '../services/prescriptionService';
import { isEmpty } from 'lodash';
class FeeCollectionModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            consultancy_fee: "",
            other_fee: "",
            status: "",
            payment_insurance_amount: "",
            payment_cash_amount: "",
            payment_card_amount: "",
            payment_remark: "",
            total_payment: 0,
            buttonText: 'Submit',
            isSubmitDisabled: false,
            show: false,
            isloading: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    feeHandler = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
        if (e.target.value === 'paid') {
            this.setState({
                show: true,
                buttonText: 'Submit',
            });
        } else if (e.target.value === 'unpaid') {
            this.setState({
                show: false,
                buttonText: 'Send',
            });
        }
    }

    handleTotalFees = (e) => {
        let consultancy_fee, other_fee;
        if (isEmpty(this.state.consultancy_fee)) {
            consultancy_fee = 0;
        } else {
            consultancy_fee = parseFloat(this.state.consultancy_fee)
        }

        if (isEmpty(this.state.other_fee)) {
            other_fee = 0;
        } else {
            other_fee = parseFloat(this.state.other_fee)
        }

        this.setState({
            total_payment: consultancy_fee + other_fee
        })
    }

    handleSubmit = (prescription_id) => {
        if (isEmpty(this.state.payment_insurance_amount) && isEmpty(this.state.payment_cash_amount) && this.state.status === 'paid'  && isEmpty(this.state.payment_card_amount)) {
            Notification.show('error', { message: 'kindly fill all the mandatory fields' });
            return false
        }
        if (isEmpty(this.state.consultancy_fee) && isEmpty(this.state.other_fee)) {
            Notification.show('error', { message: 'Doctor consultancy fee info incomplete' });
            return false;
        }
        const data = {
            consultancy_fee: this.state.consultancy_fee ? this.state.consultancy_fee : null,
            other_fee: this.state.other_fee ? this.state.other_fee : null,
            payment_insurance_amount: this.state.payment_insurance_amount ? this.state.payment_insurance_amount : null,
            payment_cash_amount: this.state.payment_cash_amount ? this.state.payment_cash_amount : null,
            payment_card_amount: this.state.payment_card_amount ? this.state.payment_card_amount : null,
            status: this.state.status,
            payment_remark: this.state.payment_remark ? this.state.payment_remark : null
        }
        this.setState({
            isloading: true
        });
        let message = this.state.status === 'paid' ? 'Payment done.' : 'Sent to receptionist/nurse'
        PrescriptionService.updatePrescriptionFee(prescription_id, data).then(resp => {
            Notification.show('success', { message: message });
            this.props.onSuccess(this.props);

            this.setState({
                consultancy_fee: "",
                other_fee: "",
                payment_insurance_amount: "",
                payment_cash_amount: "",
                status: "",
                payment_remark: "",
                payment_card_amount: "",
                total_payment: 0
            })
            this.setState({
                isloading: false
            });
            this.props.setPrescriptionFee(resp.data.data, this.state.status);
        }).catch(error => {
            this.setState({
                isloading: false,
                buttonText: 'Send'
            });
            Notification.show('error', error.response.data)
        })
    }
    render() {

        return (
            <React.Fragment>
                {this.state.isloading && this.state.isloading && <div className="loading">Loading</div>}
                <Modal
                    size='lg' centered aria-labelledby="contained-modal-title-vcenter"
                    show={this.props.show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <h6>Fee Collection</h6>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <ul className="fee_collection_form">
                                <li>Consultancy Fee: <span><input id="consultancy_fee" type="number" name="consultancy_fee" className="form-control" onChange={this.feeHandler} onBlur={this.handleTotalFees} /></span></li>
                                <li>Other Fee: <span><input type="number" id="other_fee" name="other_fee" className="form-control" onChange={this.feeHandler} onBlur={this.handleTotalFees} /></span></li>
                                <li>Total to be collected: <span>{this.state.total_payment}</span></li>

                                <li>Status: <span>
                                    <input type="radio" id="paid" name="status" value="paid" className="form-control" onChange={this.feeHandler} />
                                    <label htmlFor="paid">Collected</label>
                                    <input type="radio" id="unpaid" name="status" value="unpaid" className="form-control" onChange={this.feeHandler} />
                                    <label htmlFor="unpaid">Send to Receptionist/Nurse</label></span>
                                </li>

                                {this.state.show && <li>From Insurance: <span><input type="number" name="payment_insurance_amount" className="form-control" onChange={this.feeHandler} /></span></li>}

                                {this.state.show && <li>Cash Collected: <span><input type="number" name="payment_cash_amount" className="form-control" onChange={this.feeHandler} /></span></li>}

                                {this.state.show && <li>From Card: <span><input type="number" name="payment_card_amount" className="form-control" onChange={this.feeHandler} /></span></li>}

                                {this.state.show && <li className="full-width">Remark: <span>
                                    <textarea name="payment_remark" className="form-control" onChange={this.feeHandler} /></span>
                                </li>}
                            </ul>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" disabled={this.state.isSubmitDisabled} onClick={e => this.handleSubmit(this.props.id)}>
                            {this.state.buttonText}
                        </Button>
                        <Button variant="secondary" disabled={this.state.isSubmitDisabled} onClick={this.props.handleClose}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        )
    }
}

export default FeeCollectionModal
