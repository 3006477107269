import * as types from '../actions/actionTypes';

export default function (state = {}, { type, ...payload }) {
    switch (type) {
        case types.PROFILE_EDIT_QUEUE_RESET:
            return {};
        case types.PROFILE_EDIT_QUEUE_LIST_SUCCESS:
            return {
                ...payload
            };
        case types.FETCH_PROFILE_EDIT_QUEUE:
            return {
                ...payload
            };
        case types.PROFILE_EDIT_QUEUE_UPDATE:
            return {
                ...payload
            };
        default:
            return state;
    }
}