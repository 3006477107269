import React from 'react';
import { getDateTimeFormat, getDateFormat } from "../utils/helpers";
import moment from 'moment';

const Notification = ({ notification, handleReadAction }) => {
    return <React.Fragment>
        {notification.type === 'immunization' &&
            <a href key={notification.id} onClick={() => handleReadAction(notification)} className={"notification-" + notification.status}>
                <div className="card icon consultation">
                    <div className="card-body">
                        {Array.isArray(notification.payload) && notification.payload.map((data) => (
                            <p>{data.label}: <span>{data.value}</span></p>
                        ))}
                        <p>Date: <span>{getDateTimeFormat(notification.created_at)}</span></p>
                    </div>
                </div>
            </a>}
        {notification.type === 'patient_fees' &&
            <a href key={notification.id} onClick={() => handleReadAction(notification)} className={"notification-" + notification.status}>
                <div className="card icon consultation">
                    <div className="card-body">
                        {Array.isArray(notification.payload) && notification.payload.map((data) => (
                            <p>{data.label}: <span>{data.value}</span></p>
                        ))}
                        <p>Date: <span>{getDateTimeFormat(notification.created_at)}</span></p>
                    </div>
                </div>
            </a>}
        {notification.type === 'prescription_approval' &&
            <a href key={notification.id} onClick={() => handleReadAction(notification)} className={"notification-" + notification.status}>
                <div className="card icon appointment-request">
                    <div className="card-body">
                        {Array.isArray(notification.payload) && notification.payload.map((data) => (
                            <p>{data.label}: <span>{data.value}</span></p>
                        ))}
                        <p>Date: <span>{getDateTimeFormat(notification.created_at)}</span></p>
                    </div>
                </div>
            </a>}
        {notification.type === 'prescription_queue' &&
            <a href key={notification.id} onClick={() => handleReadAction(notification)} className={"notification-" + notification.status}>
                <div className="card icon appointment-request">
                    <div className="card-body">
                        {Array.isArray(notification.payload) && notification.payload.map((data) => (
                            <p>{data.label}: <span>{data.value}</span></p>
                        ))}
                        <p>Date: <span>{getDateTimeFormat(notification.created_at)}</span></p>
                    </div>
                </div>
            </a>}
        {notification.type === 'permission_request' &&
            <a href key={notification.id} onClick={() => handleReadAction(notification)} className={"notification-" + notification.status}>
                <div className="card icon pending-request">
                    <div className="card-body">
                        {Array.isArray(notification.payload) && notification.payload.map((data) => (
                            <p>{data.label}: <span>{data.value}</span></p>
                        ))}
                        <p>Date: <span>{getDateTimeFormat(notification.created_at)}</span></p>
                    </div>
                </div>
            </a>}
        {notification.type === 'prescription_concern' &&
            <a href key={notification.id} onClick={() => handleReadAction(notification)} className={"notification-" + notification.status}>
                <div className="card icon appointment-request">
                    <div className="card-body">
                        {Array.isArray(notification.payload) && notification.payload.map((data) => (
                            <p>{data.label}: <span>{data.value}</span></p>
                        ))}
                        <p>Date: <span>{getDateTimeFormat(notification.created_at)}</span></p>
                    </div>
                </div>
            </a>}
        {notification.type === 'prescription_refill_reminder' &&
            <a href key={notification.id} onClick={() => handleReadAction(notification)} className={"notification-" + notification.status}>
                <div className="card icon appointment-request">
                    <div className="card-body">
                        {Array.isArray(notification.payload) && notification.payload.map((data) => (
                            <p>{data.label}: <span>{data.value}</span></p>
                        ))}
                    </div>
                </div>
            </a>}
        {notification.type === 'emergency_hospital' &&
            <a href key={notification.id} onClick={() => handleReadAction(notification)} className={"notification-" + notification.status}>
                <div className="card icon appointment-request">
                    <div className="card-body">
                        {Array.isArray(notification.payload) && notification.payload.map((data) => (
                            <p>{data.label}: <span>{data.value}</span></p>
                        ))}
                    </div>
                </div>
            </a>}
        {notification.type === 'appointment_cancel' &&
            <a href key={notification.id} onClick={() => handleReadAction(notification)} className={"notification-" + notification.status}>
                <div className="card icon appointment-request">
                    <div className="card-body">
                        <p><span>{notification.payload.patient_name} appointment for {notification.payload.doctor_name} on {getDateFormat(notification.payload.appointment_date)} at {moment(notification.payload.appointment_time, 'HH:mm:ss').format('hh:mm A')} is cancelled.</span></p>
                    </div>
                </div>
            </a>}
        {(notification.type === 'pathology_report_upload' || notification.type === 'radiology_report_upload') &&
            <a href key={notification.id} onClick={() => handleReadAction(notification)} className={"notification-" + notification.status}>
                <div className="card icon appointment-request">
                    <div className="card-body">
                        <p>Patient Name: <span>{notification.payload.patient_name}</span></p>
                        <p>{notification.type === 'pathology_report_upload' ? 'Pathology' : 'Radiology'} Report: <span>{notification.payload.test_name} prescribed on {getDateFormat(notification.payload.prescription_date)} is now availabe.</span></p>
                    </div>
                </div>
            </a>}
        {notification.type === 'chat' &&
            <a href key={notification.id} onClick={() => handleReadAction(notification)} className={"notification-" + notification.status}>
                <div className="card icon">
                    <div className="card-body">
                        <p>Chat</p>
                        {Array.isArray(notification.payload) && notification.payload.map((data) => (
                            <p>{data.label}: <span>{data.value}</span></p>
                        ))}
                        <p>Date: <span>{getDateTimeFormat(notification.created_at)}</span></p>
                    </div>
                </div>
            </a>}
    </React.Fragment>
}
export default Notification