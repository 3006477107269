export const LabTest = {
    "25": [
        {
            parameter: "rbc",
            text: "RBC",
            unit: "",
        },
        {
            parameter: "wbc",
            text: "WBC",
            unit: "",
        },
        {
            parameter: "hematocrit",
            text: "Hematocrit",
            unit: "hct",
        },
        {
            parameter: "hemoglobin",
            text: "Hemoglobin",
            unit: "Hgb",
        },
        {
            parameter: "platelet-count",
            text: "Platelet count",
            unit: "",
        },
        {
            parameter: "mean-platelet-volume",
            text: "Mean Platelet Volume",
            unit: "MPV",
        }]
}

