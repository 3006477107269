import React from "react";
import { Modal, Button, Form } from 'react-bootstrap';
import ProfileService from "../services/profileService";
import SimpleReactValidator from 'simple-react-validator';
import Notification from "../utils/notification";
import * as authUtils from '../utils/auth';

class TwoFAPinModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            code: ''
        };
        this.validator = new SimpleReactValidator();
    }
    onSubmit = (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        ProfileService.verifyProfilePin(this.state.code).then(resp => {
            authUtils.setTwoFa(resp.data.status);
            this.props.onPinVerify()
        }).catch(err => {
            Notification.show('error', err.response.data);
        })
    }
    handleChange = (evt) => {
        this.setState({
            [evt.target.name]: evt.target.value
        });
    }
    onResendCode = (e) => {
        e.preventDefault();
        ProfileService.resendProfilePin().then(_resp => {
            Notification.show('success', { message: 'Pin has been sent.' })
        }).catch(err => {
            Notification.show('error', err.response.data);
        })
    }
    onKeyDown = (keyEvent) => {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }
    componentWillUnmount() {
        this.setState({ code: '' })
    }
    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onCloseModal}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    Enter Profile PIN
                </Modal.Header>
                <Modal.Body>
                    <Form onKeyDown={this.onKeyDown}>
                        <Form.Control
                            size="lg"
                            type="password"
                            placeholder="Enter Profile PIN"
                            value={this.state.code}
                            id="code"
                            name="code"
                            onChange={this.handleChange}
                            maxLength="6"
                            autoComplete="one-time-code"
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.onSubmit}>
                        Submit
                    </Button>
                    <Button variant="outline-primary" onClick={this.onResendCode}>
                        Resend
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}


export default (TwoFAPinModal);