import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import DoctorDashboard from '../../components/Dashboard/DoctorDashboard';
import PharmacyDashboard from '../../components/Dashboard/PharmacyDashboard';
import PathologyDashboard from '../../components/Dashboard/PathologyDashboard';
import RadiologyDashboard from '../../components/Dashboard/RadiologyDashboard';
import CovidCenterDashboard from '../../components/Dashboard/CovidCenterDashboard';
import NurseReceptionistDashboard from '../../components/Dashboard/NurseReceptionistDashboard';
import { hasRole } from '../../utils/auth';

class DashboardPage extends React.Component {
    render() {
        const { selected_user_profile } = this.props
        return (<React.Fragment>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            {
                hasRole(selected_user_profile, ['doctor', 'associate-doctor', 'medical-practice-manager']) && <DoctorDashboard selected_user_profile={selected_user_profile} />
            }
            {
                hasRole(selected_user_profile, ['pharmacy', "pharmacy-manager"]) && <PharmacyDashboard selected_user_profile={selected_user_profile} />
            }
            {
                hasRole(selected_user_profile, ['pathology']) && <PathologyDashboard selected_user_profile={selected_user_profile} />
            }
            {
                hasRole(selected_user_profile, ['radiology']) && <RadiologyDashboard selected_user_profile={selected_user_profile} />
            }
            {
                hasRole(selected_user_profile, ['covid-center-owner']) && <CovidCenterDashboard selected_user_profile={selected_user_profile} />
            }
            {
                hasRole(selected_user_profile, ['doctor-nurse', 'doctor-receptionist']) && <NurseReceptionistDashboard selected_user_profile={selected_user_profile} />
            }
        </React.Fragment>);
    }
}

const mapStateToProps = (state) => ({
    authenticated: state.session.authenticated,
    selected_user_profile: state.selected_user_profile
});

const mapActionsToProps = ({
})

export default connect(mapStateToProps, mapActionsToProps)(DashboardPage);
