import React from 'react';
import SearchDoctor from './SearchDoctor';

const ReferralLetter = ({ validator, form_attributes, handleChangeCertificate, handleSearchDoctor }) => {
    return (
        <React.Fragment>
            <h6 className="blue-main-heading grey-border-bottom mb-4">Referral Letter</h6>
            <div className="mb-4 row">
                <label htmlFor="staticEmail" className="col-auto col-form-label color-grey">Referred To :</label>
                <div className="col-sm-10">
                    <SearchDoctor
                        doctor={form_attributes.doctor}
                        validator={validator}
                        handleSearchDoctor={handleSearchDoctor}
                    />
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-24">
                    <h6 className="color-grey">Dear Dr:</h6>
                </div>
                <div className="col-md-24">
                    <textarea className="form-control" placeholder="Type content here" name="referred_reason" value={form_attributes.referred_reason} onChange={handleChangeCertificate.bind(this)}></textarea>
                    {validator.message('Reason of referring', form_attributes.referred_reason, 'required|string')}
                </div>
            </div>
        </React.Fragment>
    )
}

export default ReferralLetter;