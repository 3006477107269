import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function (state = initialState.changedPrescription, { type, ...payload }) {
    switch (type) {
        case types.FETCH_CHANGED_PRESCRIPTION_REQUEST:
            return {
                ...state,
                ...payload
            };
        case types.FETCH_CHANGED_PRESCRIPTION_SUCCESS:
            return {
                ...state,
                ...payload
            };
        case types.FETCH_CHANGED_PRESCRIPTION_FAILED:
            return {
                ...state,
                ...payload
            };
        case types.CLEAR_CHANGED_PRESCRIPTION:
                return {
                };     
        default:
            return state;
    }
}