import React from 'react';
import DashboardService from '../../services/dashboardService';
import Notification from '../../utils/notification';
import Card from './Card';

class NurseJrDoctorDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            assignedCount: '',
            approvePendingCount: ''
        }
    }

    componentDidMount() {
        Promise.all([this.getDashboardCounts("assigned-prescriptions"), this.getDashboardCounts("approve-prescriptions")])
        this.interval = setInterval(() => { Promise.all([this.getDashboardCounts("assigned-prescriptions"), this.getDashboardCounts("approve-prescriptions")]) }, 60000);
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    getDashboardCounts = (type) => {
        let filter = { status: 'draft' }
        return new Promise((resolve, reject) => {
            return resolve(DashboardService.getCountInfo(type, filter).then(resp => {
                if (type === "assigned-prescriptions") {
                    this.setState({
                        assignedCount: resp.data.assignedCount
                    })
                } else if (type === "approve-prescriptions") {
                    this.setState({
                        approvePendingCount: resp.data.approve_pending_count
                    })
                }
            }).catch(error => {
                Notification.show('error', error.response.data);
                return false;
            }))
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="col-md-12">
                    <Card
                        imgSrc="/images/assigned-prescription.png"
                        label="Assigned Patient"
                        count={this.state.assignedCount}
                    />
                </div>
                <div className="col-md-12">
                    <Card
                        imgSrc="/images/approval-pending-doctor.png"
                        label="Approval Pending From Doctor"
                        count={this.state.approvePendingCount}
                    />
                </div>
            </React.Fragment>
        )
    }
}
export default NurseJrDoctorDashboard
