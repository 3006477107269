import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class UserAccountService {
    static invoiceList(service_provider_id, { page, sizePerPage, sortBy, searchText }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText })
        };

        return axios.get(apiRoute("/v1/service-providers/" + service_provider_id + "/invoices"), requestOptions);
    }

    static statementList(service_provider_id, { page, sizePerPage, sortBy, searchText }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText })
        };

        return axios.get(apiRoute("/v1/service-providers/" + service_provider_id + "/statements"), requestOptions);
    }

    static viewServiceProviderStatements(service_provider_id, statement_id) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute('/v1/service-providers/' + service_provider_id + '/statements/' + statement_id), requestOptions);
    }

    static viewServiceProviderInvoices(service_provider_id, invoice_id) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute('/v1/service-providers/' + service_provider_id + '/invoices/' + invoice_id), requestOptions);
    }
}

export default UserAccountService;