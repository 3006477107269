import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class AirlineService {
    static getPatient(patientId, query) {
        const requestOptions = {
            headers: getApiHeader(),
            params: query
        };

        return axios.get(apiRoute('/v1/airlines/patients/' + patientId), requestOptions);
    }

    static getPatientCovidDocuments(patientId, query) {
        const requestOptions = {
            headers: getApiHeader(),
            params: query
        };

        return axios.get(apiRoute('/v1/airlines/patients/'+patientId+'/pathology-documents'), requestOptions);
    }
    
    static getScannedPatients({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        }
        return axios.get(apiRoute("/v1/airlines/scanned-patients"), requestOptions);
    }
}

export default AirlineService;