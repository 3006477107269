import React from 'react';
import DoctorService from '../../services/doctorService';
import _ from 'lodash';
import AutoSuggest from "../AutoSuggest";

class DoctorSearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            options: [],
            filter:{
                specialization:''
            }
        }
    }

    _handleSearch = (query) => {
        if(_.isEmpty(this.props.share_with_doctor.doctor_specialization)){
            return false;
        }

        this.setState({ 
            isLoading: true,
            filter:{
                role_slug:'doctor',
                specialization: this.props.share_with_doctor.doctor_specialization
            }
        });
        const filter = {
            role_slug: 'doctor',
            specialization: this.props.share_with_doctor.doctor_specialization
        };
        DoctorService.searchDoctor({ searchText: query, filter})
            .then(resp => {
                const items = resp.data.data;
                
                const options = items.map(i => ({
                    doctor_id: i.user_profile_id,
                    doctor_name: i.full_name,
                    specialization: this.state.filter.specialization,
                    service_provider_id: i.service_provider_id,
                }));
                
                this.setState({
                    isLoading: false,
                    options: options
                });
            });
    }

    _onChange = (option) => {
        let { share_with_doctor } = this.props;

        if (!_.isEmpty(option)) {
            share_with_doctor.doctor_id = option.doctor_id;
            share_with_doctor.doctor_name = option.doctor_name;
            share_with_doctor.service_provider_id = option.service_provider_id;
        } else {
            share_with_doctor.doctor_id = "";
            share_with_doctor.doctor_name = "";
            share_with_doctor.service_provider_id = "";
        }

        this.props.handleShareWith(share_with_doctor);
    }

    render() {
        return (<React.Fragment>
                <AutoSuggest
                    allowNew={false}
                    isLoading={this.state.isLoading}
                    options={this.state.options}
                    newSelectionPrefix="New Doctor: "
                    labelKey="doctor_name"
                    placeholder="Search doctor"
                    defaultValue={this.props.share_with_doctor.doctor_name}
                    onSearch={this._handleSearch}
                    onChange={this._onChange}
                    renderMenuItem={(option) => {
                        return <div key={option.doctor_id}>
                            <div>{option.doctor_name}</div>
                            <small>Specialization: {option.specialization}</small>
                        </div>
                    }}
                />
        </React.Fragment>
        );
    }
}

export default DoctorSearch;