import AmbulanceService from '../services/ambulanceService';
import Notification from '../utils/notification';

export function list({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: 'AMBULANCE_RECORD_REQUEST', request: { page, sizePerPage, sortBy, searchText, filter } });

        AmbulanceService.paginateRecords({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: 'AMBULANCE_RECORD_SUCCESS', ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    dispatch({ type: 'AMBULANCE_RECORD_FAILED' });
                    Notification.show('error', error.response.data)
                }
            });
    }
}

export function get(recordId) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: 'FETCH_AMBULANCE_RECORD_REQUEST'});

        AmbulanceService.getRecord(recordId)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: 'FETCH_AMBULANCE_RECORD_SUCCESS', ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    dispatch({ type: 'FETCH_AMBULANCE_RECORD_FAILED' });
                    Notification.show('error', error.response.data)
                }
            });
    }
}