import * as types from './actionTypes';
import CardService from '../services/cardService';
import Notification from '../utils/notification'

export function reissueCardList({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.PENDING_CARD_LIST, request: { page, sizePerPage, sortBy, searchText, filter } });

        CardService.reissueCardList({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: types.PENDING_CARD_LIST, data });
            })
            .catch(error => {
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                if (error.response && error.response.status) {
                    Notification.show('error', error.response.data)
                }
            });
    }
}