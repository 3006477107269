import { isEmpty } from 'lodash';
import React from 'react';
import * as ConductedSubmittedBlock from '../ConductedSubmittedBlock';

class VitalInfo extends React.Component {
    render() {
        const { vital, patient_info } = this.props;
        return (<div className="row">
            <div className="col-md-24">
                <ConductedSubmittedBlock.ConductedBySubmittedAt
                    name={vital.submitted_by}
                    date={vital.submitted_at}
                />
                <ul className="prescription-patient-details">
                    <li>Height: <span>{(vital && vital.patient_height) ? parseInt(vital.patient_height) : '--'} cms</span></li>
                    <li>Weight: <span>{(vital && vital.patient_weight) ? parseFloat(vital.patient_weight).toFixed(2) : '--'} kgs</span></li>
                    <li>Blood Pressure: <span>{(vital && vital.patient_blood_pressure_max) ? parseInt(vital.patient_blood_pressure_max) : '--'}/{(vital && vital.patient_blood_pressure_min) ? parseInt(vital.patient_blood_pressure_min) : '--'} mm of Hg</span></li>
                    <li>Respiratory Rate: <span>{(vital && vital.patient_respiratory_rate) ? parseInt(vital.patient_respiratory_rate) : '--'} BPM</span></li>
                    <li>Pulse Rate: <span>{(vital && vital.patient_pulse_rate) ? parseInt(vital.patient_pulse_rate) : '--'} BPM</span></li>
                    <li>Pulse Ox: <span>{(vital && vital.patient_pulse_ox) ? parseFloat(vital.patient_pulse_ox).toFixed(2) : '--'} SpO2</span></li>
                </ul>
                <ul className="prescription-patient-details">
                    <li>GMR: <span>{(vital && vital.patient_rbg) ? parseFloat(vital.patient_rbg).toFixed(2) : '--'}</span></li>
                    <li>Temperature: <span>{(vital && vital.patient_temperature) ? parseFloat(vital.patient_temperature).toFixed(2) : '--'} F</span></li>
                    <li>BMI: <span>{(vital && vital.patient_bmi) ? parseFloat(vital.patient_bmi).toFixed(2) : '--'} kg/m²</span></li>
                    <li>Category: <span>
                        {(vital && vital.patient_bmi_category) ?
                            (patient_info.age <= 19 && patient_info.gender === "other") ?
                                vital.patient_bmi_category &&
                                    vital.patient_gender_other === "male" ? <span>{vital.patient_bmi_category} (Boy)</span> :
                                    vital.patient_gender_other === "female" ? <span>{vital.patient_bmi_category} (Girl)</span> : vital.patient_bmi_category
                                :
                                vital.patient_bmi_category
                            : '--'}
                    </span>
                    </li>

                    <li>Blood Group: <span>{(vital && vital.patient_blood_group) ? vital.patient_blood_group : '--'}</span></li>
                    <li>Urinalysis: <span>{(vital && vital.patient_urinalysis) ? vital.patient_urinalysis : '--'}</span></li>
                    <li>Allergies: <span>{(vital && !isEmpty(vital.patient_allergy)) ? vital.patient_allergy.join(", ") : '--'}</span></li>
                    <li>Comorbidities: <span>{(vital && !isEmpty(vital.patient_comorbidities)) ? vital.patient_comorbidities.join(", ") : '--'}</span></li>
                </ul>
            </div>
        </div>)
    }
}
export default VitalInfo