import * as types from '../actions/actionTypes';

export default function (state = {}, { type, ...payload }) {
    switch (type) {
        case types.RESET_PATIENT:
            return {};
        case types.ADD_PATIENT:
            return {
                ...payload
            };
        case types.FETCH_PATIENT:
            return {
                ...payload
            };
        case types.UPDATE_PATIENT:
            return {
                ...payload
            };
        case types.SEARCH_PATIENT:
            return {
                ...payload
            };
        default:
            return state;
    }
}