import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class CptService {
    static searchwithPagination({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute("/v1/cpts/search"), requestOptions);
    }

    static getCptCrosswalk(cpt_code) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute("/v1/cpts/" + cpt_code + "/crosswalks"), requestOptions);
    }
}

export default CptService;