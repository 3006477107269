import React from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { handleRedirect } from '../../utils/auth';

class PermissionDeniedPage extends React.Component {
    render() {
        return (
            <div className="row justify-content-md-center">
                <div className="col-md-24">
                    <div className="wrapper-404 text-center">
                        <img src="images/403.png" alt="denied" />
                        <h5>ACCESS DENIED...</h5>
                        <p>You don't have permission to access this page.</p>
                        <Link to={handleRedirect(this.props.selected_user_profile)} className="btn btn-primary mb-5">GO To Home</Link>
                    </div>
                </div>
            </div>
        )
    }

}
const mapStateToProps = state => ({
    selected_user_profile: state.selected_user_profile,
});

const mapActionsToProps = {};

export default connect(
    mapStateToProps,
    mapActionsToProps
)(PermissionDeniedPage)