import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class AppointmentService {
    static create({ data }) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute("/v1/queue"), data, requestOptions);
    }

    static list({ page, sizePerPage, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, filter })
        };

        return axios.get(apiRoute("/v1/queue"), requestOptions);
    }

    static remove(id) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.delete(apiRoute("/v1/queue/" + id), requestOptions);
    }

    static deleteAppointment(patient_id) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.delete(apiRoute("/v1/patients/" + patient_id + "/remove"), requestOptions);
    }

    static paginateAppointments({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        }
        return axios.get(apiRoute("/v1/appointments"), requestOptions);
    }

    static getDoctorByLocation(location_id) {
        const requestOptions = {
            headers: getApiHeader(),
        }
        return axios.get(apiRoute("/v1/doctors?location_id=" + location_id), requestOptions);
    }

    static getSlotdates(locationId, doctorProfileId) {
        const requestOptions = {
            headers: getApiHeader(),
            params: {
                doctor_profile_id: doctorProfileId
            }
        }
        return axios.get(apiRoute("/v1/slots/locations/" + locationId + "/dates"), requestOptions);
    }

    static createSlot(slotData) {
        const requestOptions = {
            headers: getApiHeader(),
        }
        return axios.post(apiRoute("/v1/slots"), slotData, requestOptions);
    }

    static getSlot({ page, sizePerPage, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, filter })
        }
        return axios.get(apiRoute("/v1/slots"), requestOptions);
    }
    static deleteSlot(params) {
        const requestOptions = {
            headers: getApiHeader(),
            params: params
        }
        return axios.delete(apiRoute("/v1/slots"), requestOptions);
    }
    static getSlotHolidays(user_location_id, { page, sizePerPage, sortBy, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, filter })
        }
        return axios.get(apiRoute("/v1/slots/locations/" + user_location_id + "/holidays"), requestOptions);
    }
    static slotCount(params) {
        const requestOptions = {
            headers: getApiHeader(),
            params: params
        }
        return axios.get(apiRoute("/v1/slots/available/count"), requestOptions);
    }
    static createAppointment(data) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute("/v1/appointments"), data, requestOptions);
    }
    static rescheduleAppointment(appointment_id, data) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute(`/v1/appointments/${appointment_id}/reschedule`), data, requestOptions);
    }
    static deleteAppointmentById(id) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.delete(apiRoute(`/v1/appointments/${id}`), requestOptions);
    }
    static todaysAppointments({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        }
        return axios.get(apiRoute("/v1/todays-appointments"), requestOptions);
    }
}


export default AppointmentService;