import React from 'react';
import BarGraph from '../Graph/BarGraph';

class PatientVisitedGraph extends React.Component {

    handlePatientVisitedView = (view) => {
        this.props.renderDashboardGraph('patient_visited', view)
    }

    render() {
        return (
            <BarGraph
                barColor="#7BE874"
                barLabel="Patient Visits"
                yScaleLabel="No. Of Patients"
                datasetLabels={this.props.patient_added_graph_data.x_axis_label}
                datasetCounts={this.props.patient_added_graph_data.counts}
                handleViewChange={this.handlePatientVisitedView}
            />
        )
    }
}
export default PatientVisitedGraph