import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import BodyChart from './BodyChartComponent'
import ViewBodyChart from './ViewBodyChartComponent'
class FemaleFront extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      paths: [{
        "className": "head",
        "value": "Head",
        "name":"head",
        "d": "M342.16,18.833c-25.906,0-43.827,13.667-50.414,35.475c-3.214,10.641-3.598,24.057-3.598,40.511c0,2.183,0.059,4.363,0.16,6.536c0.521,11.222,2.31,22.173,4.608,31.583c3.209,13.141,7.402,23.275,10.496,26.914c3.472,5.323,9.371,9.767,13.83,13.25c7.537,5.888,14.671,9.029,26.037,9.029c11.718,0,20.105-3.845,27.271-10.22c3.897-3.467,8.842-8.387,10.528-12.226c3.354-6.575,6.155-17.269,8.3-28.02c2.513-12.598,4.122-25.258,4.663-31.473c0.133-1.537,0.204-2.694,0.204-3.341C394.245,81.727,402.167,18.833,342.16,18.833z"
      }, {
        "className": "eye-rt",
        "value": "Eye [RT]",
        "name":"eye-rt",
        "d": "M330.873,88.935c0-4.836-5.481-8.756-13.478-8.756c-7.995,0-14.477,3.92-14.477,8.756c0,4.836,6.481,8.756,14.477,8.756C325.392,97.691,330.873,93.771,330.873,88.935z"
      }, {
        "className": "eye-lt",
        "value": "Eye [LT]",
        "name":"eye-lt",
        "d": "M366.229,97.691c7.995,0,14.477-3.92,14.477-8.756c0-4.836-6.481-8.756-14.477-8.756c-7.996,0-13.478,3.92-13.478,8.756C352.751,93.771,358.232,97.691,366.229,97.691z"
      }, {
        "className": "nose",
        "value": "Nose",
        "name":"nose",
        "d": "M328.035,116.752c-0.408,4.419,5.838,7.005,9.574,6.071c4.67,2.452,6.304,0.583,9.223-0.584c5.371,0.817,7.473-3.036,7.473-7.238c0-4.203-4.087-6.305-4.554-11.442s-3.152-7.122-2.919-12.609s-2.218-9.223-5.684-9.223c-4.866,0-5.874,7.355-5.524,9.924c0.351,2.568-1.984,9.924-2.335,12.843S328.619,110.448,328.035,116.752z"
      }, {
        "className": "mouth",
        "value": "Mouth",
        "name":"mouth",
        "d": "M350.479,132.547c-2.009-2.389-5.549-1.449-7.013-0.658c-0.785,0.424-3.116,0.768-4.563-0.329s-5.454-0.109-6.567,1.426c-1.113,1.536-10.464,5.497-11.242,8.128c-0.778,2.632,7.681,3.851,9.795,4.619c2.115,0.768,4.008,3.29,11.688,3.29c7.682,0,9.765-2.445,11.799-2.851c2.035-0.406,7.235-1.987,7.569-4.071C362.278,140.018,353.708,136.386,350.479,132.547z"
      }, {
        "className": "ear-rt",
        "value": "Ear [RT]",
        "name":"ear-rt",
        "d": "M281.25,97.125c-2.875,0.625-4.125,8.25-3.125,13.625s3.909,14.25,6.455,18.75c2.337,4.131,4.847,9.557,8.337,3.438c-2.298-9.411-4.087-20.362-4.608-31.583C285.986,99.067,283.102,96.723,281.25,97.125z"
      }, {
        "className": "ear-lt",
        "value": "Ear [LT]",
        "name":"ear-lt",
        "d": "M396,132.625c1.5-2.375,4.375-8.875,5.75-12.625s2.875-14.25,2.375-18.875s-4.75-4.875-6.5-2.25c-0.807,1.209-2.197,1.536-3.584,1.318c-0.541,6.214-2.149,18.875-4.663,31.473C391.777,136.98,394.819,134.495,396,132.625z"
      }, {
        "className": "neck",
        "value": "Neck",
        "name":"neck",
        "d": "M315.245,235.186c7.666-1.333,14,3.667,18.333,7.667s11.666,4,15.333,0s12.001-9.333,24.334-7.333s16.333-1,26.333-6c13.203-6.602,19.454-9.105,36.681-14.544c-4.235-0.279-8.442-0.39-12.514-0.622c-17.5-1-38.957-4.63-42.5-13.333c-4.079-10.019-3.245-27.352-0.167-41.333c-1.687,3.839-6.631,8.759-10.528,12.226c-7.165,6.375-15.553,10.22-27.271,10.22c-11.366,0-18.5-3.142-26.037-9.029c-4.459-3.483-10.358-7.927-13.83-13.25c0.333,2.167,2.75,18.772,1.833,32.25c-0.912,13.398-10.762,16.644-31.612,20.584c-8.076,1.526-21.371,2.995-29.388,3.666c20.666,3.5,43.333,10.5,47.333,13.5S307.579,236.519,315.245,235.186z"
      }, {
        "className": "chest",
        "value": "Chest",
        "name":"chest",
        "d": "M443.389,320.851c-1.799-4.795-3.164-9.618-2.81-16c0.434-7.804,1.971-18.29,1.593-28.995c-0.712-20.142-8.204-41.059-42.594-46.337c-10,5-14,8-26.333,6s-20.667,3.333-24.334,7.333s-11,4-15.333,0s-10.667-9-18.333-7.667s-19.667-2.333-23.667-5.333c-32.877,3.202-47.718,24.43-48.082,49.281c-0.053,3.622,0.198,7.319,0.749,11.05c1.906,12.904-0.123,23.486-2.841,33.069c-3.46,12.201-7.175,14.914-7.034,34.474c1.25,8,5.125,16.75,8.875,21.25c0.494,4.944,3.648,20.29,5.687,28.839c0.539,2.259,1,4.043,1.313,5.036c0.467,1.478,1.176,8.086,1.885,16.806c2.263,3.992,7.449,32.313,48.83,14.238c12.048-5.262,24.116-13.146,41.201-13.146s30.371,8.46,41.064,13.26c40.021,17.962,46.986-6.785,50.044-16.517c0.871-10.137,1.665-17.716,1.976-18.765c1-3.375,5.166-24.208,5.833-30.042c3.333-4,7.917-15,8.333-19.417C451.936,339.241,446.857,330.096,443.389,320.851z"
      }, {
        "className": "breasts",
        "value": "Breasts",
        "name":"breasts",
        "d": "M443.389,320.851c-16.477-44.995-66.144-13.999-101.228-13.999s-84.915-32.333-100.757,16.401c-3.46,12.201-7.175,14.914-7.034,34.474c1.25,8,5.125,16.75,8.875,21.25c23,29.375,52.834,11.539,67-1c8.393-7.428,20.5-14.811,31.916-14.811c9.584,0,18.084,4.821,27.584,12.811c22,18.503,54,26.625,71.333,2.708c3.333-4,7.917-15,8.333-19.417C451.936,339.241,446.857,330.096,443.389,320.851z"
      },{
        "className": "abdomen",
        "value": "Abdomen",
        "name":"abdomen",
        "d": "M383.226,444.009c-10.693-4.799-23.979-13.26-41.064-13.26s-29.153,7.884-41.201,13.146c-41.381,18.075-46.567-10.247-48.83-14.238c1.571,19.306,3.148,48.961,2.115,56.194c-1.135,7.941-9.276,28.04-14.799,41.473c3.636,7.516,25.04,39.648,101.913,39.648s99.986-31.362,104.039-39.648c-2.226-5.323-4.486-11.973-5.403-14.723c-1.25-3.75-6.75-17-9.25-23.25c-1.723-4.308,0.592-39.376,2.524-61.86C430.212,437.224,423.246,461.971,383.226,444.009z"
      },{
        "className": "pelvis",
        "value": "Pelvis",
        "name":"pelvis",
        "d": "M341.359,566.973c-76.873,0-98.277-32.132-101.913-39.648c-1.779,4.327-3.287,7.963-4.201,10.277c-0.718,1.819-4.753,12.178-9.266,28.098c20.901,5.683,66.602,53.919,86.013,79.232c15.253-7.08,41.929-7.299,60.341-0.689c16.61-24.865,56.122-72.163,85.544-78.591c-4.775-18.663-9.193-31.593-10.132-33.299c-0.733-1.333-1.538-3.092-2.347-5.027C441.346,535.611,418.232,566.973,341.359,566.973z"
      },{
        "className": "pubis",
        "value": "Pubis",
        "name":"pubis",
        "d": "M311.992,644.932c5.745,7.492,9.585,12.977,10.419,14.754c15.333,17.167,28.667,12.5,40.834-0.834c0.847-1.714,4.106-7.152,9.088-14.609C353.921,637.633,327.245,637.852,311.992,644.932z"
      },{
        "className": "shoulder-lt",
        "value": "Shoulder [LT]",
        "name":"shoulder-lt",
        "d": "M442.172,275.855c5.073,20.997,49.579,18.889,52.073,36.997c0-5.5-5-59.75-19-79.25c-10.742-14.963-25.018-17.708-38.986-18.628c-17.227,5.439-23.478,7.943-36.681,14.544C433.968,234.797,441.46,255.713,442.172,275.855z"
      },{
        "className": "shoulder-rt",
        "value": "Shoulder [RT]",
        "name":"shoulder-rt",
        "d": "M243.496,279.133c0.364-24.852,15.205-46.08,48.082-49.281c-4-3-26.667-10-47.333-13.5c-8.017,0.671-36.833-0.833-45.5,44.5c-1.326,6.936-6.612,29.151-8.441,49.644C198.757,297.442,235.279,295.249,243.496,279.133z"
      },{
        "className": "arm-lt",
        "value": "Arm [LT]",
        "name":"arm-lt",
        "d": "M517.358,401.382c-0.201-0.769-0.409-1.559-0.633-2.391c-2.22-8.277-5.535-19.675-10.48-34.389c-3.74-11.128-6.349-20.293-8.152-27.713c-1.16-4.771-1.988-8.82-2.571-12.207c-0.995-5.78-1.276-9.627-1.276-11.83c-2.494-18.108-47-16-52.073-36.997c0.378,10.705-1.159,21.191-1.593,28.995c-0.354,6.382,1.011,11.205,2.81,16c3.469,9.245,8.547,18.391,6.022,38.418c0.333,1.917,1.834,4.083,2.667,6.917s7.334,22.667,10.334,30.833c1.362,3.708,3.618,8.688,5.8,13.723C473.072,423.191,524.631,432.896,517.358,401.382z"
      },{
        "className": "arm-rt",
        "value": "Arm [RT]",
        "name":"arm-rt",
        "d": "M241.404,323.253c2.718-9.583,4.747-20.165,2.841-33.069c-0.551-3.731-0.802-7.429-0.749-11.05c-8.217,16.115-44.739,18.309-53.192,31.362c-0.268,3.007-0.463,5.978-0.559,8.856c-0.697,20.911-17.797,65.556-22.819,82.598c1.973,21.535,38.65,20.571,48.624,11.675c0.842-2.145,1.608-3.973,2.195-5.273c2.333-5.167,15.667-39.667,16.625-50.625C234.229,338.167,237.944,335.454,241.404,323.253z"
      },{
        "className": "elbow-lt",
        "value": "Elbow [LT]",
        "name":"elbow-lt",
        "d": "M504.594,444.005c11.671-5.155,25.484-5.065,32.105,9.867c-1.364-3.261-2.682-6.233-3.891-8.824c-1.132-2.424-2.169-4.515-3.063-6.196c-6.25-11.75-8-20-9-23.75c-0.469-1.759-1.437-6.279-3.387-13.72c7.272,31.514-44.286,21.81-49.146,9.359c2.623,6.052,5.139,12.183,5.866,16.277c0.87,4.895,2.834,15.833,4.5,21.333c0.842,2.78,2.11,9.819,3.504,17.005c1.362,7.03,2.845,14.2,4.163,17.662C481.555,462.252,491.784,449.663,504.594,444.005z"
      },{
        "className": "elbow-rt",
        "value": "Elbow [RT]",
        "name":"elbow-rt",
        "d": "M166.926,401.95c-0.381,1.295-0.701,2.449-0.931,3.402c-3.25,13.5-5.75,23.5-9.5,29.75c-1.344,2.239-4.423,8.375-7.957,16.714c8.213-9.979,21.624-12.289,31.76-7.812c10.472,4.626,18.204,17.605,19.489,33.772c0.413-1.687,0.739-3.183,0.957-4.425c1.667-9.5,6.5-29,8-37.667c1.123-6.486,4.299-15.679,6.805-22.061C205.576,422.521,168.898,423.484,166.926,401.95z"
      },{
        "className": "forearm-lt",
        "value": "Forearm [LT]",
        "fill": "transparent",
        "stroke": "#ffc0cb",
        "cursor": "default",
        "name":"forearm-lt",
        "d": "M555.942,591.821c5.137-2.229,10.528-4.53,12.336-8.833c-0.175-0.452-0.352-0.911-0.533-1.386c-6.5-17-11.5-47.25-15-73.25c-2.464-18.305-9.761-39.461-16.046-54.48c-6.621-14.932-20.435-15.022-32.105-9.867c-12.81,5.658-23.039,18.248-18.349,39.014c2.667,7,11,35,20,52c7.483,14.136,23.78,47.339,29.779,59.996C541.258,595.928,550.457,594.202,555.942,591.821z"
      },{
        "className": "forearm-rt",
        "value": "Forearm [RT]",
        "name":"forearm-rt",
        "d": "M180.299,444.005c-10.137-4.478-23.547-2.167-31.76,7.812c-6.328,14.935-14.119,36.964-16.043,56.536c-4.38,44.525-10.921,61.208-15.92,74.535c1.766,4.367,7.199,6.687,12.375,8.934c5.517,2.395,14.79,4.127,20.007,3.178c2.049-4.53,4.271-9.329,6.538-13.397c8.5-15.25,21.75-43.417,28.75-59.083c6.084-13.618,12.796-33.527,15.543-44.742C198.503,461.61,190.771,448.631,180.299,444.005z"
      },{
        "className": "wrist-lt",
        "value": "Wrist [LT]",
        "name":"wrist-lt",
        "d": "M558.829,607.838c7.824-4.917,17.79-6.998,26.583-2.986c-9.072-4.698-11.06-6.172-17.134-21.864c-1.808,4.303-7.199,6.604-12.336,8.833c-5.485,2.381-14.685,4.107-19.918,3.194c1.216,2.565,2.01,4.29,2.221,4.837c1.25,3.25,5.625,12.75,5.75,15.5c0.057,1.237,0.467,4.698,1.117,9.02C543.745,612.54,550.563,613.033,558.829,607.838z"
      },{
        "className": "wrist-rt",
        "value": "Wrist [RT]",
        "name":"wrist-rt",
        "d": "M128.95,591.821c-5.177-2.247-10.61-4.566-12.375-8.934c-0.683,1.821-1.338,3.582-1.955,5.34c-2.742,7.825-5.627,12.177-8.158,14.688c6.81-0.805,13.795,1.275,19.601,4.923c6.229,3.915,11.635,4.602,13.336,9.767c1.027-3.539,3.603-9.493,6.953-16.841c0.824-1.808,1.699-3.763,2.604-5.765C143.74,595.948,134.467,594.215,128.95,591.821z"
      },{
        "className": "hand-lt",
        "value": "Hand [LT]",
        "name":"hand-lt",
        "d": "M629.578,635.019c-3.334-5.167-13.666-10-17-13.5s-17.833-11.833-27.166-16.667c-8.793-4.012-18.759-1.931-26.583,2.986c-8.266,5.195-15.084,4.702-13.717,16.534c0.795,5.289,1.951,11.865,3.258,17.23c2.375,9.75,2.125,16.25,3.5,22.375c1.375,6.125,4.875,14.5,5.75,21.25s3.375,7.25,5.5,15.25s6.875,8.375,8.125,6.125s0.375-7.5-0.375-8.5s-1.5-3.125-1.875-5.25s-1.625-8.375-2.125-11.5s-3.125-13.125-2.25-14.5s3.5,4,3.75,5.875s3,14.875,5,21.25s4,18.5,7.125,22.875s7.125,1.125,7.125-2.875s-3-17.125-3.25-20s-3.875-20-4.375-21.625s2.5-1.125,2.75,0.25s1.75,10.625,3,14.375s5,18.125,5.5,20.75s2,14.125,6.125,16.25s5.875-2.125,6.25-5.875s-5.5-30-6.375-34.5s-4.375-16.875-3-18.125c1.375-1.25,4.875,11.75,5.5,15.75s4.375,22.875,5.875,26.625s5.75,4.75,7.5,1.5s-1.5-24.375-1.875-31.375s-4.375-14.25-4.5-21.25s-5.25-20.5-4.834-22.708c0.499-2.646,9.167,0.333,12.167,2.667c3,2.333,8.5,8.5,11.5,10s8.833,3.833,10.167,0C637.079,642.852,632.912,640.186,629.578,635.019z"
      },{
        "className": "hand-Rt",
        "value": "Hand [RT]",
        "name":"hand-rt",
        "d": "M126.063,607.838c-5.806-3.648-12.791-5.729-19.601-4.923c-2.199,2.181-4.13,2.973-5.467,3.438c-2.875,1-20.625,11.625-26.375,15.25s-8.625,9.375-14.25,12.125s-8.5,6.875-10,8.625s-6.625,7.5-6.625,9.875s4.375,3.75,8,2.875s11.75-8.625,15.75-12.375s9.5-4.875,12.25-4.875s2.625,3.125,1.875,4.5s-2.875,8.25-4,13.625s-1,11.875-1.875,14s-5,19.375-5.625,23.625s-2.625,15.5,0,19s6,1.25,7.125-1.375s3.625-14.125,4.086-16.875s6.789-21.125,7.414-22.75s3.125,2.625,2.25,5.75s-2.375,11.75-4.75,18.5s-2.625,14.125-3.375,17.5s-1.75,8.125-1.5,11.5s3.125,5.875,5.5,4.625s4.875-9.5,5.5-12.5s4-14.375,5.625-18.375s3-20.5,4-23s1.875,1.125,1.25,2.75s-3.375,18-3.75,21.625s-3.5,14.875-2.625,19.125s5.125,3.5,6.75,1.75s2.5-7.125,3-9.75s4.125-13.375,5.427-17.5s3.448-16.25,4.073-20.25s2-1.625,1.125,1.75s-0.875,11.875-1.75,14.25s-1,8.125-1.75,9.625s-2.75,5.875-1,9s5,1.5,5.875,0.125s3.75-10.625,5-13.375s2.625-11,3.75-15.875s3.208-11,4.375-17.5c1.167-6.5,1.333-14,2.5-18.334c1.167-4.333,4.5-18.666,4.667-23c0.022-0.589,0.196-1.409,0.487-2.413C137.699,612.44,132.293,611.753,126.063,607.838z"
      },{
        "className": "thigh-lt",
        "value": "Thigh [LT]",
        "name":"thigh-lt",
        "d": "M372.333,644.243c-4.981,7.457-8.241,12.896-9.088,14.609c-22,24.166-15.5,60.25-10.5,83.75s4,49.25,3.75,59.5c-0.192,7.873-2.694,56.297-3.015,88.533c7.488,47.217,22.265-11.783,80.561-5.816c0.635-3.859,1.253-7.717,1.87-11.301c1.666-9.666,18.001-92.666,21.001-108.666s14.579-62.191,14.583-105.25c0.003-32.74-7.137-68.625-13.618-93.951C428.455,572.08,388.943,619.377,372.333,644.243z"
      },{
        "className": "thigh-rt",
        "value": "Thigh [RT]",
        "name":"thigh-rt",
        "d": "M225.979,565.7c-8.939,31.535-19.756,84.918-10.4,137.151c1.925,10.749,29.666,157,32.416,171.75c0.446,2.395,1.164,5.843,2.016,10.031c54.814-4.183,72.884,49.144,80.681,10.055c-0.334-28.322-1.681-73.09-2.697-91.586c-0.494-9-1.657-28.941,3.583-60.582c2.38-14.367,13.417-54.918-9.167-82.833c-0.834-1.777-4.674-7.262-10.419-14.754C292.582,619.62,246.881,571.383,225.979,565.7z"
      },{
        "className": "knee-lt",
        "value": "Knee [LT]",
        "name":"knee-lt",
        "d": "M353.48,890.635c-0.097,9.738,0.006,17.998,0.431,23.219c1.833,22.5,4.167,31.666,7.667,44.5c15.167,23.998,59.667,38.498,64.917-16.002c0-11.75,2.083-30.834,4.083-39.166c1.259-5.246,2.386-11.811,3.463-18.367C375.745,878.852,360.969,937.852,353.48,890.635z"
      },{
        "className": "knee-rt",
        "value": "Knee [RT]",
        "name":"knee-rt",
        "d": "M250.011,884.633c3.118,15.324,8.045,40.617,8.183,61.056c9.946,48.058,48.957,36.435,64.312,13.894c0.233-1.731,0.557-3.249,0.989-4.48c3.25-9.25,7-21.25,7.25-44c0.047-4.254,0.023-9.899-0.053-16.414C322.895,933.776,304.825,880.45,250.011,884.633z"
      },{
        "className": "leg-lt",
        "value": "Leg [LT]",
        "name":"leg-lt",
        "d": "M361.578,958.354c3.5,12.832-5.166,55.332-7,75.166c-1.834,19.832,5,54.5,5.167,66.334c0.167,11.832,3.166,42.5,4.833,56.832c1.667,14.334,2.333,41.168-0.667,53.5c3.302-5.502,5.874-9.074,12.053-10.424c8.796-1.922,21.821,0.66,28.281,8.59c0.5-9.5,5.25-38.25,14.75-79.25s12.75-69.75,14.5-100.5s-0.5-49.75-2.25-55s-4.75-19.5-4.75-31.25C421.245,996.852,376.745,982.352,361.578,958.354z"
      },{
        "className": "leg-rt",
        "value": "Leg [RT]",
        "name":"leg-rt",
        "d": "M258.194,945.688c0.056,8.378-0.69,15.94-2.699,21.663c-9.481,27.008-3.25,94.75,0,112s21.25,99.75,24.084,121.502c0.292,2.238,0.427,4.588,0.471,6.978c7.033-6.858,20.667-9.863,28.878-8.069c4.987,1.089,8.275,3.628,11.094,7.459c-2.393-9.663-1.715-19.744-1.028-34.869c0.75-16.5,6.75-83.5,9.75-105.25s0.75-46.25-2.5-62c-2.817-13.653-5.256-34.25-3.739-45.52C307.151,982.123,268.141,993.746,258.194,945.688z"
      },{
        "className": "ankle-lt",
        "value": "Ankle [LT]",
        "name":"ankle-lt",
        "d": "M375.964,1199.762c-6.179,1.35-8.751,4.922-12.053,10.424c-3,12.334-3.166,15.168-1.166,22.334c2,7.168,0,8.5-1.833,18c4.667-11.668,38.198-9.256,42-3.5c-1.666-4.334,0.5-5.5,1.833-12.5s-1-16.668-0.5-26.168C397.785,1200.422,384.76,1197.84,375.964,1199.762z"
      },{
        "className": "ankle-rt",
        "value": "Ankle [RT]",
        "name":"ankle-rt",
        "d": "M308.929,1199.762c-8.211-1.794-21.846,1.211-28.878,8.069c0.207,10.988-1.584,22.862,0.196,29.022c1.613,5.583,2.301,5.904,1.311,9.828c7.505-4.416,35.265-5.709,42.285,2.941c-0.564-3.49-1.649-7.012-2.346-9.521c-1.25-4.5,0.25-8.75,1.75-11.25s0.75-8.5-2.5-19c-0.271-0.875-0.504-1.752-0.722-2.631C317.204,1203.39,313.916,1200.851,308.929,1199.762z"
      },{
        "className": "foot-lt",
        "value": "Foot [LT]",
        "name":"foot-lt",
        "d": "M360.912,1250.52c-1.833,9.5,2.666,9.834,0.333,14.166c-2.333,4.334-1.333,10.5-0.167,11.5s-1.333,2.5,3,7.168c4.333,4.666,13.834,2.666,15.334,2.166s3-1,4,0.201c1,1.203,6.5,0.633,7.666,0c1.166-0.631,3.334-1.201,4-0.318s5.167-0.383,7.5-0.049s5.834-1.168,8.167-0.834c2.333,0.332,7.167-2,7-6.5s-2.833-6.166-4.833-9.166s-8.334-17.5-10-21.834C399.11,1241.264,365.579,1238.852,360.912,1250.52z"
      },{
        "className": "foot-rt",
        "value": "Foot [RT]",
        "name":"foot-rt",
        "d": "M281.557,1246.682c-0.34,1.347-0.877,3.116-1.644,5.672c-3,10-9.334,16.832-12.5,22c-3.166,5.166-0.5,7.832,2.833,9.666c3.333,1.832,3.333-0.5,4.833,0.666c1.5,1.168,12.334,0.5,13.5,0.168c1.166-0.334,1.5-0.668,5.167,0.832s8.833-2.332,10.667-0.666s10,1.666,12.666,1.404s5.334-5.238,5.834-6.738s0.333-3,1-5s1.166-6.166-0.167-9c-1.333-2.832-1.5-3.332,0-8.332c0.664-2.211,0.543-4.961,0.095-7.73C316.821,1240.973,289.062,1242.266,281.557,1246.682z"
      },],
      body_chart: this.props.body_chart,
      slug:'female_front',
      isClickDisabled:false,
      chart:this.props.chart,
      
    }
  }

  componentDidMount() {
    let paths = this.state.paths;
    let { body_chart } = this.props;
    let isClickDisabled= this.props.isClickDisabled ? this.props.isClickDisabled : false;
    this.state.paths.map((path, i) => (
      paths[i].fill = "rgba(255, 255, 255, 0)"
    ));
    
    this.setState({
      paths: paths,
      isClickDisabled:isClickDisabled,
    });
    if(!_.isEmpty(body_chart)){
      paths.map((path, i) => {
        
        if(body_chart.some(body_charts => path.value === body_charts.part_name && this.state.slug === body_charts.image_slug)){
          path.className = path.className + " active";
          path.fill = "rgba(29, 81, 138)";
          }
        return path;
      });
      this.setState({paths: paths}); 
    }
    
  }
  
  setPaths = (paths) => {
    this.setState({
      paths: paths
    });
  };
  
  handleBodyChart = (bodyChart) => {
    let {body_chart} = this.state;
    bodyChart.map((chart, i) => {
        chart.image_slug = this.state.slug;
        chart.image_name= 'Female Front';
        return chart;
    });
    
    this.setState({ body_chart: body_chart.concat(bodyChart) })
    this.props.handleBodyChart(bodyChart)
      
  }
  removePart = (part) =>{
    let {body_chart,slug} = this.state;
    body_chart = body_chart.filter((s) =>{
            
      return part !== s.part_name || s.image_slug!==slug
    });
    this.setState({ body_chart: body_chart })
    this.props.removePart(part,slug)
  }
  removeBodyChart = (image_slug) =>{
    let { body_chart } = this.state;
    body_chart = body_chart.filter((s) =>{
        return s.image_slug!==image_slug
    });
    this.setState({ body_chart: body_chart })
    this.props.removeBodyChart(image_slug)
  }
  
  removeChart =(image_slug,e) =>{
    let { chart } = this.state;
    _.pull(chart, image_slug);
    this.setState({ chart: image_slug })
    this.removeBodyChart(image_slug)
  }
  changeHandler = (parts,e) => {
        
    let {body_chart,slug} = this.state;
    const result = body_chart.findIndex(s => s.part_slug === parts &&  s.image_slug===slug);
    
    body_chart[result][e.target.name] = e.target.value;
    this.setState({ body_chart: body_chart })
    this.props.changeHandler(parts,slug,e)
    
  }
  changeSubPartHandler = (parts, e) => {
    let {body_chart,slug} = this.state
    const result = body_chart.findIndex(s => s.part_slug === parts &&  s.image_slug===slug);

    body_chart[result]['sub_part'] = e.value;
    this.setState({ body_chart: body_chart })
    this.props.changeSubPartHandler(parts, slug, e)
  }
  render() {
    
    return (
      <>
        {!this.props.isViewBodyChart && <BodyChart
        pathConfig={this.state.paths}
        slug={this.state.slug}
        body_chart={this.props.body_chart}
        setPaths={this.setPaths}
        handleBodyChart={this.handleBodyChart}
        removeChart ={this.removeChart}
        removePart={this.removePart}
        changeHandler={this.changeHandler}
        changeSubPartHandler={this.changeSubPartHandler}
        />}
        {this.props.isViewBodyChart && <ViewBodyChart
        pathConfig={this.state.paths}
        body_chart={this.props.body_chart}
        />}
      </>
    );
  }
}

export default connect(null, null)(FemaleFront);