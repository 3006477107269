import React from "react";
import _ from "lodash";
import classNames from "classnames";

class Paginator extends React.Component 
{

  prevPageClicked = (evt) => {
    evt.preventDefault();

    if (this.props.currPage > 1) {
      this.props.onChange(Number(this.props.currPage) - 1);
    }
  }
  nextPageClicked = (evt) => {
    evt.preventDefault();

    if (this.props.currPage < this.props.lastPage) {
      this.props.onChange(Number(this.props.currPage) + 1);
    }
  }
  pageClicked = (pageNum, evt) => {
    evt.preventDefault();

    if (this.props.currPage !== pageNum) {
      this.props.onChange(Number(pageNum));
    }
  }
  renderPrevious = () => {
    var classStr = classNames({ disabled: this.props.currPage <= 1 });
    return (
      <li key="prev" className={"page-item " + classStr}>
        <a href rel="prev" onClick={this.prevPageClicked} className="page-link">
          «
        </a>
      </li>
    );
  }
  renderNext = () => {
    var classStr = classNames({
      disabled: this.props.currPage >= this.props.lastPage
    });
    return (
      <li key="next" className={"page-item " + classStr}>
        <a href rel="next" onClick={this.nextPageClicked} className="page-link">
          »
        </a>
      </li>
    );
  }
  renderDots = (key) => {
    return (
      <li key={key} className="disabled">
        <span>...</span>
      </li>
    );
  }
  renderNumber = (num) => {
    var classStr = classNames({ active: this.props.currPage === num });
    return (
      <li key={num} className={"page-item " +classStr}>
        <a href onClick={_.partial(this.pageClicked, num)} className="page-link">
          {num}
        </a>
      </li>
    );
  }
  renderRange = (firstNum, lastNum) => {
    var pages = [];
    for (var i = firstNum; i <= lastNum; i++) {
      pages.push(this.renderNumber(i));
    }
    return pages;
  }
  renderStart = () => {
    var pages = this.renderRange(1, 2);
    pages.push(this.renderDots("dots-start"));

    return pages;
  }
  renderFinish = () => {
    var pages = this.renderRange(this.props.lastPage - 1, this.props.lastPage);
    pages.unshift(this.renderDots("dots-finish"));

    return pages;
  }
  renderAdjacentRange = () => {
    return this.renderRange(this.props.currPage - 2, this.props.currPage + 2);
  }
  renderSlider = () => {
    var sliderNum = 6;
    var buttons = [];

    if (this.props.currPage <= sliderNum) {
      buttons = buttons.concat(this.renderRange(1, sliderNum + 2));
      buttons = buttons.concat(this.renderFinish());
    } else if (this.props.currPage >= this.props.lastPage - sliderNum) {
      buttons = buttons.concat(this.renderStart());
      buttons = buttons.concat(
        this.renderRange(this.props.lastPage - sliderNum, this.props.lastPage)
      );
    } else {
      buttons = buttons.concat(this.renderStart());
      buttons = buttons.concat(this.renderAdjacentRange());
      buttons = buttons.concat(this.renderFinish());
    }

    return buttons;
  }
  render() {
    var buttons = [];

    buttons.push(this.renderPrevious());

    if (this.props.lastPage <= 13) {
      buttons = buttons.concat(this.renderRange(1, this.props.lastPage));
    } else {
      buttons = buttons.concat(this.renderSlider());
    }

    buttons.push(this.renderNext());

    return (
      <div className="float-right mt-3">
        <ul className="pagination">{buttons}</ul>
      </div>
    );
  }
}

export default Paginator;
