import React from "react";
import { Modal } from 'react-bootstrap';
import PathologyService from '../../services/pathologyService';
import Notification from '../../utils/notification'
import _ from 'lodash'
import SimpleReactValidator from 'simple-react-validator';

class AddPathologyBlock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tests: '',
            searchKey: '',
            searchResult: [],
            selectedCategory: '',
            pathology: [],
            selectedTests: []
        }
        this.validator = new SimpleReactValidator();
        this.handleClick = this.handleClick.bind();
    }

    componentDidMount() {
        this.getAllPathologyTests()
    }
    getAllPathologyTests (){
        PathologyService.getAllPathologyTests().then(resp => {
            this.setState({
                tests: _.groupBy(resp.data.data, 'test_category')
            })
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }

    handleChange = (evt, index) => {
        let value = evt.target.value
        let pathology = this.state.pathology;
        pathology[index] = {
            test_id: '',
            test_type: '',
            test_category: '',
            test_name: value,
            test_description: '',
            is_other: true
        }
        this.setState({ pathology: pathology });
    }

    handleSearch = (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        const searchKey = this.state.searchKey.toLowerCase();
        let searchResult = []
        _.forEach(this.state.tests, function (value) {
            if (!_.isEmpty(value.test_category) && _.includes(value.test_category.toLowerCase(), searchKey)) {
                searchResult = searchResult.concat(value)
            } else if (!_.isEmpty(value.test_name) && _.includes(value.test_name.toLowerCase(), searchKey)) {
                searchResult = searchResult.concat(value)
            }
        });
        this.setState({
            searchResult: searchResult
        })
    }

    handleClick = (key) => {
        let data = _.filter(this.state.tests, function (test, index) {
            return index === key
        });
        this.setState({
            selectedCategory: data
        })
    }

    handleCheckbox = (e, test, index) => {
        const { selectedCategory } = this.state
        const options = selectedCategory[0].slice();
        if (options[index]) {
            options[index].checked = !options[index].checked;
        }
        if (e.target.checked) {
            this.setState({
                selectedTests: this.state.selectedTests.concat([{
                    test_id: test.test_id,
                    test_type: test.test_type,
                    test_category: test.test_category,
                    test_name: test.test_name,
                    test_description: test.test_description,
                    is_other: false
                }])
            })
        }
        if (!e.target.checked) {
            const selectedTests = this.state.selectedTests.filter((s) => test.test_id !== s.test_id);
            this.setState({ selectedTests: selectedTests });
        }

    }

    handleAddPathology = () => {
        this.setState({
            pathology: this.state.pathology.concat([{
                test_id: '',
                test_type: '',
                test_category: '',
                test_name: '',
                test_description: '',
                is_other: true
            }])
        });
    }

    handleRemovePathologyTest = (event) => {
        this.validator.hideMessages()
        const idx = Number(event.target.dataset["id"]);
        const pathology = this.state.pathology.filter((s, sidx) => idx !== sidx);
        this.setState({ pathology: pathology });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        if (!this.validator.allValid() && !_.isEmpty(this.state.pathology)) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        let newData = this.state.selectedTests.concat(this.state.pathology);
        if (!_.isEmpty(newData)) {
            this.props.handlePathology(newData);
        }
        this.setState({
            selectedCategory: '',
            pathology: [],
            selectedTests: []
        });
        this.props.handleAddPathologyBlock()
    }

    handleAddPathologyBlock = () => {
        this.validator.hideMessages()
        this.setState({
            selectedCategory: '',
            pathology: [],
            selectedTests: []
        });
        this.props.handleAddPathologyBlock()
    }

    render() {
        return (
            <>
                <Modal size='lg' centered aria-labelledby="contained-modal-title-vcenter" show={this.props.show} onHide={this.handleAddPathologyBlock} className="original-prescription">
                    <Modal.Header closeButton>
                        <h6>Add Pathology Test</h6>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="category-list">
                            {this.state.tests && <div>
                                <h3>Test Category</h3>
                                <div className="scroll-list">
                                    <ul>
                                        {this.state.tests && Object.keys(this.state.tests).map((key, index) => (
                                            <li onClick={e => this.handleClick(key)} key={index}>
                                                {key !== "null" ? key : "Profiles"}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>}
                            {this.state.selectedCategory && <div>
                                <h3>Test Name</h3>
                                <div className="scroll-list">
                                    <ul>
                                        {this.state.selectedCategory[0].map((test, index) => (
                                            <li key={test.test_id}>
                                                <input type="checkbox" id={test.test_id} name="test_name" onClick={e => this.handleCheckbox(e, test, index)} defaultChecked={test.checked} />
                                                <label htmlFor={test.test_id}>{test.test_name}</label>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>}
                        </div>
                        <ul className="prescription-accordion">
                            <li className="accordion-item">
                                <h6>Can't find test</h6>
                                {this.state.pathology.map((pathology, idx) => (
                                    <div className="prescription-confirm" key={idx}>
                                        <div className="input-wrapper">
                                            <input type="text" name="test_name" onChange={e => this.handleChange(e, idx)} className="form-control" autoComplete="off" autoCorrect="off" placeholder="Type Test Name" value={pathology.test_name} />
                                            {this.validator.message('test_name', pathology.test_name, 'required')}
                                        </div>
                                        <button
                                            className="cancel"
                                            data-id={idx}
                                            onClick={this.handleRemovePathologyTest}
                                        >X</button>
                                    </div>
                                ))}
                                <button onClick={this.handleAddPathology} className="add-new btn btn-primary" disabled={this.state.edit_pathology}>Add</button>
                            </li>
                        </ul>
                        <button type="submit" className="btn btn-primary" onClick={this.handleSubmit}>Submit</button>
                    </Modal.Body>
                </Modal>
            </>)
    }
}
export default AddPathologyBlock