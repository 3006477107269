import axios from 'axios';
import { apiRoute, getApiHeader, createFilterStringQuery } from '../utils/helpers';

class DashboardService {
    //get prescription counts of doctor module
    static getCountInfo(type, filter = null) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createFilterStringQuery(filter)
        };

        return axios.get(apiRoute("/v1/dashboards/doctors/counts/" + type), requestOptions);
    }

    //get graphs data of doctor module
    static getDoctorsGraphInfo(type, filter) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createFilterStringQuery(filter)
        };
        return axios.get(apiRoute("/v1/dashboards/doctors/graphs/" + type), requestOptions);
    }

    //get pharmay amount payable info
    static renderPharmacyCountInfo(type) {
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.get(apiRoute("/v1/dashboards/pharmacies/counts/" + type), requestOptions);
    }

    //get pharmacy graph data
    static getPharmacyOrderGraphData(type, filter = null) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createFilterStringQuery(filter)
        };
        return axios.get(apiRoute("/v1/dashboards/pharmacies/graphs/" + type), requestOptions);
    }

    //get pathology graph data
    static getPathologyGraphsData(type, filter) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createFilterStringQuery(filter)
        };
        return axios.get(apiRoute("/v1/dashboards/pathologies/graphs/" + type), requestOptions);
    }

    //get pathology dashboard counts
    static renderPathologyCountInfo(type, filter = null) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createFilterStringQuery(filter)
        };
        return axios.get(apiRoute("/v1/dashboards/pathologies/counts/" + type), requestOptions);
    }

    //get radiology graph data
    static getRadiologyGraphsData(type, filter) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createFilterStringQuery(filter)
        };
        return axios.get(apiRoute("/v1/dashboards/radiologies/graphs/" + type), requestOptions);
    }

    //get radiology dashboard counts
    static renderRadiologyCountInfo(type, filter = null) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createFilterStringQuery(filter)
        };
        return axios.get(apiRoute("/v1/dashboards/radiologies/counts/" + type), requestOptions);
    }

    // static renderPathologistDashboard() {
    //     const requestOptions = {
    //         headers: getApiHeader(),
    //     };

    //     return axios.get(apiRoute("/v1/dashboards/pathologists"), requestOptions);
    // }

    // static renderRadiologistDashboard() {
    //     const requestOptions = {
    //         headers: getApiHeader(),
    //     };

    //     return axios.get(apiRoute("/v1/dashboards/radiologists"), requestOptions);
    // }

    // static getPathologyDashboardCountInfo() {
    //     const requestOptions = {
    //         headers: getApiHeader(),
    //     };
    //     return axios.get(apiRoute("/v1/dashboards/pathologies"), requestOptions);
    // }

    // static getRadiologyDashboardCountInfo() {
    //     const requestOptions = {
    //         headers: getApiHeader(),
    //     };
    //     return axios.get(apiRoute("/v1/dashboards/radiologies"), requestOptions);
    // }

    // static getRadiologyDashboardFilters({ filter }) {
    //     const requestOptions = {
    //         headers: getApiHeader(),
    //         params: createFilterStringQuery(filter)
    //     };
    //     return axios.get(apiRoute("/v1/dashboards/radiologies/filters"), requestOptions);
    // }

    //get covid center dashboard counts
    static renderCovidCenterCountInfo(type, filter = null) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createFilterStringQuery(filter)
        };
        return axios.get(apiRoute("/v1/dashboards/covid-center/counts/" + type), requestOptions);
    }

    //get covid center graphs data
    static getCovidTestGraphInfo(filter) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createFilterStringQuery(filter)
        };
        return axios.get(apiRoute("/v1/dashboards/covid-center/graphs/"), requestOptions);
    }

    static renderCovidCenterReportCountInfo(type, filter = null) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createFilterStringQuery(filter)
        };
        return axios.get(apiRoute("/v1/dashboards/covid-center/report/" + type), requestOptions);
    }
}

export default DashboardService