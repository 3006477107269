import React, { memo } from 'react'
import _ from 'lodash'

export default memo(function Card({ imgSrc, label, count }) {
    return (
        <div className="dashboard-main-links">
            <a href>
                <span className="icon">
                    {imgSrc && <img src={imgSrc} alt={label} />}
                </span>
                <h4>{label} <span>{_.isNumber(count) ? _.padStart(count, 2, '0') : '...'}</span>
                </h4>
            </a>
        </div>
    )
})
