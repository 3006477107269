import React from 'react';
import * as Helper from "../../utils/helpers";
import * as Config from "../../config";
import * as ShowCptIcd from '../../components/ShowCptIcd';
import { has, isEmpty, capitalize } from 'lodash';

class PrintPrescriptionPage extends React.Component {

    render() {
        const { prescription, prescription_icd_data } = this.props
        return (
            prescription ? <React.Fragment>
                <div>
                    <div className="print-wrapper">
                        <table className="print-prescription">
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="page">
                                            <div className="container">
                                                <div className="middleWrapper">
                                                    <div className="header">
                                                        <h2>{this.props.selected_user_profile.service_provider.name}</h2>
                                                        <div className="right">
                                                            <p>Prescription Id: <span>{prescription.prescription_id}</span></p>
                                                            <p>Date of Issue: <span>{Helper.getDateFormat(prescription.prescription_date)}</span></p>
                                                        </div>
                                                    </div>
                                                    <div className="info div-break prescription-tbl">
                                                        <div>
                                                            <table className="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <th className="width no-border-right no-border-bottom"></th>
                                                                        <th colSpan="8" className="no-border-left no-border-right "> Patient Info</th>
                                                                        <th className="width no-border-left no-border-bottom"></th>
                                                                    </tr>
                                                                    <tr><td height="5" colSpan="4" className="no-border no-padding"></td></tr>
                                                                    <tr>
                                                                        <td class="width no-border-right no-border-bottom"></td>
                                                                        <td class="no-border-left no-border-bottom no-border-right">Name: <span>{prescription.patient_info && prescription.patient_info.full_name}</span></td>

                                                                        <td class="no-border-right no-border-bottom border-blue pl-50">Age: <span>{prescription.patient_info && (prescription.patient_info.age.year)}</span></td>
                                                                        <td class="width border-blue no-border-right no-border-bottom"></td>

                                                                        <td class="no-border-right no-border-bottom no-border-left pl-50">Gender: <span>{prescription.patient_info && capitalize(prescription.patient_info.gender)}</span></td>
                                                                        <td class="width no-border-bottom no-border-right border-blue"></td>

                                                                        <td class="no-border-right no-border-bottom no-border-left pl-50">Contact: <span>{prescription.patient_info && (prescription.patient_info.contact_number)}</span></td>
                                                                        <td class="width no-border-bottom no-border-right border-blue"></td>

                                                                        <td class="no-border-right no-border-bottom no-border-left border-blue pl-50">Address: <span>{prescription.patient_info && Helper.fullAddressStr(prescription.patient_info.address)}</span></td>
                                                                        <td class="width no-border-left no-border-bottom"></td>
                                                                    </tr>
                                                                    <tr><td height="5" colSpan="10" className="no-padding no-border-top"></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div>
                                                            <table className="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <th className="width no-border-right no-border-bottom"></th>
                                                                        <th colSpan="8" className="no-border-left no-border-right ">Doctor Info</th>
                                                                        <th className="width no-border-left no-border-bottom"></th>
                                                                    </tr>
                                                                    <tr><td height="5" colSpan="4" className="no-border no-padding"></td></tr>
                                                                    <tr>
                                                                        <td class="width no-border-right no-border-bottom"></td>
                                                                        <td class="no-border-left no-border-bottom no-border-right">Name: <span>{prescription.doctor_info.full_name}</span></td>

                                                                        <td class="no-border-right no-border-bottom border-blue pl-50">Specialization: <span>{prescription ? prescription.doctor_info.specialization : ''}</span></td>
                                                                        <td class="width border-blue no-border-right no-border-bottom"></td>

                                                                        <td class="no-border-right no-border-bottom no-border-left pl-50">Email: <span>{prescription.doctor_info.email}</span></td>
                                                                        <td class="width no-border-bottom no-border-right border-blue"></td>

                                                                        <td class="no-border-right no-border-bottom no-border-left pl-50">Contact Number: <span>{prescription.doctor_info.contact_number}</span></td>
                                                                        <td class="width no-border-bottom no-border-right border-blue"></td>

                                                                        <td class="no-border-right no-border-bottom no-border-left border-blue pl-50">Registration Number: <span>{prescription.doctor_info.license_number}</span></td>
                                                                        <td class="width no-border-left no-border-bottom"></td>
                                                                    </tr>
                                                                    <tr><td height="5" colSpan="10" className="no-padding no-border-top"></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div>
                                                            <table className="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <th className="width no-border-right no-border-bottom"></th>
                                                                        <th colSpan="8" className="no-border-left no-border-right ">Diagnosis</th>
                                                                        <th className="width no-border-left no-border-bottom"></th>
                                                                    </tr>
                                                                    <tr><td height="5" colSpan="4" className="no-border no-padding"></td></tr>
                                                                    <tr>
                                                                        <td class="width no-border-right no-border-bottom"></td>
                                                                        <td class="no-border-left no-border-bottom no-border-right"><span>{prescription.diagnosis ? prescription.diagnosis : '--'}</span></td>
                                                                    </tr>
                                                                    <tr><td height="5" colSpan="10" className="no-padding no-border-top"></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    {/* icd cpt */}
                                                    {has(prescription, 'prescription_cpt_icd') && !isEmpty(prescription_icd_data) &&
                                                        <div className="content-wrapper padding-bottom-10">
                                                            <h6>ICD CPT</h6>
                                                            <ul className="prescription-patient-details medicine-details">
                                                                {prescription_icd_data.map((icd, index) => (<ShowCptIcd.ShowIcd icd={icd} idx={index} disable={true} />))}
                                                            </ul>
                                                        </div>}
                                                    {/* prescription data */}
                                                    <p className="title">Radiology Test</p>
                                                    {prescription.prescription_radiology_tests.radiology_test_items.map((item, index) => (
                                                        <div className="test-desc light-th" key={index}>
                                                            <label className="checkbox">
                                                                <input type="checkbox" />
                                                                <span className="checkmark"></span>
                                                            </label>
                                                            <table className="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <th className="width no-border-right no-border-bottom"></th>
                                                                        <th className="no-border-left no-border-right width-120">Test Name:</th>
                                                                        <th className="no-border-left no-border-right"><span>{item.test_name + " - " + (item.test_category ? item.test_category : 'Profile Test')}</span></th>
                                                                        <th className="width no-border-left no-border-bottom"></th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width no-border-right no-border-bottom no-border-top"></td>
                                                                        <td className="no-border-left no-border-right width-120"><span>Description:</span></td>
                                                                        <td className="no-border-left no-border-right">{item.test_description}</td>
                                                                        <td className="width no-border-left no-border-bottom no-border-top"></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width no-border-right no-border-bottom no-border-top"></td>
                                                                        <td className="no-border-left no-border-right width-120"><span>Remark:</span></td>
                                                                        <td className="no-border-left no-border-right">{item.remark}</td>
                                                                        <td className="width no-border-left no-border-bottom no-border-top"></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>))}
                                                    <div className="signature">
                                                        <p><span>Signature</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>
                                        <div className="page-footer-space"></div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                        <footer>
                            <p>This is System generated prescription order</p>
                            <div className="flex">
                                <div className="powererby">
                                    <img src="/images/logo1.png" alt="logo" />
                                    <p>{Helper.getCurrentYear()} &copy; Powered by Daisy-Health</p>
                                </div>
                                <div className="toll-free">
                                    <p>Toll Free No. <span>{Config.TOLL_FREE_NO}</span> </p>
                                </div>
                            </div>
                        </footer>
                    </div>
                </div>
            </React.Fragment> : ''
        )
    }
}

export default (PrintPrescriptionPage)