import React from 'react';
import { Link } from "react-router-dom";
import { handleRedirect } from '../../utils/auth';
import { connect } from "react-redux";

class NotFoundPage extends React.Component {
    render() {
        return (
            <div className="row justify-content-md-center">
                <div className="col-md-10 text-center page-404-wrapper">
                    <img src="images/404.png" alt="404" className="img-404" />
                    <h4><strong>Page Not Found</strong></h4>
                    <p>Sorry! we couldn’t find the page you were looking for</p>
                    <Link to={handleRedirect(this.props.selected_user_profile)} className="btn btn-primary mb-5">GO To Home</Link>
                </div>
            </div>

        );
    }
}
const mapStateToProps = state => ({
    selected_user_profile: state.selected_user_profile,
});

const mapActionsToProps = {};

export default connect(
    mapStateToProps,
    mapActionsToProps
)(NotFoundPage)