import * as types from '../actions/actionTypes';
import * as authUtils from '../utils/auth';

const selectedProfile = authUtils.getUserProfile();
const initalState = selectedProfile ? selectedProfile : {};

export default function (state = initalState, { type, payload }) {
    switch (type) {
        case types.PROFILE_RESET:
            return {}
        case types.PROFILE_REQUEST:
            return Object.assign({}, state)
        case types.PROFILE_SUCCESS:
            return Object.assign({}, payload)
        case types.PROFILE_FAILURE:
            return Object.assign({}, state)
        default:
            return state;
    }
}