import _ from 'lodash';
import * as encryptUtils from './encrypt';

export const setSessionToken = (data) => {
    const stringValue = JSON.stringify({
        access_token: data.access_token,
        refresh_token: data.refresh_token,
        access_expires_at: data.access_expires_at,
        refresh_expires_at: data.refresh_expires_at
    });

    const encryptedValue = encryptUtils.encryptLocal(stringValue);
    localStorage.setItem('_session', encryptedValue);
}

export const getSessionToken = () => {
    const encryptedValue = localStorage.getItem('_session');

    if (encryptedValue) {
        const stringValue = encryptUtils.decryptLocal(encryptedValue);
        return JSON.parse(stringValue);
    }

    return false;
}

export const checkSessionToken = () => {
    if (localStorage.getItem('_session')) {
        return true;
    }

    return false;
}

export const removeSessionToken = () => {
    localStorage.removeItem('_session');
}

export const setUserProfile = (data) => {
    const stringValue = JSON.stringify(data);

    const encryptedValue = encryptUtils.encryptLocal(stringValue);
    localStorage.setItem('_user_profile', encryptedValue);
}

export const getUserProfile = () => {
    const encryptedValue = localStorage.getItem('_user_profile');

    if (encryptedValue) {
        const stringValue = encryptUtils.decryptLocal(encryptedValue);
        return JSON.parse(stringValue);
    }

    return false;
}

export const checkUserProfile = () => {
    if (localStorage.getItem('_user_profile')) {
        return true;
    }

    return false;
}

export const removeUserProfile = () => {
    localStorage.removeItem('_user_profile');
}

export const hasPermission = (user, rights) => {
    if (_.isEmpty(user) || _.isEmpty(user.rights)) {
        return false;
    }

    return rights.some(right => user.rights.includes(right));
}

export const hasRole = (user, role_slug) => {
    if (_.isEmpty(user) || _.isEmpty(user.role_slug)) {
        return false;
    }
    return role_slug.some(role => user.role_slug === role);
}

export const hasProvider = (service_provider, provider_slugs) => {
    if (_.isEmpty(service_provider) || _.isEmpty(service_provider.service_provider_type)) {
        return false;
    }
    return provider_slugs.some(provider => service_provider.service_provider_type === provider);
}

export const removeNotificationCount = () => {
    localStorage.removeItem('_notification_count');
}

export const getNotificationCount = () => {
    return localStorage.getItem('_notification_count');
}

export const setNotificationCount = (count) => {
    localStorage.setItem('_notification_count', count);
}


export const handleRedirect = (user) => {
    if (hasRole(user, ["doctor-nurse", "junior-doctor", 'medical-practice-manager'])) {
        return '/prescriptions'
    } else if (hasRole(user, ["pharmacist"])) {
        return '/prescription/orders/queue'
    } else if (hasRole(user, ["pharmacy-technician"])) {
        return '/pharmacy/technician/prescription/list'
    } else if (hasRole(user, ["pathologist"])) {
        return '/pathology/orders'
    } else if (hasRole(user, ["radiologist"])) {
        return '/radiology/orders'
    } else if (hasRole(user, ["radiology-receptionist"])) {
        return '/patient/radiology-tests'
    } else if (hasRole(user, ["pathology-receptionist"])) {
        return '/patient/pathology-tests'
    } else if (hasRole(user, ["doctor-receptionist", 'covid-nurse', 'covid-technician', 'covid-doctor'])) {
        return '/patients/search'
    } else if (hasRole(user, ['covid-center-owner', "airline-owner"])) {
        return '/user-management'
    } else if (hasRole(user, ['covid-vendor'])) {
        return '/patient-details'
    } else if (hasRole(user, ["airport-assistant"])) {
        return '/authority-access/patients/search'
    } else {
        return '/dashboard'
    }
}

export const setTwoFa = (value) => {
    localStorage.setItem('_is_2fa_verified', value);
}

export const getTwoFa = () => {
    return localStorage.getItem('_is_2fa_verified');
}

export const removeTwoFa = () => {
    localStorage.removeItem('_is_2fa_verified');
}