import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Button, Tabs, Tab } from 'react-bootstrap';
import { getDateFormat, patientInsurance } from '../../utils/helpers';
import DataGrid from "../../components/DataGrid";
import * as CovidCenterActions from '../../actions/covidCenterAction';
import _ from 'lodash';
import CovidCenterService from '../../services/covidCenterService';
import * as loaderAction from '../../actions/loaderAction';
import * as QueueAction from '../../actions/queueAction';
import Notification from '../../utils/notification';
import PrintButton from '../../components/PrintButton';
import PrintConsentPage from '../../components/pdf/PrintConsentPage';

class CovidTestPatientsReportPage extends React.Component {
    constructor(props) {
        super(props);
        const { params: { type } } = this.props.match;
        this.state = {
            type: type,
            filter: {
                type: 'registered'
            }
        };
    }
    componentDidMount() {
        this.props.paginate({ sortBy: { sortField: "updated_at", sortOrder: "asc" }, filter: this.state.filter });
    }
    columns = () => {
        return [{
            dataField: 'created_at',
            text: 'Request Date',
            formatter: this.dateTimeFormat,
            style: {width:'120px'}
        }, {
            text: 'Patient Name',
            dataField: 'name'
        }, {
            text: 'Gender',
            dataField: 'gender',
            formatter: this.genderFormatter
        }, {
            text: 'Date of Birth',
            dataField: 'date_of_birth',
            formatter: this.dateTimeFormat,
            style: {width:'120px'}
        }, {
            text: 'Contact Number',
            dataField: 'contact'
        }, {
            text: 'Email',
            dataField: 'email'
        }, {
            text: 'Insurance Type',
            dataField: 'insurance_type',
            formatter: this.insuranceTypeFormatter
        }, {
            dataField: 'actions',
            text: 'Actions',
            isDummyField: true,
            formatter: this.actionFormatter,
            style: {'min-width':'350px'}
        }];
    };
    dateTimeFormat = (cell, row) => {
        return getDateFormat(cell)
    }

    genderFormatter = (cell, row) => {
        return _.capitalize(cell)
    }

    insuranceTypeFormatter = (cell, row) => {
        return cell ? patientInsurance(cell) : ""
    }

    actionFormatter = (cell, row) => {
        return <React.Fragment className="pul-right">
            {this.state.filter.type === "registered" && <Button variant="primary" className='ml-2 height-30' onClick={e => this.handleQueueClick(row)}>
                Add to Queue
            </Button>}
            {this.state.filter.type === "unregistered" && <Button variant="primary" className='ml-2 height-30' onClick={e => this.addPatientHandle(row)}>
                Add as Patient
            </Button>}
            <PrintButton
                content={row}
                component={PrintConsentPage}
                title="Print Consent"
            />
            <Button variant="danger" className='ml-2 height-30' onClick={e => this.remove(row.id)}>
                Remove
            </Button>
        </React.Fragment>
    };

    _onSelect = (selectedKey) => {
        this.setState({
            filter: {
                type: selectedKey
            }
        }, () => {
            this.props.paginate({ sortBy: { sortField: "updated_at", sortOrder: "asc" }, filter: this.state.filter });
        })
    };

    remove = (id) => {
        this.props.showLoader();
        CovidCenterService.deleteCovitTestPatient(id).then(resp => {
            this.props.hideLoader();
            Notification.show('success', { message: 'Patient deleted successfully.' });
            this.props.paginate({ sortBy: { sortField: "updated_at", sortOrder: "asc" }, filter: this.state.filter });
        }).catch(error => {
            this.props.hideLoader();
            if (error.response && error.response.status) {
                Notification.show('error', error.response.data);
            }
        });
    };

    handleQueueClick(row) {
        const queueData = {
            patient_id: row.patient_id,
            appointment_type: 'queue',
            prescription_id: '',
        };

        this.props.createQueue(queueData, this.props.history, 'covid-center');
        CovidCenterService.deleteCovitTestPatient(row.id)
    }

    addPatientHandle(row) {
        this.props.history.push({
            pathname: '/patients/create',
            state: row
        });
    }

    render() {
        let { data, meta } = this.props.covid_reports;
        return <React.Fragment>
            <Helmet>
                <title>Patient's List</title>
            </Helmet>
            <div className="row">
                <div className="col-md-24">
                    <h4 className="page-title">Patient's List</h4>
                </div>
            </div>
            <div className="content-wrapper">
                <Tabs defaultActiveKey={"registered"} onSelect={this._onSelect}>
                    <Tab eventKey="registered" title="Registered Patient" style={{ width: "100%" }}>
                        {data && <DataGrid
                            columns={this.columns()}
                            data={data}
                            meta={meta}
                            noDataIndication="No Record Found"
                        />}
                    </Tab>
                    <Tab eventKey="unregistered" title="Unregistered Patient" style={{ width: "100%" }}>
                        {data && <DataGrid
                            columns={this.columns()}
                            data={data}
                            meta={meta}
                            noDataIndication="No Record Found"
                        />}
                    </Tab>
                </Tabs>
            </div>
        </React.Fragment>
    }
}
const mapStateToProps = (state) => ({
    covid_reports: state.covid_reports,
});

const mapActionsToProps = {
    paginate: CovidCenterActions.paginateCovidTestForm,
    showLoader: loaderAction.loaderShow,
    hideLoader: loaderAction.loaderHide,
    createQueue: QueueAction.create
};

export default connect(mapStateToProps, mapActionsToProps)(CovidTestPatientsReportPage);