import React from 'react';

export const General = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <div className="heading">
            <h6>General</h6>
        </div>
        <div className="form-group full">
            <div className="options-details">
                {/* weight gain */}
                <input type="checkbox" id='recent-weight-gain' name='recent-weight-gain' onChange={e => handleChange(e, 'general')} checked={checkIfProblemExist('general', 'recent-weight-gain') ? 'checked' : ''} />
                <label htmlFor='recent-weight-gain'>Recent weight gain?</label>
                {/* weight loss */}
                <input type="checkbox" id='recent-weight-loss' name='recent-weight-loss' onChange={e => handleChange(e, 'general')} checked={checkIfProblemExist('general', 'recent-weight-loss') ? 'checked' : ''} />
                <label htmlFor='recent-weight-loss'>Recent weight loss?</label>
                {/* fatigue */}
                <input type="checkbox" id='fatigue' name='fatigue' onChange={e => handleChange(e, 'general')} checked={checkIfProblemExist('general', 'fatigue') ? 'checked' : ''} />
                <label htmlFor='fatigue'>Fatigue</label>
                {/* weakness */}
                <input type="checkbox" id='weakness' name='weakness' onChange={e => handleChange(e, 'general')} checked={checkIfProblemExist('general', 'weakness') ? 'checked' : ''} />
                <label htmlFor='weakness'>Weakness</label>
                {/* fever */}
                <input type="checkbox" id='fever' name='fever' onChange={e => handleChange(e, 'general')} checked={checkIfProblemExist('general', 'fever') ? 'checked' : ''} />
                <label htmlFor='fever'>Fever</label>
                {/* night sweats */}
                <input type="checkbox" id='night-sweats' name='night-sweats' onChange={e => handleChange(e, 'general')} checked={checkIfProblemExist('general', 'night-sweats') ? 'checked' : ''} />
                <label htmlFor='night-sweats'>Night Sweats</label>
            </div>
        </div>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment }) => {
    return <React.Fragment>
        <label className="heading-comment">Comment </label>
        <textarea className="form-control" name="comments" placeholder="Type here..." onChange={e => handleChange(e, 'general')} value={getCategoryComment('general') ? getCategoryComment('general') : ''}></textarea>
    </React.Fragment>
}