import React from 'react';
import _ from 'lodash';
import { Link } from "react-router-dom";
import Notification from '../utils/notification'
import SimpleReactValidator from 'simple-react-validator';

class ViewFeeDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            payment_insurance_amount: "",
            payment_cash_amount: "",
            payment_card_amount: '',
            payment_online_amount: '',
            status: "unpaid",
        }
        this.validator = new SimpleReactValidator();
    }

    feeHandler = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSubmit = (fee_id, e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        this.validator.hideMessages();
        if (_.isEmpty(this.state.payment_insurance_amount) && _.isEmpty(this.state.payment_cash_amount) && _.isEmpty(this.state.payment_card_amount) && _.isEmpty(this.state.payment_online_amount)) {
            Notification.show('error', { message: 'Fill payment info' });
            return false
        }
        const data = {
            payment_insurance_amount: this.state.payment_insurance_amount ? this.state.payment_insurance_amount : null,
            payment_cash_amount: this.state.payment_cash_amount ? this.state.payment_cash_amount : null,
            status: "paid",
            payment_remark: this.state.payment_remark ? this.state.payment_remark : null,
            payment_card_amount: this.state.payment_card_amount ? this.state.payment_card_amount : null,
            payment_online_amount: this.state.payment_online_amount ? this.state.payment_online_amount : null,
            currency_id: this.props.view_data ? this.props.view_data.currency.id : null
        }
        this.props.handleSubmit(fee_id, data);
    }

    render() {
        return (
            <div className="content-wrapper padding-bottom-10" id="view_fee_collection">
                <h6>Fee Collection</h6>
                <ul className="fee_collection_form">
                    <li>Patient Name: <span>{this.props.view_data.patient_name}</span></li>
                    <li>Total to be collected: <span>{this.props.totalAmountFormatter(null, this.props.view_data)}</span></li>
                    <li>From Insurance: <span><input type="number" name="payment_insurance_amount" className="form-control" onChange={this.feeHandler} /></span>
                        {this.validator.message('From Insurance', this.state.payment_insurance_amount, 'required|numeric|min:0,num|max:10')}
                    </li>
                    <li>Cash Collected: <span><input type="number" name="payment_cash_amount" className="form-control" onChange={this.feeHandler} /></span>
                        {this.validator.message('Cash Collected', this.state.payment_cash_amount, 'required|numeric|min:0,num|max:10')}
                    </li>
                    <li>From Card: <span><input type="number" name="payment_card_amount" className="form-control" onChange={this.feeHandler} /></span>
                        {this.validator.message('From Card', this.state.payment_card_amount, 'required|numeric|min:0,num|max:10')}
                    </li>
                    <li>From Online: <span><input type="number" name="payment_online_amount" className="form-control" onChange={this.feeHandler} /></span>
                        {this.validator.message('From Online', this.state.payment_online_amount, 'required|numeric|min:0,num|max:10')}
                    </li>
                    <li className="full-width">Remark: <span>
                        <textarea name="payment_remark" className="form-control" onChange={this.feeHandler} />
                        {this.validator.message('Remark', this.state.payment_remark, 'required')}
                    </span>
                    </li>
                </ul>
                <div className="button-wrapper align-right">
                    <button type="submit" className="btn btn-primary" onClick={e => this.handleSubmit(this.props.view_data.id, e)}>Submit</button>
                    <Link to="/prescriptions/fees" className="btn btn-default btn-outline-primary" onClick={this.props.clearData}>Cancel</Link>
                </div>
            </div>
        )
    }
}
export default ViewFeeDetails