import React from 'react';
import { PersonalHistory } from '../../../../../config/add_prescription';
import { getDateFormat } from '../../../../../utils/helpers'

export const Abuse = (abuseInfo) => {
    return (
        <div className="content-wrapper padding-bottom-10 margin-bottom-none">
            <h6 className="inner_heading">Abuse</h6>
            <ul className="prescription-filed-details full-width">
                <li> Have you ever been sexually, physically and emotionally abused?
                    <span>{abuseInfo.is_abuse ? 'Yes' : (abuseInfo.is_abuse === false ? 'No' : '--')}</span>
                </li>
            </ul>
            <ul className="prescription-filed-details full-width">
                <li> Details:
                <span>{abuseInfo.abuse_reason ? abuseInfo.abuse_reason : '--'}</span></li>
            </ul>
        </div>)
}
export const SubstanceAbuse = ({ substanceAbuse }) => {
    return (
        <div className="content-wrapper padding-bottom-10 margin-bottom-none">
            <h6 className="inner_heading">Substance Abuse</h6>
            <ul className="prescription-filed-details auto-width">
                <li>Substance :
                <span>{PersonalHistory.substance[substanceAbuse.substance_type]}</span>
                </li>
                <li>Currently :
                <span>{substanceAbuse.is_currently_use === true ? 'Yes' : (substanceAbuse.is_currently_use === false ? 'No' : '--')}</span>
                </li>
                <li>Previously Used :
                <span>{substanceAbuse.is_previously_used === true ? 'Yes' : (substanceAbuse.is_previously_used === false ? 'No' : '--')}</span>
                </li>
                <li>Type/Amount/Frequency :
                <span>{substanceAbuse.type_quantity}</span>
                </li>
                <li>How long (In Years) ?
                <span>{substanceAbuse.how_long_years}</span>
                </li>
                <li>If stopped then when ?
                <span>{substanceAbuse.stopped_when_reason}</span>
                </li>
            </ul>
        </div>
    )
}
export const Vaccine = (vaccineInfo) => {
    return (
        <div className="content-wrapper padding-bottom-10 margin-bottom-none">
            <h6 className="inner_heading">Vaccines</h6>
            <ul className="prescription-filed-details auto-width">
                <li>Tetanus:
                <span>{getDateFormat(vaccineInfo.tetanus_vaccacines)}</span></li>
                <li>Influenza:
                <span>{getDateFormat(vaccineInfo.influenza_vaccines)}</span></li>
                <li>HPV:
                <span>{getDateFormat(vaccineInfo.hiv_vaccines)}</span></li>
            </ul>
        </div>
    )
}
export const Excercise = (excerciseInfo) => {
    return (
        <div className="content-wrapper padding-bottom-10 margin-bottom-none">
            <h6 className="inner_heading">Excercise and Other</h6>
            <ul className="prescription-filed-details auto-width">
                <li>Do you excercise? :
                    <span>{excerciseInfo.is_exercise === true ? 'Yes' : (excerciseInfo.is_exercise === false ? 'No' : '--')}</span>
                </li>
                <li>Type and frequency of excercise :
                    <span>{excerciseInfo.exercise_frequency ? excerciseInfo.exercise_frequency : '--'}</span>
                </li>
                <li>Do you snore? :
                    <span>{excerciseInfo.is_sleep_snore === true ? 'Yes' : (excerciseInfo.is_sleep_snore === false ? 'No' : '--')}</span>
                </li>
            </ul>
        </div>
    )
}
export const Sexual = ({ personal_history }) => {
    let { sexual_partners, use_condom, is_sexually_active, is_hiv_tested, hiv_test_result, hiv_test_comment, last_hiv_test } = personal_history;
    return (
        <div className="content-wrapper padding-bottom-10 margin-bottom-none">
            <h6 className="inner_heading">Sexual</h6>
            <ul className="prescription-filed-details auto-width">
                <li>No. of partners:
                <span>{sexual_partners || '--'}</span>
                </li>
                <li>Sexually Active:
                <span>{is_sexually_active === true ? 'Yes' : (is_sexually_active === false ? 'No' : '--')}</span>
                </li>
                <li>Use of Condom :
                <span>{use_condom ? PersonalHistory.use_of_condom[use_condom] : '--'}</span>
                </li>
            </ul>
            <ul className="prescription-filed-details auto-width">
                <li>HIV Test:
                <span>{is_hiv_tested === true ? 'Yes' : (is_hiv_tested === false ? 'No' : '--')}</span>
                </li>
                <li>Last HIV Test Date:
                <span>{last_hiv_test || '--'}</span>
                </li>
                <li>HIV Test Result :
                <span>{hiv_test_result ? PersonalHistory['hiv_test_result'][hiv_test_result] : '--'}</span>
                </li>
                <li>HIV Test Result Comment :
                <span>{hiv_test_comment || '--'}</span>
                </li>
            </ul>
        </div >
    )
}
