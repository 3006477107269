import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as PrescriptionAction from "../../actions/prescriptionAction";
import { Helmet } from "react-helmet";
import DataGrid from "../../components/DataGrid";
import * as Helper from "../../utils/helpers";
import PrescriptionService from "../../services/prescriptionService";
import Notification from "../../utils/notification";
import RoleSerivce from '../../services/roleService';

class ApprovePrescriptionPage extends React.Component {
    constructor(props) {
        super(props);

        this.props.paginate({});
    }

    columns = () => {
        return [
            {
                dataField: "type",
                text: "Assigned Type",
                formatter: this.typeFormatter
            },
            {
                dataField: "patient_name",
                text: "Patient Name",
            },
            {
                dataField: "staff_name",
                text: "Acquired Staff Name",
                formatter: this.staffNameFormatter
            },
            {
                dataField: "created_date",
                text: "Created Date",
                formatter: this.dateTimeFormatter
            },
            {
                dataField: "assigned_at",
                text: "Updated Date",
                formatter: this.dateTimeFormatter
            },
            {
                dataField: "actions",
                text: "Action",
                formatter: this.actionFormatter
            }
        ]
    };

    staffNameFormatter = (cell) => {
        if (cell && cell.user) {
            return cell.user.full_name;
        }
        return "--";
    }

    dateTimeFormatter = (cell) => {
        return Helper.getDateTimeFormat(cell);
    };

    typeFormatter = (cell) => {
        return RoleSerivce.getRoleNameBySlug(cell);
    }

    actionFormatter = (cell, row) => {
        let isAcquired = (row.acquired_by === null || row.acquired_by === this.props.selected_user_profile.user_profile_id) ? false : true;

        if (isAcquired) {
            return <React.Fragment><button className="btn btn-outline-primary" onClick={e => this.revokeAcquirePrescription(row)}> Revoke </button></React.Fragment>
        }

        return (
            <React.Fragment>
                <Link to={"/patients/" + row.patient_id + "/prescriptions/" + row.prescription_id} className="btn btn-primary">Acquire</Link>
            </React.Fragment>
        );
    };

    revokeAcquirePrescription(row) {
        const postData = {
            prescription_id: row.prescription_id
        };

        PrescriptionService.revokeAcquiredPrescriptionAccess(postData)
            .then(resp => {
                this.props.paginate({});
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    Notification.show('error', error.response.data);
                }
            });
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        if (type === "search") {
            page = 1;
        }

        this.props.paginate({ page, sizePerPage, sortBy: { sortField, sortOrder }, searchText });
    };

    render() {
        let { data, meta } = this.props.prescription_list;

        return (
            <React.Fragment>
                <Helmet>
                    <title>Approve Prescription</title>
                </Helmet>
                <div class="row">
                    <div class="col-md-24">
                        <h4 class="page-title">Approval Pending</h4>
                    </div>
                </div>
                <div class="content-wrapper">
                    <DataGrid
                        
                        columns={this.columns()}
                        noDataIndication="No Record Found"
                        data={data}
                        meta={meta}
                        handleTableChange={this.handleTableChange}
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    prescription_list: state.prescription.paginate,
    selected_user_profile: state.selected_user_profile,
});

const mapActionsToProps = {
    paginate: PrescriptionAction.listApprovalPendingPrescription
};

export default connect(mapStateToProps, mapActionsToProps)(ApprovePrescriptionPage);
