import * as types from './actionTypes';
import Notification from '../utils/notification';
import PreRegisteredPatientService from '../services/preRegisteredPatientService';

export function reset() {
    return dispatch => {
        dispatch({ type: types.PATIENT_LIST_RESET });
        dispatch({ type: types.RESET_PATIENT });
    }
}

export function list({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' })
        dispatch({ type: types.PRE_REGISTERED_PATIENT_LIST_REQUEST, request: { page, sizePerPage, sortBy, filter } });

        PreRegisteredPatientService.list({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' })
                dispatch({ type: types.PRE_REGISTERED_PATIENT_LIST_SUCCESS, ...data });

                if (data.data && data.data.length <= 0) {
                    Notification.show('error', { message: 'Patient record not found.' })
                }
            })
            .catch(error => {
                dispatch({ type: types.PRE_REGISTERED_PATIENT_LIST_FAILED, ...error.response })
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);

                }
            });
    }
}