import axios from 'axios';
import { apiRoute, getApiHeader } from '../utils/helpers';
import * as encryptUtils from '../utils/encrypt';
import * as authUtils from '../utils/auth';

class AuthService {
    static login(email, password) {
        const requestOptions = {
            headers: getApiHeader(null, false),
        };

        const body = JSON.stringify({ 
            email: email, 
            password: encryptUtils.encryptServer(password) 
        });

        return axios.post(apiRoute('/v1/login'), body, requestOptions);
    }

    static logout() {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.post(apiRoute('/v1/logout'), "", requestOptions);
    }

    static forgotPassword(email) {
        const requestOptions = {
            headers: getApiHeader(null, false),
        };

        const body = JSON.stringify({ email: email });

        return axios.post(apiRoute('/v1/forget-password'), body, requestOptions);
    }

    static resetPassword(email, token, password, password_confirmation) {
        const requestOptions = {
            headers: getApiHeader(null, false),
        };

        const body = JSON.stringify({
            email: email,
            token: token,
            password: password,
            password_confirmation: password_confirmation
        });

        return axios.post(apiRoute('/v1/reset-password'), body, requestOptions);
    }

    static changePassword(stateData) {
        const requestOptions = {
            headers: getApiHeader(null, true)
        };

        const body = JSON.stringify({
            current_password: stateData.current_password,
            password: stateData.password,
            password_confirmation: stateData.password_confirmation
        });

        return axios.post(apiRoute('/v1/change-password'), body, requestOptions);
    }

    static newPassword(email, token, password, password_confirmation) {
        const requestOptions = {
            headers: getApiHeader(null, false),
        };

        const body = JSON.stringify({
            email: email,
            token: token,
            password: password,
            password_confirmation: password_confirmation
        });

        return axios.post(apiRoute('/v1/set-password'), body, requestOptions);
    }

    static refreshToken() {
        const requestOptions = {
            headers: getApiHeader(null, false),
        };

        const token = authUtils.getSessionToken();

        const body = token ? JSON.stringify({
            refresh_token: token.refresh_token,
            access_token: token.access_token
        }) : "";

        return axios.post(apiRoute('/v1/refresh-token'), body, requestOptions);

    }
}

export default AuthService;