import React from 'react';

export const Eyes = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <h6>Eyes </h6>
        <input type="checkbox" id='eyes-normal' name='eyes-normal' onChange={e => handleChange(e, 'eyes')} checked={checkIfProblemExist('eyes', 'eyes-normal') ? 'checked' : ''} />
        <label htmlFor='eyes-normal'>Normal</label>
    </React.Fragment>
}
export const EyesOptions = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <div className="options-details">
            {/* pupils light */}
            <input type="checkbox" id='eyes-pupils-reactive' name='eyes-pupils-reactive' onChange={e => handleChange(e, 'eyes')} checked={checkIfProblemExist('eyes', 'eyes-pupils-reactive') ? 'checked' : ''} />
            <label htmlFor='eyes-pupils-reactive'>Pupils reactive to light</label>
            {/* redness */}
            <input type="checkbox" id='eyes-redness' name='eyes-redness' onChange={e => handleChange(e, 'eyes')} checked={checkIfProblemExist('eyes', 'eyes-redness') ? 'checked' : ''} />
            <label htmlFor='eyes-redness'>Redness</label>
            {/* discharge */}
            <input type="checkbox" id='eyes-discharge' name='eyes-discharge' onChange={e => handleChange(e, 'eyes')} checked={checkIfProblemExist('eyes', 'eyes-discharge') ? 'checked' : ''} />
            <label htmlFor='eyes-discharge'>Discharge</label>
            {/* swallen */}
            <input type="checkbox" id='eyes-swollen' name='eyes-swollen' onChange={e => handleChange(e, 'eyes')} checked={checkIfProblemExist('eyes', 'eyes-swollen') ? 'checked' : ''} />
            <label htmlFor='eyes-swollen'>Swollen</label>
            {/* lump */}
            <input type="checkbox" id='eyes-lump' name='eyes-lump' onChange={e => handleChange(e, 'eyes')} checked={checkIfProblemExist('eyes', 'eyes-lump') ? 'checked' : ''} />
            <label htmlFor='eyes-lump'>Lump/Bump</label>
        </div>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment, checkIfProblemExist }) => {
    return <React.Fragment>
        <label className="heading-comment">Comment </label>
        <textarea className="form-control" name="comments" placeholder="Type here..." onChange={e => handleChange(e, 'eyes')} disabled={checkIfProblemExist('eyes', 'eyes-normal') ? 'disabled' : ''} value={getCategoryComment('eyes') ? getCategoryComment('eyes') : ''}></textarea>
    </React.Fragment>
}