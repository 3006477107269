import React from 'react';
import moment from 'moment';

const sectionSeperator = {
    borderBottom: "1px solid #e9ecef",
    marginTop: "15px",
    marginBotton: "15px",
};

export const Abuse = ({ is_abuse, abuse_reason, handleChange, getValue }) => {
    return <React.Fragment>
        <div className="heading">
            <h6>Abuse</h6>
        </div>
        <div className="form-group full form-left">
            <label>Have you ever been sexually, physically and emotionally abused? </label>
            <select className="form-control" name="is_abuse" onChange={handleChange} value={getValue(is_abuse)}>
                <option value='' defaultValue>---select---</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
            </select>
            {/* <input type="checkbox" id="is_abuse" name="is_abuse" onChange={handleChange} value={is_abuse ? is_abuse : ''} checked={is_abuse ? 'checked' : ''} />
            <label htmlFor="is_abuse"></label> */}
        </div>
        <div className="form-group full">
            <label className="blue-label">Details </label>
            <textarea className="form-control" name="abuse_reason" placeholder="Type here..." onChange={handleChange} value={abuse_reason ? abuse_reason : ''}></textarea>
        </div>
    </React.Fragment>
}
export const Excercise = ({ is_exercise, exercise_frequency, is_sleep_snore, handleChange, getValue }) => {
    return (
        <React.Fragment>
            <div className="heading">
                <h6>Exercise and Other</h6>
            </div>
            <div className="form-group form-left padding-right excercise-and-other-first">
                <label>Do you exercise? </label>
                <select className="form-control" name="is_exercise" onChange={handleChange} value={getValue(is_exercise)}>
                    <option value='' defaultValue>---select---</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                </select>
            </div>
            <div className="form-group form-left padding-right excercise-and-other-second">
                <label>Type and frequency of exercise </label>
                <input type="text" className="form-control" name="exercise_frequency" onChange={handleChange} value={exercise_frequency ? exercise_frequency : ''} />
            </div>
            <div className="form-group form-left excercise-and-other-third">
                <label>Do you snore?</label>
                <select className="form-control" name="is_sleep_snore" onChange={handleChange} value={getValue(is_sleep_snore)}>
                    <option value='' defaultValue>---select---</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                </select>
            </div>
        </React.Fragment>
    )
}
export const Sexual = ({ personal_history, handleChange, getValue }) => {
    let { sexual_partners, use_condom, is_sexually_active, is_hiv_tested, hiv_test_result, hiv_test_comment, last_hiv_test } = personal_history;
    return (
        <React.Fragment>
            <div className="heading">
                <h6>Sexual</h6>
            </div>
            <div className="form-group form-left substance-abuse-four">
                <label>Number of partners? </label>
                <input type="text" className="form-control" name="sexual_partners" onChange={handleChange} value={sexual_partners ? sexual_partners : ''} />
            </div>
            <div className="form-group form-left pl-0 excercise-and-other-third">
                <label>Use of Condom </label>
                <select className="form-control" name="use_condom" onChange={handleChange} value={use_condom ? use_condom : ''}>
                    <option value='' defaultValue>---select---</option>
                    <option value="always">Always</option>
                    <option value="sometimes">Sometimes</option>
                    <option value="never">Never</option>
                </select>
            </div>
            <div className="form-group form-left pl-3 excercise-and-other-third">
                <label>Sexually Active </label>
                <select className="form-control" name="is_sexually_active" onChange={handleChange} value={getValue(is_sexually_active)}>
                    <option value='' defaultValue>---select---</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                </select>
            </div>
            <br />
            <div className="form-group form-left padding-right sexual-second">
                <label>HIV Test </label>
                <select className="form-control" name="is_hiv_tested" onChange={handleChange} value={getValue(is_hiv_tested)}>
                    <option value='' defaultValue>---select---</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                </select>
            </div>
            <div className="form-group form-left pl-0 excercise-and-other-second">
                <label>Last HIV Test Date</label>
                <input type="text" className="form-control" name="last_hiv_test" onChange={handleChange} value={last_hiv_test || ''} disabled={!is_hiv_tested ? 'disabled' : ''} />
            </div>
            <div className="form-group form-left  pl-0 excercise-and-other-third">
                <label>HIV Test Result</label>
                <select className="form-control" name="hiv_test_result" onChange={handleChange} value={hiv_test_result ? hiv_test_result : ''} disabled={!is_hiv_tested ? 'disabled' : ''}>
                    <option value='' defaultValue>---select---</option>
                    <option value="normal">Normal</option>
                    <option value="abnormal">Abnormal</option>
                </select>
            </div>
            <div className="form-group full">
                <label className="blue-label">HIV Test Result Comment </label>
                <textarea className="form-control" name="hiv_test_comment" placeholder="Type here..." onChange={handleChange} value={hiv_test_comment || ''} disabled={!is_hiv_tested ? 'disabled' : ''}></textarea>
            </div>
        </React.Fragment>)
}
export const SubstanceAbuse = ({ substance, addSubstanceAbuseBlock, handleChange, removeSubstanceAbuse, getValue }) => {
    return <React.Fragment>
        <div className="heading">
            <h6>Substance Abuse</h6>
        </div>
        {substance && substance.map((item, index) => (
            <SubstanceBlock
                item={item}
                index={index}
                handleChange={handleChange}
                key={index}
                removeSubstanceAbuse={removeSubstanceAbuse}
                getValue={getValue}
            />
        ))}
        <button onClick={addSubstanceAbuseBlock} className="add-new btn btn-default btn-outline-primary">Add</button>
    </React.Fragment>
}
export const SubstanceBlock = ({ item, index, handleChange, removeSubstanceAbuse, getValue }) => {
    return (
        <React.Fragment key={index}>
            <div className="cancel_section" style={sectionSeperator}>
                <div className="form-group form-left substance-abuse-first">
                    <label>Substance </label>
                    <select className="form-control" name="substance_type" onChange={e => handleChange(e, index)} value={item.substance_type}>
                        <option value=""></option>
                        <option value="caffeine">Caffeine</option>
                        <option value="tobacco">Tobacco</option>
                        <option value="alcohol">Alcohol</option>
                        <option value="street-drugs">Street drugs</option>
                    </select>
                </div>
                <div className="form-group form-left substance-abuse-second">
                    <label>Currently </label>
                    <select className="form-control" name="is_currently_use" onChange={e => handleChange(e, index)} value={getValue(item.is_currently_use)} disabled={!item.substance_type ? 'disabled' : ''}>
                        <option value='' defaultValue>---select---</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select>
                </div>
                <div className="form-group form-left substance-abuse-third">
                    <label>Previously Used </label>
                    <select className="form-control" name="is_previously_used" onChange={e => handleChange(e, index)} value={getValue(item.is_previously_used)} disabled={!item.substance_type ? 'disabled' : ''}>
                        <option value='' defaultValue>---select---</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select>
                </div>
                <div className="form-group form-left substance-abuse-four">
                    <label>Type/Amount/Frequency </label>
                    <input type="text" className="form-control" name="type_quantity" onChange={e => handleChange(e, index)} value={item.type_quantity ? item.type_quantity : ''} disabled={!item.substance_type ? 'disabled' : ''} />
                </div>
                <div className="form-group form-left substance-abuse-five">
                    <label>How long (In Years)? </label>
                    <input type="text" className="form-control" name="how_long_years" onChange={e => handleChange(e, index)} value={item.how_long_years ? item.how_long_years : ''} disabled={!item.substance_type ? 'disabled' : ''} />
                </div>
                <div className="form-group form-left substance-abuse-six">
                    <label>If stopped then when? </label>
                    <input type="text" className="form-control" name="stopped_when_reason" onChange={e => handleChange(e, index)} value={item.stopped_when_reason ? item.stopped_when_reason : ''} disabled={!item.substance_type ? 'disabled' : ''} />
                </div>
                <span className="cancel" data-id={index} onClick={removeSubstanceAbuse}></span>
            </div>
        </React.Fragment>
    )
}
export const Vaccine = ({ tetanus_vaccacines, influenza_vaccines, hiv_vaccines, handleChange }) => {
    return (
        <React.Fragment>
            <div className="heading">
                <h6>Vaccines</h6>
            </div>
            <div className="form-group form-left padding-right vaccines-first">
                <label>Tetanus </label>
                <input type="date" id="tetanus" className="form-control" name="tetanus_vaccacines" onChange={handleChange} max={moment().format('YYYY-MM-DD')} value={tetanus_vaccacines ? tetanus_vaccacines : ''} />
            </div>
            <div className="form-group form-left padding-right vaccines-second">
                <label>Influenza </label>
                <input type="date" id="influenza" className="form-control" name="influenza_vaccines" onChange={handleChange} max={moment().format('YYYY-MM-DD')} value={influenza_vaccines ? influenza_vaccines : ''} />
            </div>
            <div className="form-group form-left vaccines-third">
                <label>HPV </label>
                <input type="date" id="hpv" className="form-control" name="hiv_vaccines" onChange={handleChange} max={moment().format('YYYY-MM-DD')} value={hiv_vaccines ? hiv_vaccines : ''} />
            </div>
        </React.Fragment>
    )
}