import React from 'react';
import { Modal, Form, Row, Col, Container, Button, Pagination } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as loaderAction from '../../actions/loaderAction';
import AppointmentService from '../../services/appointmentService';
import moment from 'moment';
import SimpleReactValidator from "simple-react-validator";
import { find, isEmpty, groupBy } from 'lodash';
import Notification from '../../utils/notification';

const SelectDoctor = ({ doctors, doctor_profile_id, handleSelectDoctor, disabled }) => {
    return <Form.Group>
        <Form.Label>Select Doctor:</Form.Label>
        <Form.Control as="select" className="mx-sm-3" onChange={handleSelectDoctor} name="doctor_profile_id" value={doctor_profile_id} disabled={disabled}>
            <option value="">Select Doctor</option>
            {doctors.map(doctor => (
                <option value={doctor.user_profile_id} key={doctor.user_profile_id}>{doctor.full_name}</option>
            ))}
        </Form.Control>
    </Form.Group>
}

class ScheduleAppointmentModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            doctors: '',
            user_location_id: '',
            doctor_profile_id: this.props.doctor_profile_id || '',
            from: '',
            to: '',
            dates: '',
            selected_date: '',
            current_dates_pointer: 0,
            selected_date_slot: '',
            slot_id: '',
            patient_name: this.props.patient_name || '',
            patient_contact: this.props.patient_contact || '',
            patient_id: this.props.patient_id,
            patient_code: this.props.patient_code
        }
        this.validator = new SimpleReactValidator();
    }
    handleDatePagination = (type) => {
        let { current_dates_pointer, dates } = this.state;
        if (type === 'next') {
            current_dates_pointer = ((dates.length - 1) > (current_dates_pointer + 6)) ? current_dates_pointer + 6 : current_dates_pointer;
        } else if (type === 'prev') {
            current_dates_pointer = (0 <= (current_dates_pointer - 6)) ? current_dates_pointer - 6 : current_dates_pointer
        }
        this.setState({ current_dates_pointer })
    }
    componentDidUpdate(_prevProps, prevState) {
        let { to, from, doctor_profile_id } = this.state;

        if ((prevState.to !== to) || (prevState.from !== from) || prevState.doctor_profile_id !== doctor_profile_id) {
            this.setState({
                dates: '', selected_date_slot: '', selected_date: ''
            })
        }
    }
    componentDidMount() {
        const { user_location_id } = this.props.selected_user_profile
        this.props.showLoader();
        AppointmentService.getDoctorByLocation(user_location_id).then(resp => {
            this.setState({
                doctors: resp.data,
                user_location_id: user_location_id,
                from: '',
                to: '',
            });
            this.props.hideLoader();
        }).catch(_error => {
            this.props.hideLoader();
            Notification.show('error', { message: 'Something went wrong.' })
        })
    }
    handleSelectedDate = (date) => {
        let { user_location_id, doctor_profile_id } = this.state;
        if (date.count > 0) {
            this.props.showLoader();
            const queryParams = {
                doctor_profile_id: doctor_profile_id,
                user_location_id: user_location_id,
                from: date.date,
                to: date.date,
            }
            AppointmentService.getSlot({ filter: queryParams }).then(resp => {
                this.props.hideLoader()
                if (resp.data.data.length > 0) {
                    let slots = resp.data.data[0].slots;
                    let selected_date_slot = groupBy(slots, 'day_part');
                    this.setState({ selected_date_slot, selected_date: date.date });
                }
            }).catch(err => {
                this.props.hideLoader();
                Notification.show('error', { message: 'Something went wrong.' })
            });
        } else {
            this.setState({ selected_date_slot: {}, selected_date: date.date });
        }
    }
    handleChange = (evt) => this.setState({ [evt.target.name]: evt.target.value })

    getslotCount = (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        this.props.showLoader();
        let params = {
            location_id: this.state.user_location_id,
            doctor_profile_id: this.state.doctor_profile_id,
            to: this.state.to,
            from: this.state.from
        }
        AppointmentService.slotCount(params).then(resp => {
            let { data } = resp.data, slot_days = [], startDate = moment(this.state.from), endDate = moment(this.state.to), now = startDate.clone();
            while (now.isSameOrBefore(endDate)) {
                slot_days.push(now.format('YYYY-MM-DD'));
                now.add(1, 'days');
            }
            let dates = [];
            slot_days.map(date => {
                let found = find(data, (item) => {
                    if (moment(date).isSame(item.date, 'day')) return date
                });
                return dates.push(this.getDateArray(date, found))
            });
            this.setState({ dates })
            this.props.hideLoader();
        }).catch(_error => {
            this.props.hideLoader();
            Notification.show('error', { message: 'Something went wrong.' })
        })
    }
    handleSlotSelection = (item) => {
        this.setState({ slot_id: item.id })
    }
    getSlots = (slot, key) => {
        return <Col md={24}>
            <div className="title"><span>{key}</span> ({slot.length} Slot)</div>
            <div className="appmnt-slots-data">
                <ul className="row list-inline">
                    {slot.map(item => (
                        <li className="col-md-3" key={item.id}>
                            <a href='javascript:void(0)' onClick={(e) => this.handleSlotSelection(item)} className={this.getClassName(item)}>{item.start_time_12hour} To {item.end_time_12hour}</a>
                        </li>
                    ))}
                </ul>
            </div>
        </Col>
    }
    getClassName = (appointment) => {
        let diff = moment(appointment.start_time, 'HH:mm:ss').diff(moment(), 'minutes')
        if (moment().isSame(this.state.selected_date, 'day') && diff <= 120) {
            return "blocked"
        }

        return appointment.status === 'booked' ? "blocked" : this.state.slot_id === appointment.id ? "selected" : ''
    }
    getDateArray = (date, found) => {
        if (moment().isSame(date, 'day')) {
            return {
                label: 'Today',
                date_label: moment(date, 'YYYY-MM-DD').format('DD MMMM'),
                date: date,
                count: found ? found.count : 0,
            }
        } else if (moment().add(1, 'days').isSame(date, 'day')) {
            return {
                label: 'Tomorrow',
                date_label: moment(date, 'YYYY-MM-DD').format('DD MMMM'),
                date: date,
                count: found ? found.count : 0,
            }
        } else {
            return {
                label: moment(date, 'YYYY-MM-DD').format('dddd'),
                date_label: moment(date, 'YYYY-MM-DD').format('DD MMMM'),
                date: date,
                count: found ? found.count : 0,
            }
        }
    }
    getCountSpan = (date) => {
        return date.count ? <span className="schedule-appmnt-slot">{(date.count + " Slot Available")}</span> : <span className="schedule-appmnt-slot no-available">(No Slot Available)</span>
    }
    sliceDateArray = () => {
        let { current_dates_pointer, dates } = this.state;
        return dates.slice(current_dates_pointer, current_dates_pointer + 6)
    }
    handleSubmit = (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        if (!this.state.patient_name || !this.state.patient_contact) {
            Notification.show('error', { message: 'Please enter required details' });
            return false
        }
        if (!this.state.slot_id) {
            Notification.show('error', { message: 'Please select slot' });
            return false
        }
        this.props.handleSubmit(this.state)
    }
    render() {
        let { selected_date_slot } = this.state
        return <Modal size="xl" aria-labelledby="contained-modal-title-vcenter" centered show={this.props.showHide} backdrop="static" keyboard={false} onHide={this.props.handleModalShowHide}>
            <Modal.Header closeButton >
                <Modal.Title>{this.props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="schedule-appmnt-popup">
                    <Form inline>
                        <Row>
                            <Col md={12} className="pb-sm-1 pt-sm-1 v-line mb-3">
                                {this.state.doctors && <SelectDoctor
                                    doctors={this.state.doctors}
                                    doctor_profile_id={this.state.doctor_profile_id}
                                    handleSelectDoctor={this.handleChange}
                                    disabled={this.props.doctor_profile_id ? 'disabled' : ''} />}
                                {this.validator.message("doctor_profile_id", this.state.doctor_profile_id, "required")}
                            </Col>
                            <Col md={12} className="pb-sm-1 pt-sm-1 v-line mb-3">
                                <Form.Group>
                                    <Form.Label>Patient ID:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="mx-sm-3"
                                        value={this.state.patient_code}
                                        readOnly
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={12} className="pb-sm-1 pt-sm-1 v-line mb-3">
                                <Form.Group>
                                    <Form.Label className="required">Patient Name:</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        className="mx-sm-3"
                                        name="patient_name"
                                        value={this.state.patient_name}
                                        onChange={this.handleChange}
                                        disabled={this.props.patient_name ? 'disabled' : ''}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={12} className="pb-sm-1 pt-sm-1 v-line">
                                <Form.Group>
                                    <Form.Label className="required">Contact Number:</Form.Label>
                                    <Form.Control
                                        required
                                        type="phone"
                                        name="patient_contact"
                                        className="mx-sm-3"
                                        value={this.state.patient_contact}
                                        onChange={this.handleChange}
                                        disabled={this.props.patient_contact ? 'disabled' : ''}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={12} className="pb-sm-1 pt-sm-1 v-line">
                                <Form.Group>
                                    <Form.Label className="required">Date:</Form.Label>
                                    <Form.Control
                                        required
                                        type="date"
                                        className="mr-3 i-cale from-date"
                                        name="from"
                                        min={moment().format('YYYY-MM-DD')}
                                        max={this.state.to}
                                        onChange={this.handleChange}
                                        value={this.state.from} />
                                    <Form.Label>To</Form.Label>
                                    <Form.Control
                                        required
                                        type="date"
                                        className="mx-sm-3 i-cale from-date"
                                        name="to"
                                        min={this.state.from ? this.state.from : moment().format('YYYY-MM-DD')}
                                        onChange={this.handleChange}
                                        value={this.state.to} />
                                </Form.Group>
                                {this.validator.message("from_date", this.state.from, "required")}
                                {this.validator.message("to_date", this.state.to, "required")}
                            </Col>
                        </Row>
                        <Row className="w-100 mt-5">
                            <Col md={24} className="d-flex justify-content-md-center">
                                <Button variant="primary" onClick={this.getslotCount}>Search Schedule</Button>
                            </Col>
                        </Row>
                    </Form>
                    {!isEmpty(this.state.dates) &&
                        <Row className="pagination-mfixes">
                            <Col md={24} className="p-0">
                                <Pagination className="custom_pagination">
                                    <Pagination.Prev disabled={this.state.dates.length <= 6 ? "disabled" : ''} onClick={() => this.handleDatePagination('prev')} />
                                    {this.sliceDateArray().map(date => (
                                        <Pagination.Item onClick={() => this.handleSelectedDate(date)} active={this.state.selected_date === date.date ? 'active' : ''}>
                                            {
                                                <span>{date.label}<span className="schedule-appmnt-date">{date.date_label}</span>{this.getCountSpan(date)}</span>

                                            }
                                        </Pagination.Item>
                                    ))}
                                    <Pagination.Next disabled={this.state.dates.length <= 6 ? "disabled" : ''} onClick={() => this.handleDatePagination('next')} />
                                </Pagination>
                            </Col>
                        </Row>}
                    <Row className="appmnt-slots">
                        {this.state.selected_date && isEmpty(this.state.selected_date_slot) && <Col md={24}>
                            <div className="appmnt-slots-data text-center">
                                <div className="text-danger">(No Slot Available)</div>
                            </div>
                        </Col>}
                        {!isEmpty(this.state.selected_date_slot) && this.state.selected_date && Object.keys(selected_date_slot).map((key) => (
                            selected_date_slot[key].length > 0 && this.getSlots(selected_date_slot[key], key)
                        ))}
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                {!isEmpty(this.state.dates) && <div className="slots-data-sign">
                    <span className="slots-data-sign-Blocked">Booked</span>
                    <span className="slots-data-sign-Selected">Selected</span>
                </div>}
                <Button variant="primary" onClick={this.handleSubmit}>
                    Schedule
                </Button>
                <Button variant="outline-primary" onClick={this.props.handleModalShowHide}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    }
}
const mapStateToProps = (state) => ({
    selected_user_profile: state.selected_user_profile
});

const mapActionsToProps = {
    showLoader: loaderAction.loaderShow,
    hideLoader: loaderAction.loaderHide
};
export default connect(mapStateToProps, mapActionsToProps)(ScheduleAppointmentModal)
