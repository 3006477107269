import React from 'react';
import * as Config from "../../config";

class TrialAccountExpirePage extends React.Component {
    render(){
        return ( 
                <div className="row justify-content-md-center align-items-center trial-account">
                    <div className="col-md-10 text-center page-404-wrapper">
                        <img src="/images/trial-account-expire.png" alt="Trial account expires"/>
                        <h5><strong>Trial account is expired</strong></h5>
                        <p>Please contact daisy health team for further details</p>
                        <p>Toll Free No. <span>{Config.TOLL_FREE_NO}</span></p>
                    </div>
                </div>
        );
    }
}
export default TrialAccountExpirePage;