import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import * as AuthAction from '../../actions/authAction';
import Loader from '../../components/Loader';
import { Helmet } from 'react-helmet';
import ReCAPTCHABlock from "../../components/ReCAPTCHABlock";

class ForgotPasswordPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
        };
        this.recaptchaRef = React.createRef();
        this.validator = new SimpleReactValidator();
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    verifySubmit = (evt) => {
        evt.preventDefault();
        this.recaptchaRef.execute();
    }

    verifiedSubmit = () => {
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        this.props.forgotPassword(this.state.email);
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Forgot Password</title>
                </Helmet>
                <ReCAPTCHABlock ref={el => this.recaptchaRef = el} onSubmit={this.verifiedSubmit} />
                <div className="container login-register-wrapper">
                    <div className="row justify-content-md-center">
                        <div className="col-lg-8 col-md-16 col-sm-24">
                            <img src="/images/logo1.png" className="logo" alt="" />
                            <h4>Forgot Password</h4>
                            <p>Enter your email to reset your password</p>
                            <Loader />
                            <form onSubmit={this.verifySubmit}>
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Email" id="email" name="email" value={this.state.email} onChange={this.handleChange} autoComplete="email" />
                                    {this.validator.message('email', this.state.email, 'required')}
                                </div>
                                <div className="form-group clearfix">
                                    <button type="submit" className="btn btn-primary float-left">Request</button>
                                    <Link to="/login" className="float-right forgot-password"> Cancel </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    alert: state.alert
});

const mapActionsToProps = ({
    forgotPassword: AuthAction.forgotPassword
})

export default connect(mapStateToProps, mapActionsToProps)(ForgotPasswordPage);