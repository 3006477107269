import * as types from './actionTypes';

export function openPopup(key) {
    return dispatch => {
        dispatch({ type: types.SHOW_POPUP, key: key });
    }
}

export function closePopup(key) {
    return dispatch => {
        dispatch({ type: types.HIDE_POPUP, key: key });
    }
}