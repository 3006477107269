import * as types from './actionTypes';
import UserActivityService from '../services/userActivityService';
import Notification from '../utils/notification'

export function userActivities({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.USER_ACTIVITY_LOG, request: { page, sizePerPage, sortBy, searchText, filter } });

        UserActivityService.userActivities({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: types.USER_ACTIVITY_LOG, data });
            })
            .catch(error => {
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                if (error.response && error.response.status) {
                    Notification.show('error', error.response.data)
                }
            });
    }
}