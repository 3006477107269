import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class HospitalService {
    static paginateRecords({ page, sizePerPage, sortBy, searchText, filter }, status) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute('/v1/hospitals/records/'+status+'/status'), requestOptions);
    }

    static getRecord(recordId) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute('/v1/hospitals/records/' + recordId), requestOptions);
    }

    static confirmRecord(recordId){
        const requestOptions = {
            headers: getApiHeader(),
        };
        
        return axios.patch(apiRoute('/v1/hospitals/records/' + recordId + '/confirmed'), null, requestOptions);
    }

    static dischargeRecord(recordId,data){
        const requestOptions = {
            headers: getApiHeader(),
        };
        
        return axios.patch(apiRoute('/v1/hospitals/records/' + recordId + '/discharged'), data, requestOptions);
    }

    static getCount(status){
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute('/v1/hospitals/records/count/' + status), requestOptions);
    }
}

export default HospitalService;