import React from "react";
import OfflinePrescriptionService from '../services/offlinePrescriptionService';
import _ from 'lodash';
import Notification from '../utils/notification';

class AsyncDemoSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            drug_skus: '',
        }
        this.changeHandle = this.changeHandle.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.medicine !== prevProps.medicine) {
            if (this.props.medicine.is_given === "yes" && this.props.medicine.drug_id && _.isEmpty(this.props.medicine.drug_sku_id)) {
                this.promiseOptions()
            }
        }
    }

    promiseOptions = () =>
        new Promise(resolve => {
            resolve(this.loadDrugSkus(this.props.medicine.drug_id));
        });

    loadDrugSkus = (drug_id) => {
        OfflinePrescriptionService.getDrugSkus(drug_id).then(resp => {
            if(_.isEmpty(resp.data)){
                Notification.show('error', {message: "No package exist for selected medicine"})
            }
            this.setState({
                drug_skus: resp.data
            })
        })
    }

    changeHandle = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
        this.props.handleChange(e, this.state.drug_skus)
    }

    render() {
        return (
            <select name="package_size" className="form-control" value={this.props.package_size} onChange={this.changeHandle} disabled={this.props.package_size_disabled}>
                {!this.props.package_size && <option value="" defaultValue disabled selected>select</option>}
                {
                    this.state.drug_skus && this.state.drug_skus.map((drug_sku) => (
                        <option value={drug_sku.id} data-ndc={drug_sku.ndc_code}>{drug_sku.package_size}</option>
                    ))
                }
                {((this.props.medicine.drug_id === '') || (this.props.medicine.drug_id === null)) &&
                    <option value={1} data-ndc={''}>{1}</option>
                }
            </select>
        )
    }
}

export default AsyncDemoSelect