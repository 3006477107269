import React from 'react';
import { connect } from 'react-redux';

class Loader extends React.Component {
    render() {
        return (
            <React.Fragment>
                {this.props.loader && this.props.loader.isloading && <div className="loading">Loading</div>}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    loader: state.loader
});

export default connect(mapStateToProps, null)(Loader);