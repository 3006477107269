import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import * as PriceListAction from '../../actions/priceListAction';
import DataGrid from '../../components/DataGrid';

class RadiologyTestPriceListPage extends React.Component {

    columns = () => [{
        dataField: 'id',
        text: 'ID',
        hidden: true
    }, {
        dataField: 'test_name',
        text: 'Test Name',
    }, {
        dataField: 'current_price',
        text: 'Price',
    }, {
        dataField: 'future_price',
        text: 'Future Price',
        formatter : this.priceFormatter
    }, {
        dataField: 'update_date',
        text: 'Price Update Date',
        formatter : this.priceFormatter
    }];

    priceFormatter = (cell, row) => {
        if (!cell) {
            return '--';
        }
        return cell
    }

    constructor(props) {
        super(props);

        this.props.paginate();
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        if (type === 'search') {
            page = 1;
        }

        this.props.paginate(page, sizePerPage, { sortField, sortOrder }, searchText);
    }

    render() {
        let { data, meta } = this.props;

        return (<React.Fragment>
            <Helmet>
                <title>Radiology Tests</title>
            </Helmet>
            <div className="row">
                <div className="col-md-24">
                    <h4 className="page-title">Radiology Tests</h4>
                </div>
            </div>
            <div className="content-wrapper">
                <DataGrid
                    columns={this.columns()}
                    data={data}
                    meta={meta}
                    handleTableChange={this.handleTableChange}
                />
            </div>
        </React.Fragment>);
    }
}

const mapStateToProps = (state) => ({
    ...state.price_list
});

const mapActionsToProps = ({
    paginate: PriceListAction.RadiologyTestList
})

export default connect(mapStateToProps, mapActionsToProps)(RadiologyTestPriceListPage);