import * as types from './actionTypes';
import AppointmentService from '../services/appointmentService';
import Notification from '../utils/notification';

export function list({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: types.APPOINTMENT_LIST_REQUEST, request: { page, sizePerPage, sortBy, searchText, filter } });

        AppointmentService.paginateAppointments({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.APPOINTMENT_LIST_SUCCESS, ...data });
            })
            .catch(error => {
                dispatch({ type: types.APPOINTMENT_LIST_FAILED, error: error.response });
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    Notification.show('error', errorData)
                }
            });
    }
}

export function holidaylist(user_location_id, { page, sizePerPage, sortBy, filter }) {
    return dispatch => {
        dispatch({ type: types.HOLIDAY_LIST_REQUEST, request: { user_location_id, page, sizePerPage, sortBy, filter } });

        AppointmentService.getSlotHolidays(user_location_id, { page, sizePerPage, sortBy, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.HOLIDAY_LIST_SUCCESS, ...data });
            })
            .catch(error => {
                dispatch({ type: types.HOLIDAY_LIST_FAILED, error: error.response });
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    Notification.show('error', errorData)
                }
            });
    }
}
