import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function (state = initialState.pagination, { type, ...payload }) {
    switch (type) {
        case types.LIST_RESET:
            return {
                ...initialState.pagination
            };
        case types.PATIENT_PATHOLOGY_TEST_LIST_REQUEST:
            return {
                ...state,
                ...payload
            };
        case types.PATIENT_PATHOLOGY_TEST_LIST_SUCCESS:
            return {
                ...state,
                ...payload,
                is_requested: true,

            };
        case types.PATIENT_PATHOLOGY_TEST_LIST_FAILED:
            return {
                ...state,
                ...payload,
                is_requested: true,
            };
        case types.PATIENT_RADIOLOGY_TEST_LIST_REQUEST:
            return {
                ...state,
                ...payload
            };
        case types.PATIENT_RADIOLOGY_TEST_LIST_SUCCESS:
            return {
                ...state,
                ...payload,
                is_requested: true,

            };
        case types.PATIENT_RADIOLOGY_TEST_LIST_FAILED:
            return {
                ...state,
                ...payload,
                is_requested: true,
            };


        case types.LIST_PATHOLOGY_ORDER_REQUEST:
            return {
                ...state,
                ...payload
            };
        case types.LIST_PATHOLOGY_ORDER_SUCCESS:
            return {
                ...state,
                ...payload,
                is_requested: true,

            };
        case types.LIST_PATHOLOGY_ORDER_FAILED:
            return {
                ...state,
                ...payload,
                is_requested: true,
            };


        case types.LIST_RADIOLOGY_ORDER_REQUEST:
            return {
                ...state,
                ...payload
            };
        case types.LIST_RADIOLOGY_ORDER_SUCCESS:
            return {
                ...state,
                ...payload,
                is_requested: true,

            };
        case types.LIST_RADIOLOGY_ORDER_FAILED:
            return {
                ...state,
                ...payload,
                is_requested: true,
            };
        default:
            return state;
    }

}