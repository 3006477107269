import React from 'react';
import _ from 'lodash';

const ReturnToWork = ({ validator, form_attributes, patient, handleChangeCertificate }) => {
    return (
        <React.Fragment>
                <div className="row mb-4">
                    <div className="col-md-24 certi-details">
                        <span>{patient.full_name} has been under my care</span>
                        <form className="form-inline d-inline">
                            from <input type="date" className="form-control i-cale ml-1" name="start_date" value={form_attributes.start_date} onChange={handleChangeCertificate} />
                            <span className="mr-2 ml-2">to</span> <input type="date" className="form-control i-cale" name="end_date" value={form_attributes.end_date} onChange={handleChangeCertificate.bind(this)} min={form_attributes.start_date}/>
                        </form>
                        <span>and is able to return to work on</span>
                        <form className="form-inline d-inline">
                            <input type="date" className="form-control i-cale" name="return_on" value={form_attributes.return_on} onChange={handleChangeCertificate} min={form_attributes.end_date} disabled={!_.isEmpty(form_attributes.start_date) && !_.isEmpty(form_attributes.end_date) ? false : true }/>
                        </form>
                        {validator.message('Start Date', form_attributes.start_date, 'required')}
                        {validator.message('End Date', form_attributes.end_date, 'required')}
                        {validator.message('Return on', form_attributes.return_on, 'required')}
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-md-24">
                        <h6 className="blue-main-heading">Nature of Illness/Injury</h6>
                    </div>
                    <div className="col-md-24 mb-3">
                        <textarea className="form-control" name="nature_of_illness" value={form_attributes.nature_of_illness} onChange={handleChangeCertificate.bind(this)}></textarea>
                        {validator.message('Nature of illness', form_attributes.nature_of_illness, 'required')}
                    </div>
                    <div className="col-md-24">
                        <select className="form-control" name="restriction" onChange={handleChangeCertificate.bind(this)} value={form_attributes.restriction}>
                            <option value=""> Select Action </option>
                            <option value="restriction">Restriction</option>
                            <option value="no_restriction">No Restriction</option>
                        </select>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-md-24">
                        <h6 className="blue-main-heading">Comments</h6>
                    </div>
                    <div className="col-md-24">
                        <textarea className="form-control" name="comments" value={form_attributes.comments} onChange={handleChangeCertificate.bind(this)}></textarea>
                        {validator.message('Comments', form_attributes.comments, 'string')}
                    </div>
                </div>
        </React.Fragment>
    )
}

export default ReturnToWork;