import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import SearchPatientWithoutCode from '../../components/SearchPatientWithoutCode'

export class SearchPatientPage extends Component {

    handlePatientChange = (patient_id) => {
        this.props.history.push('/ambulance/records/patients/' + patient_id);
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Search Patient</title>
                </Helmet>
                <div className="row">
                    <div className="col-md-24">
                        <h4 className="page-title">Search Patient</h4>
                        <div className="content-wrapper">
                            <SearchPatientWithoutCode 
                                handlePatientSearch={this.handlePatientChange}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default SearchPatientPage
