import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import BodyChart from './BodyChartComponent'
import ViewBodyChart from './ViewBodyChartComponent'
class FemaleBack extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      paths: [{
        "className": "head",
        "value": "Head",
        "fill": "transparent",
        "stroke": "#ffc0cb",
        "cursor": "default",
        "name":"head",
        "d": "M385.356,157.415c0.778-1.884,1.42-3.543,1.733-4.601c8.911-30.064,10.593-68.02,7.453-84.763C387.5,30.5,368.5,18.687,339.467,18.687c-24.545,0-45.634,15.813-51.083,50.793c-0.606,3.894-0.718,18.188-0.385,24.688s5.167,38.333,6.5,51.167c0.402,3.875,1.295,7.53,2.367,10.833C311.74,171.064,368.674,171.289,385.356,157.415z"
      }, {
        "className": "neck",
        "value": "Neck",
        "fill": "transparent",
        "stroke": "#ffc0cb",
        "cursor": "default",
        "name":"neck",
        "d": "M418.288,214.58c-24.707-4.058-43.897-6.108-38.521-44.124c0.075-0.529,3.382-7.699,5.588-13.042c-16.682,13.874-73.616,13.649-88.489-1.249c2.479,7.635,5.935,13.355,6.633,15.333c1,2.833,1.101,23.757-0.833,27.333c-4.764,8.81-20.45,14.072-36.135,16.958C291.887,219.835,396.844,219.564,418.288,214.58z"
      }, {
        "className": "back",
        "value": "Back",
        "fill": "transparent",
        "stroke": "#ffc0cb",
        "cursor": "default",
        "name":"back",
        "d": "M446.059,310.366c1.042-6.596,4.563-15.113,6.822-24.408c1.684-6.926,2.669-14.283,1.402-21.604c-1.211-6.999-8.583-33.088-27.918-48.359c-2.739-0.525-5.439-0.982-8.077-1.415c-21.444,4.984-126.401,5.255-151.756,1.211c-2.954,0.543-5.906,1-8.787,1.383c-20.905,14.732-28.539,41.156-28.638,52.963c-0.051,6.076,0.717,11.467,1.741,16.199c1.65,7.623,3.969,13.524,4.624,17.745c0.934,6.021,4.098,12.505,3.345,30.311c0.696-0.763,1.362-0.075,1.6,1.775c0.333,2.583,0.414,8.646,0.583,14.167c0.833,27.167,8.25,62.417,10.5,74.417c0.864,4.607,2.133,12.61,3.412,21.707c12.51,3.593,36.984,6.452,64.054,7.054c8.353,0.186,16.951-1.671,25.519-1.713c8.629-0.042,17.228,1.729,25.513,1.458c24.939-0.817,47.03-3.692,58.604-7.104c0.909-7.375,1.759-13.759,2.148-15.152c1.348-4.822,7.622-41.454,8.5-45.25c2.354-10.18,2.502-27.106,3.25-30c0.749-2.893,0.702-21.669,1.75-21.75c0.085-0.006,0.299,0.349,0.618,0.999C444.572,327.208,444.675,319.114,446.059,310.366z"
      }, {
        "className": "loin",
        "value": "Loin",
        "fill": "transparent",
        "stroke": "#ffc0cb",
        "cursor": "default",
        "name":"loin",
        "d": "M344.485,451.798c-8.568,0.042-17.167,1.899-25.519,1.713c-27.069-0.602-51.543-3.461-64.054-7.054c2.051,14.595,4.126,32.012,4.588,42.793c0.75,17.5-3,26-8.75,39.25c-2.333,5.377-6.521,15.532-11.106,27.505c12.579-6.673,39.644-11.567,58.938-11.567c26.517,0,27.864,9.913,46.273,9.913c17.061,0,23.347-9.685,46.269-9.805c19.259-0.1,44.073,7.603,54.367,15.691c-3.004-8.806-5.876-17.667-8.824-23.904c-4.333-9.167-11.751-27.5-12.667-31.583c-1.039-4.633,0.9-27.839,1.5-32.5c0.354-2.753,1.789-15.457,3.102-26.098c-11.573,3.412-33.664,6.287-58.604,7.104C361.712,453.527,353.114,451.755,344.485,451.798z"
      },{
        "className": "buttocks",
        "value": "Buttocks",
        "fill": "transparent",
        "stroke": "#ffc0cb",
        "cursor": "default",
        "name":"buttocks",
        "d": "M344.855,554.351c-18.409,0-19.756-9.913-46.273-9.913c-19.295,0-46.359,4.895-58.938,11.567c-6.713,17.528-14.282,38.953-18.144,54.995c-5.731,23.808-6.991,46.636-7.209,63.701c29.158,39.25,78.172,59.463,116.954,24c0.208-1.105,0.721-1.672,1.672-2.2c1.5-0.834,5.25-6.916,6.25-8.75s-0.5-4.25,2.667-4.25s2.167,2.167,3.083,4.417s5.25,6.833,6.75,9.25c0.244,0.394,0.419,0.699,0.549,0.957c38.419,35.822,87.09,16.505,116.522-22.031c-0.555-18.68-3.028-36.469-5.238-50.094c-3-18.5-9-41.667-13.666-53.667c-1.491-3.835-2.932-7.959-4.343-12.096c-10.294-8.089-35.107-15.792-54.367-15.691C368.202,544.666,361.917,554.351,344.855,554.351z"
      },{
        "className": "shoulder-lt",
        "value": "Shoulder [LT]",
        "fill": "transparent",
        "stroke": "#ffc0cb",
        "cursor": "default",
        "name":"shoulder-lt",
        "d": "M230.848,286.337c-1.024-4.732-1.792-10.124-1.741-16.199c0.1-11.807,7.733-38.231,28.638-52.963c-3.144,0.418-6.203,0.747-9.078,0.993c-19.5,1.667-30,7.167-40.667,17.667s-12.833,29-16.5,42.167s-6.167,32.833-8.667,47.667c-0.185,1.098-0.394,2.236-0.618,3.4C193.799,316.474,215.07,302.938,230.848,286.337z"
      },{
        "className": "shoulder-rt",
        "value": "Shoulder [RT]",
        "fill": "transparent",
        "stroke": "#ffc0cb",
        "cursor": "default",
        "name":"shoulder-rt",
        "d": "M454.283,264.354c1.267,7.32,0.281,14.678-1.402,21.604c15.378,16.313,36.147,29.67,48.012,42.064c-2.872-15.51-2.674-29.3-12.393-66.522c-9.696-37.136-34.926-40.146-61.182-45.321c-0.318-0.063-0.636-0.124-0.953-0.184C445.7,231.266,453.072,257.356,454.283,264.354z"
      },{
        "className": "arm-lt",
        "value": "Arm [LT]",
        "fill": "transparent",
        "stroke": "#ffc0cb",
        "cursor": "default",
        "name":"arm-lt",
        "d": "M235.471,304.082c-0.655-4.221-2.974-10.122-4.624-17.745c-15.777,16.601-37.049,30.137-48.632,42.73c-2.809,14.561-8.511,33.735-12.215,43.767c-4,10.833-11.333,33.333-15.333,47.333c-0.271,0.947-0.549,1.88-0.833,2.804c2.254,13.99,11.089,17.709,19.833,21.162c9.813,3.874,18.164,1.82,24.583-5.67c2.1-7.862,12.472-30.725,17.083-43.129c4.833-13,21.75-56.583,22.667-59.333c0.26-0.781,0.541-1.305,0.816-1.608C239.569,316.587,236.405,310.102,235.471,304.082z"
      },{
        "className": "arm-rt",
        "value": "Arm [RT]",
        "fill": "transparent",
        "stroke": "#ffc0cb",
        "cursor": "default",
        "name":"arm-rt",
        "d": "M510.415,444.132c8.355-3.299,16.792-6.843,19.493-19.357c-2.97-9.552-5.826-22.777-10.408-36.525c-2.616-7.848-10.031-27.096-16.289-50.052c-0.987-3.621-1.718-6.936-2.318-10.176c-11.864-12.394-32.634-25.751-48.012-42.064c-2.26,9.295-5.78,17.813-6.822,24.408c-1.384,8.748-1.486,16.841-1.19,24.633c3.607,7.351,20.851,53.064,21.882,55.501c1.122,2.652,13.73,35.901,15.75,40c0.62,1.258,1.399,3.698,2.203,6.554C491.26,445.68,500.016,448.238,510.415,444.132z"
      },{
        "className": "elbow-lt",
        "value": "Elbow [LT]",
        "fill": "transparent",
        "stroke": "#ffc0cb",
        "cursor": "default",
        "name":"elbow-lt",
        "d": "M173.667,444.132c-8.744-3.453-17.579-7.172-19.833-21.162c-3.906,12.737-8.938,23.293-12.668,31.529c-2.147,4.742-5.686,12.941-8.969,21.789c4.363-4.216,12.979-7.047,32.166-1.196c6.256,1.908,18.88,7.908,21.493,17.354c2.268-8.253,4.666-21.363,6.144-25.947c1.667-5.167,4.667-20.333,6-27c0.061-0.306,0.149-0.66,0.25-1.038C191.831,445.953,183.479,448.006,173.667,444.132z"
      },{
        "className": "elbow-rt",
        "value": "Elbow [RT]",
        "fill": "transparent",
        "stroke": "#ffc0cb",
        "cursor": "default",
        "name":"elbow-rt",
        "d": "M519.719,475.093c17.666-5.388,26.376-3.416,31.052,0.222c-3.518-10.316-7.057-17.938-8.021-19.564c-1.571-2.652-5.5-13.5-10-23.25c-0.994-2.155-1.924-4.77-2.842-7.725c-2.701,12.514-11.138,16.058-19.493,19.357c-10.399,4.106-19.155,1.547-25.712-7.079c1.814,6.445,3.757,15.015,4.297,16.946c1.396,4.992,3.128,13.187,4.25,18.25c0.746,3.363,1.606,9.433,4.882,20.598C500.495,483.172,513.375,477.027,519.719,475.093z"
      },{
        "className": "forearm-lt",
        "value": "Forearm [LT]",
        "fill": "transparent",
        "stroke": "#ffc0cb",
        "cursor": "default",
        "name":"forearm-lt",
        "d": "M164.363,475.093c-19.187-5.851-27.803-3.021-32.166,1.196c-2.833,7.635-5.475,15.751-6.864,22.545c-3,14.667-3.833,31.167-6.167,46.5c-1.317,8.659-4.655,21.356-8.076,31.76c0.041,4.226,5.042,6.953,12.832,10.491c4.603,2.09,11.921,4.052,17.679,4.78c5.512-11.326,14.676-28.423,20.898-41.531c7.833-16.5,20-47.833,22.5-55.5c0.282-0.864,0.568-1.838,0.856-2.886C183.243,483,170.619,477,164.363,475.093z"
      },{
        "className": "forearm-rt",
        "value": "Forearm [RT]",
        "fill": "transparent",
        "stroke": "#ffc0cb",
        "cursor": "default",
        "name":"forearm-rt",
        "d": "M572.271,579.668c-6.903-23.146-12.489-67.07-15.521-82.418c-1.548-7.837-3.76-15.422-5.979-21.936c-4.676-3.638-13.386-5.609-31.052-0.222c-6.344,1.934-19.224,8.08-21.587,17.755c1.656,5.645,3.928,12.588,7.118,21.152c9.197,24.688,23.002,50.249,35.914,77.07c0.09,0.187,0.26,0.683,0.494,1.392c5.866-0.635,13.676-2.687,18.501-4.877C566.249,584.819,570.627,582.546,572.271,579.668z"
      },{
        "className": "wrist-lt",
        "value": "Wrist [LT]",
        "fill": "transparent",
        "stroke": "#ffc0cb",
        "cursor": "default",
        "name":"wrist-lt",
        "d": "M111.091,577.094c-2.637,8.02-5.324,14.679-7.174,17.073c-0.801,1.037-2.903,2.156-5.536,3.241c10.734,1.056,27.465,8.22,36.476,14.186c0.176-2.485,0.76-5.597,3.311-11.76c0.822-1.987,2.008-4.538,3.435-7.469c-5.758-0.728-13.076-2.69-17.679-4.78C116.133,584.047,111.131,581.319,111.091,577.094z"
      },{
        "className": "wrist-rt",
        "value": "Wrist [RT]",
        "fill": "transparent",
        "stroke": "#ffc0cb",
        "cursor": "default",
        "name":"wrist-rt",
        "d": "M574.5,586.25c-0.757-1.922-1.5-4.138-2.229-6.582c-1.644,2.878-6.021,5.151-12.111,7.917c-4.825,2.191-12.635,4.242-18.501,4.877c0.587,1.78,1.59,4.951,2.842,8.288c1.155,3.082,2.127,7.941,2.679,12.28c7.984-5.969,25.61-13.901,37.272-15.479C580.674,595.721,577.034,592.687,574.5,586.25z"
      },{
        "className": "back-of-hand-lt",
        "value": "Back Of Hand [LT]",
        "fill": "transparent",
        "stroke": "#ffc0cb",
        "cursor": "default",
        "name":"back-of-hand-lt",
        "d": "M83.75,602.167c-5.917,1.667-7.083,4.167-11.833,6.417s-9.75,6.167-10.417,7.25s-2.75,2.917-5.167,5.667s-0.833,5.583,1.833,6.417s7.75-0.5,11.25-3.333s6.75-4.667,9.167-5.75s3.417,0.417,3.667,2.083s-2.583,9.583-4,14s-1.417,6.833-1.917,10.5s-2.583,12.917-3.25,17.667s-1.417,14.417-1.5,19.25s0.833,8.75,3.333,9.083s4.583-2,5.333-5.667s2.5-10.083,2.917-13.833s2.583-12.667,3.083-14.333s2.083-1.083,1.833,0.351s-1,5.482-1.5,8.149s-2.417,13.5-3.083,17.167c-0.667,3.666-1.5,14-1.333,18.167s2.833,6,5.167,4.833s4.583-6.5,5.333-10.75s2.167-10,3-15.25s2.917-15.083,4-18.583s2.417-0.917,2.333,0.917s-0.833,4.333-1,8.917s-1,15.584-1.5,19.834s-1.417,11,1.583,12.166s5.167-2.5,6.417-6.166s2.417-12.084,3.417-16.75S113,665.25,113.75,661.75s2.083-6.25,2.917-5.667s0.75,3.917,0,7.583s-1.667,9.75-2.667,13.5s-3.25,10.083,0,11.417s5.583-3.917,7-7.584s2.917-6.417,4.083-11s1.667-3.375,3.417-12.066s1.375-11.309,3.125-21.059s2.708-17.208,3.042-21.208c0.121-1.45,0.09-2.661,0.19-4.074c-9.011-5.966-25.741-13.13-36.476-14.186C93.767,599.309,87.518,601.105,83.75,602.167z"
      },{
        "className": "back-of-hand-Rt",
        "value": "Back Of Hand [RT]",
        "fill": "transparent",
        "stroke": "#ffc0cb",
        "cursor": "default",
        "name":"back-of-hand-rt",
        "d": "M623,619.25c-2.167-1.833-3.25-5.75-5.166-6.583c-4.294-1.868-5.833-3.5-10.167-5.667c-2.845-1.422-7.706-4.102-16-6.833c-2.291-0.755-4.781-1.438-7.216-2.616c-11.662,1.579-29.288,9.511-37.272,15.479c0.284,2.231,0.459,4.328,0.487,5.97c0.083,4.833,2.75,18,3.25,22.417s0.501,11.75,3.001,17.583s1.5,11.083,3.333,14.083s2.25,6.25,5.167,11.333c2.917,5.083,5.166,5.583,6.416,2.166s0-8.167-0.833-9.917s-1.917-7.583-2.334-10.417s-1.583-7.583-0.75-9.833s3.167,2.417,3.334,4.5s1.416,12.333,2.083,16.333s2.25,14.083,3.417,19.083s4.5,8.584,6.833,7.084s2.75-6.834,2.583-9.084s-1.416-12.499-1.583-15.583s-1.666-15.75-1.916-17.5s2.25-1.583,2.75,0.583s2.083,9.583,2.833,13s2.916,15.417,3.666,21.667s4.917,10.25,7.5,10.417s3.084-6.667,3.167-9.167s-1-12.5-1.5-15.333s-4.25-24.833-3.167-24.833s3.584,14.75,4.834,21.25c1.25,6.5,2.75,13,6.667,13.083s3.25-4.833,3.416-12.75s-0.75-13.917-2.333-25.25s-3.333-17.917-4.417-22s-3.166-10.75-2.083-12.583s7.5,1.667,11.333,5.167s10.834,4.5,13.25,2.5S625.167,621.083,623,619.25z"
      },{
        "className": "hamstring-lt",
        "value": "Hamstring [LT]",
        "fill": "transparent",
        "stroke": "#ffc0cb",
        "cursor": "default",
        "name":"hamstring-lt",
        "d": "M214.291,674.701c-0.029,2.288-0.041,4.48-0.041,6.549c0,17.5,1.75,35.25,3.75,48s13.25,79.25,16,96.25c2.169,13.408,7.448,52.006,11.381,76.551c12.794,17.416,28.61,21.08,42.423,21.946c13.647,0.857,27.359-1.631,35.557-9.223c0.568-15.524,0.902-32.5,0.64-40.274c-0.5-14.833-2.25-56-2.125-67.875s2.625-35,4.25-43.75s4.958-31.457,5.208-41.624s0-17-0.167-20c-0.061-1.098-0.042-1.912,0.078-2.55C292.463,734.164,243.449,713.951,214.291,674.701z"
      },{
        "className": "hamstring-rt",
        "value": "Hamstring [RT]",
        "fill": "transparent",
        "stroke": "#ffc0cb",
        "cursor": "default",
        "name":"hamstring-rt",
        "d": "M352.216,698.125c0.669,1.323,0.021,1.213-0.049,3.376c-0.083,2.583-0.833,6.832-0.333,16.332s3,33.5,6.167,48.667s3.5,45.5,3.167,59.333s-1.833,43.333-2,53c-0.1,5.755,0.215,21.138,0.662,35.071c8.068,8.272,22.294,10.981,36.45,10.093c13.415-0.841,28.717-4.327,41.308-20.48c1.438-8.746,2.679-16.652,3.081-21.016c1-10.833,4.5-31.167,6.5-44.667s9.166-56.5,11.5-71.5s8.333-44.334,9.833-70c0.396-6.763,0.437-13.557,0.238-20.24C439.306,714.63,390.635,733.947,352.216,698.125z"
      },{
        "className": "back-of-knee-lt",
        "value": "Back Of Knee [LT]",
        "fill": "transparent",
        "stroke": "#ffc0cb",
        "cursor": "default",
        "name":"back-of-knee-lt",
        "d": "M287.804,923.997c-13.813-0.866-29.628-4.53-42.423-21.946c1.054,6.575,2.011,12.146,2.786,15.949c3.667,18,2.667,23.833,4.167,33s0.833,17.5-1.667,33.5c-0.191,1.223-0.35,2.636-0.485,4.176c6.072-11.57,19.8-13.982,31.779-12.699c12.366,1.323,28.625,6.021,35.092,11.214c-0.222-4.039-0.335-7.883-0.386-11.357c-0.167-11.5,3.5-22.5,4.833-30.333c0.634-3.728,1.344-16.629,1.86-30.726C315.163,922.366,301.451,924.854,287.804,923.997z"
      },{
        "className": "back-of-knee-rt",
        "value": "Back Of Knee [RT]",
        "fill": "transparent",
        "stroke": "#ffc0cb",
        "cursor": "default",
        "name":"back-of-knee-rt",
        "d": "M396.278,923.997c-14.156,0.889-28.381-1.82-36.45-10.093c0.304,9.472,0.668,18.275,1.005,22.929c0.833,11.5,5.833,24.167,5.833,40.667c0,3.606-0.085,6.959-0.251,10.211c5.992-5.396,22.927-10.366,35.706-11.734c11.358-1.217,24.285,0.894,30.772,10.978c-0.721-4.93-1.487-9.509-2.06-13.453c-1.5-10.333-0.334-21.167,1.166-34.334c0.896-7.864,3.456-22.682,5.586-35.65C424.995,919.67,409.692,923.156,396.278,923.997z"
      },{
        "className": "calf-lt",
        "value": "Calf [LT]",
        "fill": "transparent",
        "stroke": "#ffc0cb",
        "cursor": "default",
        "name":"calf-lt",
        "d": "M250.182,988.676c-1.644,18.618,0.485,58.332,0.485,68.491c0,11,3.167,52.833,8,69.666c2.067,7.199,4.53,18.583,6.868,30.302c5.065,6.955,16.429,9.48,26.006,9.887c9.072,0.384,18.664-3.994,23.572-12.067c0.954-9.137,1.89-17.886,2.387-23.787c1.333-15.833,6.333-57.667,7-66.834s-0.167-34.5-3.5-48c-2.326-9.421-3.433-19.813-3.947-29.143c-6.467-5.192-22.726-9.891-35.092-11.214C269.981,974.693,256.254,977.105,250.182,988.676z"
      },{
        "className": "calf-rt",
        "value": "Calf [RT]",
        "fill": "transparent",
        "stroke": "#ffc0cb",
        "cursor": "default",
        "name":"calf-rt",
        "d": "M402.121,975.977c-12.778,1.368-29.714,6.339-35.706,11.734c-0.594,11.627-2.29,21.884-5.416,37.122c-4,19.5-2.167,39.5-0.167,53.834s3,38.5,6.167,63.5c0.523,4.132,1.001,8.069,1.438,11.864c4.719,8.662,14.69,13.389,24.103,12.99c8.899-0.377,19.338-2.587,24.836-8.479c3.605-15.573,7.638-32.348,9.79-44.542c4-22.666,5.5-50.667,5.5-59.167s2.166-28.333,2.333-42.333c0.104-8.655-0.94-17.563-2.106-25.547C426.406,976.87,413.479,974.76,402.121,975.977z"
      },{
        "className": "ankle-lt",
        "value": "Ankle [LT]",
        "fill": "transparent",
        "stroke": "#ffc0cb",
        "cursor": "default",
        "name":"ankle-lt",
        "d": "M291.541,1167.021c-9.577-0.406-20.94-2.932-26.006-9.887c3.129,15.681,6.034,31.961,7.465,39.615c2.5,13.375,2.667,35.916,2.083,40c-0.144,1.009-0.375,1.654-0.678,2.092c3.308,10.532,2.482,17.97,1.66,29.594c-0.249,3.515-0.06,13.297,0.44,20.242c0.508,0.56,1.035,1.105,1.598,1.626c4.882-14.596,28.534-15.045,35.094-7.896c1.65-5.945,1.032-11.148,2.053-18.408c1.125-8-0.125-13.125-0.5-16.625s1.875-5.125,3.375-12.125s-1.125-12.875-3-19.625s-2.458-13.625-2.958-22.959c-0.313-5.855,1.34-22.334,2.946-37.712C310.205,1163.027,300.613,1167.405,291.541,1167.021z"
      },{
        "className": "ankle-rt",
        "value": "Ankle [RT]",
        "fill": "transparent",
        "stroke": "#ffc0cb",
        "cursor": "default",
        "name":"ankle-rt",
        "d": "M368.438,1154.031c2.207,19.166,3.258,34.279,2.562,47.636c-0.408,7.827-3.25,15-5.417,22.25s-0.167,12.749,1.167,16.666s1.583,7,0.583,11.5s1.25,14.084,1.417,19.084c0.101,3.019,0.029,7.618,1.324,12.31c5.01-8.101,30.007-8.45,35.654,6.138c0.716-0.778,1.365-1.59,1.947-2.401c0.425-6.792,0.573-15.504,0.342-18.777c-0.809-11.434-1.615-18.82,1.506-29.082c-0.119-0.115-0.241-0.226-0.355-0.353c-1.5-1.667-1-7.333-1.333-10.667s0.5-18.833,2.666-36c1.001-7.932,3.781-20.418,6.877-33.791c-5.498,5.892-15.937,8.102-24.836,8.479C383.128,1167.42,373.157,1162.693,368.438,1154.031z"
      },{
        "className": "sole-lt",
        "value": "Sole [LT]",
        "fill": "transparent",
        "stroke": "#ffc0cb",
        "cursor": "default",
        "name":"sole-lt",
        "d": "M278.103,1290.304c4.011,3.708,9.411,6.354,16.022,6.821c10.625,0.75,16.125-6.125,18.5-12.875c0.22-0.625,0.404-1.236,0.572-1.842C306.637,1275.259,282.985,1275.708,278.103,1290.304z"
      },{
        "className": "sole-rt",
        "value": "Sole [RT]",
        "fill": "transparent",
        "stroke": "#ffc0cb",
        "cursor": "default",
        "name":"sole-rt",
        "d": "M370.074,1283.477c0.85,3.08,2.283,6.199,4.76,8.939c6.25,6.916,20.083,3.834,25.833,1.084c1.966-0.94,3.644-2.342,5.061-3.886C400.081,1275.026,375.083,1275.376,370.074,1283.477z"
      },{
        "className": "foot-lt",
        "value": "Foot [LT]",
        "fill": "transparent",
        "stroke": "#ffc0cb",
        "cursor": "default",
        "name":"foot-lt",
        "d": "M276.065,1268.436c0.822-11.624,1.647-19.062-1.66-29.594c-0.924,1.334-2.522,0.714-4.405,2.408c-1.34,1.206-1.583,2.583-3.833,2.667s-4.667,2.25-5.5,3.75s-2.75,0.25-4.917,1.416s-3,3.667-2.417,6.25s2.333,3.25,2.417,4.917s1,4.25,3.75,7.75s9.25,7.25,12.875,14.625c1.053,2.142,2.442,4.193,4.13,6.053C276.006,1281.732,275.816,1271.95,276.065,1268.436z"
      },{
        "className": "foot-rt",
        "value": "Foot [RT]",
        "fill": "transparent",
        "stroke": "#ffc0cb",
        "cursor": "default",
        "name":"foot-rt",
        "d": "M408.017,1268.436c0.231,3.273,0.083,11.985-0.342,18.777c1.638-2.285,2.762-4.545,3.409-5.88c1.334-2.75,9.5-11.333,12.75-14.916s2.499-5.084,2.499-7.084s2-2,2.5-5.333s-1.833-4.833-2.916-5.583s-4.084-0.75-4.25-1.417s-1.25-1.917-3.167-2.917s-4,0.667-4.917-1.083c-0.847-1.617-2.613-2.247-4.061-3.646C406.401,1249.615,407.208,1257.002,408.017,1268.436z"
      },],
      body_chart: this.props.body_chart,
      slug:'female_back',
      isClickDisabled:false,
      chart:this.props.chart,
      
    }
  }

  componentDidMount() {
    let paths = this.state.paths;
    let { body_chart } = this.props;
    let isClickDisabled= this.props.isClickDisabled ? this.props.isClickDisabled : false;
    this.state.paths.map((path, i) => (
      paths[i].fill = "rgba(255, 255, 255, 0)"
    ));
    
    this.setState({
      paths: paths,
      isClickDisabled:isClickDisabled,
    });
    
    if(!_.isEmpty(body_chart)){
      paths.map((path, i) => {
        if(body_chart.some(body_charts => path.value === body_charts.part_name && this.state.slug === body_charts.image_slug)){
          path.fill = "rgba(29, 81, 138)";
          path.className = path.className + " active";
            
          }
          return path;
      });
      this.setState({
        paths: paths,
      }); 
    }
  }

  setPaths = (paths) => {
    this.setState({
      paths: paths
    });
  };

  handleBodyChart = (bodyChart) => {
    let {body_chart} = this.state;
    bodyChart.map((chart, i) => {
        chart.image_slug = this.state.slug;
        chart.image_name= 'Female Back';
        return chart;
    });
    
    this.setState({ body_chart: body_chart.concat(bodyChart) })
    this.props.handleBodyChart(bodyChart)
      
  }
  removePart = (part) =>{
    let {body_chart,slug} = this.state;
    body_chart = body_chart.filter((s) =>{
            
      return part !== s.part_name || s.image_slug!==slug
    });
    this.setState({ body_chart: body_chart })
    this.props.removePart(part,slug)
  }
  removeBodyChart = (image_slug) =>{
    let { body_chart } = this.state;
    body_chart = body_chart.filter((s) =>{
        return s.image_slug!==image_slug
    });
    this.setState({ body_chart: body_chart })
    this.props.removeBodyChart(image_slug)
  }
  removeChart =(image_slug,e) =>{
    let { chart } = this.state;
    _.pull(chart, image_slug);
    this.setState({ chart: image_slug })
    this.removeBodyChart(image_slug)
  }
  changeHandler = (parts,e) => {
        
    let {body_chart,slug} = this.state;
    const result = body_chart.findIndex(s => s.part_slug === parts &&  s.image_slug===slug);
    
    body_chart[result][e.target.name] = e.target.value;
    this.setState({ body_chart: body_chart })
    this.props.changeHandler(parts,slug,e)
    
  }
  changeSubPartHandler = (parts, e) => {
    let {body_chart,slug} = this.state
    const result = body_chart.findIndex(s => s.part_slug === parts &&  s.image_slug===slug);

    body_chart[result]['sub_part'] = e.value;
    this.setState({ body_chart: body_chart })
    this.props.changeSubPartHandler(parts, slug, e)
  }
  render() {
    return (
      <>
        {!this.props.isViewBodyChart && <BodyChart
        pathConfig={this.state.paths}
        setPaths={this.setPaths}
        slug={this.state.slug}
        body_chart={this.props.body_chart}
        handleBodyChart={this.handleBodyChart}
        removeChart ={this.removeChart}
        removePart={this.removePart}
        changeHandler={this.changeHandler}
        changeSubPartHandler={this.changeSubPartHandler}
        />}
        {this.props.isViewBodyChart && <ViewBodyChart
        pathConfig={this.state.paths}
        body_chart={this.props.body_chart}
        />}
      </>
    );
  }
}

export default connect(null, null)(FemaleBack);