import React from 'react';
import AddCptBlock from '../../../AddCpt';
import AddIcdBlock from '../../../AddIcd';
import * as ShowCptIcd from '../../../ShowCptIcd';
import { isEmpty } from 'lodash'

export const IcdCpt = (args) => {
    return (
        <ul className="prescription-patient-details medicine-details">
            <div className="content-wrapper">
                <ul className="doctor-tests">
                    {!isEmpty(args.cpt_data) && args.cpt_data.map((cpt, index) => (
                        <ShowCptIcd.ShowCpt
                            cpt={cpt}
                            idx={index}
                            handleRemoveCptData={args.handleRemoveCptData}
                            key={index}
                        />))}
                    {!isEmpty(args.icd_data) && args.icd_data.map((icd, index) => (
                        <ShowCptIcd.ShowIcd
                            icd={icd}
                            idx={index}
                            handleRemoveIcdData={args.handleRemoveIcdData}
                            key={index}
                        />))}
                </ul>
            </div>
            <a href className="add-new btn btn-primary" onClick={args.handleCptBlock}>Add CPT</a>
            <AddCptBlock
                show={args.cpt_block_show}
                handleCptBlock={args.handleCptBlock}
                handleCptData={args.handleCptData}
            />
            <a href className="add-new btn btn-primary" onClick={args.handleIcdBlock}>Add ICD</a>
            <AddIcdBlock
                show={args.icd_block_show}
                handleIcdBlock={args.handleIcdBlock}
                handleIcdData={args.handleIcdData}
            />
        </ul>)
}
export const AssessmentRemark = ({ remark, handleChange }) => {
    return <li>
        <span className="section-heading">Assessment Remark:</span>
        <p>
            <textarea className="form-control" name="remark" onChange={handleChange} value={remark ? remark : ''}></textarea>
        </p></li>
}