import * as types from "./actionTypes";
import DoctorService from "../services/doctorService";
import Notification from '../utils/notification';

export function patientList({ page, sizePerPage, sortBy, searchText, filter}) {
    return dispatch => {
        dispatch({
          type: types.PATIENT_LIST_REQUEST,
          request: { page, sizePerPage, sortBy, searchText}
        });

        DoctorService.patientList({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.PATIENT_LIST_SUCCESS, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: types.PATIENT_LIST_FAILED, error: error.response})
                    let errorData = error.response.data;
                    Notification.show('error', errorData);
                }
            });
    }
}