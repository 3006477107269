import AppointmentService from '../services/appointmentService';
import Notification from '../utils/notification';

export function create(queueData, history, redirect = "") {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });

        AppointmentService.create({ data: queueData })
            .then(resp => {
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                Notification.show("success", { message: 'The patient added to the queue successfully.' })

                if (redirect === "covid-center") {
                    history.push("/patient-queue");
                } else {
                    history.push("/doctor-queues");
                }
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}

export function deleteAppointment(patient_id) {
    return dispatch => {
        AppointmentService.deleteAppointment(patient_id).then(resp => {
            console.info(resp.data)
        }).catch(error => {
            if (error.response && error.response.status) {
                console.error(error.response)
            }
        });
    }
}