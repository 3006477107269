import React from 'react';

class PharmacistInfo extends React.Component {
    render() {
        const { pharmacist_info,pharmacy_info } = this.props;
        
        return (
            <React.Fragment>
                <div className="content-wrapper">
                    <h6>Pharmacist Info</h6>
                    <ul className="prescription-patient-details">
                        <li>Pharmacist Name: <span>{(pharmacist_info) ? pharmacist_info.full_name : ''}</span></li>
                        <li>Pharmacist Registration Number: <span>{(pharmacy_info) ? pharmacy_info.license_number : ''}</span></li>
                        <li>Pharmacy Contact: <span>{(pharmacy_info) ? pharmacy_info.contact_number : ''}</span></li>
                    </ul>
                </div>
            </React.Fragment>
        )
    }
}

export default PharmacistInfo;
