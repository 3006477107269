import React from 'react';
import { TOLL_FREE_NO } from '../config';

class Footer extends React.Component {
    render() {
        return (
            <React.Fragment>
                <footer className="clearfix no-print inner-footer">
                    <div className="content-wrapper">
                        <p><small>Copyright 2019-{new Date().getFullYear()} Daisy-Health. All Rights Reserved. 
                        <a href={`${process.env.PUBLIC_URL}/privacy.html`} className="text-secondary" target="_blank" rel="noopener noreferrer"> Privacy Policy</a> and 
                        <a href={`${process.env.PUBLIC_URL}/terms.html`} className="text-secondary" target="_blank" rel="noopener noreferrer"> Terms & Conditions</a>.</small>
                            <small> Toll Free No. <span className="text-secondary">{TOLL_FREE_NO}</span></small></p>
                    </div>
                </footer>
            </React.Fragment>
        );
    }
}

export default Footer;
