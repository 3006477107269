import React from 'react';
import PathoRadioService from '../services/pathoRadioService';
import Notification from '../utils/notification';

class UploadedFileSpan extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            status: 'process',
            class: 'upload inprogress',
            href: '#',
            name: '',
        }
        this.props.updateFileStatus(this.state.status);
    }

    componentWillMount() {
        if (this.props.service === 'radiology') { this.submitHnadler(); }
        else if (this.props.service === 'pathology') { this.handleSubmit(); }

    }

    submitHnadler = () => {
        const file = this.props.file;
        const data = this.props.data;
        const { order_id } = this.props;

        this.setState({
            name: file.name
        })

        const fd = new FormData();
        fd.append('file', file);

        PathoRadioService.fileUploadForRadiology(fd, order_id, data.order_item_id).then(resp => {
            this.setState({
                class: 'upload completed',
                status: 'success',
                href: resp.data.file_url,
                document_id: resp.data.document_id,
                name: file.name
            })
            this.props.updateFileStatus(this.state.status);
        }).catch(error => {
            if (error.response.status === 422) {
                Notification.show('error', { 'message': 'The specified file ' + this.props.file.name + ' could not be uploaded. Only files with extensions jpg, png, mp4, pdf are alloweded to upload upto 100 MB' })
                this.setState({
                    class: 'upload completed',
                    status: 'invalid',
                })
            }
            else {
                this.setState({
                    class: 'upload completed',
                    status: 'failed',
                })
            }
            this.props.updateFileStatus(this.state.status);
            this.props.deleteHandler(this.props.index);
        })
    }

    handleSubmit = () => {
        const file = this.props.file;
        const data = this.props.data;
        const { order_id } = this.props;

        this.setState({
            name: file.name
        })

        const fd = new FormData();
        fd.append('file', file);
        PathoRadioService.fileUploadForPathology(fd, order_id, data.order_item_id).then(resp => {
            this.setState({
                class: 'upload completed',
                status: 'success',
                href: resp.data.file_url,
                document_id: resp.data.document_id,
                name: file.name
            })
            this.props.updateFileStatus(this.state.status);
        }).catch(error => {
            if (error.response.status === 422) {
                Notification.show('error', { 'message': 'The specified file ' + this.props.file.name + ' could not be uploaded. Only files with extensions jpg, png, mp4, pdf are alloweded to upload upto 35 MB' })
                this.setState({
                    class: 'upload completed',
                    status: 'invalid',
                })
            }
            else {
                this.setState({
                    class: 'upload completed',
                    status: 'failed',
                })
            }
            this.props.updateFileStatus(this.state.status);
            this.props.deleteHandler(this.props.index);
        })
    }

    deleteHandler = (e) => {
        const document_id = e.target.name;
        if (!document_id) {
            this.props.deleteHandler(this.props.index);
            return false;
        }
        const order_item_id = this.props.data.order_item_id;
        if (this.props.service === 'radiology') {
            const order_id = this.props.data.order_radiology_tests_id;
            PathoRadioService.fileDeleteForRadiology(order_id, order_item_id, document_id).then(resp => {
                this.props.deleteHandler(this.props.index);
            }).catch(error => {
                Notification.show('error', error.response.data)
            })
        }
        else if (this.props.service === 'pathology') {
            const order_id = this.props.data.order_pathology_tests_id;
            PathoRadioService.fileDeleteForPathology(order_id, order_item_id, document_id).then(resp => {
                this.props.deleteHandler(this.props.index);
            }).catch(error => {
                Notification.show('error', error.response.data)
            })
        }

    }

    render() {
        return (
            <span className={this.state.class} key={this.props.index}>
                {this.state.status === 'success' && <a href={this.state.href} target="_blank" rel="noopener noreferrer" download>{this.props.file.name}</a>}
                {this.state.status !== 'success' && <label>{this.props.file.name}</label>}
                <button className="close" hidden={this.state.status !== 'process' ? false : true} onClick={(e) => { if (window.confirm('Delete the item?')) { this.deleteHandler(e) }; }} value={this.state.document_id} name={this.state.document_id}></button>
                <div id="loading-bar-spinner" className="spinner" hidden={this.state.status === 'process' ? false : true}>
                    <div className="spinner-icon" ></div>
                </div>
                <div className="text-danger" hidden={this.state.status === 'failed' ? false : true} onClick={this.submitHnadler}>Retry</div>
            </span>

        )
    }
}
export default UploadedFileSpan;