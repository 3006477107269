import React from 'react';
import { capitalize } from 'lodash';
import { getDateFormat, getAgeString, fullAddressStr } from '../utils/helpers';

class PatientInfo extends React.Component {
    render() {
        const { patient_info, orderDate } = this.props;
        return (
            patient_info ? <React.Fragment>
                <div className="content-wrapper">
                    <h6>Patient Info</h6>
                    <ul className="prescription-patient-details">
                        <li>Name: <span>{patient_info && patient_info.full_name}</span></li>
                        <li>Date of Birth: <span>{patient_info.date_of_birth && getDateFormat(patient_info.date_of_birth)} ({getAgeString(patient_info.date_of_birth, orderDate)})</span></li>
                        <li>Gender: <span>{patient_info && capitalize(patient_info.gender)}</span></li>
                        <li>Contact: <span>{patient_info && patient_info.contact_number ? patient_info.contact_number : '--'}</span></li>
                    </ul>
                    <ul className="prescription-patient-details">
                        <li>Address: <span>{patient_info && fullAddressStr(patient_info.address)}</span></li>
                    </ul>
                </div>
            </React.Fragment> : <></>
        )
    }
}

export default PatientInfo;