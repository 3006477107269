import React from 'react';
import SimpleReactValidator from 'simple-react-validator';
import CovidCenterService from '../../services/covidCenterService';
import Notification from '../../utils/notification';
import moment from 'moment';

class CovidTestDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collected_at: '',
            received_at: '',
            questionnaire: {},
            test_name: '',
            test_reason: ''
        }
        this.validator = new SimpleReactValidator();
    }

    componentDidMount() {
        if (this.props.patientLastCovidDetail.id) {
            let patientLastCovidDetail = this.props.patientLastCovidDetail;
            let questionnaire = JSON.parse(patientLastCovidDetail.questionnaire)
            let key = patientLastCovidDetail.test_reason == "travel" ? "destination" : (patientLastCovidDetail.test_reason == "symphomatic" ? "symptoms" : "others")
            let value = questionnaire[patientLastCovidDetail.test_reason].ans;
            this.setState({ email: patientLastCovidDetail.email, test_reason: patientLastCovidDetail.test_reason, [key]: value })
        }
    }

    onSubmit = (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        if (!this.props.paymentdetails) {
            Notification.show('error', { message: 'Kindly fill payment details first.' });
            return false;
        }

        console.log("Props::", this.props.paymentdetails);
        let test_name = JSON.parse(this.props.paymentdetails.test_type).name;
        let questionnaire = {};
        if (this.state.test_reason === "travel") {
            questionnaire = { [this.state.test_reason]: { 'que': 'Destination', 'ans': this.state.destination ? this.state.destination : "" } };
        } else if (this.state.test_reason === "symphomatic") {
            questionnaire = { [this.state.test_reason]: { 'que': 'Symptoms', 'ans': this.state.symptoms ? this.state.symptoms : "" } };
        } else {
            questionnaire = { [this.state.test_reason]: { 'que': 'Others', 'ans': this.state.others ? this.state.others : "" } };
        }
        const postData = {
            test_name: test_name,
            collected_at: this.state.collected_at + " " + this.state.collected_at_time + ":00",
            received_at: this.state.received_at + " " + this.state.received_at_time + ":00",
            covid_fee_collections_id: this.props.paymentdetails.covid_fee_collections_id,
            patient_id: this.props.patient.patient_id,
            patient_name: this.props.patient.full_name,
            questionnaire: questionnaire,
            email: this.state.email,
            test_reason: this.state.test_reason,
        }

        this.props.loaderShow()
        CovidCenterService.storeCovidReport(postData).then(resp => {
            this.props.loaderHide();
            this.props.history.push('/covid-centers/pending-reports')
        }).catch(err => {
            this.props.loaderHide();
            Notification.show('error', err.response.data);
        })
    }
    onChange = (e) => this.setState({ [e.target.name]: e.target.value });

    isDisabled = () => {
        let { payment_status } = this.props
        return payment_status === "unpaid"
    }

    handleQuestions = (e, que) => {
        let { questionnaire } = this.state;
        const target = e.target
        if (target.name in questionnaire) {
            questionnaire[target.name]['ans'] = target.value
        } else {
            questionnaire[target.name] = {
                'que': que,
                'ans': target.value
            }
        }
        this.setState({ questionnaire })
    }

    render() {
        let { questionnaire } = this.state;
        this.validator.purgeFields()

        return <div className="row">
            <div className="col-md-24">
                <h4 className="page-title">Covid Test Details</h4>
                <div className="content-wrapper edit-patient">
                    <form className="mt-2">

                        <div className="form-group row">
                            <label htmlFor="email" className="col-sm-6 col-form-label required-field">Enter email Address to receive report </label>
                            <div className="col-sm-8">
                                <input type="text" id="email" className="form-control sm-4" name="email" value={this.state.email} disabled={this.isDisabled()} onChange={this.onChange} />
                                {this.validator.message('email', this.state.email, 'required')}
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-6 col-form-label required-field" htmlFor='test_reason'>Why are you getting tested?</label>
                            <div className="col-sm-8">
                                <select className="form-control sm-4" onChange={this.onChange} value={this.state.test_reason} disabled={this.isDisabled()} name="test_reason" id="test_reason">
                                    <option value="">Select Test</option>
                                    <option value="travel">Travel</option>
                                    <option value="symphomatic">Symphomatic</option>
                                    <option value="possible-exposure">Possible Exposure</option>
                                    <option value="quarantine-exit">Quarantine Exit</option>
                                    <option value="other">Other</option>
                                </select>
                                {this.validator.message('test result', this.state.test_reason, 'required')}
                            </div>
                        </div>

                        {(this.state.test_reason && this.state.test_reason === "travel") && <div className="form-group row">
                            <label htmlFor="destination" className="col-sm-6 col-form-label">Enter Destination</label>
                            <div className="col-sm-8">
                                <input type="text" className="form-control" id="destination" value={this.state.destination} name="destination" onChange={this.onChange} disabled={this.isDisabled()} />
                            </div>
                        </div>
                        }

                        {this.state.test_reason === "symphomatic" && <div className="form-group row">
                            <label htmlFor="symptoms" className="col-sm-6 col-form-label">Enter Symptoms</label>
                            <div className="col-sm-8">
                                <textarea className="form-control" id="symptoms" value={this.state.symptoms} name="symptoms" onChange={this.onChange} disabled={this.isDisabled()} />
                            </div>
                        </div>}

                        {(this.state.test_reason && !['travel', 'symphomatic'].includes(this.state.test_reason)) && <div className="form-group row">
                            <label htmlFor="others" className="col-sm-6 col-form-label">Others</label>
                            <div className="col-sm-8">
                                <textarea className="form-control" id="others" value={this.state.others} name="others" onChange={this.onChange} disabled={this.isDisabled()} />
                            </div>
                        </div>
                        }

                        <div className="form-group row">
                            <label htmlFor="collected_at" className="col-sm-6 col-form-label required-field">Date Collected</label>
                            <div className="col-sm-8">
                                <input type="date" className="form-control mr-sm-3 col-sm-10 float-left" name="collected_at" onChange={this.onChange} disabled={this.isDisabled()} max={moment().format('YYYY-MM-DD')} />
                                <input type="time" className="form-control mx-sm-3 col-sm-10" name="collected_at_time" onChange={this.onChange} disabled={this.isDisabled()} />
                                {this.validator.message('collected_date', this.state.collected_at, 'required')}
                                {this.validator.message('collected_time', this.state.collected_at_time, 'required')}
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="received_at" className="col-sm-6 col-form-label required-field">Date Received</label>
                            <div className="col-sm-8">
                                <input type="date" className="form-control mr-sm-3 col-sm-10 float-left" name="received_at" onChange={this.onChange} max={moment().format('YYYY-MM-DD')} disabled={this.isDisabled()} />
                                <input type="time" className="form-control mx-sm-3 col-sm-10" name="received_at_time" onChange={this.onChange} disabled={this.isDisabled()} />
                                {this.validator.message('received_date', this.state.received_at, 'required')}
                                {this.validator.message('received_time', this.state.received_at_time, 'required')}
                            </div>
                        </div>

                        <div className="float-right">
                            <button type="submit" className="btn btn-primary" onClick={this.onSubmit} disabled={this.isDisabled()} >Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    }
}
export default CovidTestDetails;