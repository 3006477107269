import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function (state = initialState.pagination, { type, ...payload }) {
    switch (type) {
        case types.MEDICAL_CERTIFICATE_LIST_RESET:
            return {
                ...initialState.pagination
            };
        case types.MEDICAL_CERTIFICATE_LIST_REQUEST:
            return {
                ...state,
                ...payload
            };
        case types.MEDICAL_CERTIFICATE_LIST_SUCCESS:
            return {
                ...state,
                data: payload.data,
                meta: payload.meta
            };
        case types.MEDICAL_CERTIFICATE_LIST_FAILED:
            return {
                ...state,
                data: [],
                ...payload
            }; 
        default:
            return state;
    }
}