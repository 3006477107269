import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { getDosageType, getDurationType } from '../../../../utils/helpers';
import Notification from '../../../../utils/notification';
import { capitalize } from 'lodash';
import UrgentCareService from '../../../../services/urgentCareService';
import SimpleReactValidator from 'simple-react-validator';

class AddRecommendation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            prescriptionId: this.props.prescription_id,
            medicine: {},
            show: false,
            isSubmitDisabled: true,
            administer_note: ""
        }
        this.validator = new SimpleReactValidator();
    }

    componentDidMount() {
    }

    addNote = (medicine) => {
        this.setState({ show: true, medicine: medicine, administer_note: "", isSubmitDisabled: true });
    }

    handleClose = () => {
        this.setState({ show: false });
    }

    noteHandler = (e) => {
        e.target.value ? this.setState({ administer_note: e.target.value, isSubmitDisabled: false }) : this.setState({ administer_note: e.target.value, isSubmitDisabled: true });
    }

    handleSubmit = () => {
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        let postData = {
            'administrator_note': this.state.administer_note,
        }
        this.setState({ isSubmitDisabled: true });
        UrgentCareService.addMadicineAdministerNote(this.state.prescriptionId, this.state.medicine.id, postData).then(resp => {
            this.setState({ show: false });
            Notification.show('success', { message: "Success" })
            this.props.reloadPrescriptionMedicine();
        }).catch(error => {
            this.setState({ isSubmitDisabled: false });
            console.log(error);
            Notification.show('error', error.response.data)
        })
    }
    render() {

        let medicine = this.props.medicine;
        let type = this.props.type;
        return (
            <React.Fragment>
                {medicine && <table className="table table table-responsive common-table">
                    <thead>
                        <tr>
                            <th>Brand Name</th>
                            <th>Generic Name</th>
                            <th>Frequency</th>
                            <th>Meal</th>
                            <th>Duration</th>
                            <th>Dosage</th>
                            <th>Substitution Not Allowed</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td valign="top">{medicine.drug_name}</td>
                            <td valign="top">{medicine.drug_generic_name}</td>
                            <td valign="top">{medicine.dosage ? getDosageType(medicine.dosage) : '--'}</td>
                            <td valign="top">{medicine.meal ? capitalize(medicine.meal) : '--'}</td>
                            <td valign="top">{medicine.duration ? medicine.duration : '--'}
                                {medicine.duration_type ? getDurationType(medicine.duration_type) : '--'}</td>
                            <td valign="top">{medicine.quantity ? medicine.quantity : '--'}</td>
                            <td valign="top">{medicine.substitution_not_allowed ? "Yes" : "No"} </td>
                            {<td valign="top">
                                <button type="button" className="btn btn-outline-primary btn-small float-right" onClick={e => this.addNote(medicine)}>Administer</button>
                            </td>}
                        </tr>
                        <tr>
                            <td colSpan="8" className="text-left">
                                <span className="text-primary">Instructions: </span><span>{medicine.remarks ? medicine.remarks : "--"}</span>
                            </td>
                        </tr>
                        {type === 'current_medicine' && <tr>
                            <td colSpan="7" className="text-left">
                                <span className="text-primary">Side effects/Complaint of medicaion: </span><span>{medicine.side_effects ? medicine.side_effects : "--"}</span>
                            </td>
                        </tr>}
                    </tbody>
                </table>}

                <Modal
                    size='md' centered aria-labelledby="contained-modal-title-vcenter"
                    show={this.state.show}
                    onHide={this.handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <h6>Administrator Note</h6>
                    </Modal.Header>
                    <Modal.Body>
                        You are adding note for : <b> {this.state.medicine.drug_name}</b>
                        <textarea name="administrator_note" className="form-control" onChange={this.noteHandler} value={this.state.administer_note} />
                        {this.validator.message('administrator_note', this.state.administer_note, 'required')}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" disabled={this.state.isSubmitDisabled} onClick={e => this.handleSubmit()} >
                            Submit
                        </Button>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>

            </React.Fragment>
        )
    }
}
export default AddRecommendation