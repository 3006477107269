import React from "react";
import { connect } from "react-redux";
import PrescriptionService from '../services/prescriptionService';
import * as HELPER from "../utils/helpers";
import SimpleReactValidator from 'simple-react-validator';

class Concern extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prescriptionId: '',
      prescription_concerns: '',
      message: '',
      isloading: false,
    }

    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    this.setState({
      prescriptionId: this.props.prescriptionId
    });
    this.getPrescriptionConcerns(this.props.prescriptionId);
  }

  getPrescriptionConcerns = prescriptionId => {
    let from_profile_id = null;
    if (this.props.selected_profile.role_slug === 'doctor') {
      from_profile_id = this.props.toProfileId;
    }

    PrescriptionService.getPrescriptionConcerns(prescriptionId, this.props.selected_profile.role_slug, from_profile_id)
      .then(resp => {
        this.setState({
          prescription_concerns: resp.data.concerns
        });
      })
      .catch(error => {
        return error;
      });
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return false;
    }

    let postData = {
      'prescription_id': this.props.prescriptionId,
      'to_service_provider_id': this.props.toServiceProviderId,
      'to_profile_id': this.props.toProfileId,
      'message': this.state.message,
    }
    await PrescriptionService.prescriptionConcern(postData);
    this.setState({
      message: ''
    });
    this.getPrescriptionConcerns(this.props.prescriptionId);

  }

  render() {
    const { user_profile_id } = this.props.selected_profile;
    const { prescription_concerns } = this.state;

    return (
      <React.Fragment>
        {this.state.isloading && this.state.isloading && <div className="loading">Loading</div>}
        <ul className="prescription-accordion">
          <li className="accordion-item">
            <h3 className="accordion-thumb">Raise a concern on prescription</h3>
            <div className="chat-wrapper">
              <div className="chat-container">
                {prescription_concerns && prescription_concerns.map((concern) => {
                  return <div className={concern.from_profile_id === user_profile_id ? 'receiver clearfix' : 'sender clearfix'}>
                    <div className="msg">
                      <div className="msg-content">
                        {concern.message.message}
                      </div>
                      <div className="current-date-time">{HELPER.getDateTimeFormat(concern.created_at)}</div>
                    </div>
                  </div>
                })}

              </div>
            </div>
            <p className="accordion-panel">
              <form>
                <textarea
                  className="form-control"
                  name="message"
                  value={this.state.message}
                  placeholder="Type your message"
                  onChange={this.handleChange.bind(this)}
                ></textarea>
                {this.validator.message('message', this.state.message, 'required|max:500')}
                <button onClick={this.handleSubmit} className="send-buttonn icon send-button"></button>
              </form>
            </p>
          </li>
        </ul>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  selected_profile: state.selected_user_profile
});

export default connect(mapStateToProps, null)(Concern);
