import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import * as PrescriptionAction from '../../actions/prescriptionAction';
import PrescriptionOrderService from '../../services/prescriptionOrderService';
import { Helmet } from 'react-helmet';
import MedicineOrderListBlock from '../../components/MedicineOrderListBlock';
import Alert from '../../components/Alert';
import { hasRole } from '../../utils/auth';
import CardSwipe from 'react-card-swipe';
import * as Helper from "../../utils/helpers";
import SearchPatient from "../../components/SearchPatient";
import DataGrid from "../../components/DataGrid";
import Notification from '../../utils/notification';

const AddPrescriptionButton = ({ patientId }) => {
    return <Link className="btn btn-outline-primary ml-2 float-right" to={"/pharmacy/patients/" + patientId + "/prescriptions"}>
        Add Prescription
      </Link>
}

class SearchPrescriptionPage extends React.Component {
    columns = () => {
        return [{
            dataField: 'id',
            text: 'ID',
            hidden: true
        }, {
            dataField: 'prescription_id',
            text: 'Prescription ID',
            formatter: this.prescriptionFormatter
        }, {
            dataField: 'patient_full_name',
            text: 'Patient Name',
            //sort: true
        }, {
            dataField: 'doctor_full_name',
            text: 'Doctor Name'
        }, {
            dataField: 'prescription_date',
            text: 'Prescription Date',
            sort: true,
            formatter: this.dateFormatter
        }, {
            formatter: this.statusFormatter,
            text: 'Status',
        }, {
            dataField: 'actions',
            text: 'Actions',
            isDummyField: true,
            formatter: this.actionFormatter
        }
        ];
    };

    dateFormatter = (cell, row) => {
        // return Helper.getDateTimeFormat(cell);
        return Helper.getDateFormat(cell);
    };

    statusFormatter = (cell, row) => {
        if (row.medicine_status) {
            return Helper.getStatus(row.medicine_status)
        }
    }
    prescriptionFormatter = (cell, row) => {
        if (row.prescription_id == null) {
            return '-'
        }
        return row.prescription_id
    }
    constructor(props) {
        super(props);

        this.state = {
            card_number: '',
            patient_code: '',
            access_code: '',
            orders: '',
            patientId: '',
            show: true
        };

        this.props.resetPerscription();
        this.props.resetList();

        this.searchByCardBtn = React.createRef();

        CardSwipe.init({
            success: this.handleSwipeSuccess,
            debug: false
        });
    }

    handleSwipeSuccess = (swipeData) => {
        this.setState({
            card_number: swipeData.line2
        });

        this.searchByCardBtn.current.click();
    }

    handleHideBlock = () => this.setState({ show: !this.state.show })

    handlePatientChange = (patient_id) => {
        this.setState({ patientId: patient_id });

        !this.state.show && this.handleHideBlock()

        this.props.searchPrescription({
            sortBy: { sortField: "updated_at", sortOrder: "desc" },
            filter: { patient: patient_id, medicine_status: 'prescribed,partially-fulfilled' }
        }, patient_id);

        this.handleHideBlock()

        if (hasRole(this.props.user, ['pharmacist'])) {
            PrescriptionOrderService.patientOrderList(patient_id).then(resp => {
                this.setState({
                    orders: resp.data.data
                })
            }).catch(error => {
                Notification.show('error', error.response.data)
            })
        }

    }

    actionFormatter = (_cell, row) => {
        let { user } = this.props;
        if (hasRole(user, ['pharmacist'])) {

            return (<React.Fragment>
                <Link to={"/pharmacy/prescription/" + row.prescription_id + "/view"} title="View Prescription"><span className="icon file"></span></Link>
            </React.Fragment>);

        }
        if (hasRole(user, ['pharmacy-technician'])) {
            return row.is_locked ? <span className="btn btn-default">Locked</span> : (<React.Fragment>
                <Link to={"/technician/prescriptions/" + row.prescription_id + "/orders/create"} className="btn btn-default" title="Create Rx">Create Rx</Link>
            </React.Fragment>);
        }

    }
    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        if (type === "search") {
            page = 1;
        }
        if (!sortField) {
            sortField = 'updated_at';
            sortOrder = 'desc';
        }
        this.props.searchPrescription({ page, sizePerPage, sortBy: { sortField, sortOrder }, filter: { patient: this.state.patientId, medicine_status: 'prescribed,partially-fulfilled' } }, this.state.patientId);
    };

    render() {
        let { prescriptions } = this.props;
        const { orders } = this.state;
        return <div>
            <Helmet>
                <title>Search Patient Prescription</title>
            </Helmet>

            <div className="row">
                <div className="col-md-24">
                    <h4 className="page-title">Search Patient Prescription</h4>
                    <div className="content-wrapper">
                        <div className="row">
                            <SearchPatient
                                handlePatientSearch={this.handlePatientChange}
                                history={this.props.history}
                                show={this.state.show}
                            ></SearchPatient>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <Alert className="col-md-24" />
                {this.state.patientId && <div className="col-md-24">
                    <h4 className="page-title">
                        <span>Search Result</span>
                        {hasRole(this.props.user, ["pharmacist"]) && <AddPrescriptionButton patientId={this.state.patientId} />}
                    </h4>
                    <div className="content-wrapper">
                        {<DataGrid
                            columns={this.columns()}
                            loading={this.state.isLoading}
                            noDataIndication="No Record Found"
                            data={prescriptions.data}
                            meta={prescriptions.meta}
                            handleTableChange={this.handleTableChange}
                        />}
                    </div>
                </div>}
                {
                    orders.length > 0 && <div className="col-md-24">
                        <h4 className="page-title">Rx Order</h4>
                        <div className="content-wrapper">
                            <MedicineOrderListBlock orders={this.state.orders} />
                        </div>
                    </div>
                }
            </div>

        </div>
    }
}

const mapStateToProps = (state) => ({
    prescriptions: state.prescription.paginate,
    user: state.selected_user_profile
});

const mapActionsToProps = {
    paginate: PrescriptionAction.list,
    resetList: PrescriptionAction.resetList,
    resetPerscription: PrescriptionAction.reset,
    searchPrescription: PrescriptionAction.getPharmacyPrescriptions
};

export default connect(mapStateToProps, mapActionsToProps)(SearchPrescriptionPage);
