import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function (state = initialState.urgent_care, { type, prescription_type = null, ...payload }) {
    switch (type) {
        case types.LIST_URGENT_CARE:
            return {
                ...state,
                paginate: {
                    ...state.urgent_care,
                    prescription_type: prescription_type,
                    ...payload,
                    action: 'list'
                }
            };
        case types.CLEAR_LIST_URGENT_CARE:
            return {
                ...state,
                paginate: {
                    filter: {},
                    data: [],
                    meta: {
                        current_page: 0,
                        per_page: 0,
                        total: 0,
                    }
                }
            };
        default:
            return state;
    }
}