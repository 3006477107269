import React from 'react';
import BarGraph from '../Graph/MultiBarGraph';

class CovidTestGraph extends React.Component {

    handleCovidTestView = (view) => {
        this.props.renderDashboardGraph(view)
    }

    render() {
        return (
            <BarGraph
                yScaleLabel="Test Count"
                datasetLabels={this.props.covid_test_graph_data.x_axis_label}
                datasetCounts={this.props.covid_test_graph_data.counts}
                handleViewChange={this.handleCovidTestView}
            />
        )
    }
}
export default CovidTestGraph