import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class DataManagentService {
    static list({ page, sizePerPage, sortBy, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, filter })
        };

        return axios.get(apiRoute('/v1/data-management/covid-tests'), requestOptions);
    }

    static create({ data }) {
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.post(apiRoute('/v1/data-management/covid-tests'), data, requestOptions);
    }

    static serviceProviderLocations(service_provider_id) {
        const requestOptions = {
            headers: getApiHeader(false, true),
        };
        return axios.get(apiRoute('/v1/locations?service_provider_id=' + service_provider_id), requestOptions);
    }

    static update({ data }) {
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.put(apiRoute("/v1/data-management/covid-tests/" + data.id), data, requestOptions);
    }

    static updateTestStatus(id) {
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.patch(apiRoute("/v1/data-management/covid-tests/" + id + "/status"), {}, requestOptions);
    }

    static sendReverifyEmail(email) {
        const requestOptions = {
             headers: getApiHeader(),
        };

        const body = JSON.stringify({
            email: email
        });
        return axios.post(apiRoute('/v1/re-verify'), body, requestOptions);
    }

    static userList() {
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.get(apiRoute('/v1/user-management/users-list'), requestOptions);
    }
}
export default DataManagentService;