import React from 'react';
import * as FamilyHistoryComponents from './AddFamilyHistoryComponent';
import { pullAllBy, find, pull, isEmpty } from 'lodash';

class FamilyHistory extends React.Component {
    handleFamilyHistoryChange = (e) => {
        let { family_history } = this.props;
        if (e.target.name === "is_adopted") {
            family_history[e.target.name] = e.target.value === "" ? '' : JSON.parse(e.target.value);
        } else {
            family_history[e.target.name] = e.target.value;
        }
        this.props.handleFamilyHistory(family_history);
    }
    handleFamilyCondition = (e, member, disease) => {
        let { family_history } = this.props;
        if (e.target.name === "relation_conditions") {
            let relation_conditions = family_history['relation_conditions'] ? family_history['relation_conditions'] : [];
            let existingData = find(relation_conditions, { 'illness_type': (disease) });
            if (e.target.checked) {
                if (!existingData) {
                    relation_conditions.push({ 'illness_type': (disease), 'family_relation': [member] })
                } else if (existingData && !existingData['family_relation'].includes(member) && member !== 'none') {
                    existingData['family_relation'].push(member)
                } else if (member === 'none') {
                    pullAllBy(family_history['relation_conditions'], [{ 'illness_type': (disease) }], 'illness_type');
                    relation_conditions.push({ 'illness_type': (disease), 'family_relation': [member] })
                }
            } else {
                if (existingData && existingData['family_relation'].includes(member)) {
                    pull(existingData['family_relation'], member);
                    if (isEmpty(existingData['family_relation'])) {
                        pullAllBy(family_history['relation_conditions'], [{ 'illness_type': (disease) }], 'illness_type');
                    }
                }
            }
            family_history["relation_conditions"] = relation_conditions;
            this.props.handleFamilyHistory(family_history);
        }
    }

    handleRemarkChange = (e, disease) => {
        let { family_history } = this.props;
        let relation_conditions = family_history['relation_conditions'] ? family_history['relation_conditions'] : [];
        let existingData = find(relation_conditions, { 'illness_type': (disease) });
        if (existingData) {
            existingData['remark'] = e.target.value
        }
        family_history["relation_conditions"] = relation_conditions;
        this.props.handleFamilyHistory(family_history);
    }

    disableOtherOptions = (disease) => {
        let { family_history } = this.props;
        let relation_conditions = family_history['relation_conditions'] ? family_history['relation_conditions'] : [];
        let existingData = find(relation_conditions, { 'illness_type': (disease) });
        return existingData && existingData['family_relation'].includes('none');
    }
    render() {
        return (
            <React.Fragment>
                <div className="examination-wrapper clearfix">
                    <div className="form-group form-left">
                        <FamilyHistoryComponents.Adopted
                            handleChange={this.handleFamilyHistoryChange}
                            family_history={this.props.family_history}
                            getValue={this.props.getValue}
                        />
                    </div>
                    <div className="form-group form-left">
                        <FamilyHistoryComponents.Father
                            handleChange={this.handleFamilyHistoryChange}
                            family_history={this.props.family_history}
                        />
                    </div>
                    <div className="form-group form-left">
                        <FamilyHistoryComponents.Mother
                            handleChange={this.handleFamilyHistoryChange}
                            family_history={this.props.family_history}
                        />
                    </div>
                </div>
                <div className="examination-wrapper clearfix">
                    <FamilyHistoryComponents.FamilyCondition
                        handleChange={this.handleFamilyCondition}
                        family_history={this.props.family_history}
                        disableOtherOptions={this.disableOtherOptions}
                        handleRemarkChange={this.handleRemarkChange}
                    />
                </div>
            </React.Fragment>
        )
    }
}
export default FamilyHistory