import React from 'react';
import ImmunizationService from '../../services/immunizationService';
import Notification from '../../utils/notification';
import * as LoaderAction from '../../actions/loaderAction';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { immunizationsData, otherImmunization } from '../../config/immunization';
import _ from 'lodash'
import { Link } from "react-router-dom";
import { getDateFormat } from '../../utils/helpers';
import { Tab, Tabs } from "react-bootstrap";

class ViewPatientImmunizationPage extends React.Component {
    constructor(props) {
        super(props);
        let { patientId } = this.props.match.params
        this.state = {
            patient_id: patientId,
            fetched_data: '',
            selected_age: [],
            items: [],
            immunization_id: '',
            key: 'child-vaccine',
            child_vaccines: _.cloneDeep(immunizationsData),
            other_vaccines: _.cloneDeep(otherImmunization),
        }
    }

    componentDidMount() {
        this.requestList('child-vaccine');
    }

    handleCheckedVaccineItems = (selected_age, data, vaccineData) => {
        let result = _.find(vaccineData, (vaccine) => { return vaccine.age_slug === selected_age });
        let other_vaccines = _.filter(data, (item) => { return item.age_slug === selected_age && item.is_other_vaccine && item.status === "approved" });
        let vaccines = [...result.vaccines];
        let items = this.state.items;
        items[selected_age] = vaccines.concat(other_vaccines);
        this.setState({
            selected_age: this.state.selected_age.concat(selected_age),
            items: items,
        })
    }

    renderVaccineBlock = (age_slug, age_label) => {
        return (
            <>
                <h6 class="mt-3">{age_label}</h6>
                <div className="table-responsive">
                    <table className="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th>Type of Vaccine</th>
                                <th>Date of Immunization</th>
                                <th>Manufacturer</th>
                                <th>Batch No./Lot No.</th>
                                <th>Performed By</th>
                                <th>Doctor Name</th>
                                <th>Comment</th>
                                <th>Next date of Immunization</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.items && this.state.items[age_slug] && this.state.items[age_slug].map((vaccine, key) => (this.renderVaccineItem(vaccine, age_slug, age_label, key)
                            ))}
                        </tbody></table>
                </div>
            </>
        )
    }

    renderVaccineItem = (selected_vaccine, age_slug, _age_label, index = null) => {
        let result = _.find(this.state.fetched_data, (item) => {
            return selected_vaccine.vaccine_slug === item.vaccine_slug && item.age_slug === age_slug && selected_vaccine.vaccine_label === item.vaccine_label && item.status === "approved"
        });

        return (
            <tr key={index}>
                {/* vaccine name */}
                <td className="tabledit-view-mode">
                    <span className="tabledit-span">{selected_vaccine.vaccine_label}</span>
                    <div><small>{selected_vaccine.note ? (selected_vaccine.note) : ''}</small></div>
                </td>
                {/* immunizatin date */}
                <td className="tabledit-view-mode">
                    <span className="tabledit-span">{result && getDateFormat(result.immunization_date)}</span>
                </td>
                {/* manufacturer_name */}
                <td className="tabledit-view-mode">
                    <span className="tabledit-span">{result && result.manufacturer_name}</span>
                </td>
                {/* batch_number */}
                <td className="tabledit-view-mode">
                    <span className="tabledit-span">{result && result.batch_number}</span>
                </td>
                {/* approved_type */}
                <td className="tabledit-view-mode">
                    <span className="tabledit-span">{result && this.performedByLabelFormatter(result.approved_type)}</span>
                </td>
                {/* approved_name */}
                <td className="tabledit-view-mode">
                    <span className="tabledit-span">{result && result.approved_name}</span>
                </td>
                {/* approved_comment */}
                <td className="tabledit-view-mode">
                    <span className="tabledit-span">{result && result.approved_comment}</span>
                </td>
                {/* next_immunization_date */}
                <td className="tabledit-view-mode">
                    <span className="tabledit-span">{result && getDateFormat(result.next_immunization_date)}</span>
                </td>
            </tr>
        )
    }

    valueOfInput = (fetchedVaccine, selectedVaccine, inputField) => {
        if (fetchedVaccine && !selectedVaccine[inputField]) {
            selectedVaccine[inputField] = fetchedVaccine[inputField]
        }
        return selectedVaccine[inputField]
    }

    performedByLabelFormatter = (label) => {
        return label === "self" ? "Self" : "Other Doctor"
    }

    download = () => {
        this.props.showLoader()
        ImmunizationService.downloadPdf(this.state.patient_id).then(resp => {
            this.props.hideLoader()
            window.open(resp.data.url)
        }).catch(error => {
            this.props.hideLoader()
            Notification.show('error', error.response.data)
        })
    }

    downloadOtherImmunization = () => {
        this.props.showLoader()
        ImmunizationService.downloadOtherImmunization(this.state.patient_id).then(resp => {
            this.props.hideLoader()
            window.open(resp.data.url)
        }).catch(error => {
            this.props.hideLoader()
            Notification.show('error', error.response.data)
        })
    }

    requestList = key => {
        this.props.showLoader();
        ImmunizationService.getPatientsImmunization(this.state.patient_id, key).then(resp => {
            let age_slugs = [], data = null;
            if (key === 'child-vaccine') {
                age_slugs = Object.keys(_.groupBy(this.state.child_vaccines, 'age_slug'));
                data = this.state.child_vaccines
            } else if (key === 'other-vaccine') {
                age_slugs = Object.keys(_.groupBy(this.state.other_vaccines, 'age_slug'));
                data = this.state.other_vaccines
            }

            this.setState({
                fetched_data: resp.data.data,
                selected_age: age_slugs,
                key: key,
            });
            age_slugs && age_slugs.forEach(age => {
                this.handleCheckedVaccineItems(age, resp.data.data, data)
            })
            this.props.hideLoader();
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data)
        })
    }

    render() {
        return (<React.Fragment>
            <Helmet>
                <title>Patient Immunization</title>
            </Helmet>
            <div className="row">
                <div className="col-sm-24">
                    <h4 className="page-title">Immunization Card
                        <Link to={"/patients/" + this.state.patient_id} className="btn btn-primary ml-2">Back</Link>
                        <button className="btn btn-primary ml-2" onClick={this.downloadOtherImmunization}>Print Other Immunization</button>
                        <Link to={"/patients/" + this.state.patient_id + "/other-immunizations"} className="btn btn-primary ml-2">Create Other Immunization</Link>
                        <button className="btn btn-primary ml-2" onClick={this.download}>Print Child Immunization</button>
                        <Link to={"/patients/" + this.state.patient_id + "/immunizations"} className="btn btn-primary ml-2">Create Child Immunization</Link>
                    </h4>
                </div>
                <div className="col-md-24 mt-3 request-pending">
                    <Tabs
                        defaultActiveKey="child-vaccine"
                        onSelect={this.requestList}
                    >
                        <Tab eventKey="child-vaccine" title="Child Vaccines">
                            {this.state.key === 'child-vaccine' && < div className="content-wrapper add-user-section">
                                {this.state.fetched_data && this.state.child_vaccines.map((data) => (
                                    this.renderVaccineBlock(data.age_slug, data.age_label)
                                ))}
                            </div>}
                        </Tab>
                        <Tab eventKey="other-vaccine" title="Other Vaccine">
                            {this.state.key === 'other-vaccine' && < div className="content-wrapper add-user-section">
                                {this.state.fetched_data && this.state.other_vaccines.map((data) => (
                                    this.renderVaccineBlock(data.age_slug, data.age_label)
                                ))}
                            </div>}
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </React.Fragment >)
    }
}
const mapStateToProps = (state) => ({
    selected_user_profile: state.selected_user_profile
});

const mapActionsToProps = {
    hideLoader: LoaderAction.loaderHide,
    showLoader: LoaderAction.loaderShow,
};
export default connect(mapStateToProps, mapActionsToProps)(ViewPatientImmunizationPage)
