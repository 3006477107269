import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class PendingSignaturePrescriptionService {
    static list({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute('/v1/prescriptions/pending-signature'), requestOptions);
    }

    static getSignaturePendingModifiedPrescription({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute('/v1/modified-prescriptions/pending-signature'), requestOptions);
    }
}


export default PendingSignaturePrescriptionService;