import { includes, isEmpty, find, pull } from 'lodash';
import React from 'react';
import SimpleReactValidator from 'simple-react-validator';
import CovidCenterService from '../../services/covidCenterService';
import Notification from '../../utils/notification';
import { PL_POINT_RATE } from '../../config'

class PaymentDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "remarks": "",
            "payments": [],
            point_value: '',
            status: 'unpaid',
            is_print_check: false,
            total_fee: 0,
            covitTestList: [],
            collectAmount: "",
        }
        this.validator = new SimpleReactValidator();
    }

    componentDidMount() {
        this.getExistingFeeDetails();
        this.getCovidTestList();
    }

    getExistingFeeDetails = () => {
        this.props.loaderShow();
        CovidCenterService.getExistingFeeDetails(this.props.patient.patient_id).then(resp => {
            let point_value = '';
            let test_type_id = resp.data.test_type ? JSON.parse(resp.data.test_type).id : "";
            this.setState({
                ...resp.data, point_value, test_type: test_type_id
            });
            let existingData = this.findExistingData('pooja-loyalty-amount');

            if (existingData) {
                point_value = existingData['amount'];
            }
            if (this.props.patientLastCovidDetail.covid_test_id && this.state.status === "unpaid") {
                let search = find(this.state.covitTestList, (test) => { return test.id == this.props.patientLastCovidDetail.covid_test_id });
                this.setState({ test_type: this.props.patientLastCovidDetail.covid_test_id, selectedTest: search, total_fee: search ? search.price : 0 })
            }
            this.props.updatePaymentDetail(resp.data);
            this.props.loaderHide()
        }).catch(err => {
            this.props.loaderHide()
            // this.props.history.push('/covid-centers/pending-reports');
            console.log(" Error", err)
            // Notification.show('error', err.response.data);
        })
    }

    getCovidTestList = () => {
        CovidCenterService.getCovidTestList().then(resp => {
            this.setState({
                covitTestList: resp.data.data
            });

        }).catch(err => {
            Notification.show('error', err.response.data);
        })
    }

    findExistingData = (type) => {
        let { payments } = this.state
        return find(payments, { 'type': type })
    }

    calculatePoints = (amount) => {
        let point_value = amount * PL_POINT_RATE;
        return Math.round(point_value)
    }

    onChange = (evt) => {
        const target = evt.target
        if (includes(['card', 'cash', 'pooja-loyalty-points', 'insurance'], target.name)) {
            let { payments, point_value } = this.state;
            if (target.name === 'pooja-loyalty-points') {
                point_value = this.calculatePoints(target.value);
            }
            let existingData = this.findExistingData(target.name);

            existingData ? existingData['amount'] = target.value : payments = payments.concat({
                'type': target.name,
                'amount': target.value
            });
            this.setState({ payments, point_value })
        } else if (includes(['test_type'], target.name)) {
            let search = find(this.state.covitTestList, (test) => { return test.id == target.value });
            this.setState({ [target.name]: target.value, selectedTest: search, total_fee: search ? search.price : 0 })
        } else {
            this.setState({ [target.name]: target.value })
        }
    }

    handlePrintReceipt = (e) => {
        this.setState({ [e.target.name]: e.target.checked })
    }

    onSubmit = (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        if (isEmpty(this.state.payments)) {
            Notification.show('error', { message: 'Enter payment details' });
            return false
        } else {
            let total = 0.0;
            let { payments } = this.state
            let emptyData = find(payments, { 'amount': '' })
            if (emptyData) {
                pull(payments, emptyData);
            }
            let existingData = this.findExistingData('pooja-loyalty-points');
            if (existingData) {
                payments = payments.concat({
                    'type': 'pooja-loyalty-amount',
                    'amount': this.state.point_value
                });
            }
            payments.forEach(payment => {
                if (payment.type !== 'pooja-loyalty-points')
                    total += parseFloat(payment.amount)
            });
            if (total !== parseFloat(this.state.total_fee)) {
                Notification.show('error', { message: 'Paid amount should be equal to total fee.' });
                return false
            } else {
                let postData = {
                    "remarks": this.state.remarks,
                    "patient_id": this.props.patient.patient_id,
                    patient_name: this.props.patient.full_name,
                    "payments": payments,
                    test_type: this.state.selectedTest
                }
                this.props.loaderShow()
                CovidCenterService.storeCovidTestFee(postData).then(resp => {
                    if (this.state.is_print_check) {
                        window.open(resp.data.receipt_url, "_blank")
                    }
                    this.setState({ status: 'paid' })
                    this.props.updatePaymentDetail(resp.data);
                    this.props.loaderHide()
                }).catch(err => {
                    this.props.loaderHide();
                    Notification.show('error', err.response.data);
                })
            }
        }
    }

    getValue = (targetName) => {
        const { payments } = this.state;
        let existingData = ''
        if (!isEmpty(payments)) {
            existingData = this.findExistingData(targetName);
            return existingData ? existingData['amount'] : ''
        }
        return '';
    }
    render() {
        const { status } = this.state;
        return <div className="row">
            <div className="col-md-24">
                <h4 className="page-title">Payment Details</h4>
                <div className="content-wrapper edit-patient">
                    <form>
                        <div className="form-group row">
                            <label htmlFor="test_type" className="col-sm-4 col-form-label">Test Type</label>
                            <div className="col-sm-8">
                                <select className="form-control sm-4" onChange={this.onChange} value={this.state.test_type} name="test_type" id="test_type" disabled={status === "paid" ? true : false}>
                                    <option value="">Select Test</option>
                                    {this.state.covitTestList.map(row =>
                                        <option value={row.id}>{row.name}</option>
                                    )}
                                </select>
                            </div>
                            <label className="col-sm-4 col-form-label">Payment to be collected</label>
                            <label className="col-sm-8 col-form-label">{this.state.total_fee ? "JMD $" + this.state.total_fee : "-"}</label>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="inputcard" className="col-sm-4 col-form-label">Collected Via Card</label>
                            <div className="col-sm-8">
                                <input type="number" className="form-control" id="inputcard" onChange={this.onChange} name="card" disabled={status === "paid" ? true : false} value={this.getValue("card")} />
                            </div>
                            <label htmlFor="inputinsurance" className="col-sm-4 col-form-label">Collected Via Insurance</label>
                            <div className="col-sm-8">
                                <input type="number" className="form-control" id="inputinsurance" name="insurance" onChange={this.onChange} disabled={status === "paid" ? true : false} value={this.getValue("insurance")} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="inputcash" className="col-sm-4 col-form-label">Collected Via Cash</label>
                            <div className="col-sm-8">
                                <input type="number" className="form-control" id="inputcash" onChange={this.onChange} name="cash" disabled={status === "paid" ? true : false} value={this.getValue("cash")} />
                            </div>
                            <label htmlFor="inputpoints" className="col-sm-4 col-form-label">Collected Via Pooja Points<br /> (1 Point = 70 cents)</label>
                            <div className="col-sm-3">
                                <input type="number" className="form-control" id="inputpoints" placeholder="Enter Points" onChange={this.onChange} name="pooja-loyalty-points" disabled={status === "paid" ? true : false} value={this.getValue("pooja-loyalty-points")} />
                            </div>
                            <span className="mt-2 mb-2"> = </span>
                            <div className="col-sm-3">
                                <input type="text" className="form-control" id="inputpoints" value={this.state.point_value} readOnly />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="inputremark" className="col-sm-4 col-form-label">Remarks</label>
                            <div className="col-sm-20">
                                <textarea className="form-control" id="inputremark" name="remarks" onChange={this.onChange} disabled={status === "paid" ? true : false} value={this.state.remarks} />
                            </div>
                        </div>
                        <div className="print-receipt mb-2">
                            <input type="checkbox" id='general-normal' className='general-normal' onClick={this.handlePrintReceipt} name="is_print_check" disabled={status === "paid" ? true : false} />
                            <label htmlFor='general-normal'>
                                Print Reciept
                            </label>
                        </div><br />
                        <div className="float-right">
                            <button type="submit" className="btn btn-primary" onClick={this.onSubmit} disabled={status === "paid" ? true : false} >Submit Payment</button>
                        </div>
                    </form>
                </div>
            </div>
        </div >

    }
}
export default PaymentDetails