import React from 'react';
import * as GynaecologyHistoryComponent from './GynaecologyHistoryComponent';
import { isEmpty } from 'lodash';

class GynaecologyHistory extends React.Component {
    constructor(props) {
        super(props);
        this.handleGynaecologyHistoryChange = this.handleGynaecologyHistoryChange.bind(this);
        this.handleReproductiveHistoryChange = this.handleReproductiveHistoryChange.bind(this);
    }
    handleGynaecologyHistoryChange = (e) => {
        let { gynaecology_history } = this.props;
        let gynaecological_history = !isEmpty(gynaecology_history) && gynaecology_history['gynaecological_history'] ? gynaecology_history['gynaecological_history'] : {};
        if (e.target.name === "is_regular_periods" || e.target.name === "is_menopause") {
            gynaecological_history[e.target.name] = e.target.value === "" ? '' : JSON.parse(e.target.value);
        } else
            gynaecological_history[e.target.name] = e.target.value;
        gynaecology_history['gynaecological_history'] = gynaecological_history
        this.props.handleGynacHistory(gynaecology_history);
    }
    handleReproductiveHistoryChange = (e) => {
        let { gynaecology_history } = this.props
        let reproductive_history = !isEmpty(gynaecology_history) && gynaecology_history['reproductive_history'] ? gynaecology_history['reproductive_history'] : {};
        reproductive_history[e.target.name] = e.target.value;
        gynaecology_history['reproductive_history'] = reproductive_history;
        this.props.handleGynacHistory(gynaecology_history);
    }
    handleAllergyChanges = (e) => {
        let { gynaecology_history } = this.props
        let allergies = !isEmpty(gynaecology_history) && gynaecology_history['allergies'] ? gynaecology_history['allergies'] : {};
        if (e.target.name === "is_hospitalized_allergic_reaction") {
            allergies[e.target.name] = e.target.value === "" ? '' : JSON.parse(e.target.value);
        } else
            allergies[e.target.name] = e.target.value;
        gynaecology_history['allergies'] = allergies;
        this.props.handleGynacHistory(gynaecology_history);
    }
    render() {
        let { gynaecology_history } = this.props;
        return (
            <React.Fragment>
                {/* <div className="content-wrapper add-user-section">
                    <label>Include in Printable Patient File</label>
                    <input type="checkbox" id="include_gynaecology_history" name="include_gynaecology_history" onChange={this.props.handleCheck} checked={this.props.include_gynaecology_history ? 'checked' : ''} />
                    <label htmlFor="include_gynaecology_history"></label>
                </div> */}
                <div className="examination-wrapper clearfix reproductive-history">
                    <GynaecologyHistoryComponent.ReproductiveHistory
                        handleChange={this.handleReproductiveHistoryChange}
                        gynaecology_history={!isEmpty(gynaecology_history) && gynaecology_history['reproductive_history'] ? gynaecology_history['reproductive_history'] : {}}
                    />
                </div>
                <div className="examination-wrapper clearfix gynaecological-history">
                    <GynaecologyHistoryComponent.GynaecologyHistory
                        handleChange={this.handleGynaecologyHistoryChange}
                        gynaecology_history={!isEmpty(gynaecology_history) && gynaecology_history['gynaecological_history'] ? gynaecology_history['gynaecological_history'] : {}}
                        getValue={this.props.getValue}
                    />
                </div>
                <div className="examination-wrapper clearfix allergies">
                    <GynaecologyHistoryComponent.Allergy
                        handleChange={this.handleAllergyChanges}
                        gynaecology_history={!isEmpty(gynaecology_history) && gynaecology_history['allergies'] ? gynaecology_history['allergies'] : {}}
                        getValue={this.props.getValue}
                    />
                </div>
            </React.Fragment>
        )
    }
}
export default GynaecologyHistory