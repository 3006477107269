import React from "react";
import _ from "lodash";

export const ShowCpt = ({ cpt, idx, disable, handleRemoveCptData }) => {
    return (
        cpt && !_.isEmpty(cpt.cptCrosswalks) ? cpt.cptCrosswalks.map((crosswalk, idx) => (
            <li key={idx}>
                <div>ICD Code: <span>{crosswalk.icd_code}</span></div>
                <div>ICD Description: <span>{crosswalk.full_description}</span></div>
                <div>CPT Code: <span>{cpt.selectedCpt.cpt_code}</span></div>
                <div>CPT Description: <span>{cpt.selectedCpt.full_description}</span></div>
                {!disable && <a href className="cancel" onClick={handleRemoveCptData} data-id={idx}>X</a>}
            </li>
        ))
            :
            cpt && <li>
                <div>CPT Code: <span>{cpt.selectedCpt.cpt_code}</span></div>
                <div>CPT Description: <span>{cpt.selectedCpt.full_description}</span></div>
                {!disable && <a href className="cancel" onClick={handleRemoveCptData} data-id={idx}>X</a>}
            </li>
    )
}
export const ShowIcd = ({ icd, disable, handleRemoveIcdData, idx }) => {
    return (
        icd && !_.isEmpty(icd.icdCrosswalks) ? icd.icdCrosswalks.map((crosswalk, idx) => (
            <li key={idx}>
                {icd.selectedIcd.icd_code && <div>ICD Code: <span>{icd.selectedIcd.icd_code}</span></div>}
                {icd.selectedIcd.full_description && <div>ICD Description: <span>{icd.selectedIcd.full_description}</span></div>}
                {crosswalk.cpt_code && <div>CPT Code: <span>{crosswalk.cpt_code}</span></div>}
                {crosswalk.full_description && <div>CPT Description: <span>{crosswalk.full_description}</span></div>}
                {!disable && <a href className="cancel" onClick={handleRemoveIcdData} data-id={idx}>X</a>}
            </li>
        ))
            :
            icd && <li>
                <div>ICD Code: <span>{icd.selectedIcd.icd_code}</span></div>
                <div>ICD Description: <span>{icd.selectedIcd.full_description}</span></div>
                {!disable && <a href className="cancel" onClick={handleRemoveIcdData} data-id={idx}>X</a>}
            </li>
    )
}