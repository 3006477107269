import React from 'react';
import DataGrid from './DataGrid';
import UploadPathologyReportModal from './UploadPathologyReportModal';

class PatientPathologyTestTab extends React.Component {
    state = {
        show_upload_document_modal: false
    }
    handleReportUploadModal = () => {
        this.setState({ show_upload_document_modal: !this.state.show_upload_document_modal });
    }
    render() {
        return (
            <React.Fragment>
                <button
                    type="button"
                    className="btn btn-primary float-right mt-3 mb-3"
                    onClick={this.handleReportUploadModal}
                >
                    Upload Report
                </button>
                {<div className="content-wrapper">
                    <DataGrid
                        columns={this.props.pathologyColumns}
                        data={this.props.documents && this.props.documents.data}
                        meta={this.props.documents && this.props.documents.meta}
                        noDataIndication="No records found!"
                        handleTableChange={this.props.handlePathologyTableChange}
                    />
                </div>}
                {this.state.show_upload_document_modal && <UploadPathologyReportModal
                    show={this.state.show_upload_document_modal}
                    patient={this.props.patient}
                    handleModal={this.handleReportUploadModal}
                    refreshList={this.props.refreshPathologyList}
                    />}
            </React.Fragment>
        )
    }
}
export default PatientPathologyTestTab