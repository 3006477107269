import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ApproveModal = ({ showModal, handleModal, customText }) => {
    return (
        <Modal show={showModal} backdrop="static" keyboard={false} onHide={(e) => handleModal(e)}>
            <Modal.Header closeButton>
                <Modal.Title>Are you sure to submit {customText} ?</Modal.Title>
            </Modal.Header>
            <Modal.Body>Save Changes</Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={(e) => handleModal(e, 'cancel')}>
                    No
                </Button>
                <Button variant="success" onClick={(e) => handleModal(e, 'save')}>
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default ApproveModal