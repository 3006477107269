import React from 'react';

class AutoSuggest extends React.Component {

    static defaultProps = {
        options: [],
        allowNew: false,
        isLoading: false,
        newSelectionPrefix: 'New ',
        placeholder: 'Search...',
        labelKey: 'name',
        minLength: 1
    };

    constructor(props) {
        super(props);

        this.state = {
            options: [],
            showOption: false,
            value: this.props.defaultValue ? this.props.defaultValue : '',
            selectedOption: -1,
            activeOption: null
        }
    }

    componentWillReceiveProps(nextProps) {
        let newOptions = nextProps.options.map((option) => { return { customOption: false, ...option } });

        if (this.props.allowNew) {
            newOptions = newOptions.concat({
                customOption: true,
                [this.props.labelKey]: this.state.value
            })
        }

        this.setState({
            options: newOptions
        });

        if (nextProps.defaultValue && nextProps.defaultValue !== this.state.value) {
            this.setState({
                value: nextProps.defaultValue
            });
        }

        setTimeout(() => {
            if (nextProps.defaultValue === "" && this.state.showOption === false) {
                this.setState({
                    value: ""
                });
            }
        }, 200);
    }

    onChange = (evt) => {
        this.setState({
            value: evt.target.value,
            showOption: evt.target.value ? true : false,
            selectedOption: -1,
            activeOption: null
        })
        this.props.onSearch(evt.target.value);

        if (evt.target.value !== this.props.defaultValue || evt.target.value === '') {
            this.props.onChange(null);
        }
    }

    onBlur = (evt) => {
        setTimeout(() => {
            if (!this.state.activeOption) {
                this.setState({
                    value: '',
                    showOption: false,
                    selectedOption: -1,
                    activeOption: null
                })
            }
        }, 200);
    }

    onClick = (option) => {
        this.setState({
            showOption: false,
            value: option[this.props.labelKey],
            activeOption: option
        })
        this.props.onChange(option);
    }

    formatMenu = (option, index) => {
        let className = '';

        if (index === this.state.selectedOption) {
            className = "active";
        }

        return (
            option.customOption === false ? (<li id={"id" + index} className={className} key={index} onClick={e => this.onClick(option)} data-option={option}>{this.props.renderMenuItem(option)}</li>) : (<li id={"id" + index} className={"allow-new " + className} key={index} onClick={e => this.onClick(option)} data-option={option}>{this.props.newSelectionPrefix} {this.state.value}</li>)
        )
    }

    formatLoading = () => {
        return (
            <li className="load-list">Loading...</li>
        )
    }

    // Event fired when the user presses a key down
    onKeyDown = e => {
        const { selectedOption } = this.state;

        const filteredSuggestions = this.state.options;

        // User pressed the enter key, update the input and close the
        // suggestions
        if (e.keyCode === 13) {
            const selected = filteredSuggestions[selectedOption];
            if (selected) {
                this.setState({
                    showOption: false,
                    value: selected[this.props.labelKey],
                    activeOption: selected
                });
            }
            this.props.onChange(selected);
        }
        // User pressed the up arrow, decrement the index
        else if (e.keyCode === 38) {
            if (selectedOption === -1) {
                return;
            }

            this.setState({ selectedOption: selectedOption - 1 });
        }
        // User pressed the down arrow, increment the index
        else if (e.keyCode === 40) {
            if (selectedOption + 1 === filteredSuggestions.length) {
                return;
            }

            this.setState({ selectedOption: selectedOption + 1 });
        } else if (e.keyCode === 27) {
            this.setState({
                showOption: false,
                activeOption: null,
            });
        }
    };

    render() {
        return (
            <React.Fragment>
                <div className="suggesstion">
                    <input type="text" className="form-control" value={this.state.value} placeholder={this.props.placeholder} onChange={this.onChange} onKeyDown={this.onKeyDown} onBlur={this.onBlur} disabled={this.props.disabled} />

                    <ul className="suggesstion-list">
                        {this.props.isLoading && this.state.showOption ? this.formatLoading() : (this.state.showOption && this.state.options.map((option, index) => this.formatMenu(option, index)))}
                    </ul>
                </div>
            </React.Fragment>
        )
    }
}

export default AutoSuggest;