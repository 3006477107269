import React from 'react';
import { Tab, Tabs } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import { capitalize, isEmpty } from 'lodash';
import { getAgeString, getDateFormat } from '../../utils/helpers';
import { hasRole } from '../../utils/auth';
import VitalComponent from '../../components/Prescription/Vital/AddVital';
import HistoryComponent from '../../components/Prescription/History/AddHistoryAccordion';
import PresentConditionComponent from '../../components/Prescription/PresentingCondition/AddPresentingCondition';
import AssessmentComponent from '../../components/Prescription/Assessment/AddAssessment';
import RecommendationComponent from '../../components/Prescription/Recommendation/AddRecommendation';
import SimpleReactValidator from 'simple-react-validator';
import { connect } from 'react-redux';
import ExaminationComponent from '../../components/Prescription/Examination/AddExamination';
import AssignToBlock from '../../components/Prescription/AssignToBlock';
import PrescriptionService from '../../services/prescriptionService';
import PatientService from '../../services/patientService';
import * as LoaderAction from '../../actions/loaderAction';
import Notification from '../../utils/notification';
import CancelModal from '../../components/CancelModal';
import AddBodyChart from '../../components/Prescription/BodyChart/AddBodyChart';
import FreeSelectModal from '../../components/FreeSelectModal';

class AddPrescriptionPageNew extends React.Component {
    constructor(props) {
        super(props);
        const params = this.props.match.params;
        let { state } = this.props.history.location
        this.state = {
            patient: {},
            currentKey: state === "repeated" ? 7 : 1,
            patientId: params.patientId,
            prescriptionId: params.prescriptionId,
            btn_assign: false,
            assign_to: "",
            assign_by: this.props.selected_user_profile.role_slug,
            showCancelModal: false,
            isEdited: false,
            editedSection: '',
            showFreeSelectModal: false,
            selectedKey: null
        }
        this.validator1 = new SimpleReactValidator();
        this.vitalRef = React.createRef();
        this.historyRef = React.createRef();
        this.presentConditionRef = React.createRef();
        this.assessmentRef = React.createRef();
        this.recommendationRef = React.createRef();
        this.bodyChartRef = React.createRef();
        this.examinationRef = React.createRef();
    }
    handleCancelClose = () => {
        this.setState({ showCancelModal: false })
    }
    handleCancelRedirect = () => {
        this.setState({ showCancelModal: false })
        PrescriptionService.cancelPrescription(this.state.prescriptionId).then(_resp => {
            this.props.history.push('/patients/search');
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data)
        })
    }
    showCancelModal = () => {
        this.setState({ showCancelModal: true })
    }
    //Assign to Nurse or Jr. Doctor
    handleChangeAssign = (data) => {
        !isEmpty(data) ? this.setState({
            assign_to: data,
            btn_assign: true
        }) : this.setState({
            assign_to: '',
            btn_assign: false
        })
    }
    componentDidMount() {
        this.props.showLoader();
        PatientService.get(this.state.patientId).then(response => {
            let { data } = response;
            this.setState({
                patient: data
            });
            this.props.hideLoader();
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data)
        });
        this.acquirePrescription();
    }
    acquirePrescription = () => {
        this.props.showLoader();
        PrescriptionService.acquirePrescriptionAccess({ prescription_id: this.state.prescriptionId }).then(_resp => {
            this.props.hideLoader();
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data);
            this.props.history.push('/prescriptions')
        });
    }
    renderButtons = () => {
        return (<React.Fragment>
            <div className="col-md-4 cancel-prescription">
                {!this.state.btn_assign && <button type="submit" className="btn btn-primary" onClick={e => this.handleAssignToDoctor(e, 'approval')}>Send For Approval</button>}
            </div>
            <div className="col-md-4 cancel-prescription">
                {!this.state.btn_assign && <button type="submit" onClick={e => this.handleAssignToDoctor(e, 'queue')} className="btn btn-primary">Send to queue</button>}
            </div>
        </React.Fragment>)
    }
    handlePrevious = () => {
        this.setState((prevState, _props) => {
            return { currentKey: prevState.currentKey - 1 }
        })
    }
    handleAssignToDoctor = (e, type) => {
        e.preventDefault();
        this.props.showLoader();
        PrescriptionService.prescriptionAssignToDoctor(this.state.prescriptionId, { 'assign_type': type }).then(resp => {
            this.props.hideLoader();
            Notification.show('success', resp.data);
            this.props.history.push('/prescriptions')
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data)
        })
    }
    handleAssignToAssistant = (e) => {
        e.preventDefault();
        this.props.showLoader();
        PrescriptionService.prescriptionAssignToAssistant(this.state.prescriptionId, { 'assign_role': this.state.assign_to }).then(resp => {
            this.props.hideLoader();
            Notification.show('success', resp.data);
            this.props.history.push('/approve-prescription')
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data)
        })
    }
    handleSaveAndNext = () => {
        this.setState((prevState, _props) => {
            return { currentKey: (this.state.selectedKey ? parseInt(this.state.selectedKey) : prevState.currentKey + 1), selectedKey: '', isEdited: false }
        })
    }
    handleRedirect = () => {
        this.props.history.push('/patients/search');
    }
    handleCancelPrescription = () => {
        PrescriptionService.cancelPrescription(this.state.prescriptionId).then(_resp => {
            this.props.history.push('/patients/search');
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data)
        })
    }
    _onSelect = (selectedKey) => {
        this.state.isEdited ? this.setState({ showFreeSelectModal: true, selectedKey }) : this.setState({ currentKey: parseInt(selectedKey), selectedKey: '' })
    };

    handleIsPrescriptionEdited = (editedSection) => this.setState({ editedSection, isEdited: true });

    handleFreeSelect = (evt, type = null) => {
        let { selectedKey } = this.state
        if (type === 'save') {
            this.state.editedSection === 'vital' && this.vitalRef.current.handleVitalSaveAndNext(evt, 'save');
            this.state.editedSection === 'history' && this.historyRef.current.handleHistorySaveAndNext(evt, 'save');
            this.state.editedSection === 'present_condition' && this.presentConditionRef.current.handlePresentConditionSaveAndNext(evt, 'save');
            this.state.editedSection === 'examination' && this.examinationRef.current.handleExaminationSaveAndExit(evt, 'save');
            this.state.editedSection === 'assessment' && this.assessmentRef.current.handleAssessmentSaveAndNext(evt, 'save');
            this.state.editedSection === 'body_chart' && this.bodyChartRef.current.handleBodyChartSaveAndNext(evt, 'save');
            this.state.editedSection === 'recommendation' && this.recommendationRef.current.handleRecommendationSave(evt, 'save');
        }
        if (type === 'cancel') selectedKey = '';
        this.setState({ showFreeSelectModal: false, selectedKey });
    }

    render() {
        return (<React.Fragment >
            <Helmet>
                <title>Add Encounter</title>
            </Helmet>
            <section className="container-fluid">
                <div className="row">
                    <div className="col-md-12 requester-tabs">
                        <h4 className="page-title">Add Encounter</h4>
                    </div>

                    {(this.props.selected_user_profile.role_slug === "doctor" || this.props.selected_user_profile.role_slug === "associate-doctor") &&
                        <div className="col-md-8 assign-to-new">
                            <h6>Assign to</h6>
                            <AssignToBlock
                                roleSlug={this.props.selected_user_profile.role_slug}
                                handleChangeAssign={this.handleChangeAssign}
                            />
                            {this.state.btn_assign &&
                                <button className="btn btn-primary" onClick={this.handleAssignToAssistant}>Assign</button>
                            }
                        </div>}
                    {hasRole(this.props.selected_user_profile, ['doctor-nurse', 'junior-doctor']) && this.renderButtons()}
                    <div className="col-md-4 cancel-prescription">
                        <button type="submit" className="btn btn-default btn-outline-primary" onClick={this.showCancelModal}>{ hasRole(this.props.selected_user_profile, ['doctor-nurse']) ? "Cancel Visit" : "Cancel Encounter" }</button>
                    </div>
                    <div className="content-wrapper padding-bottom-10">
                        <h6>Personal Info</h6>
                        <ul className="prescription-patient-details">
                            <li>Name: <span>{this.state.patient.full_name}</span></li>
                            <li>Date of Birth: <span>{this.state.patient.date_of_birth && getDateFormat(this.state.patient.date_of_birth)} ({getAgeString(this.state.patient.date_of_birth)})</span></li>
                            <li>Gender: <span>{capitalize(this.state.patient.gender)}</span></li>
                        </ul>
                    </div>
                    <div className="col-md-24 mt-3 request-pending">
                        <Tabs activeKey={this.state.currentKey} onSelect={this._onSelect}>
                            <Tab eventKey={1} title="Vitals">
                                {this.state.currentKey === 1 && <VitalComponent
                                    selected_user_profile={this.props.selected_user_profile}
                                    patient_dob={this.state.patient.date_of_birth}
                                    patient_gender={this.state.patient.gender}
                                    showLoader={this.props.showLoader}
                                    hideLoader={this.props.hideLoader}
                                    handleSaveAndNext={this.handleSaveAndNext}
                                    prescriptionId={this.state.prescriptionId}
                                    patientId={this.state.patientId}
                                    handleRedirect={this.handleRedirect}
                                    handleIsPrescriptionEdited={this.handleIsPrescriptionEdited}
                                    ref={this.vitalRef}
                                />}
                            </Tab>
                            {hasRole(this.props.selected_user_profile, ['junior-doctor', "associate-doctor", "doctor"]) && <Tab eventKey={2} title="General History">
                                {this.state.currentKey === 2 && <HistoryComponent
                                    patient_gender={this.state.patient.gender}
                                    prescriptionId={this.state.prescriptionId}
                                    selected_user_profile={this.props.selected_user_profile}
                                    showLoader={this.props.showLoader}
                                    hideLoader={this.props.hideLoader}
                                    handleSaveAndNext={this.handleSaveAndNext}
                                    patientId={this.state.patientId}
                                    handlePrevious={this.handlePrevious}
                                    handleIsPrescriptionEdited={this.handleIsPrescriptionEdited}
                                    ref={this.historyRef}
                                />}
                            </Tab>}
                            {hasRole(this.props.selected_user_profile, ['junior-doctor', "associate-doctor", "doctor"]) && <Tab eventKey={3} title="Presenting History">
                                {this.state.currentKey === 3 && <PresentConditionComponent
                                    prescriptionId={this.state.prescriptionId}
                                    validator={this.validator1}
                                    patientId={this.state.patientId}
                                    handlePrevious={this.handlePrevious}
                                    showLoader={this.props.showLoader}
                                    hideLoader={this.props.hideLoader}
                                    handleSaveAndNext={this.handleSaveAndNext}
                                    handleIsPrescriptionEdited={this.handleIsPrescriptionEdited}
                                    ref={this.presentConditionRef}
                                    selected_user_profile={this.props.selected_user_profile}
                                />}
                            </Tab>}
                            {hasRole(this.props.selected_user_profile, ['junior-doctor', "associate-doctor", "doctor"]) && <Tab eventKey={4} title="Examination">
                                {this.state.currentKey === 4 && <ExaminationComponent
                                    prescriptionId={this.state.prescriptionId}
                                    validator={this.validator1}
                                    patientId={this.state.patientId}
                                    handlePrevious={this.handlePrevious}
                                    showLoader={this.props.showLoader}
                                    hideLoader={this.props.hideLoader}
                                    handleSaveAndNext={this.handleSaveAndNext}
                                    handleRedirect={this.handleRedirect}
                                    selected_user_profile={this.props.selected_user_profile}
                                    handleIsPrescriptionEdited={this.handleIsPrescriptionEdited}
                                    ref={this.examinationRef}
                                />}
                            </Tab>}
                            {hasRole(this.props.selected_user_profile, ["associate-doctor", "doctor"]) && <Tab eventKey={5} title="Assessment">
                                {this.state.currentKey === 5 && <AssessmentComponent
                                    prescriptionId={this.state.prescriptionId}
                                    validator={this.validator1}
                                    patientId={this.state.patientId}
                                    handlePrevious={this.handlePrevious}
                                    showLoader={this.props.showLoader}
                                    hideLoader={this.props.hideLoader}
                                    handleSaveAndNext={this.handleSaveAndNext}
                                    selected_user_profile={this.props.selected_user_profile}
                                    handleIsPrescriptionEdited={this.handleIsPrescriptionEdited}
                                    ref={this.assessmentRef}
                                />}
                            </Tab>}
                            {hasRole(this.props.selected_user_profile, ["associate-doctor", "doctor"]) && <Tab eventKey={6} title="Body Chart">
                                {this.state.currentKey === 6 && <AddBodyChart
                                    prescriptionId={this.state.prescriptionId}
                                    validator={this.validator1}
                                    patientId={this.state.patientId}
                                    handlePrevious={this.handlePrevious}
                                    showLoader={this.props.showLoader}
                                    hideLoader={this.props.hideLoader}
                                    handleSaveAndNext={this.handleSaveAndNext}
                                    selected_user_profile={this.props.selected_user_profile}
                                    handleIsPrescriptionEdited={this.handleIsPrescriptionEdited}
                                    ref={this.bodyChartRef}
                                />}
                            </Tab>}
                            {hasRole(this.props.selected_user_profile, ["associate-doctor", "doctor"]) && <Tab eventKey={7} title="Prescription and Referral">
                                {this.state.currentKey === 7 && <RecommendationComponent
                                    prescriptionId={this.state.prescriptionId}
                                    patientId={this.state.patientId}
                                    handlePrevious={this.handlePrevious}
                                    showLoader={this.props.showLoader}
                                    hideLoader={this.props.hideLoader}
                                    handleSaveAndNext={this.handleSaveAndNext}
                                    selected_user_profile={this.props.selected_user_profile}
                                    history={this.props.history}
                                    handleIsPrescriptionEdited={this.handleIsPrescriptionEdited}
                                    ref={this.recommendationRef}
                                />}
                            </Tab>}
                        </Tabs>
                    </div>
                </div>
            </section>
            {this.state.showCancelModal &&
                <CancelModal showModal={this.state.showCancelModal} handleRedirect={this.handleCancelRedirect} handleClose={this.handleCancelClose} />
            }
            {this.state.showFreeSelectModal &&
                <FreeSelectModal
                    showModal={this.state.showFreeSelectModal}
                    handleModal={this.handleFreeSelect} />
            }
        </React.Fragment >)
    }
}
const mapStateToProps = (state) => ({
    selected_user_profile: state.selected_user_profile,
});
const mapActionsToProps = {
    hideLoader: LoaderAction.loaderHide,
    showLoader: LoaderAction.loaderShow,
};
export default connect(mapStateToProps, mapActionsToProps)(AddPrescriptionPageNew)