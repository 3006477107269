import CommissionService from '../services/commissionService';
import Notification from '../utils/notification';

export function list({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: 'COMMISSION_LIST_REQUEST', request: { page, sizePerPage, sortBy, searchText, filter } });

        CommissionService.list({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'COMMISSION_LIST_SUCCESS', ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'COMMISSION_LIST_FAILED' });
                    Notification.show('error', error.response.data)
                }
            });
    }
}