import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { getDateFormat } from '../../utils/helpers';
import DataGrid from "../../components/DataGrid";
import * as CovidCenterActions from '../../actions/covidCenterAction';
import { Link } from "react-router-dom";
import { capitalize } from 'lodash';
import SearchComponent from '../../components/Covid/SearchComponent';
import * as loaderAction from '../../actions/loaderAction';
import CovidCenterService from '../../services/covidCenterService';
import Notification from '../../utils/notification';

class CovidReportPage extends React.Component {
    constructor(props) {
        super(props);
        const { params: { type } } = this.props.match;

        this.state = {
            type: type,
            filter: {
            }

        };
    }
    componentDidMount() {
        this.props.paginate({ sortBy: { sortField: "updated_at", sortOrder: "asc" }, filter: this.state.filter });
    }
    columns = () => {
        return [{
            dataField: 'created_at',
            text: 'Uploaded Date',
            formatter: this.dateTimeFormat,
        }, {
            text: 'Patient Name',
            dataField: 'patient_name',
        }, {
            text: 'Doctor Name',
            dataField: 'doctor_name',
        }, {
            text: 'Laboratory Name',
            dataField: 'laboratory_name',
        }, {
            dataField: 'conducted_at',
            text: 'Conducted Date',
            formatter: this.dateTimeFormat,
        }, {
            text: 'Test Result',
            dataField: 'test_result',
            formatter: (cell, row) => capitalize(cell)
        }, {
            dataField: 'actions',
            text: 'Actions',
            isDummyField: true,
            formatter: this.actionFormatter
        }];
    };
    dateTimeFormat = (cell, row) => {
        return getDateFormat(cell)
    }
    actionFormatter = (cell, row) => {
        return <Link to="#" className="ml-2" onClick={e => this.download(e, row.id)}><span className="icon file"></span></Link>
    };
    download = (e, id) => {
        e.preventDefault();
        this.props.loaderShow();
        CovidCenterService.downloadUploadedReports(id).then(resp => {
            if (resp.data && resp.data.download_url) {
                window.open(resp.data.download_url, "_blank")
            }
            this.props.loaderHide()
        }).catch(err => {
            this.props.loaderHide()
            Notification.show('error', err.response.data);
        })
    }
    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        if (type === 'search') {
            page = 1;
        }
        this.props.paginate({ page, sizePerPage, sortBy: { sortField: "updated_at", sortOrder: "asc" }, filter: this.state.filter });
    }
    onSearch = (filter) => {
        this.props.paginate({ sortBy: { sortField: "updated_at", sortOrder: "asc" }, filter: filter });
    }
    render() {
        let { data, meta } = this.props.covid_reports;
        return <React.Fragment>
            <Helmet>
                <title>Uploaded Reports</title>
            </Helmet>
            <div className="row">
                <div className="col-md-24">
                    <h4 className="page-title">Uploaded Reports</h4>
                </div>
            </div>
            <SearchComponent onSearch={this.onSearch} list_type="uploaded" />
            <div className="content-wrapper">
                {data && <DataGrid
                    columns={this.columns()}
                    data={data}
                    meta={meta}
                    handleTableChange={this.handleTableChange}
                    noDataIndication="No Record Found"

                />}
            </div>
        </React.Fragment>
    }
}
const mapStateToProps = (state) => ({
    covid_reports: state.covid_reports,
});

const mapActionsToProps = {
    paginate: CovidCenterActions.paginateUploadedReports,
    loaderShow: loaderAction.loaderShow,
    loaderHide: loaderAction.loaderHide,
};

export default connect(mapStateToProps, mapActionsToProps)(CovidReportPage);