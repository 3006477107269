import React from 'react';

export const Skin = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <div className="heading">
            <h6>Skin</h6>
        </div>
        <div className="form-group full">
            <div className="options-details">
                <input type="checkbox" id="skin-redness" name="skin-redness" onChange={e => handleChange(e, 'skin')} checked={checkIfProblemExist('skin', "skin-redness") ? 'checked' : ''} />
                <label htmlFor="skin-redness">Redness</label>
                <input type="checkbox" id="rash" name="rash" onChange={e => handleChange(e, 'skin')} checked={checkIfProblemExist('skin', "rash") ? 'checked' : ''} />
                <label htmlFor="rash">Rash</label>
                <input type="checkbox" id="nodules-bumps" name="nodules-bumps" onChange={e => handleChange(e, 'skin')} checked={checkIfProblemExist('skin', "nodules-bumps") ? 'checked' : ''} />
                <label htmlFor="nodules-bumps">Nodules/Bumps</label>
                <input type="checkbox" id='hair-loss' name='hair-loss' onChange={e => handleChange(e, 'skin')} checked={checkIfProblemExist('skin', 'hair-loss') ? 'checked' : ''} />
                <label htmlFor='hair-loss'>Hair Loss</label>
                <input type="checkbox" id='color-changes-of-hands-or-feet' name='color-changes-of-hands-or-feet' onChange={e => handleChange(e, 'skin')} checked={checkIfProblemExist('skin', 'color-changes-of-hands-or-feet') ? 'checked' : ''} />
                <label htmlFor='color-changes-of-hands-or-feet'>Color changes of hand or feet</label>
            </div>
        </div>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment }) => {
    return <React.Fragment>
        <label className="heading-comment">Comment </label>
        <textarea className="form-control" name="comments" placeholder="Type here..." onChange={e => handleChange(e, 'skin')} value={getCategoryComment('skin') ? getCategoryComment('skin') : ''}></textarea>
    </React.Fragment>
}