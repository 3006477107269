import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class PatientService {
    static list({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute("/patients"), requestOptions);
    }

    static searchPatientConsent(searchData) {
        const requestOptions = {
            headers: getApiHeader()
        };
        let paramsdata = JSON.stringify(searchData);

        return axios.post(apiRoute("/v1/patients/search/consent"), paramsdata, requestOptions);
    }

    static searchPatientCard(searchData) {

        const requestOptions = {
            headers: getApiHeader()
        };
        let paramsdata = JSON.stringify(searchData);

        return axios.post(apiRoute('/v1/patients/search/card'), paramsdata, requestOptions);
    }

    static create({ data }) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute('/v1/patients'), data, requestOptions);
    }

    static get(id, query) {
        const requestOptions = {
            headers: getApiHeader(),
            params: query
        };

        return axios.get(apiRoute('/v1/patients/' + id), requestOptions);
    }

    static update(data, patient_id) {

        const requestOptions = {
            headers: getApiHeader()
        };
        let paramsdata = JSON.stringify(data);

        return axios.patch(apiRoute('/v1/patients/' + patient_id), paramsdata, requestOptions);
    }


    static checkCardNumber(cardNumber) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/patients/card-numbers/' + cardNumber + '/status'), requestOptions);
    }

    static listWeight(patient_id) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/patient/' + patient_id + '/weights?limit=5'), requestOptions);
    }

    static listBloodPressure(patient_id) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/patient/' + patient_id + '/blood-pressures?limit=5'), requestOptions);
    }

    static listRate(patient_id, type) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/patients/' + patient_id + '/' + type + '?limit=5'), requestOptions);
    }

    static listPulseOx(patient_id) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/patients/' + patient_id + '/pulse-ox?limit=5'), requestOptions);
    }

    static listPatientBmi(patient_id) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/patients/' + patient_id + '/bmi?limit=5'), requestOptions);
    }

    static listPatientRbg(patient_id) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/patients/' + patient_id + '/rbg?limit=5'), requestOptions);
    }

    static listPatientTemperature(patient_id) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/patients/' + patient_id + '/temperatures?limit=5'), requestOptions);
    }

    static listSymptoms(patient_id) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/patient/' + patient_id + '/symptoms?limit=5'), requestOptions);
    }

    static listDiagnosis(patient_id) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/patient/' + patient_id + '/diagnosis?limit=5'), requestOptions);
    }

    static listPatientHeight(patient_id) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/patient/' + patient_id + '/heights?limit=5'), requestOptions);
    }

    static listExamination(patient_id) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/patient/' + patient_id + '/examinations?limit=5'), requestOptions);
    }

    static listRemarks(patient_id) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/patient/' + patient_id + '/remarks?limit=5'), requestOptions);
    }

    static verifyCard(cardObj) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute('/v1/cards/available'), cardObj, requestOptions);
    }

    static uploadProfilePicture(data) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute('/v1/patient/profilepicture'), data, requestOptions);
    }
    static getPhamacyPatient(id, query) {
        const requestOptions = {
            headers: getApiHeader(),
            params: query
        };

        return axios.get(apiRoute('/v1/pharmacy/patients/' + id), requestOptions);
    }
    static deleteProfilePicture(id) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.delete(apiRoute("/v1/profile-pictures/" + id), requestOptions);
    }

    static getPrescribedPathologyTests(patientId) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute(`/v1/doctors/patients/${patientId}/pathology-tests`), requestOptions);
    }

    static getPrescribedRadiologyTests(patientId) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute(`/v1/doctors/patients/${patientId}/radiology-tests`), requestOptions);
    }

    static searchPatientQRCode(searchData) {
        const requestOptions = {
            headers: getApiHeader()
        };
        let paramsdata = JSON.stringify(searchData);

        return axios.post(apiRoute("/v1/patients/search/qrcode"), paramsdata, requestOptions);
    }

    static getHistoricalData(patientId, { page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute(`/v1/patients/${patientId}/prescription-histories`), requestOptions);
    }

    static getHistoryDownloadUrl(id) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute(`/v1/prescription-histories/${id}/download`), requestOptions);
    }

    static historicDataUpload(files) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute("/v1/prescription-histories"), files, requestOptions);
    }

    static getUploadedHistoricData({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute(`/v1/prescription-histories`), requestOptions);
    }
    static attachPrescriptionHistory(patientId, documentId) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute(`/v1/patients/${patientId}/prescription-histories/${documentId}`), null, requestOptions);
    }

    static getPatientFeeDownloadLink(prescriptionFeeId) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/patients/fee/' + prescriptionFeeId + '/download'), requestOptions);
    }

    static updatePatientProfile(data,id) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.put(apiRoute('/v1/patient/profile-edit-queue/' + id), data, requestOptions);
    }

    static patientList({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter})
        };
        return axios.get(apiRoute("/v1/patients-list"), requestOptions);
    }
}


export default PatientService;
