import React from 'react';

export const Eyes = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <div className="heading">
            <h6>Eyes</h6>
        </div>
        <div className="form-group full">
            <div className="options-details">
                <input type="checkbox" id="pain" name="pain" onChange={e => handleChange(e, 'eyes')} checked={checkIfProblemExist('eyes', "pain") ? 'checked' : ''} />
                <label htmlFor="pain">Pain</label>
                <input type="checkbox" id="redness" name="redness" onChange={e => handleChange(e, 'eyes')} checked={checkIfProblemExist('eyes', "redness") ? 'checked' : ''} />
                <label htmlFor="redness">Redness</label>
                <input type="checkbox" id='loss-of-vision' name='loss-of-vision' onChange={e => handleChange(e, 'eyes')} checked={checkIfProblemExist('eyes', 'loss-of-vision') ? 'checked' : ''} />
                <label htmlFor='loss-of-vision'>Loss of vision</label>
                <input type="checkbox" id='double-or-blurred-vision' name='double-or-blurred-vision' onChange={e => handleChange(e, 'eyes')} checked={checkIfProblemExist('eyes', 'double-or-blurred-vision') ? 'checked' : ''} />
                <label htmlFor='double-or-blurred-vision'>Double or blurred vision</label>
                <input type="checkbox" id='dryness' name='dryness' onChange={e => handleChange(e, 'eyes')} checked={checkIfProblemExist('eyes', 'dryness') ? 'checked' : ''} />
                <label htmlFor='dryness'>Dryness</label>
            </div>
        </div>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment }) => {
    return <React.Fragment>
        <label className="heading-comment">Comment </label>
        <textarea className="form-control" name="comments" placeholder="Type here..." onChange={e => handleChange(e, 'eyes')} value={getCategoryComment('eyes') ? getCategoryComment('eyes') : ''}></textarea>
    </React.Fragment>
}