import React from 'react'
import { Accordion, Card } from "react-bootstrap"
import * as Helper from "../../utils/helpers"

function MedicationItem({ medicine }) {
    return (
        <div className="medication-Info">
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr className="font-size-12">
                            <th>Brand Name</th>
                            <th>Generic Name</th>
                            <th>Frequency</th>
                            <th>Meal</th>
                            <th>Duration</th>
                            <th>Dosage</th>
                            <th>Substitution</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="color-grey font-size-12">
                            <td>{medicine.drug_name}</td>
                            <td>{medicine.drug_generic_name}</td>
                            <td>{medicine.dosage}</td>
                            <td>{medicine.meal}</td>
                            <td>{medicine.duration_type}</td>
                            <td>{medicine.quantity}</td>
                            <td>{medicine.substitution_not_allowed}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {<div className="row">
                <div className="col-3">
                    <span className="font-size-12 color-blue">Instructions:</span>
                </div>
                <div className="col-19">
                    <p className="color-grey font-size-12">{medicine.remarks}</p>
                </div>
            </div>}
        </div>
    )
}

function MedicationCard({ prescription, index, handleOpenPrescription }) {
    return (
        <Card>
            <Accordion.Toggle as={Card.Header} onClick={() => (prescription.prescription_id === index) ?
                handleOpenPrescription(null) :
                handleOpenPrescription(prescription.prescription_id)} eventKey={prescription.prescription_id} className="color-blue bg-white" >
                Prescription Date: {Helper.getDateFormat(prescription.prescription_date)}
                <span className="float-right">{index === prescription.prescription_id ? "-" : "+"}</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={prescription.prescription_id}>
                <Card.Body>
                    {
                        prescription.prescription_medicines.map((medicine, index) => {
                            return <MedicationItem medicine={medicine} key={prescription.prescription_id + '-' + index} />
                        })
                    }
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}
class CurrentMedicationSection extends React.Component {
    state = {
        openPrescriptionId: null
    }
    handleOpenPrescription = (key) => {
        this.setState({ openPrescriptionId: key })
    }
    render() {
        return (
            <div className="row">
                <div className="col-24">
                    <div className="content-wrapper pb-4">
                        <h4 className="wrapper-heading">Current Medication Info</h4>
                        <Accordion className="medication-Info-wrapper" activeKey={this.state.openPrescriptionId} defaultActiveKey={this.state.openPrescriptionId}>
                            {
                                this.props.prescriptions && this.props.prescriptions.map((prescription) => {
                                    return <MedicationCard
                                        prescription={prescription}
                                        index={this.state.openPrescriptionId}
                                        handleOpenPrescription={this.handleOpenPrescription}
                                        key={prescription.prescription_id} />
                                })
                            }
                        </Accordion>
                    </div>
                </div>
            </div>
        )
    }
}

export default CurrentMedicationSection
