import React from 'react';

const ShowPathology = ({ pathology }) => {
    return (
        pathology &&
        <li>
            <div>Test Name: <span>{pathology.test_name} - {pathology.test_category}</span></div>
            <div className="no-border">Remark: <span>{pathology.remark}</span></div>
        </li>
    )
}
export default ShowPathology