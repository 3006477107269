import React from 'react';

export const Kidney = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <div className="heading">
            <h6>Kidney/Urine/Bladder</h6>
        </div>
        <div className="form-group full">
            <div className="options-details">
                <input type="checkbox" id='frequent-or-painfull-urination' name='frequent-or-painfull-urination' onChange={e => handleChange(e, 'kidney-urine-bladder')} checked={checkIfProblemExist('kidney-urine-bladder', 'frequent-or-painfull-urination') ? 'checked' : ''} />
                <label htmlFor='frequent-or-painfull-urination'>Frequent or painful urination</label>
                <input type="checkbox" id='blood-in-urine' name='blood-in-urine' onChange={e => handleChange(e, 'kidney-urine-bladder')} checked={checkIfProblemExist('kidney-urine-bladder', 'blood-in-urine') ? 'checked' : ''} />
                <label htmlFor='blood-in-urine'>Blood in urine</label>
            </div>
        </div>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment }) => {
    return <React.Fragment>
        <label className="heading-comment">Comment </label>
        <textarea className="form-control" name="comments" placeholder="Type here..." onChange={e => handleChange(e, 'kidney-urine-bladder')} value={getCategoryComment('kidney-urine-bladder') ? getCategoryComment('kidney-urine-bladder') : ''}></textarea>
    </React.Fragment>
}