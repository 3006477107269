import React from 'react';
import { connect } from 'react-redux';
import DashboardService from '../../services/dashboardService';
import _ from 'lodash';
import Notification from '../../utils/notification';
import ServiceProviderLocations from '../ServiceProviderLocations'
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import FulfilledOrderGraph from './FulfilledOrderGraph'
import { hasRole } from '../../utils/auth';
import Card from './Card';

class PharmacyDashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            filter: {
                user_location: "",
                start_date: "",
                end_date: "",
                status: 'fulfilled',
                order_fulfilled_group_by: 'week',
                repeat_customer_group_by: 'week',
                type: ''
            },
            order_graph_data: {
                x_axis_label: '',
                counts: ''
            },
            show_start_date: new Date(),
            show_end_date: new Date(),
            tags: {
                date_filter: "",
                location_filter: ""
            }
        }
    }

    componentDidMount() {
        if (!_.isEmpty(this.props.selected_user_profile)) {
            if (hasRole(this.props.selected_user_profile, ['pharmacy'])) {
                this.renderPharmacyAmountPayableInfo();
                this.interval = setInterval(this.renderPharmacyAmountPayableInfo, 60000);
            }
            
            this.handleGraph(this.state.filter);
        }
    }

    renderPharmacyAmountPayableInfo = () => {
        DashboardService.renderPharmacyCountInfo("amount-payables").then(resp => {
            this.setState({
                'dashboard_info': resp.data
            })
        }).catch(error => {
            Notification.show('error', error.response.data)
        });
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    handleEvent = (_event, picker) => {
        this.setState({
            filter: {
                start_date: (picker.startDate._d) ? moment(picker.startDate._d).format('YYYY-MM-DD') : '',
                end_date: (picker.endDate._d) ? moment(picker.endDate._d).format('YYYY-MM-DD') : '',
                status: 'fulfilled',
                user_location: this.state.filter.user_location,
                order_fulfilled_group_by: this.state.filter.order_fulfilled_group_by,
                repeat_customer_group_by: this.state.filter.repeat_customer_group_by,
                type: ''
            },
            show_start_date: picker.startDate._d,
            show_end_date: picker.endDate._d,
            tags: {
                date_filter: (picker.startDate._d && picker.endDate._d) ? moment(picker.startDate._d).format('DD-MM-YYYY') + ' to ' + moment(picker.endDate._d).format('DD-MM-YYYY') : '',
                location_filter: this.state.tags.location_filter
            },
        });
    }

    handleLocationFilter = (user_location_id, location_name = null) => {
        const filter = this.state.filter;
        filter.type = ''
        filter.user_location = user_location_id ? user_location_id : undefined;
        this.setState({
            filter: filter,
            tags: {
                date_filter: this.state.tags.date_filter,
                location_filter: location_name
            }
        });
    }

    handleSubmit = () => {
        window['searchToggle']();
        this.handleGraph(this.state.filter);
    }

    handleGraph = (filter) => {
        DashboardService.getPharmacyOrderGraphData("orders", filter).then(resp => {
            this.setState({
                order_graph_data: {
                    x_axis_label: resp.data.x_axis_label,
                    counts: resp.data.counts
                },
            })
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }

    resetSearch = () => {
        const filter = this.state.filter;
        filter.user_location = "";
        filter.start_date = "";
        filter.end_date = "";
        filter.type = "";
        this.setState({
            filter: filter,
            show_start_date: new Date(),
            show_end_date: new Date(),
            tags: {
                date_filter: "",
                location_filter: ""
            }
        });

        window['searchToggle']();
        this.handleGraph(filter)
    }

    removeFilter = (e) => {
        const filter = this.state.filter;
        const tags = this.state.tags;
        filter["status"] = 'fulfilled';
        filter.type = ''
        if (e.target.dataset.tag === 'date_filter') {
            filter["start_date"] = '';
            filter["end_date"] = '';
        } else {
            filter['user_location'] = '';
        }
        tags[e.target.dataset.tag] = '';
        this.setState({ filter: filter, tags: tags });
        this.handleGraph(filter)
    }

    handleOrderGraph = (type, view) => {
        const { filter } = this.state;
        filter.order_fulfilled_group_by = view;
        filter.type = type
        this.setState({
            filter: filter
        });
        this.handleGraph(filter)
    }

    renderTags(tags) {
        let options;
        return (Object.keys(tags)).map((tag) => {
            if (tags[tag] !== '' && !(_.isEmpty(tags[tag]))) {
                options = <span className="search-list">{tags[tag]} <span className='removesearch' data-tag={tag} onClick={this.removeFilter.bind(this)}> x </span> </span>
            } else {
                return false
            }
            return options
        })
    }

    render() {
        const { tags } = this.state
        return (
            <div className="row">
                {/* amount Payable count */}
                {hasRole(this.props.selected_user_profile, ['pharmacy']) && <div className="col-md-8">
                    <Card
                        label="Amount Payable"
                        count={this.state.dashboard_info ? this.state.dashboard_info.amountPayable : null}
                    />
                </div>}
                {/* filter box */}
                <div className="col-md-24">
                    <div className="filter-wrapper">
                        <div className="search-wrapper">
                            <label>Filter</label>
                            <div className="search-box clearfix">
                                {tags && this.renderTags(tags)}
                                <span className="search-box-icon"></span>
                                <div className="search-container">
                                    <ul>
                                        <li>
                                            <span>Select Date Range</span>
                                            <DateRangePicker
                                                startDate={this.state.show_start_date}
                                                onApply={this.handleEvent}
                                                endDate={this.state.show_end_date}
                                            >
                                                <input
                                                    type="text"
                                                    className="search-input"
                                                    name="start_date"
                                                    autoComplete="off"
                                                    value={this.state.filter.start_date ? moment(this.state.filter.start_date).format('DD-MM-YYYY') : ''}

                                                ></input>
                                                <input
                                                    type="text"
                                                    className="search-input"
                                                    name="end_date"
                                                    autoComplete="off"
                                                    value={this.state.filter.end_date ? moment(this.state.filter.end_date).format('DD-MM-YYYY') : ''}

                                                ></input>
                                            </DateRangePicker>
                                        </li>
                                        <li>
                                            <span>Select location</span>
                                            <ServiceProviderLocations
                                                service_provider_id={this.props.selected_user_profile.service_provider_id}
                                                handleLocationChange={this.handleLocationFilter}
                                                user_location_id={this.state.filter.user_location}
                                            />
                                        </li>
                                        <li>
                                            <button type="button" onClick={this.resetSearch} className="btn btn-secondary">Reset</button>
                                            <button type="button" onClick={this.handleSubmit} className="btn btn-primary">Search</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* No. of order fulfilled */}
                {this.state.order_graph_data && <div className="col-md-24"><FulfilledOrderGraph
                    filter={this.state.filter}
                    order_graph_data={this.state.order_graph_data}
                    renderOrderGraph={this.handleOrderGraph} /></div>}
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    selected_user_profile: state.selected_user_profile,
    dashboard_info: state.dashboard_info,
});

const mapActionsToProps = {
};

export default connect(mapStateToProps, mapActionsToProps)(PharmacyDashboard);