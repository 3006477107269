export const MenuItems = {
  'covid-center': [{
    id: "dashboard",
    title: "Dashboard",
    url: "/dashboard",
    icon_class: "icon dashboard",
    roles: ['covid-center-owner'],
    order: 1,
    data_tooltip: "Dashboard",
    data_index: 1,
  }, {
    id: "user-management",
    title: "User Management",
    url: "/user-management",
    icon_class: "icon user-management",
    roles: ['covid-center-owner'],
    order: 2,
    data_tooltip: "User Management",
    data_index: 2,
  }, {
    id: "data-management",
    title: "Data Management",
    url: "/data-management",
    icon_class: "icon user-management",
    roles: ['covid-center-owner'],
    order: 2,
    data_tooltip: "Data Management",
    data_index: 2,
  }, {
    id: "search-patient",
    title: "Search Patient",
    url: "/patients/search",
    icon_class: "icon patient",
    roles: ['covid-doctor', 'covid-nurse', 'covid-technician'],
    order: 3,
    data_tooltip: "Search Patient",
    data_index: 3,
  }, {
    id: "pending-report",
    title: "Pending Reports",
    url: "/covid-centers/pending-reports",
    icon_class: "icon reports",
    roles: ['covid-nurse', 'covid-technician', 'covid-doctor'],
    order: 4,
    data_tooltip: "Pending Reports",
    data_index: 4,
  }, {
    id: "completed-report",
    title: "Completed Reports",
    url: "/covid-centers/completed-reports",
    icon_class: "icon reports",
    roles: ['covid-nurse', 'covid-technician', 'covid-doctor'],
    order: 5,
    data_tooltip: "Completed Reports",
    data_index: 5,
  }, {
    id: "uploaded-report",
    title: "Uploaded Reports",
    url: "/covid-centers/uploaded-reports",
    icon_class: "icon reports",
    roles: ['covid-nurse', 'covid-technician', 'covid-doctor'],
    order: 6,
    data_tooltip: "Uploaded Reports",
    data_index: 6,
  }, {
    id: "covid-patients",
    title: "Patient's List",
    url: "/covid-centers/patients",
    icon_class: "icon reports",
    roles: ['covid-doctor'],
    order: 5,
    data_tooltip: "Patient's List",
    data_index: 5,
  }, {
    id: "covid-patient-queue",
    title: "Today's Queue",
    url: "/patient-queue",
    icon_class: "icon reports",
    roles: ['covid-doctor'],
    order: 5,
    data_tooltip: "Today's Queue",
    data_index: 5,
  }, {
    id: "payments",
    title: "Payment Details",
    url: "/covid-centers/payments",
    icon_class: "icon reports",
    roles: ['covid-nurse', 'covid-technician'],
    order: 6,
    data_tooltip: "Payment Details",
    data_index: 6,
  }, {
    id: "completed-report",
    title: "Test List",
    url: "/covid-centers/completed-reports",
    icon_class: "icon reports",
    roles: ['covid-center-owner'],
    order: 7,
    data_tooltip: "Test List",
    data_index: 7,
  }, {
    id: "activity-logs",
    title: "Staff Activity Log",
    url: "/activity-logs",
    icon_class: "icon staff-activity-log",
    roles: ['covid-center-owner'],
    order: 8,
    data_tooltip: "",
    data_index: 8,
  }, {
    id: "patient-details",
    title: "Patient Details",
    url: "/patient-details",
    icon_class: "icon patient",
    roles: ['covid-vendor'],
    order: 9,
    data_tooltip: "Patient Details",
    data_index: 9,
  },
  {
    id: "pre-registered-patient",
    title: "Pre Registered Patient",
    url: "/patient/pre-registered",
    icon_class: "icon prescription",
    roles: ["doctor", "associate-doctor", "doctor-nurse", "medical-practice-manager"],
    order: 10,
    data_tooltip: "Pre Registered Patient",
    data_index: 10,
  }
],
  'hospital': [{
    id: "user-management",
    title: "User Management",
    url: "/user-management",
    icon_class: "icon user-management",
    roles: ['hospital-admin'],
    order: 1,
    data_tooltip: "User Management",
    data_index: 1,
  }, {
    id: "search-patient",
    title: "Patient Queue",
    url: "/hospital/patients/assisted",
    icon_class: "icon patient",
    roles: ['paramedic-doctor'],
    order: 2,
    data_tooltip: "Patient Queue",
    data_index: 2,
  }, {
    id: "im-patient-list",
    title: "In-Patient List",
    url: "/hospital/patients/confirmed",
    icon_class: "icon prescription-queue",
    roles: ['paramedic-doctor'],
    order: 3,
    data_tooltip: "In-Patient List",
    data_index: 3,
  }, {
    id: "discharged-list",
    title: "Discharged List",
    url: "/hospital/patients/discharged",
    icon_class: "icon prescription-queue",
    roles: ['paramedic-doctor'],
    order: 4,
    data_tooltip: "Discharged List",
    data_index: 4,
  }, {
    id: "request-pending",
    title: "Pending Request",
    url: "/request-pending",
    icon_class: "icon request-pending",
    roles: ["paramedic-doctor"],
    order: 5,
    data_tooltip: "Pending Request",
    data_index: 5,
  }],
  'ambulance': [{
    id: "user-management",
    title: "User Management",
    url: "/user-management",
    icon_class: "icon user-management",
    roles: ["ambulance-admin"],
    order: 6,
    data_tooltip: "User Management",
    data_index: 6,
  }, {
    id: "search-patient",
    title: "Search Patient",
    url: "/ambulance/patients/search",
    icon_class: "icon patient",
    roles: ['ambulance-assistant'],
    order: 2,
    data_tooltip: "Search Patient",
    data_index: 2,
  }, {
    id: "patient-list",
    title: "Patient List",
    url: "/ambulance/records",
    icon_class: "icon prescription-queue",
    roles: ['ambulance-assistant'],
    order: 2,
    data_tooltip: "Patient List",
    data_index: 2,
  }],
  // pathology sidebar menu items
  pathology: [{
    id: "dashboard",
    title: "Dashboard",
    url: "/dashboard",
    icon_class: "icon dashboard",
    roles: ["pathology"],
    order: 1,
    data_tooltip: "Dashboard",
    data_index: 1,
  }, {
    id: "search-prescription",
    title: "Search Referral",
    url: "/patient/pathology-tests",
    icon_class: "icon search-prescription",
    roles: ["pathology-receptionist", "pathologist"],
    order: 2,
    data_tooltip: "Search Referral",
    data_index: 2,
  }, {
    id: "queue",
    title: "Today's Queue",
    url: "/queue",
    icon_class: "icon todays-queue",
    roles: ["pathologist", "pathology-receptionist"],
    order: 3,
    data_tooltip: "Today's Queue",
    data_index: 3,
  }, {
    id: "prescription-order",
    title: "Referral Orders",
    url: "/pathology/orders",
    icon_class: "icon prescription-order",
    roles: ["pathologist"],
    order: 4,
    data_tooltip: "Referral Orders",
    data_index: 4,
  }, {
    id: "commission-reports",
    title: "Reports",
    url: "/commission-reports",
    icon_class: "icon reports",
    roles: ["pathology"],
    order: 5,
    data_tooltip: "Reports",
    data_index: 5,
  }, {
    id: "user-management",
    title: "User Management",
    url: "/user-management",
    icon_class: "icon user-management",
    roles: ["pathology"],
    order: 6,
    data_tooltip: "User Management",
    data_index: 6,
  }, {
    id: "offline-rx",
    title: "Offline Rx",
    url: "/offline/prescriptions",
    icon_class: "icon offline-rx",
    roles: ["pathologist"],
    order: 7,
    data_tooltip: "Offline Rx",
    data_index: 7,
    is_active: false
  }, {
    id: "billing-and-accounting",
    title: "Billing and Accounting",
    url: "/service-providers/accounts",
    icon_class: "icon billing",
    roles: ["pathology"],
    order: 8,
    data_tooltip: "Billing and Accounting",
    data_index: 8,
  }, {
    id: "pathology-test-list",
    title: "Pathology Test List",
    url: "/pathology-test/prices",
    icon_class: "",
    roles: ["pathology"],
    order: 8,
    data_tooltip: "Pathology Test List",
    data_index: 9,
  }, {
    id: "activity-logs",
    title: "Staff Activity Log",
    url: "/activity-logs",
    icon_class: "icon staff-activity-log",
    roles: ["pathology"],
    order: 9,
    data_tooltip: "",
    data_index: 9,
  }, {
    id: "activity-logs",
    title: "Activity Log",
    url: "/activity-logs",
    icon_class: "icon staff-activity-log",
    roles: ["pathologist", "pathology-receptionist"],
    order: 9,
    data_tooltip: "",
    data_index: 9,
  }],
  // radiology sidebar menu items
  radiology: [{
    id: "dashboard",
    title: "Dashboard",
    url: "/dashboard",
    icon_class: "icon dashboard",
    roles: ["radiology"],
    order: 1,
    data_tooltip: "Dashboard",
    data_index: 1,
  }, {
    id: "search-prescription",
    title: "Search Referral",
    url: "/patient/radiology-tests",
    icon_class: "icon search-prescription",
    roles: ["radiology-receptionist", "radiologist"],
    order: 2,
    data_tooltip: "Search Referral",
    data_index: 2,
  }, {
    id: "queue",
    title: "Today's Queue",
    url: "/queue",
    icon_class: "icon todays-queue",
    roles: ["radiology-receptionist", "radiologist"],
    order: 3,
    data_tooltip: "Today's Queue",
    data_index: 3,
  }, {
    id: "prescription-order",
    title: "Referral Orders",
    url: "/radiology/orders",
    icon_class: "icon prescription-order",
    roles: ["radiologist"],
    order: 4,
    data_tooltip: "Referral Orders",
    data_index: 4,
  }, {
    id: "commission-reports",
    title: "Reports",
    url: "/commission-reports",
    icon_class: "icon reports",
    roles: ["radiology"],
    order: 5,
    data_tooltip: "Reports",
    data_index: 5,
  }, {
    id: "user-management",
    title: "User Management",
    url: "/user-management",
    icon_class: "icon user-management",
    roles: ["radiology"],
    order: 6,
    data_tooltip: "User Management",
    data_index: 6,
  }, {
    id: "offline-rx",
    title: "Offline Rx",
    url: "/offline/prescriptions",
    icon_class: "icon offline-rx",
    roles: ["radiologist"],
    order: 7,
    data_tooltip: "Offline Rx",
    data_index: 7,
    is_active: false
  }, {
    id: "billing-and-accounting",
    title: "Billing and Accounting",
    url: "/service-providers/accounts",
    icon_class: "icon billing",
    roles: ["radiology"],
    order: 8,
    data_tooltip: "Billing and Accounting",
    data_index: 8,
  }, {
    id: "radiology-test-list",
    title: "Radiology Test List",
    url: "/radiology-test/prices",
    icon_class: "",
    roles: ["radiology"],
    order: 8,
    data_tooltip: "Radiology Test List",
    data_index: 9,
  }, {
    id: "activity-logs",
    title: "Staff Activity Log",
    url: "/activity-logs",
    icon_class: "icon staff-activity-log",
    roles: ["radiology"],
    order: 9,
    data_tooltip: "",
    data_index: 9,
  }, {
    id: "activity-logs",
    title: "Activity Log",
    url: "/activity-logs",
    icon_class: "icon staff-activity-log",
    roles: ["radiologist", "radiology-receptionist"],
    order: 9,
    data_tooltip: "",
    data_index: 9,
  }],
  // doctor sidebar menu item
  doctor: [{
    id: "dashboard",
    title: "Dashboard",
    url: "/dashboard",
    icon_class: "icon dashboard",
    roles: [
      "doctor", "associate-doctor", "doctor-nurse", "doctor-receptionist"
    ],
    order: 1,
    data_tooltip: "Dashboard",
    data_index: 1,
  }, {
    id: "dashboard",
    title: "Practice Dashboard",
    url: "/dashboard",
    icon_class: "icon dashboard",
    roles: [
      "medical-practice-manager"
    ],
    order: 1,
    data_tooltip: "Dashboard",
    data_index: 1,
  }, {
    id: "search-patient",
    title: "Search Patient",
    url: "/patients/search",
    icon_class: "icon patient",
    roles: [
      "doctor",
      "associate-doctor",
      "doctor-receptionist",
      "doctor-nurse",
      "junior-doctor",
      "medical-practice-manager"
    ],
    order: 2,
    data_tooltip: "Search Patient",
    data_index: 2,
  }, {
    id: "queue",
    title: "Today's Queue",
    url: "/doctor-queues",
    icon_class: "icon todays-queue",
    roles: [
      "doctor",
      "associate-doctor",
      "doctor-receptionist",
      "doctor-nurse",
      "junior-doctor",
      "medical-practice-manager"
    ],
    order: 3,
    data_tooltip: "Todays Queue",
    data_index: 3,
  }, {
    id: "medicines",
    title: "Medication",
    url: "/medicines",
    icon_class: "icon medicine",
    roles: ["doctor", "associate-doctor"],
    order: 4,
    data_tooltip: "Medication",
    data_index: 4,
  }, {
    id: "daily-patient-list",
    title: "Daily Patient List",
    url: "/patient/daily-list",
    icon_class: "icon todays-queue",
    roles: ["doctor", "doctor-receptionist", "doctor-nurse", "medical-practice-manager"],
    order: 4,
    data_tooltip: "Daily Patient List",
    data_index: 4,
  }, {
    id: "prescriptions",
    title: "Encounter",
    url: "/prescriptions",
    icon_class: "icon prescription",
    roles: ["doctor", "associate-doctor"],
    order: 5,
    data_tooltip: "Encounter",
    data_index: 5,
  }, {
    id: "pending_signature",
    title: "Unsigned Encounters",
    url: "/prescriptions/pending-signature",
    icon_class: "icon unsigned-prescription",
    roles: ["doctor", "associate-doctor"],
    order: 5,
    data_tooltip: "Unsigned Encounters",
    data_index: 5,
  }, {
    id: "prescription-queue",
    title: "Patient Queue",
    url: "/prescriptions",
    icon_class: "icon prescription-queue",
    roles: ["doctor-nurse", "junior-doctor"],
    order: 5,
    data_tooltip: "Patient Queue",
    data_index: 11,
  }, {
    id: "commission-reports",
    title: "Reports",
    url: "/commission-reports",
    icon_class: "icon reports",
    roles: ["doctor", "associate-doctor"],
    order: 5,
    data_tooltip: "Reports",
    data_index: 6,
  }, {
    id: "user-management",
    title: "User Management",
    url: "/user-management",
    icon_class: "icon user-management",
    roles: ["doctor", "medical-practice-manager"],
    order: 6,
    data_tooltip: "User Management",
    data_index: 7,
  }, {
    id: "approve-prescription",
    title: "Approve Encounter",
    url: "/approve-prescription",
    icon_class: "icon approve-prescription",
    roles: ["doctor", "associate-doctor",],
    order: 6,
    data_tooltip: "Approve Encounter",
    data_index: 8,
  }, {
    id: "request-pending",
    title: "Pending Request",
    url: "/request-pending",
    icon_class: "icon request-pending",
    roles: ["doctor", "associate-doctor"],
    order: 6,
    data_tooltip: "Pending Request",
    data_index: 9,
  }, {
    id: "patient-card-reissue",
    title: "Patient Card Re-Issue",
    url: "/reissue-cards",
    icon_class: "icon card-reissue",
    roles: ["doctor", "doctor-receptionist", "doctor-nurse", "associate-doctor"],
    order: 6,
    data_tooltip: "Patient Card Re-Issue",
    data_index: 10,
  }, {
    id: "billing-and-accounting",
    title: "Billing and Accounting",
    url: "/service-providers/accounts",
    icon_class: "icon billing",
    roles: ["doctor", "associate-doctor", "medical-practice-manager"],
    order: 6,
    data_tooltip: "Billing and Accounting",
    data_index: 11,
  }, {
    id: "consultation-fee",
    title: "Consultation Fee/Charges",
    url: "/prescriptions/fees",
    icon_class: "icon consultation",
    roles: ["doctor", "associate-doctor", "doctor-receptionist", "doctor-nurse", "medical-practice-manager"],
    order: 6,
    data_tooltip: "Consultation Fee/Charges",
    data_index: 12,
  }, {
    id: "activity-logs",
    title: "Staff Activity Log",
    url: "/activity-logs",
    icon_class: "icon staff-activity-log",
    roles: ["doctor", "associate-doctor", "medical-practice-manager"],
    order: 9,
    data_tooltip: "",
    data_index: 13,
  }, {
    id: "activity-logs",
    title: "Activity Log",
    url: "/activity-logs",
    icon_class: "icon staff-activity-log",
    roles: ["doctor-receptionist", "doctor-nurse", "junior-doctor"],
    order: 9,
    data_tooltip: "",
    data_index: 13,
  }, {
    id: "immunization-queue",
    title: "Immunization Queue",
    url: "/immunization-queues",
    icon_class: "icon immunization-queue",
    roles: ["doctor",],
    order: 10,
    data_tooltip: "Immunization Queue",
    data_index: 14,
  }, {
    id: "appointment-management",
    title: "Manage Appointment",
    url: "/appointments",
    icon_class: "icon appointment-management",
    roles: ["doctor", "associate-doctor", "doctor-receptionist", "doctor-nurse", "medical-practice-manager"],
    order: 10,
    data_tooltip: "Manage Appointment",
    data_index: 14,
  }, {
    id: "historic-data",
    title: "Upload Historic Data",
    url: "/historical-data",
    icon_class: "icon staff-activity-log",
    roles: ["doctor", "associate-doctor"],
    order: 9,
    data_tooltip: "",
    data_index: 13,
  }, {
    id: "draft-prescriptions",
    title: "Draft Encounters",
    url: "/prescriptions/draft",
    icon_class: "icon prescription",
    roles: ["doctor", "associate-doctor"],
    order: 10,
    data_tooltip: "Draft Encounters",
    data_index: 14,
  },
  // {
  //   id: "urgent-care",
  //   title: "Urgent Care",
  //   url: "/urgent-care",
  //   icon_class: "icon prescription",
  //   roles: ["doctor", "associate-doctor", "doctor-nurse", "junior-doctor"],
  //   order: 10,
  //   data_tooltip: "Urgent Care",
  //   data_index: 15,
  // },Confirm Patient edit
  {
    id: "urgent-care",
    title: "Urgent Care",
    url: "/urgent-care",
    icon_class: "icon prescription",
    roles: ["doctor", "associate-doctor", "doctor-nurse", "junior-doctor"],
    order: 10,
    data_tooltip: "Urgent Care",
    data_index: 15,
  },
   {
    id: "procedure-list",
    title: "Procedure",
    url: "/procedure",
    icon_class: "icon prescription",
    roles: ["doctor", "associate-doctor", "doctor-nurse", "medical-practice-manager"],
    order: 12,
    data_tooltip: "Procedure",
    data_index: 16,
  },
  {
    id: "confirm-patient-edit-list",
    title: "Confirm Patient Edit",
    url: "/patient-confirm-edit",
    icon_class: "icon prescription",
    roles: ["doctor", "associate-doctor"],
    order: 14,
    data_tooltip: "Confirm Patient Edit",
    data_index: 18,
  },
  {
    id: "pre-registered-patient",
    title: "Pre Registered Patient",
    url: "/patient/pre-registered",
    icon_class: "icon prescription",
    roles: ["doctor", "associate-doctor", "doctor-nurse", "medical-practice-manager"],
    order: 13,
    data_tooltip: "Pre Registered Patient",
    data_index: 17,
  }
],
  // pharmacy sidebar menu item
  pharmacy: [{
    id: "dashboard",
    title: "Dashboard",
    url: "/dashboard",
    icon_class: "icon dashboard",
    roles: ["pharmacy", 'pharmacy-manager'],
    order: 1,
    data_tooltip: "This section will display detailed at-a-glance insights and performance of all prescriptions and commissions",
    data_index: 1,
  }, {
    id: "search-prescription",
    title: "Prescription Search",
    url: "/pharmacy/prescriptions/search",
    icon_class: "icon search-prescription",
    roles: ["pharmacist", "pharmacy-technician"],
    order: 2,
    data_tooltip: "You can search prescription in this section",
    data_index: 2,
  }, {
    id: "prescription-queue",
    title: "Prescription Queue",
    url: "/prescription/orders/queue",
    icon_class: "icon prescription-queue",
    roles: ["pharmacist"],
    order: 4,
    data_tooltip: "In this section, the pharmacist can view the list of prescription assigned to a technician for fulfilling prescription.",
    data_index: 4,
  }, {
    id: "commission- reports",
    title: "Reports",
    url: "/commission-reports",
    icon_class: "icon reports",
    roles: ["pharmacy"],
    order: 5,
    data_tooltip: "Reports",
    data_index: 7,
  }, {
    id: "user-management",
    title: "User Management",
    url: "/user-management",
    icon_class: "icon user-management",
    roles: ["pharmacy", "pharmacy-manager"],
    order: 6,
    data_tooltip: "User Management",
    data_index: 8,
  }, {
    id: "assigned-prescription",
    title: "Assigned Prescription",
    url: "/pharmacy/technician/prescription/list",
    icon_class: "icon patient",
    roles: ["pharmacy-technician"],
    order: 5,
    data_tooltip: "This button will assign the prescription to a technician for fulfilment",
    data_index: 9,
  }, {
    id: "prescription-rx",
    title: "Dispensed Orders",
    url: "/prescription/orders/list",
    icon_class: "icon file",
    roles: ["pharmacy", "pharmacist", "pharmacy-technician", 'pharmacy-manager'],
    order: 5,
    data_tooltip: "This section displays a list of all the prescription order with respect to the location",
    data_index: 5,
  }, {
    id: "offline-rx",
    title: "Offline Rx",
    url: "/offline/prescriptions",
    icon_class: "icon offline-rx",
    roles: ["pharmacist"],
    order: 6,
    data_tooltip: "Offline Rx",
    data_index: 7,
    is_active: false
  }, {
    id: "billing-and-accounting",
    title: "Billing and Accounting",
    url: "/service-providers/accounts",
    icon_class: "icon billing",
    roles: ["pharmacy"],
    order: 7,
    data_tooltip: "Billing and Accounting",
    data_index: 7,
  }, {
    id: "drug-list",
    title: "Drug List",
    url: "/drugs/prices",
    icon_class: "",
    roles: ["pharmacy", 'pharmacy-manager'],
    order: 7,
    data_tooltip: "Drug List",
    data_index: 8,
  }, {
    id: "returned-orders",
    title: "Returned Orders",
    url: "/orders/returns",
    icon_class: "",
    roles: ["pharmacy", "pharmacist", 'pharmacy-manager'],
    order: 8,
    data_tooltip: "This section displays a list of all the orders",
    data_index: 8,
  }, {
    id: "activity-logs",
    title: "Staff Activity Log",
    url: "/activity-logs",
    icon_class: "icon staff-activity-log",
    roles: ["pharmacy", 'pharmacy-manager'],
    order: 9,
    data_tooltip: "",
    data_index: 9,
  }, {
    id: "activity-logs",
    title: "Activity Log",
    url: "/activity-logs",
    icon_class: "icon staff-activity-log",
    roles: ["pharmacy-technician", "pharmacist"],
    order: 9,
    data_tooltip: "",
    data_index: 9,
  },
  // {
  //   id: "signature-pending-modified-prescription",
  //   title: "Unsigned Modified Prescription",
  //   url: "/modified-prescriptions/pending-signature",
  //   icon_class: "icon unsigned-prescription",
  //   roles: ["pharmacist"],
  //   order: 10,
  //   data_tooltip: "",
  //   data_index: 10,
  // }, 
  {
    id: "signature-pending",
    title: "Unsigned Rx",
    url: "/orders/pending-signature",
    icon_class: "icon unsigned-order",
    roles: ["pharmacist"],
    order: 11,
    data_tooltip: "",
    data_index: 11,
  },
  {
    id: "pharmacist-draft-prescription",
    title: "Rx Draft",
    url: "/pharmacy/draft/prescriptions",
    icon_class: "icon prescription",
    roles: ["pharmacist"],
    order: 12,
    data_tooltip: "In this section, the pharmacist can view the list of prescribed prescription.",
    data_index: 12,
  },
  {
    id: "pharmacist-prescription",
    title: "Emergency Prescriptions",
    url: "/pharmacy/prescriptions",
    icon_class: "icon prescription-queue",
    roles: ["pharmacist"],
    order: 12,
    data_tooltip: "In this section, the pharmacist can view the list of fulfilled prescription.",
    data_index: 12,
  },
  {
    id: "order-reminders",
    title: "Reminder List",
    url: "/reminders",
    icon_class: "icon prescription-queue",
    roles: ["pharmacist"],
    order: 12,
    data_tooltip: "In this section, the pharmacist can view the list of fulfilled prescription.",
    data_index: 12,
  },],
  'airline': [{
    id: "user-management",
    title: "User Management",
    url: "/user-management",
    icon_class: "icon user-management",
    roles: ['airline-owner'],
    order: 1,
    data_tooltip: "User Management",
    data_index: 1,
  }, {
    id: "search-record",
    title: "Search Record",
    url: "/authority-access/patients/search",
    icon_class: "icon patient",
    roles: ['airport-assistant'],
    order: 2,
    data_tooltip: "Search Record",
    data_index: 2,
  }, {
    id: "past-scan-record",
    title: "Past Scan Record",
    url: "/authority-access/past-scan-records",
    icon_class: "icon staff-activity-log",
    roles: ['airline-owner', 'airport-assistant'],
    order: 3,
    data_tooltip: "Past Scan Record",
    data_index: 3,
  }, {
    id: "activity-logs",
    title: "Staff Activity Log",
    url: "/activity-logs",
    icon_class: "icon staff-activity-log",
    roles: ['airline-owner'],
    order: 4,
    data_tooltip: "",
    data_index: 4,
  }]
};
