import React from 'react';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import * as AuthAction from '../../actions/authAction';
import queryString from 'query-string';
import Loader from '../../components/Loader';
import { Helmet } from 'react-helmet';
import ReCAPTCHABlock from "../../components/ReCAPTCHABlock";

class NewPasswordPage extends React.Component {
    constructor(props) {
        super(props);

        const queryParams = queryString.parse(this.props.location.search)

        this.state = {
            email: queryParams.email,
            token: queryParams.token,
            password: '',
            password_confirmation: '',
            agree: ''
        };

        /* if (!this.state.username || !this.state.token) {
            this.props.history.push('/login');
        } */

        this.recaptchaRef = React.createRef();
        this.validator = new SimpleReactValidator();
        this.handleChange = this.handleChange.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleCheckChange(e) {
        if (e.target.checked) {
            this.setState({ [e.target.name]: e.target.value });
        } else {
            this.setState({ [e.target.name]: '' });
        }
    }

    verifySubmit = (evt) => {
        evt.preventDefault();
        this.recaptchaRef.execute();
    }

    verifiedSubmit = () => {
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        this.props.newPassword(this.state.email, this.state.token, this.state.password, this.state.password_confirmation, this.props.history);
        this.setState({
            password: '',
            password_confirmation: ''
        });
        this.validator.hideMessages();
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Set New Password</title>
                </Helmet>
                <ReCAPTCHABlock ref={el => this.recaptchaRef = el} onSubmit={this.verifiedSubmit} />
                <div className="container login-register-wrapper">
                    <div className="row justify-content-md-center">
                        <div className="col-lg-8 col-md-16 col-sm-24">
                            <img src="/images/logo1.png" className="logo" alt="" />
                            <h4>Welcome</h4>
                            <p>Enter your new password</p>
                            <Loader />
                            <form onSubmit={this.verifySubmit}>
                                <div className="form-group input-tooltip">
                                    <input type="password" className="form-control" placeholder="Password" id="password" name="password" value={this.state.password} onChange={this.handleChange} autoComplete="new-password" />
                                    {this.validator.message('password', this.state.password, 'required')}
                                    <span className="tooltip-custom">
                                        <i className="fa fa-info-circle" aria-hidden="true"></i>
                                        <span className="tooltiptext">
                                            Choose a password between 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character.
                                    </span>
                                    </span>
                                </div>
                                <div className="form-group">
                                    <input type="password" className="form-control" placeholder="Confirm Password" id="password_confirmation" name="password_confirmation" value={this.state.password_confirmation} onChange={this.handleChange} autoComplete="new-password" />
                                    {this.validator.message('password_confirmation', this.state.password_confirmation, 'required')}
                                </div>
                                <div className="form-group agree">
                                    <input type="checkbox" id="agree" name="agree" value="agree" onChange={this.handleCheckChange} />
                                    <label htmlFor="agree">I agree to the <a href="terms.html" target="_blank">terms & conditions</a></label>
                                    {this.validator.message('agree', this.state.agree, 'required')}
                                </div>
                                <div className="form-group clearfix">
                                    <button type="submit" className="btn btn-primary float-left">Set Password</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    alert: state.alert
});

const mapActionsToProps = ({
    newPassword: AuthAction.newPassword
})

export default connect(mapStateToProps, mapActionsToProps)(NewPasswordPage);