import React from 'react';
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from 'moment';
import _ from 'lodash';
import { hasRole } from '../../utils/auth';

class SearchFilter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: {
                start_date: null,
                end_date: null,
                user_name: null,
                patient_name: null
            },
            tags: {
                date_range: null,
                user_name: null,
                patient_name: null
            }
        }
    }

    removeFilter = (e) => {
        if (e.target.dataset.tag === 'date_range') {
            this.setState({
                filters: {
                    ...this.state.filters,
                    start_date: null,
                    end_date: null
                },
                tags: {
                    ...this.state.tags,
                    date_range: null
                }
            }, () => {
                this.props.handleSubmit(this.state.filters)
            })
        } else {
            this.setState({
                filters: {
                    ...this.state.filters,
                    [e.target.dataset.tag]: null
                },
                tags: {
                    ...this.state.tags,
                    [e.target.dataset.tag]: null
                }
            }, () => {
                this.props.handleSubmit(this.state.filters)
            })
        }
    }

    handleEvent = (_event, picker) => {
        this.setState({
            filters: {
                ...this.state.filters,
                start_date: (picker.startDate._d) ? moment(picker.startDate._d).format('YYYY-MM-DD') : null,
                end_date: (picker.endDate._d) ? moment(picker.endDate._d).format('YYYY-MM-DD') : null
            },
            tags: {
                ...this.state.tags,
                date_range: (picker.startDate._d && picker.endDate._d) ? moment(picker.startDate._d).format('DD-MM-YYYY') + ' to ' + moment(picker.endDate._d).format('DD-MM-YYYY') : ''
            }
        })

    }

    handleChange = (e) => {
        this.setState({
            filters: {
                ...this.state.filters,
                [e.target.name]: e.target.value,
            },
            tags: {
                ...this.state.tags,
                [e.target.name]: e.target.value,
            }
        })
    }

    clickSearch = () => {
        this.props.handleSubmit(this.state.filters)
    }

    clickReset = () => {
        this.setState({
            filters: {
                start_date: null,
                end_date: null,
                user_name: null,
                patient_name: null
            },
            tags: {
                date_range: null,
                user_name: null,
                patient_name: null
            }
        }, () => {
            this.props.handleSubmit(this.state.filters)
        })
    }

    render() {
        return (
            <div className="search-wrapper">
                <label>Search</label>
                <div className="search-box clearfix">
                    {(Object.keys(this.state.tags)).map((tag, index) => {
                        if (this.state.tags[tag] !== '' && !(_.isEmpty(this.state.tags[tag]))) {
                            return <span className="search-list" key={index}>{this.state.tags[tag]} <span className='removesearch' data-tag={tag} onClick={this.removeFilter.bind(this)}> x </span> </span>
                        }
                        return '';
                    })}
                    <span className="search-box-icon"></span>

                    <div className="search-container">
                        <ul>
                            <li>
                                <span>Select Date</span>
                                <DateRangePicker
                                    startDate={this.state.filters.start_date ? moment(this.state.filters.start_date) : new Date()}
                                    onApply={this.handleEvent}
                                    endDate={this.state.filters.end_date ? moment(this.state.filters.end_date) : new Date()}
                                    maxDate={moment()}
                                >
                                    <input
                                        type="text"
                                        className="search-input"
                                        name="start_date"
                                        autoComplete="off"
                                        defaultValue={this.state.filters.start_date ? moment(this.state.filters.start_date).format('DD-MM-YYYY') : ''}

                                    ></input>
                                    <input
                                        type="text"
                                        className="search-input"
                                        name="end_date"
                                        autoComplete="off"
                                        defaultValue={this.state.filters.end_date ? moment(this.state.filters.end_date).format('DD-MM-YYYY') : ''}
                                    ></input>
                                </DateRangePicker>
                            </li>
                            {hasRole(this.props.selected_profile, ['airline-owner']) && <li>
                                <span>Assistant Name</span>
                                <input type="text" value={this.state.filters.user_name ? this.state.filters.user_name : ''} onChange={this.handleChange} name="user_name"></input>
                            </li>}
                            <li>
                                <span>Patient Name</span>
                                <input type="text" value={this.state.filters.patient_name ? this.state.filters.patient_name : ''} onChange={this.handleChange} name="patient_name"></input>
                            </li>
                            <li>
                                <button type="button" onClick={this.clickReset} className="btn btn-secondary">Reset</button>
                                <button type="submit" onClick={this.clickSearch} className="btn btn-primary">Search</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>)
    }
}

export default SearchFilter;