export const immunizationsData =
    [
        {
            age_label: 'Birth - 6 Weeks',
            age_slug: 'birth-6-weeks',
            vaccines:
                [{
                    vaccine_label: 'BCG',
                    vaccine_slug: 'bcg',
                },]
        }, {
            age_label: '6 Weeks',
            age_slug: '6-weeks',
            vaccines: [{
                vaccine_label: 'OPV/IPV',
                vaccine_slug: 'opv-ipv',
            }, {
                vaccine_label: 'DPT/DT',
                vaccine_slug: 'dpt-dt',
            }, {
                vaccine_label: 'HepB',
                vaccine_slug: 'hepb',
            }, {
                vaccine_label: 'Hib',
                vaccine_slug: 'hib',
            }]
        }, {
            age_label: '3 Months',
            age_slug: '3-months',
            vaccines: [{
                vaccine_label: 'OPV/IPV',
                vaccine_slug: 'opv-ipv',
            }, {
                vaccine_label: 'DPT/DT',
                vaccine_slug: 'dpt-dt',
                note: 'Second dose of',
            }, {
                vaccine_label: 'HepB',
                vaccine_slug: 'hepb',
            }, {
                vaccine_label: 'Hib',
                vaccine_slug: 'hib',
            },]
        }, {
            age_label: '5 - 6 Months',
            age_slug: '5-6-months',
            vaccines: [{
                vaccine_label: 'OPV/IPV',
                vaccine_slug: 'opv-ipv',
            }, {
                vaccine_label: 'DPT/DT',
                vaccine_slug: 'dpt-dt',
                note: 'Third dose of',
            }, {
                vaccine_label: 'HepB',
                vaccine_slug: 'hepb',
            }, {
                vaccine_label: 'Hib',
                vaccine_slug: 'hib',
            },]
        }, {
            age_label: '12 Months',
            age_slug: '12-months',
            vaccines: [{
                vaccine_label: 'MMR',
                vaccine_slug: 'mmr',
            },]
        }, {
            age_label: '18 Months',
            age_slug: '18-months',
            vaccines: [{
                vaccine_label: 'DPT/DT',
                vaccine_slug: 'dpt-dt',
            }, {
                vaccine_label: 'OPV/IPV',
                vaccine_slug: 'opv-ipv',
                note: 'Booster doses of',
            },]
        }, {
            age_label: '4 - 6 Years',
            age_slug: '4-6-years',
            vaccines: [{
                vaccine_label: 'DPT/DT',
                vaccine_slug: 'dpt-dt',
            }, {
                vaccine_label: 'OPV/IPV',
                vaccine_slug: 'opv-ipv',
                note: 'Booster doses of',
            }, {
                vaccine_label: 'MMR',
                vaccine_slug: 'mmr',
            },]
        }, {
            age_label: '10 - 11 Years',
            age_slug: '10-11-years',
            vaccines: [{
                vaccine_label: 'Td or Adult DT',
                vaccine_slug: 'td-or-adult-dt',
            },]
        }
    ];

export const otherImmunization = [
    {
        age_label: 'COVID-19',
        age_slug: 'covid-19',
        vaccines: [{
            vaccine_label: 'COVID-19 Dose 1',
            vaccine_slug: 'covid-19-dose-1',
        }, {
            vaccine_label: 'COVID-19 Dose 2',
            vaccine_slug: 'covid-19-dose-2',
        }]
    }
]