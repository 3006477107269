import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Form, Row, Col } from 'react-bootstrap';
import Notification from '../utils/notification';
import SimpleReactValidator from 'simple-react-validator';
import DocketNumberService from '../services/docketNumberService';
import * as loaderAction from '../actions/loaderAction';
import { connect } from 'react-redux';

class DocketNumberModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {            
            name: "",
            buttonText: "Add",
            isSubmitDisabled: true,
            show: false,
            isloading: false,
            docketNumber: "",
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validator = new SimpleReactValidator();
    }
    componentDidMount () {
        this.setDocket(this.props);
    }
    componentDidUpdate(prevProps) {
        if (this.props.patient != prevProps.patient) {
            this.setDocket(this.props)
        }
    }
    setDocket = (props) => {
        this.props.showLoader()
        let docket_number = props.patient.docket_number
        this.setState({  
            docketNumber: docket_number ? docket_number.docket_number : "",
            buttonText: props.buttonText ? props.buttonText : "Add",
            isSubmitDisabled: true,
            show: props.show ? props.show : false,
        });
        this.props.hideLoader()
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            isSubmitDisabled: false
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        const data = {
            patient_id: this.props.patient ? this.props.patient.patient_id : null,
            docket_number: this.state.docketNumber ? this.state.docketNumber : null,
            id: this.props.patient.docket_number ? this.props.patient.docket_number.id : null,
        }
        this.setState({
            isloading: true
        });
        this.props.showLoader()
        if(this.state.buttonText === "Update") {
            let message = 'Docket Number Updated';

            DocketNumberService.update(this.props.patient.docket_number.id, data).then(resp => {
                Notification.show('success', { message: message });
                this.props.onSuccess(resp.data);
                this.clearState()
                this.props.hideLoader()
            }).catch(error => {
                this.setState({
                    isloading: false,
                    buttonText: 'Update'
                });
                Notification.show('error', error.response.data)
                this.props.hideLoader()
            })
        } else {
            let message = 'Docket Number Added';
            DocketNumberService.create(data).then(resp => {
                Notification.show('success', { message: message });
                this.props.onSuccess(resp.data);
                this.clearState()
                this.props.hideLoader()
            }).catch(error => {
                this.setState({
                    isloading: false,
                    buttonText: 'Submit'
                });
                Notification.show('error', error.response.data)
                this.props.hideLoader()
            })
        }
    }

    handleClose = () => {
        this.props.handleClose()
        this.validator.hideMessages();
    }

    clearState = () => {
        this.setState({
            docketNumber: "",
            isloading: false,
        })
        this.handleClose()
    }
    render() {
        return (
            <React.Fragment>
                {this.state.isloading && this.state.isloading && <div className="loading">Loading</div>}
                <Modal
                    size='md' centered aria-labelledby="contained-modal-title-vcenter"
                    show={this.props.show}
                    onHide={this.handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <h6>{this.state.buttonText === "Update" ? "Update" : "Add"} Docket Number</h6>
                    </Modal.Header>
                    <Modal.Body>
                            <Row>
                                <Col md={24}>
                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                        <Form.Label column sm={8}>
                                        Docket Number: *
                                        </Form.Label>
                                        <Col sm={16}>
                                        <Form.Control type="text" placeholder="Docket Number" name="docketNumber" value={this.state.docketNumber} onChange={this.handleChange}/>
                                        {this.validator.message('Docket Number', this.state.docketNumber, 'required|alpha_num|max:10')}
                                        </Col>
                                    </Form.Group>
                                </Col>
                            </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Cancel
                        </Button>
                        <Button variant="primary" disabled={this.state.isSubmitDisabled} onClick={this.handleSubmit}>
                            {this.state.buttonText}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        )
    }
}

// export default DocketNumberModal

const mapActionsToProps = {
    showLoader: loaderAction.loaderShow,
    hideLoader: loaderAction.loaderHide
};

export default connect(null, mapActionsToProps)(DocketNumberModal);