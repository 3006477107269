import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function (state = initialState.pagination, { type, ...payload }) {
    switch (type) {
        case types.PENDING_CARD_LIST:
            return {
                ...state,
                ...payload.data
            };
        case types.HISTORY_LIST:
            return {
                ...state,
                ...payload
            };
        default:
            return state;
    }
}