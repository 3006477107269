import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import PathoRadioService from '../../services/pathoRadioService';
import { has, isEmpty, groupBy } from 'lodash';
import Notification from '../../utils/notification';
import { hasRole } from "../../utils/auth";
import { Link } from "react-router-dom";
import PrintButton from '../../components/PrintButton';
import queryString from 'query-string';
import PrintPrescriptionPage from './PrintPrescriptionPage';
import * as QueueAction from '../../actions/queueAction';
import PatientInfo from '../../components/PatientInfo';
import DoctorInfo from '../../components/DoctorInfo';
import * as LoaderAction from "../../actions/loaderAction";
import * as ShowCptIcd from '../../components/ShowCptIcd';

class AddToQueuePathology extends React.Component {

    constructor(props) {
        super(props);
        const { match: { params } } = this.props;
        const prescriptionId = params.prescriptionId;
        const queryParams = queryString.parse(this.props.location.search)
        this.state = {
            data: '',
            prescriptionId: prescriptionId,
            isLoading: false,
            isChecked: false,
            autoPrint: has(queryParams, 'print') ? true : false,
            prescription_icd_data: []
        }
        this.handleCheckedData = this.handleCheckedData.bind(this)
    }

    componentWillMount() {
        PathoRadioService.showPathologyTestsByPrescriptionId(this.state.prescriptionId, { log: true }).then(resp => {
            this.setState({
                data: resp.data
            });
            if (resp.data.prescription_cpt_icd) {
                const prescription_icd_data = groupBy(resp.data.prescription_cpt_icd, 'icd_code')
                let modified = [];

                Object.keys(prescription_icd_data).forEach((key) => {
                    prescription_icd_data[key].forEach((icd, _index) => (
                        modified.push({
                            selectedIcd: {
                                icd_code: icd.icd_code,
                                full_description: icd.icd_description
                            },
                            icdCrosswalks: [{
                                cpt_code: icd.cpt_code,
                                full_description: icd.cpt_description
                            }]
                        })
                    ))
                })

                this.setState({
                    prescription_icd_data: modified,
                })
            }
        }).catch(error => {
            Notification.show('error', error.response)
        })
    }

    getTodaysDate = () => {
        var today = new Date(),
            date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        return date;
    }

    getCurrentTime() {
        var today = new Date();
        const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        return time
    }

    addToQueue = () => {
        const { selected_profile } = this.props
        const queueData = {
            patient_id: this.state.data.patient_id,
            appointment_type: 'queue',
            service_provider_id: selected_profile.service_provider_id,
            user_location_id: selected_profile.user_location_id,
            user_profile_id: selected_profile.user_profile_id,
            prescription_id: this.state.prescriptionId,
        };
        this.props.loaderShow();
        PathoRadioService.addToQueue(queueData).then(resp => {
            this.props.loaderHide();
            Notification.show("success", resp.data);
            this.props.history.push('/queue');
        }).catch(error => {
            this.props.loaderHide();
            Notification.show('error', error.response.data);
        })
    }

    handleCheckedData = (index) => {
        const { data } = this.state
        const options = data.prescription_pathology_tests.pathology_test_items.slice();

        if (options[index]) {
            options[index].checked = !options[index].checked;
        }
    }

    renderButton = () => {
        if (hasRole(this.props.selected_profile, ["pathologist", 'pathology'])) {
            return (<React.Fragment>
                <div className="button-wrapper align-right">
                    <button className="btn btn-primary" onClick={this.createOrder}>Start test</button>
                </div>
            </React.Fragment>
            )
        }
        else if ((hasRole(this.props.selected_profile, ["pathology-receptionist"]))) {
            return (<React.Fragment>
                <div className="button-wrapper align-right">
                    {/* <Link to={'/pathology/print/' + this.state.prescriptionId} className="btn btn-primary" target="_blank">Print</Link> */}
                    <PrintButton
                        autoPrint={this.state.autoPrint}
                        component={PrintPrescriptionPage}
                        prescription={this.state.data}
                        selected_user_profile={this.props.selected_profile}
                        prescription_icd_data={this.state.prescription_icd_data}
                    />
                    <button className="btn btn-outline-primary" onClick={this.addToQueue}>Add to queue</button>
                </div>
            </React.Fragment>)
        }
    }

    createOrder = () => {
        const { data } = this.state
        const options = data.prescription_pathology_tests.pathology_test_items
        const selectedData = options.filter(option => option.checked)
        if (selectedData === '') {
            Notification.show('error', { message: 'Select atleast one test.' });
        } else {
            const createOrder = {
                prescription_pathology_test_id: data.prescription_pathology_tests_id,
                patient_id: data.patient_id,
                order_pathology_test_items: [...selectedData],
                prescription_id: this.state.prescriptionId,
            }
            this.props.loaderShow();
            this.props.deleteAppointment(data.patient_id)
            PathoRadioService.createPathologyOrder(createOrder).then(resp => {
                this.props.loaderHide();
                this.props.history.push('/patient/pathology-tests');
                Notification.show('success', { message: "Test done, report not ready" });
            }).catch(error => {
                this.props.loaderHide();
                Notification.show('error', error.response.data);
            })
        }
    }

    render() {
        const { data, prescription_icd_data } = this.state
        return (<React.Fragment>
            <Helmet>
                <title>Pathology</title>
            </Helmet>
            {data && <div className="row">
                <div className="col-md-24">
                    <h4 className="page-title no-heading">
                        <Link to="/patient/pathology-tests">&#8249; Search Prescription</Link>
                        {/* <span className="date"><i>{"Date " + this.getTodaysDate() + ", " + this.getCurrentTime()} </i></span> */}
                    </h4>

                    <PatientInfo patient_info={data.patient_info} />
                    <DoctorInfo doctor_info={data.doctor_info}
                        doctor_location={data.doctor_location_info} />
                    {has(data, 'diagnosis') && <div className="content-wrapper padding-bottom-10">
                        <h6>Diagnosis</h6>
                        <p>{data.diagnosis ? data.diagnosis : "--"}</p>
                    </div>}

                    {has(data, 'prescription_cpt_icd') && !isEmpty(prescription_icd_data) &&
                        <div className="content-wrapper padding-bottom-10">
                            <h6>ICD CPT</h6>
                            <ul className="prescription-patient-details medicine-details">
                                <div className="content-wrapper">
                                    <ul className="doctor-tests">
                                        {prescription_icd_data.map((icd, index) => (
                                            <ShowCptIcd.ShowIcd icd={icd} idx={index} disable={true} />))}
                                    </ul>
                                </div>
                            </ul>
                        </div>}

                    <h4 className="page-title font-size-16">Test Details</h4>
                    {(hasRole(this.props.selected_profile, ["pathology-receptionist"])) && data.prescription_pathology_tests.pathology_test_items.filter((test) => test.test_category !== "COVID-19").map((item, index) => (
                        <div className="content-wrapper padding-bottom-10" key={index}>
                            <ul className="prescription-patient-details test-details">
                                <li>Test Name: <span>{item.test_name + " - " + (item.test_category ? item.test_category : 'Profile Test')}</span></li>
                                <li>Description: <span>{item.test_description}</span></li>
                                <li className="no-border">Remark: <span>{item.remark}</span></li>
                            </ul>
                        </div>))}

                    {(hasRole(this.props.selected_profile, ["pathologist"])) && data.prescription_pathology_tests.pathology_test_items.filter((test) => test.test_category !== "COVID-19").map((item, index) => (
                        <div className="content-wrapper padding-bottom-10" key={index} index={this.state.isChecked}>
                            <input type="checkbox" id={index} onClick={() => this.handleCheckedData(index)} checked={item.checked} />
                            <label htmlFor={index}></label>
                            <ul className="prescription-patient-details test-details">
                                <li>Test Name: <span>{item.test_name + " - " + (item.test_category ? item.test_category : 'Profile Test')}</span></li>
                                <li>Description: <span>{item.test_description}</span></li>
                                <li className="no-border">Remark: <span>{item.remark}</span></li>
                            </ul>
                        </div>))}

                    {this.renderButton()}
                </div>
            </div>}
        </React.Fragment>);
    }
}

const mapStateToProps = (state) => ({
    selected_profile: state.selected_user_profile
});

const mapActionsToProps = ({
    deleteAppointment: QueueAction.deleteAppointment,
    loaderShow: LoaderAction.loaderShow,
    loaderHide: LoaderAction.loaderHide,
})

export default connect(mapStateToProps, mapActionsToProps)(AddToQueuePathology);