import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class MedicineService {
    static list({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute('/v1/drugs'), requestOptions);
    }
    static searchMedicines({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute('/v1/drugs/search'), requestOptions);
    }
    static lock(id) {
        const requestOptions = {
            headers: getApiHeader()
        };
        const body = JSON.stringify({
            id: id
        });
        return axios.post(apiRoute("/v1/doctors/prescriptions/"+id+"/lock"), body,requestOptions);
    }
    static unlock(patientId,prescriptionId,prescriptionUnlockId) {
        const requestOptions = {
            headers: getApiHeader()
        };
        const body = JSON.stringify({
            patient_id: patientId,
            prescription_unlock_id:prescriptionUnlockId,
        });
        return axios.post(apiRoute("/v1/pharmacy/prescriptions/"+prescriptionId+"/unlock"), body,requestOptions);
    }
      
}


export default MedicineService;