import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function (state = initialState.prescription, { type, prescription_type = null, ...payload }) {
    switch (type) {
        case types.RESET_PERSCRIPTION:
            return {
                ...initialState.prescription,
                new: {}
            };
        case types.FILTER_PERSCRIPTION:
            return {
                ...state,
                filter: payload,
                action: 'filter'
            };
        case types.LIST_PERSCRIPTION:
            return {
                ...state,
                paginate: {
                    ...state.paginate,
                    prescription_type: prescription_type,
                    ...payload,
                    action: 'list'
                }
            };
        case types.APPROVE_PERSCRIPTION:
            return {
                ...state,
                filter: payload,
                action: 'approve'
            };
        case types.LIST_PRESCRIPTION_REQUEST:
            return {
                ...state,
                paginate: {
                    ...state.paginate,
                    ...payload,
                    action: 'prescription_request'
                }
            };
        case types.CLEAR_LIST_PERSCRIPTION:
            return {
                ...state,
                paginate: {
                    filter: {},
                    data: [],
                    meta: {
                        current_page: 0,
                        per_page: 0,
                        total: 0,
                    }
                }
            };
        case types.FETCH_PERSCRIPTION:
            return {
                ...state,
                fetch: payload,
                action: 'fetch'
            };
        case types.LIST_PRESCRIPTION_FEES_REQUEST:
            return {
                ...state,
                ...payload
            };
        case types.LIST_PRESCRIPTION_FEES_SUCCESS:
            return {
                ...state,
                paginate: {
                    ...state.paginate,
                    ...payload,
                    action: 'list'
                }
            };
        case types.LIST_PRESCRIPTION_FEES_FAILED:
            return {
                ...state,
                ...payload
            };
        default:
            return state;
    }
}
