import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function (state = initialState.locations, { type, ...payload }) {
    switch (type) {
        case types.SERVICE_PROVIDER_LOCATION_REQUEST:
            return {
                ...state,
                ...payload,
            };
        case types.SERVICE_PROVIDER_LOCATION_SUCCESS:
            return {
                ...state,
                ...payload
            };
        case types.SERVICE_PROVIDER_LOCATION_FAILED:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }

}