import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class PreRegisteredPatientService {
    static list({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute("/v1/patient/pre-registered"), requestOptions);
    }
}


export default PreRegisteredPatientService;