import React from 'react';
import PrescriptionService from '../../../services/prescriptionService';
import _ from 'lodash';
import FemaleBack from './components/FemaleBack';
import FemaleFront from './components/FemaleFront';
import MaleBack from './components/MaleBack';
import MaleFront from './components/MaleFront';
import Select from 'react-select';
import UploadBodyChart from './UploadBodyChart';
import Notification from '../../../utils/notification';
import { removeAutoSaveWithInterval, setAutoSaveWithInterval } from '../../../utils/helpers';

class AddBodyChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            body_chart: '',
            chart: [],
            submittedChart: [],
            image_uploads: ''
        }
    }
    componentDidMount() {
        this.props.showLoader();
        let { chart, submittedChart } = this.state;

        PrescriptionService.getPrescriptionBodyChart(this.props.prescriptionId).then(resp => {
            let bodyChartArr = resp.data;

            if (!_.isEmpty(bodyChartArr)) {
                chart = _.uniq(_.map(bodyChartArr, 'image_slug'))
                submittedChart = _.uniq(_.map(bodyChartArr, 'image_slug'))
            }
            this.setState({
                chart: chart,
                submittedChart: submittedChart,
                body_chart: bodyChartArr,
            });
            this.props.hideLoader();
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data)
        })
        setAutoSaveWithInterval(this.props.selected_user_profile, this.handleBodyChartSaveAndNext)
    }

    componentWillUnmount() {
        removeAutoSaveWithInterval()
    }

    handleBodyChart = (bodyChart) => {
        let { body_chart } = this.state;
        body_chart = body_chart.concat(bodyChart);
        this.setState({ body_chart: body_chart })
        this.props.handleIsPrescriptionEdited('body_chart')
    }
    handleChart = (chart) => {
        this.setState({ chart: chart })
        this.props.handleIsPrescriptionEdited('body_chart')
    }
    handleBodyChartSaveAndNext = (e = null, type = null) => {
        e && e.preventDefault();
        type = e ? type : "auto_save";

        if (this.checkUploadValidation()) {
            e && this.props.showLoader();
            Promise.all([this.createBodyChart(), this.uploadBodyChart()])
                .then((values) => {
                    e && this.props.hideLoader();
                    type !== "auto_save" && this.props.handleSaveAndNext();
                }).catch(error => {
                    e && this.props.hideLoader();
                    console.log('error', error)
                });
        } else {
            e && this.props.hideLoader();
        }
    }

    createBodyChart = () => {
        let sendData = {
            'body_charts': this.state.body_chart,
        }
        return new Promise((resolve, reject) => {
            PrescriptionService.createPrescriptionBodyChart(this.props.prescriptionId, sendData).then(resp => {
                resolve();
            }).catch(error => {
                Notification.show('error', error.response.data)
                reject(error);
            });
        })
    }

    uploadBodyChart = () => {
        let files = this.state.image_uploads;
        return new Promise((resolve, reject) => {
            if (!_.isEmpty(files)) {

                let formData = new FormData();
                for (let i = 0; i < files.length; i++) {
                    formData.append(`files.${i}.image`, files[i].image);
                    formData.append(`files.${i}.description`, files[i].description);
                }
                PrescriptionService.createPrescriptionBodyChartUpload(this.props.prescriptionId, formData).then(resp => {
                    resolve();
                }).catch(error => {
                    console.log(error);
                    reject(error);
                });
            } else {
                resolve();
            }
        })
    }

    checkUploadValidation = () => {
        let files = this.state.image_uploads;
        if (!_.isEmpty(files)) {
            let checkDescription = _.filter(files, function (file) {
                return _.isEmpty(file.description);
            });
            if (!_.isEmpty(checkDescription)) {
                Notification.show('error', { message: "Image details is required" });
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    handlePartChange = (selectedOptions) => {
        let selectedValues = _.map(selectedOptions, 'value');
        this.setState({ chart: selectedValues });
        this.props.handleIsPrescriptionEdited('body_chart')
    }
    removePart = (part, image_slug) => {
        let { body_chart } = this.state;
        body_chart = body_chart.filter((s) => {
            return part !== s.part_slug || s.image_slug !== image_slug
        });

        this.setState({ body_chart: body_chart })
        this.props.handleIsPrescriptionEdited('body_chart')
    }
    changeHandler = (parts, slug, e) => {
        let { body_chart } = this.state;
        const result = body_chart.findIndex(s => s.part_slug === parts && s.image_slug === slug);
        body_chart[result][e.target.name] = e.target.value;
        this.setState({ body_chart: body_chart })
        this.props.handleIsPrescriptionEdited('body_chart')
    }

    changeSubPartHandler = (parts, slug, e) => {
        let { body_chart } = this.state;
        const result = body_chart.findIndex(s => s.part_slug === parts && s.image_slug === slug);
        body_chart[result]['sub_part'] = e.value;
        this.setState({ body_chart: body_chart })
        this.props.handleIsPrescriptionEdited('body_chart')
    }
    checkIfBodyChartExist = (part) => {
        let { chart } = this.state;
        return _.includes(chart, part);
    }

    removeBodyChart = (image_slug) => {
        let { body_chart, submittedChart, chart } = this.state;

        body_chart = this.filteBodyChartByPart(body_chart, image_slug)
        _.pull(chart, image_slug);
        _.pull(submittedChart, image_slug);

        this.setState({ chart: chart, submittedChart: submittedChart })
        this.setState({ body_chart: body_chart })
        this.props.handleIsPrescriptionEdited('body_chart')
    }
    filteBodyChartByPart = (body_chart, part) => {
        return body_chart.filter((s) => {
            return s.image_slug !== part
        });
    }
    handleChartSubmit = () => {
        let { submittedChart, chart, body_chart } = this.state;
        let diff = _.difference(submittedChart, chart);
        _.remove(submittedChart)
        submittedChart = submittedChart.concat(chart);
        if (!_.isEmpty(diff)) {
            diff.map((d) => {
                body_chart = this.filteBodyChartByPart(body_chart, d)
                this.setState({ body_chart: body_chart })
                return d
            });
        }
        this.setState({ submittedChart: submittedChart })
        this.props.handleIsPrescriptionEdited('body_chart')
    }
    handleChartCancel = () => {
        let { submittedChart, chart, body_chart } = this.state;
        _.remove(submittedChart)
        _.remove(chart)
        _.remove(body_chart)
        this.setState({ submittedChart: submittedChart, chart: chart, body_chart: body_chart })
        this.props.handleIsPrescriptionEdited('body_chart')
    }

    handleImage = (images) => {
        this.setState({ image_uploads: images })
        this.props.handleIsPrescriptionEdited('body_chart')
    }

    handleImageUploadEdited = () => {
        this.props.handleIsPrescriptionEdited('body_chart')
    }

    render() {
        let { body_chart, submittedChart } = this.state;
        const options = [
            { value: 'male_front', label: 'Male Front' },
            { value: 'male_back', label: 'Male Back' },
            { value: 'female_front', label: 'Female Front' },
            { value: 'female_back', label: 'Female Back' }
        ];
        const formGroup = {
            flex: "0 0 80%"
        };
        const optionsDetails = {
            flex: "0 0 410px"
        }

        return (
            <React.Fragment>
                <div className="content-wrapper padding-20">
                    <div className="row">
                        <div className="col-md-24 select-chart-dropdown d-flex">
                            <label>Select the Chart</label>
                            <div className="form-group full d-flex" style={formGroup}>

                                <div className="options-details" style={optionsDetails}>
                                    <Select className="basic-multi-select" name="charts"
                                        isMulti options={options} onChange={this.handlePartChange} value={options.filter(obj => this.state.chart.includes(obj.value))}>
                                    </Select>
                                </div>
                                <div className="prescription pl-4">
                                    <button type="submit" className="btn btn-primary mr-2" onClick={this.handleChartSubmit}>Submit</button>
                                    <button type="submit" className="btn btn-default btn-outline-primary" onClick={this.handleChartCancel}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row anatomy-view">
                        {submittedChart && Array.isArray(submittedChart) && _.includes(submittedChart, 'male_front') && <MaleFront handleBodyChart={this.handleBodyChart} body_chart={body_chart} chart={submittedChart} handleChart={this.handleChart} removePart={this.removePart} changeHandler={this.changeHandler} changeSubPartHandler={this.changeSubPartHandler} removeBodyChart={this.removeBodyChart} />}
                        {submittedChart && Array.isArray(submittedChart) && _.includes(submittedChart, 'male_back') && <MaleBack handleBodyChart={this.handleBodyChart} body_chart={body_chart} chart={submittedChart} handleChart={this.handleChart} removePart={this.removePart} changeHandler={this.changeHandler} changeSubPartHandler={this.changeSubPartHandler} removeBodyChart={this.removeBodyChart} />}
                        {submittedChart && Array.isArray(submittedChart) && _.includes(submittedChart, 'female_front') && <FemaleFront handleBodyChart={this.handleBodyChart} body_chart={body_chart} chart={submittedChart} handleChart={this.handleChart} removePart={this.removePart} changeHandler={this.changeHandler} changeSubPartHandler={this.changeSubPartHandler} removeBodyChart={this.removeBodyChart} />}
                        {submittedChart && Array.isArray(submittedChart) && _.includes(submittedChart, 'female_back') && <FemaleBack handleBodyChart={this.handleBodyChart} body_chart={body_chart} chart={submittedChart} handleChart={this.handleChart} removePart={this.removePart} changeHandler={this.changeHandler} changeSubPartHandler={this.changeSubPartHandler} removeBodyChart={this.removeBodyChart} />}
                    </div>
                </div>
                <UploadBodyChart showLoader={this.props.showLoader}
                    prescriptionId={this.props.prescriptionId}
                    handleImageUpload={this.handleImage}
                    hideLoader={this.props.hideLoader}
                    handlePrescriptionEdited={this.handleImageUploadEdited}
                />
                {/* NOTE:Change its submit function */}
                <div className="bottom-btns">
                    <button className="btn btn-primary" onClick={e => this.handleBodyChartSaveAndNext(e, 'save_and_next')}>Save & Next</button>
                    <button className="btn btn-default btn-outline-primary" onClick={this.props.handlePrevious}>Previous</button>
                </div>
            </React.Fragment>
        )
    }
}
export default AddBodyChart