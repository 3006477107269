import React from "react";
import { Helmet } from "react-helmet";
import { Tab, Tabs } from "react-bootstrap";
import OverviewUrgentCarePage from "./OverviewUrgentCarePage";
import ApprovalUrgentCarePage from "./ApprovalUrgentCarePage";

class ListUrgentCarePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            key: "overview"
        };
    }

    componentWillUnmount() {
    }

    listPrescription = key => {
        this.setState({
            key: key
        });
    };
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Urgent Care</title>
                </Helmet>
                <section className="container-fluid">
                    <div className="row">
                        <div className="col-md-24 requester-tabs">
                            <h4 className="page-title">Urgent Care</h4>
                        </div>
                        <div className="col-md-24 mt-3 request-pending">
                            <Tabs
                                activeKey={this.state.key}
                                onSelect={this.listPrescription.bind(this)}
                            >
                                <Tab eventKey="overview" title="Overview">
                                    {this.state.key === "overview" && <OverviewUrgentCarePage />}
                                </Tab>
                                <Tab eventKey="approval" title="For Approval">
                                    {this.state.key === "approval" && <ApprovalUrgentCarePage />}
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default ListUrgentCarePage
