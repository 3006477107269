import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class PrescriptionOrderService {
    static list({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute('/v1/pharmacy/prescription/orders'), requestOptions);
    }

    static create({ data }) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute('/v1/prescription/order'), data, requestOptions);
    }

    static get(id) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/prescription/order/' + id), requestOptions);
    }

    static technicianPrescriptionOrder(data) {
        const requestOptions = {
            headers: getApiHeader(null, true)
        };

        return axios.post(apiRoute('/v1/pharmacy/technician/create/order'), data, requestOptions);
    }

    static deleteOrder(id) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.delete(apiRoute('/v1/pharmacy/prescription/orders/' + id), requestOptions);
    }

    static deleteAndReOrder(id) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.delete(apiRoute('/v1/pharmacy/prescription/orders/' + id + '?action=reorder'), requestOptions);
    }

    static patientOrderList(id) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute('/v1/patients/' + id + '/orders'), requestOptions);
    }

    static assignOrderToTechnician(data) {
        const requestOptions = {
            headers: getApiHeader(null, true)
        };

        return axios.post(apiRoute('/v1/pharmacy/' + data.prescription_id + '/assign/technician'), data, requestOptions);
    }

    static checkPosNumber(data) {
        const requestOptions = {
            headers: getApiHeader(null, true)
        };

        return axios.post(apiRoute('/v1/pharmacy/check/posnumber'), data, requestOptions);
    }

    static checkReferenceNumber(data) {
        const requestOptions = {
            headers: getApiHeader(null, true)
        };

        return axios.post(apiRoute('/v1/pharmacy/check/referencenumber'), data, requestOptions);
    }

    static cancelAcquisition(data) {
        const requestOptions = {
            headers: getApiHeader(null, true)
        };

        return axios.post(apiRoute('/v1/pharmacy/cancel/prescription/acquisition'), data, requestOptions);
    }

    static saveDraft(data) {
        const requestOptions = {
            headers: getApiHeader(null, true)
        };

        return axios.post(apiRoute('/v1/pharmacy/save/draft'), data, requestOptions);
    }

    static getOrderPrescriptionPdf(order_id) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/pharmacies/orders/' + order_id + '/prescription/download'), requestOptions);
    }

    static getOrderPdf(order_id) {
        const requestOptions = {
            headers: getApiHeader(null, true)
        };

        return axios.get(apiRoute('/v1/pharmacies/orders/' + order_id + '/download'), requestOptions);
    }

    static getSignaturePendingOrders({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute('/v1/orders/signature-pendings'), requestOptions);
    }

    static getOrderReminders({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute('/v1/pharmacies/reminders'), requestOptions);
    }
}

export default PrescriptionOrderService;
