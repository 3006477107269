import React from 'react';
import BarGraph from '../Graph/BarGraph';

class PatientAddedGraph extends React.Component {
    handlePatientAdded = (view) => {
        this.props.renderDashboardGraph('patient_added', view)
    }

    render() {
        return (
            <BarGraph 
                barColor="#F9F336"
                barLabel="New Patients Onboarded"
                yScaleLabel="No. Of Patients"
                datasetLabels={this.props.patient_added_graph_data.x_axis_label}
                datasetCounts={this.props.patient_added_graph_data.counts}
                handleViewChange={this.handlePatientAdded}
            />
        )
    }
}
export default PatientAddedGraph