import * as types from './actionTypes';
import Notification from '../utils/notification';
import UserAccountService from '../services/userAccountService';

export function invoiceList(service_provider_id, { page, sizePerPage, sortBy }) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.LIST_INVOICES, request: { service_provider_id, page, sizePerPage, sortBy } });

        UserAccountService.invoiceList(service_provider_id, { page, sizePerPage, sortBy })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.LIST_INVOICES, ...data });
                dispatch({ type: 'HIDE_PAGE_LOADER' });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}

export function statementList(service_provider_id, { page, sizePerPage, sortBy }) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.LIST_STATEMENTS, request: { service_provider_id, page, sizePerPage, sortBy } });

        UserAccountService.statementList(service_provider_id, { page, sizePerPage, sortBy })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.LIST_STATEMENTS, ...data });
                dispatch({ type: 'HIDE_PAGE_LOADER' });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}