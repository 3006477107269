import React from 'react';
import { connect } from 'react-redux';
import DashboardService from '../../services/dashboardService';
import _ from 'lodash';
import Notification from '../../utils/notification';
import ServiceProviderLocations from '../ServiceProviderLocations'
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import PatientVisitedGraph from './PatientVisitedGraph'
import PatientAddedGraph from './PatientAddedGraph'
import UniquePatientGraph from './UniquePatientGraph';
import PrescriptionCount from './PrescriptionCount';
import DoctorWorkflow from './DoctorWorkflow';

class DoctorDashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            filter: {
                user_location: '',
                start_date: "",
                end_date: "",
                patient_visited_group_by: 'week',
                patient_added_group_by: 'week',
                unique_patient_group_by: 'week',
                type: ''
            },
            prescription_count: '',
            patient_visited: {
                x_axis_label: '',
                counts: ''
            },
            patient_added: {
                x_axis_label: '',
                counts: ''
            },
            unique_patient: {
                x_axis_label: '',
                counts: ''
            },
            show_start_date: new Date(),
            show_end_date: new Date(),
            tags: {
                date_filter: "",
                location_filter: ""
            }
        }
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount() {
        this.getDashboardData();
    }

    getDashboardData = (type = null) => {
        const { filter } = this.state
        const filters = {
            user_location: filter.user_location,
            start_date: filter.start_date,
            end_date: filter.end_date,
        }
        if (_.isEmpty(type)) {
            DashboardService.getCountInfo("prescriptions", filters).then(resp => {
                this.setState({
                    prescription_count: resp.data.prescription_count,
                })
            }).catch(error => {
                Notification.show('error', error.response.data)
            })
        }
        if (type === "patient_visited" || _.isEmpty(type)) {
            filters.patient_visited_group_by = filter.patient_visited_group_by
            DashboardService.getDoctorsGraphInfo("patient-visits", filters).then(resp => {
                this.setState({
                    patient_visited: {
                        x_axis_label: resp.data.x_axis_label,
                        counts: resp.data.counts,
                    }
                })
            }).catch(error => {
                Notification.show('error', error.response.data)
            })
        }
        if (type === "patient_added" || _.isEmpty(type)) {
            filters.patient_added_group_by = filter.patient_added_group_by
            DashboardService.getDoctorsGraphInfo("patient-onboards", filters).then(resp => {
                this.setState({
                    patient_added: {
                        x_axis_label: resp.data.x_axis_label,
                        counts: resp.data.counts,
                    },
                })
            }).catch(error => {
                Notification.show('error', error.response.data)
            })
        }
        if (type === "unique_patient" || _.isEmpty(type)) {
            filters.unique_patient_group_by = filter.unique_patient_group_by
            DashboardService.getDoctorsGraphInfo("unique-patients", filters).then(resp => {
                this.setState({
                    unique_patient: {
                        x_axis_label: resp.data.x_axis_label,
                        counts: resp.data.counts,
                    },
                })
            })
        }
    }

    handleEvent = (event, picker) => {
        this.setState({
            filter: {
                start_date: (picker.startDate._d) ? moment(picker.startDate._d).format('YYYY-MM-DD') : '',
                end_date: (picker.endDate._d) ? moment(picker.endDate._d).format('YYYY-MM-DD') : '',
                user_location: this.state.filter.user_location,
                patient_visited_group_by: this.state.filter.patient_visited_group_by,
                patient_added_group_by: this.state.filter.patient_added_group_by,
                unique_patient_group_by: this.state.filter.unique_patient_group_by,
                type: ''
            },
            show_start_date: picker.startDate._d,
            show_end_date: picker.endDate._d,
            tags: {
                date_filter: (picker.startDate._d && picker.endDate._d) ? moment(picker.startDate._d).format('DD-MM-YYYY') + ' to ' + moment(picker.endDate._d).format('DD-MM-YYYY') : '',
                location_filter: this.state.tags.location_filter
            },
        });
    }

    handleLocationFilter = (user_location_id, location_name = null) => {
        const filter = this.state.filter;
        filter.type = ''
        filter.user_location = user_location_id ? user_location_id : undefined;
        this.setState({
            filter: filter,
            tags: {
                date_filter: this.state.tags.date_filter,
                location_filter: location_name
            }
        });
    }

    handleSubmit = () => {
        window['searchToggle']();
        this.getDashboardData();
    }

    resetSearch = () => {
        const filter = this.state.filter;
        filter.user_location = "";
        filter.start_date = "";
        filter.end_date = "";
        filter.type = "";
        this.setState({
            filter: filter,
            show_start_date: new Date(),
            show_end_date: new Date(),
            tags: {
                date_filter: "",
                location_filter: ""
            }
        });
        window['searchToggle']();
        this.getDashboardData();
    }

    removeFilter = (e) => {
        const filter = this.state.filter;
        const tags = this.state.tags;
        filter.type = ''
        if (e.target.dataset.tag === 'date_filter') {
            filter["start_date"] = '';
            filter["end_date"] = '';
        } else {
            filter['user_location'] = '';
        }
        tags[e.target.dataset.tag] = '';
        this.setState({ filter: filter, tags: tags });
        this.getDashboardData();
    }

    handleGraphs = (type, view) => {
        const { filter } = this.state;
        if (type === "patient_visited") {
            filter.patient_visited_group_by = view;
        } else if (type === "patient_added") {
            filter.patient_added_group_by = view;
        } else if (type === "unique_patient") {
            filter.unique_patient_group_by = view;
        }
        this.getDashboardData(type)
        filter.type = type
        this.setState({
            filter: filter
        });
    }

    renderTags(tags) {
        let options;
        return (Object.keys(tags)).map((tag) => {
            if (tags[tag] !== '' && !(_.isEmpty(tags[tag]))) {
                options = <span className="search-list">{tags[tag]} <span className='removesearch' data-tag={tag} onClick={this.removeFilter.bind(this)}> x </span> </span>
            } else {
                return false
            }
            return options
        });
    }

    render() {
        return (<React.Fragment>
            <div className="row">
                {/* request pending, approval pending and assigned prescription count*/}
                <DoctorWorkflow />
                {/* filter box */}
                <div className="col-md-24">
                    <div className="filter-wrapper">
                        <div className="search-wrapper">
                            <label>Filter</label>
                            <div className="search-box clearfix">
                                {this.state.tags && this.renderTags(this.state.tags)}
                                <span className="search-box-icon"></span>
                                <div className="search-container">
                                    <ul>
                                        <li>
                                            <span>Select Date</span>
                                            <DateRangePicker
                                                startDate={this.state.show_start_date}
                                                onApply={this.handleEvent}
                                                endDate={this.state.show_end_date}
                                            >
                                                <input
                                                    type="text"
                                                    className="search-input"
                                                    name="start_date"
                                                    autoComplete="off"
                                                    defaultValue={this.state.filter.start_date ? moment(this.state.filter.start_date).format('DD-MM-YYYY') : ''}
                                                ></input>
                                                <input
                                                    type="text"
                                                    className="search-input"
                                                    name="end_date"
                                                    autoComplete="off"
                                                    defaultValue={this.state.filter.end_date ? moment(this.state.filter.end_date).format('DD-MM-YYYY') : ''}
                                                ></input>
                                            </DateRangePicker>
                                        </li>
                                        <li>
                                            <span>Select location</span>
                                            <ServiceProviderLocations
                                                service_provider_id={this.props.selected_user_profile.service_provider_id}
                                                handleLocationChange={this.handleLocationFilter}
                                                user_location_id={this.state.filter.user_location}
                                            />
                                        </li>
                                        <li>
                                            <button type="button" className="btn btn-secondary" onClick={this.resetSearch}>Reset</button>
                                            <button type="submit" className="btn btn-primary" onClick={this.handleSubmit}>Search</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                {/* prescription count according to status */}
                {<PrescriptionCount prescription_count={this.state.prescription_count} />}
            </div>
            <div className="row">
                {/* patient visited graph */}
                {this.state.patient_visited && <div className="col-md-24"><PatientVisitedGraph
                    patient_added_graph_data={this.state.patient_visited}
                    renderDashboardGraph={this.handleGraphs} /></div>}
                {/* patient added graph */}
                {this.state.patient_added && <div className="col-md-24"><PatientAddedGraph
                    patient_added_graph_data={this.state.patient_added}
                    renderDashboardGraph={this.handleGraphs} /></div>}
                {/* Unique patient graph */}
                {this.state.patient_added && <div className="col-md-24"><UniquePatientGraph
                    unique_patient_graph_data={this.state.unique_patient}
                    renderDashboardGraph={this.handleGraphs} /></div>}
            </div>
        </React.Fragment>
        )
    }
}
const mapStateToProps = (state) => ({
    selected_user_profile: state.selected_user_profile,
    dashboard_info: state.dashboard_info,
});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(DoctorDashboard);
