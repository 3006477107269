import axios from 'axios';
import { apiRoute, getApiHeader, createPaginateQueryString } from '../utils/helpers';

class CardService {
    static attachCardToPatient(data) {
        const postData = JSON.stringify(data);

        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.post(apiRoute("/v1/cards/patients/assign"), postData, requestOptions);
    }

    static reissueCardList({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute("/v1/reissue/cards"), requestOptions);
    }

    static getReissueCardInfo(request_id) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.get(apiRoute('/v1/reissue-cards/' + request_id), requestOptions);
    }
}

export default CardService;