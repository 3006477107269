import * as types from './actionTypes';
import PrescriptionService from '../services/prescriptionService';
import Notification from '../utils/notification';

export function reset() {
    return dispatch => {
        dispatch({ type: types.RESET_PERSCRIPTION });
    }
}

export function resetList() {
    return dispatch => {
        dispatch({ type: types.CLEAR_LIST_PERSCRIPTION });
    }
}

export function list({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.LIST_PERSCRIPTION, request: { page, sizePerPage, sortBy, filter } });

        PrescriptionService.list({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;

                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: types.LIST_PERSCRIPTION, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}

export function draftPrescriptions({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.LIST_PERSCRIPTION, request: { page, sizePerPage, sortBy, filter } });

        PrescriptionService.draftPrescriptions({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;

                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: types.LIST_PERSCRIPTION, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}

export function listPrescriptionRequest({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.LIST_PRESCRIPTION_REQUEST, request: { page, sizePerPage, sortBy, filter } });

        PrescriptionService.listPrescriptionRequest({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;

                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: types.LIST_PRESCRIPTION_REQUEST, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}

export function listApprovalPendingPrescription({ page, sizePerPage, sortBy, searchText }) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.CLEAR_LIST_PERSCRIPTION });
        dispatch({ type: types.LIST_PERSCRIPTION, request: { page, sizePerPage, sortBy } });

        PrescriptionService.listApprovalPendingPrescription({ page, sizePerPage, sortBy, searchText })
            .then(resp => {
                let data = resp.data;

                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: types.LIST_PERSCRIPTION, ...data });

            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}

export function searchPrescription({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.LIST_PERSCRIPTION, request: ({ page, sizePerPage, sortBy, filter }) });

        PrescriptionService.searchPrescription({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                Notification.clearAll();
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: types.LIST_PERSCRIPTION, ...data });

                if (data.data && data.data.length <= 0) {
                    Notification.show('error', { message: 'No data found.' })
                }
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}

export function getPrescriptions({ page, sizePerPage, sortBy, searchText, filter }, id) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.LIST_PERSCRIPTION, request: ({ page, sizePerPage, sortBy, filter }, id) });

        PrescriptionService.getPrescriptions({ page, sizePerPage, sortBy, searchText, filter }, id)
            .then(resp => {
                let data = resp.data;
                Notification.clearAll();
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: types.LIST_PERSCRIPTION, ...data });

                // if (data.data && data.data.length <= 0) {
                //     Notification.show('info', { message: 'Patient Prescription Not Found' })
                // }
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}

export function getPrescriptionsList({ page, sizePerPage, sortBy, searchText, filter }, id, prescription_type) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.LIST_PERSCRIPTION, request: ({ page, sizePerPage, sortBy, filter }, id) });

        PrescriptionService.getPrescriptions({ page, sizePerPage, sortBy, searchText, filter }, id)
            .then(resp => {
                let data = resp.data;
                // Notification.clearAll();
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: types.LIST_PERSCRIPTION, prescription_type, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}

export function fetch(id, history) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });

        PrescriptionService.get(id)
            .then(resp => {
                let data = resp.data;

                Notification.clearAll();
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: types.FETCH_PERSCRIPTION, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                    history.push('/patients/search');
                }
            });
    }
}

export function prescriptionList({ page, sizePerPage, sortBy }, pharmacy_code) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.LIST_PERSCRIPTION, request: ({ page, sizePerPage, sortBy }, pharmacy_code) });

        PrescriptionService.prescriptionList({ page, sizePerPage, sortBy }, pharmacy_code)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: types.LIST_PERSCRIPTION, ...data });

                // if (data.data && data.data.length <= 0) {
                //     Notification.show('error', { message: 'Prescription Not Found' })
                // }
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }

}

export function changePrescription(data, history, callback) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });

        PrescriptionService.changePrescription(data, history)
            .then(resp => {
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                callback(resp.data)
                // history.push("/pharmacy/prescription/" + data.prescription_id + "/view");
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'FETCH_CHANGED_PRESCRIPTION_FAILED' });
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
                dispatch({ type: 'HIDE_PAGE_LOADER' });
            });
    }
}

export function getChangePrescription(prescription_id, query) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: 'CLEAR_CHANGED_PRESCRIPTION' });
        dispatch({ type: 'FETCH_CHANGED_PRESCRIPTION_REQUEST' });
        PrescriptionService.getChangePrescription(prescription_id, query)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'FETCH_CHANGED_PRESCRIPTION_SUCCESS', ...data });
                dispatch({ type: 'HIDE_PAGE_LOADER' });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'FETCH_CHANGED_PRESCRIPTION_FAILED' });
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
                dispatch({ type: 'HIDE_PAGE_LOADER' });
            });
    }
}

export function getPrescriptionForOrder(id) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });

        PrescriptionService.getPrescriptionForOrder(id)
            .then(resp => {
                let data = resp.data;

                Notification.clearAll();
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: types.FETCH_PERSCRIPTION, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}
export function getPharmacyPrescriptions({ page, sizePerPage, sortBy, searchText, filter }, id) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.LIST_PERSCRIPTION, request: ({ page, sizePerPage, sortBy, filter }, id) });

        PrescriptionService.getPharmacyPrescriptions({ page, sizePerPage, sortBy, searchText, filter }, id)
            .then(resp => {
                let data = resp.data;
                Notification.clearAll();
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: types.LIST_PERSCRIPTION, ...data });

                // if (data.data && data.data.length <= 0) {
                //     Notification.show('info', { message: 'Patient Prescription Not Found' })
                // }
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}
export function getPrescriptionOrderPharmacy(id) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });

        PrescriptionService.getPrescriptionOrderPharmacy(id)
            .then(resp => {
                let data = resp.data;

                Notification.clearAll();
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: types.FETCH_PERSCRIPTION, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}

export function technicianPrescriptionList({ page, sizePerPage, sortBy, filter }) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.LIST_PERSCRIPTION, request: ({ page, sizePerPage, sortBy, filter }) });

        PrescriptionService.technicianPrescriptionList({ page, sizePerPage, sortBy, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: types.LIST_PERSCRIPTION, ...data });

                // if (data.data && data.data.length <= 0) {
                //     Notification.show('error', { message: 'Prescription Not Found' })
                // }
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }

}

export function assignPrecription(postData, history) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });

        PrescriptionService.assignPrecription(postData.prescription_id, postData)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                Notification.show('success', { message: data.message });
                history.push("/pharmacy/prescriptions/search");
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }

}

export function acquirePrescription(prescription, history, isRepeat) {
    return dispatch => {
        PrescriptionService.acquirePrescription(prescription)
            .then(_resp => {
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    history.push('/pharmacy/technician/prescription/list');
                    setTimeout(() => {
                        Notification.show('error', error.response.data);
                    }, 500);

                }
            });
    }
}

export function technicianPrescriptionOrder(postData, history) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });

        PrescriptionService.technicianPrescriptionOrder(postData)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                Notification.show('success', { message: data.message });
                history.push("/pharmacy/prescriptions/search");
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }

}

export function prescriptionQueueList({ page, sizePerPage, sortBy, searchText, filter }) {

    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.LIST_PERSCRIPTION, request: { page, sizePerPage, sortBy, filter } });

        PrescriptionService.prescriptionQueueList({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;

                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: types.LIST_PERSCRIPTION, ...data });

                // if (data.data && data.data.length <= 0) {
                //     Notification.show('error', { message: 'Patient Prescription Not Found' })
                // }
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }

}

export function PharmacistAcquirePrescription(prescription, history) {
    return dispatch => {
        PrescriptionService.PharmacistAcquirePrescription(prescription)
            .then(_resp => {
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    history.push('/pharmacy/prescriptions/search');
                    setTimeout(() => {
                        Notification.show('error', error.response.data);
                    }, 500);
                }
            });
    }
}

export function getPrescriptionFees({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: 'LIST_PRESCRIPTION_FEES_REQUEST' });
        PrescriptionService.getPrescriptionFees({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'LIST_PRESCRIPTION_FEES_SUCCESS', ...data });
                dispatch({ type: 'HIDE_PAGE_LOADER' });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'LIST_PRESCRIPTION_FEES_FAILED' });
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
                dispatch({ type: 'HIDE_PAGE_LOADER' });
            });
    }


}

export function getPrescriptionForEdit(id) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });

        PrescriptionService.getPrescriptionForEdit(id)
            .then(resp => {
                let data = resp.data;

                Notification.clearAll();
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: types.FETCH_PERSCRIPTION, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}
export function getPharmacyEmergencyPrescriptionList({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.LIST_PERSCRIPTION, request: ({ page, sizePerPage, sortBy, filter }) });

        PrescriptionService.getPharmacyEmergencyPrescriptionList({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: types.LIST_PERSCRIPTION, ...data });

                // if (data.data && data.data.length <= 0) {
                //     Notification.show('info', { message: 'Patient Prescription Not Found' })
                // }
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}

export function getPresentingConditionImage({ page, sizePerPage, sortBy, searchText, filter }, prescriptionId) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' })
        dispatch({ type: types.LIST_PRESCRIPTION_FEES_REQUEST, request: { page, sizePerPage, sortBy, filter } });

        PrescriptionService.getPresentingConditionImage({ page, sizePerPage, sortBy, searchText, filter }, prescriptionId)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' })
                dispatch({ type: types.LIST_PRESCRIPTION_FEES_SUCCESS, ...data });
            })
            .catch(error => {
                dispatch({ type: types.LIST_PRESCRIPTION_FEES_FAILED, ...error.response })
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}