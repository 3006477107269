import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function (state = initialState.pagination, { type, ...payload }) {
    switch (type) {
        case types.PATIENT_HISTORIC_DATA_LIST_REQUEST: {
            return {
                ...state,
                ...payload,
                is_requested: true,
            }
        }
        case types.PATIENT_HISTORIC_DATA_SUCCESS: {
            return {
                ...state,
                ...payload,
                is_requested: false,
            }
        }
        case types.PATIENT_HISTORIC_DATA_FAILED: {
            return {
                ...state,
                ...payload,
                is_requested: false,
            }
        }
        case types.UPLOADED_HISTORIC_DATA_LIST_REQUEST: {
            return {
                ...state,
                ...payload,
                is_requested: true,
            }
        }
        case types.UPLOADED_HISTORIC_DATA_LIST_SUCCESS: {
            return {
                ...state,
                ...payload,
                is_requested: false,
            }
        }
        case types.UPLOADED_HISTORIC_DATA_LIST_FAILED: {
            return {
                ...state,
                ...payload,
                is_requested: false,
            }
        }
        default:
            return state;
    }
}