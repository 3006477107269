import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function (state = initialState.patient_data, { type, ...payload }) {
    switch (type) {
        case types.FETCH_PATIENT_WEIGHT_DATA:
            return {
                weight_data: payload
            };
        case types.FETCH_PATIENT_BLOOD_PRESSURE_DATA:
            return {
                bloodpressure_data: payload
            };
        default:
            return state;
    }
}