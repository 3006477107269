import React from 'react';
import { connect } from 'react-redux';
import * as QueueAction from '../actions/queueAction';

class DoctorAddQueueButton extends React.Component {

    constructor(props) {
        super(props);

        this.handleQueueClick = this.handleQueueClick.bind(this);
    }

    handleQueueClick(patientId) {
        const queueData = {
            patient_id: patientId,
            appointment_type: 'queue',
            prescription_id: '',
        };

        this.props.createQueue(queueData, this.props.history);
    }

    render() {
        return (
            <button className="btn btn-secondary" onClick={this.handleQueueClick.bind(this, this.props.patientId)}>
                Add To Queue
            </button>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.session.user,
});

const mapActionsToProps = ({
    createQueue: QueueAction.create
});

export default connect(mapStateToProps, mapActionsToProps)(DoctorAddQueueButton);