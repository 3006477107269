import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function alert(state = initialState.alert, { type, message, ...payload }) {
    switch (type) {
        case types.ALERT_SUCCESS:
            return {
                isloading: false,
                status: 'success',
                type: 'success',
                message: message,
                ...payload
            };
        case types.ALERT_ERROR:
            return {
                isloading: false,
                status: 'error',
                type: 'danger',
                message: message,
                ...payload
            };
        case types.ALERT_WARNING:
            return {
                isloading: false,
                status: 'warning',
                type: 'warning',
                message: message,
                ...payload
            };
        case types.ALERT_CLEAR:
            return {
                ...initialState.alert,
                ...payload
            };
        default:
            return state;
    }
}