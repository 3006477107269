import * as types from "./actionTypes";
import PatientConfirmEditService from "../services/patientConfirmEditService";
import Notification from '../utils/notification';

export function patientConfirmEditList({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({
          type: types.PROFILE_EDIT_QUEUE_LIST_REQUEST,
        });

        PatientConfirmEditService.list({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.PROFILE_EDIT_QUEUE_LIST_SUCCESS, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: types.PROFILE_EDIT_QUEUE_LIST_FAILED, error: error.response})
                    let errorData = error.response.data;
                    Notification.show('error', errorData);
                }
            });
    }
}

export function add(data, history) {
    return dispatch => {
        dispatch({
          type: types.PROFILE_EDIT_QUEUE_ADD_REQUEST,
        });

        PatientConfirmEditService.add(data)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.PROFILE_EDIT_QUEUE_ADD_SUCCESS, ...data });
                Notification.show('success', { message: 'Profile Update Request Submitted' })
                setTimeout(() => {
                    Notification.clearAll();
                    history.push('/patients/' + data.patient_id);
                }, 2000);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: types.PROFILE_EDIT_QUEUE_ADD_FAILED, error: error.response})
                    let errorData = error.response.data;
                    Notification.show('error', errorData);
                }
            });
    }
}