import * as types from './actionTypes';
import MedicineService from '../services/pendingSignaturePrescription.js';
import Notification from '../utils/notification';

export function list({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER ' });
        dispatch({ type: types.PENDING_SIGNATURE_PRESCRIPTION_LIST_REQUEST, request: { page, sizePerPage, sortBy, searchText, filter } });

        MedicineService.list({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER ' });
                dispatch({ type: types.PENDING_SIGNATURE_PRESCRIPTION_LIST_SUCCESS, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER ' });
                    let errorData = error.response.data;
                    Notification.show('error', errorData)
                }
            });
    }
}

export function getSignaturePendingModifiedPrescription({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER ' });
        dispatch({ type: 'MODIFIED_PRESCRIPTION_LIST_REQUEST', request: { page, sizePerPage, sortBy, searchText, filter } });

        MedicineService.getSignaturePendingModifiedPrescription({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER ' });
                dispatch({ type: 'MODIFIED_PRESCRIPTION_LIST_SUCCESS', ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER ' });
                    dispatch({ type: 'MODIFIED_PRESCRIPTION_LIST_FAILURE', ...error.response.data });
                    let errorData = error.response.data;
                    Notification.show('error', errorData)
                }
            });
    }
}