import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function (state = initialState.pagination, { type, ...payload }) {
    switch (type) {
        case types.IMMUNIZATION_LIST_REQUEST:
            return {
                ...state,
                ...payload
            };
        case types.IMMUNIZATION_LIST_SUCCESS:
            return {
                ...state,
                ...payload
            };
        case types.IMMUNIZATION_LIST_FAILED:
            return {
                ...state,
                ...payload
            };
        default:
            return state;
    }
}