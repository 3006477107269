import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import * as HELPER from "../../utils/helpers";
import PatientInfo from '../../components/PatientInfo';
import PharmacistInfo from '../../components/PharmacistInfo';
import { withTranslation } from 'react-i18next';
import * as LoaderAction from '../../actions/loaderAction';
import PrescriptionService from '../../services/prescriptionService';
class ViewEmergencyPrescription extends React.Component {
  constructor(props) {
    super(props);
    let { params } = this.props.match;
    this.state = {
      prescriptionId: params.prescriptionId,
    };
  }

  componentDidMount() {

    this.props.pageLoaderShow();
    PrescriptionService.view(this.state.prescriptionId).then(response => {

      this.props.selected_user_profile.user_profile_id === response.data.user_profile_info.user_profile_id && this.getPrescriptionConcernsPharmacist(this.state.prescriptionId);
      this.setState({
        prescription: response.data,
      })
      this.props.pageLoaderHide();
    }).catch(error => {
      this.props.pageLoaderHide();
      Notification.show('error', error.response.data)
    });
  }



  render() {
    const {
      prescription,
    } = this.state;

    let doctor = prescription ? prescription.user_profile_info : null;
    let service_provider_info = prescription ? prescription.service_provider_info : null;

    return (
      <React.Fragment>
        <Helmet>
          <title>View Prescription</title>
        </Helmet>
        <div className="row">
          <div className="col-md-24">
            <h4 className="page-title no-heading">
              <Link onClick={() => this.props.history.goBack()} to="#">&#8249; Back</Link>
              <span className="prescription-details">Prescription Id: <i> {prescription && prescription.prescription_id}</i></span>
              <span className="date">Prescription Date: <i> {HELPER.getDateFormat(prescription && prescription.prescription_date)} </i></span>
            </h4>

            {prescription && <PatientInfo patient_info={prescription.patient_info} orderDate={prescription.prescription_date} />}
            {prescription && prescription.prescription_type === 'pharmacy-prescription' && <PharmacistInfo pharmacist_info={doctor} pharmacy_info={service_provider_info} prescription_date={HELPER.getDateFormat(prescription && prescription.prescription_date)} />}
            {prescription && prescription.prescription_medicines && !prescription.prescription_medicines.is_locked && (
              <div>
                <h4 className="page-title font-size-16">Prescribed Medication</h4>
                <div className="content-wrapper">
                  <table className="table table table-responsive common-table">
                    <thead>
                      <tr>
                        <th>Brand Name</th>
                        <th>Generic Name</th>
                        <th>Frequency</th>
                        <th>Duration</th>
                        <th>Dosage</th>
                        <th>Type</th>
                        <th>Meal</th>
                      </tr>
                    </thead>
                    <tbody>
                      {prescription.prescription_medicines.prescription_medicine_item &&
                        prescription.prescription_medicines.prescription_medicine_item.map((medicine, idx) => (
                          <React.Fragment>
                            <tr>
                              <td>{medicine.drug_name}</td>
                              <td>{medicine.drug_generic_name}</td>
                              <td>{medicine.dosage}</td>
                              <td> {medicine.duration}{HELPER.getDurationType(medicine.duration_type)} </td>
                              <td>{parseInt(medicine.quantity)}</td>
                              <td>{medicine.dosage_form}</td>
                              <td>{medicine.meal}</td>
                            </tr>
                            {medicine.remarks && <tr style={{ borderTop: "hidden" }}>
                              <td colSpan="7" style={{ textAlign: "left" }}><i>Instructions:</i> {medicine.remarks}</td>
                            </tr>}
                          </React.Fragment>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  selected_user_profile: state.selected_user_profile,
  user: state.session.user
});


const mapActionsToProps = ({
  pageLoaderHide: LoaderAction.loaderHide,
  pageLoaderShow: LoaderAction.loaderShow,
})
export default withTranslation()(connect(mapStateToProps, mapActionsToProps)(ViewEmergencyPrescription));
