import React from 'react';
import { connect } from 'react-redux';
import * as SelectedProfileAction from "../../actions/selectedProfileAction";
import ProfileService from '../../services/profileService';
import { isEmpty } from 'lodash';
import { Helmet } from 'react-helmet';
import Notification from '../../utils/notification';
import ProfileItem from '../../components/SwitchProfile/ProfileItem';
import Loader from '../../components/SwitchProfile/Loader';
import TwoFAPinModal from '../../components/TwoFAPinModal';
import * as authUtils from '../../utils/auth'

class SelectProfilePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            data: '',
            user: '',
            twofa: false
        }
    }

    componentDidMount() {
        if (!isEmpty(this.props.selected_user_profile)) {
            this.props.history.goForward();
            return;
        }
        this.setState({ isLoading: true })

        ProfileService.listOfProfiles().then(resp => {
            const { data } = resp.data;

            if (!isEmpty(data) && data.length === 1) {
                this.handleSubmit(data[0])
                // this.props.selectedProfile(data[0], this.props.history);
                return true;
            }

            this.setState({
                isLoading: false,
                data: data
            })
        }).catch(error => {
            Notification.show('error', error.response.data);
            this.setState({ isLoading: false })
        })
    }

    changeHandler = user => {
        this.setState({
            user: user,
        })
    }

    onProfileSelect = e => {
        e.preventDefault();
        if (this.state.user === '') {
            Notification.show('error', { message: 'Please Select Your Profile First.' });
        } else {
            this.handleSubmit(this.state.user)
        }
    }

    handleSubmit = (user) => {
        const postData = {
            'user_profile_id': user.user_profile_id,
            'service_provider_id': user.service_provider_id,
            'user_location_id': user.user_location_id
        }
        ProfileService.userProfileToken(postData)
            .then(_resp => {
                authUtils.setTwoFa(_resp.data.twofa);
                this.setState({
                    twofa: _resp.data.twofa
                })
                if (!_resp.data.twofa) {
                    this.props.selectedProfile(this.state.user, this.props.history);
                }
            }).catch(err => {
            console.log(err.response.dat)
        });
    }

    onPinVerify = () => {
        this.props.selectedProfile(this.state.user, this.props.history);
    }

    handleCloseModal = () => {
        this.setState({ twofa: false })
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Select Profile</title>
                </Helmet>
                <div className="select-profile-wrapper">
                    <img src="/images/profile.png" alt="Profile" />
                    <h4>Select Profile</h4>
                    <ul className="location-list">
                        {this.state.isLoading && <Loader />}
                        {this.state.data && this.state.data.map((user, index) => (
                            <ProfileItem
                                index={index}
                                user={user}
                                key={index}
                                onChange={this.changeHandler}
                            />
                        ))}
                    </ul>
                    <button className="btn btn-primary" onClick={this.onProfileSelect}>SUBMIT</button>
                </div>
                <TwoFAPinModal show={this.state.twofa} onPinVerify={this.onPinVerify} onCloseModal={this.handleCloseModal} />
            </React.Fragment >
        )
    }

}
const mapStateToProps = (state) => ({
    user: state.session.user,
    session: state.session,
    selected_user_profile: state.selected_user_profile
});
const mapActionsToProps = ({
    selectedProfile: SelectedProfileAction.selectedProfile,
});

export default connect(mapStateToProps, mapActionsToProps)(SelectProfilePage)
