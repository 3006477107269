import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import * as Config from '../../config';
import SimpleReactValidator from 'simple-react-validator';
import * as SelectedProfileAction from '../../actions/selectedProfileAction';
import { hasRole } from '../../utils/auth';
import * as loaderActions from '../../actions/loaderAction';
import ProfileService from '../../services/profileService';
import Notification from '../../utils/notification';

class EditUserProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            updateForm: {
                name_title: '',
                first_name: '',
                last_name: '',
                gender: '',
                contact_number: '',
                alt_contact_number: '',
                date_of_birth: '',
                license_number: '',
                stamp: '',
                signature: ''
            },
            isLoading: false
        }
        this.validator = new SimpleReactValidator({
            element: message => <span className="error">{message}</span>
        });
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        const user_profile = this.props.selected_profile;

        this.setState({
            email: user_profile.user.email,
            updateForm: {
                name_title: user_profile.user.name_title ? user_profile.user.name_title : '',
                first_name: user_profile.user.first_name ? user_profile.user.first_name : '',
                last_name: user_profile.user.last_name ? user_profile.user.last_name : '',
                gender: user_profile.user.gender ? user_profile.user.gender : '',
                contact_number: user_profile.user.contact_number ? user_profile.user.contact_number : '',
                alt_contact_number: user_profile.user.alt_contact_number ? user_profile.user.alt_contact_number : '',
                date_of_birth: user_profile.user.date_of_birth ? user_profile.user.date_of_birth : '',
                license_number: user_profile.license_number ? user_profile.license_number : '',
                specialization: user_profile.specialization ? user_profile.specialization : '',
                degree: user_profile.degree ? user_profile.degree : '',
                stamp: user_profile.user.documents.stamp,
                signature: user_profile.user.documents.signature,
            }
        })
    }

    handleChange = e => {
        const updateForm = this.state.updateForm;
        updateForm[e.target.name] = e.target.value
        this.setState({
            updateForm: updateForm
        });
    }

    handleSubmit = e => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        let data = this.state.updateForm;
        const user = this.props.selected_profile;
        let history = this.props.history;
        this.props.editProfile(data, user, history);
    }

    handleFilUpload = (e, type) => {
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        this.props.loaderShow();
        ProfileService.uploadUserDocuments(formData, type).then(resp => {
            this.props.loaderHide();
            let { updateForm } = this.state;
            updateForm[type] = resp.data
            this.setState({ updateForm })
        }).catch(err => {
            this.props.loaderHide();
            Notification.show('error', err.response.data)
        })
    }

    render() {
        return (<React.Fragment>
            <Helmet>
                <title>Edit Profile</title>
            </Helmet>

            <div className="select-profile-wrapper profile-details">
                <img src="/images/profile-details.png" alt="profile-detail" />
                <h4>Profile Details</h4>
                <ul className="profile-details-listing">
                    <li>
                        <label htmlFor="name_title">Title<i>*</i></label>
                        <select className="form-control" id="name_title" name="name_title" onChange={this.handleChange} value={this.state.updateForm.name_title}>
                            <option value="" >Select Title</option>
                            {Config.name_title.map(function (key) {
                                return <option value={key}>
                                    {key}
                                </option>;
                            })}
                        </select>
                        {this.validator.message('name_title', this.state.updateForm.name_title, 'required')}
                    </li>
                    <li>
                        <label>First Name <i>*</i></label>
                        <input type="text" className="form-control" name="first_name" id="first_name" onChange={this.handleChange} value={this.state.updateForm.first_name} />
                        {this.validator.message('first_name', this.state.updateForm.first_name, 'required')}
                    </li>
                    <li>
                        <label>Last Name <i>*</i></label>
                        <input type="text" className="form-control" id="last_name" name="last_name" onChange={this.handleChange} value={this.state.updateForm.last_name} />
                        {this.validator.message('last_name', this.state.updateForm.last_name, 'required')}
                    </li>
                    <li>
                        <label>Email <i>*</i></label>
                        <input type="text" className="form-control" id="email" name="email" value={this.state.email} readOnly />
                    </li>
                    <li>
                        <label>Contact Number </label>
                        <input type="number" className="form-control" id="contact_number" name="contact_number" onChange={this.handleChange} value={this.state.updateForm.contact_number} />
                        {this.validator.message('contact_number', this.state.updateForm.contact_number, 'phone')}
                    </li>
                    <li>
                        <label>Alternate Contact Number</label>
                        <input type="number" className="form-control" id="alt_contact_number" name="alt_contact_number" onChange={this.handleChange} value={this.state.updateForm.alt_contact_number} />
                        {this.validator.message('alt_contact_number', this.state.updateForm.alt_contact_number, 'phone')}
                    </li>
                    <li>
                        <label>Gender <i>*</i></label>
                        <select className="form-control" id="gender" name="gender" onChange={this.handleChange} value={this.state.updateForm.gender} >
                            <option value="">Select Gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                        </select>
                        {this.validator.message('gender', this.state.updateForm.gender, 'required')}
                    </li>
                    <li>
                        <label>Date of Birth <i>*</i></label>
                        <input type="date" className="form-control" id="date_of_birth" name="date_of_birth" onChange={this.handleChange} value={this.state.updateForm.date_of_birth} />
                        {this.validator.message('date_of_birth', this.state.updateForm.date_of_birth, 'required')}
                    </li>

                    {hasRole(this.props.selected_profile, ['doctor', 'junior-doctor', "associate-doctor", 'covid-doctor']) &&
                        <li>
                            <label>Specialization <i>*</i></label>
                            <select className="form-control" id="specialization" name="specialization" onChange={this.handleChange} value={this.state.updateForm.specialization} >
                                <option value="">Select Specialization </option>
                                {Config.doctor_specialization.map((value) => {
                                    return <option value={value}>{value}</option>
                                })}
                            </select>
                            {this.validator.message('specialization', this.state.updateForm.specialization, 'required')}
                        </li>}
                    {hasRole(this.props.selected_profile, ['doctor', 'junior-doctor', "associate-doctor", 'covid-doctor']) &&
                        <li>
                            <label>Degree <i>*</i></label>
                            <select className="form-control" id="degree" name="degree" onChange={this.handleChange} value={this.state.updateForm.degree} >
                                <option value="">Select Degree </option>
                                {Config.doctor_degree.map((value) => {
                                    return <option value={value}>{value}</option>
                                })}
                            </select>
                            {this.validator.message('degree', this.state.updateForm.degree, 'required')}
                        </li>}
                    {hasRole(this.props.selected_profile, ['doctor', 'pharmacist', 'pathologist', 'radiologist', 'doctor-nurse', 'junior-doctor', "associate-doctor", 'covid-doctor', 'covid-nurse']) && <li>
                        <label>Registration Number<i>*</i></label>
                        <input type="text" className="form-control" id="license_number" name="license_number" onChange={this.handleChange} value={this.state.updateForm.license_number} />
                        {this.validator.message('registration_number', this.state.updateForm.license_number, 'required')}
                    </li>}
                    {hasRole(this.props.selected_profile, ['covid-doctor']) && <div className="row">
                        <li>
                            <label>Upload Signature<i>*</i></label>
                            <span> <input type="file" ref={signatureFile => this.fileInput1 = signatureFile} onChange={e => this.handleFilUpload(e, 'signature')} name="signature" id="signature" hidden accept='.jpg,.png,.jpeg' />
                                <button onClick={() => this.fileInput1.click()} className="btn btn-outline-primary" name="signature" id="signature">Upload</button>
                            </span>
                            {this.validator.message('signature', this.state.updateForm.signature, 'required')}
                            {this.state.updateForm.signature && <div className="patient-pic m-2">
                                <img src={this.state.updateForm.signature} alt="User Signature" height="250" width="280" />
                            </div>}
                        </li>
                        {hasRole(this.props.selected_profile, ['covid-doctor']) && <li>
                            <label>Upload Stamp<i>*</i></label>
                            <span> <input type="file" ref={stampFile => this.fileInput2 = stampFile} onChange={e => this.handleFilUpload(e, 'stamp')} name="stamp" id="stamp" hidden accept='.jpg,.png,.jpeg' />
                                <button onClick={() => this.fileInput2.click()} className="btn btn-outline-primary" name="stamp" id="stamp">Upload</button>
                            </span>
                            {hasRole(this.props.selected_profile, ['covid-doctor']) && this.validator.message('stamp', this.state.updateForm.stamp, 'required')}
                            {this.state.updateForm.stamp && <div className="patient-pic m-2">
                                <img src={this.state.updateForm.stamp} alt="User stamp" height="250" width="280" />
                            </div>}
                        </li>}
                    </div>}
                </ul>
                <div className='clear-both'>
                    <button type="submit" className="btn btn-primary" onClick={this.handleSubmit}>Submit</button>
                </div>

            </div>
        </React.Fragment >

        )
    }

}

const mapStateToProps = (state) => ({
    selected_profile: state.selected_user_profile,
});

const mapActionsToProps = ({
    editProfile: SelectedProfileAction.editProfile,
    loaderShow: loaderActions.loaderShow,
    loaderHide: loaderActions.loaderHide,
})
export default connect(mapStateToProps, mapActionsToProps)(EditUserProfile);
