import * as types from './actionTypes';
import PrescriptionOrderService from '../services/prescriptionOrderService';
import Notification from '../utils/notification';

export function reset() {
    return dispatch => {
        dispatch({ type: types.RESET_PERSCRIPTION_ORDER });
    }
}

export function verify(prescriptionOrder, history) {
    return dispatch => {
        dispatch({ type: types.VERIFY_PERSCRIPTION_ORDER, ...prescriptionOrder });

        history.push('/prescriptions/' + prescriptionOrder.prescription_id + '/orders/summary');
    }
}

export function submit(data, action, history, callback) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        PrescriptionOrderService.create({ data })
            .then(resp => {
                let data = resp.data;
                Notification.clearAll();
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: types.RESET_PERSCRIPTION_ORDER });
                dispatch({ type: types.RESET_PERSCRIPTION });
                callback(data);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}

export function list({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.LIST_PERSCRIPTION_ORDER, request: { page, sizePerPage, sortBy, filter } });

        PrescriptionOrderService.list({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: types.LIST_PERSCRIPTION_ORDER, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}

export function fetch(id, history) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        PrescriptionOrderService.get(id)
            .then(resp => {
                let data = resp.data;
                Notification.clearAll();
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: types.FETCH_PERSCRIPTION_ORDER, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                    history.goBack();
                }
            });
    }
}

export function technicianPrescriptionOrder(postData, history) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });

        PrescriptionOrderService.technicianPrescriptionOrder(postData)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                Notification.show('success', { message: data.message });
                setTimeout(() => {
                    history.push("/pharmacy/prescriptions/search");
                }, 2000);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                    history.push("/pharmacy/technician/prescription/list");
                }
            });
    }
}

export function deleteOrder(postData, history) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });

        PrescriptionOrderService.deleteOrder(postData)
            .then(resp => {
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                Notification.show('success', { message: "Order returned successfully." });
                setTimeout(() => {
                    history.push("/pharmacy/prescriptions/search");
                }, 2000);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}

export function deleteAndReOrder(postData, history) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });

        PrescriptionOrderService.deleteAndReOrder(postData)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                Notification.show('success', { message: "Order returned successfully." });
                setTimeout(() => {
                    history.push("/prescriptions/" + data.prescription_id + "/orders/create");
                }, 2000);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}

export function assignOrderToTechnician(postData, history) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });

        PrescriptionOrderService.assignOrderToTechnician(postData)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                Notification.show('success', { message: data.message });
                history.push("/pharmacy/prescriptions/search");
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}

export function saveDraft(postData, history) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });

        PrescriptionOrderService.saveDraft(postData)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                Notification.show('success', { message: data.message });
                setTimeout(() => {
                    history.push("/pharmacy/prescriptions/search");
                }, 2000);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}

export function getSignaturePendingOrders({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.LIST_PERSCRIPTION_ORDER, request: { page, sizePerPage, sortBy, filter } });

        PrescriptionOrderService.getSignaturePendingOrders({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: types.LIST_PERSCRIPTION_ORDER, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}

export function getOrderReminders({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });

        PrescriptionOrderService.getOrderReminders({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: types.PRESCRIPTION_ORDER_REMINDER_LIST, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}
