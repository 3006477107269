import React, { useState } from "react";
import { Card, Collapse } from 'react-bootstrap';
import * as HELPER from "../../../../utils/helpers";
import ShowMedicineBlock from '@Components/UrgentCare/Recommendation/components/ShowMedicineBlock';
import ShowPathologyBlock from '@Components/Prescription/ShowPathologyBlock';
import ShowRadiologyBlock from '@Components/Prescription/ShowRadiologyBlock';
import RoleSerivce from '../../../../services/roleService';
import _ from 'lodash';

export const ViewMedication = ({ prescription_medicine, reloadPrescriptionMedicine }) => {
    let medicineAdministratorNote = prescription_medicine.prescription_medicine_item.map(item => {
        let notes = item.administrator_note.map(note => {
            note['drug_name'] = item.drug_name;
            return note;
        });
        return notes;
    });

    let administratorNote = [].concat.apply([], medicineAdministratorNote);
    administratorNote = administratorNote.sort(function (a, b) {
        return new Date(b.created_at) - new Date(a.created_at);
    });
    const [open, setOpen] = useState(false);
    return (
        <React.Fragment>
            {prescription_medicine && <div className="content-wrapper padding-bottom-10 urgent-care-medicine">
                {prescription_medicine.prescription_medicine_item && prescription_medicine.prescription_medicine_item.map((item, idx) => (
                    <ShowMedicineBlock medicine={item} key={idx} prescription_id={prescription_medicine.prescription_id} reloadPrescriptionMedicine={reloadPrescriptionMedicine} />
                ))}

                {administratorNote.length > 0 &&
                    <Card className="mt-2 administer-history">
                        <Card.Header onClick={() => setOpen(!open)}>
                            Administer History
                            <i className={open ? "arrow-icon-up" : "arrow-icon-down"}></i>

                        </Card.Header>

                        <Collapse in={open}>
                            <div>
                                {<table className="table">
                                    <thead>
                                        <tr className="font-weight-bold">
                                            <td>Name</td>
                                            <td>Date and Time</td>
                                            <td>Administrator Name</td>
                                            <td>Role</td>
                                            <td>Comment</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {_.isEmpty(administratorNote) && <tr><td colSpan="4" className="text-center">No Record</td></tr>}
                                        {administratorNote.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.drug_name}</td>
                                                <td>{HELPER.getDateTimeFormat(item.created_at)}</td>
                                                <td>{item.submitted_by}</td>
                                                <td>{item.submitted_by_role && RoleSerivce.getRoleNameBySlug(item.submitted_by_role)}</td>
                                                <td>{item.note}</td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                </table>}
                            </div>
                        </Collapse>
                    </Card>
                }
            </div>}
        </React.Fragment>
    );
};

export const ViewPathology = ({ prescription_pathology_test }) => {
    return (
        <React.Fragment>
            {prescription_pathology_test && <div className="content-wrapper padding-bottom-10">
                <ul className="prescription-patient-details medicine-details">
                    <div className="content-wrapper">
                        <ul className="doctor-tests">
                            {prescription_pathology_test.prescription_pathology_test_items.map((item, idx) => (
                                <ShowPathologyBlock pathology={item} key={idx} />
                            ))}
                        </ul>
                    </div>
                </ul>
            </div>}
        </React.Fragment>
    )
}

export const ViewRadiology = ({ prescription_radiology_test }) => {
    return (
        <React.Fragment>
            {prescription_radiology_test && <div className="content-wrapper padding-bottom-10">
                <ul className="prescription-patient-details medicine-details">
                    <div className="content-wrapper">
                        <ul className="doctor-tests">
                            {prescription_radiology_test.prescription_radiology_test_items.map((item, idx) => (
                                <ShowRadiologyBlock radiology={item} key={idx} />
                            ))}
                        </ul>
                    </div>
                </ul>
            </div>}
        </React.Fragment>
    )
}