import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as PrescriptionAction from "../../actions/prescriptionAction";
import DataGrid from "../../components/DataGrid";
import * as Helper from "../../utils/helpers";
import { hasRole } from "../../utils/auth";
import SimpleReactValidator from 'simple-react-validator';
import _ from 'lodash';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import NurseJrDoctorDashboard from '../../components/Dashboard/Nurse&JrDoctorDashboard';

class ListPrescriptionPage extends React.Component {
  constructor(props) {
    super(props);
    let status_values, assigned_role;

    if (hasRole(this.props.selected_user_profile, ['doctor-nurse', 'junior-doctor', "medical-practice-manager"])) {
      status_values = "draft";
      assigned_role = this.props.selected_user_profile.role_slug;
    } else if (hasRole(this.props.selected_user_profile, ['doctor', 'associate-doctor'])) {
      status_values = "prescribed,partially-fulfilled,fulfilled";
      assigned_role = "";
    }

    this.state = {
      filter: {
        patient_code: "",
        patient_name: "",
        prescription_id: "",
        start_date: "",
        end_date: "",
        show_start_date: new Date(),
        show_end_date: new Date(),
        status: status_values,
        assigned_role: assigned_role
      },
      tags: {
        patient_code: "",
        patient_name: "",
        prescription_id: "",
        // start_date: "",
        // end_date: ""
      }
    };

    this.validator = new SimpleReactValidator();
    this.handleEvent = this.handleEvent.bind(this);
    this.props.paginate({
      sortBy: { sortField: "updated_at", sortOrder: "desc" },
      filter: this.state.filter
    });
  }

  columns = () => {
    return [
      {
        dataField: "prescription_id",
        text: "Prescription Id",
        sort: true
      },
      {
        dataField: "patient_code",
        text: "Patient Id",
        // sort: true
      },
      {
        dataField: "patient_name",
        text: "Patient Name"
      }, {
        dataField: "prescription_medicines_status",
        text: "Medication Status",
        formatter: this.statusFormatter
      },
      {
        dataField: "prescription_pathology_tests",
        text: "Pathology Test Status",
        formatter: this.statusFormatter
      }, {
        dataField: "prescription_radiology_tests",
        text: "Radiology Test Status",
        formatter: this.statusFormatter
      },
      {
        dataField: "created_by",
        text: "Created By"
      },
      {
        dataField: "prescription_date",
        text: "Created On",
        sort: true,
        formatter: this.dateFormatter
      },
      {
        dataField: "actions",
        text: "Actions",
        isDummyField: true,
        formatter: this.actionFormatter
      }
    ];
  };

  statusFormatter = (row) => {
    if (_.isEmpty(row)) {
      return "NA"
    } else {
      return _.capitalize(row);
    }
  }

  handleChange = (e) => {
    const filter = this.state.filter;
    filter[e.target.name] = e.target.value ? e.target.value : undefined;
    this.setState({ filter });
  }

  actionFormatter = (_cell, row) => {
    return (
      row && <React.Fragment>
        {hasRole(this.props.selected_user_profile, ['doctor', 'associate-doctor']) &&
          <Link to={"/prescriptions/" + row.prescription_id} >
            <span className="icon file"></span>
          </Link>}
        {this.isPrescriptionExpired(row) && (row.status === 'draft') ? <Link to={"/patients/" + row.patient_id + "/prescriptions/" + row.prescription_id}>
          <span className="icon edit"></span>
        </Link> : ''
        }
        {this.isPrescriptionExpired(row) && (row.prescription_medicines_status === "prescribed" ||
          row.prescription_pathology_tests === "prescribed" ||
          row.prescription_radiology_tests === "prescribed")
          ? <Link to={"/prescriptions/" + row.prescription_id + "/edit"}>
            <span className="icon edit"></span>
          </Link> : ''}
      </React.Fragment>
    );
  };

  isPrescriptionExpired = row => {
    var currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 3);
    if (currentDate.getTime() < new Date(row.prescription_date).getTime()) {
      return true;
    }
    return false;
  }

  dateFormatter = (cell, row) => {
    if (row.status === 'draft') {
      return Helper.getDateTimeFormat(row.updated_at);
    }
    return Helper.getDateFormat(cell);
  };

  handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, searchText }
  ) => {
    if (type === "search") {
      page = 1;
    }

    const filter = this.state.filter;
    this.props.paginate({ page, sizePerPage, sortBy: { sortField, sortOrder }, searchText, filter });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return false;
    }

    /* External javascript function  */
    window['searchToggle']();

    this.setState({
      tags: {
        patient_code: this.state.filter.patient_code,
        patient_name: this.state.filter.patient_name,
        prescription_id: this.state.filter.prescription_id,
        // start_date: this.state.filter.start_date,
        // end_date: this.state.filter.end_date,
        date_filter: (this.state.filter.start_date && this.state.filter.end_date) ? this.state.filter.start_date + ' to ' + this.state.filter.end_date : ''
      }
    });

    this.props.paginate({
      sortBy: { sortField: "updated_at", sortOrder: "desc" },
      filter: this.state.filter
    });
  }

  resetSearch = (e) => {
    e.preventDefault()

    let status_values, assigned_role;

    if (hasRole(this.props.selected_user_profile, ['doctor-nurse', 'junior-doctor', "'medical-practice-manager'"])) {
      status_values = "draft";
      assigned_role = this.props.selected_user_profile.role_slug;
    } else if (hasRole(this.props.selected_user_profile, ['doctor'])) {
      status_values = "prescribed,partially-fulfilled,fulfilled";
      assigned_role = "";
    }

    this.setState({
      filter: {
        patient_code: "",
        patient_name: "",
        prescription_id: "",
        start_date: "",
        end_date: "",
        show_start_date: new Date(),
        show_end_date: new Date(),
        status: status_values,
        assigned_role: assigned_role
      },
      tags: {
        patient_code: "",
        patient_name: "",
        prescription_id: "",
        // start_date: "",
        // end_date: "",
        date_filter: ""
      }
    });

    window['searchToggle']();

    this.props.paginate({
      sortBy: { sortField: "updated_at", sortOrder: "desc" },
      filter: this.state.filter
    });
  }

  handleEvent = (event, picker) => {
    let status_values, assigned_role;

    if (hasRole(this.props.selected_user_profile, ['doctor-nurse', 'junior-doctor', "'medical-practice-manager'"])) {
      status_values = "draft";
      assigned_role = this.props.selected_user_profile.role_slug;
    } else if (hasRole(this.props.selected_user_profile, ['doctor'])) {
      status_values = "prescribed,partially-fulfilled,fulfilled";
      assigned_role = "";
    }
    this.setState({
      filter: {
        patient_code: this.state.filter.patient_code,
        patient_name: this.state.filter.patient_name,
        prescription_id: this.state.filter.prescription_id,
        start_date: (picker.startDate._d) ? moment(picker.startDate._d).format('YYYY-MM-DD') : '',
        end_date: (picker.endDate._d) ? moment(picker.endDate._d).format('YYYY-MM-DD') : '',
        show_start_date: picker.startDate._d,
        show_end_date: picker.endDate._d,
        status: status_values,
        assigned_role: assigned_role
      },
      tags: {
        patient_code: this.state.filter.patient_code,
        patient_name: this.state.filter.patient_name,
        prescription_id: this.state.filter.prescription_id,
        date_filter: (picker.startDate._d && picker.endDate._d) ? moment(picker.startDate._d).format('DD-MM-YYYY') + ' to ' + moment(picker.endDate._d).format('DD-MM-YYYY') : ''
      }
    });

    this.props.paginate({ filter: this.state.filter });
  }

  removeFilter = (e) => {

    const filter = this.state.filter;
    const tags = this.state.tags;

    if (e.target.dataset.tag === 'date_filter') {
      filter["start_date"] = '';
      filter["end_date"] = '';
    } else {
      filter[e.target.dataset.tag] = '';
    }
    tags[e.target.dataset.tag] = '';

    this.setState({ filter: filter, tags: tags });

    this.props.paginate({ filter: this.state.filter });
  }

  render() {
    let { data, meta } = this.props.prescription_list;
    let tags = this.state.tags;
    return (
      <React.Fragment>
        <Helmet>
          <title>Prescriptions</title>
        </Helmet>
        <div className="row">
          <div className="col-md-24">
            <h4 className="page-title">Prescription</h4>
            {
              !hasRole(this.props.selected_user_profile, ['doctor', 'associate-doctor']) && <div className="row"> <NurseJrDoctorDashboard />
              </div>
            }
            <div className="search-wrapper">
              <label>Search</label>
              <div className="search-box clearfix">
                {(Object.keys(tags)).map((tag) => {
                  if (tags[tag] !== '' && !_.isEmpty(tags[tag])) {
                    return <span className="search-list">{tags[tag]} <span className='removesearch' data-tag={tag} onClick={this.removeFilter.bind(this)}> x </span> </span>
                  }
                  return <></>
                })
                }
                <span className="search-box-icon"></span>

                <div className="search-container">
                  <form>
                    <ul>
                      <li>
                        <span>Patient Id</span>
                        <input type="text" value={this.state.filter.patient_code} onChange={this.handleChange} name="patient_code"></input>
                        {this.validator.message('patient_code', this.state.filter.patient_code, 'alpha_num')}
                      </li>
                      <li>
                        <span>Patient Name</span>
                        <input type="text" value={this.state.filter.patient_name} onChange={this.handleChange} name="patient_name"></input>
                        {this.validator.message('patient_name', this.state.filter.patient_name, 'alpha_num_space')}
                      </li>
                      <li>
                        <span>Prescription Id</span>
                        <input type="text" value={this.state.filter.prescription_id} onChange={this.handleChange} name="prescription_id"></input>
                        {this.validator.message('prescription_id', this.state.filter.prescription_id, 'alpha_num')}
                      </li>
                      <li>
                        <span>Date</span>
                        <DateRangePicker
                          startDate={this.state.filter.show_start_date}
                          onApply={this.handleEvent}
                          endDate={this.state.filter.show_end_date}
                        >
                          <input type="text" className='search-input' value={this.state.filter.start_date ? moment(this.state.filter.start_date).format('DD-MM-YYYY') : ''} name="start_date" autoComplete='off'></input>
                          <input type="text" className='search-input' value={this.state.filter.end_date ? moment(this.state.filter.end_date).format('DD-MM-YYYY') : ''} name="end_date" autoComplete='off'></input>
                        </DateRangePicker>
                      </li>
                      <li>
                        <button onClick={this.resetSearch} className="btn btn-secondary">Reset</button>
                        <button onClick={this.handleSubmit} className="btn btn-primary">Search</button>
                      </li>
                    </ul>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-wrapper">
          {data && (
            <DataGrid
              columns={this.columns()}
              data={data}
              meta={meta}
              handleTableChange={this.handleTableChange}
              noDataIndication="No Record Found"
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.session.user,
    prescription_list: state.prescription.paginate,
    selected_user_profile: state.selected_user_profile,
  };
}

const mapActionsToProps = {
  paginate: PrescriptionAction.list
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ListPrescriptionPage);
