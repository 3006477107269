import React from 'react';
import PatientRemarksBlock from '../PatientRemarksBlock';
import { SPECIALIZATION } from '../../../config';
import DoctorSearch from '../DoctorSearch';
import PersonalNote from './components/PersonalNote';
import MedicineBlock from '../MedicineBlock';
import ShowPathologyTest from '../PathologyTestBlock';
import ShowRadiologyTest from '../RadiologyTestBlock'
import AddPathologyBlock from "../../AddPathologyBlock";
import AddRadiologyBlock from "../../AddRadiologyBlock";
import SimpleReactValidator from 'simple-react-validator';
import PrescriptionService from '../../../services/prescriptionService';
import PathologyService from '../../../services/pathologyService';
import Notification from '../../../utils/notification';
import { hasRole } from '../../../utils/auth';
import { removeAutoSaveWithInterval, setAutoSaveWithInterval } from '../../../utils/helpers';

class AddRecommendation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show_add_pathology_block: false,
            show_add_radiology_block: false,
            disable_doctor_search: true,
            edit_refer_remark: true,
            prescription: {
                prescription_drugs: [],
                prescription_pathology_tests: [],
                prescription_radiology_tests: [],
                refer_to: {},
                personal_note: '',
                remarks: '',
                covid_tests: []
            },
        }
        this.recommendationValidator = new SimpleReactValidator();
    }
    componentDidMount() {
        this.props.showLoader();
        PrescriptionService.getPrescriptionRecommendation(this.props.prescriptionId).then(resp => {
            let { data } = resp;
            let { prescription } = this.state
            this.setState({
                prescription: {
                    prescription_drugs: data.prescription_medicines ? data.prescription_medicines.prescription_medicine_item : prescription.prescription_drugs,
                    prescription_pathology_tests: data.prescription_pathology_tests ? data.prescription_pathology_tests.prescription_pathology_test_items : prescription.prescription_pathology_tests,
                    prescription_radiology_tests: data.prescription_radiology_tests ? data.prescription_radiology_tests.prescription_radiology_test_items : prescription.prescription_radiology_tests,
                    refer_to: data.refer_to ? {
                        doctor_id: data.refer_to.user_id,
                        doctor_name: data.refer_to.full_name,
                        doctor_specialization: data.refer_to.specialization,
                        refer_remark: data.refer_to.remark
                    } : prescription.refer_to,
                    personal_note: data.personal_note ? data.personal_note : prescription.personal_note,
                    remarks: data.remarks ? data.remarks : prescription.remarks,
                    patient_id: this.props.patientId,
                }
            })
            this.props.hideLoader();
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data)
        })

        this.fetchCovidTests();
        setAutoSaveWithInterval(this.props.selected_user_profile, this.handleRecommendationSave)
    }

    componentWillUnmount() {
        removeAutoSaveWithInterval()
    }

    fetchCovidTests = () => {
        PathologyService.getAllCovidTests().then(resp => {
            this.setState({
                covid_tests: resp.data.data
            })
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }

    handleRecommendationSave = (e = null, type = null) => {
        e && e.preventDefault();
        type = e ? type : "auto_save";
        if (!this.recommendationValidator.allValid()) {
            this.recommendationValidator.showMessages();
            this.forceUpdate();
            return false;
        }
        e && this.props.showLoader();
        PrescriptionService.updatePrescriptionRecommendation(this.props.prescriptionId, this.state.prescription).then(_resp => {
            e && this.props.hideLoader();
            if (type === 'save') {
                this.props.handleSaveAndNext();
                return true;
            }
            type !== "auto_save" && hasRole(this.props.selected_user_profile, ["paramedic-doctor"]) && (type === 'save_and_next') ? this.props.history.push('/hospital/' + this.props.recordId + '/' + this.props.prescriptionId + '/summary') : this.props.history.push('/prescriptions/' + this.props.prescriptionId + '/summary');
        }).catch(error => {
            e && this.props.hideLoader();
            Notification.show('error', error.response.data)
        })
    }
    // Start Medicine Block Events
    handleMedicineChange = (idx, data) => {
        let { prescription } = this.state;
        const newMedicines = prescription.prescription_drugs.map((medicine, sidx) => {
            if (idx !== sidx) return medicine;

            return { ...medicine, ...data };
        });
        prescription['prescription_drugs'] = newMedicines;
        this.setState({ prescription });
        this.props.handleIsPrescriptionEdited('recommendation')
    };
    handleAddMedicine = () => {
        let { prescription } = this.state;
        prescription['prescription_drugs'] = prescription.prescription_drugs.concat([{
            drug_id: '',
            drug_name: '',
            drug_generic_id: '',
            drug_generic_name: '',
            dosage: '',
            meal: '',
            duration: '',
            duration_type: '',
            substitution_not_allowed: false,
            quantity: '',
            drug_type: '',
            is_other: false,
            over_dosage: false,
            substitution_not_allowed_disable: false
        }]);
        this.setState({ prescription });
        this.props.handleIsPrescriptionEdited('recommendation')
    };
    handleRemoveMedicine = (event) => {
        let { prescription } = this.state;
        const idx = Number(event.target.dataset['id']);
        prescription['prescription_drugs'] = prescription.prescription_drugs.filter((s, sidx) => idx !== sidx);
        this.setState({ prescription });
        this.props.handleIsPrescriptionEdited('recommendation')
    };
    //End medicine block
    // Start Pathology Block Events
    handlePathologyChange = (idx, data) => {
        let { prescription } = this.state;

        let newPathology = [];
        if (data.test_category === "COVID-19") {
            newPathology = prescription.prescription_pathology_tests.map((pathology) => {
                if (data.test_id !== pathology.test_id) return pathology;

                return { ...pathology, ...data };
            });
        } else {
            newPathology = prescription.prescription_pathology_tests.map((pathology, sidx) => {
                if (idx !== sidx) return pathology;

                return { ...pathology, ...data };
            });
        }
        prescription['prescription_pathology_tests'] = newPathology;
        this.setState({ prescription });
        this.props.handleIsPrescriptionEdited('recommendation')
    };
    handleAddPathology = () => {
        let { prescription } = this.state;
        let pathology = prescription.prescription_pathology_tests.concat([{
            test_id: '',
            test_type: '',
            test_category: '',
            test_name: '',
            test_description: '',
            remark: '',
            is_other: false
        }])
        prescription['prescription_pathology_tests'] = pathology;
        this.setState({ prescription });

        this.props.handleIsPrescriptionEdited('recommendation')
    };
    handleAddPathologyBlock = () => {
        this.setState({
            show_add_pathology_block: !this.state.show_add_pathology_block
        })
    }
    handleRemovePathologyTest = (event) => {
        let { prescription } = this.state;
        const idx = Number(event.target.dataset["id"]);
        const type = event.target.dataset["type"];
        let pathology = [];
        if (type == "COVID-19") {
            pathology = prescription.prescription_pathology_tests.filter((s) => idx !== s.test_id);
        } else {
            pathology = prescription.prescription_pathology_tests.filter((s, sidx) => idx !== sidx);
        }

        prescription['prescription_pathology_tests'] = pathology;
        this.setState({ prescription });
        this.props.handleIsPrescriptionEdited('recommendation')
    };
    handlePathology = (data) => {
        let { prescription } = this.state;
        prescription['prescription_pathology_tests'] = data;
        this.setState({ prescription });
        this.props.handleIsPrescriptionEdited('recommendation')
    }
    // End Pathology Block Events
    // Start Radiology Block Events
    handleRadiologyChange = (idx, data) => {
        let { prescription } = this.state;
        const newRadiology = prescription.prescription_radiology_tests.map((radiology, sidx) => {
            if (idx !== sidx) return radiology;

            return { ...radiology, ...data };
        });
        prescription['prescription_radiology_tests'] = newRadiology;
        this.setState({ prescription });
        this.props.handleIsPrescriptionEdited('recommendation')
    };
    handleAddRadiology = () => {
        let { prescription } = this.state;
        let radiology = prescription.prescription_radiology_tests.concat([{
            test_id: '',
            test_category: '',
            test_name: '',
            test_description: '',
            remark: '',
            is_other: false
        }]);
        prescription['prescription_radiology_tests'] = radiology;
        this.setState({ prescription });
        this.props.handleIsPrescriptionEdited('recommendation')
    };
    handleRemoveRadiologyTest = (event) => {
        let { prescription } = this.state;
        const idx = Number(event.target.dataset["id"]);
        const radiology = prescription.prescription_radiology_tests.filter((s, sidx) => idx !== sidx);
        prescription['prescription_radiology_tests'] = radiology;
        this.setState({ prescription });
        this.props.handleIsPrescriptionEdited('recommendation')
    };
    handleAddRadiologyBlock = () => {
        this.setState({
            show_add_radiology_block: !this.state.show_add_radiology_block
        })
    }
    handleRadiology = (data) => {
        let { prescription } = this.state;
        prescription['prescription_radiology_tests'] = data;
        this.setState({ prescription });
        this.props.handleIsPrescriptionEdited('recommendation')
    }
    // End Radiology Block Events
    handleChange = (e) => {
        let { prescription } = this.state;
        if (e.target.name === 'doctor_specialization') {
            if (e.target.value !== null && e.target.value !== "") {
                prescription.refer_to = {
                    doctor_id: '',
                    doctor_name: '',
                    doctor_specialization: e.target.value,
                    refer_remark: '',
                };
                this.setState({
                    prescription,
                    disable_doctor_search: false,
                    edit_refer_remark: false,
                });
            } else {
                prescription.refer_to = {
                    doctor_id: '',
                    doctor_name: '',
                    doctor_specialization: '',
                    refer_remark: '',
                };
                this.setState({
                    prescription,
                    disable_doctor_search: true,
                    edit_refer_remark: true,
                });
            }
        } else if (e.target.name === 'refer_remark') {
            prescription.refer_to['refer_remark'] = e.target.value;
        } else {
            prescription[e.target.name] = e.target.value;
        }
        this.setState({ prescription });
        this.props.handleIsPrescriptionEdited('recommendation')
    }
    //Refer To doctor
    handleReferToDoctor = (data) => {
        let { prescription } = this.state;
        prescription.refer_to = data;
        this.setState({ prescription });
        this.props.handleIsPrescriptionEdited('recommendation')
    }

    handleCovidTestChange = (e) => {
        let { prescription } = this.state;
        const test_id = e.target.value;
        const pathology = prescription.prescription_pathology_tests.filter((s) => s.test_category !== "COVID-19");
        prescription['prescription_pathology_tests'] = pathology;
        this.setState({ prescription }, () => {
            if (test_id != "") {
                let covid_test = this.state.covid_tests.filter((s) => s.test_id == test_id);
                if (covid_test.length > 0) {
                    let data = {
                        test_id: covid_test[0].test_id,
                        test_type: covid_test[0].test_type,
                        test_category: covid_test[0].test_category,
                        test_name: covid_test[0].test_name,
                        test_description: covid_test[0].test_description,
                        is_other: false
                    }

                    let { prescription } = this.state;
                    prescription['prescription_pathology_tests'].push(data);

                    this.setState({ prescription });
                }
            }
        });
        this.props.handleIsPrescriptionEdited('recommendation')
    }
    render() {
        let { prescription } = this.state;
        let selectedCovidTest = "";
        selectedCovidTest = prescription.prescription_pathology_tests.filter((test) => test.test_category == "COVID-19")
        selectedCovidTest = selectedCovidTest.length > 0 ? selectedCovidTest[0].test_id : "";
        this.recommendationValidator.purgeFields();
        return (
            <React.Fragment>
                <div className=" lg-height">
                    <div className="row">
                        <div className="col-md-24">
                            <div className="content-wrapper padding-bottom-10">
                                <h6>Medication</h6>
                                <ul className="prescription-patient-details medicine-details">
                                    {prescription.prescription_drugs.map((medicine, idx) => (
                                        <MedicineBlock
                                            key={idx}
                                            medicine={medicine}
                                            idx={idx}
                                            handleChangeMedicine={this.handleMedicineChange}
                                            handleRemoveMedicine={this.handleRemoveMedicine}
                                            validator={this.recommendationValidator}
                                            selected_user_profile={this.props.selected_user_profile}
                                            disabled={this.props.recordId ? true : false}
                                            not_required={false}
                                        />
                                    ))}
                                </ul>
                                <button onClick={this.handleAddMedicine} className="add-new btn btn-primary" disabled={hasRole(this.props.selected_user_profile, ["paramedic-doctor"]) ? 'disabled' : ''}>Add</button>
                            </div>
                            <div className="content-wrapper padding-bottom-10">
                                <h6>Lab Test</h6>
                                <ul className="prescription-patient-details medicine-details">
                                    {prescription.prescription_pathology_tests.filter((test) => test.test_category !== "COVID-19").map((pathology, idx) => (
                                        <ShowPathologyTest
                                            pathology={pathology}
                                            idx={idx}
                                            key={idx}
                                            handleChangePathology={this.handlePathologyChange}
                                            handleRemovePathologyTest={this.handleRemovePathologyTest}
                                            validator={this.recommendationValidator}
                                            disabled={this.props.recordId ? true : false}
                                        />
                                    ))}
                                    <button onClick={this.handleAddPathologyBlock} className="add-new btn btn-primary" disabled={hasRole(this.props.selected_user_profile, ["paramedic-doctor"]) ? 'disabled' : ''}>Add</button>
                                    <AddPathologyBlock
                                        show={this.state.show_add_pathology_block}
                                        prescriptionId={this.state.prescriptionId}
                                        handleAddPathologyBlock={this.handleAddPathologyBlock}
                                        handlePathology={this.handlePathology}
                                    />
                                </ul>
                            </div>

                            <div className="content-wrapper padding-bottom-10">
                                <h6>Covid-19 Test</h6>
                                <select className="form-control margin-bottom col-sm-6" value={selectedCovidTest} onChange={this.handleCovidTestChange} name="covid_test" >
                                    <option value="">Select Test</option>
                                    {this.state.covid_tests && this.state.covid_tests.map((covid, idx) => (
                                        <option value={covid.test_id}> {covid.test_name} </option>
                                    ))}
                                </select>
                                <ul className="prescription-patient-details medicine-details">
                                    {prescription.prescription_pathology_tests.filter((test) => test.test_category === "COVID-19").map((pathology, idx) => (
                                        <ShowPathologyTest
                                            pathology={pathology}
                                            idx={idx}
                                            key={idx}
                                            handleChangePathology={this.handlePathologyChange}
                                            handleRemovePathologyTest={this.handleRemovePathologyTest}
                                            validator={this.recommendationValidator}
                                            disabled={this.props.recordId ? true : false}
                                        />
                                    ))}
                                </ul>
                            </div>

                            <div className="content-wrapper padding-bottom-10">
                                <h6>Radiology</h6>
                                <ul className="prescription-patient-details medicine-details">
                                    {prescription.prescription_radiology_tests.map((radiology, idx) => (
                                        <ShowRadiologyTest
                                            radiology={radiology}
                                            idx={idx}
                                            key={idx}
                                            handleChangeRadiology={this.handleRadiologyChange}
                                            handleRemoveRadiologyTest={this.handleRemoveRadiologyTest}
                                            validator={this.recommendationValidator}
                                            disabled={this.props.recordId ? true : false}
                                        />
                                    ))}
                                    <button onClick={this.handleAddRadiologyBlock} className="add-new btn btn-primary" disabled={hasRole(this.props.selected_user_profile, ["paramedic-doctor"]) ? 'disabled' : ''}>Add</button>
                                    <AddRadiologyBlock
                                        show={this.state.show_add_radiology_block}
                                        prescriptionId={this.state.prescriptionId}
                                        handleAddRadiologyBlock={this.handleAddRadiologyBlock}
                                        handleRadiology={this.handleRadiology}
                                    />
                                </ul>
                            </div>
                            <PersonalNote
                                note={prescription.personal_note}
                                handleChange={this.handleChange}
                            />
                            <div className="content-wrapper-nobg">
                                <div className="row">
                                    <div className="col-md-14">
                                        <div className="content-wrapper">
                                            <h6>Remark
                                                <PatientRemarksBlock patientId={this.props.patientId} />
                                            </h6>
                                            <textarea className="form-control remark" rows="2" name="remarks" value={prescription.remarks} onChange={this.handleChange} placeholder="Type remark" disabled={this.state.edit_remark}></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-10">
                                        <div className="content-wrapper margin-bottom">
                                            <h6>Refer to a Doctor</h6>
                                            {<select className="form-control margin-bottom" onChange={this.handleChange} value={prescription.refer_to.doctor_specialization} name="doctor_specialization" disabled={!hasRole(this.props.selected_user_profile, ['doctor', "associate-doctor"]) ? "disabled" : ""}>
                                                <option value="">Select Specialization</option>
                                                {Object.keys(SPECIALIZATION).map(function (key) {
                                                    return <option key={key} className="travelcompany-input" value={SPECIALIZATION[key]}>
                                                        {SPECIALIZATION[key]}
                                                    </option>;
                                                })}
                                            </select>}
                                            <DoctorSearch
                                                handleDoctorSearch={this.handleReferToDoctor}
                                                refer_to={prescription.refer_to}
                                                disabled={this.state.disable_doctor_search}
                                                validator={this.recommendationValidator}
                                            />
                                            <textarea className="form-control mt-2" rows="2" name="refer_remark" value={prescription.refer_to.refer_remark} onChange={this.handleChange} placeholder="Remark" disabled={this.state.edit_refer_remark}></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-btns">
                    {hasRole(this.props.selected_user_profile, ['doctor', "associate-doctor", "paramedic-doctor"]) && <button className="btn btn-primary" onClick={e => this.handleRecommendationSave(e, 'save_and_next')}>submit</button>}
                    <button className="btn btn-default btn-outline-primary" onClick={this.props.handlePrevious}>Previous</button>
                </div>
            </React.Fragment >
        )
    }
}
export default AddRecommendation
