import React from 'react';

export const Stomach = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <div className="heading">
            <h6>Gastrointestinal Systems</h6>
        </div>
        <div className="form-group full">
            <div className="options-details">
                <input type="checkbox" id='nausea' name='nausea' onChange={e => handleChange(e, 'stomach-and-intestines')} checked={checkIfProblemExist('stomach-and-intestines', 'nausea') ? 'checked' : ''} />
                <label htmlFor='nausea'>Nausea</label>
                <input type="checkbox" id='heartburn' name='heartburn' onChange={e => handleChange(e, 'stomach-and-intestines')} checked={checkIfProblemExist('stomach-and-intestines', 'heartburn') ? 'checked' : ''} />
                <label htmlFor='heartburn'>Heartburn</label>
                <input type="checkbox" id='stomach-pain' name='stomach-pain' onChange={e => handleChange(e, 'stomach-and-intestines')} checked={checkIfProblemExist('stomach-and-intestines', 'stomach-pain') ? 'checked' : ''} />
                <label htmlFor='stomach-pain'>Stomach pain</label>
                <input type="checkbox" id='vomiting' name='vomiting' onChange={e => handleChange(e, 'stomach-and-intestines')} checked={checkIfProblemExist('stomach-and-intestines', 'vomiting') ? 'checked' : ''} />
                <label htmlFor='vomiting'>Vomiting</label>
                <input type="checkbox" id='yellow-jaundice' name='yellow-jaundice' onChange={e => handleChange(e, 'stomach-and-intestines')} checked={checkIfProblemExist('stomach-and-intestines', 'yellow-jaundice') ? 'checked' : ''} />
                <label htmlFor='yellow-jaundice'>Yellow jaundice</label>
                <input type="checkbox" id='increasing-constipation' name='increasing-constipation' onChange={e => handleChange(e, 'stomach-and-intestines')} checked={checkIfProblemExist('stomach-and-intestines', 'increasing-constipation') ? 'checked' : ''} />
                <label htmlFor='increasing-constipation'>Increasing Constipation</label>
                <input type="checkbox" id='persistent-diarrhea' name='persistent-diarrhea' onChange={e => handleChange(e, 'stomach-and-intestines')} checked={checkIfProblemExist('stomach-and-intestines', 'persistent-diarrhea') ? 'checked' : ''} />
                <label htmlFor='persistent-diarrhea'>Persistent Diarrhea</label>
                <input type="checkbox" id='blood-in-stools' name='blood-in-stools' onChange={e => handleChange(e, 'stomach-and-intestines')} checked={checkIfProblemExist('stomach-and-intestines', 'blood-in-stools') ? 'checked' : ''} />
                <label htmlFor='blood-in-stools'>Blood in stools</label>
                <input type="checkbox" id='black-stools' name='black-stools' onChange={e => handleChange(e, 'stomach-and-intestines')} checked={checkIfProblemExist('stomach-and-intestines', 'black-stools') ? 'checked' : ''} />
                <label htmlFor='black-stools'>Black Stools</label>
            </div>
        </div>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment }) => {
    return <React.Fragment>
        <label className="heading-comment">Comment </label>
        <textarea className="form-control" name="comments" placeholder="Type here..." onChange={e => handleChange(e, 'stomach-and-intestines')} value={getCategoryComment('stomach-and-intestines') ? getCategoryComment('stomach-and-intestines') : ''}></textarea>
    </React.Fragment>
}