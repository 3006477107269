import React from 'react';
import { hasRole } from '../../utils/auth'
import DashboardService from '../../services/dashboardService';
import Notification from '../../utils/notification';
import Card from './Card';

class TechnicianPharmacistDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            assigned_prescription: '',
            order_count: ''
        }
    }

    componentDidMount() {
        Promise.all([this.getDashboardCount("assigned-prescriptions"), this.getDashboardCount("order-approvals")])
        this.interval = setInterval(() => { Promise.all([this.getDashboardCount("order-approvals"), this.getDashboardCount("assigned-prescriptions")]) }, 60000);
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    getDashboardCount = (type) => {
        return new Promise((resolve, reject) => {
            return resolve(DashboardService.renderPharmacyCountInfo(type).then(resp => {
                if (type === "assigned-prescriptions") {
                    this.setState({
                        assigned_prescription: resp.data.assignedPrescriptionCount
                    })
                } else if (type === "order-approvals") {
                    this.setState({
                        order_count: resp.data.orderApprovalsCount
                    })
                }
            }).catch(error => {
                Notification.show('error', error.response.data);
                return false;
            }))
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="col-md-12">
                    <Card
                        imgSrc="/images/assigned-prescription.png"
                        label="Assigned Prescription"
                        count={this.state.assigned_prescription}
                    />
                </div>
                {hasRole(this.props.selected_user_profile, ['pharmacy-technician']) && < div className="col-md-12">
                    <Card
                        label="Sent for Approval"
                        count={this.state.order_count}
                    />
                </div>}
                {hasRole(this.props.selected_user_profile, ['pharmacist']) && < div className="col-md-12">
                    <Card
                        imgSrc="/images/order-approval-pending.png"
                        label="Order Approval Pending"
                        count={this.state.order_count}
                    />
                </div>}

            </React.Fragment>
        )
    }
}
export default TechnicianPharmacistDashboard
