import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import * as PatientAction from '../../actions/patientAction';
import Profile from '../../components/Patient/Profile';
import PaymentDetails from '../../components/Covid/PaymentDetailComponent';
import CovidTestDetails from '../../components/Covid/CovidTestDetailComponent';
import * as loaderAction from '../../actions/loaderAction';
import UploadExistingReports from '../../components/Covid/UploadExistingReports';
import Notification from '../../utils/notification';
import CovidCenterService from '../../services/covidCenterService';
import { isEmpty, reject } from 'lodash';

class ShowPatientPage extends React.Component {
    constructor(props) {
        super(props);
        const params = this.props.match.params;
        this.state = {
            patient_id: params.patientId,
            paymentdetails: '',
            upload_report_modal: false,
            patientLastCovidDetail: {},
            loadComponent: false
        };
    }
    componentDidMount() {
        Notification.clearAll()
        this.props.getPatient(this.state.patient_id, { log: true });
        this.updateCovidTestPatientId().then(() => {
            this.getLastCovidTest();
        });
    }

    updateCovidTestPatientId = () => {
        return new Promise(resolve => {
            CovidCenterService.updateCovidTestPatientId(this.state.patient_id).then(resp => {
                resolve();
            }).catch(err => {
                Notification.show('error', err.response.data);
            })
        });
    }

    getLastCovidTest = () => {
        CovidCenterService.getLastCovidTest(this.state.patient_id).then(resp => {
            this.setState({ patientLastCovidDetail: resp.data, loadComponent: true });
            this.props.loaderHide()
        }).catch(err => {
            this.props.loaderHide()
            Notification.show('error', err.response.data);
        })
    }

    componentWillUnmount() {
        this.setState({
            patient_id: '',
            paymentdetails: '',
            upload_report_modal: false
        })
        this.props.resetPatient()
    }

    updatePaymentDetail = (paymentdetails) => {
        this.setState({ paymentdetails })
    }
    handleModalShowHide = () => this.setState({ upload_report_modal: !this.state.upload_report_modal });

    render() {
        const { patient } = this.props;
        return (<React.Fragment>
            <Helmet>
                <title>Patient</title>
            </Helmet>
            <section className="container-fluid">
                <div className="row">
                    <div className="col-md-24">
                        <div className="row">
                            <div className="col-md-24">
                                <h4 className="page-title"><span>Patient Profile</span>
                                    <button to="#" className="btn btn-outline-primary ml-2" onClick={this.handleModalShowHide}>Upload Existing Report </button>
                                </h4>
                            </div>
                        </div>
                        <Profile patient={patient} selected_user_profile={this.props.selected_user_profile} />
                    </div>
                </div>
            </section>
            {!isEmpty(patient) &&  this.state.loadComponent && <PaymentDetails patient={patient}
                updatePaymentDetail={this.updatePaymentDetail}
                loaderHide={this.props.loaderHide}
                loaderShow={this.props.loaderShow}
                history={this.props.history}
                patientLastCovidDetail={this.state.patientLastCovidDetail} />}
            {!isEmpty(patient) &&  this.state.loadComponent  && <CovidTestDetails patient={patient}
                paymentdetails={this.state.paymentdetails}
                loaderHide={this.props.loaderHide}
                loaderShow={this.props.loaderShow}
                history={this.props.history}
                payment_status={!isEmpty(this.state.paymentdetails) ? this.state.paymentdetails.status : 'unpaid'}
                patientLastCovidDetail={this.state.patientLastCovidDetail} />}
            {this.state.upload_report_modal && <UploadExistingReports
                show={this.state.upload_report_modal}
                loaderHide={this.props.loaderHide}
                loaderShow={this.props.loaderShow}
                patient={patient}
                handleUploadReportModal={this.handleModalShowHide} />}
        </React.Fragment>
        )
    }
}
const mapStateToProps = (state) => ({
    patient: state.patient,
    selected_user_profile: state.selected_user_profile,
});

const mapActionsToProps = {
    getPatient: PatientAction.get,
    resetPatient: PatientAction.reset,
    loaderShow: loaderAction.loaderShow,
    loaderHide: loaderAction.loaderHide,
};
export default connect(mapStateToProps, mapActionsToProps)(ShowPatientPage);