import * as types from './actionTypes';
import AuthService from '../services/authService';
import Notification from '../utils/notification';
import * as authUtils from '../utils/auth';

export function login(email, password, history) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: types.SHOW_PAGE_LOADER });
        dispatch({ type: types.LOGIN_REQUEST });

        AuthService.login(email, password)
            .then(resp => {
                let data = resp.data;
                authUtils.setSessionToken(data);

                dispatch({ type: types.RESET_ALL });
                dispatch({ type: types.LOGIN_SUCCESS });
                dispatch({ type: types.HIDE_PAGE_LOADER });
                history.push('/select-profile');
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    Notification.show('error', error.response.data);
                    dispatch({ type: types.LOGIN_FAILURE });
                    dispatch({ type: types.HIDE_PAGE_LOADER });
                }
            });
    }
}

export function logout(history) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: types.SHOW_PAGE_LOADER });
        dispatch({ type: types.LOGOUT_REQUEST });

        return AuthService.logout()
            .then(() => {
                authUtils.removeUserProfile()
                authUtils.removeSessionToken()
                authUtils.removeNotificationCount()
                authUtils.removeTwoFa()
                dispatch({ type: types.LOGOUT_SUCCESS });
                dispatch({ type: types.RESET_ALL });
                dispatch({ type: types.HIDE_PAGE_LOADER });

                Notification.show('success', { message: 'Thank you, you have successfully logged out.' });
                history.push('/login');
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: types.LOGOUT_FAILURE, payload: error.response });
                    Notification.show('error', error.response.data);
                    dispatch({ type: types.HIDE_PAGE_LOADER });
                }
            });
    }
}

export function forgotPassword(username) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.FORGOT_PASSWORD_REQUEST, payload: { username } });

        AuthService.forgotPassword(username)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.FORGOT_PASSWORD_SUCCESS, payload: { data } });
                Notification.show('success', data);
                dispatch({ type: 'HIDE_PAGE_LOADER' });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: types.FORGOT_PASSWORD_FAILURE, payload: error.response });
                    Notification.show('error', error.response.data);
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                }
            });
    }
}


export function resetPassword(username, token, password, password_confirmation, history) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.RESET_PASSWORD_REQUEST, payload: { username, token, password, password_confirmation } });

        AuthService.resetPassword(username, token, password, password_confirmation)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.RESET_PASSWORD_SUCCESS, payload: { data } });
                Notification.show('success', data);
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                history.push('/login');
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: types.RESET_PASSWORD_FAILURE, payload: error.response });
                    Notification.show('error', error.response.data);
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                }
            });
    }
}

export function changePassword(stateData, history, selected_user_profile) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.CHANGE_PASSWORD_REQUEST, payload: { stateData } });

        AuthService.changePassword(stateData)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.CHANGE_PASSWORD_SUCCESS, payload: { data } });
                Notification.show('success', data);
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                history.push('/redirect')
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: types.CHANGE_PASSWORD_FAILURE, payload: error.response });
                    Notification.show('error', error.response.data);
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                }
            });
    }
}

export function newPassword(username, token, password, password_confirmation, history) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.NEW_PASSWORD_REQUEST, payload: { username, token, password, password_confirmation } });

        AuthService.newPassword(username, token, password, password_confirmation)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.NEW_PASSWORD_SUCCESS, payload: { data } });
                Notification.show('success', data);
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                history.push('/login');
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: types.NEW_PASSWORD_FAILURE, payload: error.response });
                    Notification.show('error', error.response.data);
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                }
            });
    }
}