import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class PatientConfirmEditService {
    static list({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute('/v1/profile-edit-queue'), requestOptions);
    }

    static add(data) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.post(apiRoute('/v1/profile-edit-queue'), data ,requestOptions);
    }

    static getById(id) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute('/v1/profile-edit-queue/' + id), requestOptions);
    }
}


export default PatientConfirmEditService;