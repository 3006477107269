import React from 'react';

export const Muscle = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <div className="heading">
            <h6> Muscular Skeletal System</h6>
        </div>
        <div className="form-group full">
            <div className="options-details">
                <input type="checkbox" id='numbness' name='numbness' onChange={e => handleChange(e, 'muscle_joint_bones')} checked={checkIfProblemExist('muscle_joint_bones', 'numbness') ? 'checked' : ''} />
                <label htmlFor='numbness'>Numbness</label>
                <input type="checkbox" id='joint-pain' name='joint-pain' onChange={e => handleChange(e, 'muscle_joint_bones')} checked={checkIfProblemExist('muscle_joint_bones', 'joint-pain') ? 'checked' : ''} />
                <label htmlFor='joint-pain'>Joint pain</label>
                <input type="checkbox" id='muscle-weakness' name='muscle-weakness' onChange={e => handleChange(e, 'muscle_joint_bones')} checked={checkIfProblemExist('muscle_joint_bones', 'muscle-weakness') ? 'checked' : ''} />
                <label htmlFor='muscle-weakness'>Muscle weakness</label>
                <input type="checkbox" id='joint-swelling' name='joint-swelling' onChange={e => handleChange(e, 'muscle_joint_bones')} checked={checkIfProblemExist('muscle_joint_bones', 'joint-swelling') ? 'checked' : ''} />
                <label htmlFor='joint-swelling'>Joint swelling</label>
            </div>
        </div>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment }) => {
    return <React.Fragment>
        <label className="heading-comment">Comment </label>
        <textarea className="form-control" name="comments" placeholder="Type here..." onChange={e => handleChange(e, 'muscle_joint_bones')} value={getCategoryComment('muscle_joint_bones') ? getCategoryComment('muscle_joint_bones') : ''}></textarea>
    </React.Fragment>
}