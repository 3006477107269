import React from 'react';
import BarGraph from '../Graph/BarGraph';

class UniquePatientGraph extends React.Component {
    handleUniquePatientView = (view) => {
        this.props.renderDashboardGraph('unique_patient', view)
    }

    render() {
        return (
            <BarGraph
                barColor="#EFB9E9"
                barLabel="Unique Patients"
                yScaleLabel="No. Of Patients"
                datasetLabels={this.props.unique_patient_graph_data.x_axis_label}
                datasetCounts={this.props.unique_patient_graph_data.counts}
                handleViewChange={this.handleUniquePatientView}
            />
        )
    }
}
export default UniquePatientGraph