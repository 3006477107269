import React from 'react';

class ViewBodyChart extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            stroke:"black",
        }
    }

    render() {
        
        return (
            <React.Fragment>
                <div>
                    <span id="anatip"></span>
                    <div id="anawrapper">
                        <div id="basea">
                        
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="800px" height="1360px" viewBox="0 0 800 1360" xmlSpace="preserve" id="men_back" class="anatomys">
                            
                            {this.props.pathConfig && this.props.pathConfig.map((path, i) => (
                            <path id={"male-back_" + path.name}  className={path.className} fill={path.fill} style={{cursor:'auto'}} stroke={this.state.stroke}  d={path.d} 
                            >
                                <title>{path.value}</title>
                            </path>
                            ))}
                        
                            <g id="anaspotsa"></g>
                        </svg>
                        </div >
                    </div >
                    
                </div>
                
            </React.Fragment>
        )
    }
}
export default ViewBodyChart;



