import * as types from './actionTypes';
import UserManagementService from '../services/userManagementService';
import Notification from '../utils/notification';

export function list({ page, sizePerPage, sortBy, filter }) {
    return dispatch => {
        dispatch({ type: types.USER_MANAGEMENT_LIST_REQUEST, request: { page, sizePerPage, sortBy, filter } });

        UserManagementService.list({ page, sizePerPage, sortBy, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.USER_MANAGEMENT_LIST_SUCCESS, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: types.USER_MANAGEMENT_LIST_FAILED, ...error.response });
                    Notification.show('error', error.response.data);
                }
            });
    }
}
