export default {
    alert: {
        isloading: false,
        status: '',
        type: '',
        message: '',
        errors: []
    },
    loader: {
        isloading: false
    },
    patient: {},
    patient_data: {
        weight_data: [],
        bloodpressure_data: []
    },
    pagination: {
        is_requested: false,
        request: {},
        data: [],
        meta: {
            current_page: 0,
            per_page: 0,
            total: 0,
        }
    },
    prescription: {
        action: '',
        paginate: {
            isLoading: false,
            filter: {},
            data: [],
            meta: {
                current_page: 0,
                per_page: 0,
                total: 0,
            }
        },
        fetch: {
        },
        new: {
        }
    },
    prescriptionOrder: {
        action: '',
        paginate: {
            filter: {},
            data: [],
            meta: {
                current_page: 0,
                per_page: 0,
                total: 0,
            }
        },
        fetch: {
        },
        new: {
        }
    },
    authActions: {
        isLoading: false,
        isStatus: '',
        request: {},
        response: {}
    },
    profile: {
        is_updated: false,
    },
    changedPrescription: {
    },
    user_notification: {
    },
    filters: {},
    priceList: {},
    locations: {},
    urgent_care: {
        action: '',
        paginate: {
            isLoading: false,
            filter: {},
            data: [],
            meta: {
                current_page: 0,
                per_page: 0,
                total: 0,
            }
        },
        fetch: {
        },
        new: {
        }
    },
    urgent_care_approval: {
        action: '',
        paginate: {
            isLoading: false,
            filter: {},
            data: [],
            meta: {
                current_page: 0,
                per_page: 0,
                total: 0,
            }
        },
        fetch: {
        },
        new: {
        }
    },
}