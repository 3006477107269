import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
import { hasRole } from '../utils/auth';
import _ from "lodash";
import { MenuItems } from '../config/menu';
import ContextHelp from '../pages/ContextHelp';
import RoleSerivce from '../services/roleService';

class Sidebar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            full_name: null,
            providers: null,
            role_slug: null,
            menuItems: []
        }
    }

    componentDidMount() {
        if (!_.isEmpty(this.props.selected_user_profile)) {
            const user_profile = this.props.selected_user_profile;
            this.setState({
                full_name: user_profile.user.full_name,
                providers: user_profile.service_provider.service_provider_type,
                role_slug: user_profile.role_slug,
                menuItems: (MenuItems[user_profile.service_provider.service_provider_type] ? MenuItems[user_profile.service_provider.service_provider_type] : []) 
            })
        }
    }

    renderMenuItems = () => {
        const { service_provider } = this.props.selected_user_profile
        let { selected_user_profile } = this.props
        return this.state.menuItems.map((key, i) => {
            if (key.id === "billing-and-accounting" && service_provider.no_commission) {
                return false;
            }
            
            if (key.is_active === false) {
                return false
            }

            if ( (key.id === "procedure-list" || key.id === "consultation-fee") && hasRole(selected_user_profile, ["associate-doctor"]) && selected_user_profile.is_procedure_listed === false ){
                return false
            }

            return hasRole(this.state, key.roles) && (<li key={i} className="nav-item" >
                <NavLink exact to={key.url} className="nav-link" data-index={key.data_index} data-tooltip={key.data_tooltip}><span className={key.icon_class}></span>{key.title}</NavLink>
            </li>)
        })
    }

    render() {
        return (
            <React.Fragment>
                <nav className="no-print navbar navbar-expand-sm bg-light navbar-light">
                    <div className="user-details">
                        {this.state.full_name && _.startCase(_.camelCase(this.state.full_name))}
                        <span>{this.state.role_slug && RoleSerivce.getRoleNameBySlug(this.state.role_slug)}</span>
                    </div>
                    {this.state.providers && <ul className="navbar-nav">
                        {this.renderMenuItems()}
                    </ul>}
                </nav>
                <ContextHelp />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    selected_user_profile: state.selected_user_profile,
})
export default connect(mapStateToProps)(Sidebar);