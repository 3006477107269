import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function (state = initialState.loader, { type }) {
    switch (type) {
        case types.SHOW_PAGE_LOADER:
            return {
                isloading: true,
            };
        case types.HIDE_PAGE_LOADER:
            return {
                isloading: false,
            };
        default:
            return state;
    }    
}