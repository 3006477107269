import React from "react";
import "./pdf.css";

class PrintConsentPage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { content } = this.props;
        return (
            <div className="print-main">
                <h1 className="text-center">Covid-19 Consent</h1>
                <h3> Name:  {content.name} <br />
                    Gender:  {content.gender}<br />
                    Email:  {content.email}<br />
                    Contact:  {content.contact}<br /></h3>
            </div>
        );
    }
}

export default PrintConsentPage;
