import React from 'react';
import { Helmet } from 'react-helmet';
import { capitalize, isEmpty } from 'lodash';
import { getAgeString, getDateFormat } from '../../utils/helpers';
import { hasRole } from '../../utils/auth';
import PrescriptionService from '../../services/prescriptionService';
import { connect } from 'react-redux';
import ShowMedicineBlock from '../../components/Prescription/ShowMedicineBlock';
import ShowRadiologyBlock from '../../components/Prescription/ShowRadiologyBlock';
import ShowPathologyBlock from '../../components/Prescription/ShowPathologyBlock';
import Notification from '../../utils/notification';
import * as LoaderAction from '../../actions/loaderAction';
import PinModal from '../../components/Signature/PinModal';
import CancelModal from '../../components/CancelModal';

class EditPrescriptionSummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            edited_prescription: '',
            prescriptionId: '',
            showPinModal: false,
            showCancelModal: false,
        }
    }
    handleCancelClose = () => {
        this.setState({ showCancelModal: false })
    }

    handleCancelRedirect = () => {
        this.setState({ showCancelModal: false })
        this.props.history.push("/patients/search");
    }
    handleCancel = () => {
        this.setState({ showCancelModal: true })
    }
    componentDidMount() {
        let { state } = this.props.location;
        if (!state) {
            let { params } = this.props.match
            this.props.history.push('/prescriptions/' + params.prescriptionId + '/edit')
        } else {
            this.setState({
                edited_prescription: state.edited_prescription,
                prescriptionId: this.props.match.params.prescriptionId
            })
        }
    }
    handleSubmit = e => {
        let { edited_prescription } = this.state;
        e.preventDefault();
        let data = {
            patient_id: edited_prescription.patient.patient_id,
            prescription_drugs: !edited_prescription.edit_medicine ? edited_prescription.medicines.prescription_medicine_item : [],
            prescription_pathology_tests: !edited_prescription.edit_pathology ? edited_prescription.pathology.prescription_pathology_test_items : [],
            prescription_radiology_tests: !edited_prescription.edit_radiology ? edited_prescription.radiology.prescription_radiology_test_items : []
        }
        this.props.showLoader();
        PrescriptionService.updatePrescription(this.state.prescriptionId, data).then(response => {
            if (!isEmpty(response) && !isEmpty(this.state.prescriptionId)) {
                Notification.show('success', response.data);
                this.showPinModal();
            }
            this.props.hideLoader();
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data);
        })
    }
    showPinModal = () => {
        this.setState({ showPinModal: true });
    }
    handleClose = () => {
        this.setState({ showPinModal: false });
    }
    onSuccessRedirect = () => {
        this.setState({ showPinModal: false });
        this.props.history.push("/prescriptions/" + this.state.prescriptionId);
    }
    render() {
        let { edited_prescription } = this.state;
        return (<React.Fragment >
            <Helmet>
                <title>Edit Prescription Summary</title>
            </Helmet>
            <div className="row">
                <div className="col-md-24">
                    <h4 className="page-title">Edit Prescription</h4>
                    {edited_prescription && edited_prescription.patient && <div className="content-wrapper padding-bottom-10">
                        <h6>Personal Info</h6>
                        <ul className="prescription-patient-details">
                            <li>Name: <span>{edited_prescription.patient.full_name}</span></li>
                            <li>Date of Birth: <span>{edited_prescription.patient.date_of_birth && getDateFormat(edited_prescription.patient.date_of_birth)} ({getAgeString(edited_prescription.patient.date_of_birth)})</span></li>
                            <li>Gender: <span>{capitalize(edited_prescription.patient.gender)}</span></li>
                        </ul>
                    </div>}
                    <div className="content-wrapper padding-bottom-10">
                        <h6>Prescription and Recommendation</h6>

                        {edited_prescription && !edited_prescription.edit_medicine && edited_prescription.medicines && edited_prescription.medicines.status === "prescribed" && <div className="content-wrapper padding-bottom-10">
                            <h6>Medication Info</h6>
                            {edited_prescription.medicines.prescription_medicine_item.map((medicine, idx) => (
                                <ShowMedicineBlock medicine={medicine} key={idx} />
                            ))}
                        </div>}
                        {edited_prescription && !edited_prescription.edit_pathology && edited_prescription.pathology && edited_prescription.pathology.status === "prescribed" && <div className="content-wrapper padding-bottom-10">
                            <h6>Pathology Test</h6>
                            <ul className="prescription-patient-details medicine-details">
                                <div className="content-wrapper">
                                    <ul className="doctor-tests">
                                        {edited_prescription.pathology.prescription_pathology_test_items.map((pathology, idx) => (
                                            <ShowPathologyBlock pathology={pathology} key={idx} />
                                        ))}
                                    </ul>
                                </div>
                            </ul>
                        </div>}
                        {edited_prescription && !edited_prescription.edit_radiology && edited_prescription.radiology && edited_prescription.radiology.status === 'prescribed' && <div className="content-wrapper padding-bottom-10">
                            <h6>Radiology Test</h6>
                            <ul className="prescription-patient-details medicine-details">
                                <div className="content-wrapper">
                                    <ul className="doctor-tests">
                                        {edited_prescription.radiology.prescription_radiology_test_items.map((radiology, idx) => (
                                            <ShowRadiologyBlock radiology={radiology} key={idx} />
                                        ))}
                                    </ul>
                                </div>
                            </ul>
                        </div>}
                    </div>
                </div>
                {hasRole(this.props.selected_user_profile, ['doctor', 'associate-doctor']) && <div className="button-wrapper align-right">
                    <button type="submit" className="btn btn-primary" onClick={e => this.handleSubmit(e)}>Submit</button>
                    <button type="cancel" className="btn btn-default btn-outline-primary" onClick={this.handleCancel}>Cancel</button>
                </div>}
                {this.state.showPinModal &&
                    <PinModal show={this.state.showPinModal} handleClose={this.handleClose} id={this.state.prescriptionId} type="prescription" onSuccess={this.onSuccessRedirect} />
                }
                {this.state.showCancelModal &&
                    <CancelModal showModal={this.state.showCancelModal} handleRedirect={this.handleCancelRedirect} handleClose={this.handleCancelClose} />
                }
            </div>
        </React.Fragment>)
    }
}
const mapStateToProps = (state) => ({
    selected_user_profile: state.selected_user_profile,
})
const mapActionsToProps = {
    hideLoader: LoaderAction.loaderHide,
    showLoader: LoaderAction.loaderShow,
};
export default connect(mapStateToProps, mapActionsToProps)(EditPrescriptionSummary)