import * as types from "./actionTypes";
import Notification from '../utils/notification';
import MedicalCertificateService from "../services/medicalCertificateService";

export function list({ page, sizePerPage, sortBy, searchText, filter}) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({
          type: types.MEDICAL_CERTIFICATE_LIST_REQUEST,
          request: { page, sizePerPage, sortBy, searchText}
        });

        MedicalCertificateService.list({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: types.MEDICAL_CERTIFICATE_LIST_SUCCESS, ...data });
            })
            .catch(error => {
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                if (error.response && error.response.status) {
                    dispatch({ type: types.MEDICAL_CERTIFICATE_LIST_FAILED, error: error.response})
                    let errorData = error.response.data;
                    Notification.show('error', errorData);
                }
            });
    }
}