import React from 'react';
import UploadedFileSpan from './UploadedFileSpan';
import PathoRadioService from '../services/pathoRadioService';
import Notification from '../utils/notification';

class TestBlock extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            uploadedFiles: [],
            data: '',
            documents: this.props.data.documents ? [...this.props.data.documents] : []
        }
    }

    selectedFileHandler = (e, data) => {
        const uploadFile = [...e.target.files];
        let concat = this.state.uploadedFiles.concat(uploadFile);
        this.setState({
            uploadedFiles: concat,
            data: data
        })
    }

    deleteHandler = key => {
        this.state.uploadedFiles.splice(key, 1);
        this.setState({
            uploadedFiles: this.state.uploadedFiles
        })
    }

    updateFileStatus = status => {
        this.props.updateFileStatus(status)
    }

    handleDelete = (e, index) => {
        const document_id = e.target.name;
        const order_item_id = this.props.data.order_item_id;


        if (this.props.service === 'radiology') {
            const order_id = this.props.data.order_radiology_tests_id;

            PathoRadioService.fileDeleteForRadiology(order_id, order_item_id, document_id).then(resp => {
                this.state.documents.splice(index, 1);
                this.setState({
                    documents: this.state.documents
                })
            }).catch(error => {
                Notification.show('error', error.response.data)
            })
        }
        else if (this.props.service === 'pathology') {
            const order_id = this.props.data.order_pathology_tests_id;
            PathoRadioService.fileDeleteForPathology(order_id, order_item_id, document_id).then(resp => {
                this.state.documents.splice(index, 1);
                this.setState({
                    documents: this.state.documents
                })
            }).catch(error => {
                Notification.show('error', error.response.data)
            })
        }


    }

    renderUploadedFile() {
        return (
            this.state.documents ? this.state.documents.map((file, index) => (
                <span className='upload completed' key={index}>
                    <a href onClick={this.props.documentDownload.bind(this, file.document_id)}>{file.file_name}</a>
                    <a href className="close" onClick={(e) => { if (window.confirm('Delete the item?')) { this.handleDelete(e, index) }; }} value={file.document_id} name={file.document_id} hidden={this.props.status === 'fulfilled' ? true : false}> </a>
                </span>
            )) : false
        )

    }

    render() {
        const { data } = this.props
        return (
            <React.Fragment>
                {
                    <div className="content-wrapper padding-bottom-10" >
                        <ul className="prescription-patient-details test-details">
                            <li>Test Name: <span>{data.test_name + " - " + data.test_category}</span></li>
                            {this.props.service === 'pathology' && <li>Description: <span>{(data.test_description ? data.test_description : '--')}</span></li>}
                            <li className="no-border">Remark: <span>{data.test_remark}</span></li>
                            <li className="no-border">Upload Document: <span>
                                {this.renderUploadedFile()}

                                {this.state.uploadedFiles.map((file, index) => (
                                    <UploadedFileSpan file={file} deleteHandler={this.deleteHandler} key={index} index={index} data={this.state.data} service={this.props.service} order_id={this.props.order_id} updateFileStatus={this.updateFileStatus} />
                                ))}
                                <input type="file" ref={fileInput => this.fileInput = fileInput} onChange={e => this.selectedFileHandler(e, data)} name="file" id="file" hidden multiple accept='.pdf,.jpg,.png,.mp4' />
                                <a href onClick={() => this.fileInput.click()} className="btn btn-report" name="file" id="file" hidden={this.props.status === 'fulfilled' ? true : false}>Add Reports</a></span>
                                {this.props.status !== 'fulfilled' && <span className="text-info">Please attach jpg, png, pdf or mp4</span>}
                            </li>
                        </ul>
                    </div>
                }

            </React.Fragment>
        );
    }
}

export default TestBlock