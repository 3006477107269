import React from 'react';
import * as HELPER from '../../utils/helpers';
import PatientService from '../../services/patientService';
import _ from "lodash";

class PatientRemarksBlock extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            data: null,
        };
    }
    
    fetchData = () => {
        if (this.state.data === null && this.state.loading === false) {
            this.setState({
                loading: true
            });

            PatientService.listRemarks(this.props.patientId)
            .then(resp => {
                let data = resp.data;
                this.setState({
                    loading: false,
                    data: data
                });
            })
            .catch(error => {
                this.setState({
                    loading: false,
                    data: []
                });
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="tooltip-custom" onMouseOver={this.fetchData}>
                    <div className="left full-width">
                        {this.state.loading && <div>loading...</div>}
                        {this.state.data && <table>
                            <thead>
                                <tr>
                                    <td width="8%">Date</td>
                                    <td width="90%">Remark</td>
                                </tr>
                            </thead>
                            <tbody>
                                {_.isEmpty(this.state.data) && <tr><td colSpan="2" className="text-success">No past record exist.</td></tr>}
                                {this.state.loading && <tr><td colSpan="2">loading...</td></tr>}
                                {
                                    this.state.data && this.state.data.map((item, index) => (
                                        <tr>
                                            <td>{HELPER.getDateFormat(item.created_at)}</td>
                                            <td>{item.remarks}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>}
                    </div>
                </div>
            </React.Fragment>
        );
    }

}

export default PatientRemarksBlock;