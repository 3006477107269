import React from "react";
import SimpleReactValidator from "simple-react-validator";
import { Helmet } from 'react-helmet';
import OfflinePrescriptionService from '../services/offlinePrescriptionService';
import _ from 'lodash';
import Notification from '../utils/notification';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import PathologyTestBlock from "../components/Prescription/PathologyTestBlock";
import RadiologyTestBlock from "../components/Prescription/RadiologyTestBlock";
import MedicineBlock from '../components/Prescription/MedicineBlock';
import * as OfflinePrescriptionAction from '../actions/offlinePrescriptionAction';
import { handleRedirect } from '../utils/auth';

class CreateOfflinePrescription extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            temporary_id: "",
            status: "",
            pathology: [],
            radiology: [],
            medicines: [],
            prescription_type: this.props.selected_user_profile.service_provider.service_provider_type,
            doctor_id: "",
            patient_id: "",
            patient_info: {
                patient_name: "",
                age: "",
                gender: "",
                contact_number: ""
            },
            doctor_info: {
                doctor_name: "",
                license_no: "",
                doctor_contact_number: "",
                email: ""
            },
            prescription_date: ""
        }
        this.validator = new SimpleReactValidator();
        this.validatorOne = new SimpleReactValidator();
    }
    handleSubmit = e => {
        e.preventDefault();
        if (!this.validatorOne.allValid()) {
            this.validatorOne.showMessages();
            this.forceUpdate();
            return false;
        }

        OfflinePrescriptionService.getByTemporaryId(this.state.temporary_id).then(resp => {
            let data = resp.data;
            const service_provider_type = this.props.selected_user_profile.service_provider.service_provider_type;

            if (!_.isEmpty(data)) {
                if (service_provider_type === 'pathology') {
                    this.props.history.push('/patient/pathology-test/' + data.prescription_id);
                }
                else if (service_provider_type === 'radiology') {
                    this.props.history.push('/patient/radiology-test/' + data.prescription_id);
                } else if (service_provider_type === 'pharmacy') {
                    this.props.history.push('/pharmacy/prescription/' + data.prescription_id + '/view');
                }
                else {
                    Notification.show('info', { 'message': 'Fill the prescription form.' });
                    this.setState({
                        status: 'failed'
                    })
                }
            }
        }).catch(error => {
            if (error.response.status === 404) {
                Notification.show('info', { 'message': 'Fill the prescription form.' });
                this.setState({
                    status: 'failed'
                })
            }
        })
    }
    changeHandler = e => {
        const patient_param = ["patient_name", "age", "gender", "contact_number"];
        const doctor_param = ["doctor_name", "license_no", "doctor_contact_number", "email"];
        if (patient_param.includes(e.target.name)) {
            const patient_info = this.state.patient_info;
            patient_info[e.target.name] = e.target.value;

            this.setState({
                patient_info: patient_info
            });
        }
        else if (doctor_param.includes(e.target.name)) {
            const doctor_info = this.state.doctor_info;
            doctor_info[e.target.name] = e.target.value;

            this.setState({
                doctor_info: doctor_info
            });
        }
        else
            this.setState({ [e.target.name]: e.target.value });
    }
    /*pathology block*/
    handlePathologyChange = (idx, data) => {
        const newPathology = this.state.pathology.map((pathology, sidx) => {
            if (idx !== sidx) return pathology;

            return { ...pathology, ...data };
        });
        this.setState({ pathology: newPathology });
    };

    handleAddPathology = () => {
        this.setState({
            pathology: this.state.pathology.concat([{
                test_id: '',
                test_type: '',
                test_category: '',
                test_name: '',
                test_description: '',
                remark: '',
                is_other: false
            }])
        });
    };

    handleRemovePathologyTest = (event) => {
        const idx = Number(event.target.dataset["id"]);
        const pathology = this.state.pathology.filter((s, sidx) => idx !== sidx);
        this.setState({ pathology: pathology });
    };
    /*end pathology block*/

    /*radiology*/
    handleRadiologyChange = (idx, data) => {
        const newRadiology = this.state.radiology.map((radiology, sidx) => {
            if (idx !== sidx) return radiology;

            return { ...radiology, ...data };
        });

        this.setState({ radiology: newRadiology });

    };

    handleAddRadiology = () => {
        this.setState({
            radiology: this.state.radiology.concat([{
                test_id: '',
                test_category: '',
                test_name: '',
                test_description: '',
                remark: '',
                is_other: false
            }])
        });
    };

    handleRemoveRadiologyTest = (event) => {
        const idx = Number(event.target.dataset["id"]);
        const radiology = this.state.radiology.filter((s, sidx) => idx !== sidx);
        this.setState({ radiology: radiology });
    };
    /*end radiology block*/
    /*medicine block*/
    handleMedicineChange = (idx, data) => {
        const newMedicines = this.state.medicines.map((medicine, sidx) => {
            if (idx !== sidx) return medicine;

            return { ...medicine, ...data };
        });

        this.setState({ medicines: newMedicines });

    };

    handleAddMedicine = () => {
        this.setState({
            medicines: this.state.medicines.concat([{
                drug_id: '',
                drug_name: '',
                drug_generic_id: '',
                drug_generic_name: '',
                dosage: '',
                meal: '',
                duration: '',
                duration_type: '',
                substitution_not_allowed: false,
                quantity: '',
                drug_type: '',
                is_other: false,
                over_dosage: false
            }])
        });
    };

    handleRemoveMedicine = (event) => {
        const idx = Number(event.target.dataset['id']);
        const medicines = this.state.medicines.filter((s, sidx) => idx !== sidx);
        this.setState({ medicines: medicines });
    };
    /*end medicine block*/

    submitPrescription = (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        const postData = JSON.stringify(this.state);
        const service_provider_type = this.props.selected_user_profile.service_provider.service_provider_type;
        this.props.createOfflinePrescription(postData, this.state.temporary_id, service_provider_type, this.props.history)
    }

    render() {
        const service_provider_type = this.props.selected_user_profile.service_provider.service_provider_type
        this.validator.purgeFields();
        return (
            <div>
                <Helmet>
                    <title>Offline Prescription</title>
                </Helmet>
                <div className="row">
                    <div className="col-md-24">
                        <h4 className="page-title">Offline Prescription</h4>
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-24 search-by-id offline-search">
                                    <form onSubmit={this.handleSubmit} id="number_search_form">
                                        <div className="row">
                                            <div className="col-md-21">
                                                <fieldset>
                                                    <input type="text" className="form-control" id="temporary_id" name="temporary_id" placeholder="Search temporary ID" onChange={this.changeHandler} />
                                                    {this.validatorOne.message('temporary id', this.state.temporary_id, 'required')}
                                                </fieldset>
                                            </div>
                                            <div className="col-md-3">
                                                <button type="submit" className="btn btn-primary card_number_search_btn" form="number_search_form">Search</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        {
                            this.state.status === 'failed' && <div className="row">
                                <div className="col-md-24">
                                    <h4 className="page-title">Add Prescription</h4>
                                    <div className="content-wrapper offline-precription padding-bottom-10">
                                        <h6>Patient Details</h6>
                                        <ul className="prescription-patient-details">
                                            <li>Patient ID: <span>
                                                <input type="text" name="patient_id" className="form-control" value={this.state.patient_id} onChange={this.changeHandler} />
                                                {this.validator.message('Patient id', this.state.patient_id, 'required')}
                                            </span></li>
                                            <li>Name: <span>
                                                <input type="text" name="patient_name" className="form-control" value={this.state.patient_info.patient_name} onChange={this.changeHandler} />
                                                {this.validator.message('patient_name', this.state.patient_info.patient_name, 'required')}
                                            </span></li>
                                            <li>Age: <span>
                                                <input type="number" name="age" className="form-control" value={this.state.patient_info.age} onChange={this.changeHandler} />
                                                {this.validator.message('age', this.state.patient_info.age, 'required')}
                                            </span></li>
                                            <li>Gender: <span>
                                                <select className="form-control" name="gender" value={this.state.patient_info.gender} onChange={this.changeHandler}>
                                                    <option value="" defaultValue disabled>--Select Gender---</option>
                                                    <option value="female">Female</option>
                                                    <option value="male">Male</option>
                                                    <option value="other">Other</option>
                                                </select>
                                                {this.validator.message('gender', this.state.patient_info.gender, 'required')}
                                            </span></li>
                                            <li>Contact Number: <span>
                                                <input type="number" name="contact_number" className="form-control" value={this.state.patient_info.contact_number} onChange={this.changeHandler} />
                                                {this.validator.message('contact_number', this.state.patient_info.contact_number, 'required|phone')}
                                            </span></li>
                                        </ul>
                                    </div>
                                    <div className="content-wrapper offline-precription padding-bottom-10">
                                        <h6>Doctor Info</h6>
                                        <ul className="prescription-patient-details">
                                            <li>Doctor ID: <span>
                                                <input type="text" className="form-control" onChange={this.changeHandler} name="doctor_id" value={this.state.doctor_id} />
                                                {this.validator.message('Doctor id', this.state.doctor_id, 'required')}
                                            </span></li>
                                            <li>Doctor Name: <span><input type="text" className="form-control" onChange={this.changeHandler} name="doctor_name" value={this.state.doctor_info.doctor_name} />
                                                {this.validator.message('Name', this.state.doctor_info.doctor_name, 'required')}</span></li>
                                            <li>Registration No.: <span><input type="text" className="form-control" onChange={this.changeHandler} name="license_no" value={this.state.doctor_info.license_no} />
                                                {this.validator.message('license no', this.state.doctor_info.license_no, 'required')}</span></li>
                                            <li>Contact Number: <span>
                                                <input type="number" name="doctor_contact_number" className="form-control" value={this.state.doctor_info.doctor_contact_number} onChange={this.changeHandler} />
                                                {this.validator.message('doctor_contact_number', this.state.doctor_info.doctor_contact_number, 'required|phone')}
                                            </span></li>
                                            <li>Email: <span>
                                                <input type="email" name="email" className="form-control" value={this.state.doctor_info.email} onChange={this.changeHandler} />
                                                {this.validator.message('email', this.state.doctor_info.email, 'required')}
                                            </span></li>
                                            <li>Prescription Date: <span>
                                                <input type="date" className="form-control date" name="prescription_date" value={this.state.prescription_date} onChange={this.changeHandler} />
                                                {this.validator.message('prescription date', this.state.prescription_date, 'required')}
                                            </span></li>
                                        </ul>
                                    </div>
                                    {service_provider_type === 'pathology' && <div className="content-wrapper padding-bottom-10">
                                        <h6>Pathology</h6>
                                        <ul className="prescription-patient-details medicine-details">
                                            {this.state.pathology.map((pathology, idx) => (
                                                <PathologyTestBlock
                                                    pathology={pathology}
                                                    idx={idx}
                                                    handleChangePathology={this.handlePathologyChange}
                                                    handleRemovePathologyTest={this.handleRemovePathologyTest}
                                                    validator={this.validator}
                                                    key={idx}
                                                    show_cancel
                                                />
                                            ))}

                                            <button onClick={this.handleAddPathology} className="add-new btn btn-primary" >Add Pathology</button>
                                        </ul>
                                    </div>}
                                    {service_provider_type === 'radiology' && <div className="content-wrapper padding-bottom-10">
                                        <h6>Radiology</h6>
                                        <ul className="prescription-patient-details medicine-details">
                                            {this.state.radiology.map((radiology, idx) => (
                                                <RadiologyTestBlock
                                                    radiology={radiology}
                                                    idx={idx}
                                                    handleChangeRadiology={this.handleRadiologyChange}
                                                    handleRemoveRadiologyTest={this.handleRemoveRadiologyTest}
                                                    validator={this.validator}
                                                    show_cancel
                                                />
                                            ))}
                                            <button onClick={this.handleAddRadiology} class="add-new btn btn-primary" >Add Radiology</button>
                                        </ul>
                                    </div>}
                                    {
                                        service_provider_type === 'pharmacy' && <div className="content-wrapper padding-bottom-10">
                                            <h6>Medicine</h6>
                                            <ul className="prescription-patient-details medicine-details">
                                                {this.state.medicines.map((medicine, idx) => (
                                                    <MedicineBlock
                                                        medicine={medicine}
                                                        idx={idx}
                                                        handleChangeMedicine={this.handleMedicineChange}
                                                        handleRemoveMedicine={this.handleRemoveMedicine}
                                                        validator={this.validator}
                                                        selected_user_profile={this.props.selected_user_profile}
                                                        show_cancel
                                                        not_required={false}
                                                    />
                                                ))}
                                                {this.validator.message('medicines', this.state.medicines, 'required')}
                                            </ul>
                                            <button onClick={this.handleAddMedicine} className="add-new btn btn-primary">Add Medicine</button>
                                        </div>
                                    }
                                </div>
                                <div className="button-wrapper align-right">
                                    <button className="btn btn-primary" onClick={(e) => { if (window.confirm('Are You Sure?')) { this.submitPrescription(e) }; }} >Proceed To Order</button>
                                    <Link to={handleRedirect(this.props.selected_user_profile)} className="btn btn-default btn-outline-primary">Cancel</Link>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div >
        )
    }
}
const mapStateToProps = (state) => ({
    selected_user_profile: state.selected_user_profile,
});

const mapActionsToProps = ({
    createOfflinePrescription: OfflinePrescriptionAction.fetchOfflinePrescriptionId
})

export default connect(mapStateToProps, mapActionsToProps)(CreateOfflinePrescription)
