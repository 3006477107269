import * as types from './actionTypes';
import UrgentCareService from '../services/urgentCareService';
import Notification from '../utils/notification';

export function resetList() {
    return dispatch => {
        dispatch({ type: types.CLEAR_LIST_URGENT_CARE });
    }
}

export function list({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.LIST_URGENT_CARE, request: { page, sizePerPage, sortBy, filter } });

        UrgentCareService.list({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: types.LIST_URGENT_CARE, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}

export function resetListApproval() {
    return dispatch => {
        dispatch({ type: types.CLEAR_LIST_URGENT_CARE_APPROVAL });
    }
}

export function listApproval({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.LIST_URGENT_CARE_APPROVAL, request: { page, sizePerPage, sortBy, filter } });

        UrgentCareService.listApproval({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: types.LIST_URGENT_CARE_APPROVAL, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}