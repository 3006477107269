import React from 'react'
import _ from "lodash";
import * as Helper from "../../utils/helpers";

export default function PatientInfoSection({ isLoading=false, patient }) {
    return (
        <div className="row">
            <div className="col-md-5">
                <div className="content-wrapper padding-none patient-pic">
                    {patient && !_.isEmpty(patient.profile_image) && <img src={patient.profile_image.thumbnail_url} alt="Patient Profile" />}
                </div>
            </div>
            <div className="col-md-9">
                <div className="content-wrapper padding-none">
                    <h5> Personal Information </h5>
                    <ul className="details-listing full-width clearfix">
                        <li> <span>Full Name:</span>{patient && patient.full_name }</li>
                        <li> <span>Gender:</span> {patient && _.capitalize(patient.gender) } </li>
                        <li> <span>Date Of Birth:</span> {patient && Helper.getDateFormat(patient.date_of_birth)} ({patient && Helper.getAgeString(patient.date_of_birth)})</li>
                        <li> <span>Insurance:</span> {patient && Helper.patientInsurance(patient.insurance_type) } </li>
                        <li> <span>Occupation:</span> {patient && patient.occupation } </li>
                        <li> <span>Kin Name:</span> {patient && patient.kin_name } </li>
                        <li> <span>Kin Contact Number:</span> {patient && patient.kin_number} </li>
                    </ul>
                </div>
            </div>

            <div className="col-md-10">
                <div className="content-wrapper padding-none">
                    <h5>
                        Contact Detail
                    </h5>
                    <ul className="details-listing clearfix full-width">
                        <li> <span>Contact Number:</span> {patient && patient.contact_number} </li>
                        <li> <span>Alternate Number / Landline Number:</span> {patient && patient.alt_contact_number} </li>
                        <li> <span>Email:</span> {patient && patient.email} </li>
                        <li> <span>Address:</span> 
                            <span className="address">{patient && Helper.fullAddressStr(patient.address) }</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
