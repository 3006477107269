import React from 'react';
import { GaenacHistory } from '../../../../../config/add_prescription';
import { isNull } from 'lodash';

export const GynaecologyHistory = ({ gynaecological_history }) => {
    return (
        <div className="content-wrapper padding-bottom-10">
            <h6 className="inner_heading">Gynaecological History</h6>
            <ul className="prescription-filed-details auto-width">
                <li>Age of first period :
                <span>{gynaecological_history.age_of_first_period ? gynaecological_history.age_of_first_period : '--'}</span>
                </li>
                <li>Regular Period? :
                <span>{gynaecological_history.is_regular_periods === true ? 'Yes' : (gynaecological_history.is_regular_periods === false ? 'No' : '--')}</span>
                </li>
                <li>Duration of Period :
                <span>{gynaecological_history.bleeding_between_periods ? gynaecological_history.bleeding_between_periods : '--'}</span>
                </li>
                <li>Menopause :
                <span>{gynaecological_history.is_menopause === true ? 'Yes' : (gynaecological_history.is_menopause === false ? 'No' : '--')}</span>
                </li>
                <li>Menopause Comment :
                <span>{gynaecological_history.menopause_comment ? gynaecological_history.menopause_comment : '--'}</span>
                </li>
                <li>Menopause start date :
                <span>{gynaecological_history.menopause_age ? gynaecological_history.menopause_age : '--'}</span>
                </li>
                <li>Contraception :
                <span>{gynaecological_history.contraception ? GaenacHistory.contraception[gynaecological_history.contraception] : '--'}</span>
                </li>
                <li>Pap Smear :
                <span>{gynaecological_history.pap_smear ? GaenacHistory.papsmear[gynaecological_history.pap_smear] : '--'}</span>
                </li>
                <li>Pap Smear Test Date:
                <span>{gynaecological_history.pap_smear_test_date || '--'}</span>
                </li>
            </ul>
        </div>
    )
}

export const ReproductionHistory = ({ reproductive_history }) => {
    return (
        <div className="content-wrapper padding-bottom-10">
            <h6 className="inner_heading">Reproductive History</h6>
            <ul className="prescription-filed-details auto-width">
                <li>Number of Pregnancies :
                <span>{!isNull(reproductive_history.pregnancies_number) ? reproductive_history.pregnancies_number : '--'}</span>
                </li>
                <li>Number of Miscarriage :
                <span>{!isNull(reproductive_history.miscarrages_number) ? reproductive_history.miscarrages_number : '--'}</span>
                </li>
                <li>Number of Abortions :
                <span>{!isNull(reproductive_history.abortions_number) ? reproductive_history.abortions_number : '--'}</span>
                </li>
            </ul>
        </div>
    )
}
export const Allergy = ({ allergies }) => {
    return (
        <div className="content-wrapper padding-bottom-10">
            <h6 className="inner_heading">Allergies to Contraceptives</h6>
            <ul className="prescription-filed-details auto-width">
                <li>Allergies to medications/substances :
                <span>{allergies.allergies ? allergies.allergies : '--'}</span>
                </li>
                <li>Ever been hospitalized for allergic reaction? :
                <span>{allergies.is_hospitalized_allergic_reaction === true ? 'Yes' : (allergies.is_hospitalized_allergic_reaction === false ? 'No' : '--')}</span>
                </li>
                <li>Comment :
                    <span>{allergies.allergies_comment ? allergies.allergies_comment : '--'}</span>
                </li>
            </ul>
        </div>
    )
}