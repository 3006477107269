import React from 'react';
import DashboardService from '../../services/dashboardService';
import Notification from '../../utils/notification';
import Card from './Card';

class DoctorWorkflow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            assignedPrescription: '',
            prescriptionApprovalPending: '',
            requestPendingCount: '',
        }
    }

    componentDidMount() {
        Promise.all([this.getDashboardCounts("request-pendings"), this.getDashboardCounts("assigned-prescriptions"), this.getDashboardCounts("approve-prescriptions")])
        this.interval = setInterval(() => { Promise.all([this.getDashboardCounts("request-pendings"), this.getDashboardCounts("assigned-prescriptions"), this.getDashboardCounts("approve-prescriptions")]) }, 60000);
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    getDashboardCounts = (type) => {
        let filter = {}
        if (type !== "request-pendings") {
            filter = { status: 'draft' }
        }
        return new Promise((resolve, reject) => {
            return resolve(
              DashboardService.getCountInfo(type, filter)
                .then((resp) => {
                  if (type === "request-pendings") {
                    this.setState({
                      requestPendingCount: resp.data.requestPendingCount,
                    });
                  } else if (type === "assigned-prescriptions") {
                    this.setState({
                      prescriptionApprovalPending: resp.data.assignedCount,
                    });
                  } else if (type === "approve-prescriptions") {
                    this.setState({
                      assignedPrescription: resp.data.approve_pending_count,
                    });
                  }
                })
                .catch((error) => {
                  Notification.show("error", error.response.data);
                  return false;
                })
            );
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="col-md-8">
                    <Card
                        imgSrc="/images/clockwise-rotation.png"
                        label="Pending Request"
                        count={this.state.requestPendingCount}
                    />
                </div>
                <div className="col-md-8">
                    <Card
                        imgSrc="/images/prescription-approvals-pending.png"
                        label="Approval Pending"
                        count={this.state.prescriptionApprovalPending}
                    />
                </div>
                <div className="col-md-8">
                    <Card
                        imgSrc="/images/assigned-prescription.png"
                        label="Assigned Task"
                        count={this.state.assignedPrescription}
                    />
                </div>
            </React.Fragment>
        )
    }
}
export default DoctorWorkflow