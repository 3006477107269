import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { Helmet } from "react-helmet";
import * as PrescriptionAction from "../../actions/prescriptionAction";
import { getDateFormat } from "../../utils/helpers";
import MedicineBlock from "../../components/Prescription/MedicineBlock";
import Notification from '../../utils/notification';
import PatientInfo from '../../components/PatientInfo';
import DoctorInfo from '../../components/DoctorInfo';
import PinModal from "../../components/Signature/PinModal";
import { hasRole } from '../../utils/auth'
import PharmacistInfo from '../../components/PharmacistInfo';

class PharmacyEditPrescriptionPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            patient: this.props.patient,
            prescription_id: '',
            status: '',
            medicine_id: '',
            medicines: [{
                drug_id: '',
                drug_name: '',
                drug_generic_id: '',
                drug_generic_name: '',
                dosage: '',
                meal: '',
                duration: '',
                duration_type: '',
                is_generic_allowed: false,
                substitution_not_allowed: false,
                quantity: '',
                drug_type: '',
                is_other: false,
                drug_dosage_form: '',
                remarks: '',
            }],
            showPinModal: false,
            isloading: '',
        };

        this.validator = new SimpleReactValidator();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.showPinModal = this.showPinModal.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.onSuccessRedirect = this.onSuccessRedirect.bind(this);
        this.setLoading = this.setLoading.bind(this);
    }

    showPinModal = () => {
        this.setState({ showPinModal: true });
    }
    handleClose = () => {
        this.setState({ showPinModal: false });
    }

    onSuccessRedirect = () => {
        this.setState({ showPinModal: false });
        this.setLoading(false);
        this.props.history.push("/pharmacy/prescription/" + this.state.prescription_id + "/view")
    }

    setLoading = (value) => {
        this.setState({
            isloading: value
        });
    }

    componentDidMount() {
        const {
            match: { params }
        } = this.props;
        const id = params.id;
        this.props.getPerscription(id);
    }

    componentWillReceiveProps(props) {
        this.setState({
            prescription_id: props.prescription.prescription_id,
            patient: props.prescription.patient_info,
            id: props.prescription.prescription_id,
            doctor_id: props.prescription.doctor_id,
            patient_id: props.prescription.patient_id,
            status: props.prescription.status,
            // medicine_id: props.prescription.prescription_medicines ? props.prescription.prescription_medicines.precription_medicines_id : null,
            medicines: props.prescription.prescription_medicine_items ? props.prescription.prescription_medicine_items : [],
        });

        if (props.prescription.prescription_id) {
            this.props.pharmacistAcquirePrescription({ prescription_id: props.prescription.prescription_id, assigned_to: "pharmacist,pharmacy-technician", assigned_by: "pharmacist", accuired_by: "pharmacist" }, this.props.history);
        }

    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    // Start Medicine Block Events
    handleMedicineChange = (idx, data) => {
        const newMedicines = this.state.medicines.map((medicine, sidx) => {
            if (idx !== sidx) return medicine;

            return { ...medicine, ...data };
        });

        this.setState({ medicines: newMedicines });

    };

    handleSubmit = e => {
        e.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        let data = {
            prescription_id: this.state.prescription_id,
            prescription_drugs: this.state.medicines
        }

        this.props.changePrescription(data, this.props.history, (response) => {
            Notification.show('success', { message: 'Edited prescription is successfully submitted.' });
            if (response && response.id && hasRole(this.props.selected_user_profile, ['pharmacist'])) {
                this.setState({ medicine_id: response.id })
                this.showPinModal();
            }
        });
    };

    checkPrescriptionIsEditable(history) {
        history.push("/pharmacy/prescriptions/search"); Notification.show('warning', { message: 'prescription can not be changes.' });
    }

    render() {
        this.validator.purgeFields();
        const { prescription } = this.props;
        const patient_info = prescription.patient_info
        return (
            <div>
                <Helmet>
                    <title>Edit Prescription</title>
                </Helmet>
                {prescription && prescription.status && (prescription.status !== 'prescribed') ? this.checkPrescriptionIsEditable(this.props.history) : this.state.id && (
                    <div className="row">
                        <div className="col-md-24">
                            <h4 className="page-title">Change Prescription
                                {/* <span className="date">Prescription Date <i>{HELPER.getDateFormat(this.props.prescription.prescription_date)}</i></span> */}
                                <span className="date">Prescription Date: <i>{getDateFormat(prescription.prescription_date)}</i></span>
                            </h4>

                            <div className="row">
                                <div className="col-md-24">
                                    <PatientInfo patient_info={patient_info} orderDate={prescription.prescription_date} />
                                </div >
                                <div className="col-md-24">
                                    {prescription && prescription.prescription_type === 'doctor-prescription' && <DoctorInfo
                                        doctor_info={prescription.user_profile_info}
                                        doctor_location={prescription.user_location_info} />}
                                    {prescription && prescription.prescription_type === 'pharmacy-prescription' && <PharmacistInfo
                                        pharmacist_info={prescription.user_profile_info}
                                        pharmacy_info={prescription.service_provider_info}
                                        prescription_date={getDateFormat(prescription && prescription.prescription_date)} />}
                                </div>

                            </div>

                            <div className="content-wrapper padding-bottom-10">
                                <h6>Medication</h6>
                                <ul className="prescription-patient-details medicine-details">
                                    {this.state.medicines.map((medicine, idx) => (
                                        <MedicineBlock
                                            medicine={medicine}
                                            idx={idx}
                                            handleChangeMedicine={this.handleMedicineChange}
                                            handleRemoveMedicine={this.handleRemoveMedicine}
                                            selected_user_profile={this.props.selected_user_profile}
                                            validator={this.validator}
                                            not_required={false}
                                        />
                                    ))}
                                </ul>
                            </div>

                            <div className="btn-center">
                                {!this.state.btn_assign && <button type="submit" className="btn btn-primary" onClick={this.handleSubmit}>Submit</button>}
                                <Link to="/pharmacy/prescriptions/search" className="btn btn-secondary">Cancel</Link>
                            </div>
                        </div>
                    </div>
                )}
                {this.state.showPinModal &&
                    <PinModal show={this.state.showPinModal} handleClose={this.handleClose} id={this.state.medicine_id} type="edit-prescription" onSuccess={this.onSuccessRedirect} setLoading={this.setLoading} />
                }
            </div>);
    }
}

const mapStateToProps = state => ({
    prescription: state.prescription.fetch,
    selected_user_profile: state.selected_user_profile,
    user: state.session.user
});

const mapActionsToProps = {
    getPerscription: PrescriptionAction.getPrescriptionForEdit,
    changePrescription: PrescriptionAction.changePrescription,
    pharmacistAcquirePrescription: PrescriptionAction.PharmacistAcquirePrescription,
};

export default connect(mapStateToProps, mapActionsToProps)(PharmacyEditPrescriptionPage);
