import React from 'react';

export const Muscle = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <h6> Muscular Skeletal System</h6>
        <input type="checkbox" id='muscle-joints-bones-normal' name='muscle-joints-bones-normal' onChange={e => handleChange(e, 'muscle-joints-bones')} checked={checkIfProblemExist('muscle-joints-bones', 'muscle-joints-bones-normal') ? 'checked' : ''} />
        <label htmlFor='muscle-joints-bones-normal'>Normal</label>
    </React.Fragment>
}
export const MuscleOptions = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <div className="options-details">
            {/* swallen */}
            <input type="checkbox" id='muscle-joints-bones-swollen' name='muscle-joints-bones-swollen' value='muscle-joints-bones-swollen' onChange={e => handleChange(e, 'muscle-joints-bones')} checked={checkIfProblemExist('muscle-joints-bones', 'muscle-joints-bones-swollen') ? 'checked' : ''} />
            <label htmlFor='muscle-joints-bones-swollen'>Swollen</label>
            {/* tender */}
            <input type="checkbox" id='muscle-joints-bones-tender' value='muscle-joints-bones-tender' name='muscle-joints-bones-tender' onChange={e => handleChange(e, 'muscle-joints-bones')} checked={checkIfProblemExist('muscle-joints-bones', 'muscle-joints-bones-tender') ? 'checked' : ''} />
            <label htmlFor='muscle-joints-bones-tender'>Tender</label>
            {/* crepitous */}
            <input type="checkbox" id='muscle-joints-bones-crepitous' value='muscle-joints-bones-crepitous' name='muscle-joints-bones-crepitous' onChange={e => handleChange(e, 'muscle-joints-bones')} checked={checkIfProblemExist('muscle-joints-bones', 'muscle-joints-bones-crepitous') ? 'checked' : ''} />
            <label htmlFor='muscle-joints-bones-crepitous'>Crepitus</label>
        </div>
    </React.Fragment>
}
export const Movement = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <label className="heading">Movement </label>
        <div className="options-details">
            <input type="checkbox" id='muscle-joints-bones-movement-decreased' name='muscle-joints-bones-movement-decreased' onChange={e => handleChange(e, 'muscle-joints-bones')} checked={checkIfProblemExist('muscle-joints-bones', 'muscle-joints-bones-movement-decreased') ? 'checked' : ''} />
            <label htmlFor='muscle-joints-bones-movement-decreased'>Decreased</label>
        </div>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment, checkIfProblemExist }) => {
    return <React.Fragment>
        <label className="heading-comment">Comment </label>
        <textarea className="form-control" name="comments" placeholder="Type here..." onChange={e => handleChange(e, 'muscle-joints-bones')} disabled={checkIfProblemExist('muscle-joints-bones', 'muscle-joints-bones-normal') ? 'disabled' : ''} value={getCategoryComment('muscle-joints-bones') ? getCategoryComment('muscle-joints-bones') : ''}></textarea>
    </React.Fragment>
}
export const Power = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <label className="heading">Power </label>
        <div className="options-details">
            {/* normal */}
            <input type="checkbox" id='muscle-joints-bones-power-normal' name='muscle-joints-bones-power-normal' onChange={e => handleChange(e, 'muscle-joints-bones')} checked={checkIfProblemExist('muscle-joints-bones', 'muscle-joints-bones-power-normal') ? 'checked' : ''} />
            <label htmlFor='muscle-joints-bones-power-normal'>Normal</label>
            {/* decreased */}
            <input type="checkbox" id='muscle-joints-bones-power-decreased' name='muscle-joints-bones-power-decreased' onChange={e => handleChange(e, 'muscle-joints-bones')} checked={checkIfProblemExist('muscle-joints-bones', 'muscle-joints-bones-power-decreased') ? 'checked' : ''} />
            <label htmlFor='muscle-joints-bones-power-decreased'>Decreased</label>
        </div>
    </React.Fragment>
}