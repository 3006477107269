import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function (state = initialState.pagination, { type, ...payload }) {
    switch (type) {
        case types.LIST_USER_NOTIFICATION_REQUEST:
            return {
                ...state,
                ...payload
            };
        case types.LIST_USER_NOTIFICATION_SUCCESS:
            return {
                ...state,
                data: payload.data,
                meta: payload.meta
            };
        case types.LIST_USER_NOTIFICATION_FAILED:
            return {
                ...state,
                ...payload
            };
        case types.CLEAR_USER_NOTIFICATION_LIST:
            return {
                ...initialState.prescription,
                ...payload
            };
        case types.USER_NOTIFICATION_COUNT:
            return {
                ...state,
                ...payload,
            };
        case types.USER_NOTIFICATION_COUNT_RESET:
            return {
                notification_count: payload.notification_count
            }
        default:
            return state;
    }
}