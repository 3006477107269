import React from 'react';
import * as Helper from "../../utils/helpers";

const ContactDetails = ({ patient }) => {
    return <div className="content-wrapper padding-none">
        <h5>
            Contact Detail
        </h5>
        <ul className="details-listing clearfix full-width">
            <li>
                <span>Contact Number:</span>
                {patient.contact_number}
            </li>
            <li>
                <span>Alternate Number / Landline Number:</span>
                {patient.alt_contact_number}
            </li>
            <li>
                <span>Email:</span>
                {patient.email}
            </li>
            <li>
                <span>Address:</span>
                <span className="address"> {patient.address && Helper.fullAddressStr(patient.address)
                }</span>
            </li>
        </ul>
    </div>
}
export default ContactDetails