import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function (state = initialState.prescriptionOrder, { type, ...payload }) {
    switch (type) {
        case types.RESET_PERSCRIPTION_ORDER:
            return {
                ...initialState.prescriptionOrder
            };
        case types.VERIFY_PERSCRIPTION_ORDER:
            return {
                ...state,
                new: payload
            };
        case types.FILTER_PERSCRIPTION_ORDER:
            return {
                ...state,
                filter: payload
            };
        case types.LIST_PERSCRIPTION_ORDER:
            return {
                ...state,
                paginate: {
                    ...state.paginate,
                    ...payload
                }
            };
        case types.FETCH_PERSCRIPTION_ORDER:
            return {
                ...state,
                fetch: payload
            };
        case types.PRESCRIPTION_ORDER_REMINDER_LIST:
            return {
                ...state,
                paginate: {
                    ...state.paginate,
                    ...payload
                }
            }
        default:
            return state;
    }
}