import * as types from "./actionTypes";
import OfflinePrescriptionService from '../services/offlinePrescriptionService';
import Notification from '../utils/notification';

export function fetchOfflinePrescriptionId(postData, temporary_id, service_provider_type, history) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });

        OfflinePrescriptionService.createOfflinePrescription(postData).then(resp => {
            Notification.show('success', { 'message': 'Prescription Created Successfully' });
            let data = resp.data;
            dispatch({ type: 'HIDE_PAGE_LOADER' });
            dispatch({ type: types.FETCHED_ID, ...data });
            if (service_provider_type === 'pharmacy') {
                history.push('/offline/prescriptions/' + data.id + "/view");
            } else if (service_provider_type === 'pathology') {
                history.push('/offline/patient/pathology-tests/' + data.id);
            } else if (service_provider_type === 'radiology') {
                history.push('/offline/patient/radiology-tests/' + data.id);
            }
        }).catch(error => {
            dispatch({ type: 'HIDE_PAGE_LOADER' });
            if (error.response && error.response.status) {
                let errorData = error.response.data;
                Notification.show('error', errorData);
            }
        });
    }
}

export function fetchOfflinePrescription(id) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });

        OfflinePrescriptionService.getofflinePresciptionById(id).then(resp => {
            let data = resp.data;
            dispatch({ type: 'HIDE_PAGE_LOADER' });
            dispatch({ type: types.FETCH_OFFLINE_PRESCRIPTION, ...data });
        }).catch(error => {
            dispatch({ type: 'HIDE_PAGE_LOADER' });
            if (error.response && error.response.status) {
                let errorData = error.response.data;
                Notification.show('error', errorData);
            }
        })
    }
}