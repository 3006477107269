import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class UserActivityService {
    static userActivities({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute("/v1/users/activity-logs"), requestOptions);
    }

    static export({ filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ filter })
        };

        return axios.get(apiRoute("/v1/users/activity-logs/exports"), requestOptions);
    }
}
export default UserActivityService

