import React from 'react';
import { FamilyHistory } from '../../../../../config/add_prescription';
import { find } from 'lodash';

export const Father = ({ family_history, handleChange }) => {
    return (
        <React.Fragment>
            <div className="padding-right family-details family-first">
                <label>Father :</label>
                <select className="form-control" name="father_status" onChange={handleChange} value={family_history.father_status ? family_history.father_status : ''}>
                    <option value='' defaultValue>---select---</option>
                    <option value="alive">Alive</option>
                    <option value="deceased">Deceased</option>
                </select>
            </div>
            <div className="padding-right family-details family-second">
                <label>Age:</label>
                <input type="text" id="father_age" name="father_age" className="form-control" onChange={handleChange} value={family_history.father_age ? family_history.father_age : ''} />
            </div>
            <div className="family-details family-third">
                <label>Cause of death :</label>
                <input type="text" id="father_death_reason" className="form-control" name="father_death_reason" onChange={handleChange} value={family_history.father_death_reason ? family_history.father_death_reason : ''} disabled={family_history.father_status !== "deceased"} />
            </div>
        </React.Fragment>
    )
}
export const Mother = ({ family_history, handleChange }) => {
    return <React.Fragment>
        <div className="padding-right family-details family-first">
            <label>Mother :</label>
            <select className="form-control" name="mother_status" onChange={handleChange} value={family_history.mother_status ? family_history.mother_status : ''}>
                <option value='' defaultValue>---select---</option>
                <option value="alive">Alive</option>
                <option value="deceased">Deceased</option>
            </select>
        </div>
        <div className="padding-right family-details family-second">
            <label>Age:</label>
            <input type="text" id="mother_age" className="form-control" name="mother_age" onChange={handleChange} value={family_history.mother_age ? family_history.mother_age : ''} />
        </div>
        <div className="padding-right family-details family-third">
            <label>Cause of death :</label>
            <input type="text" id="mother_death_reason" className="form-control" name="mother_death_reason" onChange={handleChange} value={family_history.mother_death_reason ? family_history.mother_death_reason : ''} disabled={family_history.mother_status !== "deceased"} />
        </div>
    </React.Fragment>
}
export const Adopted = ({ family_history, handleChange, getValue }) => {
    return <React.Fragment>
        <label>Are you Adopted? : </label>
        <select className="form-control" name="is_adopted" onChange={handleChange} value={getValue(family_history.is_adopted)}>
            <option value='' defaultValue>---select---</option>
            <option value={true}>Yes</option>
            <option value={false}>No</option>
        </select>
    </React.Fragment>
}
export const FamilyCondition = ({ family_history, handleChange, disableOtherOptions, handleRemarkChange }) => {
    let { illness, family_relation } = FamilyHistory;
    let { relation_conditions } = family_history
    return (<React.Fragment>
        <div className="heading">
            <h6>Family Member Illness/Conditions Details</h6>
        </div>
        {Object.keys(illness).map((illness_key, idx) => (
            <>
                <div className="form-group full illiness-list" key={idx}>
                    <label className="main-label">{illness[illness_key]} : </label>
                    {Object.keys(family_relation).map((member_key, index) => (
                        <React.Fragment key={index}>
                            <input type="checkbox" id={idx + '-' + member_key + '-' + index} name="relation_conditions" onChange={e => handleChange(e, member_key, illness_key)}
                                checked={checkIfDataExist(relation_conditions, illness_key, member_key)}
                                disabled={member_key !== 'none' && disableOtherOptions(illness_key) ? "disabled" : ''} />
                            <label className="illness-check" htmlFor={idx + '-' + member_key + '-' + index}>{family_relation[member_key]}</label>
                        </React.Fragment>
                    ))}
                    {illness_key && getExistingData(relation_conditions, illness_key) && <div className="col-md-12 d-flex justify-content-end mt-3">
                        <label className="main-label">Remark:</label>
                        <input type="text" className="form-control" name="remark" onChange={(e) => handleRemarkChange(e, illness_key)} value={getRemark(relation_conditions, illness_key)} /></div>}
                </div>
            </>
        ))
        }
    </React.Fragment >)
}
function checkIfDataExist(relation_conditions, disease, member) {
    let existingData = getExistingData(relation_conditions, disease);
    return existingData && existingData['family_relation'].includes(member) ? 'checked' : '';
}
function getRemark(relation_conditions, disease) {
    let existingData = getExistingData(relation_conditions, disease);
    if (existingData) {
        return existingData.remark
    }
    return '';
}
function getExistingData(relation_conditions, disease) {
    return find(relation_conditions, { 'illness_type': disease });
}