import React from 'react'
import { Modal, Button } from 'react-bootstrap';

const DeleteSlotModal = ({ showModal, handleDeleteSlot, handleClose }) => {
    return (
        <Modal show={showModal} backdrop="static" keyboard={false} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered size="md">
            <div className="delete-slots m-0 p-0">
                <img src="/images/icons/Alert-Icon.svg" alt="" className="alert-icon" />
                <p className="deleteslot del-msg-1">Alert!</p>
                <p className="deleteslot del-msg-2">Do You really want to delete current slot!</p>
                <p className=" p-del-popup">If you delete current slot, all the scheduled appointments will be cancelled & the slot will be deactivated. </p>
            </div>

            <div className="delete-footer">
                <Button className="btn btn-primary delete-popup-btn" size="sm" onClick={handleClose}>
                    No
                </Button>
                <Button className="btn btn-secondary delete-popup-btn" size="sm" onClick={handleDeleteSlot}>
                    Yes
                </Button>
            </div>
        </Modal>
    )
}
export default DeleteSlotModal
