import React from 'react'
import { Helmet } from 'react-helmet'
import ReactToPrint from 'react-to-print';
import { QrCodeGenerator } from '../components/QrCodeGenerator';
import { connect } from 'react-redux';

class QrCode extends React.PureComponent {

  render() {
    return (
      <React.Fragment>
        <Helmet>
            <title>QR Code</title>
        </Helmet>
        <div className="select-profile-wrapper profile-details" style={{width: '765px'}}>
            <div className="qrcode-container" >
                <div className="alert-content">
                    <h4>QR Code</h4>
                    <QrCodeGenerator 
                      ref={el => (this.componentRef = el)} 
                      serviceProviderId={this.props.selected_user_profile.service_provider_id}
                      userLocationId={this.props.selected_user_profile.user_location_id}
                    />
                    <ReactToPrint
                      trigger={() => (
                        <div className='clear-both'>
                          <button type="submit" className="btn btn-primary">Download</button>
                        </div>
                      )}
                      content={() => this.componentRef}
                    />
				        </div>
            </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
    selected_user_profile: state.selected_user_profile,
});

export default connect(mapStateToProps)(QrCode)