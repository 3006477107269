import React from 'react';
import PrescriptionService from '../../../services/prescriptionService';
import _ from 'lodash';
import Notification from '../../../utils/notification';

class UploadBodyChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chart: [],
            files: [],
            uploadedImages: []
        }
    }
    componentDidMount() {
        this.props.showLoader();
        this.getBodyChartUploadedImages();
    }

    getBodyChartUploadedImages = () => {
        PrescriptionService.getPrescriptionBodyChartUpload(this.props.prescriptionId).then(resp => {
            this.setState({ uploadedImages: resp.data })
            this.props.hideLoader();
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data)
        })
    }

    handleFileUpload = (e) => {
        let totalFiles = this.state.uploadedImages.length + this.state.files.length + e.target.files.length;
        let validate = _.filter(e.target.files, (file) => {
            if (/\.(jpg|jpeg|png)$/i.test(file.name.toLowerCase()) === false) {
                return true;
            }
        });

        if (!_.isEmpty(validate)) {
            Notification.show('error', { message: 'Only Image files are allowed to upload' });
            return false;
        }

        if (totalFiles > 5) {
            e.target.value = null;
            Notification.show('error', { 'message': "You are only allowed to upload a maximum of 5 images" })
        } else {
            let files = this.state.files;

            _.map(e.target.files, (file, index) => (
                files.push({ image: file, description: "" })
            ));
            this.setState({ files: files }, () => {
                this.props.handleImageUpload(this.state.files);
            })
        }
        this.props.handlePrescriptionEdited();
    }

    deleteUploadedImage = (image_id) => {
        this.props.showLoader();
        PrescriptionService.deletePrescriptionBodyChartUpload(image_id).then(_resp => {
            this.props.hideLoader();
            this.getBodyChartUploadedImages();
            Notification.show('success', { message: 'Deleted' });
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data)
        })
        this.props.handlePrescriptionEdited();
    }

    changeHandler = (e, index) => {
        let { files } = this.state;
        files[index]['description'] = e.target.value;
        this.setState({ files: files }, () => {
            this.props.handleImageUpload(this.state.files);
        })
        this.props.handlePrescriptionEdited();
    }

    deleteImage = (index) => {
        let files = this.state.files;
        files = files.filter((f, i) => {
            return index !== i
        });
        this.setState({ files: files }, () => {
            this.props.handleImageUpload(this.state.files);
        });
        this.props.handlePrescriptionEdited();
    }

    render() {
        return (
            <React.Fragment>
                <div className="content-wrapper padding-20">
                    {this.state.uploadedImages.length < 5 && <div className="row">
                        <div className="col-md-24 select-chart-dropdown d-flex">

                            <label>Upload Image</label>
                            <div className="form-group ">
                                <input type="file" ref={fileInput => this.fileInput = fileInput} onChange={this.handleFileUpload} name="files" id="file" hidden multiple accept="image/*" />
                                <button onClick={() => this.fileInput.click()} className="float-right btn btn-outline-primary" name="file" id="file">Choose</button>
                            </div>

                        </div>
                    </div>
                    }
                    <div className="row">

                        {
                            this.state.files && _.map(this.state.files, (file, index) => (
                                <div className="image-upload col-md-24 p-3 border-bottom anatomy-view">
                                    <div className="cancel_section">
                                        <span className="cancel top-0" onClick={(e) => this.deleteImage(index)}></span>
                                    </div>
                                    <div className="col-md-12 float-left">
                                        <label>Details</label>
                                        <textarea id="description" name="description" className="form-control" value={file.description} onChange={(e) => this.changeHandler(e, index)} />
                                    </div>
                                    <div className="col-md-12 float-left text-right">
                                        <img src={URL.createObjectURL(file.image)} height="150px" />
                                    </div>
                                </div>
                            ))
                        }

                        {!_.isEmpty(this.state.uploadedImages) && <div className="col-md-24 mt-3 mb-n4">
                            <label className="font-weight-bold">Uploaded Image</label>
                        </div>}
                        {
                            this.state.uploadedImages && _.map(this.state.uploadedImages, (image, index) => (
                                <div className="image-upload col-md-24 p-3 border-bottom anatomy-view">

                                    <div className="cancel_section">
                                        <span className="cancel top-0" onClick={(e) => this.deleteUploadedImage(image.id)} ></span>
                                    </div>
                                    <div className="col-md-12 float-left">
                                        <label>Details</label>
                                        <textarea id="description" name="description" className="form-control" value={image.description} readOnly />
                                    </div>
                                    <div className="col-md-12 float-left text-right">
                                        <img src={image.url} height="150px" />
                                    </div>
                                </div>
                            ))
                        }

                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default UploadBodyChart