import React from 'react';
import { connect } from 'react-redux';

class ContextHelp extends React.Component {

    render() {
        return (<React.Fragment>
           <div className="help-box">
                <div className="heading">
                    <h4>Permissions</h4>
                    <a href className="icon cross"> </a>
                </div>
                <div className="content">
                    <p className="desc">daisy content</p>
                </div>
                <div className="section-cover">
                    <div className="left-side">
                        <span className="current-section"></span>of
                        <span className="total-section"></span>
                    </div>
                    <div className="right-side">
                        <a href className="previous disable">Previous</a>
                        <a href className="next">Next</a>
                    </div>
                </div>
            </div>
            <div className="overlay"></div>
        </React.Fragment>);
    }
}

export default connect(null, null)(ContextHelp);