import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function (state = initialState.authActions, {type, payload}){
    switch(type){
        case types.FORGOT_PASSWORD_REQUEST:
            return{
                ...initialState.authActions,
                isLoading: true,
                isStatus: 'request',
                request: payload
            }
        case types.FORGOT_PASSWORD_SUCCESS:
            return{
                ...state,
                isLoading: false,
                isStatus: 'success',
                response: payload
            }
            case types.FORGOT_PASSWORD_FAILURE:
                return {
                ...state,
                isLoading: false,
                isStatus: 'error',
                response: payload,
            }
        default: 
        return state;
    }

}