import { JSEncrypt } from 'jsencrypt';
import CryptoJS from 'crypto-js';
import * as config from './config';

export function encryptServer(stringValue) {
    const publicKey = config.get('PORTAL_PUBLIC_KEY')

    if (publicKey) {
        const crypt = new JSEncrypt()
        crypt.setPublicKey(publicKey)
        
        return crypt.encrypt(stringValue);
    }

    return stringValue;
}

export function encryptLocal(stringValue) {
    const aesKey = config.get('LOCAL_SECRET_KEY')
    
    if (aesKey) {
        return CryptoJS.AES.encrypt(stringValue, aesKey).toString()
    }

    return stringValue;
}

export function decryptLocal(encryptedValue) {
    const aesKey = config.get('LOCAL_SECRET_KEY')

    if (aesKey) {
        return CryptoJS.AES.decrypt(encryptedValue, aesKey).toString(CryptoJS.enc.Utf8)
    }

    return encryptedValue;
}
