import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import * as PrescriptionAction from "../../actions/prescriptionAction";
import * as HELPER from "../../utils/helpers";
import _ from "lodash";
import PrescriptionOrdersBlock from "../../components/Prescription/PrescriptionOrdersBlock";
import Concern from "../../components/Concern";
import PatientInfo from '../../components/PatientInfo';
import DoctorInfo from '../../components/DoctorInfo';
import PharmacistInfo from '../../components/PharmacistInfo';
import { withTranslation } from 'react-i18next';
import MedicineUnlockModal from "../../components/Medicine/MedicineUnlockModal";
import * as LoaderAction from "../../actions/loaderAction";
import Notification from '../../utils/notification';
import PrescriptionService from '../../services/prescriptionService';

class ViewPrescription extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prescriptionId: "",
      showMedicineUnlockModal: false,
      is_extended: false
    };

    this.onSuccessRedirect = this.onSuccessRedirect.bind(this);
    this.showMedicineUnlockModal = this.showMedicineUnlockModal.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    const prescriptionId = params.prescriptionid;
    this.setState({
      prescriptionId: prescriptionId
    });
    this.props.changedPerscription(prescriptionId, { log: true });
  }

  assignPrescription() {
    let postData = {
      prescription_id: this.state.prescriptionId,
      assign_to: this.props.selected_user_profile.role_slug === "pharmacist" ? "pharmacy-technician" : "pharmacist",
      prescription_extended: this.props.changePrescription.prescription_extended,
    };
    this.props.assignPrecription(postData, this.props.history);
  }

  onSuccessRedirect() {
    this.setState({ showMedicineUnlockModal: false });
    this.props.changedPerscription(this.state.prescriptionId, { log: true });
  }

  showMedicineUnlockModal() {
    this.setState({ showMedicineUnlockModal: true });
  }

  handleClose() {
    this.setState({ showMedicineUnlockModal: false });
  }

  extendPrescription = () => {
    this.props.loaderShow();
    PrescriptionService.extendPrescription(this.state.prescriptionId)
      .then(resp => {
          this.setState({
            is_extended: true,
            prescription_extended: resp.data,
          });
          this.props.loaderHide();
          Notification.show('success', {"message" : "Prescription Extended Successfully"});
      })
      .catch(error => {
          if (error.response && error.response.status) {
            this.props.loaderHide();
              Notification.show('error', error.response.data);
          }
          this.props.loaderHide();
      });
  }

  render() {
    const { t } = this.props;
    const {
      prescription,
      patient,
      doctor,
      prescription_medicine_items,
      prescription_edited_medicine_items,
      prescription_medicine,
      prescription_extended
    } = this.props.changePrescription;
    const { prescriptionId } = this.state;
    const statuses = ['prescribed', 'partially-fulfilled'];
    let doctor_location = prescription ? JSON.parse(prescription.user_location_info) : null;
    let service_provider_info = prescription ? JSON.parse(prescription.service_provider_info) : null;
    return (
      <React.Fragment>
        <Helmet>
          <title>View Prescription</title>
        </Helmet>
        <div className="row">
          <div className="col-md-24">
            <h4 className="page-title no-heading">
              <a href="/pharmacy/prescriptions/search">&#8249; Search Prescription</a>
              <span className="prescription-details">Prescription Id: <i> {prescription && prescription.prescription_id}</i></span>
              <span className="date">Prescription Date: <i> {HELPER.getDateFormat(prescription && prescription.prescription_date)} </i></span>
            </h4>
            <div className="content-wrapper align-center">
              {((prescription_medicine && !prescription_medicine.is_locked && prescription_medicine.status !== 'fulfilled') || this.state.is_extended) && !prescription.is_expired && <button
                className="btn btn-outline-primary"
                onClick={this.assignPrescription.bind(this)}
                data-index="17" data-tooltip="This button will assign the prescription to a technician for fulfilment">
                Assign To Technician
              </button>}
              {prescription && !prescription.is_expired && prescription_medicine && prescription.status === "prescribed" && prescription.prescription_type === 'doctor-prescription' && _.isEmpty(prescription_medicine.reference_id) && statuses.includes(prescription_medicine.status) && !prescription_medicine.is_locked && (
                <a
                  className="btn btn-outline-primary"
                  href={"/pharmacy/prescriptions/" + this.state.prescriptionId + "/edit"}
                  data-index="18" data-tooltip="Here, you can edit a prescription.">
                  Edit Prescription
                </a>
              )}
              <button className="btn btn-outline-primary"
                onClick={prescription_extended && prescription_extended.status === 'draft' ? 
                (e) => { if (window.confirm('Prescription is already extented, Are You Sure?')) { this.extendPrescription(e) }; }
                  : (e) => { if (window.confirm('Are You Sure?')) { this.extendPrescription(e) }; }
                } >
                  Extend prescription
              </button>
              {((prescription_medicine && !prescription_medicine.is_locked && prescription_medicine.status !== 'fulfilled') || this.state.is_extended) && !prescription.is_expired && <a href={"/prescriptions/" + this.state.prescriptionId + "/orders/create"} className="btn btn-outline-primary" data-index="19" data-tooltip="Here, you can create an order for the prescription"> Create Rx </a>}
              {prescription_medicine && prescription_medicine.is_locked && !prescription.is_expired && <button className="btn btn-outline-primary" onClick={e => this.showMedicineUnlockModal()}>Unlock Prescription</button>}
            </div>
            {prescription && <PatientInfo patient_info={patient} orderDate={prescription.prescription_date} />}
            {prescription && prescription.prescription_type === 'doctor-prescription' && <DoctorInfo doctor_info={doctor} doctor_location={doctor_location} />}
            {prescription && prescription.prescription_type === 'pharmacy-prescription' && <PharmacistInfo pharmacist_info={doctor} pharmacy_info={service_provider_info} prescription_date={HELPER.getDateFormat(prescription && prescription.prescription_date)} />}
            {prescription_medicine && !prescription_medicine.is_locked && (
              <div>
                <h4 className="page-title font-size-16">Prescribed Medication</h4>
                <div className="content-wrapper">
                  <table className="table table table-responsive common-table">
                    <thead>
                      <tr>
                        <th>Brand Name</th>
                        <th>Generic Name</th>
                        <th>Frequency</th>
                        <th>Duration</th>
                        <th>Dosage</th>
                        <th>Type</th>
                        <th>Meal</th>
                      </tr>
                    </thead>
                    <tbody>
                      {prescription_medicine_items &&
                        prescription_medicine_items.map((medicine, idx) => (
                          <React.Fragment>
                            <tr>
                              <td>{medicine.drug_name}</td>
                              <td>{medicine.drug_generic_name}</td>
                              <td>{medicine.dosage}</td>
                              <td> {medicine.duration}{HELPER.getDurationType(medicine.duration_type)} </td>
                              <td>{parseInt(medicine.quantity)}</td>
                              <td>{medicine.dosage_form}</td>
                              <td>{medicine.meal}</td>
                            </tr>
                            {medicine.remarks && <tr style={{ borderTop: "hidden" }}>
                              <td colSpan="7" style={{ textAlign: "left" }}><i>Instructions:</i> {medicine.remarks}</td>
                            </tr>}
                          </React.Fragment>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            {prescription_edited_medicine_items && (
              <div>
                <h4 className="page-title font-size-16">
                  {t('labels.modified-prescription')}
                  <span className="date">
                    Date{" "}
                    <i>
                      {prescription_edited_medicine_items &&
                        HELPER.getDateFormat(
                          prescription_edited_medicine_items[0].created_at
                        )}
                    </i>
                  </span>
                </h4>
                <div className="content-wrapper">
                  <table className="table table table-responsive common-table">
                    <thead>
                      <tr>
                        <th>Brand Name</th>
                        <th>Generic Name</th>
                        <th>Dosage</th>
                        <th>Duration</th>
                        <th>Dosage</th>
                        <th>Type</th>
                        <th>Meal</th>
                      </tr>
                    </thead>
                    <tbody>
                      {prescription_edited_medicine_items &&
                        prescription_edited_medicine_items.map(
                          (medicine, idx) => (
                            <React.Fragment>
                              <tr>
                                <td>{medicine.drug_name}</td>
                                <td>{medicine.drug_generic_name}</td>
                                <td>{medicine.dosage}</td>
                                <td>{medicine.duration} {HELPER.getDurationType(medicine.duration_type)}</td>
                                <td>{parseInt(medicine.quantity)}</td>
                                <td>{medicine.dosage_form}</td>
                                <td>{medicine.meal}</td>
                              </tr>
                              {medicine.remarks && <tr style={{ borderTop: "hidden" }}>
                                <td colSpan="7" style={{ textAlign: "left" }}><i>Instructions:</i> {medicine.remarks}</td>
                              </tr>}
                            </React.Fragment>
                          )
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            {this.state.prescriptionId && <PrescriptionOrdersBlock prescriptionId={this.state.prescriptionId} />}

            <div class="ml-3">
              <span className="text-muted">{t('pharmacy.prescription-note')}</span>
            </div>

            {prescriptionId && prescription && prescription.prescription_type === 'doctor-prescription' && (
              <div className="raise-concern float-right" data-index="20" data-tooltip="Pharmacist can directly raise a concern to a doctor via sending message to doctor.">
                <Concern
                  prescriptionId={this.state.prescriptionId && this.state.prescriptionId}
                  toServiceProviderId={prescription ? prescription.service_provider_id : ''}
                  toProfileId={prescription ? doctor.user_profile_id : ''}
                  fromServiceProviderId={this.props.selected_user_profile.service_provider_id}
                  fromProfileId={this.props.selected_user_profile.user_profile_id}
                >

                </Concern>
              </div>
            )}
            <MedicineUnlockModal
              patientId={prescription && prescription.patient_id}
              prescriptionId={this.state.prescriptionId && this.state.prescriptionId}
              onSuccess={this.onSuccessRedirect}
              show={this.state.showMedicineUnlockModal}
              handleClose={this.handleClose}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  changePrescription: state.change_prescription,
  selected_user_profile: state.selected_user_profile,
  user: state.session.user
});

const mapActionsToProps = {
  changedPerscription: PrescriptionAction.getChangePrescription,
  assignPrecription: PrescriptionAction.assignPrecription,
  loaderShow: LoaderAction.loaderShow,
  loaderHide: LoaderAction.loaderHide,
};

export default withTranslation()(connect(mapStateToProps, mapActionsToProps)(ViewPrescription));
