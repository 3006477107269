import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import * as PatientAction from '../../actions/patientAction';
import Profile from '../../components/Patient/Profile';
import * as loaderAction from '../../actions/loaderAction';
import ConductCovidTest from '../../components/Covid/ConductCovidTest';
import UploadExistingReports from '../../components/Covid/UploadExistingReports';

class CovidReportGeneratePage extends React.Component {
    constructor(props) {
        super(props);
        const params = this.props.match.params;
        this.state = {
            patient_id: params.patientId,
            report_id: params.reportId,
            upload_report_modal: false
        };
    }
    componentDidMount() {
        this.props.getPatient(this.state.patient_id);
    }
    handleModalShowHide = () => this.setState({ upload_report_modal: !this.state.upload_report_modal });

    render() {
        const { patient } = this.props;
        return (<React.Fragment>
            <Helmet>
                <title>Patient</title>
            </Helmet>
            <section className="container-fluid">
                <div className="row">
                    <div className="col-md-24">
                        <div className="row">
                            <div className="col-md-24">
                                <h4 className="page-title"><span>Patient Profile</span>
                                    <button to="#" className="btn btn-outline-primary ml-2" onClick={this.handleModalShowHide}>Upload Existing Report </button>
                                </h4>
                            </div>
                        </div>
                        <Profile patient={patient} selected_user_profile={this.props.selected_user_profile} />
                    </div>
                </div>
            </section>
            {<ConductCovidTest
                patient_id={this.state.patient_id}
                report_id={this.state.report_id}
                loaderShow={this.props.loaderShow}
                loaderHide={this.props.loaderHide}
                history={this.props.history} />}
            {this.state.upload_report_modal && <UploadExistingReports
                show={this.state.upload_report_modal}
                loaderHide={this.props.loaderHide}
                loaderShow={this.props.loaderShow}
                patient={patient}
                handleUploadReportModal={this.handleModalShowHide} />}
        </React.Fragment>
        )
    }
}
const mapStateToProps = (state) => ({
    patient: state.patient,
    selected_user_profile: state.selected_user_profile,
});

const mapActionsToProps = {
    getPatient: PatientAction.get,
    loaderShow: loaderAction.loaderShow,
    loaderHide: loaderAction.loaderHide,
};
export default connect(mapStateToProps, mapActionsToProps)(CovidReportGeneratePage);