import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class UserManagentService {
    static list({ page, sizePerPage, sortBy, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, filter })
        };

        return axios.get(apiRoute('/v1/user-management/users'), requestOptions);
    }

    static create({ data }) {
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.post(apiRoute('/v1/user-management/users'), data, requestOptions);
    }

    static serviceProviderLocations(service_provider_id) {
        const requestOptions = {
            headers: getApiHeader(false, true),
        };
        return axios.get(apiRoute('/v1/locations?service_provider_id=' + service_provider_id), requestOptions);
    }

    static updateUser({ data }) {
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.put(apiRoute("/v1/user-management/users/" + data.user_profile_id), data, requestOptions);
    }

    static updateUserStatus(user_id, status) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        const postData = {
            status: status
        };

        return axios.patch(apiRoute("/v1/user-management/users/" + user_id + "/status"), postData, requestOptions);
    }

    static sendReverifyEmail(email) {
        const requestOptions = {
             headers: getApiHeader(),
        };

        const body = JSON.stringify({
            email: email
        });
        return axios.post(apiRoute('/v1/re-verify'), body, requestOptions);
    }

    static userList() {
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.get(apiRoute('/v1/user-management/users-list'), requestOptions);
    }

    static updateUserProcedure(user_id, procedure) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        const postData = {
            procedure: procedure
        };

        return axios.patch(apiRoute("/v1/user-management/users/" + user_id + "/procedure"), postData, requestOptions);
    }
}
export default UserManagentService;