import React from "react";
import Notification from '../../utils/notification';
import { hasRole } from '../../utils/auth';
import * as ButtonComponents from './Buttons';
import ViewRecommendation from '@Components/UrgentCare/Recommendation/ViewRecommendation';
import ShowRadiologyTest from '../Prescription/RadiologyTestBlock'
import AddRadiologyBlock from "./AddRadiologyBlock";
import UrgentCareService from "../../services/urgentCareService";
import _ from 'lodash';

class RadiologyBlock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {    
            savedPrescriptionRadiologyTests: [],
            draftPrescriptionRadiologyTests: {},
            show_add_radiology_block: false,
            showSubmitButton: false
        }
        this.radiologyValidator = props.validator
        this.addRadiologyBlockRef = React.createRef();
    }

    componentDidMount() {
        this.getRadiology()
    }

    componentDidUpdate(prevProps) {
        if (this.props.prescription_radiology_tests !== prevProps.prescription_radiology_tests) {
            this.getRadiology()
        }
    }

    getRadiology() {
        let draftPrescriptionRadiologyTests = this.props.prescription_radiology_tests ? this.props.prescription_radiology_tests.filter(prt => prt.status === "draft") : []
        draftPrescriptionRadiologyTests = draftPrescriptionRadiologyTests.length ? draftPrescriptionRadiologyTests[0] : { prescription_radiology_test_items: [] }
        this.setState({
            savedPrescriptionRadiologyTests: this.props.prescription_radiology_tests ? this.props.prescription_radiology_tests.filter(prt => prt.status === "save") : [],
            draftPrescriptionRadiologyTests: draftPrescriptionRadiologyTests,
        })
        if (!_.isEmpty(draftPrescriptionRadiologyTests.prescription_radiology_test_items))
            this.handleAddButton()
    }

    handleRemoveComponent = () => {
        this.setState({ showSubmitButton: false, radiologyTests: {}, draftPrescriptionRadiologyTests: { prescription_radiology_test_items:[] } });
        this.props.handleIsPrescriptionEdited('recommendation_radiology', false);
    };

    handleAddButton = () => {
        this.setState({ showSubmitButton: true });
    }

    handleRadiologySaveAndNext = (e, type = null) => {
        if (_.isEmpty(this.state.draftPrescriptionRadiologyTests) && (type === "save_and_next" || type === "draft_and_next")) {
            this.props.handleSaveAndNext();
            
            return false;
        }
        let status = ['save', 'save_and_next'].includes(type) ? "save" : "draft"

        this.setState(prevState => ({
            radiologyTests: {
                prescription_radiology_tests: prevState.draftPrescriptionRadiologyTests.prescription_radiology_test_items,
                status: status,
                patient_id: this.props.patientId
            }
        }), () => {
            if (this.state.draftPrescriptionRadiologyTests.prescription_radiology_test_items.id) {
                this.setState(prevState => ({
                    radiologyTests: {
                        prescription_radiology_tests: prevState.draftPrescriptionRadiologyTests.prescription_radiology_test_items,
                        id: this.state.draftPrescriptionRadiologyTests.prescription_radiology_test_items.id
                    }
                }), () => {
                    this.addRadiology(type)
                })
            } else {
                this.addRadiology(type)
            }
        })
    }

    addRadiology = (type) => {
        if (!this.radiologyValidator.allValid()) {
            this.radiologyValidator.showMessages();
            this.forceUpdate();
            return false;
        }
        this.props.showLoader();
        UrgentCareService.createRadiologyTest(this.props.prescriptionId, this.state.radiologyTests).then(resp => {
            this.props.hideLoader();
            this.handleRemoveComponent();
            this.props.getPrescriptionRecommendation();
            if (type === "save_and_next" || type === "draft_and_next") {
                this.props.handleSaveAndNext();
            }
            this.addRadiologyBlockRef.current.getAllRadiologyTests()
            
        }).catch(error => {
            this.props.hideLoader();
            console.log("error =",error)
            // Notification.show('error', error.response.data)
        })
    }
    // Start Radiology Block Events
    handleRadiologyChange = (idx, data) => {
        let { draftPrescriptionRadiologyTests } = this.state;
        const newRadiology = draftPrescriptionRadiologyTests.prescription_radiology_test_items.map((radiology, sidx) => {
            if (idx !== sidx) return radiology;

            return { ...radiology, ...data };
        });
        draftPrescriptionRadiologyTests.prescription_radiology_test_items = newRadiology;
        this.setState({ draftPrescriptionRadiologyTests });
        this.props.handleIsPrescriptionEdited('recommendation_radiology')
    };
    handleRemoveRadiologyTest = (event) => {
        let { draftPrescriptionRadiologyTests } = this.state;
        const idx = Number(event.target.dataset["id"]);
        const radiology = draftPrescriptionRadiologyTests.prescription_radiology_test_items.filter((s, sidx) => idx !== sidx);
        draftPrescriptionRadiologyTests.prescription_radiology_test_items = radiology;
        this.setState({ draftPrescriptionRadiologyTests }, () => {
            if(this.state.draftPrescriptionRadiologyTests.prescription_radiology_test_items.length === 0){
                this.setState({ showSubmitButton: false });
                this.props.handleIsPrescriptionEdited('recommendation_radiology', false)
            }
        });
    };
    handleAddRadiologyBlock = () => {
        this.setState({
            show_add_radiology_block: !this.state.show_add_radiology_block
        })
    }
    handleRadiology = (data) => {
        this.handleAddButton()
        let { draftPrescriptionRadiologyTests } = this.state;
        draftPrescriptionRadiologyTests.prescription_radiology_test_items = data;
        this.setState({ draftPrescriptionRadiologyTests });
        this.props.handleIsPrescriptionEdited('recommendation_radiology')
    }
    // End Radiology Block Events
    
    render() {
        return (
            <div>
                <div className="content-wrapper padding-bottom-10">
                    {this.state.savedPrescriptionRadiologyTests && this.state.savedPrescriptionRadiologyTests.map((item, idx) => ( 
                        <ViewRecommendation 
                            type={"radiology"}
                            prescription_radiology_test={item}
                            idx={idx}
                        />
                    ))}
                    <ul className="prescription-patient-details medicine-details clear-both">
                        {this.state.showSubmitButton && this.state.draftPrescriptionRadiologyTests.prescription_radiology_test_items.map((radiology, idx) => (
                            <ShowRadiologyTest
                                radiology={radiology}
                                idx={idx}
                                key={idx}
                                handleChangeRadiology={this.handleRadiologyChange}
                                handleRemoveRadiologyTest={this.handleRemoveRadiologyTest}
                                validator={this.radiologyValidator}
                                disabled={this.props.recordId ? true : false}
                            />
                        ))}
                        <button onClick={this.handleAddRadiologyBlock} className="add-new btn btn-primary" disabled={hasRole(this.props.selected_user_profile, ["paramedic-doctor"]) ? 'disabled' : ''}>Add</button>
                        <AddRadiologyBlock
                            show={this.state.show_add_radiology_block}
                            prescriptionId={this.props.prescriptionId}
                            handleAddRadiologyBlock={this.handleAddRadiologyBlock}
                            handleRadiology={this.handleRadiology}
                            ref={this.addRadiologyBlockRef}
                        />
                    </ul>
                    <br /><br /><br /><br />
                    {this.state.showSubmitButton && <div className="bottom-btns">                            
                        <ButtonComponents.PrimaryButton
                            handleChange={this.handleRadiologySaveAndNext}
                            buttonType={"save"}
                            buttonName={"Submit Test"}

                        />
                    </div>}
                </div>

                <div className="bottom-btns">
                    {hasRole(this.props.selected_user_profile, ['doctor', "associate-doctor", "paramedic-doctor"]) && 
                        <ButtonComponents.PrimaryButton
                        handleChange={this.handleRadiologySaveAndNext}
                        buttonType={"save_and_next"}
                        buttonName={"Save & Next"}
                    />}
                    <ButtonComponents.OutlineButton
                        handleChange={this.props.handlePrevious}
                        buttonName={"Previous"}
                    />                   
                    <ButtonComponents.PrimaryButton
                        handleChange={this.handleRadiologySaveAndNext}
                        buttonType={"draft_and_next"}
                        buttonName={"Save as Draft & Next"}
                        disabled={!this.state.showSubmitButton}
                    />                
                    <ButtonComponents.PrimaryButton
                        handleChange={this.handleRadiologySaveAndNext}
                        buttonType={"draft"}
                        buttonName={"Save as Draft"}
                        disabled={!this.state.showSubmitButton}
                    />
                </div>

            </div>
        );
    }
}
 
export default RadiologyBlock;