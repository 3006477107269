import React from 'react';
import BarGraph from '../Graph/BarGraph';

class FulfilledOrderGraph extends React.Component {
    handleOrderFulfilledView = (view) => {
        this.props.renderOrderGraph('order', view)
    }

    render() {

        return (
            <BarGraph 
                barColor="#7BE874"
                barLabel="Number Of  Order Fulfilled"
                yScaleLabel="Orders Fulfilled"
                datasetLabels={this.props.order_graph_data.x_axis_label}
                datasetCounts={this.props.order_graph_data.counts}
                handleViewChange={this.handleOrderFulfilledView}
            />
        )
    }
}
export default FulfilledOrderGraph