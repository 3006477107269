import React from 'react'
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import PathoRadioService from '../../services/pathoRadioService';
import { getDateFormat } from '../../utils/helpers';
import Notification from '../../utils/notification';
import TestBlock from '../../components/TestBlock';
import { Link } from "react-router-dom";
import PatientInfo from '../../components/PatientInfo';
import DoctorInfo from '../../components/DoctorInfo';
import * as LoaderAction from "../../actions/loaderAction";
import * as PathoRadioAction from "../../actions/PathoRadioAction";
import * as ShowCptIcd from '../../components/ShowCptIcd';
import { has, isEmpty, groupBy } from 'lodash';

class AddReportsPage extends React.Component {

    constructor(props) {
        super(props);
        const params = this.props.match.params;
        const orderId = params.orderId;
        this.state = {
            data: '',
            orderId: orderId,
            isLoading: false,
            isChecked: false,
            uploadedFiles: [],
            prescription_icd_data: []
        }
    }

    componentWillMount() {
        PathoRadioService.showPathologyOrderById(this.state.orderId).then(resp => {
            this.setState({
                data: resp.data
            })
            if (resp.data.prescription_cpt_icd) {
                const prescription_icd_data = groupBy(resp.data.prescription_cpt_icd, 'icd_code')
                let modified = [];

                Object.keys(prescription_icd_data).forEach((key) => {
                    prescription_icd_data[key].forEach((icd, _index) => (
                        modified.push({
                            selectedIcd: {
                                icd_code: icd.icd_code,
                                full_description: icd.icd_description
                            },
                            icdCrosswalks: [{
                                cpt_code: icd.cpt_code,
                                full_description: icd.cpt_description
                            }]
                        })
                    ))
                })

                this.setState({
                    prescription_icd_data: modified,
                })
            }
        }).catch(error => {
            Notification.show('error', error.response.data)
            // this.props.history('/pathology/orders');
        })
    }

    getTodaysDate = () => {
        var today = new Date(),
            date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        return date;
    }

    getCurrentTime() {
        var today = new Date();
        const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        return time
    }

    updateFileStatus = status => {
        this.setState({
            file_status: status
        })
    }

    submitHandler = () => {
        const order_id = this.state.orderId;
        const status = 'fulfilled';

        if (this.state.file_status === 'process') {
            alert('File upload is in process, can not submit data!');
            return false;
        }

        this.props.loaderShow();
        PathoRadioService.pathologyReportSubmit(order_id, status).then(resp => {
            this.props.loaderHide();
            this.props.history.push('/pathology/orders');
            Notification.show("success", { message: "Report submitted" });
        }).catch(error => {
            this.props.loaderHide();
            Notification.show('error', error.response)
        })
    }

    keepOnPage = (e) => {
        var message = 'Warning!\n\nNavigating away from this page will delete your text if you haven\'t already saved it.';
        e.returnValue = message;
        return message;
    }
    componentDidMount() {
        window.addEventListener("beforeunload", (ev) => {
            ev.preventDefault();
            return ev.returnValue = 'Are you sure you want to close?';
        });
    }

    componentWillUnmount() {
        if (this.state.file_status === 'process') {
            window.confirm('You want to leave this page. You may lost the data!')
        }

    }

    render() {
        const { data, prescription_icd_data } = this.state;
        return (
            <React.Fragment>
                <Helmet>
                    <title>View Order</title>
                </Helmet>
                {data &&
                    <div className="row">
                        <div className="col-md-24">
                            <h4 className="page-title no-heading">
                                <Link to="/pathology/orders">&#8249; Order List</Link>
                                <span className="date"><i>{"Date: " + getDateFormat(data.order_date)} </i></span>
                            </h4>

                            <PatientInfo patient_info={data.patient_info} orderDate={data.order_date} />
                            <DoctorInfo doctor_info={data.doctor_info}
                                doctor_location={data.doctor_location_info} />
                            {has(data, 'diagnosis') && <div className="content-wrapper padding-bottom-10">
                                <h6>Diagnosis</h6>
                                <p>{data.diagnosis ? data.diagnosis : "--"}</p>
                            </div>}

                            {has(data, 'prescription_cpt_icd') && !isEmpty(prescription_icd_data) &&
                                <div className="content-wrapper padding-bottom-10">
                                    <h6>ICD CPT</h6>
                                    <ul className="prescription-patient-details medicine-details">
                                        <div className="content-wrapper">
                                            <ul className="doctor-tests">
                                                {prescription_icd_data.map((icd, index) => (<ShowCptIcd.ShowIcd icd={icd} idx={index} disable={true} />))}
                                            </ul>
                                        </div>
                                    </ul>
                                </div>}

                            <h4 className="page-title font-size-16">Test Info</h4>
                            {
                                data.order_items.map((item, index) => (
                                    <TestBlock
                                        data={item}
                                        key={index}
                                        status={data.status}
                                        service={'pathology'}
                                        order_id={this.state.data.order_id}
                                        updateFileStatus={this.updateFileStatus}
                                        documentDownload={this.props.documentDownload}
                                    />
                                ))
                            }

                            <div className="button-wrapper align-right">
                                <Link to={'/pathology/orders'} className="btn btn-primary" hidden={this.state.data.status === 'fulfilled' ? true : false}>Draft</Link>
                                <button className="btn btn-primary" onClick={(e) => { if (window.confirm('Are You Sure?')) { this.submitHandler() }; }} hidden={this.state.data.status === 'fulfilled' ? true : false}>Submit</button>
                            </div>
                        </div>
                    </div>}
            </React.Fragment>)
    }
}

const mapStateToProps = (state) => ({
    selected_profile: state.selected_user_profile
});

const mapActionsToProps = ({
    loaderShow: LoaderAction.loaderShow,
    loaderHide: LoaderAction.loaderHide,
    documentDownload: PathoRadioAction.documentDownload,
})

export default connect(mapStateToProps, mapActionsToProps)(AddReportsPage);