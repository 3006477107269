import React from 'react';
import { Link } from "react-router-dom";

class ErrorBoundary extends React.Component {

    render() {
        if (this.props.error) {
            return (
                <section className="container-fluid">
                    <h4>Technical Error</h4>
                    <div> Something went wrong, try again!</div>
                    <div> If problem persists, please contact technical team.</div>
                    <div><small>Error: {this.props.error.toString()}</small></div>
                    <div><Link to="/redirect" className="btn btn-success">Back to Portal</Link></div>
                </section>
            );
        }
        return this.props.children;
    }
}

export default (ErrorBoundary)