import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class PathologyService {
    static list({ page, sizePerPage, sortBy, searchText }, filter) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute('/v1/pathology/test-search'), requestOptions);
    }

    static getAllPathologyTests() {
        const requestOptions = {
            headers: getApiHeader(),
            // params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute('/v1/pathology-tests'), requestOptions);
    }

    static getAllCovidTests() {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute('/v1/covid-tests'), requestOptions);
    }
}


export default PathologyService;