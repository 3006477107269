import React from 'react';
import { Link } from "react-router-dom";
import { hasRole } from '../../utils/auth';
import { capitalize } from 'lodash';
import * as Helper from "../../utils/helpers";
import _ from 'lodash'
import * as Config from '../../config';

const PersonalDetails = ({ patient, selected_user_profile, showInsurance = true }) => {
    return <div className="content-wrapper padding-none">
        <h5>
            Personal Information
            {hasRole(selected_user_profile, ["doctor", "associate-doctor", "doctor-nurse"]) && <Link to={"/patient/" + patient.patient_id + "/edit"} className="edit-details" ></Link>}
        </h5>
        <ul className="details-listing clearfix">
            <li>
                <span>Full Name:</span>
                {patient.full_name}
            </li>
            <li>
                <span>Gender:</span>
                {capitalize(patient.gender)}
            </li>
            {patient.date_of_birth && <li>
                <span>Date Of Birth:</span>
                {Helper.getDateFormat(patient.date_of_birth)} ({patient.age_string})
            </li>}
            {showInsurance &&
                <li>
                    <span>Insurance:</span>
                    <span className="address insurance">
                        {patient.insurance_type && getInsurance(patient)}
                    </span>
                </li>}
            <li>
                <span>Occupation:</span>
                {patient.occupation ? patient.occupation : '--'}
            </li>
        </ul>
    </div>
}

function getInsurance(patient) {
    let insurance = patient.insurance_type.map(value => {
        return _.has(Config.INSURANCE, value) ? Config.INSURANCE[value] : ''
    })
    return _.filter(insurance).join(', ')
}
export default PersonalDetails