import React from 'react'
import { capitalize } from 'lodash'
import RoleService from '../../services/roleService'

export default function ProfileItem({ index, user, selectedUser, onChange }) {
    return (
        <li key={index}>
            <span value={"profile-" + index}>
                <input type="radio" id={"profile-" + index} name="radio-group" onChange={() => onChange(user)} checked={selectedUser && selectedUser.user_location_id === user.user_location_id && selectedUser.user_profile_id === user.user_profile_id} />
                <label htmlFor={"profile-" + index}>
                    <h6>{capitalize(user.service_provider_name)}</h6>
                    <p>{capitalize(user.user_location_name)}</p>
                    {<p className="role">{RoleService.getRoleNameBySlug(user.role_slug)}</p>}
                </label>
            </span>
        </li>
    )
}
