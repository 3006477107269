import React from 'react';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { getDateFormat, getAgeString } from "../../utils/helpers";
import { capitalize, groupBy, isEmpty, has, size, map } from 'lodash';
import { connect } from "react-redux";
import { hasRole } from "../../utils/auth";
import PrintButtonGroup from '@Components/PrintButtonGroup';
import CollectFeeButton from '@Components/CollectFeeButton';
import VitalBlock from '@Components/Prescription/Vital/ViewVital';
import ViewHistoryBlock from '@Components/Prescription/History/ViewHistoryAccordion';
import ViewExamination from '@Components/Prescription/Examination/ViewExamination';
import ShowMedicineBlock from '@Components/Prescription/ShowMedicineBlock';
import ShowRadiologyBlock from '@Components/Prescription/ShowRadiologyBlock';
import ShowPathologyBlock from '@Components/Prescription/ShowPathologyBlock';
import * as ShowCptIcd from '@Components/ShowCptIcd';
import PrescriptionService from '../../services/prescriptionService';
import Notification from '../../utils/notification';
import * as LoaderAction from '../../actions/loaderAction';
import moment from 'moment';
import EditPersonalNoteButton from '@Components/Prescription/Recommendation/components/EditPersonalNoteButton';
import Concern from "@Components/Concern";
import PrescriptionOrdersBlock from "@Components/Prescription/PrescriptionOrdersBlock";
import ViewBodyChart from '@Components/Prescription/BodyChart/ViewBodyChart';
import RepeatPrescriptionButton from '../../components/RepeatPrescriptionButton';

class ViewDoctorPrescriptionPage extends React.Component {
    constructor(props) {
        super(props);

        let { params } = this.props.match;

        this.state = {
            prescription: '',
            prescription_fee: '',
            prescription_fee_status: '',
            personal_note: '',
            prescriptionId: params.prescriptionId,
            icd_cpts: [],
            body_charts: [],
            prescriptionConcernPharmacist: null
        }
    }

    getPrescriptionConcernsPharmacist = (prescriptionId) => {
        PrescriptionService.getPrescriptionConcernsPharmacist(prescriptionId).then(resp => {
            this.setState({
                prescriptionConcernPharmacist: resp.data.pharmacists
            });
        }).catch(error => {
            console.error(error)
        })
    }

    componentDidMount() {
        this.props.pageLoaderShow();
        PrescriptionService.view(this.state.prescriptionId).then(response => {
            let { data } = response;
            let modifiedIcdCpts = [];
            let prescription_body_chart_data = [];
            if (data.assessment && data.assessment.icd_cpts) {
                const prescription_icd_data = groupBy(data.assessment.icd_cpts, 'icd_code');
                Object.keys(prescription_icd_data).forEach((key) => {
                    prescription_icd_data[key].forEach((icd, _index) => (
                        modifiedIcdCpts.push({
                            selectedIcd: {
                                icd_code: icd.icd_code,
                                full_description: icd.icd_description
                            },
                            icdCrosswalks: [{
                                cpt_code: icd.cpt_code,
                                full_description: icd.cpt_description
                            }]
                        })
                    ))
                });
            }

            if (data.body_charts) {
                prescription_body_chart_data = groupBy(data.body_charts, 'image_slug');
            }
            this.props.selected_user_profile.user_profile_id === response.data.user_profile_info.user_profile_id && this.getPrescriptionConcernsPharmacist(this.state.prescriptionId);
            this.setState({
                prescription: response.data,
                prescription_fee: response.data.prescripetion_fee,
                icd_cpts: modifiedIcdCpts,
                prescription_fee_status: !isEmpty(response.data.prescripetion_fee) ? response.data.prescripetion_fee.status : 'unpaid',
                body_charts: prescription_body_chart_data,
            })
            this.props.pageLoaderHide();
        }).catch(error => {
            this.props.pageLoaderHide();
            Notification.show('error', error.response.data)
        });
    }
    setPrescriptionFee = (prescription_fee, status) => {
        this.setState({
            prescription_fee: prescription_fee,
            prescription_fee_status: status,
        });
    }
    setPrescriptionPersonalNote = (personal_note) => {
        this.setState(prevState => {
            let prescription = Object.assign({}, prevState.prescription);
            prescription.personal_note = personal_note;
            return { prescription };
        })
    }
    hideCollectFee = () => {
        let { prescription } = this.state;
        let expireAt = moment(prescription.created_date).add(12, 'hours');
        let currentTimestamp = moment();
        return currentTimestamp.isBefore(expireAt) || currentTimestamp.isSame(expireAt)
    }

    redirectToCollectFee = (patientId) => {
        this.props.history.push({
            pathname: '/patients/' + patientId,
            state: { showCollectFeeModal: true }
        });
    }

	getPdfLink = (id) => {
		this.props.pageLoaderShow();
		PrescriptionService.getPresentingConditionImageDownloadLink(id).then(resp => {
			this.props.pageLoaderHide();
			window.open(resp.data.link);
		}).catch(error => {
			this.props.pageLoaderHide();
			Notification.show('error', error.response.data);
		});
	}

    render() {
        let { prescription, prescription_fee, prescription_fee_status, prescriptionConcernPharmacist } = this.state;
        return (
            <React.Fragment>
                <Helmet>
                    <title>Encounter</title>
                </Helmet>
                <div className="row">
                    <div className="col-md-24">
                        <h4 className="page-title no-heading">
                            <Link onClick={() => this.props.history.goBack()} to="#">&#8249; Back</Link>
                            <span className="prescription-details">Prescription Id: <i>{prescription.prescription_id}</i></span>
                            <span className="date">Prescription Date <i>{getDateFormat(prescription.prescription_date)}</i></span>
                        </h4>
                        <h4 className="page-title">Encounter
                            <div>
                                {/* print drug */}
                                {hasRole(this.props.selected_user_profile, ['doctor', "associate-doctor"]) && prescription.status !== 'draft' && this.props.selected_user_profile.service_provider_id === prescription.service_provider_id && has(prescription, 'recommendation') && !isEmpty(prescription.recommendation.prescription_medicines) && prescription.has_signed && (prescription.medicine_status && prescription.medicine_status === 'prescribed') && <PrintButtonGroup prescriptionId={prescription.prescription_id} type="medicine" title="print prescribed drug" />}
                                {/* print lab test */}
                                {hasRole(this.props.selected_user_profile, ['doctor', "associate-doctor"]) && prescription.status !== 'draft' && this.props.selected_user_profile.service_provider_id === prescription.service_provider_id && has(prescription, 'recommendation') && !isEmpty(prescription.recommendation.prescription_pathology_tests) && prescription.has_signed && (prescription.pathology_test_status && prescription.pathology_test_status === 'prescribed') && <PrintButtonGroup prescriptionId={prescription.prescription_id} type="pathology" title="print prescribed pathology test" />}
                                {/* print radiology test */}
                                {hasRole(this.props.selected_user_profile, ['doctor', "associate-doctor"]) && prescription.status !== 'draft' && this.props.selected_user_profile.service_provider_id === prescription.service_provider_id && has(prescription, 'recommendation') && !isEmpty(prescription.recommendation.prescription_radiology_tests) && prescription.has_signed && (prescription.radiology_test_status && prescription.radiology_test_status === 'prescribed') && <PrintButtonGroup prescriptionId={prescription.prescription_id} type="radiology" title="print prescribed radiology test" />}
                                {/* print patient record */}
                                {hasRole(this.props.selected_user_profile, ['doctor', "associate-doctor"]) && prescription.status !== 'draft' && this.props.selected_user_profile.service_provider_id === prescription.service_provider_id && prescription.has_signed && <PrintButtonGroup prescriptionId={prescription.prescription_id} type="patient" title="print patient record" />}
                                {/* fee collection */}
                                {/* {hasRole(this.props.selected_user_profile, ['doctor', "associate-doctor"]) && prescription.status !== 'draft' && this.props.selected_user_profile.service_provider_id === prescription.service_provider_id && prescription.has_signed && isEmpty(prescription_fee) && this.hideCollectFee() && <CollectFeeButton prescriptionId={prescription.prescription_id} setPrescriptionFee={this.setPrescriptionFee} title="Collect Fee" />} */}
                                {/* print fee */}
                                {/* {hasRole(this.props.selected_user_profile, ['doctor', "associate-doctor"]) && prescription.status !== 'draft' && this.props.selected_user_profile.service_provider_id === prescription.service_provider_id && prescription.has_signed && !isEmpty(prescription_fee) && prescription_fee_status === "paid" && <PrintButtonGroup prescriptionId={prescription.prescription_id} type="fee_collection" title="print fee" />} */}
                                {/* sign prescription */}
                                {hasRole(this.props.selected_user_profile, ['doctor']) && prescription && this.props.selected_user_profile.user_profile_id === prescription.created_by.user_profile_id && !prescription.has_signed && <span class="sign_prescription float-right">Kindly sign the prescription to print. <Link to={{ pathname: "/prescriptions/pending-signature" }}>click here</Link></span>}
                                {/* Repeat prescription */}
                                {hasRole(this.props.selected_user_profile, ['doctor', "associate-doctor"]) && prescription.status !== 'draft' && this.props.selected_user_profile.service_provider_id === prescription.service_provider_id && prescription.has_signed && prescription.recommendation.prescription_medicines && <RepeatPrescriptionButton prescriptionId={prescription.prescription_id}
                                    patientId={prescription.patient_id} history={this.props.history} />}
                                {/* Collect fee redirect*/}
                                {hasRole(this.props.selected_user_profile, ['doctor', "associate-doctor"]) && prescription.status !== 'draft' && this.props.selected_user_profile.service_provider_id === prescription.service_provider_id && prescription.has_signed && <button className="btn btn-primary" onClick={e => this.redirectToCollectFee(prescription.patient_id)}>Collect Fee</button>}
                                {/* Print Presenting History */}
                                {hasRole(this.props.selected_user_profile, ['doctor', "associate-doctor"]) && prescription.status !== 'draft' && this.props.selected_user_profile.service_provider_id === prescription.service_provider_id && prescription.has_signed && <button className="btn btn-outline-primary ml-2" onClick={e => this.getPdfLink(this.state.prescriptionId)}>Print Presenting History</button>}
                            </div>
                        </h4>
                        {has(prescription, 'patient_info') && <div className="content-wrapper padding-bottom-10">
                            <h6>Personal Info</h6>
                            <ul className="prescription-patient-details">
                                <li>Name: <span>{prescription.patient_info.full_name}</span></li>
                                <li>Date of Birth: <span>{getDateFormat(prescription.patient_info.date_of_birth)} ({getAgeString(prescription.patient_info.date_of_birth, prescription.prescription_date)})</span></li>
                                <li>Gender: <span>{capitalize(prescription.patient_info.gender)}</span></li>
                            </ul>
                        </div>}
                        {/* vital info */}
                        {has(prescription, 'vital') && <div className="content-wrapper padding-bottom-10">
                            <h6>Vitals</h6>
                            <VitalBlock vital={prescription.vital} patient_info={prescription.patient_info} />
                        </div>}
                        {/* history component */}
                        {prescription && (!isEmpty(prescription.personal_history) || !isEmpty(prescription.family_history) || !isEmpty(prescription.surgical_history) || !isEmpty(prescription.obstetrics_gynaecology) || (prescription.current_medicine && !isEmpty(prescription.current_medicine.prescription_drugs))) && <div className="content-wrapper padding-bottom-10">
                            <h6>History</h6>
                            {<ViewHistoryBlock
                                prescriptionId={this.state.prescriptionId}
                                patient_gender={prescription && prescription.patient_info.gender}
                                personal_history={prescription.personal_history}
                                family_history={prescription.family_history}
                                surgical_history={prescription.surgical_history}
                                obstetrics_gynaecology={prescription.obstetrics_gynaecology}
                                current_medicine={prescription.current_medicine} />}
                        </div>}
                        {/* present condition */}
                        {has(prescription, 'present_condition') && <div className="content-wrapper padding-bottom-10">
                            <h6>Presenting Condition</h6>
                            <p>{has(prescription, 'present_condition') && prescription.present_condition.symptoms ? prescription.present_condition.symptoms : '--'}</p>
                        </div>}
                        {/* examination */}
                        {has(prescription, 'examination') && (prescription.examination_note || !isEmpty(prescription.examination.review_of_system) || !isEmpty(prescription.examination.examination)) && <div className="content-wrapper padding-bottom-10">
                            <h6>Examination</h6>
                            <ViewExamination examination={prescription.examination} examination_note={prescription.examination_note} />
                            {/* <div className="content-wrapper padding-bottom-10">
                                <h6>Examination Note</h6>
                                <p>{prescription.examination_note ? prescription.examination_note : '--'}</p>
                            </div> */}
                        </div>}
                        {/* assessment */}
                        {has(prescription, 'assessment') && (!isEmpty(this.state.icd_cpts) || prescription.assessment.remark) && <div className="content-wrapper padding-bottom-10">
                            <h6>Assessment</h6>
                            {hasRole(this.props.selected_user_profile, ['doctor', "associate-doctor", 'junior-doctor', 'medical-practice-manager', 'paramedic-doctor']) && !isEmpty(this.state.icd_cpts) &&
                                <div className="content-wrapper padding-bottom-10">
                                    <h6>ICD CPT</h6>
                                    <ul className="prescription-patient-details medicine-details">
                                        <div className="content-wrapper">
                                            <ul className="doctor-tests">
                                                {this.state.icd_cpts.map((icd, index) => (<ShowCptIcd.ShowIcd icd={icd} idx={index} disable={true} key={index} />))}
                                            </ul>
                                        </div>
                                    </ul>
                                </div>}
                            <div className="content-wrapper padding-bottom-10">
                                <h6>Assessment Remark</h6>
                                <p>{prescription.assessment.remark ? prescription.assessment.remark : '--'}</p>
                            </div>
                        </div>}
                        {/* body_charts */}
                        {has(prescription, 'body_charts') && (!isEmpty(this.state.body_charts)) && <div className="content-wrapper padding-bottom-10">
                            <h6>Body Chart</h6>
                            {hasRole(this.props.selected_user_profile, ['doctor', "associate-doctor", 'junior-doctor', 'medical-practice-manager']) &&
                                <ViewBodyChart
                                    prescriptionId={this.state.prescriptionId}
                                    body_charts={prescription && this.state.body_charts && this.state.body_charts}
                                />
                            }
                        </div>}
                        {/* body_chart_uploads */}
                        {has(prescription, 'body_chart_uploads') && (!isEmpty(prescription.body_chart_uploads)) && <div className="content-wrapper padding-bottom-10">
                            <h6>Body Chart Uploaded Images</h6>
                            {hasRole(this.props.selected_user_profile, ['doctor', "associate-doctor", 'junior-doctor', 'medical-practice-manager']) &&
                                (map(prescription.body_chart_uploads, (image, index) => (
                                    <div class="image-upload col-md-24 p-3 border-top anatomy-view float-left">
                                        <div className="col-md-12 float-left">
                                            <ul class="prescription-patient-details"><li>Description: <span>{image.description}</span></li></ul>
                                        </div>
                                        <div className="col-md-12 float-left text-right">
                                            <img src={image.url} height="150px" />
                                        </div>
                                    </div>
                                )))
                            }
                        </div>}
                        {/* recommendation */}
                        {has(prescription, 'recommendation') && (prescription.recommendation.prescription_medicines || prescription.recommendation.prescription_pathology_tests || prescription.recommendation.prescription_radiology_tests) && <div className="content-wrapper padding-bottom-10">
                            <h6>Prescription and Recommendation</h6>
                            {/* Medication info */}
                            {has(prescription, 'recommendation') && prescription.recommendation.prescription_medicines && <div className="content-wrapper padding-bottom-10">
                                <h6>Prescribed Medication</h6>
                                {prescription.recommendation.prescription_medicines.prescription_medicine_item.map((item, idx) => (
                                    <ShowMedicineBlock medicine={item} key={idx} />
                                ))}
                            </div>}
                            {/* pathology info */}
                            {has(prescription, 'recommendation') && prescription.recommendation.prescription_pathology_tests && <div className="content-wrapper padding-bottom-10">
                                <h6>Lab Test</h6>
                                <ul className="prescription-patient-details medicine-details">
                                    <div className="content-wrapper">
                                        <ul className="doctor-tests">
                                            {prescription.recommendation.prescription_pathology_tests.prescription_pathology_test_items.map((item, idx) => (
                                                <ShowPathologyBlock pathology={item} key={idx} />
                                            ))}
                                        </ul>
                                    </div>
                                </ul>
                            </div>}
                            {/* radiology info */}
                            {has(prescription, 'recommendation') && prescription.recommendation.prescription_radiology_tests && <div className="content-wrapper padding-bottom-10">
                                <h6>Radiology Test</h6>
                                <ul className="prescription-patient-details medicine-details">
                                    <div className="content-wrapper">
                                        <ul className="doctor-tests">
                                            {prescription.recommendation.prescription_radiology_tests.prescription_radiology_test_items.map((item, idx) => (
                                                <ShowRadiologyBlock radiology={item} key={idx} />
                                            ))}
                                        </ul>
                                    </div>
                                </ul>
                            </div>}
                        </div>}
                        {this.props.selected_user_profile.user_profile_id === prescription.user_profile_id && <div className="content-wrapper padding-bottom-10">
                            {hasRole(this.props.selected_user_profile, ['doctor', "associate-doctor"]) && <h6>Personal Note
                                <EditPersonalNoteButton prescriptionId={prescription.prescription_id} personal_note={prescription.personal_note} setPrescriptionPersonalNote={this.setPrescriptionPersonalNote} />
                            </h6>}
                            <p>{prescription.personal_note ? prescription.personal_note : '--'}</p>
                        </div>}
                        {has(prescription, 'recommendation') && prescription.recommendation.remarks && <div className="content-wrapper padding-bottom-10">
                            <h6>Remark</h6>
                            <p>{prescription.recommendation.remarks ? prescription.recommendation.remarks : '--'}</p>
                        </div>}
                        {has(prescription, 'recommendation') && prescription.recommendation.refer_to && (this.props.selected_user_profile.user_profile_id === prescription.created_by.user_profile_id || this.props.selected_user_profile.user_id === prescription.recommendation.refer_to.user_id) && <div className="content-wrapper padding-bottom-10">
                            <h6>Refer To Doctor</h6>
                            <p><span>Doctor Specialization: </span>{prescription.recommendation.refer_to.specialization}</p>
                            <p><span>Doctor Name: </span>{prescription.recommendation.refer_to.full_name}</p>
                            <p><span>Refer Remark: </span>{prescription.recommendation.refer_to.remark}</p>
                        </div>}
                    </div>
                </div>
                {this.props.selected_user_profile.user_profile_id === prescription.user_profile_id && this.state.prescriptionId && (prescription.medicine_status && prescription.medicine_status !== 'prescribed') && <PrescriptionOrdersBlock
                    prescriptionId={this.state.prescriptionId} />}
                {prescriptionConcernPharmacist && (size(prescriptionConcernPharmacist) > 0) && (
                    <div className="raise-concern float-right">
                        {prescriptionConcernPharmacist.map(concerns => {
                            return <Concern
                                prescriptionId={this.state.prescriptionId}
                                toServiceProviderId={concerns.from_service_provider_id}
                                fromServiceProviderId={concerns.to_service_provider_id}
                                toProfileId={concerns.from_profile_id}
                                fromProfileId={concerns.to_profile_id}
                            >
                            </Concern>
                        })
                        }
                    </div>
                )}
            </React.Fragment >
        )
    }
}
const mapStateToProps = (state) => {
    return {
        selected_user_profile: state.selected_user_profile
    };
}

const mapActionsToProps = ({
    pageLoaderHide: LoaderAction.loaderHide,
    pageLoaderShow: LoaderAction.loaderShow,
})

export default connect(mapStateToProps, mapActionsToProps)(ViewDoctorPrescriptionPage)
