import { roles } from './../config/role';
import _ from 'lodash';

class RoleService {
    static getRoleBySlug(role_slug) {
        let role =  _.find(roles, { slug: role_slug});
        return role ? role : null;
    }

    static getRoleNameBySlug(role_slug) {
        let role = _.find(roles, { slug: role_slug });
        return role ? role.name : "";
    }
}

export default RoleService