import axios from 'axios';
import { apiRoute, getApiHeader, createPaginateQueryString } from '../utils/helpers';

class CovidCenterService {
    static storeCovidTestFee(data) {
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.post(apiRoute("/v1/covid-centers/fees"), data, requestOptions);
    }

    static storeCovidReport(data) {
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.post(apiRoute("/v1/covid-centers/reports"), data, requestOptions);
    }

    static list({ page, sizePerPage, sortBy, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, filter })
        };

        return axios.get(apiRoute("/v1/covid-centers/reports/search"), requestOptions);
    }
    static uploadExistingReport(data) {
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.post(apiRoute("/v1/covid-centers/existing-reports"), data, requestOptions);
    }
    static getUploadExistingReport({ page, sizePerPage, sortBy, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, filter })
        };
        return axios.get(apiRoute("/v1/covid-centers/existing-reports"), requestOptions);
    }
    static getExistingFeeDetails(patient_id) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute(`/v1/covid-fees/${patient_id}`), requestOptions);
    }
    static getCovidReport(patient_id, report_id) {
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.get(apiRoute(`/v1/patients/${patient_id}/reports/${report_id}`), requestOptions);
    }
    static getActiveUserProfiles() {
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.get(apiRoute(`/v1/covid-centers/profiles`), requestOptions);
    }
    static updateCovidReport(data, report_id) {
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.put(apiRoute(`/v1/covid-centers/reports/${report_id}`), data, requestOptions);
    }
    static paginatePayments({ page, sizePerPage, sortBy, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, filter })
        };
        return axios.get(apiRoute("/v1/covid-centers/payments"), requestOptions);
    }
    static downloadReports(id) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.get(apiRoute(`/v1/covid-centers/reports/${id}/download`), requestOptions);
    }
    static downloadUploadedReports(id) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.get(apiRoute(`/v1/uploaded-reports/${id}/download`), requestOptions);
    }
    static downloadPaymentReceipt(id) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.get(apiRoute(`/v1/covid-centers/payments/${id}/download`), requestOptions);
    }
    static sendReport(data) {
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.post(apiRoute("/v1/covid-centers/send-report"), data, requestOptions);
    }
    static paginateCovidTestPatients({ page, sizePerPage, sortBy, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, filter })
        };
        return axios.get(apiRoute("/v1/covid-centers/test-patients"), requestOptions);
    }

    static deleteCovitTestPatient(id) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.delete(apiRoute(`/v1/covid-centers/test-patients/${id}`), requestOptions);
    }

    static getCovidTestList() {
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.get(apiRoute('/v1/data-management/covid-test-list'), requestOptions);
    }

    static getLastCovidTest(patientId) {
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.get(apiRoute(`/v1/test-patients/${patientId}`), requestOptions);
    }

    static updateCovidTestPatientId(patientId) {
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.post(apiRoute(`/v1/test-patients/${patientId}/update`), {}, requestOptions);
    }
}
export default CovidCenterService;