import * as types from '../actions/actionTypes';
import initialState from './initialState';


export default function profile(state = initialState.profile, { type, ...payload }) {
    switch (type) {
        case types.UPDATE_PROFILE_REQUEST:
            return {
                ...state,
                ...payload
            };
        case types.UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                ...payload
            };
        default:
            return state;
    }
}