import React from 'react';
import * as Helper from "../../utils/helpers";
import RoleSerivce from '../../services/roleService';

export const ConductedBySubmittedAt = ({ name, date }) => {
    return (
        <React.Fragment>
            <span className="clear-both d-flex justify-content-between font-weight-bold">Conducted By: {name && name.full_name + " (" + RoleSerivce.getRoleNameBySlug(name.role_slug) + ")"}
                <span>Submitted At: {Helper.getDateTimeFormat(date)}</span>
            </span>

        </React.Fragment>
    )
}