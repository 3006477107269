import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import BodyChart from './BodyChartComponent'
import ViewBodyChart from './ViewBodyChartComponent'
class MaleBack extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      paths: [{
        "className": "head",
        "value": "Head",
        "name":"head",
        "d": "M450.806,154.076c3.058-18.988,9.442-66.107,10.527-83.743C462.666,48.667,432.333,15,400,15c-27.334,0-58.5,32-58,52.667c0.19,7.875,2,33,2.333,36.333c0.239,2.389,4.332,32.016,7.459,49.645C362,174.667,440.611,174.486,450.806,154.076z"
      }, {
        "className": "neck",
        "value": "Neck",
        "name":"neck",
        "d": "M497.833,226c-28-9.5-48.999-27.333-49.999-29.5s0.166-30.667,1.5-34.5c0.248-0.713,0.773-3.584,1.472-7.924c-10.194,20.41-88.806,20.59-99.013-0.432c1.235,6.962,2.32,12.053,2.957,12.855c1.555,1.958,2.93,28.364,0.5,31.5c-7.805,10.073-31.475,20.792-49.208,27.5C327.75,219.5,479.908,222.22,497.833,226z"
      }, {
        "className": "back",
        "value": "Back",
        "name":"back",
        "d": "M539,351c2.836-16.7,6.265-36.969,4.098-48.71c-0.126-0.68-0.267-1.336-0.431-1.956c-3-11.333-7.667-52-44.834-74.333c-17.925-3.78-170.083-6.5-191.792-0.5c-39.458,21.5-44.542,68.75-45.542,74.5s0.5,26.25,2.25,36.75s8.25,29.583,4.625,66.375c1.125,0,1.5,3.5,1.875,6.125s4.25,16.75,9.25,23s9.25,25,13.25,32.5c4.468,5.507,41.373,10.639,83.746,11.485c9.657,0.193,19.599-1.733,29.504-1.776c9.978-0.044,19.919,1.793,29.499,1.512c39.579-1.163,72.98-6.345,77.196-11.47c2.613-5.708,6.414-14.637,7.473-18.167c1.5-5,2.666-9.167,4.833-12.667s7.833-18.083,8.666-21.083s2.167-9.417,3.334-9.5C535,387.667,536,368.667,539,351z"
      }, {
        "className": "loin",
        "value": "Loin",
        "name":"loin",
        "d": "M434.499,475.97c-9.58,0.281-19.521-1.556-29.499-1.512c-9.906,0.043-19.847,1.969-29.504,1.776c-42.373-0.846-79.277-5.978-83.746-11.485c4,7.5,6.5,19,8.5,37.75s-2.25,32-3.25,37.75s-0.227,23.88,1.25,28c1.412,3.939,3.607,9.041-0.422,15.812c6.278-9.18,30.556-16.657,56.643-16.657c29.53,0,31.03,10.279,51.53,10.279c19,0,26-10.042,51.526-10.166c25.239-0.123,43.853,7.19,48.38,16.593c-0.532-1.279-0.915-2.17-1.072-2.61c-0.834-2.333-1.166-6.167-0.333-8.167s2.667-12.833,2.833-19s-3.667-30-4.667-34.833s1.667-28.5,2.334-33.333s3-14.667,4.333-16.833c0.392-0.637,1.273-2.456,2.361-4.833C507.479,469.625,474.078,474.807,434.499,475.97z"
      },{
        "className": "buttocks",
        "value": "Buttocks",
        "name":"buttocks",
        "d": "M457.526,567.518C432,567.642,425,577.684,406,577.684c-20.5,0-22-10.279-51.53-10.279c-26.087,0-50.365,7.477-56.643,16.657c-0.185,0.311-0.366,0.62-0.578,0.938c-6,9-12,51.75-11.5,64s-2.5,24-4,32.5c0,19,7.324,25.063,15.316,37.142C317.76,749.914,355.235,772.904,389.5,739c2.75-2.875,6.75-8.875,7.75-11.625s2-3.25,4.375-3.25s3.75,1.125,4.25,2.875s3.792,8.5,7.292,11.334c37.774,39.903,74.878,12.96,94.414-18.404c8.533-13.701,14.134-14.93,14.134-38.43c-1.558-8.437-3.389-18.087-4.048-21.667c-1.167-6.333-0.5-24.333-2.666-42.667c-1.622-13.732-6.051-25.594-8.521-31.664c-0.206-0.505-0.397-0.97-0.573-1.393C501.379,574.708,482.766,567.395,457.526,567.518z"
      },{
        "className": "shoulder-lt",
        "value": "Shoulder [LT]",
        "name":"shoulder-lt",
        "d": "M260.5,300c1-5.75,6.083-52.999,45.542-74.5c-8.126,3.074-15.006,5.307-18.542,6.25c-8.263,2.203-41.894,9.408-53.5,19.5c-17.25,15-26,35-27.5,62.75c-0.721,13.331,0,25.833,0,34.5C216.333,324.25,240.667,322.333,260.5,300z"
      },{
        "className": "shoulder-rt",
        "value": "Shoulder [RT]",
        "name":"shoulder-rt",
        "d": "M542.667,300.333c0.164,0.62,0.305,1.276,0.431,1.956c16.05,17.076,38.94,31.042,53.412,43.878c-0.086-0.138-0.175-0.282-0.26-0.417C596.5,325.5,601,295,584,267.25S525.833,235.5,497.833,226C535,248.333,539.667,289,542.667,300.333z"
      },{
        "className": "arm-lt",
        "value": "Arm [LT]",
        "name":"arm-lt",
        "d": "M262.75,336.75c-1.75-10.5-3.25-31-2.25-36.75c-19.833,22.333-44.167,24.25-54,48.5c-6.833,10.667-18.25,33.75-20,44s-4.5,20-7.25,27.75c-3.98,34.526,10.693,40.75,26.143,46.43c16.538,6.08,29.232,0.639,38.288-15.131c1.1-2.171,2.2-4.311,3.152-6.215c3.5-7,16.417-34.458,17.292-37.333s2.125-4.875,3.25-4.875C271,366.333,264.5,347.25,262.75,336.75z"
      },{
        "className": "arm-rt",
        "value": "Arm [RT]",
        "name":"arm-rt",
        "d": "M598.107,466.68c14.466-5.319,29.127-11.117,27.667-40.179c-2.005-7.583-4.833-13.009-8.024-28.751c-3.706-18.282-14.002-39.975-21.24-51.583c-14.472-12.836-37.362-26.802-53.412-43.878c2.167,11.742-1.262,32.011-4.098,48.71c-3,17.667-4,36.667-2.999,52.083C537.168,403,538.75,406,540,408.75c1.086,2.39,15.768,34.99,21.069,46.274C570.186,468.092,582.849,472.29,598.107,466.68z"
      },{
        "className": "elbow-lt",
        "value": "Elbow [LT]",
        "name":"elbow-lt",
        "d": "M205.393,466.68c-15.45-5.68-30.124-11.904-26.143-46.43c-2.75,7.75-1.75,15.25-6.5,23.5s-0.75,6.5-9.75,20c-4.221,6.332-8.992,20.141-13.178,35.472c-1.258,4.606-2.463,9.351-3.584,14.07c3.399-5.935,6.601-22.609,50.438-11c10.714,2.837,31.865,11.173,26.897,27.549c2.671-7.794,4.745-15.229,6.308-21.617c2.547-10.41,3.739-18.036,3.953-19.891c0.5-4.333,0.833-7.333,1.5-9.333s2.167-9.833,2.333-13.167c0.122-2.427,3.069-8.474,6.014-14.285C234.625,467.319,221.931,472.76,205.393,466.68z"
      },{
        "className": "elbow-rt",
        "value": "Elbow [RT]",
        "name":"elbow-rt",
        "d": "M606.824,502.292c39.135-10.364,46.681,1.813,50.268,8.767c-0.215-1.377-0.413-2.655-0.592-3.809c-2.75-17.75-17.75-47-19.5-49.75s-8.25-16.5-10.25-26.75c-0.298-1.528-0.625-2.92-0.976-4.249c1.46,29.062-13.201,34.86-27.667,40.179c-15.259,5.61-27.922,1.412-37.038-11.656c0.798,1.699,1.386,2.92,1.681,3.476c2.25,4.25,2.25,4.75,2.177,7.75s2.823,14.25,4.073,19.5c1.179,4.95,0.139,15.905,7.558,38.93C579.114,512.447,597.768,504.69,606.824,502.292z"
      },{
        "className": "forearm-lt",
        "value": "Forearm [LT]",
        "name":"forearm-lt",
        "d": "M196.676,502.292c-43.837-11.609-47.038,5.065-50.438,11c-3.104,13.064-5.568,25.943-6.738,35.208c-2.207,17.467-8.379,42.596-11.756,56.062c-0.875,6.021,6.182,9.66,17.564,14.473c11.004,4.653,23.67,4.044,26.295,0.232c10.117-17.065,26.772-37.525,39.896-61.517c4.95-9.049,8.926-18.728,12.073-27.909C228.541,513.465,207.39,505.129,196.676,502.292z"
      },{
        "className": "forearm-rt",
        "value": "Forearm [RT]",
        "name":"forearm-rt",
        "d": "M658.191,619.035c8.857-3.745,15.074-6.784,16.994-10.783c-1.959-5.819-4.01-12.795-5.436-20.252c-3.039-15.895-9.573-57.137-12.658-76.941c-3.587-6.955-11.133-19.131-50.268-8.767c-9.057,2.398-27.71,10.155-30.267,22.388c0.45,1.397,0.928,2.833,1.442,4.32c9,26,30,55.25,45.5,79.5c2.965,4.638,5.481,8.858,7.626,12.689C637.134,623.104,649.446,622.732,658.191,619.035z"
      },{
        "className": "wrist-lt",
        "value": "Wrist [LT]",
        "name":"wrist-lt",
        "d": "M145.309,619.035c-11.382-4.813-18.439-8.452-17.564-14.473c-1.215,4.844-2.068,8.179-2.244,9.105c-0.667,3.5-4.164,10.214-6.167,18.333c-0.375,1.692-2.811,3.547-5.5,4.5c3.667-0.75,44.577,18.365,45.167,20.5c-1-4-1.25-8,7-27c1.483-3.416,3.387-6.993,5.604-10.733C168.979,623.079,156.313,623.688,145.309,619.035z"
      },{
        "className": "wrist-rt",
        "value": "Wrist [RT]",
        "name":"wrist-rt",
        "d": "M686,635.75c-3.5-0.5-4-8.25-5.25-12.25c-0.701-2.246-3.058-7.8-5.564-15.248c-1.92,3.999-8.137,7.038-16.994,10.783c-8.745,3.698-21.058,4.07-27.065,2.155c9.067,16.197,11.432,25.37,12.375,29.144c0.527,2.109,0.644,3.571,0.461,4.91c8.146-4.652,34.231-16.276,43.573-19.125C686.977,635.944,686.459,635.815,686,635.75z"
      },{
        "className": "back-of-hand-lt",
        "value": "Back Of Hand [LT]",
        "name":"back-of-hand-lt",
        "d": "M113.833,636.5c-3.168,1.123-14.167,7-17.833,8.5s-5.833,6.5-10.167,9s-8.333,6-9,8.833s-5.5,4.333-5.5,7.333s2,5.333,6.879,6s12.621-8,14.121-9.5s2.5,0.5,1.833,2.333s-5.667,15-6.833,19.834c-1.167,4.833-3.833,17-4.5,21s-3,20.999-3.333,23.999s-3.333,15,1.167,18.334s7.833-2.334,9.833-7.667s1.5-11.833,2.667-14.5s4.333-19,6.333-22.5s2.833,1.166,1.667,4.166S97.333,727.833,97.333,730s-1.833,14-2.5,18s-1.333,14,0,18.167s7.167,1.666,9-0.5s3.667-11.167,4.5-16.5s1-14.167,2.531-20s3.636-16.333,5.469-19.167s3.833,0.334,3.333,2.5s-2.333,9.5-4,16.333s-1.5,14.5-3,21.334s-3.167,12.333,0,15.833s6.5,0.833,8.5-1.667S125.5,751,126.833,742.5s4.667-21.166,5.833-25.166c1.167-4.001,3.5-7.834,5.333-7.5c1.833,0.333-0.167,6-1,9.166s-5,20.667-5.167,26.334s2.5,7.833,5.667,6.5s4.333-6,5-8.834s2.667-8.666,3.167-12s4.167-16.166,6.167-20.334c2-4.166,2.833-9.332,6.673-27.332C162.346,665.333,160,661,159,657C158.411,654.865,117.5,635.75,113.833,636.5z"
      },{
        "className": "back-of-hand-Rt",
        "value": "Back Of Hand [RT]",
        "name":"back-of-hand-rt",
        "d": "M724.001,661c-1.445-3.854-8-7.667-10.333-8.667S705.75,644.25,701,642.25c-4.127-1.738-9.761-4.982-13.465-6.132c-9.342,2.85-35.428,14.474-43.573,19.125c-0.222,1.623-0.882,3.065-1.795,5.257c-1.667,4-0.666,16.167,0.334,19.5c1,3.334,4.166,22.334,5.833,26s3,8.167,3.667,10.5s7.667,32,10.167,34.333s5.666,1.834,7-0.5s0.5-7.5,0-10.833s-1.667-9.833-2-12.5s-2.334-10.5-3.334-14.166c-1-3.667,1.334-3.668,3-1.5c1.666,2.166,3.334,8.666,4.167,11.833s3.5,16.166,4.333,20.666s2.834,17.667,5.834,20.834s5.666,3.333,8.166,1s1.167-7.333,0.834-10.167s-2.5-19.166-2.834-23s-3.833-14.334-4.666-20.5s2.666-1.834,3-0.5s4.166,14.833,4.666,18.333s3,15.667,3.5,22.667s3.667,13,4.834,14.5s6,2.167,7.5,0s1.166-5.667,1-9.333s-1.5-22.167-1.5-25.667s-4.5-19.834-5-23.5s1.333-1.834,2-0.166c0.667,1.666,4.999,19.166,5.833,22.833s1.166,7.333,1.833,12s3.833,9,6.333,10.333s5.5-1.166,6.5-3.833s-1.5-15.167-1.833-23.333s-1.5-11.334-2.167-14c-0.667-2.667-3-18.167-3.833-22.5c-0.833-4.334-6.666-19-7.666-21.834s4.166,0.5,5.666,2.833s7.5,5.5,10.5,5.333s5.667-1.667,6-5.333S726.001,666.333,724.001,661z"
      },{
        "className": "hamstring-lt",
        "value": "Hamstring [LT]",
        "name":"hamstring-lt",
        "d": "M297.066,718.642c-7.993-12.078-15.316-18.142-15.316-37.142c-1.5,8.5-8.25,43-9.75,54s-3,14.5-7.25,46.75s-1.25,76,2.75,93.5S280.25,912,282.75,925c14.239,23.213,32.047,27.719,48.263,28.709c17.666,1.079,33.441-2.949,40.654-15.376c1.667-5.833,6-44.5,8.167-58s9.5-61.333,10.5-78.667s1-34.999,0.167-40.999s-1.625-16.292-1-21.667C355.235,772.904,317.76,749.914,297.066,718.642z"
      },{
        "className": "hamstring-rt",
        "value": "Hamstring [RT]",
        "name":"hamstring-rt",
        "d": "M413.167,738.334C414,744.168,413.25,748.5,412,767s3.25,73.25,6.5,86.75s7,38,8.75,56.25c1.093,11.397,3.355,23.087,5.571,32.39c8.43,9.247,24.089,12.271,39.665,11.319c15.283-0.934,32.867-4.996,46.76-24.891c0.889-5.953,1.705-9.622,2.004-10.818c0.75-3,10.75-28,13.5-41.25s4.25-43.083,5.25-58.083s-4.499-54.001-5.833-61.667s-3.833-29.666-5.166-35.833s-4.334-21.667-4.834-25.667c-0.218-1.739-1.254-7.511-2.452-14c0,23.5-5.601,24.729-14.134,38.43C488.045,751.294,450.941,778.237,413.167,738.334z"
      },{
        "className": "back-of-knee-lt",
        "value": "Back Of Knee [LT]",
        "name":"back-of-knee-lt",
        "d": "M331.013,953.709c-16.216-0.99-34.024-5.496-48.263-28.709c2.5,13,3.25,32.25,4.25,53.5c0.655,13.917-0.084,29.658-1.164,42.445c2.574-20.91,19.106-19.136,35.64-17.488c16.633,1.658,33.267,3.272,35.69,9.876c-1.167-5.5,0.667-11.167,3-16s3.167-17.833,4-28.833s5.833-24.334,7.5-30.167C364.455,950.76,348.679,954.788,331.013,953.709z"
      },{
        "className": "back-of-knee-rt",
        "value": "Back Of Knee [RT]",
        "name":"back-of-knee-rt",
        "d": "M472.486,953.709c-15.576,0.951-31.235-2.072-39.665-11.319c1.331,5.594,2.646,10.325,3.679,13.61c2.75,8.75,2.25,34.25,5.5,40.5c2.566,4.935,3.724,9.253,3.484,15.155c6.028-4.677,22.368-6.785,36.539-8.198c13.658-1.361,29.354,1.297,35.854,14.047c-1.023-13.899-1.763-29.888-1.628-46.754c0.15-18.787,1.655-32.959,2.996-41.932C505.354,948.713,487.77,952.775,472.486,953.709z"
      },{
        "className": "calf-lt",
        "value": "Calf [LT]",
        "name":"calf-lt",
        "d": "M321.476,1003.457c-16.533-1.647-33.065-3.422-35.64,17.488c-0.569,6.737-1.232,12.655-1.836,17.055c-1.75,12.75-5,46-2.5,60s8.75,70.5,9,91.75c2.411,11.598,18.52,15.432,31.624,15.948c13.165,0.52,23.325-2.338,25.624-16.146c1.52-12.183,2.896-25.104,3.086-31.552c0.333-11.333,8.333-24,12.833-37.334s0.5-46.666-1.167-65.5s-4.167-36.333-5.333-41.833C354.743,1006.729,338.109,1005.115,321.476,1003.457z"
      },{
        "className": "calf-rt",
        "value": "Calf [RT]",
        "name":"calf-rt",
        "d": "M482.023,1003.457c-14.171,1.413-30.511,3.521-36.539,8.198c-0.064,1.573-0.222,3.253-0.484,5.095c-1.25,8.75-7,65.25-7.5,84.75s7.5,36,10.5,40s3.75,15.5,4,21.75c0.127,3.173,1.801,16.722,3.811,30.928c5.639,7.736,15.869,11.903,25.566,11.521c11.76-0.464,25.933-3.604,30.46-12.624c0.124-3.28,0.258-6.378,0.413-9.074c0.75-13,4.75-46.75,7.5-74s3-44.75,1-62.25c-0.921-8.055-1.999-18.392-2.872-30.246C511.377,1004.754,495.682,1002.096,482.023,1003.457z"
      },{
        "className": "ankle-lt",
        "value": "Ankle [LT]",
        "name":"ankle-lt",
        "d": "M322.124,1205.698c-13.104-0.517-29.212-4.351-31.624-15.948c0.25,21.25,4.125,51.5,4.25,58.125s-1.25,26.75-1,28.625s0.25,3.75-0.875,3.75c6.082,14.415,4.342,25.212,3.644,34.406c-0.388,5.104,0.181,9.513,1.315,14.177c10.5-13.499,47.957-20.15,48.229-7.491c-0.177-6.154-1.244-13.505-2.062-20.509c-1.5-12.834,1.833-27.333,2.167-31.167s-4.5-18.5-5.833-25.5s2.167-19.166,4.167-31.333c0.862-5.245,2.096-14.051,3.247-23.281C345.448,1203.36,335.289,1206.218,322.124,1205.698z"
      },{
        "className": "ankle-rt",
        "value": "Ankle [RT]",
        "name":"ankle-rt",
        "d": "M481.377,1205.698c-9.697,0.383-19.928-3.784-25.566-11.521c1.949,13.775,4.214,28.17,5.69,34.323c3,12.5,1,17.833-1.833,26.667s-2.334,14.333-1.334,21.999s0.667,17.5,0.5,24.5c-0.098,4.075-2.111,11.312-2.63,18.029c5.397-8.651,37.767-2.677,48.526,9.038c0.54-0.488,1.031-0.948,1.459-1.357c0.771-4.053,1.113-8.488,0.792-12.721c-0.999-13.15-1.991-21.145,2.987-33.769c-0.096-0.073-0.193-0.146-0.302-0.221C507.5,1279.167,508,1268,508,1259s1.25-24.25,2.25-32c0.793-6.143,1.114-21.391,1.587-33.926C507.31,1202.095,493.137,1205.234,481.377,1205.698z"
      },{
        "className": "sole-lt",
        "value": "Sole [LT]",
        "name":"sole-lt",
        "d": "M297.833,1328.833C300.167,1331,310.5,1338,321,1340.512s19.167-1.512,23-7.179c1.741-2.574,2.21-6.868,2.062-11.991C345.791,1308.683,308.333,1315.334,297.833,1328.833z"
      },{
        "className": "sole-rt",
        "value": "Sole [RT]",
        "name":"sole-rt",
        "d": "M456.204,1319.696c-0.372,4.823,0.025,9.38,2.463,12.305c6.573,7.889,13.334,10.333,26.667,7.166c9.396-2.231,15.717-7.104,19.396-10.433C493.971,1317.02,461.602,1311.045,456.204,1319.696z"
      },{
        "className": "foot-lt",
        "value": "Foot [LT]",
        "name":"foot-lt",
        "d": "M296.519,1314.656c0.699-9.194,2.438-19.991-3.644-34.406c-1.125,0-2.875,1.375-3.125,2.625s-2.375,0.625-4,0.125s-6.625,4.5-6.75,5.125s-0.25,1.25-2.25,1.125s-5.75,3.125-5.875,4.125s-1.208,1.792-2.875,1.958s-4.167,3-5.167,5.334s0.833,4.833,1.667,9.166s6.667,9.333,18.833,12.167s12.167,4.666,14.5,6.833C296.699,1324.169,296.13,1319.761,296.519,1314.656z"
      },{
        "className": "foot-rt",
        "value": "Foot [RT]",
        "name":"foot-rt",
        "d": "M506.981,1314.656c0.321,4.232-0.021,8.668-0.792,12.721c0.792-0.758,1.396-1.358,1.812-1.71c2.167-1.834,16-5.666,21.5-7.5s7.333-7.166,7.666-10.166s0.5-2.667,1.834-5.834s-5.167-7.5-6-7.5s-2,0-2-1.5s-3.667-4.333-5.167-4.333s-3-1-3.5-2.5s-6.667-3.833-8.833-3.5c-2.058,0.316-1.715-0.571-3.532-1.946C504.99,1293.512,505.982,1301.506,506.981,1314.656z"
      },],
      body_chart: this.props.body_chart,
      slug:'male_back',
      isClickDisabled:false,
      chart:this.props.chart,
    }
    
  }

  componentDidMount() {
    let paths = this.state.paths;
    let { body_chart } = this.props;
    
    let isClickDisabled= this.props.isClickDisabled ? this.props.isClickDisabled : false;
    this.state.paths.map((path, i) => (
      paths[i].fill = "rgba(255, 255, 255, 0)"
    ));
    
    this.setState({
      paths: paths,
      isClickDisabled:isClickDisabled,
    });
    if(!_.isEmpty(body_chart)){
      paths = paths.map((path, i) => {
        if(body_chart.some(body_charts => path.value === body_charts.part_name && this.state.slug === body_charts.image_slug)){
          path.className = path.className + " active";
          path.fill = "rgba(29, 81, 138)";
          }
        return path;
      });
     
      this.setState({
        paths: paths,
        
      }); 
    }
  }
  setPaths = (paths) => {
    this.setState({
      paths: paths
    });
    
  };
  
  handleBodyChart = (bodyChart) => {
    let {body_chart} = this.state;
    bodyChart.map((chart, i) => {
        chart.image_slug = this.state.slug;
        chart.image_name= 'Male Back';
        return chart;
    });
    this.setState({ body_chart: body_chart.concat(bodyChart) })
    this.props.handleBodyChart(bodyChart)
    
  }
  removePart = (part) =>{
    let {body_chart,slug} = this.state;
    body_chart = body_chart.filter((s) =>{
      return part !== s.part_name || s.image_slug!==slug
    });
    this.setState({ body_chart: body_chart })
    this.props.removePart(part,slug)
  }
  removeBodyChart = (image_slug) =>{
    let { body_chart } = this.state;
    body_chart = body_chart.filter((s) =>{
        return s.image_slug!==image_slug
    });
    this.setState({ body_chart: body_chart })
    this.props.removeBodyChart(image_slug)
  }
  changeHandler = (parts,e) => {
        
    let {body_chart,slug} = this.state;
    
    const result = body_chart.findIndex(s => s.part_slug === parts &&  s.image_slug===slug);
    
    body_chart[result][e.target.name] = e.target.value;
    this.setState({ body_chart: body_chart })
    this.props.changeHandler(parts,slug,e)
    
  }
  removeChart =(image_slug,e) =>{
    let { chart } = this.state;
    _.pull(chart, image_slug);
    this.setState({ chart: chart })
    
    this.removeBodyChart(image_slug)
  }
  changeSubPartHandler = (parts, e) => {
    let {body_chart,slug} = this.state
    const result = body_chart.findIndex(s => s.part_slug === parts &&  s.image_slug===slug);

    body_chart[result]['sub_part'] = e.value;
    this.setState({ body_chart: body_chart })
    this.props.changeSubPartHandler(parts, slug, e)
  }
  render() {
    return (
      <>
        {!this.props.isViewBodyChart && <BodyChart
        pathConfig={this.state.paths}
        setPaths={this.setPaths}
        slug={this.state.slug}
        body_chart={this.props.body_chart}
        handleBodyChart={this.handleBodyChart}
        removeChart ={this.removeChart}
        removePart={this.removePart}
        changeHandler={this.changeHandler}
        changeSubPartHandler={this.changeSubPartHandler}
        />}
        {this.props.isViewBodyChart && <ViewBodyChart
        pathConfig={this.state.paths}
        body_chart={this.props.body_chart}
        />}
      </>
    );
  }
}

export default connect(null, null)(MaleBack);