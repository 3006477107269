import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as PrescriptionAction from "../../actions/prescriptionAction";
import DataGrid from "../../components/DataGrid";
import * as Helper from "../../utils/helpers";
import TechnicianPharmacistDashboard from '../../components/Dashboard/Technician&PharmacistDashboard';

class TechnicianPrescriptionListPage extends React.Component {

    constructor(props) {
        super(props);
        this.props.technicianPrescriptionList({ sortBy: { sortField: "created_at", sortOrder: "asc" }, });
        this.state = {
            'dashboard_info': ''
        }
    }

    columns = () => {
        return [
            {
                dataField: "prescription_id",
                text: "Prescription Id",
                sort: false
            },
            {
                dataField: "patient_name",
                text: "Patient Name",
                sort: false
            },
            {
                dataField: "prescription_date",
                text: "Prescription Date",
                formatter: (cell) => {
                    return Helper.getDateFormat(cell);
                },
                sort: false
            },
            {
                dataField: "assigned_by_name",
                text: "Pharmacist Name",
                sort: true,
            },
            {
                dataField: "created_at",
                text: "Assigned Time",
                formatter: (cell) => {
                    return Helper.getDateTimeFormat(cell);
                },
                sort: false
            },
            {
                dataField: "acquired_by_name",
                text: "Is Acquired",
                formatter: (cell) => {
                    return cell ? 'Yes' : 'No'
                },
                sort: false
            },
            {
                dataField: "actions",
                text: "Actions",
                isDummyField: true,
                formatter: this.actionFormatter
            },
        ];
    };

    actionFormatter = (cell, row) => {
        return (<React.Fragment>
            <Link to={'/technician/prescriptions/' + row.prescription_id + '/orders/create'} className="btn btn-secondary">create Rx</Link>
        </React.Fragment>);
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        if (type === 'search') {
            page = 1;
        }

        let sortBy = sortField ? { sortField, sortOrder } : { sortField: "created_at", sortOrder: "asc" };

        this.props.technicianPrescriptionList({ page, sizePerPage, sortBy });
    }

    render() {
        let { data, meta } = this.props.prescription_list;
        return (<React.Fragment>
            <Helmet>
                <title>Assigned Prescription</title>
            </Helmet>
            <h4 className="page-title">Assigned Prescription</h4>
            <div className="row">
                <TechnicianPharmacistDashboard
                    assigned_prescription={this.state.dashboard_info.assignedCount}
                    order_count={this.state.dashboard_info.sentForApproval}
                    selected_user_profile={this.props.selected_user_profile} />
            </div>
            <div className="content-wrapper">
                <DataGrid
                    columns={this.columns()}
                    data={data}
                    meta={meta}
                    handleTableChange={this.handleTableChange}
                />
            </div>
        </React.Fragment >);
    }
}

const mapStateToProps = state => ({
    prescription_list: state.prescription.paginate,
    user: state.session.user,
    selected_user_profile: state.selected_user_profile,
});

const mapActionsToProps = {
    technicianPrescriptionList: PrescriptionAction.technicianPrescriptionList
};

export default connect(mapStateToProps, mapActionsToProps)(TechnicianPrescriptionListPage);