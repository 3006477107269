import React from 'react';
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';
import PatientService from '../services/patientService';
import * as loaderAction from '../actions/loaderAction';
import { connect } from 'react-redux';
import { getDateFormat } from '../utils/helpers';
import { isEmpty } from 'lodash';
import Notification from '../utils/notification';
import PathoRadioService from '../services/pathoRadioService';
import UploadExternalPathologyReportModal from "./UploadExternalPathologyReportModal";
import GenerateLabReportModal from  "./GenerateLabReportModal";

class uploadPathologyReportModal extends React.Component {
    state = {
        pathology_test_items: '',
        file: '',
        test_item: ''
    }
    componentDidMount() {
        this.props.showLoader();
        PatientService.getPrescribedPathologyTests(this.props.patient.patient_id).then(resp => {
            this.setState({
                pathology_test_items: resp.data
            })
            this.props.hideLoader();
        }).catch(err => {
            this.props.hideLoader();
            console.log(err)
        })
    }
    handleFileUpload = (e) => {
        this.setState({ file: e.target.files })
    }
    handleSelectedTest = (test_item) => {
        this.setState({ test_item })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        if (!this.state.file) {
            Notification.show('error', { message: 'Kindly upload file.' })
            return false;
        } else if (!this.state.test_item) {
            Notification.show('error', { message: 'Kindly select test.' })
            return false;
        } else if (/\.(pdf|PDF)$/i.test(this.state.file[0].name) === false) {
            Notification.show('error', { message: 'Only PDF file is allowed to upload' });
            return false;
        } else if (this.state.file[0].size > 25600000) {
            Notification.show('error', { message: 'PDF size should not be more than 25 MB' });
            return false;
        } else {
            const { test_item, file } = this.state;
            const fd = new FormData();
            fd.append('file', file[0]);
            fd.append('prescription_pathology_test_id', test_item.prescription_pathology_tests_id)
            fd.append('prescription_id', test_item.prescription_id)
            fd.append('patient_id', this.props.patient.patient_id);
            fd.append('order_pathology_test_items', JSON.stringify([{ ...test_item }]))
            this.props.showLoader();
            PathoRadioService.createDoctorPathologyOrder(fd).then(resp => {
                Notification.show('success', { message: 'Report has been uploaded successfully.' })
                this.props.refreshList();
                this.props.hideLoader();
                this.props.handleModal()
            }).catch(err => {
                this.props.hideLoader();
                Notification.show('error', err.response.data)
            })
        }
    }

    modelClose = () => {
        this.props.handleModal();
    }

    render() {
        return (
            <Modal size='lg'
                centered aria-labelledby="contained-modal-title-vcenter"
                show={this.props.show}
                onHide={this.props.handleModal}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    Upload Lab Report
                </Modal.Header>
                <Modal.Body>
                    <Tabs defaultActiveKey={"existing"} onSelect={k => k}>
                        <Tab eventKey="existing" title="Existing" style={{ width: "100%" }}>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Date</th>
                                        <th>Test</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.pathology_test_items && !isEmpty(this.state.pathology_test_items.data) ? this.state.pathology_test_items.data.map(row =>
                                        <tr>
                                            <td><input className="form-check-input mb-3" type="radio" name="radioGroup" id={row.id} onClick={() => this.handleSelectedTest(row)} /><label className="form-check-label mb-3" for={row.id} /></td>
                                            <td>{getDateFormat(row.created_at)}</td>
                                            <td>{row.test_name}</td>
                                        </tr>
                                    ) : <tr>No Record Found</tr>}
                                </tbody>
                            </table>
                            <br />
                            <div class="custom-file">
                                <input id="input-b2" name="input-b2" type="file" onChange={this.handleFileUpload} accept='.pdf'/>
                            </div>
                            <span className="text-info">Please attach pdf with file limit upto 25MB</span>

                            <Modal.Footer>
                                <Button variant="outline-primary" onClick={this.props.handleModal}>
                                    Cancel
                                </Button>
                                <Button variant="primary" onClick={this.handleSubmit}>
                                    Upload
                                </Button>
                            </Modal.Footer>

                        </Tab>
                        <Tab eventKey="new-upload" title="New Upload" style={{ width: "100%" }}>
                            <UploadExternalPathologyReportModal patient={this.props.patient} handleModal={this.modelClose} refreshList={this.props.refreshList}/>
                        </Tab>
                        <Tab eventKey="generate-report" title="Generate Report" style={{ width: "100%" }}>
                            <GenerateLabReportModal pathology_test_items = {(this.state.pathology_test_items && !isEmpty(this.state.pathology_test_items.data)) && this.state.pathology_test_items} patient={this.props.patient} handleModal={this.modelClose} refreshList={this.props.refreshList}/>
                        </Tab>
                    </Tabs>
                </Modal.Body>
            </Modal >
        )
    }
}
const mapStateToProps = (state) => ({
});

const mapActionsToProps = {
    showLoader: loaderAction.loaderShow,
    hideLoader: loaderAction.loaderHide
};

export default connect(mapStateToProps, mapActionsToProps)(uploadPathologyReportModal);
