import React from 'react';

export const Memory = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <label className="heading">Memory </label>
        <div className="options-details">
            {/* normal */}
            <input type="checkbox" id='psychiatric-memory-normal' name='psychiatric-memory-normal' value='psychiatric-memory-normal' onChange={e => handleChange(e, 'psychiatric')} checked={checkIfProblemExist('psychiatric', 'psychiatric-memory-normal') ? 'checked' : ''} />
            <label htmlFor='psychiatric-memory-normal'>Normal</label>
            {/* abnormal */}
            <input type="checkbox" id='psychiatric-memory-abnormal' name='psychiatric-memory-abnormal' value='psychiatric-memory-abnormal' onChange={e => handleChange(e, 'psychiatric')} checked={checkIfProblemExist('psychiatric', 'psychiatric-memory-abnormal') ? 'checked' : ''} />
            <label htmlFor='psychiatric-memory-abnormal'>Abnormal</label>
        </div>
    </React.Fragment>
}
export const Speech = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <label className="heading">Speech </label>
        <div className="options-details">
            {/* incongruent */}
            <input type="checkbox" id='psychiatric-speech-incongruent' name='psychiatric-speech-incongruent' value='psychiatric-speech-incongruent' onChange={e => handleChange(e, 'psychiatric')} checked={checkIfProblemExist('psychiatric', 'psychiatric-speech-incongruent') ? 'checked' : ''} />
            <label htmlFor='psychiatric-speech-incongruent'>Incongruent</label>
            <input type="checkbox" id='psychiatric-speech-pressured' name='psychiatric-speech-pressured' value='psychiatric-speech-pressured' onChange={e => handleChange(e, 'psychiatric')} checked={checkIfProblemExist('psychiatric', 'psychiatric-speech-pressured') ? 'checked' : ''} />
            <label htmlFor='psychiatric-speech-pressured'>Pressured</label>
        </div>
    </React.Fragment>
}
export const Comment = ({ handleChange, disabled, getCategoryComment }) => {
    return <React.Fragment>
        <label className="heading-comment">Comment </label>
        <textarea className="form-control" name="comments" placeholder="Type here..." onChange={e => handleChange(e, 'psychiatric')} disabled={disabled === true ? 'disabled' : ''} value={getCategoryComment('psychiatric') ? getCategoryComment('psychiatric') : ''}></textarea>
    </React.Fragment>
}
export const OTTP = ({ handleChange, checkIfProblemExist }) => {
    return <div className="options-details">
        <input type="checkbox" id='psychiatric-ottp' name='psychiatric-ottp' value='psychiatric-ottp' onChange={e => handleChange(e, 'psychiatric')} checked={checkIfProblemExist('psychiatric', 'psychiatric-ottp') ? 'checked' : ''} />
        <label htmlFor='psychiatric-ottp'>OTPP</label>
    </div>
}