import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function (state = initialState.pagination, { type, ...payload }) {
    switch (type) {
        case types.APPOINTMENT_LIST_REQUEST:
            return {
                ...state,
                ...payload,
                isLoading: true
            };
        case types.APPOINTMENT_LIST_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoading: false
            };
        case types.APPOINTMENT_LIST_FAILED:
            return {
                ...state,
                ...payload,
                isLoading: false
            };
        case types.HOLIDAY_LIST_REQUEST:
            return {
                ...state,
                ...payload,
                isLoading: true
            };
        case types.HOLIDAY_LIST_SUCCESS:
            return {
                ...state,
                holidays: payload.data,
                isLoading: false
            };
        case types.HOLIDAY_LIST_FAILED:
            return {
                ...state,
                ...payload,
                isLoading: false
            };
        default:
            return state;
    }
}