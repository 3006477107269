import React from 'react';

export const Throat = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <div className="heading">
            <h6>Throat</h6>
        </div>
        <div className="form-group full">
            <div className="options-details">
                <input type="checkbox" id='frequent-sore-throats' name='frequent-sore-throats' onChange={e => handleChange(e, 'throat')} checked={checkIfProblemExist('throat', 'frequent-sore-throats') ? 'checked' : ''} />
                <label htmlFor='frequent-sore-throats'>Frequent sore throat</label>
                <input type="checkbox" id='hoarseness' name='hoarseness' onChange={e => handleChange(e, 'throat')} checked={checkIfProblemExist('throat', 'hoarseness') ? 'checked' : ''} />
                <label htmlFor='hoarseness'>Hoarseness</label>
                <input type="checkbox" id='difficulty-in-swallowing' name='difficulty-in-swallowing' onChange={e => handleChange(e, 'throat')} checked={checkIfProblemExist('throat', 'difficulty-in-swallowing') ? 'checked' : ''} />
                <label htmlFor='difficulty-in-swallowing'>Difficulty in swallowing </label>
                <input type="checkbox" id='pain-in-jaw' name='pain-in-jaw' onChange={e => handleChange(e, 'throat')} checked={checkIfProblemExist('throat', 'pain-in-jaw') ? 'checked' : ''} />
                <label htmlFor='pain-in-jaw'>Pain in jaw</label>
            </div>
        </div>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment }) => {
    return <React.Fragment>
        <label className="heading-comment">Comment </label>
        <textarea className="form-control" name="comments" placeholder="Type here..." onChange={e => handleChange(e, 'throat')} value={getCategoryComment('throat') ? getCategoryComment('throat') : ''}></textarea>
    </React.Fragment>
}