import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class PathoRadioService {

    static patientPathologyList({ page, sizePerPage, sortBy }, patientId) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy })
        };

        return axios.get(apiRoute('/v1/patients/' + patientId + '/pathology-tests'), requestOptions);
    }

    static patientRadiolgyTestList({ page, sizePerPage, sortBy }, patientId) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy })
        };

        return axios.get(apiRoute('/v1/patients/' + patientId + '/radiology-tests'), requestOptions);
    }

    static showPathologyTestsByPrescriptionId(prescriptionId, query) {
        const requestOptions = {
            headers: getApiHeader(),
            params: query
        };

        return axios.get(apiRoute('/v1/pathologies/tests/' + prescriptionId), requestOptions);
    }

    static addToQueue(queueData) {
        const requestOptions = {
            headers: getApiHeader(),
        };
        let paramsdata = JSON.stringify(queueData);

        return axios.post(apiRoute('/v1/queue'), paramsdata, requestOptions);
    }

    static showRadiologyTestsByPrescriptionById(prescriptionId, query) {
        const requestOptions = {
            headers: getApiHeader(),
            params: query
        };

        return axios.get(apiRoute('/v1/radiologies/tests/' + prescriptionId), requestOptions);
    }

    static createPathologyOrder(data) {
        const requestOptions = {
            headers: getApiHeader(),
        };
        let paramsdata = JSON.stringify(data);

        return axios.post(apiRoute('/v1/pathologies/orders'), paramsdata, requestOptions);
    }

    static createRadiologyOrder(data) {
        const requestOptions = {
            headers: getApiHeader(),
        };
        let paramsdata = JSON.stringify(data);

        return axios.post(apiRoute('/v1/radiologies/orders'), paramsdata, requestOptions);
    }

    static listPathologyOrder({ page, sizePerPage, sortBy, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, filter })
        };

        return axios.get(apiRoute('/v1/pathologies/orders'), requestOptions);
    }

    static listRadiologyOrder({ page, sizePerPage, sortBy, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, filter })
        };

        return axios.get(apiRoute('/v1/radiologies/orders'), requestOptions);
    }

    static showPathologyOrderById(id) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute('/v1/pathologies/orders/' + id), requestOptions);
    }

    static showRadiologyOrderById(id) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute('/v1/radiologies/orders/' + id), requestOptions);
    }

    static radiologyReportSubmit(order_id, status) {
        const requestOptions = {
            headers: getApiHeader(),
        };
        let paramsdata = JSON.stringify(status);
        return axios.put(apiRoute('/v1/radiologies/orders/' + order_id + '/status'), paramsdata, requestOptions);
    }

    static fileUploadForPathology(fd, orderId, itemId) {
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.post(apiRoute('/v1/pathologies/orders/' + orderId + '/items/' + itemId + '/documents'), fd, requestOptions);
    }

    static fileDeleteForPathology(orderId, itemId, document_id) {
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.delete(apiRoute('/v1/pathologies/orders/' + orderId + '/items/' + itemId + '/documents/' + document_id), requestOptions);
    }

    static pathologyReportSubmit(order_id, status) {
        const requestOptions = {
            headers: getApiHeader(),
        };
        let paramsdata = JSON.stringify(status);
        return axios.put(apiRoute('/v1/pathologies/orders/' + order_id + '/status'), paramsdata, requestOptions);
    }

    static fileUploadForRadiology(fd, orderId, itemId) {
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.post(apiRoute('/v1/radiologies/orders/' + orderId + '/items/' + itemId + '/documents'), fd, requestOptions);
    }

    static fileDeleteForRadiology(orderId, itemId, document_id) {
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.delete(apiRoute('/v1/radiologies/orders/' + orderId + '/items/' + itemId + '/documents/' + document_id), requestOptions);
    }

    static listPathologyDocuments({ page, sizePerPage, sortBy, filter }, patient_id) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, filter })
        };
        return axios.get(apiRoute('/v1/patients/' + patient_id + '/pathology-documents'), requestOptions);
    }

    static listRadiologyDocuments({ page, sizePerPage, sortBy, filter }, patient_id) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, filter })
        };
        return axios.get(apiRoute('/v1/patients/' + patient_id + '/radiology-documents'), requestOptions);
    }

    static listPrescriptionPathologyDocuments(prescriptionId) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute('/v1/prescriptions/' + prescriptionId + '/pathology-orders/documents'), requestOptions);
    }

    static listPrescriptionRadiologyDocuments(prescriptionId) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute('/v1/prescriptions/' + prescriptionId + '/radiology-orders/documents'), requestOptions);
    }

    static getDocumentDownloadUrl(documentId) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute('/v1/documents/' + documentId + '/download'), requestOptions);
    }

    static createDoctorPathologyOrder(postData) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.post(apiRoute('/v1/doctors/pathology-orders'), postData, requestOptions);
    }

    static createDoctorRadiologyOrder(postData) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.post(apiRoute('/v1/doctors/radiology-orders'), postData, requestOptions);
    }

    static uplodDoctorPathologyOrder(postData) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.post(apiRoute('/v1/doctors/pathology-report-upload'), postData, requestOptions);
    }

    static uplodDoctorRadiologyOrder(postData) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.post(apiRoute('/v1/doctors/radiology-report-upload'), postData, requestOptions);
    }

    static generatePathologyReportOrder(postData) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.post(apiRoute('/v1/doctors/pathology-report-generate'), postData, requestOptions);
    }
}
export default PathoRadioService