import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import SearchPatient from '../../components/SearchPatient';

export class SearchPatientPage extends Component {

    handlePatientChange = (patientId) => {
        this.props.history.push('/authority-access/patients/' + patientId);
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Search Record</title>
                </Helmet>
                <div className="row">
                    <div className="col-md-24">
                        <h4 className="page-title">Search Record</h4>
                        <div className="content-wrapper">
                            <SearchPatient 
                                handlePatientSearch={this.handlePatientChange}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default SearchPatientPage
