import React from 'react';

export const ReproductiveHistory = ({ gynaecology_history, handleChange }) => {
    return <React.Fragment>
        <div className="heading">
            <h6>Reproductive History</h6>
        </div>
        <div className="form-group col-33">
            <label>Number of Pregnancies :</label>
            <input type="text" id="pregnancies_number" name="pregnancies_number" className="form-control" onChange={handleChange} value={gynaecology_history.pregnancies_number ? gynaecology_history.pregnancies_number : ''} />
        </div>
        <div className="form-group col-33">
            <label>Number of Miscarriage :</label>
            <input type="text" id="miscarrages_number" name="miscarrages_number" className="form-control" onChange={handleChange} value={gynaecology_history.miscarrages_number ? gynaecology_history.miscarrages_number : ''} />
        </div>
        <div className="form-group col-33 padding-none">
            <label>Number of Abortions :</label>
            <input type="text" id="abortions_number" name="abortions_number" className="form-control" onChange={handleChange} value={gynaecology_history.abortions_number ? gynaecology_history.abortions_number : ''} />
        </div>
    </React.Fragment>
}

export const GynaecologyHistory = ({ gynaecology_history, handleChange, getValue }) => {
    let { age_of_first_period, bleeding_between_periods, is_menopause, menopause_age, contraception, pap_smear, is_regular_periods, pap_smear_test_date, menopause_comment } = gynaecology_history;
    return <React.Fragment>
        <div className="heading">
            <h6>Gynaecological History</h6>
        </div>
        <div className="form-group first">
            <label>Age of first period :</label>
            <input type="text" id="age_of_period" name="age_of_first_period" className="form-control" onChange={handleChange} value={age_of_first_period ? age_of_first_period : ''} />
        </div>
        <div className="form-group second">
            <label>Regular Period? : </label>
            <select className="form-control" name="is_regular_periods" onChange={handleChange} value={getValue(is_regular_periods)}>
                <option value='' defaultValue>---select---</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
            </select>
        </div>
        <div className="form-group third no-padding">
            <label>Duration of Period :</label>
            <input type="number" id="bleeding_between_periods" name="bleeding_between_periods" className="form-control" onChange={handleChange} value={bleeding_between_periods ? bleeding_between_periods : ""} />
            <span> Days</span>
        </div>
        <div className="form-group fourth">
            <label>Menopause : </label>
            <select className="form-control" name="is_menopause" onChange={handleChange} value={getValue(is_menopause)}>
                <option value='' defaultValue>---select---</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
            </select>
        </div>
        <div className="form-group fifth">
            <label> Menopause start date :</label>
            <input type="date" id="menopause_age" name="menopause_age" className="form-control" onChange={handleChange} value={menopause_age ? menopause_age : ''} 
            />
        </div>
        {
            is_menopause && <div className='form-group fifth'>
            <label style={{marginRight: '8px'}}>Menopause Comment :</label>
            <textarea
                  className="form-control"
                  name="menopause_comment"
                  id="menopause_comment"
                  onChange={handleChange}
                  style={{marginBottom: '-15px'}}
                >{menopause_comment ? menopause_comment : ''}</textarea>
        </div>
        }
        <div className="form-group sixth">
            <label>Contraception : </label>
            <select className="form-control" name="contraception" onChange={handleChange} value={contraception ? contraception : ''}>
                <option value='' defaultValue>---select---</option>
                <option value='ocp'>OCP</option>
                <option value="injectable">Injectable</option>
                <option value="subcutaneous">Subcutaneous</option>
                <option value="iud">IUD</option>
            </select>
        </div>
        <div className="form-group seventh no-padding">
            <label>Pap Smear : </label>
            <select className="form-control" name="pap_smear" onChange={handleChange} value={pap_smear ? pap_smear : ''}>
                <option value='' defaultValue>---select---</option>
                <option value="normal">Normal</option>
                <option value="abnormal">Abnormal</option>
                <option value="never-did-papsmear">Never did Pap Smear</option>
            </select>
        </div>
        <div className="form-group fifth no-padding">
            <label>Pap Smear Test Date: </label>
            <input type="date" name="pap_smear_test_date" className="form-control" onChange={handleChange} value={pap_smear_test_date ? pap_smear_test_date : ''} />
        </div>
    </React.Fragment>
}
export const Allergy = ({ gynaecology_history, handleChange, getValue }) => {
    let { allergies, is_hospitalized_allergic_reaction, allergies_comment } = gynaecology_history;
    return <React.Fragment>
        <div className="heading">
            <h6>Allergies to Contraceptives</h6>
        </div>
        <div className="form-group full">
            <label>Allergies to medications/substances :</label>
            <input type="text" id="allergies" name="allergies" className="form-control" onChange={handleChange} value={allergies ? allergies : ''} />
        </div>
        <div className="form-group full">
            <label>Ever been hospitalized for allergic reaction? : </label>
            <select className="form-control" name="is_hospitalized_allergic_reaction" onChange={handleChange} value={getValue(is_hospitalized_allergic_reaction)}>
                <option value='' defaultValue>---select---</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
            </select>
        </div>
        <div className="form-group full">
            <label htmlFor="comment">Comment : </label>
            <input type="text" className="form-control" name="allergies_comment" onChange={handleChange} value={allergies_comment ? allergies_comment : ""}/>
        </div>
    </React.Fragment>
}