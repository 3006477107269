import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class UserNotificationService {
    static userNotification({ page, sizePerPage, sortBy, searchText }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText })
        };

        return axios.get(apiRoute('/v1/user-notifications'), requestOptions);
    }
    static readNotification(id) {
        const body = JSON.stringify({
            id: id
        });
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.put(apiRoute('/v1/user-notifications/' + id), body, requestOptions);
    }

    static sendTokenToSever(token) {
        const body = JSON.stringify({
            token: token
        });
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute('/v1/users/notification-tokens'), body, requestOptions);
    }

    static getNotificationCount() {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute('/v1/notifications/counts'), requestOptions);
    }

    static resetNotificationCount() {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute('/v1/notifications/reset-counts'), requestOptions);
    }
}


export default UserNotificationService;