import axios from 'axios';
import { apiRoute, getApiHeader } from '../utils/helpers';

class DocketNumberService {
    static create(data) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.post(apiRoute('/v1/docket-numbers'), JSON.stringify(data), requestOptions);
    }

    static update(id, data) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.put(apiRoute('/v1/docket-numbers/' + id), JSON.stringify(data), requestOptions);
    }
}

export default DocketNumberService;