import React from 'react';

export const NervousSystem = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <h6>Nervous System </h6>
        <input type="checkbox" id='nervous-system-normal' name='nervous-system-normal' onChange={e => handleChange(e, 'nervous-system')} checked={checkIfProblemExist('nervous-system', 'nervous-system-normal') ? 'checked' : ''} />
        <label htmlFor='nervous-system-normal'>Normal</label>
    </React.Fragment>
}
export const Gait = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <label className="heading">Gait </label>
        <div className="options-details">
            <input type="checkbox" id='nervous-system-gait-normal' name='nervous-system-gait-normal' onChange={e => handleChange(e, 'nervous-system')} checked={checkIfProblemExist('nervous-system', 'nervous-system-gait-normal') ? 'checked' : ''} />
            <label htmlFor='nervous-system-gait-normal'>Normal</label>
            <input type="checkbox" id='nervous-system-gait-abnormal' name='nervous-system-gait-abnormal' onChange={e => handleChange(e, 'nervous-system')} checked={checkIfProblemExist('nervous-system', 'nervous-system-gait-abnormal') ? 'checked' : ''} />
            <label htmlFor='nervous-system-gait-abnormal'>Abnormal</label>
        </div>
    </React.Fragment>
}
export const Reflex = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <label className="heading">Reflex </label>
        <div className="options-details">
            <input type="checkbox" id='nervous-system-reflex-normal' name='nervous-system-reflex-normal' onChange={e => handleChange(e, 'nervous-system')} checked={checkIfProblemExist('nervous-system', 'nervous-system-reflex-normal') ? 'checked' : ''} />
            <label htmlFor='nervous-system-reflex-normal'>Normal</label>
            <input type="checkbox" id='nervous-system-reflex-abnormal' name='nervous-system-reflex-abnormal' onChange={e => handleChange(e, 'nervous-system')} checked={checkIfProblemExist('nervous-system', 'nervous-system-reflex-abnormal') ? 'checked' : ''} />
            <label htmlFor='nervous-system-reflex-abnormal'>Abnormal</label>
        </div>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment, checkIfProblemExist }) => {
    return <React.Fragment>
        <label className="heading-comment">Comment </label>
        <textarea className="form-control" name="comments" placeholder="Type here..." onChange={e => handleChange(e, 'nervous-system')} disabled={checkIfProblemExist('nervous-system', 'nervous-system-normal') ? 'disabled' : ''} value={getCategoryComment('nervous-system') ? getCategoryComment('nervous-system') : ''}></textarea>
    </React.Fragment>
}
export const Power = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <label className="heading">Power </label>
        <div className="options-details">
            <input type="checkbox" id='nervous-system-power-normal' name='nervous-system-power-normal' onChange={e => handleChange(e, 'nervous-system')} checked={checkIfProblemExist('nervous-system', 'nervous-system-power-normal') ? 'checked' : ''} />
            <label htmlFor='nervous-system-power-normal'>Normal</label>
            <input type="checkbox" id='nervous-system-power-abnormal' name='nervous-system-power-abnormal' onChange={e => handleChange(e, 'nervous-system')} checked={checkIfProblemExist('nervous-system', 'nervous-system-power-abnormal') ? 'checked' : ''} />
            <label htmlFor='nervous-system-power-abnormal'>Abnormal</label>
        </div>
    </React.Fragment>
}