import React from 'react';

export const Ears = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <h6>Ears </h6>
        <input type="checkbox" id='ears-normal' name='ears-normal' onChange={e => handleChange(e, 'ears')} checked={checkIfProblemExist('ears', 'ears-normal') ? 'checked' : ''} />
        <label htmlFor='ears-normal'>Normal</label>
    </React.Fragment>
}
export const EarDrum = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <label className="heading">Ear drum </label>
        <div className="options-details">
            {/* normal */}
            <input type="checkbox" id='ears-ear-drum-normal' name='ears-ear-drum-normal' onChange={e => handleChange(e, 'ears')} checked={checkIfProblemExist('ears', 'ears-ear-drum-normal') ? 'checked' : ''} />
            <label htmlFor='ears-ear-drum-normal'>Normal</label>
            {/* dull */}
            <input type="checkbox" id='ears-ear-drum-dull' name='ears-ear-drum-dull' onChange={e => handleChange(e, 'ears')} checked={checkIfProblemExist('ears', 'ears-ear-drum-dull') ? 'checked' : ''} />
            <label htmlFor='ears-ear-drum-dull'>Dull</label>
            {/* erythematous */}
            <input type="checkbox" id='ears-ear-drum-erythematous' name='ears-ear-drum-erythematous' onChange={e => handleChange(e, 'ears')} checked={checkIfProblemExist('ears', 'ears-ear-drum-erythematous') ? 'checked' : ''} />
            <label htmlFor='ears-ear-drum-erythematous'>Erythematous</label>
        </div>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment, checkIfProblemExist }) => {
    return <React.Fragment>
        <label className="heading-comment">Comment </label>
        <textarea className="form-control" name="comments" placeholder="Type here..." disabled={checkIfProblemExist('ears', 'ears-normal') ? 'disabled' : ''} onChange={e => handleChange(e, 'ears')} value={getCategoryComment('ears') ? getCategoryComment('ears') : ''}></textarea>
    </React.Fragment>
}