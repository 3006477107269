import React from 'react';
import _ from "lodash";

class DoctorInfo extends React.Component {
    render() {
        const { doctor_info, doctor_location } = this.props;
        
        return (
            <React.Fragment>
                <div className="content-wrapper">
                    <h6>Doctor Info</h6>
                    <ul className="prescription-patient-details">
                        <li>Doctor Name: <span>{(doctor_info) ? doctor_info.full_name : ''}</span></li>
                        <li>Specialization: <span>{(doctor_info) ?  doctor_info.specialization : ''}</span></li>
                        <li>Registration Number: <span>{(doctor_info) ? doctor_info.license_number : ''}</span></li>
                    </ul>
                    <ul className="prescription-patient-details">
                        <li>Email: <span>{(doctor_info) ? doctor_info.email : ''}</span></li>
                        <li>Contact Number: <span>{(doctor_info) ? doctor_info.contact_number : ''}</span></li>
                    </ul>
                    <ul className="prescription-patient-details">
                        <li>Address: <span>{(doctor_location && doctor_location.address) ? (_.isNull(doctor_location.address.address_line_1) ? '' : doctor_location.address.address_line_1) +' '+ (_.isNull(doctor_location.address.address_line_2) ? '' : doctor_location.address.address_line_2) +' '+ (_.isNull(doctor_location.address.address_line_3) ? '' : doctor_location.address.address_line_3) : ''}</span></li>
                        <li>City: <span>{(doctor_location && doctor_location.address) ? doctor_location.address.city : ''}</span></li>
                        <li>Parish: <span>{(doctor_location && doctor_location.address) ? doctor_location.address.parish : ''}</span></li>
                        <li>Pincode: <span>{(doctor_location && doctor_location.address) ? doctor_location.address.pincode : ''}</span></li>
                    </ul>
                </div>
            </React.Fragment>
        )
    }
}

export default DoctorInfo;