import React from 'react';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from 'react-helmet';
import * as PrescriptionAction from '../../actions/prescriptionAction';
import * as PrescriptionOrderAction from '../../actions/prescriptionOrderAction';
import _ from 'lodash';
import PharmacyMedicineBlock from '../../components/PharmacyMedicineBlock';
import Notification from '../../utils/notification';
import PrescriptionOrderService from '../../services/prescriptionOrderService';
import * as LoaderAction from "../../actions/loaderAction";
import ViewOriginalPresription from '../../components/ViewOriginalPresription';
import PatientInfo from '../../components/PatientInfo';
import DoctorInfo from '../../components/DoctorInfo';
import PrescriptionService from '../../services/prescriptionService';
import PharmacistInfo from '../../components/PharmacistInfo';
import * as HELPER from "../../utils/helpers";
import moment from 'moment';

class EditPrescriptionOrderPage extends React.Component {
    constructor(props) {
        super(props);

        const params = this.props.match.params;
        const prescriptionId = params.prescriptionId;

        this.state = {
            prescription: '',
            pos: '',
            madePayment: true,
            prescriptionId: prescriptionId,
            ref_number: '',
            pos_validation_msg: '',
            reference_validation_msg: '',
            is_pos_valid: false,
            is_ref_num_valid: false,
            reminder_date: ''
        };

        this.props.getPrescriptionOrderPharmacy(prescriptionId);

        this.validator = new SimpleReactValidator();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.removeAcquisition = this.removeAcquisition.bind(this);
        this.sendOrderToTechnician = this.sendOrderToTechnician.bind(this);
        // this.checkPosNum = this.checkPosNum.bind(this);
        // this.checkReferenceNum = this.checkReferenceNum.bind(this);
        this.showOriginalPrescription = this.showOriginalPrescription.bind(this);
        this.hideOriginalPrescription = this.hideOriginalPrescription.bind(this);
    }

    componentDidMount() {
        const {
            match: { params }
        } = this.props;
        const id = params.prescriptionId;
        this.props.pharmacistAcquirePrescription({ prescription_id: id, assigned_to: "pharmacist", assigned_by: "pharmacist", accuired_by: "pharmacist" }, this.props.history);

    }

    handleClick() {
        this.setState({
            madePayment: !this.state.madePayment
        })
    }

    handleChange(e) {
        if (e.target.name === "pos") {
            this.setState({
                pos_validation_msg: '',
            })
        }
        if (e.target.name === "ref_number") {
            this.setState({
                reference_validation_msg: '',
            })
        }
        this.setState({ [e.target.name]: e.target.value });
    }

    handleMedicineChange = (idx, data) => {
        const newMedicines = this.state.prescription.prescription_medicines.map((medicine, sidx) => {
            if (idx !== sidx) return medicine;

            return { ...medicine, ...data };
        });

        const prescription = {
            ...this.state.prescription,
            prescription_medicines: newMedicines
        }
        this.setState({ prescription: prescription });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        if (!_.isEmpty(this.state.pos_validation_msg) || !_.isEmpty(this.state.reference_validation_msg)) {
            return false;
        }

        let checkMedicinesGiven = _.filter(this.state.prescription.prescription_medicines, _.matches({ medicine_given: true }));
        if ((Object.keys(checkMedicinesGiven).length) <= 0) {
            Notification.show('warning', { message: 'Please select medicines for order.' });
            return false;
        }

        let sumUnitGiven = _.sumBy(this.state.prescription.prescription_medicines, 'unit_given');

        if (sumUnitGiven === undefined || sumUnitGiven <= 0) {
            return false;
        }
        this.state.madePayment === false ? this.createOrder() : Notification.show('info', { message: 'Confirm your payment status.' })
    }

    async createOrder() {
        this.props.loaderShow();
        Promise.all([this.checkPosValidity(), this.checkReferenceNumberValidity()]).then(resp => {
            if (resp.every(x => x === "available") === true) {
                this.props.loaderShow();
                const prescriptionOrder = this.state.prescription;
                prescriptionOrder.pos_reference_no = this.state.pos;
                prescriptionOrder.reference_number = this.state.ref_number;
                prescriptionOrder.reminder_date = this.state.reminder_date;
                prescriptionOrder.prescription_extended = this.state.prescription.prescription_extended;
                this.props.verifyPerscriptionOrder(prescriptionOrder, this.props.history);
            }
            this.props.loaderHide();
        }).catch(error => {
            this.props.loaderHide();
            Notification.show('error', error.response.data)
        });
    }

    checkPosValidity = () => {
        return new Promise((resolve, reject) => {
            PrescriptionOrderService.checkPosNumber({ 'pos_number': this.state.pos }).then(res => {
                if (res.data.status === 'not_available') {
                    this.setState({
                        pos_validation_msg: 'POS number already exists',
                        is_pos_valid: false
                    });
                }

                if (res.data.status === 'available') {
                    this.setState({
                        pos_validation_msg: '',
                        is_pos_valid: true
                    });
                }
                return resolve(res.data.status)
            }).catch(error => {
                this.setState({
                    pos_validation_msg: '',
                    is_pos_valid: true
                });
                Notification.show('error', error.response.data)
                return reject(error);
            });
        })
    }

    checkReferenceNumberValidity = () => {
        return new Promise((resolve, reject) => {
            PrescriptionOrderService.checkReferenceNumber({ 'reference_number': this.state.ref_number }).then(res => {
                if (res.data.status === 'not_available') {
                    this.setState({
                        reference_validation_msg: 'reference number already exists',
                        is_ref_num_valid: false
                    });
                }

                if (res.data.status === 'available') {
                    this.setState({
                        reference_validation_msg: '',
                        is_ref_num_valid: true
                    });
                }
                return resolve(res.data.status)
            }).catch(error => {
                this.setState({
                    reference_validation_msg: '',
                    is_ref_num_valid: true
                });
                Notification.show('error', error.response.data)
                return reject(error);
            });
        })
    }

    static getDerivedStateFromProps(props, state) {

        if (_.isEmpty(state.prescription)) {
            let prescription = props.prescription;

            let prescriptionItems = props.prescription.prescription_medicines && props.prescription.prescription_medicines.map((medicineItem) => {
                if (medicineItem.unit_given === undefined || medicineItem.unit_given === null) {
                    medicineItem.is_given = 'no'
                    return medicineItem;
                } else {
                    medicineItem.is_given = 'yes'
                    return medicineItem;
                }
            });

            if (prescription && prescription.prescription_medicines) {
                prescription.prescription_medicines = prescriptionItems;
            }

            return {
                prescription: props.prescription,
                pos: props.prescription.pos_number,
                ref_number: props.prescription.reference_number,
                reminder_date: props.prescription.reminder_date
            };
        }

        return null;
    }

    removeAcquisition = () => {
        let postData = {
            prescription_id: this.state.prescription.prescription_id,
            assign_to: "pharmacy-technician"
        };

        PrescriptionService.assignPrecription(this.state.prescription.prescription_id, postData)
            .then(_resp => {
                this.props.history.push("/prescription/orders/queue");
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    Notification.show('error', error.response.data);
                }
            });
    }

    sendOrderToTechnician = () => {
        const prescriptionOrder = this.state.prescription;

        let postData = {
            prescription_id: prescriptionOrder.prescription_id,
            pos_number: this.state.pos,
            reference_number: this.state.ref_number,
            assigned_to: 'pharmacy-technician',
            order_status: 'draft',
            action: 'submit'
        };

        postData.order_items = [];

        prescriptionOrder.prescription_medicines.forEach((medicine) => {
            let data = {
                prescription_item_id: medicine.id,
                drug_id: medicine.drug_id ? medicine.drug_id : '',
                quantity: parseInt(medicine.quantity),
                drug_sku_id: medicine.drug_sku_id,
                package_size: medicine.package_size,
                unit_given: parseInt(medicine.unit_given),
                unit_dispatched: parseInt(medicine.unit_dispatched),
                drug_coverage: medicine.selected_coverages,
                is_given: medicine.is_given
            }
            postData.order_items = _.concat(postData.order_items, data);

        });

        this.props.assignOrderToTechnician(postData, this.props.history);
    }

    saveDraft = () => {
        // if (!this.validator.allValid()) {
        //     this.validator.showMessages();
        //     this.forceUpdate();
        //     return false;
        // }

        let checkMedicinesGiven = _.filter(this.state.prescription.prescription_medicines, _.matches({ is_given: 'yes' }));

        if ((Object.keys(checkMedicinesGiven).length) <= 0) {
            Notification.show('warning', { message: 'Please select medicines for draft.' });
            return false;
        }

        const prescriptionOrder = this.state.prescription;

        let postData = {
            prescription_id: prescriptionOrder.prescription_id,
            pos_number: this.state.pos,
            reference_number: this.state.ref_number,
            assigned_to: 'pharmacist',
            order_status: 'draft',
            action: 'draft',
            reminder_date: this.state.reminder_date
        };

        postData.order_items = [];

        prescriptionOrder.prescription_medicines.forEach((medicine) => {
            if (medicine.medicine_given) {
                let data = {
                    prescription_item_id: medicine.id,
                    drug_id: medicine.drug_id ? medicine.drug_id : '',
                    quantity: parseInt(medicine.quantity),
                    drug_sku_id: medicine.drug_sku_id,
                    package_size: medicine.package_size,
                    unit_given: parseInt(medicine.unit_given),
                    unit_dispatched: parseInt(medicine.unit_dispatched),
                    drug_coverage: medicine.selected_coverages,
                    is_given: medicine.is_given
                }
                postData.order_items = _.concat(postData.order_items, data);
            }
        });

        this.props.saveDraft(postData, this.props.history);
    }

    showOriginalPrescription = () => {
        this.setState({
            show: true
        });
    }

    hideOriginalPrescription = () => {
        this.setState({
            show: false
        });
    }

    handleCancel = () => {
        this.props.history.push('/prescription/orders/queue');
    }

    render() {
        this.validator.purgeFields();
        const { prescription } = this.state;
        const doctorLocation = this.props.prescription ? this.props.prescription.created_at_location : '';

        return (<React.Fragment>
            <Helmet>
                <title>Prescription Rx</title>
            </Helmet>
            <div className="row">
                <div className="col-md-24">
                    <h4 className="page-title">Prescription Rx</h4>

                    <div className="row">
                        <div className="col-md-24">
                            <PatientInfo patient_info={prescription.patient_info} />
                        </div >
                        <div className="col-md-24">
                            {prescription && prescription.prescription_type === 'doctor-prescription' && <DoctorInfo doctor_info={prescription.user_profile_info} doctor_location={doctorLocation} />}
                            {prescription && prescription.prescription_type === 'pharmacy-prescription' && <PharmacistInfo pharmacist_info={prescription.user_profile_info} pharmacy_info={prescription.service_provider_info} prescription_date={HELPER.getDateFormat(prescription && prescription.prescription_date)} />}
                        </div >
                    </div>
                    <h4 className="page-title font-size-16">Prescribed Medication <a onClick={this.showOriginalPrescription} href className="view-orignal-prescription">View Original Prescription</a></h4>
                    <ViewOriginalPresription
                        show={this.state.show}
                        prescriptionId={this.state.prescriptionId}
                        handleShow={this.showOriginalPrescription}
                        handleClose={this.hideOriginalPrescription}
                    />

                    <form>
                        <div className="content-wrapper">
                            <ul className="medicine-list">
                                {!_.isEmpty(prescription.prescription_medicines) && prescription.prescription_medicines.map((medicine, idx) => (
                                    <PharmacyMedicineBlock
                                        medicine={medicine}
                                        idx={idx}
                                        handleChangeMedicine={this.handleMedicineChange}
                                        validator={this.validator}
                                        patientInsurance={prescription.patient_insurance}
                                        role={this.props.role}
                                        page={'submit-order'}
                                        prescription_extended_status = {prescription && prescription.prescription_extended && prescription.prescription_extended.status}
                                    />
                                ))}
                            </ul>
                        </div>

                        <div className="content-wrapper">
                            <ul className="payment-confirmed">
                                <li>Payment Confirmed</li>
                                <li><input type="checkbox" id="payment" name="payment_done" onChange={this.handleClick} value="" />
                                    <label htmlFor="payment"></label>
                                </li>
                                <li>
                                    <div className="prescription-confirm">
                                        <label>POS:</label>
                                        <div className="input-wrapper">
                                            <input type="text" name="pos" value={this.state.pos} onChange={this.handleChange} className="form-control" autoComplete="off" autoCorrect="off" />
                                            {this.validator.message('pos', this.state.pos, 'required|alpha_num')}
                                            {this.state.pos_validation_msg && <lable className="text-danger">{this.state.pos_validation_msg}</lable>}
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="prescription-confirm">
                                        <label>Reference Number:</label>
                                        <div className="input-wrapper">
                                            <input type="text" name="ref_number" value={this.state.ref_number} onChange={this.handleChange} className="form-control" />
                                            {this.validator.message('reference number', this.state.ref_number, 'required|alpha_num')}
                                            {this.state.reference_validation_msg && <lable className="text-danger">{this.state.reference_validation_msg}</lable>}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        {this.props.role.role_slug === "pharmacist" && <div className="content-wrapper">
                            <ul className="payment-confirmed">
                                <li>Set Reminder</li>
                                <form className="form-inline d-inline">
                                    <input type="date" className="form-control i-cale" name="reminder_date" value={this.state.reminder_date} onChange={this.handleChange} min={moment().add(1, 'day').format('YYYY-MM-DD')} />
                                </form>
                            </ul>
                        </div>}

                        <div className="button-wrapper align-right">
                            <button type="submit" onClick={this.handleSubmit} className="btn btn-primary">create rx</button>
                            <button type="button" className="btn btn-outline-primary" onClick={this.sendOrderToTechnician}>Send order to technician</button>
                            <button type="button" onClick={this.saveDraft} className="btn btn-primary">Save as draft</button>
                            <button type="button" className="btn btn-outline-primary" onClick={this.handleCancel}>Cancel</button>
                        </div>
                    </form>
                </div>
            </div>

        </React.Fragment>);
    }
}

const mapStateToProps = (state) => ({
    patient: state.patient,
    prescription: state.prescription.fetch,
    role: state.selected_user_profile
});

const mapActionsToProps = ({
    verifyPerscriptionOrder: PrescriptionOrderAction.verify,
    getPrescriptionOrderPharmacy: PrescriptionAction.getPrescriptionOrderPharmacy,
    resetPerscriptionOrder: PrescriptionOrderAction.reset,
    assignOrderToTechnician: PrescriptionOrderAction.assignOrderToTechnician,
    pharmacistAcquirePrescription: PrescriptionAction.PharmacistAcquirePrescription,
    loaderShow: LoaderAction.loaderShow,
    loaderHide: LoaderAction.loaderHide,
    saveDraft: PrescriptionOrderAction.saveDraft
})

export default connect(mapStateToProps, mapActionsToProps)(EditPrescriptionOrderPage)
