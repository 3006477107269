import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import rootReducers from './reducers/rootReducer';
import * as authUtils from './utils/auth';

const composeEnhancers = (typeof window !== 'undefined' &&window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const resetEnhancer = rootReducer => (state, action) => {
    if (action.type === 'RESET_ALL') {
        authUtils.removeUserProfile();
        
        const newState = rootReducer(undefined, {});
        newState.selected_user_profile = {};
        newState.session = state.session;
        newState.router = state.router;
        newState.loader = state.loader;
        return newState;
    }

    return rootReducer(state, action);
};

export const history = createBrowserHistory();

export default function configureStore(initialState = {}) {
    const store = createStore(
        resetEnhancer(rootReducers(history)),
        initialState,
        composeEnhancers(applyMiddleware(routerMiddleware(history), thunkMiddleware))
    );

    return store;
}