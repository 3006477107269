import axios from 'axios';
import { apiRoute, getApiHeader, createPaginateQueryString } from '../utils/helpers';

class MedicalCertificateService {
    static create({data}) {
        const requestOptions = {
            headers: getApiHeader(),
        };
        
        return axios.post(apiRoute("/v1/medical-certificates"), data, requestOptions);
    }

    static downloadAndPrint(certificate_id) {
        const requestOptions = {
            headers: getApiHeader(),
        };
        
        return axios.get(apiRoute("/v1/medical-certificates/"+certificate_id+"/download"), requestOptions);
    }

    static list({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute("/v1/medical-certificates"), requestOptions);
    }
}
export default MedicalCertificateService;