import React, { Component } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import * as helpers from "../utils/helpers";

class ReCAPTCHABlock extends Component {

    constructor(props) {
        super(props);

        this.state = {
            hasSiteKey: helpers.config('RECAPTCHA_SITE_KEY') ? true : false,
        }

        this.recaptchaRef = React.createRef();
        this.onReCaptchaChange = this.onReCaptchaChange.bind(this);
    }

    onReCaptchaChange(_value) {
        this.recaptchaRef.reset();
        this.props.onSubmit();
    }

    execute() {
        if (this.state.hasSiteKey) {
            this.recaptchaRef.execute();
        } else {
            this.props.onSubmit();
        }
    }

    render() {
        return (
            this.state.hasSiteKey && <ReCAPTCHA
                ref={e => this.recaptchaRef = e}
                size="invisible"
                sitekey={this.state.hasSiteKey ? helpers.config('RECAPTCHA_SITE_KEY') : "string"}
                onChange={this.onReCaptchaChange}
            />
        )
    }
}


export default ReCAPTCHABlock;