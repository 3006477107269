import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from "react-redux";
import { Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import DataGrid from "../../components/DataGrid";
import * as MedicalCertificateAction from "../../actions/medicalCertificateAction";
import _ from 'lodash';
import { getDateFormat } from "../../utils/helpers";
import queryString from 'query-string';
import MedicalCertificateService from "../../services/medicalCertificateService";

const certificates = [{
        title: 'Return to School',
        route: 'return_to_school'
    }, {
        title: 'Return to Work',
        route: 'return_to_work'
    }, {
        title: 'Work Excuse',
        route: 'work_excuse'
    }, {
        title: 'Follow Up',
        route: 'follow_up'
    }, {
        title: 'Referral Letter',
        route: 'referral_letter'
    }
]

const AddCertificateGrid = ({ handleCreateClick }) => {
    const columns = () => {
        return [
            {
                dataField: "title",
                text: "Certificate Name",
                sort: false,
            },
            {
                dataField: "actions",
                text: "Action",
                headerClasses: 'text-center',
                classes: 'text-center',
                isDummyField: true,
                formatter: (_cell, row) => {
                    return <button onClick={handleCreateClick.bind(this, row.route)} className="btn btn-outline-primary" >Create</button>
                }
            }
        ]
    }

    return (
        <DataGrid
            pagination={false}
            columns={columns()}
            data={certificates}
        />
    )
}


class ListCertificatePage extends React.Component {
    constructor(props) {
        super(props);

        const params = this.props.match.params;
        const queryParams = queryString.parse(this.props.location.search)

        this.state = {
            patient_id: params.patientId,
            filter:{
                patient_id: params.patientId,
                status:"completed"
            },
            issue: _.has(queryParams, 'issue') ? true : false,
        }

        this.props.getPatientCertificate({sortBy: { sortField: "updated_at", sortOrder: "desc" },filter:this.state.filter});
    }

    columns = () => {
		return [
			{
				dataField: "medical_certificate_type",
                text: "Certificate Name",
                sort: true,
                formatter: (_cell, row) => {
                    return _.startCase(row.medical_certificate_type);
                }
            },
            {
				dataField: "updated_at",
				text: "Issued Date",
                sort: true,
                formatter: (_cell, row) => {
                    return getDateFormat(row.updated_at);
                }
			},
			{
				dataField: "actions",
                text: "Download",
                headerClasses: 'text-center',
                classes: 'text-center',
				isDummyField: true,
                formatter: (_cell, row) => {
                    return <Link className="" onClick={this.linkBtnClick.bind(this, row)} > </Link>
                }
			}
		];
    };
    
    linkBtnClick(data){
        MedicalCertificateService.downloadAndPrint(data.medical_certificate_id).then(resp => {
			this.setState({ isloading: false });
			window.open(resp.data.link);
		}).catch(error => {
			this.setState({ isloading: false });
			Notification.show('error', error.response.data);
		});
    }

    handleTableChange = ( type, { page, sizePerPage, sortField, sortOrder, searchText } ) => {
		if (type === "search") {
			page = 1;
        }
        
        const filter = this.state.filter;
        this.props.getPatientCertificate({ page, sizePerPage, sortBy: { sortField, sortOrder }, searchText, filter });
	};
    
    handleAddCeritifcate = (action) => {
        this.props.history.push('/patients/' + this.state.patient_id +'/medical-certificate/' + action);
    };

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Certificate</title>
                </Helmet>
                <section className="container-fluid">
					<div className="row">
						<div className="col-md-24 mt-3 requester-tabs">
                            <Tabs defaultActiveKey={this.state.issue ? 2 : 1} onSelect={k => k}>
                                <Tab eventKey={1} title="Add Certificate">
                                    <div className="content-wrapper">
                                        <AddCertificateGrid  
                                            handleCreateClick={this.handleAddCeritifcate}
                                        />
                                    </div>
                                </Tab>
                                <Tab eventKey={2} title="Issued Certificate">
                                    <div className="content-wrapper issued-certi">
                                    { !_.isEmpty(this.props.medical_certificates) && <DataGrid
                                        columns={this.columns()}
                                        data={this.props.medical_certificates.data}
                                        meta={this.props.medical_certificates.meta}
                                        handleTableChange={this.handleTableChange}
                                            noDataIndication="Certificate Not Issued"
                                    />}
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        medical_certificates: state.medical_certificate,
    };
}

const mapActionsToProps = {
	getPatientCertificate: MedicalCertificateAction.list,
};

export default connect(mapStateToProps, mapActionsToProps)(ListCertificatePage);