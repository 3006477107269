import React from "react";
import ProfileService from '../services/profileService';
import { isEmpty } from 'lodash';
import * as SelectedProfileAction from "../actions/selectedProfileAction";
import * as TogglePopupAction from '../actions/togglePopupAction';
import { connect } from 'react-redux';
import Notification from '../utils/notification';
import ProfileItem from '../components/SwitchProfile/ProfileItem';
import Loader from '../components/SwitchProfile/Loader';
import * as authUtils from '../utils/auth';
import TwoFAPinModal from '../components/TwoFAPinModal';

class SelectProfile extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            isLoading: false,
            data: '',
            user: '',
            selected_user: null,
            twofa: false
        }
    }

    componentDidMount() {
        if (!isEmpty(this.props.selected_user)) {
            let selected_user = this.props.selected_user;
            this.setState({
                user: selected_user,
                selected_user: selected_user,
            });
        }
        this.setState({ isLoading: true })

        ProfileService.listOfProfiles().then(resp => {
            const { data } = resp.data
            this.setState({
                isLoading: false,
                data: data
            })
        }).catch(error => {
            Notification.show('error', error.response.data);
            this.setState({ isLoading: false })
        })
    }

    changeHandler = (user) => {
        this.setState({
            user: user,
        })
    }

    switchHandler = (e) => {
        e.preventDefault();
        const { user } = this.state
        const postData = {
            'user_profile_id': user.user_profile_id,
            'service_provider_id': user.service_provider_id,
            'user_location_id': user.user_location_id
        }
        ProfileService.userProfileToken(postData)
            .then(_resp => {
                authUtils.setTwoFa(_resp.data.twofa);
                this.setState({
                    twofa: _resp.data.twofa
                })
                if (!_resp.data.twofa) {
                    this.props.selectedProfile(this.state.user, this.props.history);
                    this.props.closePopup('select_profile');
                }
            }).catch(err => {
                Notification.show('error', err.response.data)
            });
    }

    onPinVerify = () => {
        this.props.selectedProfile(this.state.user, this.props.history);
        this.props.closePopup('select_profile');
    }

    closePopup = () => {
        this.props.closePopup('select_profile');
    }

    handleCloseModal = () => {
        this.setState({ twofa: false })
    }

    render() {
        return <React.Fragment>
            <div id="select-user">
                <div className="modal-box align-center">
                    <div className="user-popup-icon">
                        <img src="/images/deactivate-user.png" alt="deactivate user" />
                        <h4>Select Profile</h4>
                        <ul className="location-list">
                            {this.state.isLoading && <Loader />}
                            {this.state.data && this.state.data.map((user, index) => (
                                <ProfileItem
                                    index={index}
                                    user={user}
                                    key={index}
                                    selectedUser={this.state.user}
                                    onChange={this.changeHandler}
                                />
                            ))}
                        </ul>
                    </div>
                    <button className="btn btn-primary" onClick={this.switchHandler}>Switch</button>
                    <button className="btn btn-secondary js-modal-close" onClick={this.closePopup}>Cancel</button>
                </div>
            </div>
            <TwoFAPinModal show={this.state.twofa} onPinVerify={this.onPinVerify} onCloseModal={this.handleCloseModal} />
        </React.Fragment>

    }
}

const mapStateToProps = (state) => ({
    selected_user: state.selected_user_profile,
});

const mapActionsToProps = ({
    selectedProfile: SelectedProfileAction.selectedProfile,
    closePopup: TogglePopupAction.closePopup
});

export default connect(mapStateToProps, mapActionsToProps)(SelectProfile);
