import * as types from './actionTypes';
import PriceListService from '../services/priceListService';
import Notification from '../utils/notification';

export function drugSkuList(page, sizePerPage, sortBy, searchText) {
    return dispatch => {
        dispatch ({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.PRICE_LIST_REQUEST, request: { page, sizePerPage, sortBy, searchText}});

        PriceListService.drugSkuList(page, sizePerPage, sortBy, searchText)
        .then(resp => {
            let data = resp.data;
            dispatch ({ type: 'HIDE_PAGE_LOADER' });
            dispatch({ type: types.PRICE_LIST_SUCCESS, ...data });
        })
        .catch(error => {
            if (error.response && error.response.status) {
                dispatch({ type: 'HIDE_PAGE_LOADER '});
                Notification.show('error', error.response.data);
            }
        });
    }
}

export function PathologyTestList(page, sizePerPage, sortBy, searchText) {
    return dispatch => {
        dispatch ({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.PRICE_LIST_REQUEST, request: { page, sizePerPage, sortBy, searchText}});

        PriceListService.PathologyTestList(page, sizePerPage, sortBy, searchText)
        .then(resp => {
            let data = resp.data;
            dispatch ({ type: 'HIDE_PAGE_LOADER' });
            dispatch({ type: types.PRICE_LIST_SUCCESS, ...data });
        })
        .catch(error => {
            if (error.response && error.response.status) {
                dispatch({ type: 'HIDE_PAGE_LOADER '});
                Notification.show('error', error.response.data);
            }
        });
    }
}

export function RadiologyTestList(page, sizePerPage, sortBy, searchText) {
    return dispatch => {
        dispatch ({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.PRICE_LIST_REQUEST, request: { page, sizePerPage, sortBy, searchText}});

        PriceListService.RadiologyTestList(page, sizePerPage, sortBy, searchText)
        .then(resp => {
            let data = resp.data;
            dispatch ({ type: 'HIDE_PAGE_LOADER' });
            dispatch({ type: types.PRICE_LIST_SUCCESS, ...data });
        })
        .catch(error => {
            if (error.response && error.response.status) {
                dispatch({ type: 'HIDE_PAGE_LOADER '});
                Notification.show('error', error.response.data);
            }
        });
    }
}