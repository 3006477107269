import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const FreeSelectModal = ({ showModal, handleModal }) => {
    return (
        <Modal show={showModal} backdrop="static" keyboard={false} onHide={(e) => handleModal(e)}>
            <Modal.Header closeButton>
                <Modal.Title>You are changing tab</Modal.Title>
            </Modal.Header>
            <Modal.Body>Save Changes</Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={(e) => handleModal(e, 'save')}>
                    Save and Continue
                </Button>
                <Button variant="primary" onClick={(e) => handleModal(e, 'cancel')}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default FreeSelectModal