import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class AmbulanceService {
    static getPatientInfo(patientId, query) {
        const requestOptions = {
            headers: getApiHeader(),
            params: query
        };

        return axios.get(apiRoute('/v1/ambulances/patients/' + patientId), requestOptions);
    }

    static getPatientPrescription(patientId) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute('/v1/ambulances/patients/' + patientId + '/prescriptions'), requestOptions);
    }

    static createRecord(data) {
        const requestOptions = {
            headers: getApiHeader()
        };

        const body = JSON.stringify(data)

        return axios.post(apiRoute('/v1/ambulances/records'), body, requestOptions);
    }

    static paginateRecords({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute('/v1/ambulances/records'), requestOptions);
    }

    static getRecord(recordId) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute('/v1/ambulances/records/' + recordId), requestOptions);
    }

    static updateRecord(recordId, data) {
        const requestOptions = {
            headers: getApiHeader()
        };
        const body = JSON.stringify(data)

        return axios.put(apiRoute('/v1/ambulances/records/' + recordId), body, requestOptions);
    }

    static searchHosiptal(searchText = '') {
        const requestOptions = {
            headers: getApiHeader(),
            params: {
                search: searchText
            }
        };

        return axios.get(apiRoute('/v1/ambulances/search/hospitals'), requestOptions);
    }
}

export default AmbulanceService;