import * as types from '../actions/actionTypes';
import * as authUtils from '../utils/auth';

const initialState = {
    checked: true,
    authenticated: authUtils.checkSessionToken()
}

function auth(state = initialState, { type, payload })
{
    switch (type) {
        case types.LOGIN_REQUEST:
            return Object.assign({}, state, {
                authenticated: false,
            })
        case types.LOGIN_SUCCESS:
            return Object.assign({}, state, {
                authenticated: true,
            })
        case types.LOGIN_FAILURE:
            return Object.assign({}, state, {
                authenticated: false,
            })
        case types.LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                authenticated: false,
            })
        default:
            return state;
    }
}

export default auth;