import * as types from './actionTypes';
import Notification from '../utils/notification';
import PathoRadioService from '../services/pathoRadioService';

export function reset() {
    return dispatch => {
        dispatch({ type: types.LIST_RESET });
    }
}

export function list({ page, sizePerPage, sortBy }, patientId) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.PATIENT_PATHOLOGY_TEST_LIST_REQUEST, request: { page, sizePerPage, sortBy }, patientId });

        PathoRadioService.patientPathologyList({ page, sizePerPage, sortBy }, patientId)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: types.PATIENT_PATHOLOGY_TEST_LIST_SUCCESS, ...data });
            })
            .catch(error => {
                dispatch({ type: types.PATIENT_PATHOLOGY_TEST_LIST_FAILED, ...error.response });
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    let errorData = error.response.data;
                    Notification.show('error', errorData)
                }
            });
    }
}

export function patientRadiolgyTestList({ page, sizePerPage, sortBy }, patientId) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.PATIENT_RADIOLOGY_TEST_LIST_REQUEST, request: { page, sizePerPage, sortBy }, patientId });


        PathoRadioService.patientRadiolgyTestList({ page, sizePerPage, sortBy }, patientId)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: types.PATIENT_RADIOLOGY_TEST_LIST_SUCCESS, ...data });
            })
            .catch(error => {
                dispatch({ type: types.PATIENT_RADIOLOGY_TEST_LIST_FAILED, ...error.response });
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    let errorData = error.response.data;
                    Notification.show('error', errorData)
                }
            });
    }
}


export function listPathologyOrder({ page, sizePerPage, sortBy, filter }) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.LIST_PATHOLOGY_ORDER_REQUEST, request: { page, sizePerPage, sortBy, filter } });

        PathoRadioService.listPathologyOrder({ page, sizePerPage, sortBy, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: types.LIST_PATHOLOGY_ORDER_SUCCESS, ...data });
            })
            .catch(error => {
                dispatch({ type: types.LIST_PATHOLOGY_ORDER_FAILED, ...error.response });
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    let errorData = error.response.data;
                    Notification.show('error', errorData)
                }
            });
    }
}

export function listRadiologyOrder({ page, sizePerPage, sortBy, filter }) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.LIST_RADIOLOGY_ORDER_REQUEST, request: { page, sizePerPage, sortBy, filter } });

        PathoRadioService.listRadiologyOrder({ page, sizePerPage, sortBy, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: types.LIST_RADIOLOGY_ORDER_SUCCESS, ...data });
            })
            .catch(error => {
                dispatch({ type: types.LIST_RADIOLOGY_ORDER_FAILED, ...error.response });
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    let errorData = error.response.data;
                    Notification.show('error', errorData)
                }
            });
    }
}

export function documentDownload(documentId)
{
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });

        PathoRadioService.getDocumentDownloadUrl(documentId)
        .then(resp => {
            let data = resp.data;
            window.open(data.download_url);

            dispatch({ type: 'HIDE_PAGE_LOADER' });
        })
        .catch (error => {
            if (error.response && error.response.status) {
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                let errorData = error.response.data;
                Notification.show('error', errorData)
            }
        });
    }
}