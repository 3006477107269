import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class ProcedureService {
    static list({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute("/v1/procedures"), requestOptions);
    }

    static create(data) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.post(apiRoute('/v1/procedures'), JSON.stringify(data), requestOptions);
    }

    static get(id) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.get(apiRoute('/v1/procedures/' + id), requestOptions);
    }

    static update(id, data) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.put(apiRoute('/v1/procedures/' + id), JSON.stringify(data), requestOptions);
    }

    static delete(id) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.delete(apiRoute('/v1/procedures/' + id), requestOptions);
    }

    static listAll() {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.get(apiRoute('/v1/procedures-list'), requestOptions);
    }
}


export default ProcedureService;
