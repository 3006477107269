import React from 'react';

export const Ears = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <div className="heading">
            <h6>Ears</h6>
        </div>
        <div className="form-group full">
            <div className="options-details">
                <input type="checkbox" id='ringing-in-ears' name='ringing-in-ears' onChange={e => handleChange(e, 'ears')} checked={checkIfProblemExist('ears', 'ringing-in-ears') ? 'checked' : ''} />
                <label htmlFor='ringing-in-ears'>Ringing in ears</label>
                <input type="checkbox" id='loss-of-hearing' name='loss-of-hearing' onChange={e => handleChange(e, 'ears')} checked={checkIfProblemExist('ears', 'loss-of-hearing') ? 'checked' : ''} />
                <label htmlFor='loss-of-hearing'>Loss of hearing</label>
            </div>
        </div>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment }) => {
    return <React.Fragment>
        <label className="heading-comment">Comment </label>
        <textarea className="form-control" name="comments" placeholder="Type here..." onChange={e => handleChange(e, 'ears')} value={getCategoryComment('ears') ? getCategoryComment('ears') : ''}></textarea>
    </React.Fragment>
}