import axios from 'axios';
import { apiRoute, getApiHeader } from '../utils/helpers';

class OfflinePrescriptionService {
    static getByTemporaryId(temp_id) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute("/v1/temporary-ids/" + temp_id), requestOptions);
    }

    static createOfflinePrescription(postData) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.post(apiRoute("/v1/offline/prescriptions"), postData, requestOptions);
    }

    static getofflinePresciptionById(id) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute("/v1/offline/prescriptions/" + id), requestOptions);
    }

    static getDrugSkus(id) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute("/v1/drugs/" + id + "/drug-skus"), requestOptions);
    }

    static checkPosNumber(data) {
        const requestOptions = {
            headers: getApiHeader(null, true)
        };

        return axios.post(apiRoute('/v1/offline-prescriptions/check/posnumber'), data, requestOptions);
    }

    static checkReferenceNumber(data) {
        const requestOptions = {
            headers: getApiHeader(null, true)
        };

        return axios.post(apiRoute('/v1/offline-prescriptions/check/referencenumber'), data, requestOptions);
    }

    static createPharmacyOrder(data) {
        const requestOptions = {
            headers: getApiHeader(null, true)
        };

        const postData = JSON.stringify(data)

        return axios.post(apiRoute('/v1/offline/prescriptions/orders'), postData, requestOptions);
    }
}

export default OfflinePrescriptionService;