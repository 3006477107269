import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class UrgentCareService {
    static list({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute("/v1/urgent-care-prescriptions/draft"), requestOptions);
    }

    static listApproval({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute("/v1/urgent-care-prescriptions/approval"), requestOptions);
    }

    static createDraft(data) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.post(apiRoute('/v1/doctors/urgent-care-prescriptions'), JSON.stringify(data), requestOptions);
    }

    static getVital(prescriptionId) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.get(apiRoute('/v1/doctors/urgent-care-prescriptions/' + prescriptionId + '/vital'), requestOptions);
    }

    static createVital(prescriptionId, data) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.post(apiRoute('/v1/doctors/urgent-care-prescriptions/' + prescriptionId + '/vital'), JSON.stringify(data), requestOptions);
    }

    static getPatientPresentCondition(prescriptionId) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.get(apiRoute('/v1/doctors/urgent-care-prescriptions/' + prescriptionId + '/present-condition'), requestOptions);
    }

    static createPatientPresentCondition(prescriptionId, data) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.post(apiRoute('/v1/doctors/urgent-care-prescriptions/' + prescriptionId + '/present-condition'), JSON.stringify(data), requestOptions);
    }

    //start prescription & referral
    static getPrescriptionRecommendation(prescriptionId) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.get(apiRoute('/v1/doctors/urgent-care-prescriptions/' + prescriptionId + '/recommendation'), requestOptions);
    }

    static createMedication(prescriptionId, data) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.post(apiRoute('/v1/doctors/urgent-care-prescriptions/' + prescriptionId + '/recommendation/medication'), JSON.stringify(data), requestOptions);
    }
    static createLabTest(prescriptionId, data) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.post(apiRoute('/v1/doctors/urgent-care-prescriptions/' + prescriptionId + '/recommendation/lab-test'), JSON.stringify(data), requestOptions);
    }
    static createRadiologyTest(prescriptionId, data) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.post(apiRoute('/v1/doctors/urgent-care-prescriptions/' + prescriptionId + '/recommendation/radiology-test'), JSON.stringify(data), requestOptions);
    }
    static createRemarkReferral(prescriptionId, data) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.post(apiRoute('/v1/doctors/urgent-care-prescriptions/' + prescriptionId + '/recommendation/remark'), JSON.stringify(data), requestOptions);
    }

    static prescriptionSubmit(prescriptionId) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.post(apiRoute('/v1/doctors/urgent-care-prescriptions/' + prescriptionId + '/submit'), {}, requestOptions);
    }

    static addMadicineAdministerNote(prescriptionId, medicineItemId, data) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.post(apiRoute('/v1/doctors/urgent-care-prescriptions/' + prescriptionId + '/recommendation/medication-item/' + medicineItemId + '/administer-note'), JSON.stringify(data), requestOptions);
    }
}


export default UrgentCareService;
