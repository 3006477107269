import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import PatientInfoSection from '../../components/AmbulanceRecord/PatientInfoSection'
import MedicalInfoSection from '../../components/AmbulanceRecord/MedicalInfoSection'
import CurrentMedicationSection from "../../components/AmbulanceRecord/CurrentMedicationSection"
import * as AmbulanceAction from '../../actions/ambulanceAction'
import { patientStatus } from '../../utils/helpers';

export class ViewRecordPage extends Component {
    constructor(props) {
        super(props);

        const params = this.props.match.params;
        this.props.getRecord(params.recordId, { log: true });
    }

    render() {
        let { data } = this.props.ambulance;
        
        return (
            <React.Fragment>
                <Helmet>
                    <title>Ambulance Patient Profile</title>
                </Helmet>
                <section className="container-fluid">
                    <div className="row">
                        <div className="col-md-24">
                            <h4 className="page-title"><span>Patient Profile</span></h4>
                        </div>
                    </div>
                    <PatientInfoSection patient={data.patient_info}  />
                    <MedicalInfoSection  patient={data.patient_info} />
                    <div className="content-wrapper padding-bottom-10">
                        <h6>Patient Status</h6>
                        <p>{patientStatus(data.patient_status)}</p>
                    </div>
                    <CurrentMedicationSection prescriptions={data.current_prescriptions} />
                    <div className="content-wrapper padding-bottom-10">
                        <h6>Current Condition of Patient</h6>
                        <p>{data.patient_condition}</p>
                    </div>
                    <div className="row">
                        <div className="col-24">
                            <div className="content-wrapper pb-4">
                                <h4 className="wrapper-heading">Ambulance Treatment (Medicine Details)</h4>
                                {data.medicines && data.medicines.map((medicine)=> {
                                    return <React.Fragment>
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr className="font-size-12">
                                                        <th>Brand Name</th>
                                                        <th>Generic Name</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="color-grey font-size-12">
                                                        <td>{medicine.drug_name}</td>
                                                        <td>{medicine.drug_generic_name}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        {<div className="row">
                                            <div className="col-3">
                                                <span className="font-size-12 color-blue">Instructions:</span>
                                            </div>
                                            <div className="col-19">
                                                <p className="color-grey font-size-12">{medicine.remarks}</p>
                                            </div>
                                        </div>}
                                    </React.Fragment>
                                })}
                            </div>
                            <div className="content-wrapper padding-bottom-10">
                                <h6>Note</h6>
                                <p>{data.notes}</p>
                            </div>
                            <div className="content-wrapper padding-bottom-10">
                                <h6>Hospital Detail</h6>
                                <p>{data.hospital_service_provider_name +" - "+ data.hospital_user_location_name}</p>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        ambulance: state.ambulance_fetch,
    };
}

const mapDispatchToProps = {
    getRecord: AmbulanceAction.get,
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewRecordPage)
