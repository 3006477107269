import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Tab, Tabs } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import Notification from '../../utils/notification';
import Profile from '../../components/Patient/Profile';
import AirlineService from '../../services/airlineService';
import * as LoaderAction from '../../actions/loaderAction';
import * as PathoRadioAction from '../../actions/PathoRadioAction';
import * as Helper from '../../utils/helpers';
import { capitalize } from 'lodash';
import moment from 'moment'

export class ViewRecordPage extends Component {
    constructor(props) {
        super(props);

        const params = this.props.match.params;

        this.state = {
            patientId: params.patientId,
            patient: {},
            documents: [],
        }
    }

    downloadFileActionFormatter = (cell, row) => {
        return (
            <a href onClick={this.props.documentDownload.bind(this, row.document_id)} className="icon file"> View </a>
        );
    }

    columns = () => {
        return [
            {
                dataField: "test_category",
                text: "Test Category",
            },
            {
                dataField: "test_name",
                text: "Test Name",
            },
            {
                dataField: "reported_at",
                text: "Test Date",
                formatter: (cell) => Helper.getDateFormat(cell)
            },
            {
                dataField: "test_result",
                text: "Test Result",
                formatter: cell => capitalize(cell)
            },
            {
                dataField: "indicator",
                text: "Indicator",
                isDummyField: true,
                formatter: this.showIndicator
            },
            {
                dataField: "action",
                text: "Action",
                isDummyField: true,
                formatter: this.downloadFileActionFormatter
            }
        ];
    }

    showIndicator = (cell, row) => {
        var a = moment();
        var b = moment(row.reported_at);
        console.log(a.diff(b, 'hours', true), a.diff(b, 'hours'))
        if (a.diff(b, 'hours', true) <= 72) {
            return <button type="button" class="btn-success-circle"><i></i></button>
        }
        return <button type="button" class="btn-danger-circle"><i></i></button>
    }

    componentDidMount() {
        this.props.showLoader();
        Promise.all([
            AirlineService.getPatient(this.state.patientId, { log: true }).then(function (response) { return response.data }),
            AirlineService.getPatientCovidDocuments(this.state.patientId).then(function (response) { return response.data }),
        ]).then(([patient, documentPaginate]) => {
            this.setState({
                patient: patient,
                documents: documentPaginate.data,
            })
            this.props.hideLoader();
        })
            .catch(error => {
                this.props.hideLoader();
                Notification.show('error', error.response.data)
                this.props.history.push('/authority-access/patients/search');
            });
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Patient Profile</title>
                </Helmet>
                <section className="container-fluid">
                    <div className="row">
                        <div className="col-md-24">
                            <h4 className="page-title"><span>Patient Profile</span></h4>
                        </div>
                    </div>
                    <div className="row">
                        <Profile patient={this.state.patient} showInsurance={false} />
                    </div>
                    <div className="row">
                        <div className="col-md-24 mt-3 requester-tabs">
                            <Tabs defaultActiveKey="lab-reports">
                                <Tab eventKey="lab-reports" title="Lab Reports">
                                    <div className="content-wrapper">
                                        <BootstrapTable
                                            bootstrap4
                                            bordered={false}
                                            classes="table-responsive common-table"
                                            keyField="id"
                                            columns={this.columns()}
                                            data={this.state.documents}
                                            noDataIndication="No Record Exist"
                                        />
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
    hideLoader: LoaderAction.loaderHide,
    showLoader: LoaderAction.loaderShow,
    documentDownload: PathoRadioAction.documentDownload,
}

export default connect(null, mapDispatchToProps)(ViewRecordPage)
