import axios from 'axios';
import { apiRoute, getApiHeader } from '../utils/helpers';
import * as encryptUtils from '../utils/encrypt';

class ProfileService {
    static updateProfile(postData) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.post(apiRoute('/v1/profile'), postData, requestOptions);
    }

    static userProfileToken(postData) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        const body = JSON.stringify(postData);

        return axios.post(apiRoute('/v1/profile/select'), body, requestOptions);
    }

    static getActiveProfile() {
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.get(apiRoute('/v1/profile/active'), requestOptions);
    }

    static listOfProfiles() {
        const requestOptions = {
            headers: getApiHeader(null, true)
        };

        return axios.get(apiRoute('/v1/profile'), requestOptions);
    }

    static verifyProfilePin(code) {
        const requestOptions = {
            headers: getApiHeader(null, true)
        };
        const body = JSON.stringify({
            code: encryptUtils.encryptServer(code)
        });

        return axios.post(apiRoute('/v1/twofa/verify'), body, requestOptions)
    }

    static resendProfilePin() {
        const requestOptions = {
            headers: getApiHeader(null, true)
        };

        return axios.post(apiRoute('/v1/twofa/resend'), null, requestOptions)
    }

    static uploadUserDocuments(file, type){
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute(`/v1/users/documents/${type}`), file, requestOptions);
    }
}

export default ProfileService;