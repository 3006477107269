import React from 'react'
import { Modal, Button } from 'react-bootstrap';
import SimpleReactValidator from 'simple-react-validator';
import { getDateFormat } from '../../utils/helpers';

class SendReportModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: ""
        }
        this.validator = new SimpleReactValidator();
    }

    componentDidMount() {
    }

    onSubmit = (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        } else {
            this.props.sendReport(this.props.selectedPatient.id, this.state.email);
        }
    }

    onChange = (evt) => {
        this.setState({ [evt.target.name]: evt.target.value })
    }
    render() {
        const { selectedPatient: patient } = this.props;
        return <Modal show={this.props.show} backdrop="static" keyboard={false} onHide={this.props.onHide} centered size='md'>
            <Modal.Header closeButton>
                <Modal.Title>Send Report</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <div className="form-group row">
                        <label className="col-sm-8 font-weight-bold">Patient Name</label>
                        <label className='col-sm-16'>{patient.patient_name}</label>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-8 font-weight-bold">Date Reported</label>
                        <label className='col-sm-16'>{getDateFormat(patient.reported_at)}</label>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-8 font-weight-bold">Old Email</label>
                        <label className='col-sm-16'>{patient.patient_email}</label>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-8 font-weight-bold required-field">Enter New Email</label>
                        <div className="col-sm-16">
                            <input type="text" className="form-control" onChange={this.onChange} name="email" value={this.state.email} />

                            {this.validator.message('email', this.state.email, 'required|email')}
                        </div>
                    </div>
                </form>
            </Modal.Body >
            <Modal.Footer>
                <Button variant="outline-primary" onClick={this.props.onHide}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={this.onSubmit}>
                    Submit
                </Button>
            </Modal.Footer>
        </Modal >
    }
}

export default SendReportModal