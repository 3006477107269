import React from "react";
import FeeCollectionModal from "./FeeCollectionModal";

class CollectFeeButton extends React.Component {
	constructor(props){
		super(props);
		this.state = {
      		prescriptionId: '',
      		type: '',
      		isloading: false,
			title: '',
			showCollectFeeModal: false,
		}
		this.onSuccessRedirect = this.onSuccessRedirect.bind(this);
        this.showCollectFeeModal = this.showCollectFeeModal.bind(this);
        this.handleClose = this.handleClose.bind(this);
	}

    componentDidMount()
    {
    	this.setState({
      		prescriptionId: this.props.prescriptionId,
      		type: this.props.type,
			title: this.props.title,
    	});
    }

	onSuccessRedirect() {
        this.setState({ showCollectFeeModal: false });
	}
	
	showCollectFeeModal() {
        this.setState({ showCollectFeeModal: true });
	}
	
	handleClose() {
        this.setState({ showCollectFeeModal: false });
	}

    render() {

        return (<React.Fragment>
        	{this.state.isloading && this.state.isloading && <div className="loading">Loading</div>}
			<button className="btn btn-outline-primary ml-2" onClick={e => this.showCollectFeeModal()}>
        		{this.state.title}
    		</button>
			<FeeCollectionModal
				id={this.state.prescriptionId}
				onSuccess={this.onSuccessRedirect}
				show={this.state.showCollectFeeModal}
				handleClose={this.handleClose}
				setPrescriptionFee ={this.props.setPrescriptionFee}
			/>
        </React.Fragment>)
    }
}

export default CollectFeeButton;