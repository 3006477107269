import * as types from './actionTypes';
import PatientService from '../services/patientService';
import Notification from '../utils/notification';
import ImmunizationService from '../services/immunizationService';
import ProcedureService from '../services/procedureService';
import { hasProvider } from '../utils/auth'

export function reset() {
    return dispatch => {
        dispatch({ type: types.PATIENT_LIST_RESET });
        dispatch({ type: types.RESET_PATIENT });
    }
}

export function list({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' })
        dispatch({ type: types.PATIENT_LIST_REQUEST, request: { page, sizePerPage, sortBy, filter } });

        PatientService.list({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' })
                dispatch({ type: types.PATIENT_LIST_SUCCESS, ...data });

                if (data.data && data.data.length <= 0) {
                    Notification.show('error', { message: 'Patient record not found.' })
                }
            })
            .catch(error => {
                dispatch({ type: types.PATIENT_LIST_FAILED, ...error.response })
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);

                }
            });
    }
}

export function searchPatientConsent(searchData, role, history) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' })

        PatientService.searchPatientConsent(searchData, role)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' })

                if (data && data.patient_id <= 0) {
                    Notification.show('error', { message: 'Patient record not found.' })
                }
                if (role === 'doctor') {
                    history.push('/patients/' + data.patient_id);
                } else if (role === 'radiology') {
                    history.push('/patients/' + data.patient_id);
                } else if (role === 'pharmacy') {
                    history.push('/patients/' + data.patient_id);
                } else if (role === 'pathology') {
                    history.push('/patients/' + data.patient_id);
                }
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            })
    }
}

export function searchPatientCard(searchData, role, history) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' })

        PatientService.searchPatientCard(searchData, role)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' })

                if (data && data.patient_id <= 0) {
                    Notification.show('error', { message: 'Patient record not found.' })
                }
                if (role === 'doctor') {
                    history.push('/patients/' + data.patient_id);
                } else if (role === 'radiology') {
                    history.push('/patients/' + data.patient_id);
                } else if (role === 'pharmacy') {
                    history.push('/patients/' + data.patient_id);
                } else if (role === 'pathology') {
                    history.push('/patients/' + data.patient_id);
                }
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            })
    }
}

export function add(data, selected_user_profile, history) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.ADD_PATIENT, ...data });

        PatientService.create({ data })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: types.ADD_PATIENT, ...data });
                Notification.show('success', { message: 'Patient is successfully onboarded.' })
                setTimeout(() => {
                    Notification.clearAll();
                    if (hasProvider(selected_user_profile.service_provider, ['doctor']))
                        history.push('/patients/' + data.patient_id);
                    else if (hasProvider(selected_user_profile.service_provider, ['covid-center']))
                        history.push('/covid-centers/patients/' + data.patient_id);
                }, 2000);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}

export function get(id, query) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.FETCH_PATIENT });

        PatientService.get(id, query)
            .then(resp => {
                let data = resp.data;

                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: types.FETCH_PATIENT, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}

export function update(data, patient_id, history) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });

        PatientService.update(data, patient_id)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.UPDATE_PATIENT, ...data });
                dispatch({ type: 'HIDE_PAGE_LOADER' })
                Notification.show('success', { message: "Patient record updated successfully." })
                setTimeout(() => {
                    Notification.clearAll();
                    history.push("/patients/" + data.patient_id);
                }, 2000);

            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}

export function get_patients_last_weights(id) {
    PatientService.get_patients_last_weights(id)
        .then(resp => {
            let data = resp.data;
            return data;
        })
        .catch(error => {
            return false;
        });
}

export function get_patients_last_blood_pressure(id) {
    PatientService.get_patients_last_blood_pressure(id)
        .then(resp => {
            let data = resp.data;
            return data;
        })
        .catch(error => {
            return false;
        });
}

export function get_patients_last_symptoms(id) {
    PatientService.get(id)
        .then(resp => {
            let data = resp.data;
            return data;
        })
        .catch(error => {
            return false;
        });
}

export function get_patients_last_remarks(id) {
    PatientService.get_patients_last_remarks(id)
        .then(resp => {
            let data = resp.data;
            return data;
        })
        .catch(error => {
            return false;
        });
}

export function getImmunizationList({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' })
        dispatch({ type: types.IMMUNIZATION_LIST_REQUEST, request: { page, sizePerPage, sortBy, filter } });

        ImmunizationService.list({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' })
                dispatch({ type: types.IMMUNIZATION_LIST_SUCCESS, ...data });
            })
            .catch(error => {
                dispatch({ type: types.IMMUNIZATION_LIST_FAILED, ...error.response })
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}

export function getHitoricalData(patientId, { page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' })
        dispatch({ type: types.PATIENT_HISTORIC_DATA_LIST_REQUEST, request: { patientId, page, sizePerPage, sortBy, filter } });

        PatientService.getHistoricalData(patientId, { page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' })
                dispatch({ type: types.PATIENT_HISTORIC_DATA_SUCCESS, ...data });
            })
            .catch(error => {
                dispatch({ type: types.PATIENT_HISTORIC_DATA_FAILED, ...error.response })
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}

export function getHistoryDownloadUrl(documentId) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });

        PatientService.getHistoryDownloadUrl(documentId)
            .then(resp => {
                let data = resp.data;
                window.open(data.download_url);

                dispatch({ type: 'HIDE_PAGE_LOADER' });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    let errorData = error.response.data;
                    Notification.show('error', errorData)
                }
            });
    }
}

export function getUploadedHistoryData({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' })
        dispatch({ type: types.UPLOADED_HISTORIC_DATA_LIST_REQUEST, request: { page, sizePerPage, sortBy, filter } });

        PatientService.getUploadedHistoricData({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' })
                dispatch({ type: types.UPLOADED_HISTORIC_DATA_LIST_SUCCESS, ...data });
            })
            .catch(error => {
                dispatch({ type: types.UPLOADED_HISTORIC_DATA_LIST_FAILED, ...error.response })
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}

export function getProcedureList({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' })
        dispatch({ type: types.PROCEDURE_LIST_REQUEST, request: { page, sizePerPage, sortBy, filter } });

        ProcedureService.list({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' })
                dispatch({ type: types.PROCEDURE_LIST_SUCCESS, ...data });
            })
            .catch(error => {
                dispatch({ type: types.PROCEDURE_LIST_FAILED, ...error.response })
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}

export function updatePatientProfile(data, patient_id, history) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });

        PatientService.update(data, patient_id)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.UPDATE_PATIENT, ...data });
                dispatch({ type: 'HIDE_PAGE_LOADER' })
                Notification.show('success', { message: "Patient record updated successfully." })
                setTimeout(() => {
                    Notification.clearAll();
                    history.push("/patients/" + data.patient_id);
                }, 2000);

            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}

export function listPatients({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' })
        dispatch({ type: types.PATIENT_LIST_REQUEST, request: { page, sizePerPage, sortBy, searchText } });

        PatientService.patientList({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' })
                dispatch({ type: types.PATIENT_LIST_SUCCESS, ...data });

                if (data.data && data.data.length <= 0) {
                    Notification.show('error', { message: 'Patient record not found.' })
                }
            })
            .catch(error => {
                dispatch({ type: types.PATIENT_LIST_FAILED, ...error.response })
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);

                }
            });
    }
}