import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function (state = initialState.pagination, { type, ...payload }) {
    switch (type) {
        case types.LIST_INVOICES:
            return {
                ...state,
                ...payload
            }; case types.LIST_STATEMENTS:
            return {
                ...state,
                ...payload
            };
        default:
            return state;
    }
}