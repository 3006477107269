import React from 'react';

const ShowRadiology = ({ radiology }) => {
    return (
        radiology &&
        <li>
            <div>Test Name: <span>{radiology.test_name} </span></div>
            <div>Test Category: <span>{radiology.test_category}</span></div>
        </li>)
}
export default ShowRadiology