import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function (state = initialState.pagination, { type, ...payload }) {
    switch (type) {
        case types.PENDING_SIGNATURE_PRESCRIPTION_LIST_REQUEST:
            return {
                ...state,
                ...payload
            };
        case types.PENDING_SIGNATURE_PRESCRIPTION_LIST_SUCCESS:
            return {
                ...state,
                data: payload.data,
                meta: payload.meta
            };
        case types.PENDING_SIGNATURE_PRESCRIPTION_LIST_FAILED:
            return {
                ...state,
                ...payload
            };
        case 'MODIFIED_PRESCRIPTION_LIST_REQUEST':
            return {
                ...state,
                ...payload
            };
        case 'MODIFIED_PRESCRIPTION_LIST_SUCCESS':
            return {
                ...state,
                data: payload.data,
                meta: payload.meta
            };
        case 'MODIFIED_PRESCRIPTION_LIST_FAILURE':
            return {
                ...state,
                ...payload
            };
        default:
            return state;
    }
}