import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as PrescriptionAction from "../../actions/prescriptionAction";
import PrescriptionService from '../../services/prescriptionService';
import DataGrid from "../../components/DataGrid";
import * as Helper from "../../utils/helpers";
import Notification from '../../utils/notification';
import TechnicianPharmacistDashboard from '../../components/Dashboard/Technician&PharmacistDashboard';

class ListPrescriptionQueuePage extends React.Component {
    constructor(props) {
        super(props);

        this.props.prescriptionQueueList({ sortBy: { sortField: "created_at", sortOrder: "asc" } });
    }

    columns = () => {
        return [
            {
                dataField: "prescription_id",
                text: "Prescription Id",
                sort: false
            },
            {
                dataField: "patient_name",
                text: "Patient Name",
                sort: false
            },
            {
                dataField: "prescription_date",
                text: "Prescription Date",
                formatter: this.dateFormatter,
                sort: false
            },
            {
                dataField: "assigned_by_name",
                text: "Assigned By Name",
                sort: false
            },
            {
                dataField: "acquired_by_name",
                text: "Acquired By Name",
                sort: false
            },
            {
                dataField: "created_at",
                text: "Assigned Time",
                formatter: this.dateFormatter,
                sort: false
            },
            {
                dataField: "actions",
                text: "Actions",
                isDummyField: true,
                formatter: this.actionFormatter,
                sort: false
            }
        ];
    };

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        if (type === "search") {
            page = 1;
        }

        let sortBy = sortField ? { sortField, sortOrder } : { sortField: "created_at", sortOrder: "asc" };

        this.props.prescriptionQueueList({ page, sizePerPage, sortBy });
    };

    patientNameFormatter = (cell, row) => {
        if (row.patient_info.full_name) {
            return row.patient_info.full_name
        } else if (row.patient_info.patient_name)
            return row.patient_info.patient_name
    }

    dateFormatter = (cell, row) => {
        return Helper.getDateTimeFormat(cell);
    };

    nameFormatter = (cell, row) => {
        return Helper.fullNameStr(cell);
    };

    actionFormatter = (cell, row) => {
        return (
            <React.Fragment>

                {row.acquired_by_role !== 'pharmacy-technician' && <Link to={"/pharmacy/submit/order/" + row.prescription_id} className="btn btn-primary">View</Link>}
                {row.acquired_by_role === 'pharmacy-technician' && <button className="btn btn-outline-primary" onClick={e => this.revokePrescription(row)}> Revoke </button>}
            </React.Fragment>
        )
    }

    revokePrescription = (row) => {
        let postData = {
            prescription_id: row.prescription_id
        };

        PrescriptionService.revokePharmacyPrecription(row.prescription_id, postData)
            .then(resp => {
                let data = resp.data;
                Notification.show('success', { message: data.message });
                this.props.prescriptionQueueList({ sortBy: { sortField: "created_at", sortOrder: "asc" } });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    Notification.show('error', error.response.data);
                }
            });
    }


    render() {
        let { data, meta } = this.props.prescription_queue;
        return (
            data && <React.Fragment>
                <Helmet>
                    <title>Prescription Queue</title>
                </Helmet>

                <h4 className="page-title">Prescription Queue</h4>
                {this.props.selected_user_profile && <div className="row">
                    <TechnicianPharmacistDashboard
                        selected_user_profile={this.props.selected_user_profile} /> </div>
                }
                <div className="content-wrapper">
                    <DataGrid
                        columns={this.columns()}
                        data={data}
                        meta={meta}
                        noDataIndication="No Record Found"
                        handleTableChange={this.handleTableChange}
                    />
                </div>

            </React.Fragment>

        );
    }

}
const mapStateToProps = state => ({
    prescription_queue: state.prescription.paginate,
    selected_user_profile: state.selected_user_profile,
    user: state.session.user
});

const mapActionsToProps = {
    prescriptionQueueList: PrescriptionAction.prescriptionQueueList
};

export default connect(
    mapStateToProps,
    mapActionsToProps
)(ListPrescriptionQueuePage)
