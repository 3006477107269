import React from 'react';
import * as GeneralComponent from './ReviewOfSystem/General';
import * as NervousSystemComponent from './ReviewOfSystem/NervousSystem';
import * as PsychiatricComponent from './ReviewOfSystem/Psychiatric';
import * as MuscleComponent from './ReviewOfSystem/Muscle';
import * as StomachComponent from './ReviewOfSystem/Stomach';
import * as EarsComponent from './ReviewOfSystem/Ears';
import * as SkinComponent from './ReviewOfSystem/Skin';
import * as EyesComponent from './ReviewOfSystem/Eyes';
import * as ThroatComponent from './ReviewOfSystem/Throat';
import * as BloodComponent from './ReviewOfSystem/Blood';
import * as HeartComponent from './ReviewOfSystem/Heart';
import * as KidneyComponent from './ReviewOfSystem/Kidney';
import { find, pull, isEmpty, pullAllBy } from 'lodash';

class ReviewSystemBlock extends React.Component {
    handleReviewSystemChange = (e, category) => {
        let { review_of_system } = this.props;
        let existingData = this.getCategory(category);
        if (e.target.name === 'comments') {
            existingData ? existingData['comments'] = e.target.value : review_of_system.push({ 'category': category, 'comments': e.target.value, 'problems': [] })
        }
        if (e.target.checked) {
            (existingData) ? existingData['problems'].push(e.target.name) : review_of_system.push({ 'category': category, 'problems': [e.target.name], 'comments': '' });
        } else {
            if (e.target.name !== 'comments') {
                pull(existingData['problems'], e.target.name);
                if (isEmpty(existingData['problems']))
                    pullAllBy(review_of_system, [{ 'category': category }], 'category');
            }
        }
        this.props.handleReviewSystem(review_of_system)
    }
    getCategory = (category) => {
        let { review_of_system } = this.props;
        return find(review_of_system, { 'category': category });
    }
    checkIfProblemExist = (category, problem) => {
        let existingData = this.getCategory(category);
        let existElement = existingData && Array.isArray(existingData.problems) && existingData.problems.find(element => element === problem)
        return existElement;
    }
    getCategoryComment = (category) => {
        let existingData = this.getCategory(category);
        return existingData && existingData.comments;
    }
    render() {
        return (
            <React.Fragment>
                <h6 className="main-title">In the past month, has the patient had any of the following problems?</h6>
                <div className="row">
                    <div className="col-md-24">
                        <div className="examination-wrapper">
                            <div className="form-group full">
                                <GeneralComponent.General
                                    handleChange={this.handleReviewSystemChange}
                                    checkIfProblemExist={this.checkIfProblemExist}
                                />
                                <GeneralComponent.Comment
                                    handleChange={this.handleReviewSystemChange}
                                    getCategoryComment={this.getCategoryComment}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-24">
                        <div className="examination-wrapper">
                            <div className="form-group full">
                                <NervousSystemComponent.NervousSystem
                                    handleChange={this.handleReviewSystemChange}
                                    checkIfProblemExist={this.checkIfProblemExist}
                                />
                                <NervousSystemComponent.Comment
                                    handleChange={this.handleReviewSystemChange}
                                    getCategoryComment={this.getCategoryComment} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-24">
                        <div className="examination-wrapper">
                            <div className="form-group full">
                                <PsychiatricComponent.Psychiatric
                                    handleChange={this.handleReviewSystemChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                                <PsychiatricComponent.Comment
                                    handleChange={this.handleReviewSystemChange}
                                    getCategoryComment={this.getCategoryComment} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-24">
                        <div className="examination-wrapper">
                            <div className="form-group full">
                                <MuscleComponent.Muscle
                                    handleChange={this.handleReviewSystemChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                                <MuscleComponent.Comment
                                    handleChange={this.handleReviewSystemChange}
                                    getCategoryComment={this.getCategoryComment} />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-24">
                        <div className="examination-wrapper">
                            <div className="form-group full">
                                <StomachComponent.Stomach handleChange={this.handleReviewSystemChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                                <StomachComponent.Comment handleChange={this.handleReviewSystemChange}
                                    getCategoryComment={this.getCategoryComment} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-24">
                        <div className="examination-wrapper">
                            <div className="form-group full">
                                <EarsComponent.Ears handleChange={this.handleReviewSystemChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                                <EarsComponent.Comment handleChange={this.handleReviewSystemChange}
                                    getCategoryComment={this.getCategoryComment} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-24">
                        <div className="examination-wrapper">
                            <div className="form-group full">
                                <EyesComponent.Eyes handleChange={this.handleReviewSystemChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                                <EyesComponent.Comment handleChange={this.handleReviewSystemChange}
                                    getCategoryComment={this.getCategoryComment} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-24">
                        <div className="examination-wrapper">
                            <div className="form-group full">
                                <SkinComponent.Skin handleChange={this.handleReviewSystemChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                                <SkinComponent.Comment handleChange={this.handleReviewSystemChange}
                                    getCategoryComment={this.getCategoryComment} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-24">
                        <div className="examination-wrapper">
                            <div className="form-group full">
                                <ThroatComponent.Throat handleChange={this.handleReviewSystemChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                                <ThroatComponent.Comment handleChange={this.handleReviewSystemChange}
                                    getCategoryComment={this.getCategoryComment} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-24">
                        <div className="examination-wrapper">
                            <div className="form-group full">
                                <BloodComponent.Blood handleChange={this.handleReviewSystemChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                                <BloodComponent.Comment handleChange={this.handleReviewSystemChange}
                                    getCategoryComment={this.getCategoryComment} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-24">
                        <div className="examination-wrapper">
                            <div className="form-group full">
                                <HeartComponent.Heart handleChange={this.handleReviewSystemChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                                <HeartComponent.Comment handleChange={this.handleReviewSystemChange}
                                    getCategoryComment={this.getCategoryComment} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-24">
                        <div className="examination-wrapper">
                            <div className="form-group full">
                                <KidneyComponent.Kidney handleChange={this.handleReviewSystemChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                                <KidneyComponent.Comment handleChange={this.handleReviewSystemChange}
                                    getCategoryComment={this.getCategoryComment} />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ReviewSystemBlock