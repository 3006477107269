import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import MedicineService from '../../services/medicineService';
import Notification from '../../utils/notification';

class MedicineUnlockModal extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            buttonText: 'Unlock Prescription',
            prescription_unlock_id: "",
            isSubmitDisabled: false,
        };
        
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit() {

        this.setState({
            buttonText: 'Please wait....',
            isSubmitDisabled: true
        })

        let patientId = (this.props.patientId) ? this.props.patientId : null;
        let prescription_id = (this.props.prescriptionId) ? this.props.prescriptionId : null;
        
        MedicineService.unlock(patientId,prescription_id,this.state.prescription_unlock_id)
            .then((resp) => {
                Notification.show('success', { message: 'Medicine Unlocked Successfully!!!' });
                this.props.onSuccess(this.props);

                this.setState({
                    buttonText: 'Unlock Prescription',
                    prescription_unlock_id: '',
                    isSubmitDisabled: false
                })
            })
            .catch((error) => {
                Notification.show('error', error.response.data);
                this.setState({
                    buttonText: 'Unlock',
                    prescription_unlock_id: '',
                    isSubmitDisabled: false
                })
            })
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Body>
                    <Form>
                        <Form.Control
                                size="lg"
                                type="text"
                                placeholder="Enter Prescription Unlock ID"
                                value={this.state.prescription_unlock_id}
                                id="prescription_unlock_id"
                                name="prescription_unlock_id"
                                onChange={this.handleChange}
                            />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" disabled={this.state.isSubmitDisabled} onClick={this.handleSubmit}>
                        {this.state.buttonText}
                    </Button>
                    <Button variant="secondary" disabled={this.state.isSubmitDisabled} onClick={this.props.handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default MedicineUnlockModal
