import React from 'react';
import { connect } from 'react-redux';
import Loader from './Loader';
import moment from 'moment';
import * as Sentry from '@sentry/react';
import _ from 'lodash';

class Content extends React.Component {
    constructor(props) {
        super(props);
        this.checkTrailExpiry = this.checkTrailExpiry.bind(this);
    }

    componentDidMount() {
        if (!_.isEmpty(this.props.selected_profile)) {
            const { user_profile_id, role_slug, user, service_provider, user_location_id } = this.props.selected_profile

            Sentry.configureScope(function (scope) {
                scope.setUser({
                    'email': user.email,
                    'User Profile Id': user_profile_id,
                    'User Role': role_slug,
                    'Service Provider Type': service_provider.service_provider_type,
                    'User Location Id': user_location_id
                });
            });
        }
    }

    checkTrailExpiry = (trialExpiryDate) => {
        const currentDate = moment();
        const expiryDate = moment(trialExpiryDate);
        const remainingTimeInMinutes = moment(expiryDate).diff(moment(currentDate), 'minutes');
        const remainingTimeInDays = moment(expiryDate).diff(moment(currentDate), 'days');

        if (remainingTimeInMinutes > 0) {
            if (remainingTimeInDays === 0) {
                return <div className="trial-container">Your trial period ends today!</div>;
            } else {
                return <div className="trial-container">Your trial period will expire in <span>{remainingTimeInDays}</span> days.</div>
            }
        } else {
            return <div className="trial-container">Your account is expired</div>
        }
    }

    render() {
        const { service_provider } = this.props.selected_profile;
        return (
            <section className="main-container">
                {service_provider && (service_provider.status === 'trial') && this.checkTrailExpiry(service_provider.trial_expiry_at)}
                <Loader />
                {this.props.children}
            </section>
        );
    }
}

const mapStateToProps = (state) => ({
    selected_profile: state.selected_user_profile,
});

export default connect(mapStateToProps, null)(Content);