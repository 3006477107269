import React from 'react';
import CovidCenterService from '../../services/covidCenterService';
import moment from 'moment';
import { Link } from "react-router-dom";
import SelectSignatureModal from './SelectSignatureModal';
import Notification from '../../utils/notification';
import SimpleReactValidator from 'simple-react-validator';

class ConductCovidTest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            result: '',
            reported_at: '',
            show_signature_modal: false,
            test_text: ''
        }
        this.validator = new SimpleReactValidator()
    }
    componentDidMount() {
        this.props.loaderShow()
        CovidCenterService.getCovidReport(this.props.patient_id, this.props.report_id).then(resp => {
            let { data } = resp;

            this.setState({
                collected_at: moment(data.collected_at).format('DD/MM/YYYY HH:mm'),
                received_at: moment(data.received_at).format('DD/MM/YYYY HH:mm'),
                test_name: data.test_name,
                test_text: data.payment.test_type ? JSON.parse(data.payment.test_type).text : ""
            });
            this.props.loaderHide()
        }).catch(err => {
            this.props.loaderHide();
            // this.props.history('/patients/search')
            this.props.history.push('/patients/search')
            Notification.show('error', err.response.data)
        })
    }

    showSignatureModal = (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        } else {
            this.setState({ show_signature_modal: true });
        }
    }
    hideSignatureModal = () => this.setState({ show_signature_modal: false })
    onChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }
    onSubmit = (doctor) => {
        this.setState({
            show_signature_modal: false
        });
        const data = {
            'result': this.state.result,
            'reported_at': this.state.reported_at + " " + this.state.reported_at_time + ":00",
            'doctor_name': doctor.full_name,
            'doctor_user_profile_id': doctor.user_profile_id,
        }
        this.props.loaderShow()
        CovidCenterService.updateCovidReport(data, this.props.report_id).then(resp => {
            this.props.loaderHide();
            this.props.history.push('/covid-centers/completed-reports')
        }).catch(err => {
            this.props.loaderHide();
            Notification.show('error', err.response.data)
        })
    }

    render() {
        this.validator.purgeFields()
        return <div className="row">
            <div className="col-md-24">
                <h4 className="page-title">Conduct Covid Test</h4>
                <div className="content-wrapper edit-patient">
                    <form>
                        <div className="form-group row">
                            <label htmlFor="testtype" className="col-sm-4 col-form-label">Test Type:</label>
                            <div className="col-sm-10">
                                <input type="text" className="form-control mx-sm-3" value={this.state.test_name} disabled />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="collected_at" className="col-sm-4 col-form-label">Date Collected</label>
                            <div className="col-sm-10">
                                <input type="datetime" className="form-control mx-sm-3" name="collected_at" onChange={this.onChange} value={this.state.collected_at} disabled />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="received_at" className="col-sm-4 col-form-label">Date Received</label>
                            <div className="col-sm-10">
                                <input type="datetime" className="form-control mx-sm-3" name="received_at" onChange={this.onChange} value={this.state.received_at} disabled />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="reported_at" className="col-sm-4 col-form-label required-field">Date Reported</label>
                            <div className="col-sm-10">
                                <input type="date" className="form-control mx-sm-3 col-sm-10 float-left" name="reported_at" onChange={this.onChange} value={this.state.reported_at} max={moment().format('YYYY-MM-DD')} />
                                <input type="time" className="form-control mx-sm-3 col-sm-10 " name="reported_at_time" onChange={this.onChange} value={this.state.reported_at_time} />
                                <div className="col-sm-10 clear-both">
                                    {this.validator.message('reported_date', this.state.reported_at, 'required')}
                                    {this.validator.message('reported_time', this.state.reported_at_time, 'required')}
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="testtype" className="col-sm-4 col-form-label required-field">Test Result:</label>
                            <div className="col-sm-10" onChange={this.onChange} value={this.state.report}>
                                <select className="form-control mx-sm-3" name="result">
                                    <option value="">Select Result</option>
                                    <option value="positive">Positive</option>
                                    <option value="negative">Negative</option>
                                </select>
                                <div className="col-sm-12">{this.validator.message('result', this.state.result, 'required')}</div>
                            </div>
                        </div>

                        <div className="float-right">
                            <Link to="/patients/search" className="btn btn-outline-primary">Cancel</Link>
                            <button className="btn btn-primary ml-2" onClick={this.showSignatureModal}>Generate Report</button>
                        </div>
                        <div className="d-flex justify-content-center mt-2">{this.state.test_text}</div>
                    </form>
                </div></div>
            {this.state.show_signature_modal && <SelectSignatureModal show={this.state.show_signature_modal}
                onShow={this.showSignatureModal}
                onHide={this.hideSignatureModal}
                onChange={this.onChange}
                selectedProfiles={this.onSubmit}
                validator={this.validator}
                loaderShow={this.props.loaderShow}
                loaderHide={this.props.loaderHide}
            />}
        </div>
    }
}
export default ConductCovidTest