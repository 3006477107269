import React from 'react';
import _ from 'lodash';

const WorkExcuse = ({validator, form_attributes, handleChangeCertificate}) => {
    return(
        <React.Fragment>
            <div className="row mb-3">
                <div className="col-md-24">
                    <h6 className="blue-main-heading grey-border-bottom">Work Excuse</h6>
                </div>
                <div className="col-md-24 certi-details">
                    
                        <p>To Whom It May Concern:</p>
                        
                        <p>This is to ceritify that the above patient has been under my professional care since <form className="form-inline d-inline">
                            <input type="date" className="form-control i-cale" name="start_date" value={form_attributes.start_date} onChange={handleChangeCertificate} /> to &nbsp;
 
                            <input type="date" className="form-control i-cale" name="end_date" value={form_attributes.end_date} onChange={handleChangeCertificate} min={form_attributes.start_date}/> </form>, and was incapacitated during this time.
                        </p>
                        {validator.message('Start Date', form_attributes.start_date, 'required')}
                        {validator.message('End Date', form_attributes.end_date, 'required')}
                        
                        <p>This is to further certify that the above patient has recovered sufficiently to be able to return to work duties on <form className="form-inline d-inline">
                        <input type="date" className="form-control i-cale" name="return_on" value={form_attributes.return_on} onChange={handleChangeCertificate} min={form_attributes.end_date} disabled={!_.isEmpty(form_attributes.start_date) && !_.isEmpty(form_attributes.end_date) ? false : true }/>
                    </form></p>
                        {validator.message('Return on date', form_attributes.return_on, 'required')}
                </div>
            </div>

            <div className="mb-3 row certi-details">
                <label htmlFor="staticEmail" className="col-auto col-form-label">Restrictions:</label>
                <div className="col-sm-10">
                <textarea className="form-control p-2" name="restriction" rows="1" value={form_attributes.restriction} onChange={handleChangeCertificate}></textarea>
                    {validator.message('Restrictions', form_attributes.restriction, 'string')}
                </div>
            </div>
        </React.Fragment>
    )
}

export default WorkExcuse;