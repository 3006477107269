import React from 'react';
import { Helmet } from 'react-helmet';
import { capitalize, isEmpty } from 'lodash';
import { getAgeString, getDateFormat } from '../../utils/helpers';
import { hasRole } from '../../utils/auth';
import PrescriptionService from '../../services/prescriptionService';
import SimpleReactValidator from 'simple-react-validator';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import MedicineBlock from '../../components/Prescription/MedicineBlock';
import ShowRadiologyTest from "../../components/Prescription/RadiologyTestBlock";
import ShowPathologyTest from "../../components/Prescription/PathologyTestBlock";
import AddPathologyBlock from '../../components/AddPathologyBlock';
import AddRadiologyBlock from '../../components/AddRadiologyBlock';
import Notification from '../../utils/notification';
import * as LoaderAction from '../../actions/loaderAction';

class EditPrescriptionNew extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            patient: '',
            medicines: '',
            pathology: '',
            radiology: '',
            prescriptionId: '',
            show_add_pathology_block: false,
            show_add_radiology_block: false,
            originalRecommendation: '',
            edit_medicine: false,
            edit_pathology: false,
            edit_radiology: false,
        }
        this.validator = new SimpleReactValidator();
    }
    componentDidMount() {
        const params = this.props.match.params;
        this.props.showLoader();
        PrescriptionService.view(params.prescriptionId).then(resp => {
            let { data } = resp;
            this.setState({
                patient: data.patient_info,
                originalRecommendation: data.recommendation,
                prescriptionId: params.prescriptionId,
                medicines: data.recommendation.prescription_medicines ? data.recommendation.prescription_medicines : '',
                pathology: data.recommendation.prescription_pathology_tests ? data.recommendation.prescription_pathology_tests : '',
                radiology: data.recommendation.prescription_radiology_tests ? data.recommendation.prescription_radiology_tests : '',
                edit_medicine: true,
                edit_pathology: true,
                edit_radiology: true,
            });
            this.props.hideLoader();
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data)
        });
    }
    // Start Medicine Block Events
    handleMedicineChange = (idx, data) => {
        let { medicines } = this.state
        const prescription_medicine_item = medicines.prescription_medicine_item.map((medicine, sidx) => {
            if (idx !== sidx) return medicine;

            return { ...medicine, ...data };
        });
        this.setState({ medicines: { prescription_medicine_item, ...this.state.medicines } });
    };

    handleAddMedicine = () => {
        let { medicines } = this.state;
        medicines['prescription_medicine_item'] = medicines.prescription_medicine_item.concat({
            drug_id: "",
            drug_name: "",
            drug_generic_id: "",
            drug_generic_name: "",
            dosage: "",
            meal: "",
            duration: "",
            duration_type: "",
            substitution_not_allowed: false,
            quantity: "",
            drug_type: "",
            is_other: false,
            substitution_not_allowed_disable: false
        });
        this.setState({ medicines })
    };

    handleRemoveMedicine = (event) => {
        let { medicines } = this.state
        const idx = Number(event.target.dataset["id"]);
        medicines['prescription_medicine_item'] = medicines.prescription_medicine_item.filter((s, sidx) => idx !== sidx);
        this.setState({ medicines });
    };
    // End Medicine Block Events
    // Start Pathology Block Events
    handlePathologyChange = (idx, data) => {
        let { pathology } = this.state;
        const prescription_pathology_test_items = pathology.prescription_pathology_test_items.map((pathology, sidx) => {
            if (idx !== sidx) return pathology;

            return { ...pathology, ...data };
        });
        this.setState({ pathology: { prescription_pathology_test_items, ...this.state.pathology } });
    };
    handleAddPathology = () => {
        let { pathology } = this.state;
        this.setState({
            pathology: pathology.prescription_pathology_test_items.concat([{
                test_id: '',
                test_type: '',
                test_category: '',
                test_name: '',
                test_description: '',
                remark: '',
                is_other: false
            }])
        });
    };
    handleAddPathologyBlock = () => {
        this.setState({
            show_add_pathology_block: !this.state.show_add_pathology_block
        })
    }
    handleRemovePathologyTest = (event) => {
        let { pathology } = this.state
        const idx = Number(event.target.dataset["id"]);
        pathology['prescription_pathology_test_items'] = pathology.prescription_pathology_test_items.filter((s, sidx) => idx !== sidx);
        this.setState({ pathology });
    };
    handlePathology = (data) => {
        let { pathology } = this.state
        pathology['prescription_pathology_test_items'] = pathology.prescription_pathology_test_items.concat(data);
        this.setState({ pathology });
    }
    // End Pathology Block Events
    // Start Radiology Block Events
    handleRadiologyChange = (idx, data) => {
        let { radiology } = this.state;
        const prescription_radiology_test_items = radiology.prescription_radiology_test_items.map((radiology, sidx) => {
            if (idx !== sidx) return radiology;

            return { ...radiology, ...data };
        });
        this.setState({ radiology: { prescription_radiology_test_items, ...this.state.radiology } });
    };
    handleAddRadiology = () => {
        let { radiology } = this.state;
        this.setState({
            radiology: radiology.prescription_radiology_test_items.concat([{
                test_id: '',
                test_category: '',
                test_name: '',
                test_description: '',
                remark: '',
                is_other: false
            }])
        });
    };
    handleRemoveRadiologyTest = (event) => {
        let { radiology } = this.state
        const idx = Number(event.target.dataset["id"]);
        radiology['prescription_radiology_test_items'] = radiology.prescription_radiology_test_items.filter((s, sidx) => idx !== sidx);
        this.setState({ radiology });
    };
    handleAddRadiologyBlock = () => {
        this.setState({
            show_add_radiology_block: !this.state.show_add_radiology_block
        })
    }
    handleRadiology = (data) => {
        let { radiology } = this.state;
        radiology['prescription_radiology_test_items'] = radiology.prescription_radiology_test_items.concat(data);
        this.setState({ radiology });
    }
    // End Radiology Block Events
    handleEditBtnClick = (action, status) => {
        let actionText = document.getElementById(action).innerHTML;
        if (action === 'edit_medicine') {
            this.setState({
                edit_medicine: !status
            });
        } else if (action === 'edit_pathology') {
            this.setState({
                edit_pathology: !status
            });
        } else if (action === 'edit_radiology') {
            this.setState({
                edit_radiology: !status
            });
        }

        if (actionText === 'Edit') {
            document.getElementById(action).innerHTML = 'Cancel';
            document.getElementById(action).className = 'float-right p-0 btn btn-danger';

        } else {
            let { originalRecommendation } = this.state
            document.getElementById(action).innerHTML = 'Edit';
            document.getElementById(action).className = 'float-right p-0 btn btn-primary';
            if (action === 'edit_medicine') {
                this.setState({
                    medicines: originalRecommendation.prescription_medicines ? originalRecommendation.prescription_medicines : [],
                });
            } else if (action === 'edit_pathology') {
                this.setState({
                    pathology: originalRecommendation.prescription_pathology_tests ? originalRecommendation.prescription_pathology_tests : [],
                });
            } else if (action === 'edit_radiology') {
                this.setState({
                    radiology: originalRecommendation.prescription_radiology_tests ? originalRecommendation.prescription_radiology_tests : [],
                });
            }
        }
    }
    handleSubmit = (e) => {
        e.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        if (!this.state.edit_medicine && this.state.medicines && isEmpty(this.state.medicines.prescription_medicine_item)) {
            Notification.show('error', { message: 'Atleast one medicine is required.' }); return;
        }
        if (!this.state.edit_pathology && this.state.pathology && isEmpty(this.state.pathology.prescription_pathology_test_items)) {
            Notification.show('error', { message: 'Atleast one lab test is required.' }); return;
        }
        if (!this.state.edit_radiology && this.state.radiology && isEmpty(this.state.radiology.prescription_radiology_test_items)) {
            Notification.show('error', { message: 'Atleast one radiology test is required.' }); return;
        }

        let edited_prescription = {
            patient: this.state.patient,
            prescriptionId: this.state.prescriptionId,
            medicines: !this.state.edit_medicine && this.state.medicines ? this.state.medicines : '',
            pathology: !this.state.edit_pathology && this.state.pathology ? this.state.pathology : '',
            radiology: !this.state.edit_radiology && this.state.radiology ? this.state.radiology : '',
            edit_medicine: this.state.edit_medicine ? this.state.edit_medicine : false,
            edit_pathology: this.state.edit_pathology ? this.state.edit_pathology : false,
            edit_radiology: this.state.edit_radiology ? this.state.edit_radiology : false,
        }

        this.props.history.push({
            pathname: '/prescriptions/' + this.state.prescriptionId + '/edit-summary',
            state: { edited_prescription }
        })
    }
    render() {
        let { medicines, radiology, pathology } = this.state;
        this.validator.purgeFields();

        return (<React.Fragment >
            <Helmet>
                <title>Edit Prescription</title>
            </Helmet>
            <div className="row">
                <div className="col-md-24 requester-tabs">
                    <h4 className="page-title">Edit Prescription
                        <div>
                            <Link className="btn btn-primary ml-2" target="_blank" to={{ pathname: "/prescriptions/" + this.state.prescriptionId }}>view prescription</Link>
                        </div>
                    </h4>
                </div>
                {this.state.patient && <div className="content-wrapper padding-bottom-10">
                    <h6>Personal Info</h6>
                    <ul className="prescription-patient-details">
                        <li>Name: <span>{this.state.patient.full_name}</span></li>
                        <li>Date of Birth: <span>{this.state.patient.date_of_birth && getDateFormat(this.state.patient.date_of_birth)} ({getAgeString(this.state.patient.date_of_birth)})</span></li>
                        <li>Gender: <span>{capitalize(this.state.patient.gender)}</span></li>
                    </ul>
                </div>}
                <div className="content-wrapper lg-height">
                    <h6>Prescription and Recommendation</h6>
                    <div className="row">
                        <div className="col-md-24">
                            {medicines && medicines.status === "prescribed" && <div className="content-wrapper padding-bottom-10">
                                {hasRole(this.props.selected_user_profile, ['doctor', 'associate-doctor']) && medicines.status === "prescribed" && <button className='float-right p-0 btn btn-primary' onClick={() => this.handleEditBtnClick('edit_medicine', this.state.edit_medicine)} id="edit_medicine">Edit</button>}
                                <h6>Medication</h6>
                                <ul className="prescription-patient-details medicine-details">
                                    {medicines.prescription_medicine_item.map((medicine, idx) => (
                                        <MedicineBlock
                                            key={idx}
                                            medicine={medicine}
                                            idx={idx}
                                            handleChangeMedicine={this.handleMedicineChange}
                                            handleRemoveMedicine={this.handleRemoveMedicine}
                                            validator={this.validator}
                                            selected_user_profile={this.props.selected_user_profile}
                                            disabled={this.state.edit_medicine}
                                            not_required={false}
                                        />
                                    ))}
                                </ul>
                                <button onClick={this.handleAddMedicine} className="add-new btn btn-primary" disabled={this.state.edit_medicine}>Add</button>
                            </div>}
                            {pathology && pathology.status === "prescribed" && <div className="content-wrapper padding-bottom-10">
                                {hasRole(this.props.selected_user_profile, ['doctor', 'associate-doctor']) && pathology.status === "prescribed" && <button className='float-right p-0 btn btn-primary' onClick={() => this.handleEditBtnClick('edit_pathology', this.state.edit_pathology)} id="edit_pathology">Edit</button>}
                                <h6>Lab Test</h6>
                                <ul className="prescription-patient-details medicine-details">
                                    {pathology.prescription_pathology_test_items.map((pathology, idx) => (
                                        <ShowPathologyTest
                                            pathology={pathology}
                                            idx={idx}
                                            key={idx}
                                            handleChangePathology={this.handlePathologyChange}
                                            handleRemovePathologyTest={this.handleRemovePathologyTest}
                                            disabled={this.state.edit_pathology}
                                            validator={this.validator}
                                        />
                                    ))}
                                    <button onClick={this.handleAddPathologyBlock} href className="add-new btn btn-primary" disabled={this.state.edit_pathology}>Add</button>
                                    <AddPathologyBlock
                                        show={this.state.show_add_pathology_block}
                                        prescriptionId={this.state.prescriptionId}
                                        handleAddPathologyBlock={this.handleAddPathologyBlock}
                                        handlePathology={this.handlePathology}
                                    />
                                </ul>
                            </div>}
                            {radiology && radiology.status === 'prescribed' && <div className="content-wrapper padding-bottom-10">
                                {hasRole(this.props.selected_user_profile, ['doctor', 'associate-doctor']) && radiology.status === 'prescribed' && <button className='float-right p-0 btn btn-primary' onClick={() => this.handleEditBtnClick('edit_radiology', this.state.edit_radiology)} id="edit_radiology">Edit</button>}
                                <h6>Radiology</h6>
                                <ul className="prescription-patient-details medicine-details">
                                    {radiology.prescription_radiology_test_items.map((radiology, idx) => (
                                        <ShowRadiologyTest
                                            radiology={radiology}
                                            idx={idx}
                                            key={idx}
                                            handleChangeRadiology={this.handleRadiologyChange}
                                            handleRemoveRadiologyTest={this.handleRemoveRadiologyTest}
                                            disabled={this.state.edit_radiology}
                                            validator={this.validator}
                                        />
                                    ))}
                                    <button onClick={this.handleAddRadiologyBlock} href className="add-new btn btn-primary" disabled={this.state.edit_radiology}>Add</button>
                                    <AddRadiologyBlock
                                        show={this.state.show_add_radiology_block}
                                        prescriptionId={this.state.prescriptionId}
                                        handleAddRadiologyBlock={this.handleAddRadiologyBlock}
                                        handleRadiology={this.handleRadiology}
                                    />
                                </ul>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
            {hasRole(this.props.selected_user_profile, ['doctor', 'associate-doctor']) && this.state.originalRecommendation &&
                <div className="btn-center">
                    <button type="submit" className="btn btn-primary" onClick={this.handleSubmit} disabled={(!this.state.edit_medicine || !this.state.edit_pathology || !this.state.edit_radiology) ? false : true}>Next</button>
                    <Link to="/patients/search" className="btn btn-default btn-outline-primary">Cancel</Link>
                </div>}
        </React.Fragment>)
    }
}
const mapStateToProps = (state) => ({
    selected_user_profile: state.selected_user_profile,
})
const mapActionsToProps = {
    hideLoader: LoaderAction.loaderHide,
    showLoader: LoaderAction.loaderShow,
};
export default connect(mapStateToProps, mapActionsToProps)(EditPrescriptionNew)