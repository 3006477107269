import React from "react";
import PersonalNoteModal from "./PersonalNoteModal";
import * as BootsrapIcons from '@Components/Icons/BootstrapIcons';

class EditPersonalNoteButton extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			showPersonalNoteModal: false,
		}
		this.onSuccessRedirect = this.onSuccessRedirect.bind(this);
        this.showPersonalNoteModal = this.showPersonalNoteModal.bind(this);
        this.handleClose = this.handleClose.bind(this);
	}

	onSuccessRedirect() {
        this.setState({ showPersonalNoteModal: false });
	}
	
	showPersonalNoteModal() {
        this.setState({ showPersonalNoteModal: true });
	}
	
	handleClose() {
        this.setState({ showPersonalNoteModal: false });
	}

    render() {
        
        return (<React.Fragment>
			<button className="btn-none float-right" onClick={e => this.showPersonalNoteModal()} title="Edit"><BootsrapIcons.PencilSquare /></button>
			<PersonalNoteModal
                id={this.props.prescriptionId}
                personal_note={this.props.personal_note}
				onSuccess={this.onSuccessRedirect}
				show={this.state.showPersonalNoteModal}
                handleClose={this.handleClose}
                setPrescriptionPersonalNote ={this.props.setPrescriptionPersonalNote}
			/>
        </React.Fragment>)
    }
}

export default EditPersonalNoteButton;