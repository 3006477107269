import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

class NotificationPopup extends React.Component {
    onContinue = () => {
        this.props.shouldRedirect(this.props.notificationData);
    }

    render() {
        return (
            <Modal show={this.props.showModal} onHide={this.props.handleClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>You will lost your current data. still want to continue ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={this.onContinue}>
                        continue
            </Button>
                    <Button variant="primary" onClick={this.props.handleClose}>
                        cancel
            </Button>
                </Modal.Footer>
            </Modal>
        )

    }
}

export default NotificationPopup;