import React from 'react';

export const Heart = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <div className="heading">
            <h6>Cardiovascular System & Respiratory System</h6>
        </div>
        <div className="form-group full">
            <div className="options-details">
                <input type="checkbox" id='chest-pain' name='chest-pain' onChange={e => handleChange(e, 'heart-and-lungs')} checked={checkIfProblemExist('heart-and-lungs', 'chest-pain') ? 'checked' : ''} />
                <label htmlFor='chest-pain'>Chest Pain</label>
                <input type="checkbox" id='palpitations' name='palpitations' onChange={e => handleChange(e, 'heart-and-lungs')} checked={checkIfProblemExist('heart-and-lungs', 'palpitations') ? 'checked' : ''} />
                <label htmlFor='palpitations'>Palpitations</label>
                <input type="checkbox" id='shortness-of-breath' name='shortness-of-breath' onChange={e => handleChange(e, 'heart-and-lungs')} checked={checkIfProblemExist('heart-and-lungs', 'shortness-of-breath') ? 'checked' : ''} />
                <label htmlFor='shortness-of-breath'>Shortness of breath</label>
                <input type="checkbox" id='fainting' name='fainting' onChange={e => handleChange(e, 'heart-and-lungs')} checked={checkIfProblemExist('heart-and-lungs', 'fainting') ? 'checked' : ''} />
                <label htmlFor='fainting'>Fainting</label>
                <input type="checkbox" id='swollen-legs-or-feet' name='swollen-legs-or-feet' onChange={e => handleChange(e, 'heart-and-lungs')} checked={checkIfProblemExist('heart-and-lungs', 'swollen-legs-or-feet') ? 'checked' : ''} />
                <label htmlFor='swollen-legs-or-feet'>Swollen legs or feet</label>
                <input type="checkbox" id='cough' name='cough' onChange={e => handleChange(e, 'heart-and-lungs')} checked={checkIfProblemExist('heart-and-lungs', 'cough') ? 'checked' : ''} />
                <label htmlFor='cough'>Cough</label>
            </div>
        </div>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment }) => {
    return <React.Fragment>
        <label className="heading-comment">Comment </label>
        <textarea className="form-control" name="comments" placeholder="Type here..." onChange={e => handleChange(e, 'heart-and-lungs')} value={getCategoryComment('heart-and-lungs') ? getCategoryComment('heart-and-lungs') : ''}></textarea>
    </React.Fragment>
}