export const events = {
    // 'login': 'Login',
    // 'logout': 'Logout',
    // 'switch-profile': 'Profile Switched',
    'card-swipe': 'Card Swiped',
    'consent-access': 'Consent Accessed',
    'patient-access': 'Patient Accessed',
    'patient-create': 'Patient Created',
    'patient-edit': 'Patient Edited',
    'prescription-draft': 'Prescription Draft',
    'prescription-access': 'Prescription Accessed',
    'prescription-create': 'Prescription Created',
    'prescription-edit': 'Prescription Edited',
    'order-draft': 'Order Draft',
    'order-create': 'Order Created',
    'order-edit': 'Order Edited',
    'order-cancel': 'Order Cancelled',
    'consent-request': 'Consent Requested',
    'reissue-card-request': 'Reissue Card Request'
}
export const covidCenterEvents = {
    'covid-card-swipe': 'Card Swiped',
    'covid-report-upload': 'Existing Report Uploaded',
    'covid-report-submit': 'Report Submitted',
    'covid-conduct-test': 'Test Conducted',
    'covid-patient-access': 'Patient Accessed',
}
export const airlineEvents = {
    'airline-card-swipe': 'Card Swiped',
    'airline-patient-access': 'Patient Accessed',
    'airline-record-access': 'Record Accessed',
}