import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import * as HELPER from '../../utils/helpers';
import _ from 'lodash';
import Notification from '../../utils/notification';
import { hasRole } from "../../utils/auth";
import OfflinePrescriptionService from '../../services/offlinePrescriptionService';

class StartOfflinePrescriptionTest extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: '',
            isLoading: false,
            isChecked: false,
            id: this.props.match.params.id,
        }
        this.handleCheckedData = this.handleCheckedData.bind(this);
    }

    componentDidMount() {
        if (this.state.id) {
            OfflinePrescriptionService.getofflinePresciptionById(this.state.id).then(resp => {
                this.setState({
                    data: resp.data
                })
            }).catch(error => {
                Notification.show('error', error.response)
            })
        }
    }

    handleCheckedData = (index) => {
        const { data } = this.state
        const options = data.prescription_info.slice();

        if (options[index]) {
            options[index].checked = !options[index].checked;
        }
    }

    renderButton = () => {
        if ((hasRole(this.props.selected_user_profile, ["radiologist"]))) {
            return (<React.Fragment>
                <div className="button-wrapper align-right">
                    <button className="btn btn-primary" onClick={this.createOrder}>Start test</button>
                </div>
            </React.Fragment>
            )
        }
    }

    createOrder = () => {
        const { data } = this.state
        const options = data.prescription_info
        const selectedData = options.filter(option => option.checked)
        if (selectedData === '') {
            Notification.show('error', { message: 'Select atleast one test.' });
        } else {
            const createOrder = this.state.data;
            createOrder.order_info = [...selectedData];
            createOrder.order_type = "radiology";

            OfflinePrescriptionService.createPharmacyOrder(createOrder).then(resp => {
                Notification.show('success', resp.data)
                this.props.history.push('/offline/prescriptions');
            }).catch(error => {
                Notification.show('error', error.response)
            })
        }
    }

    render() {
        const { data } = this.state
        return (<React.Fragment>
            <Helmet>
                <title>Radiology</title>
            </Helmet>
            {!_.isEmpty(data) && <div className="row">
                <div className="col-md-24">
                    <h4 className="page-title no-heading">
                        <span className="prescription-details">Temporary Id: <i>{data && data.temporary_id}</i></span>
                        <span className="date">Date <i>{HELPER.getDateFormat(data.prescription_date)}</i></span>
                    </h4>

                    <div className="content-wrapper padding-bottom-10">
                        <ul className="prescription-patient-details">
                            <li>Patient ID: <span>{data && data.patient_id}</span></li>
                            <li>Name: <span>{data.patient_info && data.patient_info.patient_name}</span></li>
                            <li>Age: <span>{data.patient_info && data.patient_info.age}</span></li>
                            <li>Gender: <span>{data.patient_info && _.capitalize(data.patient_info.gender)}</span></li>
                        </ul>
                    </div>

                    <div className="content-wrapper padding-bottom-10">
                        <h6>Doctor Info</h6>
                        <ul className="prescription-patient-details">
                            <li>Doctor ID: <span>{data && data.doctor_id}</span></li>
                            <li>Name: <span>{data.doctor_info && data.doctor_info.doctor_name}</span></li>
                        </ul>
                    </div>
                    <h4 className="page-title font-size-16">Test Details</h4>
                    {data.prescription_info && data.prescription_info.map((item, index) => (
                        <div className="content-wrapper padding-bottom-10" key={index} index={this.state.isChecked}>
                            <input type="checkbox" id={index} onClick={() => this.handleCheckedData(index)} checked={item.checked} />
                            <label htmlFor={index}></label>
                            <ul className="prescription-patient-details test-details">
                                <li>Test Name: <span>{item.test_name + " - " + (item.test_category ? item.test_category : 'Profile Test')}</span></li>
                                <li>Description: <span>{item.test_description}</span></li>
                                <li className="no-border">Remark: <span>{item.remark}</span></li>
                            </ul>
                        </div>))}
                    {this.renderButton()}
                </div>
            </div>}
        </React.Fragment>);
    }
}

const mapStateToProps = (state) => ({
    selected_user_profile: state.selected_user_profile,
    data: state.offline_prescription
});

const mapActionsToProps = ({
})
export default connect(mapStateToProps, mapActionsToProps)(StartOfflinePrescriptionTest);