import React from 'react';
import { Modal, Form, Row, Col, Container, Button } from 'react-bootstrap';
import moment from 'moment';
import SimpleReactValidator from "simple-react-validator";
import AppointmentService from '../../services/appointmentService'
import { connect } from 'react-redux';
import * as loaderAction from '../../actions/loaderAction';
import Notification from '../../utils/notification'
import { includes, isEmpty, remove, groupBy } from 'lodash';

class CancelSlotButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'date',
            to: '',
            from: '',
            user_location_id: '',
            existing_slots: '',
            slot_id: [],
            doctor_profile_id: ''
        }
        this.validator = new SimpleReactValidator();
    }
    handleChange = (evt) => {
        if (this.state.type === 'slot' && evt.target.name === 'from') {
            this.setState({ to: evt.target.value, from: evt.target.value });
        } else if (evt.target.name === 'type') {
            this.setState({
                type: evt.target.value,
                to: '',
                from: '',
                user_location_id: '',
                existing_slots: ''
            })
        } else
            this.setState({ [evt.target.name]: evt.target.value });
    }
    componentDidUpdate(_prevProps, prevState) {
        let { to, from, user_location_id, doctor_profile_id } = this.state;

        if ((prevState.to !== to) || (prevState.from !== from) || (prevState.doctor_profile_id !== doctor_profile_id) || (prevState.user_location_id !== user_location_id)) {
            this.setState({ existing_slots: '' })
        }
    }
    deleteSlot = (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        };
        let { type, from, to, user_location_id, slot_id } = this.state;
        if (type === 'slot' && isEmpty(slot_id)) {
            Notification.show('error', { message: 'Kindly select a slot to delete.' });
            return false
        }
        let params = {
            type: type,
            from: from,
            to: to,
            location_id: user_location_id,
            slot_id: slot_id,
            doctor_profile_id: this.state.doctor_profile_id,
        };
        AppointmentService.deleteSlot(params).then(_resp => {
            // Notification.show('success', { message: 'Current slot deleted successfully.' });
            this.props.handleModalShowHide(true);
        }).catch(err => {
            this.props.hideLoader();
            Notification.show('error', err.response.data)
        })
    }
    handleClick = (e, slot) => {
        e.preventDefault();
        if (slot.status === 'available') {
            this.setState({ slot_id: slot.id })
        }
    }
    getSlot = (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        };
        let { user_location_id, from, to } = this.state;
        this.props.showLoader();
        const queryParams = {
            doctor_profile_id: this.state.doctor_profile_id,
            user_location_id: user_location_id,
            from: from,
            to: to,
        }
        AppointmentService.getSlot({ filter: queryParams }).then(resp => {
            this.props.hideLoader()
            if (resp.data.data.length > 0) {
                let slots = resp.data.data[0].slots;
                let existing_slots = groupBy(slots, 'day_part');
                this.setState({ existing_slots });
            } else {
                Notification.show('error', { message: 'No slots available on this date.' })
            }
        }).catch(err => {
            this.props.hideLoader()
            Notification.show('error', { message: 'Something went wrong.' })
        });
    }
    handleSlotSelection = (item) => {
        let { slot_id } = this.state;
        if (includes(slot_id, item.id)) {
            remove(slot_id, function (n) {
                return item.id === n;
            });
        } else {
            slot_id = [...slot_id, item.id]
        }
        this.setState({ slot_id })
    }
    getSlots = (slot, key) => {
        return <Col md={24}>
            <div className="title"><span>{key}</span> ({slot.length} Slot)</div>
            <div className="appmnt-slots-data">
                <ul className="row list-inline">
                    {slot.map(item => (
                        <li className="col-md-3" key={item.id}>
                            <a href='javascript:void(0)' onClick={(e) => this.handleSlotSelection(item)} className={includes(this.state.slot_id, item.id) ? "selected" : ''}>{this.getTime(item.start_time)} To {this.getTime(item.end_time)}</a>
                        </li>
                    ))}
                </ul>
            </div>
        </Col>
    }
    getTime = (time) => {
        return moment(time, 'HH:mm:ss').format('hh:mm A');
    }
    componentWillUnmount() {
        this.setState({
            type: 'date',
            to: '',
            from: '',
            user_location_id: '',
            existing_slots: '',
            slot_id: ''
        })
    }

    render() {
        this.validator.purgeFields();
        let { from, to, type, user_location_id, existing_slots } = this.state
        return <Modal size="xl" aria-labelledby="contained-modal-title-vcenter" centered show={this.props.showHide} backdrop="static" keyboard={false} onHide={() => this.props.handleModalShowHide()}>
            <Modal.Header closeButton>
                <Modal.Title>Cancel Slot</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="schedule-appmnt-popup">
                    <Form inline>
                        <Row>
                            <Col md={12} className="pb-sm-1 pt-sm-1 v-line mb-3">
                                <Form.Group>
                                    <Form.Label>Select Doctor:</Form.Label>
                                    <Form.Control as="select" className="mx-sm-3" disabled={this.props.doctor_disabled ? "disabled" : ''} name="doctor_profile_id" onChange={this.handleChange}>
                                        <option value=''>Select Doctor</option>
                                        {this.props.selected_doctor.map(doctor => (
                                            <option key={doctor.user_profile_id} value={doctor.user_profile_id}>{doctor.full_name}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                {this.validator.message('doctor', this.state.doctor_profile_id, 'required')}
                            </Col>
                            <Col md={12} className="pb-sm-1 pt-sm-1 v-line">
                                <Form.Group>
                                    <Form.Label className="required mr-3">Cancel Type:</Form.Label>
                                    {['radio'].map((type) => (
                                        <div key={`inline-${type}`}>
                                            <Form.Check inline label="Full Day" name="type" type={type} id={`inline-${type}-1`} value="date" onClick={this.handleChange} defaultChecked />
                                            <Form.Check inline label="Slot Based" type={type} id={`inline-${type}-2`} name="type" onClick={this.handleChange} value="slot" />
                                        </div>
                                    ))}
                                </Form.Group>
                                {this.validator.message('type', type, 'required')}
                            </Col>
                            <Col md={12} className="pb-sm-1 pt-sm-1 v-line">
                                <Form.Group>
                                    <Form.Label className="required">Date:</Form.Label>
                                    <Form.Control
                                        required
                                        type="date"
                                        className="mr-3 i-cale"
                                        name="from"
                                        onChange={this.handleChange}
                                        value={from} />
                                    <Form.Label>To</Form.Label>
                                    <Form.Control
                                        required
                                        type="date"
                                        className="mx-sm-3 i-cale"
                                        name="to"
                                        min={from ? from : moment().format('YYYY-MM-DD')}
                                        onChange={this.handleChange}
                                        value={to}
                                        disabled={type === 'slot' ? 'disabled' : ''} />
                                </Form.Group>
                                {this.validator.message('from_date', from, 'required')}
                                {type === "date" && this.validator.message('to_date', to, 'required')}
                            </Col>
                            <Col md={12} className="pb-sm-1 pt-sm-1 v-line">
                                <Form.Group>
                                    <Form.Label>Location:</Form.Label>
                                    <Form.Control as="select" className="mx-sm-3" onChange={this.handleChange} name="user_location_id" value={user_location_id}>
                                        <option value=''>Select location</option>
                                        {this.props.locations.map(location => (
                                            <option value={location.user_location_id} key={location.user_location_id}>{location.name}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                {this.validator.message('location', user_location_id, 'required')}
                            </Col>
                        </Row>
                        {type === 'slot' && <Row className="w-100 mt-5">
                            <Col md={24} className="d-flex justify-content-md-center">
                                <Button variant="primary" onClick={this.getSlot}>Search</Button>
                            </Col>
                        </Row>}
                    </Form>
                    {type === 'date' && <Row className="w-100 mt-5">
                        <Col md={24}>
                            <p className="slots-data-policy m-0">*Cancelling Slot will cancel all the appointment for the duration</p>
                        </Col>
                    </Row>}
                    {type === 'slot' && existing_slots && <Row className="pagination-mfixes appmnt-slots">
                        {Object.keys(existing_slots).map((key) => (
                            existing_slots[key].length > 0 && this.getSlots(existing_slots[key], key)
                        ))}
                    </Row>}

                </Container>
            </Modal.Body>
            <Modal.Footer>
                {type === 'slot' && existing_slots && <div className="slots-data-sign">
                    {/* <span className="slots-data-sign-Blocked">Booked</span> */}
                    <span className="slots-data-sign-Selected">Selected</span>
                </div>}
                <Button variant="primary" onClick={this.deleteSlot}>
                    Submit
                    </Button>
                <Button variant="outline-primary" onClick={() => this.props.handleModalShowHide()}>
                    Cancel
                    </Button>
            </Modal.Footer>
        </Modal >
    }
}
const mapActionsToProps = ({
    showLoader: loaderAction.loaderShow,
    hideLoader: loaderAction.loaderHide,
})
export default connect(null, mapActionsToProps)(CancelSlotButton)