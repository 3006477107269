import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function (state = initialState.filters, { type, ...payload }) {
    switch (type) {
        case types.DOCTOR_DASHBOARD_FILTERS:
            return {
                ...state,
                ...payload
            };
        case types.PHARMACY_DASHBOARD_FILTERS:
            return {
                ...state,
                ...payload
            };
        case types.PATHOLOGY_DASHBOARD_FILTERS:
            return {
                ...state,
                ...payload
            };
        case types.RADIOLOGY_DASHBOARD_FILTERS:
            return {
                ...state,
                ...payload
            };
        default:
            return state;
    }
}