import React from 'react';

export const PrimaryButton = ({ handleChange, buttonType, buttonName, disabled = false }) => {
    return (
        <React.Fragment>
            <button className="btn btn-primary" onClick={(e) => handleChange(e, buttonType)} disabled={disabled}>{buttonName}</button>
        </React.Fragment>
    )
}

export const OutlineButton = ({ handleChange, buttonName }) => {
    return (
        <React.Fragment>
            <button className="btn btn-default btn-outline-primary" onClick={handleChange}>{buttonName}</button>
        </React.Fragment>
    )
}