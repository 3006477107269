import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import _ from 'lodash';

class OtherProfileNotificationPopup extends React.Component {

    onContinue = () => {
        let userProfiles = this.props.profileData ? this.props.profileData : null;
        let notificationData = this.props.notificationData;
        
        if(userProfiles !== null){
         let user =  _.filter(userProfiles, { 'user_profile_id': notificationData.user_profile_id });
         this.props.shouldSwitchProfile(this.props.notificationData, user);   
        }  
    }

    render() {
        return (
            <Modal show={this.props.showModal} onHide={this.props.handleClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>You will switch to other profile and the data will be lost. still want to continue ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={this.onContinue}>
                        Switch Profile 
            </Button>
                    <Button variant="primary" onClick={this.props.handleClose}>
                        cancel
            </Button>
                </Modal.Footer>
            </Modal>
        )

    }

}

export default OtherProfileNotificationPopup;