import React from 'react';
import DataGrid from '../../components/DataGrid';
import SimpleReactValidator from 'simple-react-validator';
import moment from 'moment';
import Search from './Search';

class OverallReminder extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tags: {
                patient_name: "",
                contact_number: "",
                date_filter: ''
            },
            filter: {
                patient_name: "",
                contact_number: "",
                start_date: '',
                end_date: '',
            }
        }
        this.validator = new SimpleReactValidator();
    }

    handleChange = (e) => {
        const { filter } = this.state;
        filter[e.target.name] = e.target.value ? e.target.value : undefined;
        this.setState({ filter });
    }

    handleEvent = (event, picker) => {
        this.setState({
            filter: {
                start_date: (picker.startDate._d) ? moment(picker.startDate._d).format('YYYY-MM-DD') : '',
                end_date: (picker.endDate._d) ? moment(picker.endDate._d).format('YYYY-MM-DD') : '',
            }
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        /* External javascript function  */
        window['searchToggle']();
        this.setState({
            tags: {
                patient_name: this.state.filter.patient_name,
                contact_number: this.state.filter.contact_number,
                date_filter: (this.state.filter.start_date && this.state.filter.end_date) ? this.state.filter.start_date + ' to ' + this.state.filter.end_date : ''
            }
        });

        this.props.paginate({ filter: this.state.filter });
    }

    resetSearch = () => {
        let { filter } = this.state;
        filter.patient_name = '';
        filter.contact_number = '';
        filter.start_date = '';
        filter.end_date = '';
        this.setState({
            filter: filter,
            tags: {
                patient_name: '',
                contact_number: '',
                date_filter: ''
            },
        });

        window['searchToggle']();
        this.props.paginate({ filter: filter });
    }

    removeFilter = (e) => {
        const { filter, tags } = this.state;
        if (e.target.dataset.tag === 'date_filter') {
            filter["start_date"] = '';
            filter["end_date"] = '';
        } else {
            filter[e.target.dataset.tag] = '';
        }
        tags[e.target.dataset.tag] = '';
        this.setState({ filter: filter, tags: tags });
        this.props.paginate({ filter: this.state.filter });
    }

    handleTableChange = (
        type,
        { page, sizePerPage, sortField, sortOrder, searchText }
    ) => {
        if (type === "search") {
            page = 1;
        }
        this.props.paginate({ page, sizePerPage, filter: this.state.filter });
    };

    render() {
        let { data, meta, columns } = this.props;
        let { tags, filter } = this.state;

        return (<React.Fragment>
            <Search filter={filter}
                tags={tags}
                removeFilter={this.removeFilter}
                onSerch={this.handleSubmit}
                handleChange={this.handleChange}
                validator={this.validator}
                onReset={this.resetSearch}
                handleEvent={this.handleEvent}
            />
            <div className="content-wrapper">
                <DataGrid
                    columns={columns}
                    data={data}
                    meta={meta}
                    handleTableChange={this.handleTableChange}
                    noDataIndication="No Data Found"
                />
            </div>
        </React.Fragment>);
    }
}

export default (OverallReminder);