import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function(state = initialState.pagination, { type, ...payload })
{   
    switch(type){
        case types.USER_MANAGEMENT_LIST_REQUEST:
            return {
                ...state,
                ...payload,
                isLoading: true
            };
        case types.USER_MANAGEMENT_LIST_SUCCESS:
            return {
                ...state,
                data: payload.data,
                meta: payload.meta,
                isLoading: false
            };
        case types.USER_MANAGEMENT_LIST_FAILED:
            return {
                ...state,
                data: [],
                ...payload,
                isLoading: false
            }; 
        case types.ADD_USER_SUCCESS:
            return{
                ...state,
                ...payload
            };
            case types.ADD_USER_REQUEST:
            return{
                ...state,
                ...payload
            };
            case types.ADD_USER_FAILED:
            return{
                ...state,
                ...payload
            };
        default:
            return state;
    }
            
}