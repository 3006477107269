import React from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import MedicineService from '../services/medicineService';
import _ from 'lodash';
import { getDosageType, getDurationType, apiRoute, getApiHeader } from '../utils/helpers';
import axios from "axios";
import AsyncDemoSelect from '../components/AsyncDemoSelect';
import { hasRole } from "../utils/auth";
import { withTranslation } from 'react-i18next';


class PharmacyMedicineBlock extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            drug_name_disabled: true,
            unit_given_disabled: true,
            package_size_disabled: true,
            minimum_sale: '',
            isLoading: false,
            options: [],
            insurance_type: this.props.patientInsurance,
            filter: {
                generic_id: this.props.medicine.drug_generic_id,
            },
            role_slug: this.props.role.role_slug,
            over_dosage: false,
            ordremarks: ''
        }
    }

    _handleChange = (evt, drug_skus = null) => {
        const { medicine } = this.props;
        medicine.drug_skus = drug_skus;
        let fieldName = evt.target.name;
        let fieldValue = evt.target.value;

        medicine[fieldName] = fieldValue;

        if (fieldName === 'package_size') {
            this.setState({
                unit_given_disabled: false
            });

            if (_.isEmpty(medicine.drug_skus)) {
                medicine.minimum_sale = 1;
                medicine.unit_dispatched = Number.isNaN(medicine.unit_given) ? 1 * 1 : 1 * parseInt(medicine.unit_given);
                medicine.drug_coverages = '';
                medicine.drug_sku_id = '';
                medicine.drug_sku_number = '';
            } else {
                medicine.drug_skus.forEach((index) => {
                    if (index.id === parseInt(fieldValue)) {
                        medicine.minimum_sale = parseInt(index.min_sell_quantity);
                        medicine.unit_dispatched = medicine.unit_given > 0 ? parseInt(medicine.minimum_sale) * parseInt(medicine.unit_given) : '';
                        medicine.drug_coverages = index.drug_coverages;
                        medicine.drug_sku_id = fieldValue;
                        medicine.drug_sku_number = evt.target[evt.target.selectedIndex].getAttribute('data-ndc');
                        medicine.selected_coverages = "";

                        if (medicine.unit_given > 0) {
                            this.props.medicine.required_unit = Math.ceil(medicine.remaining_unit / (medicine.minimum_sale === "" ? 1 : parseInt(medicine.minimum_sale)));
                        }
                    }
                })
            }
        }

        if (fieldName === "unit_given") {
            this.props.medicine.required_unit = Math.ceil(medicine.remaining_unit / (medicine.minimum_sale === "" ? 1 : parseInt(medicine.minimum_sale)));
            medicine.unit_dispatched = medicine.unit_given > 0 ? ((medicine.minimum_sale === "" ? 1 : parseInt(medicine.minimum_sale)) * parseInt(medicine.unit_given)) : '';
        }

        if (_.isEmpty(medicine['selected_coverages'])) {
            medicine["selected_coverages"] = "";
        }

        this.props.handleChangeMedicine(this.props.idx, medicine);
    }

    _handleSearch = (query) => {
        this.setState({ isLoading: true });
        let filter = this.state.filter;
        this.props.medicine.unit_dispatched = '';

        MedicineService.list({ searchText: query, filter })
            .then(resp => {
                let data = resp.data;
                const items = data.data;
                const options = items.map((i) => ({
                    id: i.id,
                    name: i.name + ' - ' + i.generic_drug.name,
                    brand_name: i.name,
                    generic_id: i.generic_drug_id,
                    generic_name: i.generic_drug.name,
                    drug_skus: i.drug_skus,
                    drug_type: i.dosage_form.type
                }));
                this.setState({
                    isLoading: false,
                    options: options
                });
            });
    }

    _onChange = (evt) => {
        let { medicine } = this.props;

        if (!_.isEmpty(evt) && _.isArray(evt)) {
            let option = _.head(evt);

            if (option.customOption === true) {
                medicine.drug_name = option.brand_name;
            } else {
                medicine.drug_id = option.id;
                medicine.drug_name = option.brand_name;
                medicine.drug_skus = option.drug_skus;
                medicine.package_size = '';
                medicine.minimun_sale = '';
                medicine.unit_dispatched = '';
                medicine.unit_given = '';
                medicine.drug_type = option.drug_type;
                medicine.selected_coverages = "";
            }
        } else {
            medicine.drug_id = "";
            medicine.drug_name = "";
            medicine.drug_skus = [];
            medicine.package_size = '';
            medicine.minimum_sale = '';
            medicine.unit_dispatched = '';
            medicine.unit_given = '';
            medicine.selected_coverages = "";
        }

        this.setState({
            unit_given_disabled: true
        });
        this.props.handleChangeMedicine(this.props.idx, medicine);
    }

    allowMedicine = (evt) => {
        let { medicine, role } = this.props;

        if (evt.target.checked) {
            this.setState({
                drug_name_disabled: (role.role_slug === 'pharmacist') ? (this.props.medicine.is_generic_allowed ? false : true) : true,
                package_size_disabled: false,
                unit_given_disabled: false
            });
            medicine.unit_given = (medicine.unit_given === null || medicine.unit_given === '' || Number.isNaN(medicine.unit_given)) ? null : parseInt(medicine.unit_given);
            medicine.medicine_given = true;
            medicine.is_given = 'yes';
            medicine.minimum_sale = (medicine.minimum_sale === undefined || medicine.minimum_sale === null || medicine.minimum_sale === "") ? "" : parseInt(medicine.minimum_sale); this.props.handleChangeMedicine(this.props.idx, medicine);
        } else {
            this.setState({
                drug_name_disabled: true,
                package_size_disabled: true,
                unit_given_disabled: true
            });
            medicine.unit_given = '';
            medicine.medicine_given = false;
            medicine.package_size = '';
            medicine.is_given = 'no';
            medicine.minimum_sale = '';
        }

        if (medicine.unit_given !== null || medicine.unit_given !== '' || !Number.isNaN(medicine.unit_given)) {
            medicine.required_unit = Math.ceil(medicine.remaining_unit / (medicine.minimum_sale === "" ? 1 : parseInt(medicine.minimum_sale)));
            medicine.unit_dispatched = medicine.unit_given > 0 ? ((medicine.minimum_sale === "" ? 1 : parseInt(medicine.minimum_sale)) * parseInt(medicine.unit_given)) : '';
        }

        this.props.handleChangeMedicine(this.props.idx, medicine);
    }

    minimum_sale = () => this.state.package_size && this.props.medicine.drug_skus.map(function (index) {
        if (index.id === this.state.package_size) {
            this.setState({
                minimum_sale: index.min_sell_quantity
            });
        }
    }.bind(this));

    get_dosage_forms() {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute('/v1/drugs/dosage-forms'), requestOptions);
    }

    componentDidMount() {
        let { medicine } = this.props;
        let over_dosage = false;

        this.get_dosage_forms().then(res => {
            (res.data).forEach((forms) => {
                if (forms.key === medicine.dosage_form) {
                    over_dosage = forms.is_over_dispatch;
                }
            });
            this.setState({
                drug_dosage_form: res.data,
                over_dosage: over_dosage
            });
        });
    }

    render() {
        let drug_type = this.props.medicine.drug_type === "tablespoon" ? "ml" : "pc";
        const { t } = this.props
        return (
            <li>
                <div className="first">
                    {this.props.medicine.status === "fulfilled" && _.isNull(this.props.prescription_extended_status && this.props.prescription_extended_status !== 'draft') ? <div></div> :
                        <div className="check-brand">
                            <input type="checkbox" id={'chkmed-' + this.props.idx} name="radio-group" value="yes" onClick={this.allowMedicine} defaultChecked={this.props.medicine_given} />
                            <label htmlFor={'chkmed-' + this.props.idx}></label>
                        </div>
                    }
                    <div className="form-group-wrapper">
                        <div className="form-group">
                            <label>Brand Name</label>
                            <AsyncTypeahead
                                id="drug_name"
                                disabled={this.state.drug_name_disabled}
                                allowNew={false}
                                isLoading={this.state.isLoading}
                                options={this.state.options}
                                newSelectionPrefix="New: "
                                labelKey="brand_name"
                                minLength={1}
                                useCache={false}
                                onSearch={this._handleSearch}
                                onChange={this._onChange}
                                placeholder="Search drug"
                                defaultInputValue={this.props.medicine.drug_name}
                                renderMenuItemChildren={(option) => {
                                    return <div key={option.id}>
                                        <div>{option.brand_name}</div>
                                        <small>Generic Name: {option.generic_name}</small>
                                    </div>
                                }}
                            ></AsyncTypeahead>
                        </div>
                        <div className="form-group fixed no-padding-left">
                            <label>Package Size</label>
                            <AsyncDemoSelect
                                medicine={this.props.medicine}
                                handleChange={this._handleChange}
                                package_size_disabled={this.state.package_size_disabled}
                                package_size={this.props.medicine && this.props.medicine.package_size ? this.props.medicine.package_size : null}
                            />
                            {!this.state.package_size_disabled && this.props.validator.message('package_size' + this.props.idx, this.props.medicine.package_size, 'required')}
                        </div>
                        <div className="form-group fixed single-line">
                            <label>Frequency</label>
                            {getDosageType(this.props.medicine.dosage)}
                        </div>
                        <div className="form-group fixed single-line">
                            <label>Duration</label>
                            {this.props.medicine.duration}{getDurationType(this.props.medicine.duration_type)}
                        </div>
                        <div className="form-group fixed double-line">
                            <label>Dosage</label>
                            {this.props.medicine.quantity}
                        </div>
                        <div className="form-group fixed double-line">
                            <label>Substitution allowed:</label>
                            {this.props.medicine.is_generic_allowed ? 'Yes' : 'No'}
                        </div>
                    </div>
                </div>
                <div className="second">
                    <div className="form-group">
                        <label>Generic Name</label>
                        <input type="text" value={this.props.medicine.drug_generic_name} disabled={true} className="form-control" />
                    </div>
                    <div className="form-group fixed no-padding-left">
                        <label>Unit Required:</label>
                        {this.props.medicine.remaining_unit + drug_type}
                    </div>
                    <div className="form-group fixed">
                        <label>Min. Sale:</label>
                        {this.props.medicine.minimum_sale && parseInt(this.props.medicine.minimum_sale) + drug_type + " X "}
                    </div>
                    <div className="form-group fixed">
                        <label>Unit Given:</label>
                        <input type="number" className="form-control patient-medicines-unit" name="unit_given" value={Number.isNaN(this.props.medicine.unit_given) ? '' : this.props.medicine.unit_given} onChange={this._handleChange} disabled={this.state.unit_given_disabled} />
                        {(!this.state.unit_given_disabled &&
                            ((this.props.medicine.dosage === 'sos') ||
                                (this.state.over_dosage) || (this.props.prescription_extended_status === 'draft') ?
                                this.props.validator.message('unit_given' + this.props.idx, this.props.medicine.unit_given, 'integer|min:1,num') :
                                this.props.validator.message('unit_given' + this.props.idx, this.props.medicine.unit_given, 'required|integer|min:1,num|max:' + this.props.medicine.required_unit + ',num'))
                        )}
                    </div>
                    <div className="form-group fixed">
                        <label>{t('labels.unit-dispensed')}:</label>
                        {this.props.medicine.unit_dispatched && this.props.medicine.unit_dispatched + drug_type}
                    </div>
                    <div className="form-group fixed">
                        <label>Drug Type</label>
                        {this.props.medicine.drug_type}
                    </div>
                </div>
                <div className="third">
                    <div className="form-group">
                        <label>Dosage Form</label>
                        <select name="dosage_form" className="form-control" data-val={this.props.medicine.drug_type} data-od={''} value={this.props.medicine.dosage_form} disabled={true}>
                            {this.state.drug_dosage_form && this.state.drug_dosage_form.map((index) => (
                                <option value={index.key} data-type={index.type}>{index.label}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group fixed">
                        <label>Coverage</label>
                        <select name="selected_coverages" data-check={this.props.medicine.drug_coverages} className="form-control" value={this.props.medicine.selected_coverages} onChange={this._handleChange} disabled={this.state.unit_given_disabled}>
                            <option value="">No coverage</option>
                            {_.indexOf(this.state.insurance_type, 'jadep') > -1 && _.indexOf(this.props.medicine.drug_coverages, 'jadep') > -1 && <option value="jadep">Jadep</option>}
                        </select>
                    </div>
                </div>
                {this.props.medicine.remarks && <div className="fourth">
                    <div className="form-group-wrapper">
                        <label>Instructions:</label> <span>{this.props.medicine.remarks}</span>
                    </div>
                </div>}
                {hasRole(this.props.role, ["pharmacist"]) && <div className="fourth">
                    <div className="form-group-wrapper">
                        <label>Pharmacist's Remark</label>
                        <textarea className="form-control" name="ordremarks" value={this.props.medicine.ordremarks} onChange={this._handleChange} placeholder="Type remark..." disabled={this.props.disabled}></textarea>
                    </div>
                </div>}
            </li>
        );
    }
}

export default withTranslation()(PharmacyMedicineBlock);
