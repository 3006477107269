import React from 'react';
import PersonalDetails from './PersonalDetails';
import ContactDetails from './ContactDetails';
import { isEmpty } from 'lodash';

const Profile = ({ patient, selected_user_profile, showInsurance=true  }) => {
    return <div className="row">
        <div className="col-md-5">
            <div className="content-wrapper padding-none patient-pic">
                {patient && !isEmpty(patient.profile_image) && <img src={patient.profile_image.thumbnail_url} alt="Patient Profile" />}
            </div>
        </div>
        <div className="col-md-9">
            <PersonalDetails patient={patient} selected_user_profile={selected_user_profile} showInsurance={showInsurance} />
        </div>
        <div className="col-md-10">
            <ContactDetails patient={patient} />
        </div>
    </div>
}
export default Profile