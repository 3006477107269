import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function(state = initialState.pagination, { type, ...payload })
{   
    switch(type){
        case types.DATA_MANAGEMENT_LIST_REQUEST:
            return {
                ...state,
                ...payload,
                isLoading: true
            };
        case types.DATA_MANAGEMENT_LIST_SUCCESS:
            return {
                ...state,
                data: payload.data,
                meta: payload.meta,
                isLoading: false
            };
        case types.DATA_MANAGEMENT_LIST_FAILED:
            return {
                ...state,
                data: [],
                ...payload,
                isLoading: false
            };
        default:
            return state;
    }
            
}