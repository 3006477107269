import React from 'react';

export const Cardiovascular = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <h6>Cardiovascular</h6>
        <input type="checkbox" id='cardiovascular-normal' name='cardiovascular-normal' onChange={e => handleChange(e, 'cardiovascular')} checked={checkIfProblemExist('cardiovascular', 'cardiovascular-normal') ? 'checked' : ''} />
        <label htmlFor='cardiovascular-normal'>Normal</label>
    </React.Fragment>
}
export const Pulse = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <label className="heading">Pulse </label>
        <div className="options-details">
            {/* regular */}
            <input type="checkbox" id='cardiovascular-plus-regular' name='cardiovascular-plus-regular' onChange={e => handleChange(e, 'cardiovascular')} checked={checkIfProblemExist('cardiovascular', 'cardiovascular-plus-regular') ? 'checked' : ''} />
            <label htmlFor='cardiovascular-plus-regular'>Regular</label>
            {/* irregular */}
            <input type="checkbox" id='cardiovascular-plus-irregular' name='cardiovascular-plus-irregular' onChange={e => handleChange(e, 'cardiovascular')} checked={checkIfProblemExist('cardiovascular', 'cardiovascular-plus-irregular') ? 'checked' : ''} />
            <label htmlFor='cardiovascular-plus-irregular'>Irregular</label>
            {/* bounding */}
            <input type="checkbox" id='cardiovascular-plus-bounding' name='cardiovascular-plus-bounding' onChange={e => handleChange(e, 'cardiovascular')} checked={checkIfProblemExist('cardiovascular', 'cardiovascular-plus-bounding') ? 'checked' : ''} />
            <label htmlFor='cardiovascular-plus-bounding'>Bounding</label>
            {/* fast */}
            <input type="checkbox" id='cardiovascular-plus-fast' name='cardiovascular-plus-fast' onChange={e => handleChange(e, 'cardiovascular')} checked={checkIfProblemExist('cardiovascular', 'cardiovascular-plus-fast') ? 'checked' : ''} />
            <label htmlFor='cardiovascular-plus-fast'>Fast</label>
        </div>
    </React.Fragment>
}
export const HS = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <label className="heading">HS </label>
        <div className="options-details">
            {/* s1 */}
            <input type="checkbox" id='cardiovascular-hs-s1' name='cardiovascular-hs-s1' onChange={e => handleChange(e, 'cardiovascular')} checked={checkIfProblemExist('cardiovascular', 'cardiovascular-hs-s1') ? 'checked' : ''} />
            <label htmlFor='cardiovascular-hs-s1'>S1</label>
            {/* s2 */}
            <input type="checkbox" id='cardiovascular-hs-s2' name='cardiovascular-hs-s2' onChange={e => handleChange(e, 'cardiovascular')} checked={checkIfProblemExist('cardiovascular', 'cardiovascular-hs-s2') ? 'checked' : ''} />
            <label htmlFor='cardiovascular-hs-s2'>S2</label>
            {/* s3 */}
            <input type="checkbox" id='cardiovascular-hs-s3' name='cardiovascular-hs-s3' onChange={e => handleChange(e, 'cardiovascular')} checked={checkIfProblemExist('cardiovascular', 'cardiovascular-hs-s3') ? 'checked' : ''} />
            <label htmlFor='cardiovascular-hs-s3'>S3</label>
            {/* s4 */}
            <input type="checkbox" id='cardiovascular-hs-s4' name='cardiovascular-hs-s4' onChange={e => handleChange(e, 'cardiovascular')} checked={checkIfProblemExist('cardiovascular', 'cardiovascular-hs-s4') ? 'checked' : ''} />
            <label htmlFor='cardiovascular-hs-s4'>S4</label>
            {/* murmur */}
            <input type="checkbox" id='cardiovascular-hs-murmur' name='cardiovascular-hs-murmur' onChange={e => handleChange(e, 'cardiovascular')} checked={checkIfProblemExist('cardiovascular', 'cardiovascular-hs-murmur') ? 'checked' : ''} />
            <label htmlFor='cardiovascular-hs-murmur'>Murmur</label>
            {/* thrill */}
            <input type="checkbox" id='cardiovascular-hs-thrill' name='cardiovascular-hs-thrill' onChange={e => handleChange(e, 'cardiovascular')} checked={checkIfProblemExist('cardiovascular', 'cardiovascular-hs-thrill') ? 'checked' : ''} />
            <label htmlFor='cardiovascular-hs-thrill'>Thrill</label>
        </div>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment, checkIfProblemExist }) => {
    return <React.Fragment>
        <label className="heading-comment">Comment </label>
        <textarea className="form-control" name="comments" placeholder="Type here..." disabled={checkIfProblemExist('cardiovascular', 'cardiovascular-normal') ? 'disabled' : ''} onChange={e => handleChange(e, 'cardiovascular')} value={getCategoryComment('cardiovascular') ? getCategoryComment('cardiovascular') : ''}></textarea>
    </React.Fragment>
}