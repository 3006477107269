import React from 'react';
import Card from './Card';

class PrescriptionCount extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="col-md-8">
                    <Card 
                        imgSrc="/images/prescribed-prescription.png" 
                        label="Unfilled Prescription"
                        count={this.props.prescription_count.prescribed_prescription}
                    />
                </div>
                <div className="col-md-8">
                    <Card
                        imgSrc="/images/fulfilled-prescription.png"
                        label="Filled Prescription"
                        count={this.props.prescription_count.fulfilled_prescription}
                    />
                </div>
                <div className="col-md-8">
                    <Card
                        imgSrc="/images/partially-fulfilled-prescription.png"
                        label="Partially Filled Prescription"
                        count={this.props.prescription_count.partially_fulfilled_prescription}
                    />
                </div>
            </React.Fragment>
        )
    }
}
export default PrescriptionCount