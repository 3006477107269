import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as PrescriptionOrderAction from "../../actions/prescriptionOrderAction";
import DataGrid from "../../components/DataGrid";
import * as Helper from "../../utils/helpers";
import SimpleReactValidator from 'simple-react-validator';
import _ from 'lodash';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';

class ListPrescriptionOrderPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: {
        prescription_order_code: '',
        patient_name: '',
        prescription_code: '',
        start_date: '',
        end_date: '',
        show_start_date: new Date(),
        show_end_date: new Date(),
        status: 'fulfilled',
        pos_number: '',
        reference_number: ''
      },
      tags: {
        prescription_order_code: '',
        patient_name: '',
        prescription_code: '',
        start_date: '',
        end_date: '',
        pos_number: '',
        reference_number: ''
      }
    };

    this.validator = new SimpleReactValidator();
    this.handleEvent = this.handleEvent.bind(this);

    this.props.paginate({
      sortBy: { sortField: "updated_at", sortOrder: "desc" },
      filter: this.state.filter
    });
  }

  columns = (user_role) => {
    if (user_role && ['pharmacy', "pharmacy-manager"].includes(user_role)) {
      return [
        {
          dataField: "order_id",
          text: "Rx Id",
          sort: true
        },
        {
          dataField: "prescription_id",
          text: "Prescription Id",
          sort: true
        },
        {
          dataField: "order_date",
          text: "Rx Date",
          sort: true,
          formatter: (cell, row) => {
            return Helper.getDateFormat(cell);
          }
        },
        {
          dataField: "location_name",
          text: "Location",
          sort: false
        },
        {
          dataField: "created_by_full_name",
          text: "Created By",
          sort: false,
        },
        {
          dataField: "total_amount",
          text: "Amount",
          sort: true,
        }
      ];

    } else {
      return [
        {
          dataField: "order_id",
          text: "Rx Id",
          sort: true
        },
        {
          dataField: "prescription_id",
          text: "Prescription Id",
          sort: true
        },
        {
          dataField: "patient_full_name",
          text: "Patient Name",
        },
        {
          dataField: "created_by_full_name",
          text: "Created By",
          sort: false,
        },
        {
          dataField: "status",
          text: "Status",
          sort: true,
          formatter: (cell, row) => {
            return Helper.getStatus(cell);
          }
        },
        {
          dataField: "order_date",
          text: "Rx Date",
          sort: true,
          formatter: (cell, row) => {
            return Helper.getDateFormat(cell);
          }
        },
        {
          dataField: "actions",
          text: "Actions",
          isDummyField: true,
          formatter: this.actionFormatter
        }
      ];
    }
  };

  handleChange = (e) => {
    const filter = this.state.filter;
    filter[e.target.name] = e.target.value ? e.target.value : undefined;
    this.setState({ filter });
  }

  actionFormatter = (cell, row) => {
    return (
      <Link to={"/prescription/order/" + row.order_id}>
        <span className="icon file"></span>
      </Link>
    );
  };

  locationName = (cell, row) => {
    if (row.order_location && row.order_location.location_name) {
      return row.order_location.location_name;
    }
    return '-';
  }

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText, filter }) => {
    if (type === "search") {
      page = 1;
    }
    this.props.paginate({ page, sizePerPage, sortBy: { sortField: "updated_at", sortOrder: "desc" }, searchText, filter: this.state.filter });
  };

  resetSearch = () => {
    const filters = {
      prescription_order_code: '',
      patient_name: '',
      prescription_code: '',
      start_date: '',
      end_date: '',
      show_start_date: new Date(),
      show_end_date: new Date(),
      status: 'fulfilled',
      pos_number: '',
      reference_number: ''
    }

    this.setState({
      filter: filters,
      tags: {
        prescription_order_code: '',
        patient_name: '',
        prescription_code: '',
        start_date: '',
        end_date: '',
        pos_number: '',
        reference_number: ''
      }
    });

    window['searchToggle']();

    this.props.paginate({
      sortBy: { sortField: "updated_at", sortOrder: "desc" },
      filter: filters
    });
  }

  handleEvent(event, picker) {

    this.setState({
      filter: {
        prescription_order_code: this.state.filter.prescription_order_code,
        patient_name: this.state.filter.patient_name,
        prescription_code: this.state.filter.prescription_code,
        start_date: (picker.startDate._d) ? moment(picker.startDate._d).format('YYYY-MM-DD') : '',
        end_date: (picker.endDate._d) ? moment(picker.endDate._d).format('YYYY-MM-DD') : '',
        show_start_date: picker.startDate._d,
        show_end_date: picker.endDate._d,
        pos_number: this.state.filter.pos_number,
        reference_number: this.state.filter.reference_number
      }
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return false;
    }

    /* Reference of external javascript function  */
    window['searchToggle']();

    this.setState({
      tags: {
        prescription_order_code: this.state.filter.prescription_order_code,
        patient_name: this.state.filter.patient_name,
        prescription_code: this.state.filter.prescription_code,
        start_date: this.state.filter.start_date,
        end_date: this.state.filter.end_date,
        pos_number: this.state.filter.pos_number,
        reference_number: this.state.filter.reference_number
      }
    });

    this.props.paginate({ filter: this.state.filter });
  }

  removeFilter = (e) => {

    const filter = this.state.filter;
    const tags = this.state.tags;

    filter[e.target.dataset.tag] = '';
    tags[e.target.dataset.tag] = '';

    this.setState({ filter: filter, tags: tags });

    this.props.paginate({ sortBy: { sortField: "updated_at", sortOrder: "desc" }, filter: this.state.filter });
  }

  render() {
    let { data, meta } = this.props.prescription_orders_list;
    let tags = this.state.tags;

    return (
      <React.Fragment>
        <Helmet>
          <title>Rx Order</title>
        </Helmet>
        <div className="row">
          <div className="col-md-24">
            <h4 className="page-title">Rx Order</h4>
            <div className="search-wrapper">
              <label>Search</label>
              <div className="search-box clearfix">
                {(Object.keys(tags)).map((tag) => {
                  if (tag !== 'doctor_id' && tags[tag] !== '' && !(_.isEmpty(tags[tag]))) {
                    return <span className="search-list">{tags[tag]} <span className='removesearch' data-tag={tag} onClick={this.removeFilter.bind(this)}> x </span> </span>
                  }
                  return <></>
                })
                }
                <span className="search-box-icon"></span>

                <div className="search-container">
                  <form onSubmit={this.handleSubmit}>
                    <ul>
                      <li>
                        <span>Prescription Rx Code</span>
                        <input type="text" value={this.state.filter.prescription_order_code} onChange={this.handleChange} name="prescription_order_code"></input>
                        {this.validator.message('prescription_order_code', this.state.filter.patient_code, 'alpha_num')}
                      </li>
                      {this.props.user_role && (this.props.user_role !== 'pharmacy') && <li>
                        <span>Patient Name</span>
                        <input type="text" value={this.state.filter.patient_name} onChange={this.handleChange} name="patient_name"></input>
                        {this.validator.message('patient_name', this.state.filter.patient_name, 'alpha_num_space')}
                      </li>}
                      <li>
                        <span>Prescription Code</span>
                        <input type="text" value={this.state.filter.prescription_code} onChange={this.handleChange} name="prescription_code"></input>
                        {this.validator.message('prescription_code', this.state.filter.prescription_code, 'alpha_num')}
                      </li>
                      <li>
                        <span>POS Number</span>
                        <input type="text" value={this.state.filter.pos_number} onChange={this.handleChange} name="pos_number"></input>
                        {this.validator.message('POS Number', this.state.filter.pos_number, 'alpha_num')}
                      </li>
                      <li>
                        <span>Reference Number</span>
                        <input type="text" value={this.state.filter.reference_number} onChange={this.handleChange} name="reference_number"></input>
                        {this.validator.message('Reference Number', this.state.filter.reference_number, 'alpha_num')}
                      </li>
                      <li>
                        <span>Date</span>
                        <DateRangePicker startDate={this.state.filter.show_start_date} onApply={this.handleEvent} endDate={this.state.filter.show_end_date} >
                          <input type="text" className='search-input' value={this.state.filter.start_date} name="start_date" autoComplete='off'></input>
                          <input type="text" className='search-input' value={this.state.filter.end_date} name="end_date" autoComplete='off'></input>
                        </DateRangePicker>
                      </li>
                      <li>
                        <button type="button" onClick={this.resetSearch} className="btn btn-secondary">Reset</button>
                        <button type="submit" className="btn btn-primary">Search</button>
                      </li>
                    </ul>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-wrapper">
          {data && (
            <DataGrid
              columns={this.columns(this.props.user_role)}
              data={data}
              meta={meta}
              handleTableChange={this.handleTableChange}
              noDataIndication="No Record Found"
              
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.session.user,
    prescription_orders_list: state.prescription_order.paginate,
    user_role: state.selected_user_profile.role_slug
  };
}

const mapActionsToProps = {
  paginate: PrescriptionOrderAction.list
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ListPrescriptionOrderPage);
