import React from 'react';
import { connect } from "react-redux";
import DataGrid from "../../../components/DataGrid";
import { Accordion, Card, Button } from 'react-bootstrap';
import PrescriptionService from '../../../services/prescriptionService';
import Notification from '../../../utils/notification';
import ReviewOfSystem from '../Examination/components/AddEdit/AddReviewOfSystem';
import * as Helper from "../../../utils/helpers";
import * as PrescriptionAction from '../../../actions/prescriptionAction'

class AddPresentingCondition extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            present_condition: {},
            review_of_system: [],
        }
    }
    componentDidMount = () => {
        this.props.showLoader();
        PrescriptionService.getPatientPresentCondition(this.props.prescriptionId).then(resp => {
            this.setState({
                present_condition: {
                    symptoms: resp.data.symptoms,
                    patient_id: this.props.patientId
                },
                review_of_system: resp.data.review_of_system ? resp.data.review_of_system : [],
            })
            this.props.hideLoader();
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data)
        })
        this.props.paginate({ sortBy: { sortField: "created_at", sortOrder: "desc" } }, this.props.prescriptionId)
        Helper.setAutoSaveWithInterval(this.props.selected_user_profile, this.handlePresentConditionSaveAndNext)
    }

    componentWillUnmount() {
        Helper.removeAutoSaveWithInterval()
    }
    handlePresentConditionSaveAndNext = (e = null, type = null) => {
        e && e.preventDefault();
        type = e ? type : "auto_save";
        e && this.props.showLoader();
        let { present_condition } = this.state;
        present_condition['review_of_system'] = this.state.review_of_system;
        PrescriptionService.updatePatientPresentCondition(this.props.prescriptionId, present_condition).then(_resp => {
            e && this.props.hideLoader();
            type !== "auto_save" && this.props.handleSaveAndNext();
        }).catch(error => {
            e && this.props.hideLoader();
            Notification.show('error', error.response.data)
        })
    }
    changeHandler = (e) => {
        let { present_condition } = this.state;
        present_condition[e.target.name] = e.target.value;
        this.setState({ present_condition: present_condition });
        this.props.handleIsPrescriptionEdited('present_condition')
    }

    handleReviewSystem = (review_system) => {
        this.setState({ review_of_system: review_system });
        this.props.handleIsPrescriptionEdited('present_condition')
    }

    handleUploadedFiles = (e) => {
        e.preventDefault();
        const files = e.target.files;
        let validated = false;

        validated = this.validation(files);
        if (validated) {
            let formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                formData.append(`files[${i}]`, files[i]);
            }
            this.props.showLoader();

            PrescriptionService.uploadPresentingConditionImage(this.props.prescriptionId, formData).then(_resp => {
                this.props.hideLoader();
                Notification.show('success', { message: 'Image has been uploaded successfully' });
                this.props.paginate({ sortBy: { sortField: "created_at", sortOrder: "desc" } }, this.props.prescriptionId)
            }).catch(error => {
                this.props.hideLoader();
                Notification.show('error', error.response.data)
            })
        }
    }

    validation = (files) => {
        for (let i = 0; i < files.length; i++) {
            if (files[i].size > 5120000) {
                Notification.show('error', { message: 'Image  "' + files[i].name + '" size should not be more than 5 MB' });
                return false;
            }
        }
        return true;
    }
    columns = () => {
        return [{
            dataField: "uploaded_at",
            text: "Upload Date",
            formatter: this.dateFormatter
        },
        {
            dataField: 'file_name',
            text: 'Image Name',
        },]
    }
    dateFormatter = (cell) => {
        if (cell) {
            return Helper.getDateFormat(cell);
        }
        return '--'
    }

    handleTableChange = (type, { page, sizePerPage, sortField = "created_at", sortOrder = "desc", searchText }) => {
        if (type === "search") {
            page = 1;
        }

        this.props.paginate({ page, sizePerPage, sortBy: { sortField, sortOrder }, searchText }, this.props.prescriptionId);
    };
    render() {
        return (
            <div className=" lg-height">
                <div className="form-group full examination-wrapper content-wrapper">
                    <label className="heading-comment d-inline">Mention Symptoms </label>
                    <input type="file" ref={fileInput => this.fileInput = fileInput} onChange={this.handleUploadedFiles} name="files" id="file" hidden multiple accept='.jpg,.png,.jpeg' />
                    <button onClick={() => this.fileInput.click()} className="float-right btn btn-outline-primary p-2" name="file" id="file">Upload Image</button>
                    <textarea className="form-control height" name="symptoms" placeholder="Type here..." onChange={this.changeHandler} value={this.state.present_condition.symptoms}></textarea>
                </div>
                <div className="content-wrapper">
                    <DataGrid
                        columns={this.columns()}
                        noDataIndication="No Record Found"
                        data={this.props.presenting_condition_image.paginate.data}
                        meta={this.props.presenting_condition_image.paginate.meta}
                        handleTableChange={this.handleTableChange}
                    />
                </div>
                <Accordion>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="1">Review of Systems</Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    <ReviewOfSystem
                                        handleReviewSystem={this.handleReviewSystem}
                                        review_of_system={this.state.review_of_system}
                                    />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                <div className="text-right bottom-btns">
                    <button className="btn btn-primary" onClick={e => this.handlePresentConditionSaveAndNext(e, 'save_and_next')}>Save & Next</button>
                    <button className="btn btn-default btn-outline-primary" onClick={this.props.handlePrevious}>Previous</button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    presenting_condition_image: state.prescription
});

const mapActionsToProps = {
    paginate: PrescriptionAction.getPresentingConditionImage
};

export default connect(mapStateToProps, mapActionsToProps)(AddPresentingCondition)