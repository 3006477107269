import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import PatientInfoSection from '../../components/AmbulanceRecord/PatientInfoSection'
import MedicalInfoSection from '../../components/AmbulanceRecord/MedicalInfoSection'
import MedicationSection from '../../components/AmbulanceRecord/MedicationSection'
import CurrentMedicationSection from "../../components/AmbulanceRecord/CurrentMedicationSection"
import { PatientStatusSection, PatientConditionSection, NoteSection, SelectHospitalSection } from "../../components/AmbulanceRecord/AddEditFormSection"
import * as LoaderAction from '../../actions/loaderAction'
import * as AmbulanceAction from '../../actions/ambulanceAction'
import AmbulanceService from '../../services/ambulanceService'
import Notification from '../../utils/notification'
import SimpleReactValidator from 'simple-react-validator';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import _ from "lodash";

export class EditRecordPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            patientId: null,
            isLoading: true,
            patient: null,
            prescriptions: null,
            showModal: false,
            hospital_change: false,
            form: {
                patient_status: null,
                patient_condition: null,
                notes: null,
                medicines: [],
                hospital: []
            },
        };

        this.validator = new SimpleReactValidator();
    }

    componentDidMount() {
        const params = this.props.match.params;
        this.setState({
            recordId: params.recordId
        })
        this.props.getRecord(params.recordId, { log: true });
    }

    componentWillReceiveProps(props) {
        this.setState({
            record_id: props.ambulance.record_id,
            patient_id: props.ambulance.patient_id,
            patient: props.ambulance.patient_info,
            prescriptions: props.ambulance.current_prescriptions,
            hospital_service_provider_id: props.ambulance.hospital_service_provider_id,
            form: {
                patient_status: props.ambulance.patient_status,
                patient_condition: props.ambulance.patient_condition,
                notes: props.ambulance.notes,
                medicines: props.ambulance.medicines,
                hospital: {
                    service_provider_id: props.ambulance.hospital_service_provider_id,
                    service_provider_name: props.ambulance.hospital_service_provider_name,
                    user_location_id: props.ambulance.hospital_user_location_id,
                    user_location_name: props.ambulance.hospital_user_location_name
                }
            },
            isLoading: false
        })
    }

    onChange = (key, value) => {
        this.setState({
            form: {
                ...this.state.form,
                [key]: value
            }
        })
    }

    // Start Medicine Block Events
    handleMedicineChange = (idx, data) => {
        let { form } = this.state;
        const newMedicines = form.medicines.map((medicine, sidx) => {
            if (idx !== sidx) return medicine;

            return { ...medicine, ...data };
        });
        form['medicines'] = newMedicines;
        this.setState({ form });
    };
    handleAddMedicine = () => {
        let { form } = this.state;
        form['medicines'] = form.medicines.concat([{
            drug_id: '',
            drug_name: '',
            drug_generic_id: '',
            drug_generic_name: '',
            remarks: '',
        }]);
        this.setState({ form });
    };
    handleRemoveMedicine = (event) => {
        let { form } = this.state;
        const idx = Number(event.target.dataset['id']);
        form['medicines'] = form.medicines.filter((s, sidx) => idx !== sidx);
        this.setState({ form });
    };
    //End medicine block

    handleSearchHospital = (data) => {
        let form = this.state.form;
        form['hospital'] = {
            service_provider_id: data.service_provider_id,
            service_provider_name: data.service_provider_name,
            user_location_id: data.user_location_id,
            user_location_name: data.user_location_name
        };

        if (!_.isEmpty(data.service_provider_name)) {
            this.setState({
                showModal: true
            });
        }
        this.setState({ form });
    }

    handleSubmit = e => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        let postData = {
            patient_id: this.state.patient_id,
            patient_status: this.state.form.patient_status,
            patient_condition: this.state.form.patient_condition,
            medicines: this.state.form.medicines,
            notes: this.state.form.notes,
            hospital_service_provider_id: this.state.form.hospital.service_provider_id,
            hospital_user_location_id: this.state.form.hospital.user_location_id,
        }

        this.props.showLoader();
        AmbulanceService.updateRecord(this.state.record_id, postData)
            .then(response => {
                this.props.hideLoader();
                Notification.show('success', { message: "Record updated successfully" })
                this.props.history.push('/ambulance/records');
            })
            .catch(error => {
                this.props.hideLoader();
                if (error.response) {
                    Notification.show('error', error.response.data)
                }
            });
    }

    handleClose = (action) => {
        if (action === 'no') {
            let form = this.state.form;
            form['hospital'] = {
                service_provider_id: this.props.ambulance.hospital_service_provider_id,
                service_provider_name: this.props.ambulance.hospital_service_provider_name,
                user_location_id: this.props.ambulance.hospital_user_location_id,
                user_location_name: this.props.ambulance.hospital_user_location_name
            };
            this.setState({ form });
        }
        this.setState({
            showModal: false
        });
    }

    render() {
        this.validator.purgeFields();
        return (
            <React.Fragment>
                <Helmet>
                    <title>Ambulance Patient Profile</title>
                </Helmet>
                <section className="container-fluid">
                    <div className="row">
                        <div className="col-md-24">
                            <h4 className="page-title"><span>Patient Profile</span></h4>
                        </div>
                    </div>
                    <PatientInfoSection patient={this.state.patient} />
                    <MedicalInfoSection patient={this.state.patient} />
                    <CurrentMedicationSection prescriptions={this.state.prescriptions} />
                    <div className="row">
                        <div className="col-24">
                            <PatientStatusSection validator={this.validator} value={this.state.form.patient_status} onChange={this.onChange} />
                            <PatientConditionSection value={this.state.form.patient_condition} onChange={this.onChange} />
                            <div className="content-wrapper pb-4">
                                <h4 className="wrapper-heading">Ambulance Treatment (Medicine Details)</h4>
                                <ul className="prescription-patient-details medicine-details">
                                    {this.state.form.medicines && this.state.form.medicines.map((medicine, idx) => (
                                        <MedicationSection
                                            key={idx}
                                            medicine={medicine}
                                            idx={idx}
                                            handleChangeMedicine={this.handleMedicineChange}
                                            handleRemoveMedicine={this.handleRemoveMedicine}
                                            validator={this.validator}
                                        />
                                    ))}
                                </ul>
                                <button onClick={this.handleAddMedicine} className="btn btn-primary float-right">Add</button>
                            </div>
                            <NoteSection value={this.state.form.notes} onChange={this.onChange} />
                            <SelectHospitalSection hospital={this.state.form.hospital} validator={this.validator} handleSearchHospital={this.handleSearchHospital} />
                            <div className="text-right">
                                <button className="btn btn-primary" onClick={e => this.handleSubmit(e)}>Submit</button>
                                <button className="btn btn-secondary" onClick={() => this.props.history.goBack()}>Cancel</button>
                            </div>
                            <Modal
                                size='sm' centered aria-labelledby="contained-modal-title-vcenter"
                                show={this.state.showModal}
                                onHide={this.handleClose}
                                backdrop="static"
                            >
                                <Modal.Body>
                                    <div className="user-popup-icon text-center content-wrapper">
                                        <img src="/images/profile.png" alt="Profile" />
                                        <h4>Hospital Changes</h4>
                                        <p>Do you want to change Hospital, if you do so then the previous request will be cancelled</p>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="primary" onClick={this.handleClose.bind(this, 'yes')}>
                                        Yes
                                    </Button>
                                    <Button variant="secondary" onClick={this.handleClose.bind(this, 'no')}>
                                        No
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        ambulance: state.ambulance_fetch.data,
    };
}

const mapDispatchToProps = {
    hideLoader: LoaderAction.loaderHide,
    showLoader: LoaderAction.loaderShow,
    getRecord: AmbulanceAction.get,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditRecordPage)
