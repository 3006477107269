import React from 'react';
import { Helmet } from "react-helmet";
import moment from "moment";
import { Tab, Tabs, TabPane } from "react-bootstrap";
import AppointmentService from '../../services/appointmentService';
import * as SearchSlotComponent from '../../components/Slot/SearchSlotComponent';
import { connect } from 'react-redux';
import * as loaderAction from '../../actions/loaderAction';
import _ from 'lodash';
import * as ServiceProviderLocationAction from '../../actions/serviceProviderLocationAction';
import SimpleReactValidator from "simple-react-validator";
import CancelSlotButton from '../../components/Slot/CancelSlotModal';
import Notification from '../../utils/notification'
import { Link } from 'react-router-dom';

class SearchSlotPage extends React.Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            filter: {
                doctor_profile_id: '',
                user_location_id: [],
                to: '',
                from: ''
            },
            selected_location: '',
            tags: {
                location_filter: ""
            },
            doctors: '',
            show_slots: false,
            is_filter: '',
            cancel_slot_modal: false
        }
    }
    componentDidMount() {
        const { selected_user_profile } = this.props;
        this.props.showLoader();
        this.props.getLocations(selected_user_profile)
        AppointmentService.getDoctorByLocation(selected_user_profile.user_location_id).then(resp => {
            this.setState({
                doctors: resp.data,
            })
            this.props.hideLoader();
        }).catch(_error => {
            this.props.hideLoader();
            Notification.show('error', { message: 'Something went wrong on location change.' })
        })
    }
    handleDoctorChange = (evt) => {
        this.setState({
            filter: {
                doctor_profile_id: evt.target.value,
                user_location_id: [],
                to: '',
                from: ''
            },
            selected_location: '',
            tags: {
                location_filter: ""
            },
            is_filter: '',
            show_slots: false
        })
    }
    handleSearchSlot = (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        } else {
            this.setState({
                show_slots: true
            })
        }
    }
    handleSearch = (e) => {
        e.preventDefault();
        let { filter, tags, selected_location } = this.state
        const { locations } = this.props.service_provider_locations;
        if (selected_location) {
            filter.user_location_id = _.filter(locations, function (location) {
                if (selected_location === location.user_location_id) return location.user_location_id
            })
            tags['location_filter'] = filter.user_location_id[0].name
        }
        this.setState({ filter, tags, is_filter: 'search' })
        window['searchToggle']();
    }
    handleLocationFilter = (e) => {
        let { selected_location } = this.state;
        let target = e.target;
        selected_location = target.value ? target.value : '';

        this.setState({ selected_location });
    }
    renderTags = () => {
        let { tags } = this.state
        let options;
        return (Object.keys(tags)).map((tag) => {
            if (tags[tag] !== '' && !(_.isEmpty(tags[tag]))) {
                options = <span className="search-list">{tags[tag]} <span className='removesearch' data-tag={tag} onClick={(e) => this.removeFilter(e)}> x </span> </span>
            } else {
                return false
            }
            return options
        });
    }
    removeFilter = (e) => {
        let { filter, tags, selected_location, is_filter } = this.state;
        if (e.target.dataset.tag === 'date_filter') {
            filter["from"] = '';
            filter["to"] = '';
            is_filter = 'remove_date'
        } else if (e.target.dataset.tag === 'location_filter') {
            filter["user_location_id"] = [];
            selected_location = '';
            is_filter = 'remove_location'
        } else {
            filter[e.target.dataset.tag] = '';
        }
        tags[e.target.dataset.tag] = '';
        this.setState({ filter, tags, selected_location, is_filter });
    }
    handleEvent = (_event, picker) => {
        const { filter, tags } = this.state;
        filter.from = (picker.startDate._d) ? moment(picker.startDate._d).format('YYYY-MM-DD') : '';
        filter.to = (picker.endDate._d) ? moment(picker.endDate._d).format('YYYY-MM-DD') : '';
        filter.show_start_date = picker.startDate._d;
        filter.show_end_date = picker.endDate._d;
        tags.date_filter = (picker.startDate._d && picker.endDate._d) ? moment(picker.startDate._d).format('DD-MM-YYYY') + ' to ' + moment(picker.endDate._d).format('DD-MM-YYYY') : '';

        this.setState({
            filter, tags,
        })
    }
    resetSearch = () => {
        let { filter, tags, selected_location, is_filter } = this.state;
        filter.user_location_id = [];
        filter.from = '';
        filter.to = '';
        selected_location = '';
        filter.show_start_date = new Date();
        filter.show_end_date = new Date();
        tags.date_filter = '';
        tags.location_filter = '';
        is_filter = 'reset'
        this.setState({ filter, tags, selected_location, is_filter });
        window['searchToggle']();
    }
    handleCancelSlotModal = (status = null) => {
        this.setState({ cancel_slot_modal: !this.state.cancel_slot_modal })
        if (status) {
            this.props.history.go(0);
            Notification.show('success', { message: 'Current slot deleted successfully.' });
        }
    }
    getSelectedDoctor = () => {
        let { doctors, filter: { doctor_profile_id } } = this.state
        return _.filter(doctors, function (item) {
            if (item.user_profile_id === doctor_profile_id) return item
        })
    }
    resetFilterType = () => {
        this.setState({ is_filter: '' })
    }

    render() {
        const { filter, show_slots } = this.state;
        const { locations } = this.props.service_provider_locations;
        let location_details = (!_.isEmpty(filter.user_location_id)) ? filter.user_location_id : locations
        return (
            <React.Fragment >
                <Helmet>
                    <title>Slot Management</title>
                </Helmet>
                <div className="row">
                    <div className="col-md-24">
                        <h4 className="page-title">
                            <span>Slot Management</span>
                            <button onClick={() => this.handleCancelSlotModal()} className="btn btn-default btn-outline-primary">Cancel Slot</button>
                            <Link to="/slots/create" className="btn btn-primary ml-2">Create Slot</Link>
                        </h4>
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-17">
                                    <SearchSlotComponent.SelectDoctor doctors={this.state.doctors}
                                        doctor_profile_id={filter.doctor_profile_id}
                                        handleChange={(e) => this.handleDoctorChange(e)}
                                        handleSearch={this.handleSearchSlot} />
                                    {this.validator.message("doctor", filter.doctor_profile_id, "required")}
                                </div>
                            </div>
                        </div>
                        {show_slots && <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-24 mt-3 requester-tabs">
                                    <Tabs defaultActiveKey="ExistingSlot">
                                        <Tab eventKey="ExistingSlot" title="Existing Slot" >
                                            <TabPane>
                                                <div className="search-wrapper mt-3">
                                                    <label>Search</label>
                                                    <div className="search-box clearfix">
                                                        {this.state.tags && this.renderTags()}
                                                        <span className="search-box-icon"></span>
                                                        <div className="search-container">
                                                            <SearchSlotComponent.Search
                                                                selected_location={this.state.selected_location}
                                                                filter={filter}
                                                                locations={locations}
                                                                handleEvent={this.handleEvent}
                                                                handleSearch={this.handleSearch}
                                                                handleLocationChange={this.handleLocationFilter}
                                                                resetSearch={this.resetSearch} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {location_details.length > 0 && location_details.map(location => (
                                                    <SearchSlotComponent.Slots
                                                        key={location.user_location_id}
                                                        location={location}
                                                        filter={filter}
                                                        showLoader={this.props.showLoader}
                                                        hideLoader={this.props.hideLoader}
                                                        history={this.props.history}
                                                        is_filter={this.state.is_filter}
                                                        selected_doctor={this.getSelectedDoctor()}
                                                        resetFilterType={this.resetFilterType}
                                                    />
                                                ))}
                                            </TabPane>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>}

                    </div>
                </div>
                {this.state.cancel_slot_modal && <CancelSlotButton
                    showHide={this.state.cancel_slot_modal}
                    handleModalShowHide={this.handleCancelSlotModal}
                    selected_doctor={this.state.doctors}
                    doctor_profile_id={this.state.doctor_profile_id}
                    doctor_disabled={false}
                    locations={locations} />}
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state) => ({
    selected_user_profile: state.selected_user_profile,
    service_provider_locations: state.service_provider_locations
});
const mapActionsToProps = ({
    showLoader: loaderAction.loaderShow,
    hideLoader: loaderAction.loaderHide,
    getLocations: ServiceProviderLocationAction.getLocations
})
export default connect(mapStateToProps, mapActionsToProps)(SearchSlotPage);