import initialState from './initialState';

export default function (state = initialState.pagination, { type, ...payload }) {
    switch (type) {
        case 'FETCH_AMBULANCE_RECORD_REQUEST':
            return {
                ...state,
                ...payload
            };
        case 'FETCH_AMBULANCE_RECORD_SUCCESS':
            return {
                ...state,
                ...payload
            };
        case 'FETCH_AMBULANCE_RECORD_FAILED':
            return {
                ...state,
                ...payload
            }; 
        default:
            return state;
    }
}