import React from 'react';

export const Nose = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <h6>Nose </h6>
        <input type="checkbox" id='nose-normal' name='nose-normal' onChange={e => handleChange(e, 'nose')} checked={checkIfProblemExist('nose', 'nose-normal') ? 'checked' : ''} />
        <label htmlFor='nose-normal'>Normal</label>
    </React.Fragment>
}
export const NoseOptions = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <div className="options-details">
            <input type="checkbox" id='nose-polyps' name='nose-polyps' onChange={e => handleChange(e, 'nose')} checked={checkIfProblemExist('nose', 'nose-polyps') ? 'checked' : ''} />
            <label htmlFor='nose-polyps'>Polyps</label>
            <input type="checkbox" id='nose-ulcer' name='nose-ulcer' onChange={e => handleChange(e, 'nose')} checked={checkIfProblemExist('nose', 'nose-ulcer') ? 'checked' : ''} />
            <label htmlFor='nose-ulcer'>Ulcer</label>
            <input type="checkbox" id='nose-septal-defect' name='nose-septal-defect' onChange={e => handleChange(e, 'nose')} checked={checkIfProblemExist('nose', 'nose-septal-defect') ? 'checked' : ''} />
            <label htmlFor='nose-septal-defect'>Septal defect</label>
        </div>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment, checkIfProblemExist }) => {
    return <React.Fragment>
        <label className="heading-comment">Comment </label>
        <textarea className="form-control" name="comments" placeholder="Type here..." onChange={e => handleChange(e, 'nose')} disabled={checkIfProblemExist('nose', 'nose-normal') ? 'disabled' : ''} value={getCategoryComment('nose') ? getCategoryComment('nose') : ''}></textarea>
    </React.Fragment >
}