import React from 'react';
import OfflinePrescriptionService from '../../services/offlinePrescriptionService';
import _ from 'lodash';
import { Helmet } from 'react-helmet';
import PharmacyMedicineBlock from '../../components/PharmacyMedicineBlock';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import Notification from '../../utils/notification';
import * as LoaderAction from "../../actions/loaderAction";
import PrescriptionOrderService from '../../services/prescriptionOrderService';
import moment from 'moment';

class CreateOfflineOrders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            offline_prescription_id: this.props.match.params.id,
            pos: '',
            madePayment: true,
            ref_number: '',
            pos_validation_msg: '',
            reference_validation_msg: '',
        }

        this.validator = new SimpleReactValidator();
        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        OfflinePrescriptionService.getofflinePresciptionById(id).then(resp => {
            this.setState({
                data: resp.data
            })
        }).catch(error => {
            Notification.show('error', error.response)
        })
    }

    handleChange(e) {
        if (e.target.name === "pos") {
            this.setState({
                pos_validation_msg: '',
            })
        }
        if (e.target.name === "ref_number") {
            this.setState({
                reference_validation_msg: '',
            })
        }
        this.setState({ [e.target.name]: e.target.value });
    }

    handleClick() {
        this.setState({
            madePayment: !this.state.madePayment
        })
    }

    handleMedicineChange = (idx, data) => {
        let newMedicines = this.state.data.prescription_info.map((medicine, sidx) => {
            if (idx !== sidx)
                return medicine;
            return { ...medicine, ...data };
        });

        const prescription = {
            ...this.state.data,
            prescription_info: newMedicines
        }
        this.setState({ data: prescription });
    };

    getDrugSkus = (medicine) => {
        if (medicine.drug_id) {
            OfflinePrescriptionService.getDrugSkus(medicine.drug_id).then(resp => {
                return medicine.drug_skus = resp.data
            }).catch(error => {
                console.log(error.response.data)
            })

        }
    }

    handleSubmit = (e) => {
        e.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        let checkMedicinesGiven = _.filter(this.state.data.prescription_info, _.matches({ is_given: 'yes' }));
        if ((Object.keys(checkMedicinesGiven).length) <= 0) {
            Notification.show('warning', { message: 'Please select medicines for order.' });
            return false;
        }

        let sumUnitGiven = _.sumBy(this.state.data.prescription_info, 'unit_given');
        if (sumUnitGiven === undefined || sumUnitGiven <= 0) {
            return false;
        }

        this.state.madePayment === false ? this.createOrder(checkMedicinesGiven) : Notification.show('info', { message: 'Confirm your payment status.' })
    }

    async createOrder(checkMedicinesGiven) {
        this.props.loaderShow();
        Promise.all([this.checkPosValidity(), this.checkReferenceNumberValidity()]).then(resp => {
            if (resp.every(x => x === "available") === true) {
                this.props.loaderShow();
                const prescriptionOrder = this.state.data;
                prescriptionOrder.pos_ref_number = this.state.pos;
                prescriptionOrder.reference_number = this.state.ref_number;
                prescriptionOrder.order_info = checkMedicinesGiven;
                prescriptionOrder.order_type = "pharmacy";
                prescriptionOrder.order_date = moment().format("YYYY-MM-DD");
                OfflinePrescriptionService.createPharmacyOrder(prescriptionOrder).then(resp => {
                    this.props.loaderHide();
                    Notification.show('success', resp.data)
                    this.props.history.push('/offline/prescriptions');
                }).catch(error => {
                    this.props.loaderHide();
                    Notification.show('error', error.response.data)
                });
            }
            this.props.loaderHide();
        }).catch(error => {
            this.props.loaderHide();
            Notification.show('error', error.response.data)
        });
    }

    checkPosValidity = () => {
        return new Promise((resolve, reject) => {
            PrescriptionOrderService.checkPosNumber({ 'pos_number': this.state.pos }).then(res => {
                if (res.data.status === 'not_available') {
                    this.setState({
                        pos_validation_msg: 'POS number already exists',
                        is_pos_valid: false
                    });
                }

                if (res.data.status === 'available') {
                    this.setState({
                        pos_validation_msg: '',
                        is_pos_valid: true
                    });
                }
                return resolve(res.data.status)
            }).catch(error => {
                this.setState({
                    pos_validation_msg: '',
                    is_pos_valid: true
                });
                Notification.show('error', error.response.data);
                return reject(error);
            });
        })
    }

    checkReferenceNumberValidity = () => {
        return new Promise((resolve, reject) => {
            PrescriptionOrderService.checkReferenceNumber({ 'reference_number': this.state.ref_number }).then(res => {
                if (res.data.status === 'not_available') {
                    this.setState({
                        reference_validation_msg: 'reference number already exists',
                        is_ref_num_valid: false
                    });
                }

                if (res.data.status === 'available') {
                    this.setState({
                        reference_validation_msg: '',
                        is_ref_num_valid: true
                    });
                }
                return resolve(res.data.status)
            }).catch(error => {
                this.setState({
                    reference_validation_msg: '',
                    is_ref_num_valid: true
                });
                Notification.show('error', error.response.data)
                return reject(error);
            });
        })
    }

    render() {
        const { data } = this.state

        return (data && <React.Fragment>
            <Helmet>
                <title>Offline Order</title>
            </Helmet>
            <div className="row">
                <div className="col-md-24">
                    <h4 className="page-title">Offline Order</h4>

                    <div className="row">
                        <div className="col-md-24">
                            <div className="content-wrapper">
                                {!_.isEmpty(data) && <ul className="patient-details">
                                    <li>Patient ID: <span>{data.patient_info && data.patient_id}</span></li>
                                    <li>Name: <span>{data.patient_info && data.patient_info.patient_name}</span></li>
                                    <li>Age: <span>{data.patient_info && data.patient_info.age}</span></li>
                                    <li>Gender: <span>{data.patient_info && _.capitalize(data.patient_info.gender)}</span></li>
                                    <li>Contact Number: <span>{data.patient_info && (data.patient_info.contact_number)}</span></li>
                                </ul>}
                            </div>
                        </div>
                        <div className="col-md-24">
                            <h4 className="page-title">Doctor Info</h4>
                            <div className="content-wrapper">
                                {!_.isEmpty(data) && <ul className="patient-details">
                                    <li>Doctor ID: <span>{data && data.doctor_id}</span></li>
                                    {<li>Doctor Name: <span>{data && data.doctor_info.doctor_name}</span></li>}
                                    {<li>Registration No.: <span>{data && data.doctor_info.license_no}</span></li>}
                                    {<li>Contact Number: <span>{data && data.doctor_info.doctor_contact_number}</span></li>}
                                    {<li>Email: <span>{data && data.doctor_info.email}</span></li>}
                                </ul>}
                            </div>
                        </div >
                    </div>
                    <h4 className="page-title font-size-16">Prescribed Medicine</h4>
                    <form>
                        <div className="content-wrapper">
                            <ul className="medicine-list">
                                {!_.isEmpty(data.prescription_info) && data.prescription_info.map((medicine, idx) => (
                                    <PharmacyMedicineBlock
                                        medicine={medicine}
                                        idx={idx}
                                        handleChangeMedicine={this.handleMedicineChange}
                                        validator={this.validator}
                                        patientInsurance={data.patient_info.insurance_type}
                                        role={this.props.role}
                                    />
                                ))}
                            </ul>
                        </div>

                        <div className="content-wrapper">
                            <ul className="payment-confirmed">
                                <li>Payment Confirmed</li>
                                <li><input type="checkbox" id="payment" name="payment_done" onChange={this.handleClick} value="" />
                                    <label htmlFor="payment"></label>
                                </li>
                                <li>
                                    <label>POS:</label>
                                    <input type="text" name="pos" onChange={this.handleChange} className="form-control" autoComplete="off" autoCorrect="off" />
                                    {this.validator.message('pos', this.state.pos, 'required|alpha_num')}
                                    {this.state.pos_validation_msg && <lable className="text-danger">{this.state.pos_validation_msg}</lable>}
                                </li>
                                <li>
                                    <label>Reference Number:</label>
                                    <input type="text" name="ref_number" onChange={this.handleChange} className="form-control" />
                                    {this.validator.message('reference number', this.state.ref_number, 'required|alpha_num')}
                                    {this.state.reference_validation_msg && <lable className="text-danger">{this.state.reference_validation_msg}</lable>}
                                </li>
                            </ul>
                        </div>

                        <div className="button-wrapper align-right">
                            <button type="submit" onClick={this.handleSubmit} className="btn btn-primary">create rx</button>
                            <Link className="btn btn-outline-primary" to="/offline/prescriptions">Cancel</Link>
                        </div>
                    </form>
                </div>
            </div>

        </React.Fragment>);
    }
}
const mapStateToProps = (state) => ({
    role: state.selected_user_profile
});

const mapActionsToProps = ({
    loaderShow: LoaderAction.loaderShow,
    loaderHide: LoaderAction.loaderHide,
})

export default connect(mapStateToProps, mapActionsToProps)(CreateOfflineOrders)
