import React from 'react'
import { Modal, Button } from 'react-bootstrap';

const CancelModal = ({ showModal, handleRedirect, handleClose }) => {
    return (
        <Modal show={showModal} backdrop="static" keyboard={false} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Alert!</Modal.Title>
            </Modal.Header>
            <Modal.Body>Do You want to leave this page? You may lose the data!</Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleRedirect}>
                    Ok
            </Button>
                <Button variant="danger" onClick={handleClose}>
                    Cancel
            </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CancelModal
