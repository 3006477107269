import React from "react";
import * as Helper from "../../utils/helpers";
import PrescriptionService from "../../services/prescriptionService";
import Loader from '../Loader';

const OrderBlock = ({ order }) => {
  return <React.Fragment>
    <h4 class="page-title">Prescription Rx</h4>
    <div class="content-wrapper padding-bottom-10">
      <h6>Pharmacy Info</h6>
      <ul class="prescription-patient-details">
        <li>
          Rx Number: <span>{order && order.order_id}</span>
        </li>
        <li>
          Pharmacy Name:{" "}
          <span>
            {order &&
              order.service_provider_info &&
              order.service_provider_info.service_provider_name}
          </span>
        </li>
        <li>
          Rx Date:{" "}
          <span>{order && Helper.getDateFormat(order.order_date)}</span>
        </li>
      </ul>
    </div>
    <div class="content-wrapper  padding-bottom-10">
      <h6>Prescribed Medicine</h6>
      <table class="table table table-responsive common-table">
        <thead>
          <tr>
            <th>Brand Name</th>
            <th>Generic Name</th>
            <th>Frequency</th>
            <th>Duration</th>
            {/* <th>Unit Required</th> */}
            <th>Unit Dispensed</th>
          </tr>
        </thead>
        <tbody>
          {order &&
            order.prescription_order_items &&
            order.prescription_order_items.map(item => {
              return (
                <React.Fragment>
                  {" "}
                  <tr>
                    <td>{item.drug_name}</td>
                    <td>{item.drug_generic_name}</td>
                    <td>{item.dosage}</td>
                    <td>
                      {item.duration}
                      {Helper.getDurationType(item.duration_type)}
                    </td>
                    {/* <td>{_.isEmpty(item.drug_id) ? 1 : item.required_unit} {item.drug_type === "tablespoon" ? "ml" : "pc"}</td> */}
                    <td>
                      {item.fulfilled_unit}{" "}
                      {item.drug_type === "tablespoon" ? "ml" : "pc"}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={5} className="remark-table-cell">
                      <div className="fourth">
                        <label>Remark:</label>
                        <span>{item.remark}</span>
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
        </tbody>
      </table>
    </div>
  </React.Fragment>
}

class PrescriptionOrdersBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prescription_orders: null,
      loader: {
        isloading: false
      }
    };
  }

  componentDidMount() {
    PrescriptionService.getPrescriptionOrders({}, this.props.prescriptionId)
      .then(resp => {
        this.setState({
          prescription_orders: resp.data.data
        });
      })
      .catch(error => {
        return error;
      });
  }

  render() {
    return (
      <React.Fragment>
        <Loader />
        {this.state.prescription_orders && this.state.prescription_orders.length > 0 && this.state.prescription_orders.map((order, index) =>
          <OrderBlock key={index} order={order} />)}
      </React.Fragment>
    );
  }
}

export default (PrescriptionOrdersBlock);
