import React from "react";
import { connect } from "react-redux";
import * as PendingSignaturePrescriptionAction from '../../actions/pendingSignaturePrescriptionAction';
import { Helmet } from "react-helmet";
import Notification from "../../utils/notification";
import { getDateFormat, getStatus } from '../../utils/helpers';
import PinModal from "../../components/Signature/PinModal";
import _ from 'lodash';
import DataGrid from '../../components/DataGrid';

class SignaturePendingModifiedPrescriptions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isloading: false,
            data: '',
            id: [],
            showPinModal: false,
        }
        this.onSuccessRedirect = this.onSuccessRedirect.bind(this);
        this.showPinModal = this.showPinModal.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.setLoading = this.setLoading.bind(this);
        this.resetChecked = this.resetChecked.bind(this);
    }

    componentDidMount() {
        this.props.paginate({
            sortBy: { sortField: "updated_at", sortOrder: "desc" },
        });
    }

    selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            let array = [...this.state.id];
            let id = row.id;
            let index = this.state.id.indexOf(id);
            if (isSelect) {
                this.setState({
                    id: [...this.state.id, id]
                });
            } else {
                if (index !== -1) {
                    array.splice(index, 1);
                    this.setState({
                        id: array
                    });
                }
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            let array = [];
            if (isSelect) {
                Object.keys(rows).forEach((row) => {
                    array = [...array, rows[row].id]
                });
            }
            this.setState({
                id: array
            });
        }
    };

    columns = () => {
        return [{
            dataField: "id",
            text: "Id",
            hidden: true
        }, {
            dataField: "prescription_id",
            text: "Prescription Id",
            sort: true
        },
        {
            dataField: "patient_name",
            text: "Patient Name"
        },
        {
            dataField: "prescription_date",
            text: "Prescription Date",
            formatter: this.dateTimeFormat
        },
        {
            dataField: "prescription_status",
            text: "Prescription Status",
            formatter: (cell, row) => {
                return getStatus(cell)
            }
        },
        {
            dataField: "updated_at",
            text: "Updated Date",
            formatter: this.dateTimeFormat
        },]
    };

    dateTimeFormat = (cell, _row) => {
        return getDateFormat(cell)
    }

    setLoading = (value) => {
        this.setState({
            isloading: value
        });
    }

    bulkSign = () => {
        if (_.size(this.state.id) > 0) {
            this.showPinModal();

        } else {
            Notification.show('error', { 'message': 'Select atleast one order!' });
        }
    }

    resetChecked = () => {
        this.setState({
            id: []
        });
    }

    showPinModal = () => {
        this.setState({ showPinModal: true });
    }
    handleClose = () => {
        this.setState({ showPinModal: false });
    }

    onSuccessRedirect = () => {
        this.setState({ showPinModal: false });
        this.resetChecked();
        this.props.paginate({
            sortBy: { sortField: "updated_at", sortOrder: "desc" },
        });
    }

    handleTableChange = (
        type,
        { page, sizePerPage, sortField, sortOrder, searchText }
    ) => {
        if (type === "search") {
            page = 1;
        }
        this.props.paginate({ page, sizePerPage, sortBy: { sortField, sortOrder }, searchText });
    };

    render() {
        let { data, meta } = this.props;
        return (
            <React.Fragment>
                {this.state.isloading && this.state.isloading && <div className="loading">Loading</div>}
                <Helmet>
                    <title>Unsigned Prescriptions</title>
                </Helmet>
                <h4 className="page-title"><span>Unsigned Prescription</span>
                    <button className="btn btn-primary float-right" onClick={this.bulkSign.bind()} disabled={_.size(this.state.id) > 0 ? false : true}>Sign {_.size(this.state.id)} Checked Prescriptions</button>
                </h4>
                <div className="content-wrapper pending_signature">
                    <DataGrid
                        columns={this.columns()}
                        data={data}
                        meta={meta}
                        handleTableChange={this.handleTableChange}
                        keyField="id"
                        selectRow={this.selectRow}
                        noDataIndication="Record Not Found"
                    />
                </div>
                {this.state.showPinModal &&
                    <PinModal show={this.state.showPinModal} handleClose={this.handleClose} id={this.state.id} type="edit-prescription" onSuccess={this.onSuccessRedirect} setLoading={this.setLoading} />
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    ...state.pending_signature_prescription_list
});

const mapActionsToProps = ({
    paginate: PendingSignaturePrescriptionAction.getSignaturePendingModifiedPrescription
})

export default connect(mapStateToProps, mapActionsToProps)(SignaturePendingModifiedPrescriptions);
