import React from 'react';
import * as HELPER from '../utils/helpers';
import PatientService from '../services/patientService';
import _ from 'lodash';

class PatientWeightTooltip extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            data: null,
        };
    }

    fetchData = () => {
        if (this.state.data === null && this.state.loading === false) {
            this.setState({
                loading: true
            });

            PatientService.listWeight(this.props.patientId)
                .then(resp => {
                    let data = resp.data;
                    this.setState({
                        loading: false,
                        data: data
                    });
                })
                .catch(error => {
                    this.setState({
                        loading: false,
                        data: []
                    });
                });
        }
    }

    render() {
        return (
            <div className="tooltip-custom" onMouseOver={this.fetchData}>
                <div className="left">
                    {this.state.loading && <div>loading...</div>}
                    {this.state.data && <table>
                        <thead>
                            <tr>
                                <td>Date</td>
                                <td>Weight</td>
                            </tr>
                        </thead>
                        <tbody>
                            {_.isEmpty(this.state.data) && <tr><td colSpan="2" className="text-success">No past record exist.</td></tr>}
                            {this.state.data.map((weight, index) => (
                                <tr key={index}>
                                    <td>{HELPER.getDateFormat(weight.created_at)}</td>
                                    <td>{weight.weight} <small>kgs</small></td>
                                </tr>
                            ))
                            }
                        </tbody>
                    </table>}
                </div>
            </div>
        );
    }

}

export default PatientWeightTooltip;