import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function (state = initialState.priceList, { type, ...payload }) {
    switch (type) {
        case types.PRICE_LIST_FAILED:
            return {};
        case types.PRICE_LIST_REQUEST:
            return {
                ...payload
            };
        case types.PRICE_LIST_SUCCESS:
            return {
                ...payload
            };
        default:
            return state;
    }
}