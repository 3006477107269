import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class PriceListService {
    static drugSkuList(page, sizePerPage, sortBy, searchText) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({page, sizePerPage, sortBy, searchText})
        };

        return axios.get(apiRoute('/v1/pharmacy/drugs'), requestOptions);
    }

    static PathologyTestList(page, sizePerPage, sortBy, searchText) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({page, sizePerPage, sortBy, searchText})
        };

        return axios.get(apiRoute('/v1/pathology/tests'), requestOptions);
    }

    static RadiologyTestList(page, sizePerPage, sortBy, searchText) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({page, sizePerPage, sortBy, searchText})
        };

        return axios.get(apiRoute('/v1/radiology/tests'), requestOptions);
    }
}

export default PriceListService;