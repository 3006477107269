import React from 'react';

export const General = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <h6>General</h6>
        <input type="checkbox" id='general-normal' name='general-normal' onChange={e => handleChange(e, 'general')} checked={checkIfProblemExist('general', 'general-normal') ? 'checked' : ''} />
        <label htmlFor='general-normal'>Normal</label>
    </React.Fragment>
}
export const MM = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <label className="heading">MM </label>
        <div className="options-details">
            {/* pale */}
            <input type="checkbox" id='general-mm-pale' name='general-mm-pale' value='general-mm-pale' onChange={e => handleChange(e, 'general')} checked={checkIfProblemExist('general', 'general-mm-pale') ? 'checked' : ''} />
            <label htmlFor='general-mm-pale'>Pale</label>
            {/* icteric */}
            <input type="checkbox" id='general-mm-icteric' value='general-mm-icteric' name='general-mm-icteric' onChange={e => handleChange(e, 'general')} checked={checkIfProblemExist('general', 'general-mm-icteric') ? 'checked' : ''} />
            <label htmlFor='general-mm-icteric'>Icteric</label>
            {/* dry */}
            <input type="checkbox" id='general-mm-dry' value='general-mm-dry' name='general-mm-dry' onChange={e => handleChange(e, 'general')} checked={checkIfProblemExist('general', 'general-mm-dry') ? 'checked' : ''} />
            <label htmlFor='general-mm-dry'>Dry</label>
            {/* cyanotic */}
            <input type="checkbox" id='general-mm-cyanotic' name='general-mm-cyanotic' value='general-mm-cyanotic' onChange={e => handleChange(e, 'general')} checked={checkIfProblemExist('general', 'general-mm-cyanotic') ? 'checked' : ''} />
            <label htmlFor='general-mm-cyanotic'>Cyanotic</label>
            {/* normal */}
            <input type="checkbox" id="general-mm-normal" value="general-mm-normal" name="general-mm-normal" onChange={e => handleChange(e, 'general')} checked={checkIfProblemExist('general', "general-mm-normal") ? 'checked' : ''} />
            <label htmlFor="general-mm-normal">Normal</label>
        </div>
    </React.Fragment>
}
export const LN = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <label className="heading">LN </label>
        <input type="checkbox" id='general-ln-present' name='general-ln-present' value='general-ln-present' checked={checkIfProblemExist('general', 'general-ln-present') ? 'checked' : ''} onChange={e => handleChange(e, 'general')} />
        <label htmlFor='general-ln-present' >Present</label>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment, checkIfProblemExist }) => {
    return <React.Fragment>
        <label className="heading-comment">Comment </label>
        <textarea className="form-control" name="comments" placeholder="Type here..." disabled={checkIfProblemExist('general', 'general-normal') ? 'disabled' : ''} onChange={e => handleChange(e, 'general')} value={getCategoryComment('general') ? getCategoryComment('general') : ''} ></textarea>
    </React.Fragment>
}