import React from 'react';
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import DataGrid from "../../components/DataGrid";
import PatientService from '../../services/patientService';
import Notification from '../../utils/notification';
import * as LoaderAction from '../../actions/loaderAction';
import * as PatientAction from '../../actions/patientAction'
import * as Helper from "../../utils/helpers";

class ImportHistoricDataPage extends React.Component {
    componentDidMount() {
        this.props.paginate({ sortBy: { sortField: "created_at", sortOrder: "desc" } })
    }
    columns = () => {
        return [{
            dataField: "uploaded_at",
            text: "Import Date",
            formatter: this.dateFormatter
        },
        {
            dataField: 'file_name',
            text: 'File Name',
        },]
    }
    dateFormatter = (cell) => {
        if (cell) {
            return Helper.getDateFormat(cell);
        }
        return '--'
    }

    handleUploadedFiles = (e) => {
        e.preventDefault();
        const files = e.target.files;
        let validated = false;

        validated = this.validation(files);
        if (validated) {
            let formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                formData.append(`files[${i}]`, files[i]);
            }
            this.props.showLoader();
            PatientService.historicDataUpload(formData).then(_resp => {
                this.props.hideLoader()
                Notification.show('success', { message: 'Data has been uploaded successfully' });
                this.props.paginate({ sortBy: { sortField: "created_at", sortOrder: "desc" } })
            }).catch(err => {
                this.props.hideLoader()
                Notification.show('error', err.response.data)
            })
        }
    }

    validation = (files) => {
        for (let i = 0; i < files.length; i++) {
            let rule = /^[A-Za-z]+$/;
            if (!files[i].name.replace(/\.[^/.]+$/, "").match(rule)) {
                Notification.show('error', { message: 'The file name "' + files[i].name + '" is invalid. The file name may only contain letters' });
                return false;
            }

            if (files[i].size > 25600000) {
                Notification.show('error', { message: 'PDF  "' + files[i].name + '" size should not be more than 25 MB' });
                return false;
            }
        }
        return true;
    }

    handleTableChange = (type, { page, sizePerPage, sortField = "created_at", sortOrder = "desc", searchText }) => {
        if (type === "search") {
            page = 1;
        }

        this.props.paginate({ page, sizePerPage, sortBy: { sortField, sortOrder }, searchText });
    };

    render() {
        return (<React.Fragment>
            <Helmet>
                <title>Import Data</title>
            </Helmet>
            <div className="row">
                <div className="col-md-24">
                    <h4 className="page-title">
                        <span>Upload Historic Data</span>
                        <input type="file" ref={fileInput => this.fileInput = fileInput} onChange={this.handleUploadedFiles} name="files" id="file" hidden multiple accept='.pdf' />
                        <button onClick={() => this.fileInput.click()} className="float-right btn btn-outline-primary" name="file" id="file">Import Data</button>
                    </h4>
                </div>
            </div>
            <div className="content-wrapper">
                <DataGrid
                    columns={this.columns()}
                    noDataIndication="No Record Found"
                    data={this.props.patient_historic_data.data}
                    meta={this.props.patient_historic_data.meta}
                    handleTableChange={this.handleTableChange}
                />
            </div>
        </React.Fragment>
        )
    }
}
const mapStateToProps = state => ({
    patient_historic_data: state.patient_historic_data
});

const mapActionsToProps = {
    hideLoader: LoaderAction.loaderHide,
    showLoader: LoaderAction.loaderShow,
    paginate: PatientAction.getUploadedHistoryData
};

export default connect(mapStateToProps, mapActionsToProps)(ImportHistoricDataPage)