export const PersonalHistory = {
    'education': {
        'secondary': 'Secondary',
        'higher-secondary': 'Higher Secondary',
        'graduation': 'Graduation',
        'post-graduation': 'Post Graduation/Master'
    },
    'substance': {
        'caffeine': 'Caffeine',
        'tobacco': 'Tobacco',
        'alcohol': 'Alcohol',
        'street-drugs': 'Street Drugs'
    },
    'use_of_condom': {
        'always': 'Always',
        'sometimes': 'Sometimes',
        'never': 'Never',
    },
    'hiv_test_result': {
        'normal': 'Normal',
        'abnormal': 'Abnormal'
    }
}
export const GaenacHistory = {
    'contraception': {
        'ocp': 'OCP',
        'injectable': 'Injectable',
        'iud': 'IUD',
        'subcutaneous': 'Subcutaneous'
    },
    'papsmear': {
        'normal': 'Normal',
        'abnormal': 'Abnormal',
        'never-did-papsmear': 'Never Did Pap Smear'
    },
}

export const FamilyHistory = {
    'family_relation': {
        'grandparents': 'Grandparents',
        'father': 'Father',
        'mother': 'Mother',
        'brothers': 'Brothers',
        'sisters': 'Sisters',
        'sons': 'Sons',
        'daughters': 'Daughters',
        'none': 'None Describe'
    },
    'illness': {
        'cancer': 'Cancer',
        'heart-disease': 'Heart Disease',
        'diabetes': 'Diabetes',
        'high-blood-pressure': 'High Blood Pressure',
        'stroke': 'Stroke',
        'high-cholesterol': 'High Cholesterol',
        'liver-disease': 'Liver Disease',
        'alcohol-or-drug-abuse': 'Alcohol or Drug Abuse',
        'anxiety': 'Anxiety',
        'psychiatric-illness': 'Psychiatric Illness',
        'tuberculosis': 'Tuberculosis',
        'anesthesia-complications': 'Anesthesia Complications',
        'genetic-disorder': 'Genetic Disorder',
        'other': 'Other'
    }
}
export const overall_examination_problem_config = {
    'mm': ['general-mm-pale', 'general-mm-icteric', 'general-mm-cyanotic', 'general-mm-dry', 'general-mm-normal'],
    'ln': ['general-ln-present'],
    'chest-expansion': ['respiratory-chest-expansion-normal', 'respiratory-chest-expansion-decreased', 'respiratory-chest-expansion-bilateral', 'respiratory-chest-expansion-unilateral'],
    'air-entry': ['respiratory-air-entry-normal', 'respiratory-air-entry-decreased', 'respiratory-air-entry-bilateral', 'respiratory-air-entry-unilateral', 'respiratory-air-entry-creps', 'respiratory-air-entry-rhonchi'],
    'pulse': ['cardiovascular-plus-regular', 'cardiovascular-plus-irregular', 'cardiovascular-plus-bounding', 'cardiovascular-plus-fast'],
    'hs': ['cardiovascular-hs-s1', 'cardiovascular-hs-s2', 'cardiovascular-hs-s3', 'cardiovascular-hs-s4', 'cardiovascular-hs-murmur', 'cardiovascular-hs-thrill'],
    'memory': ['psychiatric-memory-normal', 'psychiatric-memory-abnormal'],
    'speech': ['psychiatric-speech-incongruent', 'psychiatric-speech-pressured'],
    'movement': ['muscle-joints-bones-movement-decreased'],
    'power': ['muscle-joints-bones-power-normal', 'muscle-joints-bones-power-decreased', 'nervous-system-power-normal', 'nervous-system-power-abnormal'],
    'gait': ['nervous-system-gait-normal', 'nervous-system-gait-abnormal'],
    'reflex': ['nervous-system-reflex-normal', 'nervous-system-reflex-abnormal'],
    'ear-drum': ['ears-ear-drum-normal', 'ears-ear-drum-dull', 'ears-ear-drum-erythematous'],
    'tonsils': ['throat-tonsils-swollen', 'throat-tonsils-exudate']
}
export const overall_examination_config = {
    'general': {
        'mm': ['general-mm-pale', 'general-mm-icteric', 'general-mm-cyanotic', 'general-mm-dry', 'general-mm-normal'],
        'ln': ['general-ln-present'],
    },
    'respiratory': {
        'chest-expansion': ['respiratory-chest-expansion-normal', 'respiratory-chest-expansion-decreased', 'respiratory-chest-expansion-bilateral', 'respiratory-chest-expansion-unilateral-left', 'respiratory-chest-expansion-unilateral-right'],
        'air-entry': ['respiratory-air-entry-normal', 'respiratory-air-entry-decreased', 'respiratory-air-entry-bilateral', 'respiratory-air-entry-unilateral-left', 'respiratory-air-entry-unilateral-right', 'respiratory-air-entry-creps', 'respiratory-air-entry-rhonchi'],

    },
    'abdomen': ['abdomen-flat', 'abdomen-distended', 'abdomen-visible-mass', 'abdomen-scar', 'abdomen-non-tender', 'abdomen-tender', 'abdomen-organomegally',
    ],
    'cardiovascular': {
        'pulse': ['cardiovascular-plus-regular', 'cardiovascular-plus-irregular', 'cardiovascular-plus-bounding', 'cardiovascular-plus-fast'],
        'hs': ['cardiovascular-hs-s1', 'cardiovascular-hs-s2', 'cardiovascular-hs-s3', 'cardiovascular-hs-s4', 'cardiovascular-hs-murmur', 'cardiovascular-hs-thrill'],
    },
    'psychiatric': {
        'psychiatric': 'psychiatric-ottp',
        'memory': ['psychiatric-memory-normal', 'psychiatric-memory-abnormal'],
        'speech': ['psychiatric-speech-incongruent', 'psychiatric-speech-pressured'],
    },
    'muscle-joints-bones': {
        'muscle-joints-bones': ['muscle-joints-bones-swollen', 'muscle-joints-bones-tender', 'muscle-joints-bones-crepitous'],
        'movement': ['muscle-joints-bones-movement-decreased'],
        'power': ['muscle-joints-bones-power-normal', 'muscle-joints-bones-power-decreased'],
    },
    'nervous-system': {
        'gait': ['nervous-system-gait-normal', 'nervous-system-gait-abnormal'],
        'reflex': ['nervous-system-reflex-normal', 'nervous-system-reflex-abnormal'],
        'power': ['nervous-system-power-normal', 'nervous-system-power-abnormal'],
    },
    'eyes': ['eyes-pupils-reactive', 'eyes-redness', 'eyes-discharge', 'eyes-swollen', 'eyes-lump',
    ],
    'ears': {
        'ear-drum': ['ears-ear-drum-normal', 'ears-ear-drum-dull', 'ears-ear-drum-erythematous'],
    },
    'nose': ['nose-polyps', 'nose-ulcer', 'nose-septal-defect',
    ],
    'throat': {
        'throat': ['throat-erythema'],
        'tonsils': ['throat-tonsils-swollen', 'throat-tonsils-exudate']
    },
}
export const overall_examination_label_config = {
    'pulse': 'Pulse',
    'power': 'Power',
    'ear-drum': 'Ear drum',
    'tonsils': 'Tonsils',
    'reflex': 'Reflex',
    'gait': 'Gait',
    'memory': 'Memory',
    'movement': 'Movement',
    'speech': 'Speech',
    'hs': 'HS',
    'air-entry': 'Air Entry',
    'chest-expansion': 'Chest Expansion',
    'general': 'General',
    'general-normal': 'Normal',
    'general-mm-pale': 'Pale',
    'general-mm-icteric': 'Icteric',
    'general-mm-cyanotic': 'Cyanotic',
    'general-mm-dry': 'Dry',
    'general-mm-normal': 'Normal',
    'general-ln-present': 'Present',
    'respiratory': 'Respiratory',
    'respiratory-normal': 'Normal',
    'respiratory-chest-expansion-normal': 'Normal',
    'respiratory-chest-expansion-decreased': 'Decreased',
    'respiratory-chest-expansion-bilateral': 'Bilateral',
    'respiratory-chest-expansion-unilateral': 'Unilateral L/R',
    'respiratory-air-entry-normal': 'Normal',
    'respiratory-air-entry-decreased': 'Decreased',
    'respiratory-air-entry-bilateral': 'Bilateral',
    'respiratory-air-entry-unilateral': 'Unilateral L/R',
    'respiratory-air-entry-creps': 'Creps',
    'respiratory-air-entry-rhonchi': 'Rhonchi',
    'abdomen': 'Abdomen',
    'abdomen-normal': 'Normal',
    'abdomen-flat': 'Flat',
    'abdomen-distended': 'Distended',
    'abdomen-visible-mass': 'Visible Mass',
    'abdomen-scar': 'Scar',
    'abdomen-non-tender': 'Non tender',
    'abdomen-tender': 'Tender',
    'abdomen-organomegally': 'Organomegally',
    'cardiovascular': 'Cardiovascular',
    'cardiovascular-normal': 'Normal',
    'cardiovascular-plus-regular': 'Regular',
    'cardiovascular-plus-irregular': 'Irregular',
    'cardiovascular-plus-bounding': 'Bounding',
    'cardiovascular-plus-fast': 'Fast',
    'cardiovascular-hs-s1': 'S1',
    'cardiovascular-hs-s2': 'S2',
    'cardiovascular-hs-s3': 'S3',
    'cardiovascular-hs-s4': 'S4',
    'cardiovascular-hs-murmur': 'Murmur',
    'cardiovascular-hs-thrill': 'Thrill',
    'psychiatric': 'Psychiatric',
    'psychiatric-ottp': 'OTPP',
    'psychiatric-memory-normal': 'Normal',
    'psychiatric-memory-abnormal': 'Abnormal',
    'psychiatric-speech-incongruent': 'Incongruent',
    'psychiatric-speech-pressured': 'Pressured',
    'muscle-joints-bones': ' Muscular Skeletal System',
    'muscle-joints-bones-normal': 'Normal',
    'muscle-joints-bones-swollen': 'Swollen',
    'muscle-joints-bones-tender': 'Tender',
    'muscle-joints-bones-crepitous': 'Crepitus',
    'muscle-joints-bones-movement-decreased': 'Decreased',
    'muscle-joints-bones-power-normal': 'Normal',
    'muscle-joints-bones-power-decreased': 'Decreased',
    'nervous-system': 'Nervous System',
    'nervous-system-normal': 'Normal',
    'nervous-system-gait-normal': 'Normal',
    'nervous-system-gait-abnormal': 'Abnormal',
    'nervous-system-reflex-normal': 'Normal',
    'nervous-system-reflex-abnormal': 'Abnormal',
    'nervous-system-power-normal': 'Normal',
    'nervous-system-power-abnormal': 'Abnormal',
    'eyes': 'Eyes',
    'eyes-normal': 'Normal',
    'eyes-pupils-reactive': 'Pupils reactive to light',
    'eyes-redness': 'Redness',
    'eyes-discharge': 'Discharge',
    'eyes-swollen': 'Swollen',
    'eyes-lump': 'Lump/Bump',
    'ears': 'Ears',
    'ears-normal': 'Normal',
    'ears-ear-drum-normal': 'Normal',
    'ears-ear-drum-dull': 'Dull',
    'ears-ear-drum-erythematous': 'Erythematous',
    'nose': 'Nose',
    'nose-normal': 'Normal',
    'nose-polyps': 'Polyps',
    'nose-ulcer': 'Ulcer',
    'nose-septal-defect': 'Septal defetct',
    'throat': 'Throat',
    'throat-normal': 'Normal',
    'throat-erythema': 'Erythema',
    'throat-tonsils-swollen': 'Swollen',
    'throat-tonsils-exudate': 'exudate',
    'mm': 'MM',
    'ln': 'LN',
    'respiratory-chest-expansion-unilateral-left': 'Unilateral Left',
    'respiratory-chest-expansion-unilateral-right': 'Unilateral Right',
    'respiratory-air-entry-unilateral-left': 'Unilateral Left',
    'respiratory-air-entry-unilateral-right': 'Unilateral Right',
}
export const review_of_system_label_config = {
    'recent-weight-gain': 'Recent weight gain',
    'recent-weight-loss': 'Recent weight loss',
    'fatigue': 'Fatigue',
    'weakness': 'Weakness',
    'fever': 'Fever',
    'night-sweats': 'Night sweats',
    'headaches': 'Headaches',
    'dizziness': 'Dizziness',
    'fainting-or-loss-of-consciousness': 'Fainting or loss of consciousness',
    'numbness-or-tingling': 'Numbness or tingling',
    'memory-loss': 'Memory loss',
    'depression': 'Depression',
    'excessive-worries': 'Excessive worries',
    'difficulty-falling-asleep': 'Difficulty falling asleep',
    'difficulty-staying-asleep': 'Difficulty staying asleep',
    'difficulties-with-sexual-arousal': 'Difficulties with sexual arousal',
    'poor-apetite': 'Poor appetite',
    'food-cravings': 'Food cravings',
    'frequent-crying': 'Frequent crying',
    'sensitivity': 'Sensitivity',
    'thoughts-of-suicide-attempts': 'Thoughts of suicide attempts',
    'stress': 'Stress',
    'irritability': 'Irritability',
    'poor-concentration': 'Poor concentration',
    'racing-thoughts': 'Racing thoughts',
    'hallucinations': 'Hallucinations',
    'rapid-speech': 'Rapid speech',
    'guilty-thoughts': 'Guilty thoughts',
    'paranoia': 'Paranoia',
    'mood-swings': 'Mood swings',
    'anxiety': 'Anxiety',
    'risk-behaviour': 'Risk behavior',
    'numbness': 'Numbness',
    'joint-pain': 'Joint pain',
    'muscle-weakness': 'Muscle weakness',
    'joint-swelling': 'Joint swelling',
    'nausea': 'Nausea',
    'heartburn': 'Heartburn',
    'stomach-pain': 'Stomach pain',
    'vomiting': 'Vomiting',
    'yellow-jaundice': 'Yellow jaundice',
    'increasing-constipation': 'Increasing constipation',
    'persistent-diarrhea': 'Persistent diarrhea',
    'blood-in-stools': 'Blood in stools',
    'black-stools': 'Black stools',
    'ringing-in-ears': 'Ringing in ears',
    'loss-of-hearing': 'Loss of hearing',
    'pain': 'Pain',
    'redness': 'Redness',
    'loss-of-vision': 'Loss of vision',
    'double-or-blurred-vision': 'Double or blurred vision',
    'dryness': 'Dryness',
    'rash': 'Rash',
    'nodules-bumps': 'Nodules bumps',
    'hair-loss': 'Hair loss',
    'color-changes-of-hands-or-feet': 'Color changes of hands or feet',
    'frequent-sore-throats': 'Frequent sore throats',
    'hoarseness': 'Hoarseness',
    'difficulty-in-swallowing': 'Difficulty in swallowing',
    'pain-in-jaw': 'Pain in jaw',
    'anemia': 'Anemia',
    'clots': 'Clots',
    'chest-pain': 'Chest pain',
    'palpitations': 'Palpitations',
    'shortness-of-breath': 'Shortness of breath',
    'fainting': 'Fainting',
    'swollen-legs-or-feet': 'Swollen legs or feet',
    'cough': 'Cough',
    'frequent-or-painfull-urination': 'Frequent or painful urination',
    'blood-in-urine': 'Blood in urine',
    'general': 'General',
    'nervous-system': 'Nervous System',
    'psychiatric': 'Psychiatric',
    'muscle_joint_bones': 'Muscular Skeletal System',
    'stomach-and-intestines': 'Gastrointestinal Systems',
    'ears': 'Ears',
    'eyes': 'Eyes',
    'skin': 'Skin',
    'throat': 'Throat',
    'blood': 'Blood',
    'kidney-urine-bladder': 'Kidney/Urine/Bladder',
    'heart-and-lungs': 'Cardiovascular System and Respiratory System',
    'skin-redness': 'Redness',
    'syncope': 'Syncope'
}
export const overall_body_chart_label_config = {
    'male_front': 'Male Front',
    'male_back': 'Male Back',
    'female_front': 'Female Front',
    'female_back': 'Female Back',
}

export const body_chart_sub_part = {

    'male': {
        'front': {
            'eye-lt': {
                'pupil': "Pupil",
                'cornea': "Cornea",
                'lens': "Lens",
                'retina': "Retina",
                'iris': "Iris",
                'sclera': "Sclera",
                'orbital': "Orbital",
                'lid': "Lid",
            },
            'eye-rt': {
                'pupil': "Pupil",
                'cornea': "Cornea",
                'lens': "Lens",
                'retina': "Retina",
                'iris': "Iris",
                'sclera': "Sclera",
                'orbital': "Orbital",
                'lid': "Lid",
            },
            'ear-lt': {
                'pinna': "Pinna",
                'tympanic-membrane': "Tympanic membrane",
                'cochlear': "Cochlear",
                'external-auditory-canal': "External Auditory canal",
                'malleus': "Malleus",
                'incus': "Incus",
                'stapes': "Stapes",
                'semi-circular-canals': "Semi-circular canals",
                'eustachian-tube': "Eustachian tube",
            },
            'ear-rt': {
                'pinna': "Pinna",
                'tympanic-membrane': "Tympanic membrane",
                'cochlear': "Cochlear",
                'external-auditory-canal': "External Auditory canal",
                'malleus': "Malleus",
                'incus': "Incus",
                'stapes': "Stapes",
                'semi-circular-canals': "Semi-circular canals",
                'eustachian-tube': "Eustachian tube",
            },
            'nose': {
                'nasal-cavity': "Nasal Cavity",
                'olfactory-bulb': "Olfactory bulb",
                'olfactory-tract': "Olfactory tract",
                'cribriform-plate': "Cribriform plate",
                'olfactory-nerve': "Olfactory nerve",
            },
            'mouth': {
                'soft-palate': "Soft palate",
                'hard-palate': "Hard Palate",
                'tongue': "Tongue",
                'molar': "Molar",
                'premolar': "Premolar",
                'canine': "Canine",
                'incisor': "Incisor",
            },
            'neck': {
                'external': "External",
                'pharynx': "Pharynx",
                'epiglotis': "Epiglotis",
                'larynx': "Larynx",
                'oesophagus': "Oesophagus",
                'trachea': "Trachea",
            },
            'abdomen': {
                'liver': "Liver",
                'spleen': "Spleen",
                'gallbladder': "Gallbladder",
                'stomach': "Stomach",
                'large-intestine': "Large intestine",
                'small-intestine': "Small intestine",
                'kidney': "Kidney",
                'pancreas': "Pancreas",
                'appendix': "Appendix",
            },
            'pelvis': {
                'bladder': "Bladder",
            },
            'knee-lt': {
                'patella': "Patella",
                'medial-collateral-ligament': "Medial Collateral ligament",
                'lateral-collateral-ligament': "Lateral collateral ligament",
                'lateral-meniscus': "Lateral meniscus",
                'medial-meniscus': "Medial meniscus",
            },
            'knee-rt': {
                'patella': "Patella",
                'medial-collateral-ligament': "Medial Collateral ligament",
                'lateral-collateral-ligament': "Lateral collateral ligament",
                'lateral-meniscus': "Lateral meniscus",
                'medial-meniscus': "Medial meniscus",
            },
            'hand-lt': {
                'carpals': "Carpals",
                'metacarpals': "Metacarpals",
                'phalanges': "Phalanges",
                'flexor-tendon-sheath': "Flexor Tendon Sheath",
                'natatory-ligament': "Natatory Ligament",
                'proximal-tranverse-ligament': "Proximal Tranverse Ligament",
                'pretendinous-band': "Pretendinous Band",
                'abductor-digital-minimi-muscle': "Abductor Digital Minimi Muscle",
                'commissural-ligaments': "Commissural Ligaments",
                'abductor-pollicis-brevis-muscle': "Abductor Pollicis Brevis Muscle",
                'ulnar-nerve': "Ulnar Nerve",
                'palmaris-longus-tendon': "Palmaris Longus Tendon",
                'median-nerve': "Median Nerve",
            },
            'hand-rt': {
                'carpals': "Carpals",
                'metacarpals': "Metacarpals",
                'phalanges': "Phalanges",
                'flexor-tendon-sheath': "Flexor Tendon Sheath",
                'natatory-ligament': "Natatory Ligament",
                'proximal-tranverse-ligament': "Proximal Tranverse Ligament",
                'pretendinous-band': "Pretendinous Band",
                'abductor-digital-minimi-muscle': "Abductor Digital Minimi Muscle",
                'commissural-ligaments': "Commissural Ligaments",
                'abductor-pollicis-brevis-muscle': "Abductor Pollicis Brevis Muscle",
                'ulnar-nerve': "Ulnar Nerve",
                'palmaris-longus-tendon': "Palmaris Longus Tendon",
                'median-nerve': "Median Nerve",
            },
            'foot-lt': {
                'tarpals': "Tarpals",
                'metatarpals': "Metatarpals",
                'phalanges': "Phalanges",
                'ankle-joint': "Ankle joint",
                'transverse-tarsal-joint': "Transverse Tarsal Joint",
                'calcaneus': "Calcaneus",
                'medial-longitudinal-arch': "Medial longitudinal arch",
                'calcaneal-tendon': "Calcaneal Tendon",
                'superior-extensor-retinaculum': "Superior Extensor Retinaculum",
                'interior-extensor-retinaculum': "Interior Extensor Retinaculum",
                'interior-fibular-retinaculum': "Interior Fibular Retinaculum",
            },
            'foot-rt': {
                'tarpals': "Tarpals",
                'metatarpals': "Metatarpals",
                'phalanges': "Phalanges",
                'ankle-joint': "Ankle joint",
                'transverse-tarsal-joint': "Transverse Tarsal Joint",
                'calcaneus': "Calcaneus",
                'medial-longitudinal-arch': "Medial longitudinal arch",
                'calcaneal-tendon': "Calcaneal Tendon",
                'superior-extensor-retinaculum': "Superior Extensor Retinaculum",
                'interior-extensor-retinaculum': "Interior Extensor Retinaculum",
                'interior-fibular-retinaculum': "Interior Fibular Retinaculum",
            },
            'chest': {
                'ribs': "Ribs",
                'lung-lt': "Lung (lt)",
                'lung-rt': "Lung (rt)",
                'diaphragm': "Diaphragm",
                'heart': "Heart",
            },
            'pubis': {
                'prostate-gland': "Prostate gland",
                'penis': "Penis",
                'urethra': "Urethra",
                'scrotum': "Scrotum",
                'glans-penis': "Glans Penis",
                'bulbourethral-gland': "Bulbourethral gland",
                'erectile-tissue': "Erectile Tissue",
                'epididymis': "Epididymis",
                'testis': "Testis",
                'seminal-vesicle': "Seminal vesicle",
                'ureter': "Ureter",
                'vas-deferens': "Vas deferens",
            },
        },
        'back': {
            'neck': {
                'external': "External",
                'pharynx': "Pharynx",
                'epiglotis': "Epiglotis",
                'larynx': "Larynx",
                'oesophagus': "Oesophagus",
                'trachea': "Trachea",
            }, 'foot-lt': {
                'tarpals': "Tarpals",
                'metatarpals': "Metatarpals",
                'phalanges': "Phalanges",
                'ankle-joint': "Ankle joint",
                'transverse-tarsal-joint': "Transverse Tarsal Joint",
                'calcaneus': "Calcaneus",
                'medial-longitudinal-arch': "Medial longitudinal arch",
                'calcaneal-tendon': "Calcaneal Tendon",
                'superior-extensor-retinaculum': "Superior Extensor Retinaculum",
                'interior-extensor-retinaculum': "Interior Extensor Retinaculum",
                'interior-fibular-retinaculum': "Interior Fibular Retinaculum",
            },
            'foot-rt': {
                'tarpals': "Tarpals",
                'metatarpals': "Metatarpals",
                'phalanges': "Phalanges",
                'ankle-joint': "Ankle joint",
                'transverse-tarsal-joint': "Transverse Tarsal Joint",
                'calcaneus': "Calcaneus",
                'medial-longitudinal-arch': "Medial longitudinal arch",
                'calcaneal-tendon': "Calcaneal Tendon",
                'superior-extensor-retinaculum': "Superior Extensor Retinaculum",
                'interior-extensor-retinaculum': "Interior Extensor Retinaculum",
                'interior-fibular-retinaculum': "Interior Fibular Retinaculum",
            },
            'back': {
                'cervical-spine': "Cervical Spine",
                'throracic-spine': "Throracic Spine",
                'lumbar-spine': "Lumbar Spine",
                'sacrum': "Sacrum",
                'coccyx': "Coccyx",
            }
        }
    },
    'female': {
        'front': {
            'eye-lt': {
                'pupil': "Pupil",
                'cornea': "Cornea",
                'lens': "Lens",
                'retina': "Retina",
                'iris': "Iris",
                'sclera': "Sclera",
                'orbital': "Orbital",
                'lid': "Lid",
            },
            'eye-rt': {
                'pupil': "Pupil",
                'cornea': "Cornea",
                'lens': "Lens",
                'retina': "Retina",
                'iris': "Iris",
                'sclera': "Sclera",
                'orbital': "Orbital",
                'lid': "Lid",
            },
            'ear-lt': {
                'pinna': "Pinna",
                'tympanic-membrane': "Tympanic membrane",
                'cochlear': "Cochlear",
                'external-auditory-canal': "External Auditory canal",
                'malleus': "Malleus",
                'incus': "Incus",
                'stapes': "Stapes",
                'semi-circular-canals': "Semi-circular canals",
                'eustachian-tube': "Eustachian tube",
            },
            'ear-rt': {
                'pinna': "Pinna",
                'tympanic-membrane': "Tympanic membrane",
                'cochlear': "Cochlear",
                'external-auditory-canal': "External Auditory canal",
                'malleus': "Malleus",
                'incus': "Incus",
                'stapes': "Stapes",
                'semi-circular-canals': "Semi-circular canals",
                'eustachian-tube': "Eustachian tube",
            },
            'nose': {
                'nasal-cavity': "Nasal Cavity",
                'olfactory-bulb': "Olfactory bulb",
                'olfactory-tract': "Olfactory tract",
                'cribriform-plate': "Cribriform plate",
                'olfactory-nerve': "Olfactory nerve",
            },
            'mouth': {
                'soft-palate': "Soft palate",
                'hard-palate': "Hard Palate",
                'tongue': "Tongue",
                'molar': "Molar",
                'premolar': "Premolar",
                'canine': "Canine",
                'incisor': "Incisor",
            },
            'neck': {
                'external': "External",
                'pharynx': "Pharynx",
                'epiglotis': "Epiglotis",
                'larynx': "Larynx",
                'oesophagus': "Oesophagus",
                'trachea': "Trachea",
            },
            'abdomen': {
                'liver': "Liver",
                'spleen': "Spleen",
                'gallbladder': "Gallbladder",
                'stomach': "Stomach",
                'large-intestine': "Large intestine",
                'small-intestine': "Small intestine",
                'kidney': "Kidney",
                'pancreas': "Pancreas",
                'appendix': "Appendix",
            },
            'pelvis': {
                'bladder': "Bladder",
            },
            'knee-lt': {
                'patella': "Patella",
                'medial-collateral-ligament': "Medial Collateral ligament",
                'lateral-collateral-ligament': "Lateral collateral ligament",
                'lateral-meniscus': "Lateral meniscus",
                'medial-meniscus': "Medial meniscus",
            },
            'knee-rt': {
                'patella': "Patella",
                'medial-collateral-ligament': "Medial Collateral ligament",
                'lateral-collateral-ligament': "Lateral collateral ligament",
                'lateral-meniscus': "Lateral meniscus",
                'medial-meniscus': "Medial meniscus",
            },
            'hand-lt': {
                'carpals': "Carpals",
                'metacarpals': "Metacarpals",
                'phalanges': "Phalanges",
                'flexor-tendon-sheath': "Flexor Tendon Sheath",
                'natatory-ligament': "Natatory Ligament",
                'proximal-tranverse-ligament': "Proximal Tranverse Ligament",
                'pretendinous-band': "Pretendinous Band",
                'abductor-digital-minimi-muscle': "Abductor Digital Minimi Muscle",
                'commissural-ligaments': "Commissural Ligaments",
                'abductor-pollicis-brevis-muscle': "Abductor Pollicis Brevis Muscle",
                'ulnar-nerve': "Ulnar Nerve",
                'palmaris-longus-tendon': "Palmaris Longus Tendon",
                'median-nerve': "Median Nerve",
            },
            'hand-rt': {
                'carpals': "Carpals",
                'metacarpals': "Metacarpals",
                'phalanges': "Phalanges",
                'flexor-tendon-sheath': "Flexor Tendon Sheath",
                'natatory-ligament': "Natatory Ligament",
                'proximal-tranverse-ligament': "Proximal Tranverse Ligament",
                'pretendinous-band': "Pretendinous Band",
                'abductor-digital-minimi-muscle': "Abductor Digital Minimi Muscle",
                'commissural-ligaments': "Commissural Ligaments",
                'abductor-pollicis-brevis-muscle': "Abductor Pollicis Brevis Muscle",
                'ulnar-nerve': "Ulnar Nerve",
                'palmaris-longus-tendon': "Palmaris Longus Tendon",
                'median-nerve': "Median Nerve",
            },
            'foot-lt': {
                'tarpals': "Tarpals",
                'metatarpals': "Metatarpals",
                'phalanges': "Phalanges",
                'ankle-joint': "Ankle joint",
                'transverse-tarsal-joint': "Transverse Tarsal Joint",
                'calcaneus': "Calcaneus",
                'medial-longitudinal-arch': "Medial longitudinal arch",
                'calcaneal-tendon': "Calcaneal Tendon",
                'superior-extensor-retinaculum': "Superior Extensor Retinaculum",
                'interior-extensor-retinaculum': "Interior Extensor Retinaculum",
                'interior-fibular-retinaculum': "Interior Fibular Retinaculum",
            },
            'foot-rt': {
                'tarpals': "Tarpals",
                'metatarpals': "Metatarpals",
                'phalanges': "Phalanges",
                'ankle-joint': "Ankle joint",
                'transverse-tarsal-joint': "Transverse Tarsal Joint",
                'calcaneus': "Calcaneus",
                'medial-longitudinal-arch': "Medial longitudinal arch",
                'calcaneal-tendon': "Calcaneal Tendon",
                'superior-extensor-retinaculum': "Superior Extensor Retinaculum",
                'interior-extensor-retinaculum': "Interior Extensor Retinaculum",
                'interior-fibular-retinaculum': "Interior Fibular Retinaculum",
            },
            'chest': {
                'breast-nipple': "Breast(Nipple)",
                'breast-areola': "Breast(Areola)",
                'breast-mammary-gland': "Breast (Mammary Gland)",
                'breast-tissue': "Breast (Tissue)",
            },
            'pubis': {
                'fundus': "Fundus",
                'fallopian-tube': "Fallopian Tube",
                'ovary': "Ovary",
                'uterine-cavity': "Uterine Cavity",
                'endometrium': "Endometrium",
                'uterus': "Uterus",
                'myometrium': "Myometrium",
                'cervix': "Cervix",
                'vagina': "Vagina",
            },
        },
        'back': {
            'neck': {
                'external': "External",
                'pharynx': "Pharynx",
                'epiglotis': "Epiglotis",
                'larynx': "Larynx",
                'oesophagus': "Oesophagus",
                'trachea': "Trachea",
            }, 'foot-lt': {
                'tarpals': "Tarpals",
                'metatarpals': "Metatarpals",
                'phalanges': "Phalanges",
                'ankle-joint': "Ankle joint",
                'transverse-tarsal-joint': "Transverse Tarsal Joint",
                'calcaneus': "Calcaneus",
                'medial-longitudinal-arch': "Medial longitudinal arch",
                'calcaneal-tendon': "Calcaneal Tendon",
                'superior-extensor-retinaculum': "Superior Extensor Retinaculum",
                'interior-extensor-retinaculum': "Interior Extensor Retinaculum",
                'interior-fibular-retinaculum': "Interior Fibular Retinaculum",
            },
            'foot-rt': {
                'tarpals': "Tarpals",
                'metatarpals': "Metatarpals",
                'phalanges': "Phalanges",
                'ankle-joint': "Ankle joint",
                'transverse-tarsal-joint': "Transverse Tarsal Joint",
                'calcaneus': "Calcaneus",
                'medial-longitudinal-arch': "Medial longitudinal arch",
                'calcaneal-tendon': "Calcaneal Tendon",
                'superior-extensor-retinaculum': "Superior Extensor Retinaculum",
                'interior-extensor-retinaculum': "Interior Extensor Retinaculum",
                'interior-fibular-retinaculum': "Interior Fibular Retinaculum",
            },
            'back': {
                'cervical-spine': "Cervical Spine",
                'throracic-spine': "Throracic Spine",
                'lumbar-spine': "Lumbar Spine",
                'sacrum': "Sacrum",
                'coccyx': "Coccyx",
            }
        }
    }
}