import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class PrescriptionService {
    static list({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute("/v1/prescription"), requestOptions);
    }

    static draftPrescriptions({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute("/v1/prescriptions/draft"), requestOptions);
    }

    static searchPrescription({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute("/v1/prescription"), requestOptions);
    }

    static getPrescriptions({ page, sizePerPage, sortBy, searchText, filter }, id) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute('/v1/patients/' + id + '/prescriptions'), requestOptions);
    }

    static get(id, query) {
        const requestOptions = {
            headers: getApiHeader(),
            params: query
        };

        return axios.get(apiRoute('/v1/prescription/' + id), requestOptions);
    }
    static view(id, query) {
        const requestOptions = {
            headers: getApiHeader(),
            params: query
        };

        return axios.get(apiRoute('/v1/doctors/prescriptions/' + id), requestOptions);
    }

    // list of prescription in pharmacy
    static prescriptionList({ page, sizePerPage, sortBy }, pharmacy_code) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy })
        };
        return axios.get(apiRoute('/pharmacies/' + pharmacy_code + '/prescriptions'), requestOptions);
    }

    // acquire prescription by setting updated_by_user_profile_id in prescription_workflows table
    static acquirePrescriptionAccess(data) {
        const requestOptions = {
            headers: getApiHeader(null, true)
        };
        return axios.post(apiRoute('/v1/acquire/' + data.prescription_id), data, requestOptions);
    }

    static revokeAcquiredPrescriptionAccess(data) {
        const requestOptions = {
            headers: getApiHeader(null, true)
        };
        return axios.post(apiRoute('/v1/acquire/' + data.prescription_id + '/revoke'), data, requestOptions);
    }

    static changePrescription(data) {
        const requestOptions = {
            headers: getApiHeader(null, true)
        };

        return axios.post(apiRoute('/v1/pharmacy/prescription/change'), data, requestOptions);
    }

    static getChangePrescription(prescription_id, query) {
        const requestOptions = {
            headers: getApiHeader(),
            params: query
        };

        return axios.get(apiRoute('/v1/pharmacy/edited/prescription/' + prescription_id), requestOptions);
    }

    static getPrescriptionForOrder(id) {
        const requestOptions = {
            headers: getApiHeader()
        };
        // return axios.get(apiRoute('/v1/pharmacy/prescription/' + id), requestOptions);
        return axios.get(apiRoute('/v1/pharmacy/technician/prescriptionorder/' + id), requestOptions);
    }
    static getPharmacyPrescriptions({ page, sizePerPage, sortBy, searchText, filter }, id) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute('/v1/pharmacies/patients/' + id + '/prescriptions'), requestOptions);
    }
    static getPrescriptionOrderPharmacy(id) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.get(apiRoute('/v1/pharmacy/technician/prescriptionorder/' + id), requestOptions);
    }

    static technicianPrescriptionList({ page, sizePerPage, sortBy, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, filter })
        };
        return axios.get(apiRoute('/v1/pharmacies/prescriptions'), requestOptions);
    }

    static listApprovalPendingPrescription({ page, sizePerPage, sortBy, searchText }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText })
        };

        return axios.get(apiRoute("/v1/approve-prescription"), requestOptions);
    }

    static assignPrecription(prescription_id, data) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute('/v1/pharmacies/prescriptions/' + prescription_id + '/assign-role'), data, requestOptions);
    }

    static revokePharmacyPrecription(prescription_id, data) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute('/v1/pharmacies/prescriptions/' + prescription_id + '/revoke-role'), data, requestOptions);
    }

    static acquirePrescription(prescription) {
        const requestOptions = {
            headers: getApiHeader(null, true)
        };

        return axios.post(apiRoute('/v1/pharmacy/technician/acquire/prescription'), prescription, requestOptions);
    }

    static prescriptionQueueList({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        //return axios.get(apiRoute("/v1/pharmacy/prescription/orders/queue"), requestOptions);
        return axios.get(apiRoute("/v1/pharmacies/prescriptions"), requestOptions);
    }

    static requestPrescriptionAccess(prescription_ids) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.post(apiRoute("/v1/request-permission"), prescription_ids, requestOptions);
    }

    static listPrescriptionRequest({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute("/v1/prescription-permission"), requestOptions);
    }

    static grantPermissionAccess(data) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.put(apiRoute("/v1/request-permission/" + data.id), data, requestOptions);
    }


    static getPrescriptionOrders({ page, sizePerPage, sortBy, searchText, filter }, prescriptionId) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute("/v1/doctor/prescription/" + prescriptionId + "/orders"), requestOptions);
    }

    static PharmacistAcquirePrescription(data) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.post(apiRoute("/v1/pharmacy/accuire/prescription"), data, requestOptions);
    }

    static showOriginalPrescription(prescriptionId) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/pharmacy/original/prescription/' + prescriptionId), requestOptions);
    }

    static getByTemporaryId(temp_id) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute("/v1/temporary-ids/" + temp_id), requestOptions);
    }

    static getPrescriptionConcerns(prescriptionId, roleSlug, from_profile_id = null) {
        const requestOptions = {
            headers: getApiHeader()
        };

        if (roleSlug === 'pharmacist') {
            return axios.get(apiRoute('/v1/pharmacy/prescription/concern/' + prescriptionId), requestOptions);
        } else {
            return axios.get(apiRoute('/v1/doctor/prescription/concern/messages/' + prescriptionId + '/' + from_profile_id), requestOptions);
        }
    }

    static getPrescriptionConcernsPharmacist(prescriptionId) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/doctor/prescription/concerns/' + prescriptionId), requestOptions);
    }

    static prescriptionConcern(postData) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.post(apiRoute("/v1/prescription/concern"), postData, requestOptions);
    }

    static getPrescriptionFees({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };
        return axios.get(apiRoute("/v1/doctors/prescription-fees"), requestOptions);
    }

    static getPrescriptionFeeInfo(prescription_id) {
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.get(apiRoute("/v1/doctors/prescription-fees/" + prescription_id), requestOptions);
    }

    static getPatientFeeInfo(patient_id) {
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.get(apiRoute("/v1/doctors/patients/" + patient_id + "/prescription-fees"), requestOptions);
    }

    static createPatientFeeInfo(patient_id, data) {
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.post(apiRoute("/v1/doctors/patients/" + patient_id + "/prescription-fees"), data, requestOptions);
    }

    static updatePrescriptionFeeInfo(prescription_id, data) {
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.post(apiRoute("/v1/doctors/prescription-fees/" + prescription_id + "/collected"), data, requestOptions);
    }
    static updatePrescriptionFee(prescription_id, data) {
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.post(apiRoute("/v1/doctors/prescriptions/" + prescription_id + "/prescription-fees"), data, requestOptions);
    }
    static getPrescriptionForEdit(id) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.get(apiRoute('/v1/pharmacies/prescriptions/' + id), requestOptions);
    }

    static getprescriptionForPrint(prescription_id) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.get(apiRoute('/v1/pharmacies/prescriptions/' + prescription_id), requestOptions);
    }

    static getPrescriptionsDownloadLink(prescriptionId, type) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/prescriptions/' + prescriptionId + '/download/' + type), requestOptions);
    }

    static getPendingSignatures() {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/prescriptions/pending-signature'), requestOptions);
    }
    // Create Prescription APIs
    static createDraftPrescription(data) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.post(apiRoute('/v1/doctors/prescription'), JSON.stringify(data), requestOptions);
    }
    static createVital(prescriptionId, data) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.post(apiRoute('/v1/doctors/prescriptions/' + prescriptionId + '/vital'), JSON.stringify(data), requestOptions);
    }
    static CreateHistory(prescriptionId, data) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.post(apiRoute('/v1/doctors/prescriptions/' + prescriptionId + '/history'), JSON.stringify(data), requestOptions);
    }
    static updatePatientPresentCondition(prescriptionId, data) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.post(apiRoute('/v1/doctors/prescriptions/' + prescriptionId + '/present-condition'), JSON.stringify(data), requestOptions);
    }
    static updateAssessment(prescriptionId, data) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.post(apiRoute('/v1/doctors/prescriptions/' + prescriptionId + '/assessment'), JSON.stringify(data), requestOptions);
    }
    static updatePrescriptionRecommendation(prescriptionId, data) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.post(apiRoute('/v1/doctors/prescriptions/' + prescriptionId + '/recommendation'), JSON.stringify(data), requestOptions);
    }
    static createExamination(prescriptionId, data) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.post(apiRoute('/v1/doctors/prescriptions/' + prescriptionId + '/examination'), JSON.stringify(data), requestOptions);
    }
    //Get BMI Category API
    static getBmiCategory(data) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.post(apiRoute('/v1/patients/bmi-category'), JSON.stringify(data), requestOptions);
    }
    //Get Prescription APIs
    static getVital(prescriptionId) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.get(apiRoute('/v1/doctors/prescriptions/' + prescriptionId + '/vital'), requestOptions);
    }
    static getHistory(prescriptionId) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.get(apiRoute('/v1/doctors/prescriptions/' + prescriptionId + '/history'), requestOptions);
    }
    static getPatientPresentCondition(prescriptionId) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.get(apiRoute('/v1/doctors/prescriptions/' + prescriptionId + '/present-condition'), requestOptions);
    }
    static getAssessment(prescriptionId) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.get(apiRoute('/v1/doctors/prescriptions/' + prescriptionId + '/assessment'), requestOptions);
    }
    static getPrescriptionRecommendation(prescriptionId) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.get(apiRoute('/v1/doctors/prescriptions/' + prescriptionId + '/recommendation'), requestOptions);
    }
    static getExamination(prescriptionId) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.get(apiRoute('/v1/doctors/prescriptions/' + prescriptionId + '/examination'), requestOptions);
    }
    //Assign prescriptions to doctor/assistant
    static prescriptionAssignToAssistant(prescriptionId, data) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.post(apiRoute('/v1/doctors/prescriptions/' + prescriptionId + '/assign-to-assistant'), JSON.stringify(data), requestOptions);
    }
    static prescriptionAssignToDoctor(prescriptionId, data) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.post(apiRoute('/v1/doctors/prescriptions/' + prescriptionId + '/assign-to-main'), JSON.stringify(data), requestOptions);
    }
    static createPrescriptionSummary(prescriptionId, data) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.post(apiRoute('/v1/doctors/prescriptions/' + prescriptionId + '/summary'), JSON.stringify(data), requestOptions);
    }
    static updatePrescription(prescriptionId, data) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.put(apiRoute('/v1/doctors/prescriptions/' + prescriptionId), JSON.stringify(data), requestOptions);
    }
    static cancelPrescription(prescriptionId) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.delete(apiRoute('/v1/doctors/prescriptions/' + prescriptionId), requestOptions);
    }
    static updatePrescriptionPersonalNote(prescription_id, data) {
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.put(apiRoute("/v1/doctors/prescriptions/" + prescription_id + "/personal-note"), data, requestOptions);
    }
    static createPharmacyPrescription(data) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.post(apiRoute('/v1/pharmacies/prescriptions'), JSON.stringify(data), requestOptions);
    }
    static getPharmacyEmergencyPrescriptionList({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute('/v1/pharmacies/emergrency-prescriptions'), requestOptions);
    }
    static createPrescriptionBodyChart(prescriptionId, data) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.post(apiRoute('/v1/doctors/prescriptions/' + prescriptionId + '/body-chart'), JSON.stringify(data), requestOptions);
    }
    static getPrescriptionBodyChart(prescriptionId) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.get(apiRoute('/v1/doctors/prescriptions/' + prescriptionId + '/body-chart'), requestOptions);
    }
    static cancelPharmacyPrescription(prescriptionId) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.delete(apiRoute('/v1/pharmacies/prescriptions/' + prescriptionId + '/cancel'), requestOptions);
    }
    static extendPrescription(prescriptionId) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.post(apiRoute('/v1/pharmacies/prescriptions/' + prescriptionId + '/extend'), null, requestOptions);
    }
    static repeatPrescription(prescriptionId) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.post(apiRoute(`/v1/doctors/prescriptions/${prescriptionId}/repeat`), null, requestOptions);
    }
    static detachPrescriptionHistory(patientId, documentId) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.delete(apiRoute(`/v1/patients/${patientId}/prescription-histories/${documentId}`), requestOptions);
    }
    static getPatientHistory(patientId) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.get(apiRoute(`/v1/doctors/patients/${patientId}/last-history`), requestOptions);
    }
    static createPrescriptionBodyChartUpload(prescriptionId, data) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.post(apiRoute('/v1/doctors/prescriptions/' + prescriptionId + '/body-chart-upload'), data, requestOptions);
    }
    static getPrescriptionBodyChartUpload(prescriptionId) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.get(apiRoute('/v1/doctors/prescriptions/' + prescriptionId + '/body-chart-upload'), requestOptions);
    }
    static deletePrescriptionBodyChartUpload(uploadId) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.delete(apiRoute('/v1/doctors/prescriptions/body-chart-upload/' + uploadId), requestOptions);
    }

    static getPatientFeeInfoById(fee_id) {
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.get(apiRoute("/v1/patients/fee/" + fee_id), requestOptions);
    }

    static updatePatientFeeInfo(fee_id, data) {
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.post(apiRoute("/v1/patients/fee/" + fee_id + "/collected"), data, requestOptions);
    }

    static uploadPresentingConditionImage(prescriptionId, files) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute('/v1/doctors/prescriptions/' + prescriptionId + '/presenting-condition-image'), files, requestOptions);
    }

    static getPresentingConditionImage({ page, sizePerPage, sortBy, searchText, filter }, prescriptionId) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute('/v1/doctors/prescriptions/' + prescriptionId + '/presenting-condition-image'), requestOptions);
    }

    static getPresentingConditionImageDownloadLink(prescriptionId) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/doctors/prescriptions/' + prescriptionId + '/download/presenting-condition-image'), requestOptions);
    }
}


export default PrescriptionService;
