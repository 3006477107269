const PATIENT_SANDBOX_URL = "https://patient.sandbox.daisy-health.com/patient/pre-registered/create";

const PATIENT_UAT_URL = "https://patient.uat.daisy-health.com/patient/pre-registered/create";

const PATIENT_PRODUCTION_URL = "https://patient.daisy-health.com/patient/pre-registered/create";

const PATIENT_DEVELOPMENT_URL = "http://localhost:9003/patient/pre-registered/create";

export const getPatientQrCodeUrl = (env_variable) => {
    if(env_variable === "sandbox") {
        return PATIENT_SANDBOX_URL;
    } else if(env_variable === "uat") {
        return PATIENT_UAT_URL;
    } else if(env_variable === "production") {
        return PATIENT_PRODUCTION_URL
    } else {
        return PATIENT_DEVELOPMENT_URL;
    }
}

export const QR_CODE_ENABLE = true;