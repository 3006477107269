import React from "react";
import { connect } from "react-redux";
import * as DoctorAction from "../../actions/doctorAction";
import { Helmet } from "react-helmet";
import DataGrid from "../../components/DataGrid";
import * as Helper from "../../utils/helpers";
import _ from 'lodash';
import { hasRole } from "../../utils/auth";
import * as PatientAction from '../../actions/patientAction';

class ListDailyPatientPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sortBy: { sortField: "visited_at", sortOrder: "desc" },
            filter: {
                visited_at: new Date()
            },
            export_csv: hasRole(this.props.selected_user_profile, ["doctor"])
        };
    }

    componentDidMount() {
        this.props.paginate({ sortBy: this.state.sortBy, filter: this.state.filter });
    }

    componentWillUnmount() {
        this.props.resetPaginate();
    }

    columns = () => {
        return [
            {
                dataField: "patient_code",
                text: "Patient Code",
                sort: true
            },
            {
                dataField: 'full_name',
                text: 'Full Name',
            },
            {
                dataField: "contact_number",
                text: "Contact Number"
            },
            {
                dataField: "gender",
                text: "Gender",
                formatter: this.genderFormatter,
                csvFormatter: this.genderFormatter
            },
            {
                dataField: "date_of_birth",
                text: "Age",
                sort: true,
                formatter: this.getAge,
                csvFormatter: this.getAge
            },
            {
                dataField: "last_visit_at",
                text: "Visited At",
                formatter: this.dateFormatter,
                csvFormatter: this.dateFormatter
            }
        ];
    };

    dateFormatter = (cell) => {
        if (cell) {
            return Helper.getDateFormat(cell);
        }
        return '--'
    }

    getAge = cell => {
        return Helper.getAgeString(cell);
    };

    genderFormatter = cell => {
        return _.capitalize(cell);
    };

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        if (type === "search") {
            page = 1;
        }
        if (sortField === 'last_visit_at')
            sortField = 'visited_at';
        const filter = this.state.filter;

        this.props.paginate({ page, sizePerPage, sortBy: { sortField, sortOrder }, searchText, filter });
    };

    render() {
        const { data, meta, isLoading } = this.props.patient_list;
        return (
            <React.Fragment>
                <Helmet>
                    <title>Daily Patient List</title>
                </Helmet>
                <div className="row">
                    <div className="col-md-24">
                        <h4 className="page-title">Daily Patient List</h4>
                    </div>
                </div>
                <div className="content-wrapper">
                    <DataGrid
                        loading={isLoading}
                        columns={this.columns()}
                        noDataIndication="No Record Found"
                        data={data}
                        meta={meta}
                        handleTableChange={this.handleTableChange}
                        export_csv={this.state.export_csv}
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    patient_list: state.patient_list,
    selected_user_profile: state.selected_user_profile,
});

const mapActionsToProps = {
    paginate: DoctorAction.patientList,
    resetPaginate: PatientAction.reset,
};

export default connect(mapStateToProps, mapActionsToProps)(ListDailyPatientPage);