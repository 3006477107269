import React from 'react';
import ViewVital from './Vital';

class ViewVitalInfo extends React.Component {
    render() {
        const { vital, patient_info } = this.props;

        return (<div>
            {vital.map((data) => {
                return <>
                    <ViewVital
                        vital={data}
                        patient_info={patient_info}
                    />
                    <hr />
                </>
            })}
        </div>)
    }
}
export default ViewVitalInfo