import * as types from '../actions/actionTypes';

const initialState = {
    select_profile: false,
}

export default function (state = initialState, { type, key }) {
    
    switch (type) {
        case types.SHOW_POPUP:
            return {
                ...state,
                [key]: true,
            };
        case types.HIDE_POPUP:
            document.getElementsByClassName('modal-overlay')[0].remove();
            return {
                ...state,
                [key]: false,
            };
        default:
            return state;
    }
}