import React from 'react';

export const Abdomen = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <h6>Abdomen</h6>
        <input type="checkbox" id='abdomen-normal' name='abdomen-normal' onChange={e => handleChange(e, 'abdomen')} checked={checkIfProblemExist('abdomen', 'abdomen-normal') ? 'checked' : ''} />
        <label htmlFor='abdomen-normal'>Normal</label>
    </React.Fragment>
}
export const AbdomenOptions = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <div className="options-details">
            {/* flat */}
            <input type="checkbox" id='abdomen-flat' name='abdomen-flat' onChange={e => handleChange(e, 'abdomen')} checked={checkIfProblemExist('abdomen', 'abdomen-flat') ? 'checked' : ''} />
            <label htmlFor='abdomen-flat'>Flat</label>
            {/* distended */}
            <input type="checkbox" id='abdomen-distended' name='abdomen-distended' onChange={e => handleChange(e, 'abdomen')} checked={checkIfProblemExist('abdomen', 'abdomen-distended') ? 'checked' : ''} />
            <label htmlFor='abdomen-distended'>Distended</label>
            {/* visible mass */}
            <input type="checkbox" id='abdomen-visible-mass' name='abdomen-visible-mass' onChange={e => handleChange(e, 'abdomen')} checked={checkIfProblemExist('abdomen', 'abdomen-visible-mass') ? 'checked' : ''} />
            <label htmlFor='abdomen-visible-mass'>Visible mass</label>
            {/* scar */}
            <input type="checkbox" id='abdomen-scar' name='abdomen-scar' onChange={e => handleChange(e, 'abdomen')} checked={checkIfProblemExist('abdomen', 'abdomen-scar') ? 'checked' : ''} />
            <label htmlFor='abdomen-scar'>Scar</label>
            {/* non tender */}
            <input type="checkbox" id='abdomen-non-tender' name='abdomen-non-tender' onChange={e => handleChange(e, 'abdomen')} checked={checkIfProblemExist('abdomen', 'abdomen-non-tender') ? 'checked' : ''} />
            <label htmlFor='abdomen-non-tender'>Non tender</label>
            {/* tender */}
            <input type="checkbox" id='abdomen-tender' name='abdomen-tender' onChange={e => handleChange(e, 'abdomen')} checked={checkIfProblemExist('abdomen', 'abdomen-tender') ? 'checked' : ''} />
            <label htmlFor='abdomen-tender'>Tender</label>
            {/* organomegally */}
            <input type="checkbox" id='abdomen-organomegally' name='abdomen-organomegally' onChange={e => handleChange(e, 'abdomen')} checked={checkIfProblemExist('abdomen', 'abdomen-organomegally') ? 'checked' : ''} />
            <label htmlFor='abdomen-organomegally'>Organomegally</label>
        </div>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment, checkIfProblemExist }) => {
    return <React.Fragment>
        <label className="heading-comment">Comment </label>
        <textarea className="form-control" name="comments" placeholder="Type here..." onChange={e => handleChange(e, 'abdomen')} disabled={checkIfProblemExist('abdomen', 'abdomen-normal') ? 'disabled' : ''} value={getCategoryComment('abdomen') ? getCategoryComment('abdomen') : ''}></textarea>
    </React.Fragment>
}